import { CITY_CHAR_LIMIT, VENUE_NAME_CHAR_LIMIT } from "appConstants";
import ApiModal from "components/common/apiErrorModal";
import ButtonLoader from "components/common/button/loader";
import CheckMarkButton from "components/common/checkMarkButton";
import CrossButton from "components/common/crossButton";
import Input from "components/common/input";
import { Modal, ModalBody, ModalHeader } from "components/common/modal";
import Pagination from "components/common/pagination";
import PhoneField from "components/common/phoneField";
import Select from "components/common/select";
import ZipField from "components/common/zipField";
import AddressField from "components/signup/venueSetup/addressField";
import CropImageModal from "components/signup/venueSetup/cropImageModal";
import { VendorActions } from "contexts/reducers/vendor";
import { useFormik } from "formik";
import { useIsDisabled } from "hooks/useIsDisabled";
import { useIsSuperAdmin } from "hooks/useIsSuperAdmin";
import { ImageFor } from "interfaces/vendor";
import { ChangeEvent,  useState } from "react";
import { useParams } from "react-router-dom";
import { uploadResource } from "services/common";
import { deleteObjectProp } from "util/deleteObjectProp";
import { GetLatlong } from "util/googleApiHelpers";
import { addVenueSchema } from "util/validationSchema/addVenueSchema";

interface Props {
    closeModal: Function;
    setFlash?: Function;
    vendorId: any;
    addVenue: Function
    loading: any;
}

const USA_STATES = ['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY']

const initialValues = {
    name: '',
    address: '',
    phone: '',
    zipcode: '',
    state: '',
    city: '',
    logo_filename: ''
}

const AddVenueModal = ({vendorId, closeModal, setFlash, addVenue, loading}: Props) => {

    const {id} = useParams();
    const vendor_id = id ? parseInt(id, 10) : null;

    const isSuperAdmin = useIsSuperAdmin();

    const [zipcodeDisabled, setZipcodeDisabled] = useState(false);
    const [error, setError] = useState('');
    const [step, setStep] = useState(1);
    const [imageUploading ,setImageUploading] = useState<any>(null!);
    const [, setFile] = useState<any>(null!);
    const [logoError, setLogoError] = useState<boolean>(true);
    const [logoBorderError, setLogoBorderError] = useState<boolean>(false);
    const [latLong, setLatLong] = useState({
        longitude: '',
        latitude: ''
    })

    const [imgUrl, setImgUrl] = useState<any>('');
    const [fileType, setFileType] = useState<any>('');
    const [fieldEvent, setFieldEvent]= useState<ChangeEvent<HTMLInputElement>>();

    const formik = useFormik({
        initialValues,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: addVenueSchema,
        onSubmit: async (values: any) => {
            if(step === 2){
                if(!values.logo_filename)
                {   setLogoBorderError(true);
                    setLogoError(true);
                }
                else{
                onAddVenue(values);
                
                return;
                }
            }


            if(!values.latitude && !values.longitude){
                const formatted_address = `${values.address || ''}.,${values.city || ''}, ${values.state || ''} ${values.zipcode || ''}, USA`;
                GetLatlong(formatted_address, (latLong: any) => {
                    setLatLong(latLong);
                })
            }
            step === 1 && setStep(step => step+1);
        }
    });
    const {values, errors, handleChange, handleSubmit, setValues, handleBlur, setFieldValue, setErrors} = formik;

    const onAddVenue = async (values: any) => {
    
        // const formatted_address = `${values.address}.,${values.city}, ${values.state} ${values.zipcode}, USA`;

        const finalValues = !values.latitude && !values.longitude ? {...values, ...latLong} : values;
        const res = await addVenue({...finalValues, vendor_id: isSuperAdmin ? vendor_id : vendorId});

        if(res?._status?.code === 201){
            closeModal();
            if(setFlash){
                setFlash(true);
                setTimeout(() => {
                    setFlash(false);
                },1000);
            }
        }

        if(res?.response?.data?.message || res?.response?.data?.error){
            setError(res?.response?.data?.message || res?.response?.data?.error);
        }
    }

    const onClickPlace = (addressData: any) => {
        setZipcodeDisabled(!!addressData.zipcode)
        // addressData = Object.keys(addressData).reduce((acc: any, currKey: any) => {
        //     if(addressData[currKey]){
        //         acc[currKey] = addressData[currKey]
        //     }
        //     return acc;
        // },{});

        const state = addressData?.state;
        const stateIndex = USA_STATES.findIndex((item: string) =>  item === state);
        if(stateIndex === -1){
            const option = document.createElement("option");
            option.text = addressData?.state;
            option.value = addressData?.state;
            const selectEl = document.getElementById('state-select');
            selectEl?.appendChild(option);
        }

        const {formatted_address, ...restAddressData} = addressData;
        setValues({
            ...values,
            ...restAddressData,
            ...(formatted_address && {address: formatted_address})
        });

        let errorFields: string[] = [];
        if(errors.city && restAddressData?.city){
            errorFields.push('city')
        }

        if(errors.state && restAddressData?.state){
            errorFields.push('state');
        }

        if(errors.zipcode && restAddressData.zipcode){
            errorFields.push('zipcode');
        }

        const updatedErrors = deleteAllProps(errors, errorFields);
        setErrors(updatedErrors);
    }

    const onClickCancel = (fieldName: string) => {
        const newErrors = deleteObjectProp(errors, fieldName)
        
        newErrors && setErrors && setErrors(newErrors);
    }

    const deleteAllProps = (obj: any, fields: string[]) => {
        let newObj = obj;
        fields.forEach((field: string) => {
            newObj = deleteObjectProp(newObj, field);
        });

        return newObj;
    }

    const onImageUpload = async (file: any, e?:any) => {
        setImageUploading(true)
        const targetName = e? e.target.name : fieldEvent?.target.name;
        
        const res = await uploadResource({
            file,
            image_for: ImageFor.Venue
        });
        setImageUploading(false);
        setLogoError(false);
        setLogoBorderError(false);
        setImgUrl(null!);
        setFileType('');

        if(res && res.url){
            setFieldValue(targetName? targetName:"", res.url)
        }

        const errorMessage = res?.response?.data?.message || res?.response?.data?.error;
        errorMessage && setError(errorMessage);
    }

    const onLoadImage = async (e: ChangeEvent<HTMLInputElement>) => {
        if(e.target.files && e.target.files.length > 0){
            const file = e.target.files[0];

            if(!(['image/png', 'image/jpeg', 'image/jpg', 'image/gif'].includes(file.type))){
                setError('Wrong format! Only png, jpg, jpeg, or gif image is allowed.');
                return;
            }
            file && setFile(file);
            const objectURL = URL.createObjectURL(file);
            const img = document.createElement('img');

            img.onload = () => {
                if(img.width > 600 || img.height > 600){
                    setFieldEvent(e);
                    const url = URL.createObjectURL(file);
                    setImgUrl(url);
                    setFileType(file.type);
                }else{
                    onImageUpload(file, e);
                   
                }

                URL.revokeObjectURL(objectURL);
            }

            img.src = objectURL;
        }
    }

    const isDisabled = useIsDisabled(values, errors, ['logo_filename', 'latitude', 'longitude']);
    const isLoading = loading[VendorActions.ADD_VENUE];

    return (
        <>
            <Modal
                setShowModal={() => closeModal()}
                customClass={`add-modal add-venue-modal ${error ? 'disabled' : ''}`}
            >
                <ModalHeader>
                    <div className="modal-actions">
                        <CrossButton
                            onClick={closeModal}
                            disabled={isLoading}
                        />
                        <CheckMarkButton
                            onClick={handleSubmit}
                            width={47}
                            height={47}
                            activeDisabled={step === 1 ? isDisabled : step === 2 ? logoError: false }
                            disabled={isLoading}
                            isForm
                        />
                    </div>
                    <p className="app-modal-title">Add Venue</p>
                </ModalHeader>

                <ModalBody>
                    {step === 1 ? (
                        <div className="add-lead-form">
                            <div className="row">
                                <div className="eleven-columns-sm columns">
                                    <Input
                                        name="name"
                                        label="Name"
                                        placeholder={errors.name ? errors.name as string : 'Enter name'}
                                        value={values?.name}
                                        onChange={handleChange}
                                        isError={!!(errors?.name)}
                                        errors={errors}
                                        setErrors={setErrors}
                                        maxLength={VENUE_NAME_CHAR_LIMIT}
                                        width="50%"
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="seven-columns-sm columns">
                                    <AddressField
                                        setFormValue={setFieldValue}
                                        onClickPlace={onClickPlace}
                                        address={values.address || ''}
                                        error={errors.address}
                                        onBlur={handleBlur}
                                        onClickCross={(fieldName: string) => onClickCancel(fieldName)}
                                    />
                                </div>
                                <div className="four-columns-sm columns">
                                    <PhoneField
                                        label="Phone"
                                        placeholder={errors?.phone as string || '123-456-7890'}
                                        name="phone"
                                        value={values?.phone}
                                        isError={!!(errors?.phone)}
                                        errors={errors}
                                        setErrors={setErrors}
                                        setValue={setFieldValue}
                                        errorMessage={errors.phone}
                                        width={200}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="five-columns-sm columns">
                                    <Input 
                                        label="City"
                                        placeholder={errors.city ? errors.city as string : 'Enter City'}
                                        name="city"
                                        value={values?.city}
                                        onChange={handleChange}
                                        isError={!!(errors?.city)}
                                        errors={errors}
                                        setErrors={setErrors}
                                        maxLength={CITY_CHAR_LIMIT}
                                    />
                                </div>
                                <div className="four-columns-sm columns">
                                    <Select
                                        label="State"
                                        placeholder={errors.state as string || 'XX'}
                                        isError={!!(errors.state)}
                                        name="state"
                                        id="state-select"
                                        value={values?.state || ''}
                                        onChange={handleChange}
                                        options={
                                            USA_STATES.map((item: any) => ({label: item, value: item}))
                                        }
                                        onClickCross={() => onClickCancel('state')}
                                        customStyles={{
                                            color: '#222'
                                        }}
                                    />
                                </div>
                            </div>
                            <div className="row">
                                <div className="five-columns-sm columns">
                                    <ZipField 
                                        label="Zip"
                                        placeholder={errors.zipcode ? errors.zipcode as string : 'Enter Zip'}
                                        name="zipcode"
                                        value={values?.zipcode}
                                        onChange={handleChange}
                                        isError={!!(errors?.zipcode)}
                                        errors={errors}
                                        setErrors={setErrors}
                                        disbaled={zipcodeDisabled}
                                    />
                                </div>
                            </div>
                            
                            
                        </div>
                    ):(
                        <div>
                            <p className="desc">The Venue logo is used across the system, most prominently on the Event Selection screen. Logo should be a 600 X 600 transparent PNG.</p>
                            <div className={logoBorderError ? `event-logo-error ` : `event`}>
                                <div className={`event-img ${!values?.logo_filename ? 'event-img-background' : ''}`}>
                                    {values?.logo_filename && (
                                        <img src={values?.logo_filename} alt="Logo Filename" />
                                    )}
                                    <p>Logo</p>
                                </div>
                                <div className="event-details">
                                    <p className="school-event">Example High vs </p>
                                    <p className="school-event">Example North High </p>
                                    <p className="event-timing">Today, 6:00 PM</p>
                                </div>
                            </div>
                            <div className="upload-logo">
                                <input onChange={onLoadImage} name="logo_filename" type="file" />
                                <p>
                                    Upload Venue Logo
                                    
                                </p>
                                {imageUploading && (
                                    <ButtonLoader />
                                )}
                            </div>
                        </div>
                        // <div>
                        //     <p className="desc">We didn't find an exact match for your address but found something close. Please confirm below so we can determine latitude and longitude.</p>
                        //     <div className="add-new-info">
                        //         <div className="detail-group address">
                        //             <label className="label">Address</label>
                        //             <p className="feild-text">E 41st St</p>
                        //         </div>
                        //         <div className="detail-group city">
                        //             <label className="label">City</label>
                        //             <p className="feild-text">Los Angeles</p>
                        //         </div>
                        //         <div className="detail-group state">
                        //             <label className="label">State</label>
                        //             <p className="feild-text">CA</p>
                        //         </div>
                        //         <div className="detail-group zip">
                        //             <label className="label">Zip</label>
                        //             <p className="feild-text">90011</p>
                        //         </div>
                        //     </div>
                        //     <div className="footer-bttns">
                        //         <button type="button" className="edit-blue bttn-shadow">Edit Address</button>
                        //         <Button buttonName="Confirm" />
                        //     </div>
                        // </div>
                    )}

                    <Pagination
                        count={2}
                        activePage={step-1}
                        onClickArrow={() => {}}
                        showNavigation={false}
                    />
                </ModalBody>
            </Modal>

            {imgUrl && (
                <CropImageModal
                    closeModal={() => setImgUrl(null!)}
                    image={imgUrl}
                    onCrop={(croppedImg: any) => onImageUpload(croppedImg)}
                    loading={imageUploading}
                    fileType={fileType}
                />
            )}

            {error && (
                <ApiModal
                    title="Add Venue Failed"
                    description={error}
                    setIsShow={() => setError('')}
                />
            )}
        </>
    )
};

export default AddVenueModal;
