import React, { FC } from "react";
import SingleUpcomingEvent from "./SingleUpcomingEvent";
import moment from 'moment';


interface Props {
  monthName?: string;
  monthEvents?: any;
  isMonthNameAlreadyExists?: boolean;
}

const UpComingEventListItem: FC<Props> = ({ monthName, monthEvents, isMonthNameAlreadyExists }) => {
  const mName = monthName?.split(" ")[0];
  const currentMonthName = moment().format('MMMM'); 

  const isShowMonthName = mName !== currentMonthName && !isMonthNameAlreadyExists

  return (
    <div>
      { isShowMonthName ? 
      <p className="month">{mName}</p>:<></>
      }
      <div>
        {monthEvents.map((event: any, index: number) => (
          <SingleUpcomingEvent
            date={event.start_date}
            time={event.start_time}
            name={event.name}
            index={index}
            venue =  {event.venue_name}
            enableDonationRoundUp = {event.donation_enabled}
            donationOrganization = {event.donation_organization}
            donationInfo = {event.donation_info ? event.donation_info : ""}
            endTime =  {event.end_time}
          />
        ))}
      </div>
    </div>
  );
};

export default UpComingEventListItem;
