import React, { useEffect, useState, useContext } from "react";
import BillingVenue from "./billingVenue";
import { AuthContext } from "contexts/auth";
import BillingInvoiceForm from "./billingInvoiceForm";
import { BillingStep, VendorSubscriptionType } from "interfaces/billing";
import { getBillingDetails } from "services/billing";
import Cookies from "js-cookie";
import { checkListMapper } from "interfaces/onboarding";
import BillingContactModal from "./billingContactModal";
import { putVendorDetailsForBilling } from "services/vendor";
import BillingPaymentModal from "./BillingPaymentModal";

interface Props {
  loading: string[];
  selectedStep: string;
  setSelectedStep: Function;
  markBillingPaid: Function;
  vendorDetails: any;
}

const Billing = ({
  loading,
  selectedStep,
  setSelectedStep,
  markBillingPaid,
  vendorDetails,
}: Props) => {
  const { user } = useContext(AuthContext);
  const [showModal, setShowModal] = useState(false);
  const [payNowModal, setPayNowModal] = useState(false)
  const [venueCount, setVenueCount] = useState<number>(1);
  const [daysRemaining, setDaysRemaining] = useState<number>(1);
  const [fees, setFees] = useState<number>(0);
  const [feesPerVenue, setFeesPerVenue] = useState<number>(0);
  const [paymentDisabled, setPaymentDisabled] = useState<boolean>(true);
  const [quantityDisabled, setQuantityDisabled] = useState<boolean>(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [vendorId, setVendorId] = useState<number>(0);
  const [isSubscription, setIsSubscription] = useState<boolean>(true);
  const [cardDetails, setCardDetails] = useState<any>({
    number: "",
    expiry: "",
    code: "",
    name: "",
  });

  const [, setError] = useState({type: "" , message: "" });
  const [payThroughEmailLoading, setPayThroughEmailLoading] = useState(false);



  const handleSubmit = async (values: any)=>{

    const res = vendorId
    ? await putVendorDetailsForBilling(
        {
        billing_contact_name: values.name,
        billing_contact: values.email,
        use_prospect_for_billing: values.isSelf ? true : false,
        vendor_id: vendorId,
      }): null;

      console.log(res);
        
      

    setShowModal(false);
    setPayNowModal(true)

  }




  const handleOnEmail = async()=>{   
    setPayThroughEmailLoading(true)
    const res = vendorId
    ? await putVendorDetailsForBilling(
        {
        pay_via_email_invoice: true,
        vendor_id: vendorId,
      }): null;
    
    if(res.error || res.message) {
        setError({type: "email_error", message : res.message || res.error})
    }else {
            setPayNowModal(false);
            setPayThroughEmailLoading(false)
            goNextStep();
        }


  }


 const handleOnPayNow =()=> {
    setPayNowModal(false);

 } 




  useEffect(() => {
    const userVendorIdString: string | undefined | number =
      user?.vendor_id || Cookies.get("vendor_id");
    let vendor_id: number;
    if (typeof userVendorIdString === "string") {
      vendor_id = parseInt(userVendorIdString, 10);
    } else if (typeof userVendorIdString === "number") {
      vendor_id = userVendorIdString;
    } else {
      vendor_id = 0;
    }
    setVendorId(vendor_id);

    if(!(vendorDetails?.billing_contact && vendorDetails?.billing_contact_name) )
        setShowModal(true);

/* eslint-disable react-hooks/exhaustive-deps */

  }, [user]);





  useEffect(() => {
    if (vendorId === 0) {
      return;
    }
    const getDetails = async () => {
      const res = await getBillingDetails({ vendor_id: vendorId, quantity: 1 });
      if (res) {
        if (res.type === VendorSubscriptionType.Venue) {
          setIsSubscription(false);
          setDaysRemaining(res.days);
          setFeesPerVenue(res.per_venue_price);
          setFees(res.per_venue_price);
        } else if (res.type === VendorSubscriptionType.Subscription) {
          setIsSubscription(true);
          setPaymentDisabled(false);
          setDaysRemaining(res.days);
          setFees(res.district_unlimited_price);
        }
      }
      setLoader(false);
    };
    setLoader(true);
    getDetails();
  }, [vendorId]);

  useEffect(() => {
    const calculateFees = () => {
      const fees = feesPerVenue * venueCount;
      setFees(parseFloat(fees.toFixed(2)));
    };
    calculateFees();
  }, [venueCount, feesPerVenue]);

  const changeStep = (step: String) => {
    if (step === BillingStep.Venue_Quantity) {
      setPaymentDisabled(false);
      setQuantityDisabled(true);
    } else if (step === BillingStep.Invoicing) {
      setPaymentDisabled(true);
      setQuantityDisabled(false);
    }
  };

  const goNextStep = () => {
        
        markBillingPaid();
        setSelectedStep(checkListMapper.CONNECT_STRIPE_ACCOUNT);
  };


  


  console.log(user);
  return (
    <div className="billing">
      <p className={loader ? "billing_title_skeleton" : "billing_title"}>
        {loader ? "" : "Subscription Billing"}
      </p>
      <p className={loader ? "billing_desc_skeleton" : ""}></p>
      <p className={loader ? "billing_desc_skeleton" : ""}></p>
      <p className={loader ? "billing_desc_skeleton" : ""}></p>
      {!isSubscription ? (
        <p className={loader ? "billing_desc_skeleton" : "billing_desc"}>
          {loader
            ? ""
            : `The agreement is signed and now you’re ready to make payment for the Crowd Chow yearly subscription. Crowd Chow is $100 per venue (football field, basketball gym, etc.). Our annual billing date is July 1, paid upfront. The fee is prorated based on the days remaining from today to July 1 (currently ${daysRemaining} days resulting in ${feesPerVenue} per venue).`}
        </p>
      ) : (
        <p className={loader ? "billing_desc_skeleton" : "billing_desc"}>
          {loader
            ? ""
            : `The agreement is signed and now you’re ready to make payment for the Crowd Chow yearly subscription. You have selected our District Unlimited plan for ${fees}. The annual billing date is July 1, paid upfront. The fee is prorated based on the days remaining from today to July 1 (currently ${daysRemaining} days resulting in ${fees}).`}
        </p>
      )}
      {!isSubscription && (
        <p className={loader ? "billing_desc_skeleton" : "billing_desc"}>
          {loader
            ? ""
            : "Enter the number of venues below to generate your invoice and make payment to proceed to the next step."}
        </p>
      )}
      {!isSubscription && (
        <BillingVenue
          venueCount={venueCount}
          setVenueCount={setVenueCount}
          venueFees={fees}
          confirmVenueQuantity={changeStep}
          disabled={quantityDisabled || loader}
        />
      )}
  <p
        className={
          loader ? "billing_invoiceTitle_skeleton" : "billing_invoiceTitle"
        }
      >
      </p>


      <p
        className={
          loader ? "billing_invoiceTitle_skeleton" : "billing_invoiceTitle"
        }
      >
        {loader ? "" : "Invoice"}
      </p>

      <BillingInvoiceForm
        vendorId={vendorId}
        setCardDetails={setCardDetails}
        cardDetails={cardDetails}
        fees={fees}
        daysRemaining={daysRemaining}
        venueCount={venueCount}
        feesPerVenue={feesPerVenue}
        disabled={paymentDisabled || loader}
        changeQuantity={changeStep}
        isSubscription={isSubscription}
        goNextStep={goNextStep}
      />
      
      {showModal && (
        <BillingContactModal 
        cancelDisabled = {true}  
        closeModal={() => setShowModal(false)}
        onClickSubmit={handleSubmit}
        
        
        />
      )}

      {payNowModal && (
        <BillingPaymentModal  
        title="Pay Now" description="Are you paying now or would you like us to email the invoice to the billiing contact to be paid within 7 days? "
        closeModal= {()=>{}}
        onEmail = {handleOnEmail}
        onPayNow = {handleOnPayNow}
        paymentLoading = {payThroughEmailLoading}
        
     
        
        />
        
      )}

        {/* {<ApiModal title="Something Went Wrong" description="Sorry we are unable to update the Billing" />} */}






    </div>
  );
};

export default Billing;
