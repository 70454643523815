import config from 'config/config';
import { PaymentProcessingParams, VendorBillingParams } from 'interfaces/billing';
import axios from 'services/core/axios';

export const getStripeSecret = async () => {

    try {
        const { data: { data } } = await axios.get(`${config.defaults.api_url}/v1/users/current/stripe/payment/intent`);
        return data;
    } catch (error) {
        return null;
    }
}

export const getBillingDetails = async (params: VendorBillingParams) => {

    try {
        const { data: { data } } = await axios.get(`${config.defaults.api_url}/v1/vendors/${params.vendor_id}/subscription?quantity=1`);
        return data;
    } catch (error) {
        return null;
    }
}
export const processPayment = async (params: PaymentProcessingParams) => {
    try {
        const { data: { data } } = await axios.post(`${config.defaults.api_url}/v1/vendors/${params.vendor_id}/subscribe`, params);
        return data;
    } catch (e: any) {
        return e.response?.data;
    }
}

export const getClientSecret = async (amount: number, vendor_id: number) => {
    try {
        const res = await axios.post(`${config.defaults.api_url}/v1/vendors/${vendor_id}/fetch/bank`, { amount });
        return res;
    } catch (e: any) {
        return e.response?.data;
    }
}


export const reconcilePaymentApi = async (amount: number, vendor_id: number, number_of_venues: number) => {
    try {
        const { data: { data } } = await axios.post(`${config.defaults.api_url}/v1/vendors/${vendor_id}/reconcile`, { amount, number_of_venues });
        return data?.price_match || false;
    } catch (e: any) {
        return e.response?.data;
    }
}

export const updatePaymentMethodApi = async (amount: number, vendor_id: number, number_of_venues: number, paymentType: string, status: string, paymentMethodId: string) => {
    try {
        const { data: { data } } = await axios.put(`${config.defaults.api_url}/v1/vendors/${vendor_id}/billing/status`, { amount, number_of_venues, payment_type: paymentType, status, payment_method: paymentMethodId });
        return data;
    } catch (e: any) {
        return e.response?.data;
    }
}

export const fetchPaymentDetails = async (paymentMethodId: string) => {
    try {
        const { data: { data } } = await axios.get(`${config.defaults.api_url}/v1/stripe/payment-method?payment_method_id=${paymentMethodId}`);
        return data;
    } catch (e: any) {
        console.log(e);
        return e.response?.data;
    }
}