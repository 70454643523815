import ChangePasswordModal from "components/profile/changePasswordModal";
import Sidebar from "components/sidebar";
import { AuthContext } from "contexts/auth";
import { useIsSuperAdmin } from "hooks/useIsSuperAdmin";
import Cookies from "js-cookie";
import { jwtDecode } from "jwt-decode";
import { useContext, useEffect, useState } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { clearAllCookies } from "util/clearAllCookies";
import { getAdminData } from "util/getAdminData";
import InfoModal from "../infoModal";

const Layout = (props: any) => {
  const {
    openSidebar,
    setOpenSideBar,
    openSearchbar,
    user,
    fetchOnboardingDetails,
    onboardingDetails,
    setUser,
    setOnboardingDetails,
    logoutUser,
  } = useContext(AuthContext);

  const navigate = useNavigate();
  const isSuperAdmin = useIsSuperAdmin();
  const location = useLocation();
  const pathname = location.pathname;

  const [confirmModal, setConfirmModal] = useState(false);

  const isTokenExpired = (token: string | undefined) => {
    if (!token) {
      return true;
    }

    const decodedToken = jwtDecode(token);
    return (
      !decodedToken?.exp || decodedToken?.exp * 1000 < new Date().getTime()
    );
  };

  useEffect(() => {
    const token = Cookies.get("crowd-chow-token");
    const impersonationToken = Cookies.get("impersonation_token");
    if (!token && !impersonationToken) {
      clearAllCookies();
      navigate("/login");
    }

    if (impersonationToken) {
      const isImpTokenExpired = isTokenExpired(impersonationToken);
      const isAccessTokenExpired = isTokenExpired(token);

      if (isImpTokenExpired && !isAccessTokenExpired) {
        setOnboardingDetails(null!);
        getAdminData((adminData: any) => {
          setUser(adminData);
          navigate("/");
        });
      }

      if (isImpTokenExpired && isAccessTokenExpired) {
        clearAllCookies();
        navigate("/login");
      }
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [pathname]);

  useEffect(() => {
    if (
      !user &&
      !isSuperAdmin &&
      !onboardingDetails &&
      location.pathname !== "/onboarding"
    ) {
      fetchOnboardingDetails();
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const isChangePassword =
    !confirmModal &&
    (user?.update_password === true ||
      user?.update_password === "true" ||
      Cookies.get("update_password") === "true");

  return (
    <div id="appContainerTop" className="appContainer">
      <Sidebar openSidebar={openSidebar} openSearchbar={openSearchbar} />
      <Outlet />
      {openSidebar && (
        <div
          onClick={() => setOpenSideBar(false)}
          className="sideBar-overlay"
        ></div>
      )}
      {isChangePassword && (
        <ChangePasswordModal
          isForce
          logoutUser={() => {
            setConfirmModal(true);
          }}
          closeModal={() => logoutUser()}
        />
      )}
      {confirmModal && (
        <InfoModal
          title="Password Changed"
          description="Please log in again using the new password."
          submitButtonName="Okay"
          onSubmit={() => {
            logoutUser();
            setConfirmModal(false);
          }}
          isCancel={false}
        />
      )}
    </div>
  );
};

export default Layout;
