import { useContext } from "react"
import AddButtonMobile from "../addButtonMobile"
import SearchButtonMobile from "../searchButtonMobile"
import { AuthContext } from "contexts/auth"
import LogoButton from "../logoButton"
import { useRoleId } from "hooks/useRoleId"
import UpButton from "./upButton"

interface IProps {
    showSearch?: boolean;
    showAdd?: boolean;
    onClickSearch?: Function;
    onClickAdd?: Function;
    addActive?: boolean;
    addButtonClass?: string;
    isButtonExists?: boolean;
}

const BottomBar = ({showSearch, isButtonExists, showAdd, onClickSearch, onClickAdd, addActive, addButtonClass}: IProps) => {
    const { openSidebar, openSearchbar, setOpenSideBar, setOpenSearchbar } = useContext(AuthContext);

    const roleId = useRoleId();

    const onClickBar = () => {
        openSidebar && setOpenSideBar(false);
        
        openSearchbar && setOpenSearchbar(false);
    }

    return (
        <div onClick={() => (openSidebar || openSearchbar) && onClickBar()} className={`sideBarBtn action-btns-mob ${roleId === 2 ? 'vendor-action-bar' : ''} ${openSearchbar ? 'openSearchBar' : ''} ${openSidebar ? 'openSidebar' : ''}`}>
            <SearchButtonMobile 
                onClick={() => onClickSearch && onClickSearch()}
                showSearch={showSearch} />
            <LogoButton />
           
            <UpButton showAdd = {showAdd} isButtonExists = {isButtonExists}/>
         

            <AddButtonMobile 
                onClick={() => onClickAdd && onClickAdd()}
                active={addActive}
                addButtonClass={addButtonClass}
                showAdd={showAdd}
            />
        </div>
    )
}

export default BottomBar;