import "@splidejs/react-splide/css";
import BackButton from "components/backButton";
import NoData from "components/noData";
import { ReportActions } from "contexts/reducers/report";
import { ReportContext } from "contexts/report";
import { useIsSuperAdmin } from "hooks/useIsSuperAdmin";
import moment from "moment";
import { FC, useContext, useEffect, useRef, useState } from "react";
import SeeAllSkeleton from "./seeAllSkeleton";

const ITEMS_PER_COLUMN = 9;

const getPerPageCount = () => {
  let perPage = 3;
  const width = window.innerWidth;
  switch (true) {
    case width > 0 && width <= 450:
      perPage = 1;
      break;
    case width > 450 && width <= 768:
      perPage = 2;
      break;
    case width > 768 && width <= 865:
      perPage = 2;
      break;
    case width > 865 && width <= 1024:
      perPage = 3;
      break;
    default:
  }

  return perPage;
};

interface Props {
  onClickEvent: any;
  setSeeAll: any;
}

const SeeAllEvents: FC<Props> = ({ onClickEvent, setSeeAll }) => {
    const { fetchAllEvents, allEvents, selectedVenue, loading, setAllEvents, selectedVendor } =
        useContext(ReportContext); 
    const [scrollTop, setScrollTop] = useState(0);
    const sliderRef = useRef<any>(null);
    const isSuperAdmin = useIsSuperAdmin()
    const [, setDotsCount] = useState<any>(0);
    const perPage = getPerPageCount();

    const onSelectEvent = (event: any) => {
        setAllEvents({
            events: [],
            _count: 0,
        });
        onClickEvent(event);
    };

    useEffect(() => {
        const venue_id = selectedVenue?.id ? selectedVenue?.id : 0;
  
        fetchAllEvents({ 
            venue_id: venue_id, 
            vendor_id : isSuperAdmin ? selectedVendor.id : null 
        });

    /* eslint-disable react-hooks/exhaustive-deps */
    }, []);

    useEffect(() => {
        if (allEvents?.events?.length > 0 && sliderRef?.current) {
            const count = Math.ceil(
                sliderRef?.current?.splide?.Components?.Slides?.getLength() / perPage
            );
            setDotsCount(count);
        }
    /* eslint-disable react-hooks/exhaustive-deps */
    }, [allEvents, sliderRef?.current, perPage]);

    const { events } = allEvents;

    let i = 0;
    let formattedEvents = [];
    while (i < events.length) {
        formattedEvents.push(events.slice(i, i + ITEMS_PER_COLUMN));
        i += ITEMS_PER_COLUMN;
    }

    const getAllEventsLoading = loading[ReportActions.GET_ALL_EVENTS];

    let threshold = 3;
    if (window.innerWidth <= 768 && window.innerWidth > 480) {
        threshold = 2;
    }

    if (window.innerWidth <= 480) {
        threshold = 1;
    }
    let counter = formattedEvents.length;
    while (counter % threshold) {
        counter++;
    }
    const remainingDivs = new Array(counter - formattedEvents.length).fill(
        new Array(9).fill(0)
    );
    return (
        <div className="reports-container see-all-events">
            <div className="backBtn">
                <BackButton onClick={() => setSeeAll(false)} />
            </div>
            <div className="seeAll-event-container">
                <h2 className="events-title">All Events</h2>
            </div>

            {getAllEventsLoading ? (
                <SeeAllSkeleton />
            ):(
                <div onScroll={(e: any) => setScrollTop(e.target.scrollTop)} className="events-columns-container">
                    {scrollTop < 10 && <div className="more-data"></div>}
                    {formattedEvents.length > 0 ? (
                        formattedEvents.map((eventArray: any) => {
                            const remainingColumns = new Array(
                                eventArray.length < ITEMS_PER_COLUMN
                                ? ITEMS_PER_COLUMN - eventArray.length
                                : 0
                            ).fill(0);
                            return (
                                <div className="event-columns">
                                    {eventArray.map((event: any) => {
                                        const { name, date, id } = event;
                                        return (
                                            <div
                                                key={id}
                                                className="event-detail-box"
                                                onClick={() => onSelectEvent(event)}
                                            >
                                                <p className="event-name">{name || ""}</p>
                                                <p className="event-date">
                                                    {date ? moment(date).format("dddd, MMMM D") : ""}
                                                </p>
                                            </div>
                                        );
                                    })}
                                    {remainingColumns.map((_: any, index: number) => {
                                        return (
                                            <div
                                                key={index}
                                                style={{
                                                    height: 58,
                                                    backgroundColor: "unset",
                                                    borderColor: "#fff"
                                                }}
                                                className="event-detail-box"
                                            >
                                                <p className="event-name"></p>
                                                <p className="event-date"></p>
                                            </div>
                                        );
                                    })}
                                </div>
                            );
                        })
                    ):(
                        <NoData width={1000} description="No Events" />
                    )}

                    {remainingDivs.map((emptyBoxes: any) => {
                        return (
                            <div className="event-columns">
                                {emptyBoxes.map((_: any, index: number) => {
                                    return (
                                        <div
                                            key={index}
                                            style={{
                                            height: 58,
                                            backgroundColor: "unset",
                                            borderColor: "#fff",
                                            }}
                                            className="event-detail-box"
                                        >
                                            <p className="event-name"></p>
                                            <p className="event-date"></p>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    })}
                </div>
            )}
        </div>
    );
};

export default SeeAllEvents;
