import { Skeleton } from "components/common/skeleton";

const SeeAllSkeleton = () => {
    return (
        <div className="skeleton-container">
          <div className="skeleton-column">
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
          </div>
          <div className="skeleton-column">
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
          </div>
          <div className="skeleton-column">
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
            <div className="event-loader-conatiner">
              <Skeleton height={58} />
            </div>
          </div>
        </div>
    )
};

export default SeeAllSkeleton;
