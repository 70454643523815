import config from 'config/config';
import { MenuRequest, PostVenueRequest, ResourceRequest } from 'interfaces/vendor';
import axios from 'services/core/axios';

export const putAgreement = async () => {

    try {
        const { data: { data } } = await axios.put(`${config.defaults.api_url}/v1/vendors/agreement`);
        return data

    } catch (error) {
        return null
    }
}

export const getOnboardingDetails = async () => {

    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/vendors`);
        return res?.data

    } catch (error) {
        return error
    }
}

export const postVenues = async (venueData: PostVenueRequest) => {
    const { vendor_id, ...body } = venueData;

    try {
        const res = await axios.post(`${config.defaults.api_url}/v1/vendors/${vendor_id}/venues`, body);
        return res?.data;

    } catch (error) {
        return error
    }
}

export const getLibraryResource = async (data: ResourceRequest) => {
    const queryString = Object.keys(data)
        .reduce((currentString: string, currentKey: string, i: number) => {
            const val = data[currentKey as keyof typeof data];
            currentString = val ? `${currentString}${i > 0 ? '&' : '?'}${currentKey}=${val}` : '';

            return currentString;
        }, '');

    try {
        const { data } = await axios.get(`${config.defaults.api_url}/v1/resources/library${queryString}`);
        return data

    } catch (error) {
        return error;
    }
}

export const addMenu = async (menuReq: MenuRequest) => {
    const { confirm, ...body } = menuReq;

    let formData: any = body;
    if (confirm === 'false') {
        formData = new FormData();
        Object.keys(body).forEach((key: string) => {
            const val = body[key as keyof typeof body]
            if (val) {
                formData.append(key, key === 'data' ? JSON.stringify(val) : val)
            }
        })
    }


    try {
        const { data } = await axios.post(`${config.defaults.api_url}/v1/import/menu?confirm=${confirm}`, formData);
        return data;

    } catch (error) {
        return error;
    }
}

export const sendEmailAgreement = async (vendor_id: number) => {

    try {
        const res = await axios.put(`${config.defaults.api_url}/v1/vendors/${vendor_id}/agreement/email`);
        return res?.data

    } catch (error) {
        return null
    }
}
