/* eslint-disable no-useless-escape */
import SectionHeader from "./sectionHeader";
import { useFormik } from "formik";
import * as Yup from 'yup';
// import { deleteObjectProp } from "util/deleteObjectProp";
import { useIsDisabled } from "hooks/useIsDisabled";
import { useContext, useEffect, useState } from "react";
import { VendorContext } from "contexts/vendor";
import ApiModal from "components/common/apiErrorModal";
import { VendorActions } from "contexts/reducers/vendor";
// import { useParams } from "react-router-dom";
import { VendorDetailSections } from ".";
import * as _ from 'lodash';
import { emailValidation } from "util/validationSchema/common";
import EditableInput from "components/common/editableInput";
import { EMAIL_CHAR_LIMIT, NAME_CHAR_LIMIT } from "appConstants";
import SaveIcon from "components/venue/SaveIcon";
import { AuthContext } from "contexts/auth";

const BillingContact = ({ setActiveEdit, activeEdit}: any) => {
    const { updateVendorDetails, vendorDetails ,loading } = useContext(VendorContext);
    const {vendor} = vendorDetails;
    const {setVendorDetailsEditing} = useContext(AuthContext)

    // const {id} = useParams();
    // const vendorId = id ? parseInt(id, 10) : 0;

    const [isEdit, setIsEdit] = useState(false);
    const [error, setError] = useState('');
    const [success, setSuccess] = useState(false);

    const initialValues = {
        billing_contact: vendor?.billing_contact || '',
        billing_contact_name: vendor?.billing_contact_name || ''
    }

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: Yup.object({
            billing_contact: emailValidation.required("Billing Contact Email is Required"),
            billing_contact_name: Yup.string().required('Billing contact name is required'),
        }),
        onSubmit: async (values: any) => {
            const res = await updateVendorDetails(values);

            if (res?.response?.data?.message) {
                setError(res?.response?.data?.message);
            }

            if (res?._status?.code === 200) {
                setSuccess(true);
                setIsEdit(false);
                setActiveEdit("");
                setVendorDetailsEditing(false)

            }
        }
    });

    const {values, errors, handleReset, handleChange, setErrors, handleSubmit} = formik;

    // const onClickCancel = (fieldName: string) => {
    //     const newErrors = deleteObjectProp(errors, fieldName);
        
    //     newErrors && setErrors && setErrors(newErrors);
    // }

    const onEdit = () => {
        setIsEdit(true);
        setActiveEdit(VendorDetailSections.WELCOME_EMAIL);
        setVendorDetailsEditing(true)

    }

    const onClickCross = () => {
        setIsEdit(false);
        handleReset(initialValues);
        setActiveEdit('');
        setVendorDetailsEditing(false)

    }
    
    const isDisabled = useIsDisabled(values, errors);
    const isEqual = _.isEqual(initialValues, values);
    const vendorLoading = loading[VendorActions.GET_VENDOR_DETAILS];
    const updateLoading = loading[VendorActions.UPDATE_VENDOR_DETAILS];


    useEffect(()=>{
        const sucessTimer = setTimeout(()=>{
             if(success)
             setSuccess(false);
         },3000);
 
 
         return ()=>{
             clearTimeout(sucessTimer)
         }
         
     }, [success])

    return (
        <>


            {
            <div className={`save-order ${success ? "save-order-animation" : ""}`}>
                 <span>   <SaveIcon /> Saved</span>
                </div>
            }


            <SectionHeader
                title="Billing Contact"
                isEditMode={isEdit}
                onClick={onEdit}
                onClickCross={onClickCross}
                onClickSubmit={handleSubmit}
                editDisabled={
                    activeEdit && activeEdit !== VendorDetailSections.BILLING_CONTACT
                }
                submitDisabled={isDisabled}
                isLoading={vendorLoading || updateLoading}
                disableSubmit={isEqual && values.billing_contact}
            />
            <p className="edit-details-desc">
                The billing contact receives subscription invoices via email <br/> (optional). If you want this contact to have access to the Vendor App or Portal, create an admin account for them instead.
            </p>
            <div className="admin-info">
                <div className="detail-group customer-facing-email">
                    <EditableInput
                        label="Billing Contact Name"
                        placeholder={errors.billing_contact_name as string || "Enter Billing Contact Name"}
                        name="billing_contact_name"
                        value={values.billing_contact_name}
                        onChange={handleChange}
                        maxLength={NAME_CHAR_LIMIT}
                        isError={!!errors.billing_contact_name}
                        errors={errors}
                        setErrors={setErrors}
                        isLoading={vendorLoading}
                        isEdit={isEdit}
                    />
                </div>
                <div className="detail-group customer-facing-email">
                    <EditableInput
                        label="Billing Contact Email"
                        placeholder={errors.billing_contact as string || "Enter Billing Contact Email"}
                        name="billing_contact"
                        value={values.billing_contact}
                        onChange={handleChange}
                        maxLength={EMAIL_CHAR_LIMIT}
                        isError={!!errors.billing_contact}
                        errors={errors}
                        setErrors={setErrors}
                        isLoading={vendorLoading}
                        isEdit={isEdit}
                    />
                </div>
                
            </div>
            {error && (
                <ApiModal 
                    title="Billing Contact Update Failed"
                    message={error}
                    setIsShow={() => setError('')}
                />
            )}
        </>
    )
};

export default BillingContact;
