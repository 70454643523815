interface IProps {
    children: any, 
    cursorPointer?: boolean; 
    customClass?: string;
    onClick?: Function;
}

const TableHeading = ({onClick, children, cursorPointer, customClass}: IProps) => {
    return (
        <div onClick={() => onClick && onClick()} className={`th ${customClass || ''} ${cursorPointer ? 'cursor-pointer' : ''} `}>
            {children}
        </div>
    )
};

export default TableHeading;
