export const getQueryString = (obj: any) => {
    const queryString = obj 
    ? Object.keys(obj)
        .reduce((currentString: string, currentKey: string, i: number) => {
            const val = obj[currentKey as keyof typeof obj];
            currentString += (val !== null && val !== undefined && val !== '')
            ? `${currentString.length > 0 ? '&' : '?'}${currentKey}=${val}` 
            : '';
            
            return currentString;
        },'') 
    : '';

    return queryString;
}