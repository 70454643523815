import { CHECKLIST } from "interfaces/vendor";
import CheckListBox from "./checkListBox";
import { Fragment, useContext, useState } from "react";
import { VendorDetails } from "interfaces/onboarding";
import { AuthContext } from "contexts/auth";
import Button from "components/common/button";
import InfoModal from "components/common/infoModal";

interface Props {
    selectedStep: string;
    setSelectedStep: Function;
    vendorOnboardingDetails: VendorDetails
}

export const checkList: string[] = [
    CHECKLIST.Agreement,
    CHECKLIST.Billing,
    CHECKLIST.Connect_Stripe_Account,
    CHECKLIST.Venues,
    CHECKLIST.Menu,
    CHECKLIST.Submit_For_Review
]

const CheckList = ({ vendorOnboardingDetails, setSelectedStep, selectedStep }: Props) => {
    const [slideChecklist, setSlideChecklist] = useState(false);
    const { logoutUser } = useContext(AuthContext);
    const [logout, setLogout] = useState<boolean>(false);
    const onboarding_via_email = vendorOnboardingDetails?.onboarding_via_email;

    const isStepCompleted = (currentStep: string) => {
        let isCompleted = false;
        switch (currentStep) {
            case CHECKLIST.Agreement:
                isCompleted = vendorOnboardingDetails?.agreement_accepted
                break;
            case CHECKLIST.Billing:
                isCompleted = vendorOnboardingDetails?.billing?.paid
                break;
            case CHECKLIST.Connect_Stripe_Account:
                isCompleted = vendorOnboardingDetails?.connected_account_stripe?.connected
                break;
            case CHECKLIST.Venues:
                isCompleted = vendorOnboardingDetails?.onboarding_step === 'MENU' || vendorOnboardingDetails?.onboarding_step === 'SUBMIT_REVIEW'
                break;
            case CHECKLIST.Menu:
                isCompleted = vendorOnboardingDetails?.menu_completed
                break;
            default:
        }

        return isCompleted;
    }

    const onClickSingleStep = () => {
        setSlideChecklist(true)
    }

    const onLogout = () => {
        setLogout(true);
       
    }

    return (
        <div className="signup-body_checklist">
            <h2 className="signup-body_checklist_title">Onboarding Checklist</h2>
            <div className={`signup-body_checklist_items ${slideChecklist ? 'slide-checklist-mob' : ''}`}>
                {checkList.map((item, index: number) => {
                    const isCompleted = isStepCompleted(item);
                    return (
                        <Fragment key={index}>
                            <CheckListBox
                                currentStep={item}
                                selectedStep={selectedStep}
                                isCompleted={isCompleted}
                                onboarding_via_email={item===CHECKLIST.Billing ? onboarding_via_email : false}
                            />
                        </Fragment>
                    )
                })}
            </div>
            <div className="signup-body_checklist_items checklist-mobile-item">
                <CheckListBox
                    onClickBox={(selectedStep: string) => onClickSingleStep()}
                    currentStep={selectedStep}
                    selectedStep={selectedStep}
                    isCompleted={isStepCompleted(selectedStep)}
                    onboarding_via_email={onboarding_via_email}
                />
            </div>
            {/* {selectedStep === CHECKLIST.Agreement && (
                <p 
                    className="signup-body_checklist_BottomDescription"
                >
                    Scroll through and read the entire agreement. If you agree, click the checkbox below and click Agree and Proceed.
                </p>
            )} */}
            {slideChecklist && <div onClick={() => setSlideChecklist(false)} className="checklist-backdrop"></div>}

            <div className="submitReview_logOut">
                <Button
                    buttonName='Log Out'
                    onClick={() => onLogout()}
                />
            </div>




            {logout && (
                <InfoModal 
                    title="Logout"
                    description="Are you sure you want to logout?"
                    submitButtonName="Yes"
                    closeModal={() => setLogout(false)}
                    onSubmit={() => logoutUser()}
                    isCancel
                />
            )}

        </div>
        
    )
}

export default CheckList;