import React, { FC } from "react";

interface Props {
  name: string;
  image: string;
  variant: string;
  total_price?: string;
}

const TopSellingProductListItem: FC<Props> = ({
  name,
  image,
  variant,
  total_price,
}) => {
  return (
    <div className="superEventOrder">
      <div className="vendorTopSellingItem">
        <div className="eventImage">
          <img src={image} alt="activeImage1" />
        </div>
        <div className="event-other-detail">
          <div className="eventDetail">
            <p className="productName">{name}</p>
            <p className="productDetail">{variant}</p>
          </div>
          {total_price !== undefined && total_price != null ? (
            <p className="total-sale">
              $
              {total_price && parseFloat(total_price)
                ? parseFloat(total_price).toLocaleString("en-US")
                : total_price}
            </p>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default TopSellingProductListItem;
