import React, { FC } from 'react'
import { VendorStatus } from "interfaces/vendor";
import Tick from 'assets/images/tick-white.png';
import {VendorStatusColors} from './VendorStatusColors'

// export enum VendorStatus {
//     Vp_Onboarding = 'Vp Onboarding',
//     Welcome = 'Welcome',
//     Created = 'Created',
//     Active = 'Active',
//     Needs_Review = 'Needs Review',
//     Onboarding = 'Onboarding',
//     Lapsed = 'Lapsed'
// }





interface Props {
    status: string;
    onClickStatus: Function;
    disableAll: boolean;
    fromLocation?: any;
    availableStatuses: any
}






const VendorStatuses: FC<Props> = ({disableAll, status, onClickStatus, fromLocation, availableStatuses}) => {

    const availableStatusesWithRecords = availableStatuses ? availableStatuses.map((s:any) => s.status) : [];
    const checkStatusAvailable = (status: string) => availableStatusesWithRecords.includes(status);

  return (
    <div className="status-bar">
         <div 
        onClick={() => !disableAll && checkStatusAvailable(VendorStatus.Created) && onClickStatus(VendorStatus.Created)} 
        className={`cursor-pointer vendor-status ${disableAll || !checkStatusAvailable(VendorStatus.Created) ? 'disabled' : '' }`}
      
    >
        <div className="circle" style={{backgroundColor: VendorStatusColors.CREATED}}>
            {status.includes(VendorStatus.Created) ? (
                <img src={Tick} alt="Tick" />
            ):(
                null
            )}
        </div>
        <p className="status-text">Created</p>
    </div>

    <div 
        onClick={() => !disableAll && checkStatusAvailable(VendorStatus.Welcome) && onClickStatus(VendorStatus.Welcome)} 
        className={`cursor-pointer vendor-status ${disableAll || !checkStatusAvailable(VendorStatus.Welcome) ? 'disabled' : '' }`}
    >
        <div className="circle" style={{backgroundColor: VendorStatusColors.WELCOME}}>
            {status.includes(VendorStatus.Welcome) ? (
                <img src={Tick} alt="Tick" />
            ):(
                null
            )}
        </div>
        <p className="status-text">Welcome</p>
    </div>

    <div 
        onClick={() => !disableAll && checkStatusAvailable(VendorStatus.Vp_Onboarding) && onClickStatus(VendorStatus.Vp_Onboarding)} 
        className={`cursor-pointer vendor-status ${disableAll || !checkStatusAvailable(VendorStatus.Vp_Onboarding) ? 'disabled' : '' }`}
    >
        <div className="circle" style={{backgroundColor: VendorStatusColors.VP_ONBOARDING}}>
            {status.includes(VendorStatus.Vp_Onboarding) ? (
                <img src={Tick} alt="Tick" />
            ):(
                null
            )}
        </div>
        <p className="status-text">VP Onboarding</p>
    </div>

    <div 
        onClick={() => !disableAll && checkStatusAvailable(VendorStatus.Needs_Review) && onClickStatus(VendorStatus.Needs_Review)} 
        className={`cursor-pointer vendor-status ${disableAll || !checkStatusAvailable(VendorStatus.Needs_Review) ? 'disabled' : '' }`}
    >
        <div className="circle" style={{backgroundColor: VendorStatusColors.NEEDS_REVIEW}}>
            {status.includes(VendorStatus.Needs_Review) ? (
                <img src={Tick} alt="Tick" />
            ):(
                null
            )}
        </div>
        <p className="status-text">Needs Review</p>
    </div>

    <div 
        onClick={() => !disableAll && checkStatusAvailable(VendorStatus.Onboarding) && onClickStatus(VendorStatus.Onboarding)} 
        className={`cursor-pointer vendor-status ${disableAll || !checkStatusAvailable(VendorStatus.Onboarding) ? 'disabled' : '' }`}
    >
        <div className="circle" style={{backgroundColor: VendorStatusColors.ONBOARDING}}>
            {status.includes(VendorStatus.Onboarding) ? (
                <img src={Tick} alt="Tick" />
            ):(
                null
            )}
        </div>
        <p className="status-text">Onboarding</p>
    </div>

    <div 
        onClick={() => !disableAll && checkStatusAvailable(VendorStatus.SCHEDULED) && onClickStatus(VendorStatus.SCHEDULED)} 
        className={`cursor-pointer vendor-status ${disableAll || !checkStatusAvailable(VendorStatus.SCHEDULED) ? 'disabled' : '' }`}
    >
        <div className="circle" style={{backgroundColor: VendorStatusColors.SCHEDULED}}>
            {status.includes(VendorStatus.SCHEDULED) ? (
                <img src={Tick} alt="Tick" />
            ):(
                null
            )}
        </div>
        <p className="status-text">Scheduled</p>
    </div>

    <div 
        onClick={() => !disableAll && checkStatusAvailable(VendorStatus.Active) && onClickStatus(VendorStatus.Active)} 
        className={`cursor-pointer vendor-status ${disableAll || !checkStatusAvailable(VendorStatus.Active) ? 'disabled' : '' }`}
    >
        <div className="circle" style={{backgroundColor: VendorStatusColors.ACTIVE}}>
            {status.includes(VendorStatus.Active) ? (
                <img src={Tick} alt="Tick" />
            ):(
                null
            )}
        </div>
        <p className="status-text">Active</p>
    </div>

    <div 
        onClick={() => !disableAll && checkStatusAvailable(VendorStatus.AT_RISK) && onClickStatus(VendorStatus.AT_RISK)} 
        className={`cursor-pointer vendor-status ${disableAll || !checkStatusAvailable(VendorStatus.AT_RISK) ? 'disabled' : '' }`}
    >
        <div className="circle" style={{backgroundColor: VendorStatusColors.AT_RISK}}>
            {status.includes(VendorStatus.AT_RISK) ? (
                <img src={Tick} alt="Tick" />
            ):(
                null
            )}
        </div>
        <p className="status-text">At Risk</p>
    </div>

    
    <div 
        onClick={() => !disableAll && checkStatusAvailable(VendorStatus.Lapsed) && onClickStatus(VendorStatus.Lapsed)} 
        className={`cursor-pointer vendor-status  ${disableAll || !checkStatusAvailable(VendorStatus.Lapsed) ? 'disabled' : '' }`}
    >
        <div className="circle" style={{backgroundColor: VendorStatusColors.LAPSED}}>
            {status.includes(VendorStatus.Lapsed) ? (
                <img src={Tick} alt="Tick" />
            ):(
                null
            )}
        </div>
        <p className="status-text">Lapsed</p>
    </div>
     
</div>
  )
}

export default VendorStatuses
