import Label from "components/common/label";
import { useEffect, useRef, useState } from "react";

const Email = ({updatedEmail, email, isEmailVerified}: any) => {
    const emailRef = useRef<any>(null);
    const updatedEmailRef = useRef<any>(null);

    const [tooltips, setTooltips] = useState({
        email: false,
        updatedEmail: false
    });

    useEffect(() => {
        if(updatedEmail && updatedEmailRef.current){
            setTooltips((prev: any) => ({
                ...prev,
                updatedEmail: updatedEmailRef.current ? (updatedEmailRef.current?.scrollWidth - updatedEmailRef.current?.clientWidth > 0) : false
            }))
        }

        if(email && emailRef.current){
            setTooltips((prev: any) => ({
                ...prev,
                email: emailRef.current ? (emailRef.current?.scrollWidth - emailRef.current?.clientWidth > 0) : false
            }))
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[email, updatedEmail, emailRef.current, updatedEmailRef.current]);

    return (
        <>
            <div className={updatedEmail ? 'updated-email-container' : ''} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>
                <div style={{display: updatedEmail ? 'flex' : 'block', alignItems: updatedEmail ? 'center' : 'normal'}}>
                    {(!isEmailVerified || updatedEmail) && (
                        <Label
                            text={updatedEmail ? 'Unverified Change' : "Unverified"}
                            customStyles={{marginLeft: 0, backgroundColor: '#F2bc47', color: '#000'}}
                        />
                    )}
                    <div className="show-truncate-tooltip">
                        <div ref={updatedEmailRef} className="email">
                            {updatedEmail || email}
                        </div>
                        {tooltips.updatedEmail && (
                            <span className="truncate-tooltip">{updatedEmail}</span>
                        )}
                    </div>
                    
                </div>
                {updatedEmail && (
                    <div style={{display: 'flex', alignItems: 'center'}}>
                        <Label customStyles={{marginLeft: 0}} text="Current" />
                        <div className="show-truncate-tooltip">
                            <div ref={emailRef} className="currentEmail">
                                {email}
                            </div>
                            {tooltips.email && (
                                <span className="truncate-tooltip">{email}</span>
                            )}
                        </div>
                    </div>
                )}
            </div>
        </>
    )
};

export default Email;
