import Login from 'pages/login';
import ForgotPassword from 'components/forgotPassword';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import AuthProvider from 'contexts/auth';
import Dashboard from 'pages/dashboard';
import OnBoardingPage from 'pages/onboarding';
import './App.css';
import StripeConnectCallback from 'pages/stripeConnectCallback';
import Vendors from 'pages/vendors';
import Vendor from 'pages/vendor';
import Profile from 'pages/profile';
import Users from 'pages/users';
import Venues from 'pages/venues';
import Venue from 'pages/venue';
import Orders from 'pages/orders';
import PageNotFound from 'components/common/pageNotFound';
import Reports from 'pages/reports';
import OnboardingProtectedRoute from 'components/common/protectedRoutes/onboardingProtectedRoute';
import SuperAdminRoutes from 'components/common/protectedRoutes/superAdminRoutes';
import VendorAdminRoutes from 'components/common/protectedRoutes/vendorAdminRoutes';
import UserRoutes from 'components/common/protectedRoutes/UserRoutes';
import Resource from 'pages/promotionalResources';
import Payment from 'pages/payment';
import Guides from 'pages/guides';
import VenueProviderLayout from 'components/common/layout/venueProviderLayout';
import PromotionalResourcesProvider from 'contexts/promotionalResources';
import Layout from 'components/common/layout';
import SuperReportDetails from 'pages/SuperReportDetails';
import ReportProvider from 'contexts/report';
import VendorProvider from 'contexts/vendor';


function App() {

  return (

    <BrowserRouter>
      <AuthProvider>
        <Routes>

          <Route element={<Layout />}>
            <Route element={<OnboardingProtectedRoute />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="/stripeConnectCallback" element={<StripeConnectCallback />} />



            
              <Route path='/vendors' element={<SuperAdminRoutes />}>
                <Route index element={<Vendors />} />
                <Route path=':id/venues/:id' element={<Venue />} />
                <Route path=':id' element={<Vendor />} />
              </Route>



              <Route element = {<VendorAdminRoutes />}>
                <Route element={<VenueProviderLayout />}>
                  <Route path='/venues' element = {<Venues />} />
                  <Route path='/venues/:id' element={<Venue />} />
                  <Route path="/promotional-resources" element={
                    <PromotionalResourcesProvider>
                      <Resource />
                    </PromotionalResourcesProvider>
                  } />
                </Route>
              </Route>   


              <Route path='/users' element = {<UserRoutes />}>
                <Route index element={<Users />} />
                <Route path="orders/:id" element={<Orders />} />
              </Route>


              <Route path="/reports" element={<Reports />} />

             
             
              <Route path="/reports/details" element = {
              
              <VendorProvider>
              <ReportProvider>
              <SuperReportDetails />
              </ReportProvider>
              </VendorProvider>
              
              } />


              <Route path="/payment" element={<Payment />} />
              <Route path="/guides" element={<Guides />} />
            </Route>
            
            <Route path="/onboarding" element={<OnBoardingPage />} />
            <Route path="/profile" element={<Profile />} />
          </Route>

          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/login" element={<Login />} />
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </AuthProvider>
    </BrowserRouter>


  );
}

export default App;
