export const getActiveTab = () => {
    const activeTab = localStorage.getItem("activeTab");
    if(activeTab){
        setTimeout(() => {
            localStorage.removeItem("activeTab");
        },0);
        return Number(activeTab);
    }

    return 0;
}