import { useEffect, useRef, useState } from "react";

const Paragraph = ({children, customClass, customClassParent, customStyle}: any) => {
    const textRef = useRef<any>(null);
    const [tooltip, setTooltip] = useState(false);

    useEffect(() => {
        if(children && typeof children === 'string' && textRef.current){
            setTooltip(textRef.current ? (textRef.current.scrollWidth - textRef.current.clientWidth > 0) : false)
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[children, textRef.current]);
    return (
        <div style={customStyle} className={`show-truncate-tooltip ${customClassParent || ''}`}>
            <p
                ref={textRef}
                className={`${customClass || ''}`}
            >
                {children}
            </p>
            {tooltip && (
                <span className="truncate-tooltip">{children}</span>
            )}
        </div>
    )
};

export default Paragraph;
