import { ChangeEvent } from "react";
import Label from "../label";
import CancelButton from "../inputCancelBtn";

interface IProps {
  label?: string;
  placeholder?: string;
  name?: string;
  value?: any;
  onChange?: (e: ChangeEvent<any>) => void;
  required?: boolean;
  onFocus?: (e: any) => void;
  customClass?: string;
  id?: string;
  options: { label: string | number; value: any }[];
  customStyles?: any;
  onBlur?: (e: any) => void;
  isError?: boolean;
  onClickCross?: Function;
  width?: string | number;
  disabled?: boolean;
  customStylesParent?: any;
}

const Select = ({
  id,
  label,
  value,
  name,
  onChange,
  placeholder,
  options,
  customClass,
  required,
  onFocus,
  customStyles,
  onBlur,
  isError,
  onClickCross,
  width,
  disabled,
  customStylesParent,
}: IProps) => {
  const handleChange = (e: any) => {
    onChange && onChange(e);
    onClickCross && onClickCross();
  };

  return (
    <div
      style={{
        ...(width && { width }),
        ...(customStylesParent && customStylesParent),
      }}
    >
      {label && <Label inputLabel text={label} />}
      <div className="select-container">
        <select
          name={name}
          id={id}
          value={value}
          onChange={handleChange}
          className={`select ${isError ? "select-error" : ""} ${
            customClass || ""
          }`}
          required={required}
          onFocus={onFocus}
          style={{
            ...(customStyles && customStyles),
          }}
          onBlur={onBlur}
          disabled={disabled}
        >
          <>
            {placeholder && (
              <option value="" disabled selected>
                {placeholder}
              </option>
            )}
            {options?.map((item: any, index: number) => {
              const { label, value, disabled } = item;
              return (
                <option key={index} disabled={disabled} value={value}>
                  {label}
                </option>
              );
            })}
          </>
        </select>
        {isError ? (
          <div className="cancel-icon-container">
            <CancelButton
              onClick={() => onClickCross && onClickCross()}
              customClass="input-cancel-icon"
              isError
            />
          </div>
        ) : (
          <svg
            className="select-dropdown-indicator"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="9"
            viewBox="0 0 15 9"
            fill="none"
          >
            <path
              d="M1 1L7.45166 7.45166L13.9033 1"
              stroke="#303030"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
        )}
      </div>
    </div>
  );
};

export default Select;
