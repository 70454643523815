interface IProps {
    text: string;
    customStyles?: any;
    customClass?: string;
    inputLabel?: boolean;
    disabled?: boolean;
}

const Label = ({inputLabel, text, customStyles, disabled, customClass}: IProps) => {
    return (
        <label 
            className={`label ${disabled ? 'disabled' : ''} ${inputLabel ? 'input-label' : ''} ${customClass || ''}`}
            style={{
                ...(customStyles && customStyles)
            }}
        >
            {text}
        </label>
    )
};

export default Label;
