import { FC } from "react";

interface Props  {
    isDisabled? : boolean,
}
const UserIcon: FC<Props> = ({isDisabled}) => {
    return (
        <>
            {/* {!isDisabled && <svg className="user-svg-active active-tab-svg" width="34" height="39" viewBox="0 0 34 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clipPath="url(#clip0_3686_158)">
                <path d="M20.039 20.749C24.5491 19.005 26.6909 14.1778 24.8227 9.96723C22.9546 5.75667 17.7839 3.75719 13.2738 5.50126C8.76362 7.24533 6.62187 12.0725 8.49003 16.2831C10.3582 20.4936 15.5288 22.4931 20.039 20.749Z" fill="#26CAD3"/>
                <path d="M16.6568 24.2012C7.59874 24.2012 0.256836 31.0554 0.256836 39.5118C0.256836 41.731 2.18352 43.5297 4.56066 43.5297H28.753C31.1301 43.5297 33.0568 41.731 33.0568 39.5118C33.0568 31.0554 25.7135 24.2012 16.6568 24.2012Z" fill="#0075C9"/>
                </g>
                <defs>
                <clipPath id="clip0_3686_158">
                <rect width="32.8" height="38.6571" fill="white" transform="translate(0.256836)"/>
                </clipPath>
                </defs>
            </svg>} */}
            <svg className="non-active-svg" width="19" height="27" viewBox="0 0 19 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.4">
                <mask id="mask0_1_240" mask-type="luminance" maskUnits="userSpaceOnUse" x="1" y="0" width="18" height="27">
                <path d="M18.9939 0H1V26.4367H18.9939V0Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_1_240)">
                <path d="M18.9944 13.4145V17.0117C18.9945 17.3235 18.9331 17.6323 18.8138 17.9204C18.6945 18.2085 18.5196 18.4703 18.2991 18.6907C18.0785 18.9112 17.8167 19.086 17.5286 19.2052C17.2405 19.3244 16.9316 19.3857 16.6198 19.3855C16.2388 19.3775 15.8629 19.2956 15.513 19.1444V25.2942C14.9789 25.5262 14.4302 25.7229 13.8704 25.883C12.6128 26.2536 11.3081 26.4396 9.99705 26.4353C8.68342 26.4329 7.37657 26.247 6.11438 25.883C5.55822 25.7243 5.01268 25.5305 4.48104 25.3028V19.1444C4.13119 19.2956 3.75532 19.3775 3.37427 19.3855C3.06244 19.3856 2.75366 19.3242 2.46556 19.2049C2.17746 19.0856 1.91571 18.9107 1.69525 18.6902C1.47479 18.4696 1.29996 18.2078 1.18075 17.9197C1.06154 17.6315 1.00028 17.3228 1.00049 17.0109V13.4137C1.00193 12.5098 1.36163 11.6433 2.00078 11.0042C2.63993 10.365 3.50638 10.0053 4.41027 10.0039H5.84682C7.02195 10.9567 8.48885 11.4767 10.0017 11.4767C11.5146 11.4767 12.9815 10.9567 14.1566 10.0039H15.5939C16.4964 10.0072 17.3608 10.3678 17.9981 11.0068C18.6353 11.6458 18.9936 12.512 18.9944 13.4145Z" fill="#222222"/>
                <path d="M14.8532 4.84516C14.854 5.80456 14.5702 6.74265 14.0379 7.5408C13.5055 8.33896 12.7484 8.96133 11.8624 9.32922C10.9763 9.69711 10.001 9.79401 9.05992 9.60765C8.11879 9.42129 7.25403 8.96005 6.57503 8.28224C5.89603 7.60444 5.43327 6.74052 5.24525 5.79972C5.05723 4.85892 5.15241 3.88349 5.51874 2.99678C5.88507 2.11007 6.50611 1.3519 7.30332 0.818128C8.10053 0.28436 9.03813 -0.00102887 9.99753 -0.00195212C11.284 -0.00277795 12.5181 0.507253 13.4286 1.41603C14.3391 2.3248 14.8515 3.55872 14.8532 4.84516Z" fill="#222222"/>
                </g>
                </g>
                <mask id="mask1_1_240" mask-type="luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="27">
                <path d="M17.9939 0H0V26.4367H17.9939V0Z" fill="white"/>
                </mask>
                <g mask="url(#mask1_1_240)">
                <path d="M17.9944 13.4145V17.0117C17.9945 17.3235 17.9331 17.6323 17.8138 17.9204C17.6945 18.2085 17.5196 18.4703 17.2991 18.6907C17.0785 18.9112 16.8167 19.086 16.5286 19.2052C16.2405 19.3244 15.9316 19.3857 15.6198 19.3855C15.2388 19.3775 14.8629 19.2956 14.513 19.1444V25.2942C13.9789 25.5262 13.4302 25.7229 12.8704 25.883C11.6128 26.2536 10.3081 26.4396 8.99705 26.4353C7.68342 26.4329 6.37657 26.247 5.11438 25.883C4.55822 25.7243 4.01268 25.5305 3.48104 25.3028V19.1444C3.13119 19.2956 2.75532 19.3775 2.37427 19.3855C2.06244 19.3856 1.75366 19.3242 1.46556 19.2049C1.17746 19.0856 0.915705 18.9107 0.695249 18.6902C0.474793 18.4696 0.299962 18.2078 0.18075 17.9197C0.0615388 17.6315 0.000284367 17.3228 0.000488791 17.0109V13.4137C0.0019286 12.5098 0.361634 11.6433 1.00078 11.0042C1.63993 10.365 2.50638 10.0053 3.41027 10.0039H4.84682C6.02195 10.9567 7.48885 11.4767 9.00171 11.4767C10.5146 11.4767 11.9815 10.9567 13.1566 10.0039H14.5939C15.4964 10.0072 16.3608 10.3678 16.9981 11.0068C17.6353 11.6458 17.9936 12.512 17.9944 13.4145Z" fill="white"/>
                <path d="M13.8532 4.84516C13.854 5.80456 13.5702 6.74265 13.0379 7.5408C12.5055 8.33896 11.7484 8.96133 10.8624 9.32922C9.9763 9.69711 9.00105 9.79401 8.05992 9.60765C7.11879 9.42129 6.25403 8.96005 5.57503 8.28224C4.89603 7.60444 4.43327 6.74052 4.24525 5.79972C4.05723 4.85892 4.15241 3.88349 4.51874 2.99678C4.88507 2.11007 5.50611 1.3519 6.30332 0.818128C7.10053 0.28436 8.03813 -0.00102887 8.99753 -0.00195212C10.284 -0.00277795 11.5181 0.507253 12.4286 1.41603C13.3391 2.3248 13.8515 3.55872 13.8532 4.84516Z" fill="white"/>
                </g>
            </svg>

            <svg className="svg-mob" width="19" height="28" viewBox="0 0 19 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.4">
                <mask id="mask0_1_4" mask-type="luminance" maskUnits="userSpaceOnUse" x="1" y="0" width="18" height="28">
                <path d="M18.9939 0.636719H1V27.0734H18.9939V0.636719Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_1_4)">
                <path d="M18.9944 14.0512V17.6484C18.9945 17.9602 18.9331 18.269 18.8138 18.5571C18.6945 18.8452 18.5196 19.107 18.2991 19.3274C18.0785 19.5479 17.8167 19.7227 17.5286 19.8419C17.2404 19.9612 16.9316 20.0224 16.6198 20.0222C16.2388 20.0142 15.8629 19.9323 15.513 19.7811V25.931C14.9789 26.1629 14.4302 26.3596 13.8704 26.5197C12.6128 26.8903 11.3081 27.0763 9.99704 27.072C8.68342 27.0696 7.37657 26.8837 6.11438 26.5197C5.55822 26.361 5.01268 26.1672 4.48104 25.9395V19.7811C4.13119 19.9323 3.75532 20.0142 3.37427 20.0222C3.06244 20.0223 2.75366 19.9609 2.46556 19.8416C2.17746 19.7223 1.91571 19.5474 1.69525 19.3269C1.47479 19.1063 1.29996 18.8445 1.18075 18.5564C1.06154 18.2683 1.00028 17.9595 1.00049 17.6477V14.0504C1.00193 13.1465 1.36163 12.28 2.00078 11.6409C2.63993 11.0018 3.50638 10.6421 4.41027 10.6406H5.84682C7.02195 11.5934 8.48885 12.1134 10.0017 12.1134C11.5146 12.1134 12.9815 11.5934 14.1566 10.6406H15.5939C16.4964 10.6439 17.3608 11.0045 17.9981 11.6435C18.6353 12.2825 18.9936 13.1487 18.9944 14.0512Z" fill="#222222"/>
                <path d="M14.8532 5.48188C14.854 6.44128 14.5702 7.37937 14.0379 8.17752C13.5055 8.97567 12.7484 9.59805 11.8624 9.96594C10.9763 10.3338 10.001 10.4307 9.05992 10.2444C8.11879 10.058 7.25403 9.59676 6.57503 8.91896C5.89603 8.24116 5.43327 7.37724 5.24525 6.43644C5.05723 5.49564 5.15241 4.52021 5.51874 3.6335C5.88507 2.74679 6.50611 1.98861 7.30332 1.45485C8.10053 0.921079 9.03813 0.63569 9.99753 0.634767C11.284 0.633941 12.5181 1.14397 13.4286 2.05275C14.3391 2.96152 14.8515 4.19544 14.8532 5.48188Z" fill="#222222"/>
                </g>
                </g>
                <mask id="mask1_1_4" mask-type="luminance" maskUnits="userSpaceOnUse" x="0" y="0" width="18" height="28">
                <path d="M17.9939 0.636719H0V27.0734H17.9939V0.636719Z" fill="white"/>
                </mask>
                <g mask="url(#mask1_1_4)">
                <path d="M17.9944 14.0512V17.6484C17.9945 17.9602 17.9331 18.269 17.8138 18.5571C17.6945 18.8452 17.5196 19.107 17.2991 19.3274C17.0785 19.5479 16.8167 19.7227 16.5286 19.8419C16.2404 19.9612 15.9316 20.0224 15.6198 20.0222C15.2388 20.0142 14.8629 19.9323 14.513 19.7811V25.931C13.9789 26.1629 13.4302 26.3596 12.8704 26.5197C11.6128 26.8903 10.3081 27.0763 8.99704 27.072C7.68342 27.0696 6.37657 26.8837 5.11438 26.5197C4.55822 26.361 4.01268 26.1672 3.48104 25.9395V19.7811C3.13119 19.9323 2.75532 20.0142 2.37427 20.0222C2.06244 20.0223 1.75366 19.9609 1.46556 19.8416C1.17746 19.7223 0.915705 19.5474 0.695249 19.3269C0.474793 19.1063 0.299962 18.8445 0.18075 18.5564C0.0615388 18.2683 0.000284367 17.9595 0.000488791 17.6477V14.0504C0.0019286 13.1465 0.361634 12.28 1.00078 11.6409C1.63993 11.0018 2.50638 10.6421 3.41027 10.6406H4.84682C6.02195 11.5934 7.48885 12.1134 9.00171 12.1134C10.5146 12.1134 11.9815 11.5934 13.1566 10.6406H14.5939C15.4964 10.6439 16.3608 11.0045 16.9981 11.6435C17.6353 12.2825 17.9936 13.1487 17.9944 14.0512Z" fill="white"/>
                <path d="M13.8532 5.48188C13.854 6.44128 13.5702 7.37937 13.0379 8.17752C12.5055 8.97567 11.7484 9.59805 10.8624 9.96594C9.9763 10.3338 9.00105 10.4307 8.05992 10.2444C7.11879 10.058 6.25403 9.59676 5.57503 8.91896C4.89603 8.24116 4.43327 7.37724 4.24525 6.43644C4.05723 5.49564 4.15241 4.52021 4.51874 3.6335C4.88507 2.74679 5.50611 1.98861 6.30332 1.45485C7.10053 0.921079 8.03813 0.63569 8.99753 0.634767C10.284 0.633941 11.5181 1.14397 12.4286 2.05275C13.3391 2.96152 13.8515 4.19544 13.8532 5.48188Z" fill="white"/>
                </g>
            </svg>
        </>
    )
};

export default UserIcon;
