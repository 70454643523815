interface IProps {
    children: any, 
    cursorPointer?: boolean; 
    customClass?: string;
    onClickRow?: Function;
    nonClickableRow?: boolean;
    flash?: boolean;
}

const TableRow = ({onClickRow, children, cursorPointer, customClass, flash, nonClickableRow=false}: IProps) => {
    return (
        <div onClick={() => onClickRow && onClickRow()} className={`table-row ${flash ? 'flash-table-row' : ''} ${nonClickableRow ? 'non-clickable' : ''} ${!nonClickableRow && cursorPointer ? 'cursor-pointer' : ''} ${customClass || ''}`}>
            {children}
        </div>
    )
};

export default TableRow;
