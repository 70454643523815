import SortIcon from "components/common/sortIcon";
import {TableHead, TableHeading} from "components/common/table";
import { useState } from "react";

const VendorHeader = ({queryParams, setQueryParams}:any) => {
const [nameSort, setNameSort] = useState("name");
const [venuesSort, setVenuesSort] = useState("venues");
const [scheduledSort, setScheduledSort] = useState("scheduled-events");
const [statusSort, setStatusSort]= useState("status")




const [venueSortIcon, setVenueSortIcon] = useState<boolean>(false);
const [scheduleIcon, setScheduleIcon] = useState<boolean>(false);
const [nameSortIcon, setNameSortIcon] = useState<boolean>(true);
const [statusSortIcon, setStatusSortIcon] = useState<boolean>(false);


    const onSort = (order: string) => {
        setQueryParams({
            ...queryParams,
            sort: order
        })
    }

    return (
        <TableHead>
            <TableHeading customClass="name">
                <div 
                    onClick={() => {


                        setNameSort(nameSort === "name"? "-name":"name")
                        onSort(queryParams.sort.includes("-name") ? 'name' : '-name')
                        setVenueSortIcon(false);
                        setScheduleIcon(false);
                        setNameSortIcon(true);
                        setStatusSortIcon(false);


                    }}
                    className="cursor-pointer" 
                    style={{display: 'flex', alignItems: 'flex-end', gap: 10}}>
                    <div>
                        Name
                    </div>
                   { nameSortIcon ? <SortIcon ascending={ nameSort === "name"? true :false} />: <></> }
                </div>
            </TableHeading>
            <TableHeading customClass="venue">
            <div 
                    onClick={() => {

                        setVenuesSort(venuesSort === "venues"? "-venues":"venues")
                        onSort(queryParams.sort.includes("-venues") ? 'venues' : '-venues')
                        setVenueSortIcon(true);
                        setNameSortIcon(false);
                        setScheduleIcon(false);
                        setStatusSortIcon(false);
                   
                   
                    }}
                    className="cursor-pointer" 
                    style={{display: 'flex', alignItems: 'flex-end', gap: 10, justifyContent: "center"}}>
                    <div>
                    Venues
                    </div>
                   { venueSortIcon ? <SortIcon ascending={ venuesSort === "venues"? true :false} /> : <></>}
                </div>
                
            </TableHeading>
            <TableHeading customClass="scheduled-events">
            <div 
                    onClick={() => {
                        setScheduledSort(scheduledSort === "scheduled-events"? "-scheduled-events": "scheduled-events")
                        onSort(queryParams.sort.includes("-scheduled-events") ? 'scheduled-events' : '-scheduled-events')
                        setScheduleIcon(true);
                        setNameSortIcon(false);
                        setVenueSortIcon(false);
                        setStatusSortIcon(false);

                    }}
                    className="cursor-pointer" 
                    style={{display: 'flex', alignItems: 'flex-end', gap: 10, justifyContent: "center"}}>
                    <div>
                    <p>Scheduled</p>
                    <p>Events</p>
                    </div>
                    {scheduleIcon ? <SortIcon ascending={ scheduledSort === "scheduled-events"? true :false} />: <></>}
                </div>

                
            </TableHeading>
            <TableHeading customClass="last-event">
                <p>Last</p>
                <p>Event</p>
            </TableHeading>
            <TableHeading customClass="status">
            <div 
                    onClick={() => {
                        setStatusSort(statusSort === "status"? "-status": "status")
                        onSort(queryParams.sort.includes("-status") ? 'status' : '-status')
                        setStatusSortIcon(true);
                        setScheduleIcon(false);
                        setNameSortIcon(false);
                        setVenueSortIcon(false);
                    }}
                    className="cursor-pointer" 
                    style={{display: 'flex', alignItems: 'flex-end', gap: 10, justifyContent: "center"}}>
                    <div>
                    <p>Status</p>
                
                    </div>
                    {statusSortIcon ? <SortIcon ascending={ statusSort === "status"? true :false} />: <></>}
                </div>
            </TableHeading>
            <TableHeading customClass="option">
                Options
            </TableHeading>
        </TableHead>
    )
};

export default VendorHeader;
