import React, { useContext } from "react";
import ReportTabs from "components/reports/normalAdmin";
import { useIsSuperAdmin } from "hooks/useIsSuperAdmin";
import { Navigate, useLocation } from "react-router-dom";
import { ReportContext } from "contexts/report";
import { VendorContext } from "contexts/vendor";

const SuperReportDetails = () => {
  const isSuperAdmin = useIsSuperAdmin();
  const location = useLocation();
  const { vendorList } = useContext(VendorContext);

  const {
    eventList,
    fetchEventItems,
    setEventItems,
    fetchEventSales,
    fetchVenues,
    setSeeAll,
    selectedVenue,
    selectedEvent,
    startDate,
    endDate,
    fetchReprtEvents,
    eventDetails,
    seeAll,
    setAllOrders,
    selectedVendor,
    setSelectedVendor,
    setOrderQueryParams,
    venueList,
    setSelectedVenue,
    fetchAllEvents,
    allEvents,
    setSelectedEvent,
    fetchAllOrder,
    orderQueryParams,
    setVenueOpen,
    fetchVendorVenueEvent,
    vendorsVenuesEvents, 
    superAdminReportsQueryParams, 
    setVenueList, 
    setAllEvents

  } = useContext(ReportContext);


  return (
    <>
      {isSuperAdmin ? (
        <ReportTabs
          eventList={eventList}
          fetchEventItems={fetchEventItems}
          setEventItems={setEventItems}
          fetchEventSales={fetchEventSales}
          fetchVenues={fetchVenues}
          setSeeAll={setSeeAll}
          selectedVenue={selectedVenue}
          selectedEvent={selectedEvent}
          startDate={startDate}
          endDate={endDate}
          fetchReprtEvents={fetchReprtEvents}
          eventDetails={eventDetails}
          seeAll={seeAll}
          setAllOrders={setAllOrders}
          vendorList={vendorList}
          selectedVendor={selectedVendor}
          setSelectedVendor={setSelectedVendor}
          setOrderQueryParams={setOrderQueryParams}
          venueList={venueList}
          setSelectedVenue={setSelectedVenue}
          fetchAllEvents={fetchAllEvents}
          allEvents={allEvents}
          setSelectedEvent={setSelectedEvent}
          fetchAllOrder={fetchAllOrder}
          orderQueryParams={orderQueryParams}
          setVenueOpen={setVenueOpen}
          fetchVendorVenueEvent= {fetchVendorVenueEvent}
          superAdminReportsQueryParams = {superAdminReportsQueryParams}
          vendorsVenuesEvents = {vendorsVenuesEvents}
          setVenueList = {setVenueList}
          setAllEvents = {setAllEvents}
          
        />
      ) : (
        <Navigate to="*" state={{ from: location }} />
      )}
    </>
  );
};

export default SuperReportDetails;
