import { ISuperAdminDasboardContext } from "interfaces/superAdminDashboard";

export enum superAdminDashboardActions  {
    
    SET_DASHBOARD_LOADING = 'SET_DASHBOARD_LOADING',
    RESET_DASHBOARD_LOADING = 'RESET_DASHBOARD_LOADING',
    GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA',
    SET_DASHBOARD_QUERY_PARAMS = 'SET_DASHBOARD_QUERY_PARAMS',
    GET_MORE_EVENTS_DATA = 'GET_MORE_EVENTS_DATA',
    GET_ACTIVE_VENDORS = 'GET_ACTIVE_VENDORS',
    GET_MORE_SALES_DATA = 'GET_MORE_SALES_DATA',
    GET_EVENTS_DATA= "GET_EVENTS_DATA"
}





export const superAdminDashboardReducer = (state: ISuperAdminDasboardContext, action: any)=>{
const {payload, type} = action;

switch(type) {
    case superAdminDashboardActions.SET_DASHBOARD_LOADING : {

        return {
            ...state, 
            loading: {
                ...state.loading,
                [payload]: true
            }
        }

    } 

    case superAdminDashboardActions.RESET_DASHBOARD_LOADING :   
    const newLoadingState = {
        ...state.loading
    }
    delete newLoadingState[payload];
    return {
        ...state,
        loading: newLoadingState
    }

    case superAdminDashboardActions.SET_DASHBOARD_QUERY_PARAMS:
        return {
            ...state,
            dashboardQueryParams: payload
        }

    case superAdminDashboardActions.GET_MORE_EVENTS_DATA:{

        const {event_offset, ...restData} = payload;
        return {
            ...state,
            dashboardData: {
                ...state.dashboardData,
                events: event_offset ? [...state.dashboardData.events, ...restData.events] : restData.events
            }
        }
    }
    case superAdminDashboardActions.GET_ACTIVE_VENDORS:
    case superAdminDashboardActions.GET_MORE_SALES_DATA:{

        const {offset, ...data} = payload;
        return {
            ...state,
            dashboardData: {
                ...state.dashboardData,
                data: offset ? [...state.dashboardData.data, ...data.data] : data.data
            }
        }
    }
    case superAdminDashboardActions.GET_DASHBOARD_DATA:{
        const {offset, event_offset, ...reqPayload} = payload;

        return {
            ...state,
            dashboardData: reqPayload
        }
    }
    case superAdminDashboardActions.GET_EVENTS_DATA:{
        const {offset, event_offset, ...reqPayload} = payload;

        return {
            ...state,
            dashboardData: reqPayload
        }
    }

    default:
        return state

}






}