import React, { FC, useContext, useState } from "react";
import SuperAdminReportsDropDown from "../../DropDowns/SuperAdminReportsDropDown";
import { SuperAdminReportContext } from "contexts/superAdminReport";
import { SuperAdminReportActions } from "contexts/reducers/superAdminReports";
import { useLocation } from "react-router-dom";


const sortByOptions = [
  { label: "Sort By Date", value: "date" },
  { label: "Sort By Sales", value: "sales" },
  { label: "Sort By Donations", value: "donations" },
  { label: "Sort By Avg Sale", value: "avg_sale" },
  { label: "Sort By Items", value: "items" },
];

interface Props {
  isPrint?: boolean;
  activeTab?: any
}

const EventsTableHeader: FC<Props> = ({ isPrint = false, activeTab }) => {
  const { fetchReprtEvents, eventQueryParams, setReportReducer, datesQueryParams } = useContext(
    SuperAdminReportContext
  );
  const [sortBy, setSortBy] = useState<string>(eventQueryParams.sort_by);
  const [datesSortBy, setDatesSortBy] = useState<string>(datesQueryParams.sort_by)

  // const [from, setFrom] = useState(fromLocation?.from);

  const location = useLocation();
  const fromLocation = location.state;

  const onSortByChange = async (e: React.ChangeEvent<HTMLSelectElement>) => {

    if(activeTab === 0)
    {
    delete fromLocation?.from;
    const newQueryParam = {
      ...eventQueryParams,
      sort_by: e.target.value,
      offset: 0,
    };

    setReportReducer(
      SuperAdminReportActions.SET_EVENT_QUERY_PARAMS,
      newQueryParam
    );
    setSortBy(e.target.value);
    await fetchReprtEvents(newQueryParam);
    }else{

      const newQueryParam = {
        ...datesQueryParams,
        sort_by: e.target.value,
        offset: 0,
      };
  
      setReportReducer(
        SuperAdminReportActions.SET_DATES_QUERY_PARAMS,
        newQueryParam
      );
      setDatesSortBy(e.target.value);
      await fetchReprtEvents(newQueryParam);

    }
  };

  console.log(activeTab);

  return (
    <div className="eventsTableHeader">
      <p className="eventName">Event Name</p>
      <div className="sort-name-dropDown">
        <SuperAdminReportsDropDown
          isPrint={isPrint}
          options={sortByOptions}
          value={activeTab === 0 ? (fromLocation?.from === "dashboardEvents" ? "sales" : sortBy): datesSortBy }
          onChange={onSortByChange}
        />
        {!isPrint && (
          <svg
            className="reports-select-dropDown"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="9"
            viewBox="0 0 15 9"
            fill="none"
          >
            <path
              d="M1 1L7.45166 7.45166L13.9033 1"
              stroke="#707070"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
        )}
      </div>
    </div>
  );
};

export default EventsTableHeader;
