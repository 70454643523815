import React, { useEffect, useState } from "react";
import * as pdfLib from "pdfjs-dist/build/pdf.mjs";
import SignagePreview from "components/promotionalResources/common/signagePreviewCard";

if (process.env.NODE_ENV === "development") {
  pdfLib.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.mjs",
    import.meta.url
  ).toString();
} else {
  pdfLib.GlobalWorkerOptions.workerSrc =
    "https://unpkg.com/pdfjs-dist@4.0.379/build/pdf.worker.mjs";
}

function PDFViewer({ url }: any) {
  const [loading, setLoading] = useState(false);
  const [signagePreview, setSignagePreview] = useState("");

  const generatePdfPreview = async () => {
    try {
      const pdfDoc = await pdfLib.getDocument({ url: url }).promise;
      const canvas = document.createElement("canvas");
      const page = await pdfDoc.getPage(1);

      const viewport = page.getViewport({ scale: 1 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport,
      };

      await page.render(render_context).promise;
      let img = canvas.toDataURL("image/png");

      setSignagePreview(img);
    } catch (err: any) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    generatePdfPreview();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SignagePreview>
      {!loading && (
        <div className="preview-img">
          <img
            src={signagePreview}
            alt="Preview"
            style={{
              maxWidth: 1124,
              objectFit: "cover",
            }}
          />
        </div>
      )}
    </SignagePreview>
  );
}

export default PDFViewer;
