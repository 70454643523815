interface IProps {
    title: string;
    description: string
}

const Description = ({title, description}: IProps) => {
    return (
        <>
            <h2 className="page-title">{title}</h2>
            <p className="page-desc">
                {description}
            </p>
        </>
    )
};

export default Description;
