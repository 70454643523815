import moment from "moment";
import React, { FC } from "react";

interface Props {
dataItem : any

}


const Top10MostSalesForTheYear:FC<Props> = ( { dataItem })=>{

const formattedDate = moment(dataItem.event_start_date).format('M/D/YY')
//const formattedTime = moment(dataItem.event_start_date)

const formattedTime = moment(`${dataItem.event_start_date} ${dataItem.event_start_time}`).format("h:mm A"); //.replace("M", "")
return (
    <>
        <div className="event-detail">
            <div className="school-logo">
                <img src={dataItem.event_logo} 
                    alt="product"
                />
            </div>
            <div className="event-name-date">
                <p className="school-name">{dataItem.event_name}</p>
                <p className="event-date">{formattedDate}  {formattedTime}</p>
            </div>
            {dataItem.sales ? <span className="payment-label">${dataItem.sales && parseFloat(dataItem.sales) ? parseFloat(dataItem.sales).toLocaleString('en-US') : dataItem.sales}</span>: <></>}
        </div>

        {/* <div className="event-detail-skeleton data-skeleton"></div> */}
       
       
       
       
       
        {/* <div className="scroll-data"></div> */}
    </>
)


}


export default Top10MostSalesForTheYear