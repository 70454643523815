import { useEffect } from "react";

export const useLeadTooltip = (setTooltips: Function, refs: any, data: any) => {
    const {cityRef, stateRef, nameRef, roleRef, emailRef, whereHearedRef, lastSentRef, repRef} = refs;

    useEffect(() => {
        if(data?.city && cityRef.current){
            setTooltips((prev: any) => ({
                ...prev,
                city: cityRef.current ? (cityRef.current.scrollWidth - cityRef.current.clientWidth > 0) : false
            }))
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[data, cityRef]);

    useEffect(() => {
        if(data?.state && stateRef.current){
            setTooltips((prev: any) => ({
                ...prev,
                state: stateRef.current ? (stateRef.current.scrollWidth - stateRef.current.clientWidth > 0) : false
            }))
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[data, stateRef]);

    useEffect(() => {
        if(data?.name && nameRef.current){
            setTooltips((prev: any) => ({
                ...prev,
                name: nameRef.current ? (nameRef.current.scrollWidth - nameRef.current.clientWidth > 0) : false
            }))
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[data, nameRef]);

    useEffect(() => {
        if(data?.role && roleRef.current){
            setTooltips((prev: any) => ({
                ...prev,
                role: roleRef.current ? (roleRef.current.scrollWidth - roleRef.current.clientWidth > 0) : false
            }))
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[data, roleRef]);

    useEffect(() => {
        if((data?.sales_kit?.sent_by || data?.vendor?.rep) && repRef.current){
            setTooltips((prev: any) => ({
                ...prev,
                rep: repRef.current ? (repRef.current.scrollWidth - repRef.current.clientWidth > 0) : false
            }))
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[data, repRef]);
    

    useEffect(() => {
        if((data?.sales_kit?.last_sent || data?.vendor?.conversion_date) && lastSentRef.current){
            setTooltips((prev: any) => ({
                ...prev,
                lastSent: lastSentRef.current ? (lastSentRef.current.scrollWidth - lastSentRef.current.clientWidth > 0) : false
            }))
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[data, lastSentRef]);

    useEffect(() => {
        if(data?.email && emailRef.current){
            setTooltips((prev: any) => ({
                ...prev,
                email: emailRef.current ? (emailRef.current.scrollWidth - emailRef.current.clientWidth > 0) : false
            }))
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[data, emailRef]);

    useEffect(() => {
        if(data?.where_heard && whereHearedRef.current){
            setTooltips((prev: any) => ({
                ...prev,
                where_heard: whereHearedRef.current ? (whereHearedRef.current.scrollWidth - whereHearedRef.current.clientWidth > 0) : false
            }))
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[data, whereHearedRef]);
}