import BottomBar from "components/common/bottomBar";
import Button from "components/common/button";
import TableSkeleton from "components/common/skeleton/tableSkeleton";
import NoData from "components/noData";
import DeleteModal from "components/vendors/vendorsCore/deleteModal";
import { VendorActions } from "contexts/reducers/vendor";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import AddVenueModal from "./addVenueModal";
import { useIsSuperAdmin } from "hooks/useIsSuperAdmin";
import moment from "moment";
import DeleteIcon from "components/common/deleteIcon";
import SortIcon from "components/common/sortIcon";
import VenueName from "./venueName";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { Table, TableBody, TableHead } from "components/common/table";
import { useLimit } from "hooks/useLimit";
import MoreDataSkeleton from "components/common/moreDataSkeleton";

const Venues = ({
  id,
  loading,
  deleteVenue,
  fetchVenues,
  venueList,
  addVenue,
  queryParams,
  setQueryParams,
  setPage,
}: any) => {
  const isSuperAdmin = useIsSuperAdmin();

  const navigate = useNavigate();
  const { skeletonsCount } = useLimit();

  const [flash, setFlash] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedVenue, setSelectedVenue] = useState<any>(null!);

  const fetchMoreVenue = async () => {
    const nextPage = Math.ceil(queryParams.offset / queryParams.limit) + 1;

    setPage(nextPage);
    const res = await fetchVenueList({
      ...queryParams,
      offset: nextPage * queryParams.limit,
    });

    return res;
  };

  const { handleScroll, scrollTop } = useInfiniteScroll(fetchMoreVenue, 560);

  const onClickDelete = (venue: any) => {
    setSelectedVenue(venue);
  };

  const onDelete = async () => {
    const res = await deleteVenue(selectedVenue.id);

    if (res?.status === 204) {
      setSelectedVenue(null!);
    }
  };

  const onClickAddVenue = () => {
    setShowModal(true);
  };

  const onClickRow = (id: number) => {
    navigate(isSuperAdmin ? `venues/${id}` : id.toString());
  };

  const onSort = (order: string) => {
    fetchVenueList({
      ...queryParams,
      sort: order,
    });
  };

  const fetchVenueList = async (queryParams: any) => {
    setQueryParams(queryParams);
    const res = await fetchVenues({ vendor_id: id, ...queryParams });
    return res;
  };

  useEffect(() => {
    //if (venues.length) return;


    id && fetchVenueList(queryParams);

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const { venues, _count } = venueList;
  const isLoading = loading[VendorActions.DELETE_VENUE];
  const venuesLoading = loading[VendorActions.GET_VENUES];

  return (
    <>
      <div className="add-venue-container">
        {!isSuperAdmin && <h2 className="page-heading">Venues</h2>}
        <div className="super-admin_addVendorContainer_action venues-action">
          <Button buttonName="New Venue" onClick={onClickAddVenue} />
        </div>
        <div className="venue-table">
          <Table customClass="table-container">
            <TableHead customClass="table-header">
              <div
                onClick={() =>
                  onSort(
                    queryParams &&
                      queryParams.sort &&
                      queryParams.sort.includes("-name")
                      ? "name"
                      : "-name"
                  )
                }
                style={{ textAlign: "left" }}
                className="cursor-pointer th name"
              >
                Name
                <SortIcon
                  ascending={
                    queryParams &&
                    queryParams.sort &&
                    queryParams.sort.includes("name")
                      ? !queryParams.sort.includes("-name")
                      : true
                  }
                />
              </div>
              {isSuperAdmin && (
                <div className="th map">
                  <p>Needs<br /> Map</p>
                </div>
              )}

              {!isSuperAdmin && (
                <div
                  onClick={() =>
                    onSort(
                      queryParams &&
                        queryParams.sort &&
                        queryParams.sort.includes("-scheduled_events")
                        ? "scheduled_events"
                        : "-scheduled_events"
                    )
                  }
                  className="cursor-pointer th venues"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "flex-end",
                    }}
                  >
                    <span>
                      <p>Scheduled</p>
                      <p>Events</p>
                    </span>
                    <SortIcon
                      ascending={
                        queryParams &&
                        queryParams.sort &&
                        queryParams.sort.includes("scheduled_events")
                          ? !queryParams.sort.includes("-")
                          : true
                      }
                    />
                  </div>
                </div>
              )}

              {!isSuperAdmin && (
                <div className="th last-event">
                  <p>Last</p>
                  <p>Event</p>
                </div>
              )}

              {!isSuperAdmin && (
                <div className="th option">
                  <p>Options</p>
                </div>
              )}
            </TableHead>
            <TableBody
              customClass="table-body"
              isShowBlur={_count > skeletonsCount && scrollTop < 10}
              onScroll={(e: any) => handleScroll(e, venues.length < _count)}
            >
              {venuesLoading ? (
                <TableSkeleton count={skeletonsCount} />
              ) : venues?.length > 0 ? (
                venues.map((item: any, index: number) => {
                  const showStatus = item.map_required;
                  return (
                    <div
                      key={item?.id}
                      onClick={() => onClickRow(item.id)}
                      className={`cursor-pointer table-row ${
                        index === 0 && flash ? "flash-table-row" : ""
                      }`}
                    >
                      <div
                        style={{ textAlign: "left" }}
                        className="td name show-truncate-tooltip"
                      >
                        {showStatus && (
                          <svg
                            style={{ marginRight: "12px" }}
                            xmlns="http://www.w3.org/2000/svg"
                            width="21"
                            height="21"
                            viewBox="0 0 21 21"
                            fill="none"
                          >
                            <circle
                              cx="10.5"
                              cy="10.5"
                              r="10.5"
                              fill="#FD2B13"
                            />
                          </svg>
                        )}
                        <VenueName name={item?.name} />
                      </div>
                      {isSuperAdmin && (
                        <div className="td map">
                            <p>
                                {item.map_required
                                ? "Yes"
                                : item.station_required
                                ? "Stations Required"
                                : item.concession_required
                                ? "Concession Required"
                                : "No"}
                            </p>
                        </div>
                      )}
                      {!isSuperAdmin && (
                        <div className="td venues">
                          {item?.scheduled_events}
                        </div>
                      )}
                      {!isSuperAdmin && (
                        <div className="td last-event">
                          {item?.last_event
                            ? moment(item?.last_event).format("M/D/YY")
                            : ""}
                        </div>
                      )}
                      {!isSuperAdmin && (
                        <div className="td option">
                          <DeleteIcon
                            onClick={(e: any) => {
                              e.stopPropagation();
                              onClickDelete(item);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  );
                })
              ) : (
                <NoData description="No Venues" />
              )}
              <MoreDataSkeleton
                isShow={
                  queryParams.offset > 0 &&
                  loading[VendorActions.GET_MORE_VENUES]
                }
              />
            </TableBody>
          </Table>
        </div>
      </div>
      {selectedVenue && (
        <DeleteModal
          title="Delete Venue"
          name={selectedVenue.name}
          onSuccess={onDelete}
          setIsShow={() => setSelectedVenue(null!)}
          disabled={isLoading}
        />
      )}
      {showModal && (
        <AddVenueModal
          closeModal={() => setShowModal(false)}
          setFlash={setFlash}
          vendorId={id}
          addVenue={addVenue}
          loading={loading}
        />
      )}
      <BottomBar
        showAdd
        addActive={showModal}
        onClickAdd={() => setShowModal(true)}
      />
    </>
  );
};

export default Venues;
