import { createContext, useReducer, useState } from "react";
import {
  AddAdminRequest,
  AddLeadRequest,
  AddVendorRequest,
  EditAdminRequest,
  LeadsRequest,
  SendWelcomeEmail,
  UpdateLeadRequest,
  VendorContextProvider,
  VendorDetailsRequest,
  VendorListRequest,
  VenueList,
} from "interfaces/vendor";
import { VendorActions, VendorReducer } from "./reducers/vendor";
import {
  addNotes,
  approveVendorSerive,
  deleteNote,
  getAdmins,
  getInvoiceHistory,
  getLeads,
  getVendorBillingHistory,
  getVendorDetails,
  getVendorList,
  getVendorOnboardingStatus,
  getVenueList,
  postAdmin,
  postAgreement,
  postLead,
  postVendor,
  postVenue,
  postWelcomeEmail,
  putAdmin,
  putLead,
  putSalesLead,
  putSubscriptionStatus,
  putVendorDetails,
  removeAdmin,
  removeLead,
  removeVendor,
  removeVenue,
  resettingVendor,
  sendEmailAgreement,
} from "services/vendor";
import { useParams } from "react-router-dom";
import { GetAdminRequest } from "interfaces/common";
import {
  getVenue,
  postInvoice,
  postVendorNote,
  removeVendorNote,
} from "services/venue";

const initialState: VendorContextProvider = {
  billingHistory: {
    subscriptions: [],
  },
  loading: {
    [VendorActions.GET_VENDOR_DETAILS]: true,
    [VendorActions.GET_LEADS]: true,
    [VendorActions.GET_VENDORS]: true,
  },
  setLoading: () => {},
  resetLoading: () => {},
  sendWelcomeEmail: () => Promise<any>,
  fetchVendorOnbaordingStatus: () => Promise<any>,
  updateVendorDetails: () => Promise<any>,
  updateVendorContext: () => Promise<any>,
  addLeadNote: () => Promise<any>,
  queryParams: {
    limit: window.innerHeight > 1100 ? 20 : 15,
    offset: 0,
    statusList: [],
    status: "",
    search: "",
    sort_order: "",
    sort_by: "",
  },
  vendorList: {
    vendors: [],
    enable_filters: [],
    _count: 0,
  },
  venueList: {
    venues: [],
    _count: 0,
  },
  adminList: {
    admins: [],
    _count: 0,
    _staffCount: 0,
    _customersCount: 0,
  },
  leadList: {
    leads: [],
    _count: 0,
    enable_filters: [],
  },
  invoiceHistory: {
    invoices: [],
  },
  vendorDetails: {},
  addVendor: () => Promise<any>,
  fetchVendors: () => Promise<any>,
  deleteVendor: () => Promise<any>,
  resetVendor: () => Promise<any>,
  fetchVenues: () => Promise<any>,
  deleteVenue: () => Promise<any>,
  getAdmins: () => Promise<any>,
  fetchAdmins: () => Promise<any>,
  addAdmin: () => Promise<any>,
  deleteAdmin: () => Promise<any>,
  updateAdmin: () => Promise<VenueList>,
  fetchVendorDetail: () => Promise<any>,
  addVenue: () => Promise<any>,
  addLead: () => Promise<any>,
  fetchLeads: () => Promise<VenueList>,
  deleteLead: () => Promise<any>,
  search: false,
  updateLead: () => Promise<any>,
  sendSalesLead: () => Promise<any>,
  setLead: () => {},
  approveVendor: () => Promise<any>,
  fetchVenueDetails: () => Promise<any>,
  setQueryParams: (callBack: any) => {},
  removeNote: () => Promise<any>,
  fetchVendorBillingHistory: () => Promise<any>,
  updateSubscriptionStatus: () => Promise<any>,
  sendInvoice: () => Promise<any>,
  addNoteToVendor: () => Promise<any>,
  deleteVendorNote: () => Promise<any>,
  fetchVendorInvoiceHistory: () => Promise<any>,
  salesKitSuccessModal: null!,
  setSalesKitSuccessModal: () => {},
  setActiveTab: () => {},
  activeTab: 0,
  page: 0,
  setPage: () => {},
  venuePage: 0,
  setVenuePage: () => {},
  leadsPage: 0,
  setLeadsPage: () => {},
  vendorLeadTab: 0,
  setVendorLeadTab: () => {},
  leadsClickedList: new Array<number>(),
  setLeadsClickedList: () => {},
  venueQueryParams: {
    limit: window.innerHeight > 1100 ? 20 : 15,
    offset: 0,
    sort: "",
  },
  setVenueQueryParams: () => {},
  reSetvendorList: () => {},
  adminsPage: 0,
  setAdminsPage: () => {},
  setVenues: (data: any) => {},
  setAdmins: (data: any) => {},
  adminQueryParam: {
    limit: window.innerHeight > 1100 ? 20 : 15,
    offset: 0,
    search: "",
    sort: "",
  },
  setAdminQeuryParam: () => {},
  uploadVendorCustomAgreement: () => Promise<any>,
  selectedVendorType: {},
  selectingVendorTypeInfo: () => {},
  emailAgreement: (vendor_id: number) => new Promise<any>(() => {}),
};
console.log("innerHeight", window.innerHeight);
export const VendorContext = createContext<VendorContextProvider>(null!);

const VendorProvider = ({ children }: any) => {
  const { id } = useParams();
  const vendorId = id ? parseInt(id, 10) : null;
  const [
    {
      invoiceHistory,
      billingHistory,
      leadList,
      loading,
      vendorList,
      venueList,
      adminList,
      vendorDetails,
      queryParams,
    },
    dispatch,
  ] = useReducer(VendorReducer, initialState);

  const [salesKitSuccessModal, setSalesKitSuccessModal] = useState(false);

  const [activeTab, setActiveTab] = useState(0);
  const [page, setPage] = useState(0);
  const [vendorLeadTab, setVendorLeadTab] = useState(
    initialState.vendorLeadTab
  );
  const [leadsClickedList, setLeadsClickedList] = useState<Array<number>>([]);
  const [venueQueryParams, setVenueQueryParams] = useState(
    initialState.venueQueryParams
  );
  const [venuePage, setVenuePage] = useState(initialState.venuePage);
  const [adminsPage, setAdminsPage] = useState(initialState.adminsPage);
  const [leadsPage, setLeadsPage] = useState(initialState.leadsPage);
  const [adminQueryParam, setAdminQeuryParam] = useState(
    initialState.adminQueryParam
  );

  const [selectedVendorType, setSelectedVendorType] = useState(
    initialState.selectedVendorType
  );

  const setQueryParams = (params: any) => {
    dispatch({
      type: VendorActions.SET_QUERY_PARAMS,
      payload: params,
    });
  };

  const [search, setSearch] = useState(false);

  const sendInvoice = async (sendInvoiceReq: {
    vendorId: number;
    email: string;
  }) => {
    setLoading(VendorActions.SEND_INVOICE);
    const res = await postInvoice(sendInvoiceReq);
    resetLoading(VendorActions.SEND_INVOICE);

    return res;
  };

  const addNoteToVendor = async (addNoteReq: any) => {
    setLoading(VendorActions.ADD_VENDOR_NOTE);
    const res = await postVendorNote(addNoteReq);
    resetLoading(VendorActions.ADD_VENDOR_NOTE);

    if (res?._status?.code === 201) {
      dispatch({
        type: VendorActions.ADD_VENDOR_NOTE,
        payload: res.data,
      });
    }

    return res;
  };

  const deleteVendorNote = async (note_id: any) => {
    setLoading(VendorActions.DELETE_VENDOR_NOTE);
    const res = vendorId ? await removeVendorNote(vendorId, note_id) : null;
    resetLoading(VendorActions.DELETE_VENDOR_NOTE);

    if (res?.status === 204) {
      dispatch({
        type: VendorActions.DELETE_VENDOR_NOTE,
        payload: note_id,
      });
    }

    return res;
  };

  const fetchVenueDetails = async (venue_id: string) => {
    setLoading(VendorActions.GET_VENUE_DETAILS);
    const res = await getVenue(venue_id);
    resetLoading(VendorActions.GET_VENUE_DETAILS);

    if (res?._status?.code === 200) {
      dispatch({
        type: VendorActions.GET_VENUE_DETAILS,
        payload: res.data,
      });
    }
    return res;
  };

  const addVenue = async (addVenueReq: any) => {
    setLoading(VendorActions.ADD_VENUE);
    const res = await postVenue(addVenueReq);
    resetLoading(VendorActions.ADD_VENUE);

    if (res?._status?.code === 201) {
      dispatch({
        type: VendorActions.ADD_VENUE,
        payload: {
          ...res.data,
          vendor: { ...res.data.vendor, map_required: true },
        },
      });
    }

    return res;
  };

  const setLoading = (loading: string) => {
    dispatch({
      type: VendorActions.SET_VENDOR_LOADING,
      payload: loading,
    });
  };

  const resetLoading = (loading: string) => {
    dispatch({
      type: VendorActions.RESET_VENDOR_LOADING,
      payload: loading,
    });
  };

  const fetchVendorOnbaordingStatus = async () => {
    if (!vendorId) return;

    setLoading(VendorActions.GET_VENDOR_ONBOARDING_STATUS);
    const res = await getVendorOnboardingStatus(vendorId);
    resetLoading(VendorActions.GET_VENDOR_ONBOARDING_STATUS);

    return res;
  };

  const sendWelcomeEmail = async (sendEmailReq: SendWelcomeEmail) => {
    setLoading(VendorActions.SEND_WELCOME_EMAIL);
    const res = await postWelcomeEmail(sendEmailReq);
    resetLoading(VendorActions.SEND_WELCOME_EMAIL);

    if (res?._status?.code === 201) {
      dispatch({
        type: VendorActions.SEND_WELCOME_EMAIL,
        payload: res.data,
      });
    }

    return res;
  };

  const updateVendorDetails = async (
    updateVendorDetilsReq: VendorDetailsRequest
  ) => {
    setLoading(VendorActions.UPDATE_VENDOR_DETAILS);
    const res = vendorId
      ? await putVendorDetails({
          ...updateVendorDetilsReq,
          vendor_id: vendorId,
        })
      : null;
    resetLoading(VendorActions.UPDATE_VENDOR_DETAILS);

    if (res?._status?.code === 200) {
      dispatch({
        type: VendorActions.UPDATE_VENDOR_DETAILS,
        payload: res.data,
      });
    }

    return res;
  };

  const updateVendorContext = async (
    updateVendorDetilsReq: VendorDetailsRequest
  ) => {
    dispatch({
      type: VendorActions.UPDATE_VENDOR_DETAILS,
      payload: updateVendorDetilsReq,
    });

    return updateVendorDetilsReq;
  };

  const updateSubscriptionStatus = async (updateVendorStatus: any) => {
    setLoading(VendorActions.UPDATE_SUBSCRIPTION_STATUS);
    const res = vendorId
      ? await putSubscriptionStatus({
          ...updateVendorStatus,
          vendor_id: vendorId,
        })
      : null;
    resetLoading(VendorActions.UPDATE_SUBSCRIPTION_STATUS);

    if (res?._status?.code === 200) {
      dispatch({
        type: VendorActions.UPDATE_SUBSCRIPTION_STATUS,
        payload: res.data,
      });
    }

    return res;
  };

  const fetchVendorBillingHistory = async () => {
    setLoading(VendorActions.SET_BILLING_HISTORY);
    const res = vendorId ? await getVendorBillingHistory(vendorId) : null;
    resetLoading(VendorActions.SET_BILLING_HISTORY);

    if (res?._status?.code === 200) {
      dispatch({
        type: VendorActions.SET_BILLING_HISTORY,
        payload: res.data,
      });
    }

    return res;
  };

  const fetchVendorInvoiceHistory = async () => {
    setLoading(VendorActions.SET_INVOICE_HISTORY);
    const res = vendorId ? await getInvoiceHistory(vendorId) : null;
    resetLoading(VendorActions.SET_INVOICE_HISTORY);

    if (res?._status?.code === 200) {
      dispatch({
        type: VendorActions.SET_INVOICE_HISTORY,
        payload: res.data,
      });
    }

    return res;
  };

  const setLead = (data: any) => {
    dispatch({
      type: VendorActions.SET_LEAD,
      payload: data,
    });
  };

  const addVendor = async (addVendorReq: AddVendorRequest) => {
    setLoading(VendorActions.ADD_VENDOR);
    const res = await postVendor(addVendorReq);
    resetLoading(VendorActions.ADD_VENDOR);

    if (res?._status?.code === 201) {
      dispatch({
        type: VendorActions.ADD_VENDOR,
        payload: res.data?.vendor,
      });
    }

    return res;
  };

  const fetchVendors = async (vendorListreq: VendorListRequest) => {
    if (vendorListreq.search) {
      setSearch(true);
    } else {
      setSearch(false);
    }
    vendorListreq.offset
      ? setLoading(VendorActions.GET_MORE_VENDORS)
      : setLoading(VendorActions.GET_VENDORS);
    const res = await getVendorList(vendorListreq);
    vendorListreq.offset
      ? resetLoading(VendorActions.GET_MORE_VENDORS)
      : resetLoading(VendorActions.GET_VENDORS);

    if (res?._status?.code === 200) {
      dispatch({
        type: VendorActions.GET_VENDORS,
        payload: { ...res.data, offset: vendorListreq.offset },
      });
    }

    return res;
  };

  const fetchLeads = async (getLeadsReq: LeadsRequest) => {
    if (getLeadsReq.search) {
      setSearch(true);
    } else {
      setSearch(false);
    }
    getLeadsReq.offset
      ? setLoading(VendorActions.GET_LEADS_MORE)
      : setLoading(VendorActions.GET_LEADS);
    const res = await getLeads(getLeadsReq);
    getLeadsReq.offset
      ? resetLoading(VendorActions.GET_LEADS_MORE)
      : resetLoading(VendorActions.GET_LEADS);

    if (res?._status?.code === 200) {
      dispatch({
        type: VendorActions.GET_LEADS,
        payload: { ...res.data, offset: getLeadsReq.offset },
      });
    }

    return res;
  };

  const addLead = async (addLeadReq: AddLeadRequest) => {
    setLoading(VendorActions.ADD_LEAD);
    const res = await postLead(addLeadReq);
    resetLoading(VendorActions.ADD_LEAD);

    if (res?._status?.code === 201) {
      dispatch({
        type: VendorActions.ADD_LEAD,
        payload: res.data,
      });
    }

    return res;
  };

  const updateLead = async (updateLead: UpdateLeadRequest) => {
    setLoading(VendorActions.UPDATE_LEAD);
    const res = await putLead(updateLead);
    resetLoading(VendorActions.UPDATE_LEAD);

    if (res?._status?.code === 200) {
      dispatch({
        type: VendorActions.UPDATE_LEAD,
        payload: res.data,
      });
    }

    return res;
  };

  const sendSalesLead = async (data: any) => {
    setLoading(VendorActions.SEND_SALES_LEAD);
    const res = data.id ? await putSalesLead(data.id) : null;
    resetLoading(VendorActions.SEND_SALES_LEAD);

    if (res?._status?.code === 200) {
      setSalesKitSuccessModal(data);
      dispatch({
        type: VendorActions.SEND_SALES_LEAD,
        payload: res.data,
      });
    }

    return res;
  };

  const deleteLead = async (leadId: number) => {
    setLoading(VendorActions.DELETE_LEAD);
    const res = await removeLead(leadId);
    resetLoading(VendorActions.DELETE_LEAD);

    if (res?.status === 204) {
      dispatch({
        type: VendorActions.DELETE_LEAD,
        payload: leadId,
      });
    }

    return res;
  };

  const setVenues = (data: any) => {
    dispatch({
      type: VendorActions.GET_VENUES,
      payload: data,
    });
  };

  const setAdmins = (data: any) => {
    dispatch({
      type: VendorActions.GET_ADMINS,
      payload: data,
    });
  };

  const fetchVendorDetail = async (id: number) => {
    setLoading(VendorActions.GET_VENDOR_DETAILS);
    const res = await getVendorDetails(id);
    resetLoading(VendorActions.GET_VENDOR_DETAILS);

    if (res?._status?.code === 200) {
      dispatch({
        type: VendorActions.GET_VENDOR_DETAILS,
        payload: {
          ...res.data,
          vendor: {
            ...res.data?.vendor,
            subscriptions: res.data?.vendor?.subscriptions?.map(
              (item: any, index: number) => ({ ...item, id: index + 1 })
            ),
          },
        },
      });
    }
  };

  const deleteVendor = async (vendor_id: number) => {
    setLoading(VendorActions.DELETE_VENDOR);
    const res: any = await removeVendor(vendor_id);
    resetLoading(VendorActions.DELETE_VENDOR);

    if (res?.status === 204) {
      dispatch({
        type: VendorActions.DELETE_VENDOR,
        payload: vendor_id,
      });
    }

    return res;
  };

  const resetVendor = async (vendor_id: number) => {
    setLoading(VendorActions.RESET_VENDOR);

    const res: any = await resettingVendor(vendor_id);
    resetLoading(VendorActions.RESET_VENDOR);
    if (res?.status === 200) {
      dispatch({
        type: VendorActions.RESET_VENDOR,
        payload: vendor_id,
      });
    }

    return res;
  };

  const fetchVenues = async (venueListReq: VendorListRequest) => {
    venueListReq.offset
      ? setLoading(VendorActions.GET_MORE_VENUES)
      : setLoading(VendorActions.GET_VENUES);
    const res = await getVenueList(venueListReq);
    venueListReq.offset
      ? resetLoading(VendorActions.GET_MORE_VENUES)
      : resetLoading(VendorActions.GET_VENUES);

    if (res?._status?.code === 200) {
      dispatch({
        type: VendorActions.GET_VENUES,
        payload: res.data,
      });
    }

    return res;
  };

  const deleteVenue = async (id: number) => {
    setLoading(VendorActions.DELETE_VENUE);
    const res = await removeVenue(id);
    resetLoading(VendorActions.DELETE_VENUE);

    if (res?.status === 204) {
      dispatch({
        type: VendorActions.DELETE_VENUE,
        payload: id,
      });
    }

    return res;
  };

  const fetchAdmins = async (adminReq: GetAdminRequest) => {
    adminReq.offset
      ? setLoading(VendorActions.GET_MORE_ADMINS)
      : setLoading(VendorActions.GET_ADMINS);
    const res = vendorId ? await getAdmins(adminReq) : null;
    adminReq.offset
      ? resetLoading(VendorActions.GET_MORE_ADMINS)
      : resetLoading(VendorActions.GET_ADMINS);

    if (res?._status?.code === 200) {
      dispatch({
        type: VendorActions.GET_ADMINS,
        payload: { ...res.data, offset: adminReq.offset },
      });
    }

    return res;
  };

  const addAdmin = async (addAdminReq: AddAdminRequest) => {
    setLoading(VendorActions.ADD_ADMIN);
    const res = await postAdmin(addAdminReq);
    resetLoading(VendorActions.ADD_ADMIN);

    if (res?._status?.code === 201) {
      dispatch({
        type: VendorActions.ADD_ADMIN,
        payload: res.data,
      });
    }

    return res;
  };

  const updateAdmin = async (editAdminReq: EditAdminRequest) => {
    setLoading(VendorActions.EDIT_ADMIN);
    const res = await putAdmin(editAdminReq);
    resetLoading(VendorActions.EDIT_ADMIN);

    if (res?._status?.code === 200) {
      dispatch({
        type: VendorActions.EDIT_ADMIN,
        payload: editAdminReq,
      });
    }

    return res;
  };

  const deleteAdmin = async (id: number) => {
    setLoading(VendorActions.DELETE_ADMIN);
    const res = await removeAdmin(id);
    resetLoading(VendorActions.DELETE_ADMIN);

    if (res?.status === 204) {
      dispatch({
        type: VendorActions.DELETE_ADMIN,
        payload: id,
      });
    }

    return res;
  };

  const approveVendor = async () => {
    setLoading(VendorActions.APPROVE_VEDNOR);
    const res = vendorId ? await approveVendorSerive(vendorId) : null;
    resetLoading(VendorActions.APPROVE_VEDNOR);

    if (res?._status?.code === 200) {
      dispatch({
        type: VendorActions.GET_VENDOR_DETAILS,
        payload: res.data,
      });
    }

    return res;
  };

  const addLeadNote = async (addNoteReq: any) => {
    setLoading(VendorActions.ADD_NOTE_LEAD);
    const res = await addNotes(addNoteReq);
    resetLoading(VendorActions.ADD_NOTE_LEAD);

    if (res?._status?.code === 201) {
      dispatch({
        type: VendorActions.ADD_NOTE_LEAD,
        payload: res.data,
      });
    }

    return res;
  };

  const removeNote = async (lead_id: number, note_id: number) => {
    setLoading(VendorActions.DELETE_NOTE);
    const res: any = await deleteNote(lead_id, note_id);
    resetLoading(VendorActions.DELETE_NOTE);

    if (res?.status === 204) {
      dispatch({
        type: VendorActions.DELETE_NOTE,
        payload: { lead_id, note_id },
      });
    }

    return res;
  };

  const uploadVendorCustomAgreement = async (params: any) => {
    setLoading(VendorActions.UPLOAD_AGREEMENT_CUSTOM);

    let formData: any = params;

    formData = new FormData();
    Object.keys(params).forEach((key: string) => {
      const val = params[key as keyof typeof params];
      if (val) {
        formData.append(key, key === "data" ? JSON.stringify(val) : val);
      }
    });

    const res = await postAgreement(formData);
    resetLoading(VendorActions.UPLOAD_AGREEMENT_CUSTOM);

    return res;
  };

  const selectingVendorTypeInfo = async (params: any) => {
    setSelectedVendorType(params);
  };

  const reSetvendorList = () => {
    dispatch({
      type: VendorActions.EMPTY_VENDOR_LIST,
      payload: {
        vendors: [],
        availableStatuses: [],
        _count: 0,
      },
    });
  };

  const emailAgreement = async (vendor_id: number) => {
    setLoading(VendorActions.SEND_EMAIL_AGREEMENT);
    const res = await sendEmailAgreement(vendor_id);
    resetLoading(VendorActions.SEND_EMAIL_AGREEMENT);

    return res;
  };

  return (
    <VendorContext.Provider
      value={{
        loading,
        setLoading,
        resetLoading,
        sendWelcomeEmail,
        updateVendorDetails,
        vendorList,
        addVendor,
        fetchVendors,
        deleteVendor,
        resetVendor,
        fetchVenues,
        venueList,
        deleteVenue,
        getAdmins,
        adminList,
        fetchAdmins,
        addAdmin,
        deleteAdmin,
        updateAdmin,
        fetchVendorDetail,
        vendorDetails,
        addVenue,
        fetchLeads,
        leadList,
        search,
        addLead,
        deleteLead,
        updateLead,
        sendSalesLead,
        setLead,
        approveVendor,
        queryParams,
        setQueryParams,
        addLeadNote,
        fetchVenueDetails,
        removeNote,
        setSalesKitSuccessModal,
        salesKitSuccessModal,
        setActiveTab,
        activeTab,
        fetchVendorBillingHistory,
        billingHistory,
        sendInvoice,
        updateSubscriptionStatus,
        addNoteToVendor,
        deleteVendorNote,
        fetchVendorInvoiceHistory,
        invoiceHistory,
        page,
        setPage,
        vendorLeadTab,
        setVendorLeadTab,
        leadsClickedList,
        setLeadsClickedList,
        venueQueryParams,
        setVenueQueryParams,
        venuePage,
        setVenuePage,
        adminsPage,
        setAdminsPage,
        leadsPage,
        setLeadsPage,
        adminQueryParam,
        setAdminQeuryParam,
        setVenues,
        setAdmins,
        updateVendorContext,
        uploadVendorCustomAgreement,
        fetchVendorOnbaordingStatus,
        selectedVendorType,
        selectingVendorTypeInfo,
        reSetvendorList,
        emailAgreement,
      }}
    >
      {children}
    </VendorContext.Provider>
  );
};
export default VendorProvider;
