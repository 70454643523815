import React from 'react'

const SaveIcon = () => {
    
  return (
    <svg id="Saved_Indicator" data-name="Saved Indicator" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" width="27.31" height="30.446" viewBox="0 0 27.31 30.446">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_1088" data-name="Rectangle 1088" width="26.31" height="29.446" fill="#222"/>
      </clipPath>
      <clipPath id="clip-path-2">
        <rect id="Rectangle_1088-2" data-name="Rectangle 1088" width="26.31" height="29.446" fill="none"/>
      </clipPath>
    </defs>
    <g id="Group_1352" data-name="Group 1352" transform="translate(1 1)" opacity="0.603">
      <g id="Group_1350" data-name="Group 1350" clip-path="url(#clip-path)">
        <path id="Path_25550" data-name="Path 25550" d="M26.31,8.823V28.341a1.105,1.105,0,0,1-1.1,1.1H1.1a1.1,1.1,0,0,1-1.1-1.1V1.1A1.105,1.105,0,0,1,1.1,0H17.487a1.1,1.1,0,0,1,.78.324l7.718,7.718a1.1,1.1,0,0,1,.324.78" fill="#222"/>
        <rect id="Rectangle_1081" data-name="Rectangle 1081" width="15.488" height="10.424" transform="translate(1.48)" fill="#222"/>
        <rect id="Rectangle_1082" data-name="Rectangle 1082" width="1.731" height="5.381" transform="translate(13.806 2.521)" fill="#222"/>
        <rect id="Rectangle_1083" data-name="Rectangle 1083" width="18.313" height="10.901" transform="translate(3.998 18.545)" fill="#222"/>
        <rect id="Rectangle_1084" data-name="Rectangle 1084" width="18.313" height="1.796" transform="translate(3.998 27.65)" fill="#222"/>
        <rect id="Rectangle_1085" data-name="Rectangle 1085" width="13.738" height="1.163" transform="translate(6.286 20.859)" fill="#222"/>
        <rect id="Rectangle_1086" data-name="Rectangle 1086" width="13.738" height="1.163" transform="translate(6.286 22.832)" fill="#222"/>
        <rect id="Rectangle_1087" data-name="Rectangle 1087" width="13.738" height="1.163" transform="translate(6.286 24.804)" fill="#222"/>
      </g>
    </g>
    <g id="Group_1350-2" data-name="Group 1350" clip-path="url(#clip-path-2)">
      <path id="Path_25550-2" data-name="Path 25550" d="M26.31,8.823V28.341a1.105,1.105,0,0,1-1.1,1.1H1.1a1.1,1.1,0,0,1-1.1-1.1V1.1A1.105,1.105,0,0,1,1.1,0H17.487a1.1,1.1,0,0,1,.78.324l7.718,7.718a1.1,1.1,0,0,1,.324.78" fill="#106cd0"/>
      <rect id="Rectangle_1081-2" data-name="Rectangle 1081" width="15.488" height="10.424" transform="translate(1.48)" fill="#e5e5e5"/>
      <rect id="Rectangle_1082-2" data-name="Rectangle 1082" width="1.731" height="5.381" transform="translate(13.806 2.521)" fill="#3c4b59"/>
      <rect id="Rectangle_1083-2" data-name="Rectangle 1083" width="18.313" height="10.901" transform="translate(3.998 18.545)" fill="#e5e5e5"/>
      <rect id="Rectangle_1084-2" data-name="Rectangle 1084" width="18.313" height="1.796" transform="translate(3.998 27.65)" fill="#3c4b59"/>
      <rect id="Rectangle_1085-2" data-name="Rectangle 1085" width="13.738" height="1.163" transform="translate(6.286 20.859)" fill="#3c4b59"/>
      <rect id="Rectangle_1086-2" data-name="Rectangle 1086" width="13.738" height="1.163" transform="translate(6.286 22.832)" fill="#3c4b59"/>
      <rect id="Rectangle_1087-2" data-name="Rectangle 1087" width="13.738" height="1.163" transform="translate(6.286 24.804)" fill="#3c4b59"/>
    </g>
  </svg>
  )
}

export default SaveIcon
