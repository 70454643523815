import UserProvider from "contexts/user";
import { Outlet } from "react-router-dom";

const UserRoutes = ()=>{



        
return <>
        <UserProvider>
        <Outlet />
        </UserProvider>
        </>


}
export default UserRoutes;