import { useEffect } from "react"
import { useSearchParams } from "react-router-dom";


const StripeConnectCallback = () => {
    
    let [searchParams] = useSearchParams()
    const success = searchParams.get("success")

    useEffect(() => {
        console.log("Calling callback")
        window.opener.postMessage(""+success)
    });

    return (
        <div style={{position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)'}}>
        </div>
    )
};

export default StripeConnectCallback;