import React, { useContext, useRef, useState } from "react";
import TopSellingProductListItem from "./TopSellingProductListItem";
import SuperAdminReportsDropDown from "components/reports/superAdmin/DropDowns/SuperAdminReportsDropDown";
import { VendorAdminDashboardContext } from "contexts/vendorAdminDashboard";
import { VendorAdminDashboardActions } from "contexts/reducers/vendorAdminDashboard";
import UpComingProductsSkeleton from "components/dashboard/Skeleton/UpComingProductsSkeleton";
import { Skeleton } from "components/common/skeleton";
import Top10MostSalesForTheYear from "./Top10MostSalesForTheYear";
//import Top10MostSalesForTheYear from "./Top10MostSalesForTheYear";

const topItemsDropDownOptions = [
  {
    label: "Top 10 Selling Items All Times",
    value: "TOP_10_SELLING_ITEMS_ALL_TIME",
  },
  {
    label: "Top 10 Selling Items Last 30 Days",
    value: "TOP_10_SELLING_ITEMS_LAST_30_DAYS",
  },
  {
    label: "Top 10 Most Sales For the Year",
    value: "TOP_10_MOST_SALES_FOR_THE_YEAR",
  },
];

const TopSellingProducts = ({
  fetchData,
}: {
  fetchData: () => Promise<any>;
}) => {
  const [topItemsOption, setTopItemOption] = useState<string>(
    topItemsDropDownOptions[0].value
  );
  const {
    fetchDashboardData,
    setDashboardReducer,
    dashboardQueryParams,
    dashboardData,
    loading,
  } = useContext(VendorAdminDashboardContext);
  const { data } = dashboardData;
  const { data_count } = dashboardData;

  const fetchingEventsRef = useRef<boolean>(false);

  const [scrollTopVal, setScrollTopVal] = useState(0);

  const topItemsOptionChangeHandler = async (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    setTopItemOption(e.target.value);
    const newQueryParams = { ...dashboardQueryParams, filter: e.target.value };
    setDashboardReducer(
      VendorAdminDashboardActions.SET_DASHBOARD_QUERY_PARAMS,
      newQueryParams
    );

    await fetchDashboardData({
      ...dashboardQueryParams,
      filter: e.target.value,
    }, VendorAdminDashboardActions.GET_TOP_SELLING_PRODUCTS);
  };

  const isRecordsRemaining = data.length < data_count;
  const handleScroll = async (e: any) => {
    const { scrollTop, scrollHeight } = e.target;
    setScrollTopVal(scrollTop);

    if (
      scrollTop > 0 &&
      isRecordsRemaining &&
      !fetchingEventsRef.current &&
      scrollTopVal < scrollTop
    ) {
      const scrollValue = scrollTop + 448;
      const threshold = scrollHeight - scrollHeight * 0.15;

      if (scrollValue >= threshold) {
        fetchingEventsRef.current = true;
        await fetchData();
        fetchingEventsRef.current = false;
      }
    }
  };

  const isLoading = loading[VendorAdminDashboardActions.GET_DASHBOARD_DATA];
  const topSellingProductsLoading =
    loading[VendorAdminDashboardActions.GET_TOP_SELLING_PRODUCTS];


    const loadingSkeletoToShow= ()=>{
            if(topItemsOption === "TOP_10_MOST_SALES_FOR_THE_YEAR")
            {
                return  <> 
                            <Skeleton customClass="event-skeleton" width={25} animation = {true}/> 
                            <Skeleton customClass="event-skeleton" width={25} animation = {true}/> 
                            <Skeleton customClass="event-skeleton" width={25} animation = {true}/> 
                        </>

            }else {
                return <UpComingProductsSkeleton isStatic={false} showTenRows={true} />

            }
    
    }



    const staticSkeletonToShow = ()=>{

        if(topItemsOption === "TOP_10_MOST_SALES_FOR_THE_YEAR")
        {
            return <div> 
                <Skeleton width={25} animation = {false} /> 
                </div>

        }else {
            return <UpComingProductsSkeleton isStatic={true} showTenRows={true} />

        }


    }

  return (
    <div className="mostActiveVendors">
      <div className="lastActiveEvents">
        <div className="select-container">
          <SuperAdminReportsDropDown
            value={topItemsOption}
            options={topItemsDropDownOptions}
            onChange={topItemsOptionChangeHandler}
          />
        </div>
      </div>
      <div onScroll={handleScroll} className="activeEventsItems scroll">
        <div className="superactiveEventsorders">
          {/* <Top10MostSalesForTheYear /> */}

          {isLoading || topSellingProductsLoading ? (
            // <UpComingProductsSkeleton isStatic={false} showTenRows={true} />
            loadingSkeletoToShow()
          ) : data.length < 1 ? (

            // <UpComingProductsSkeleton isStatic={true} showTenRows={true} />
            staticSkeletonToShow()
            ) : topItemsOption === "TOP_10_MOST_SALES_FOR_THE_YEAR" ? (
            data.map((data_item: any) => (
              <Top10MostSalesForTheYear dataItem={data_item} />
            ))
          ) : (
            data.map((data_item: any) => (
              <TopSellingProductListItem
                name={data_item.name}
                image={data_item.image_url}
                variant={data_item.variant}
                total_price={data_item.total_price}
              />
            ))
          )}
          {dashboardQueryParams.offset > 0 &&
            loading[VendorAdminDashboardActions.GET_MORE_SALES_DATA] && (
              <div className="productSkeleton">
                <div className="productImg">
                  <Skeleton />
                </div>
                <div className="productSkeletonDetailContainer">
                  <div className="productSkeletonDetail">
                    <Skeleton width={130} />
                    <Skeleton />
                  </div>
                  <Skeleton width={85} height={22} animation={false} />
                </div>
              </div>
            )}
          {data.length > 7 && scrollTopVal < 10 && (
            <div className="scroll-data"></div>
          )}
        </div>
      </div>
    </div>
  );
};
export default TopSellingProducts;
