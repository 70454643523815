import { TabPanel, Tabs } from "components/common/tabs";
import { FC, useContext, useEffect } from "react";
import VendorsCore from "./vendorsCore";
import Leads from "./leads";
import { VendorContext } from "contexts/vendor";

interface Props {
  fromLocation?: any;
}

const Vendors: FC<Props> = ({ fromLocation }) => {
  const { search } = useContext(VendorContext);
  const {
    vendorLeadTab,
    setVendorLeadTab,
    setPage,
    setLeadsPage,
    leadsPage,
    page,
    reSetvendorList,
    fetchVendors, 
  
    
  } = useContext(VendorContext);

  useEffect(() => {
    if (fromLocation?.from === "superDashboard") {
      setVendorLeadTab(1);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <div className="super-admin">
      <div className="super-admin_addVendorContainer">
        <Tabs
          tabs={["Vendors", "Leads"]}
          onClickTab={(selectedTab: any) => {
            setVendorLeadTab(selectedTab);
            setPage(page);
            setLeadsPage(leadsPage);
           if(selectedTab !== 0) {
            reSetvendorList();
          
        }
            if(selectedTab === 0 ){
            fetchVendors({offset: 0, limit: 15})
            }
          }}
          activeTab={vendorLeadTab}
          disbaledIndex={search ? [0, 1] : []}
        >
          <TabPanel selected={vendorLeadTab === 0}>
            <VendorsCore />
          </TabPanel>
          <TabPanel selected={vendorLeadTab === 1}>
            <Leads fromLocation={fromLocation} />
          </TabPanel>
        </Tabs>
      </div>
    </div>
  );
};

export default Vendors;
