import Button from "components/common/button";
import EditableInput from "components/common/editableInput";

import { Modal } from "components/common/modal";
import { useEffect, useState } from "react";

interface Props {
  closeModal?: Function;
  onSubmit: Function;
  name: string;
  venue?: string;
  date?: string;
  startTime?: string;
  endTime?: string;
  enableDonationRoundUp?: boolean;
  donationOrganization?: string;
  donationInfo?: string;
}

const EventDetailsModal = ({
  closeModal,
  onSubmit,
  name,
  venue,
  date,
  startTime,
  endTime,
  enableDonationRoundUp,
  donationOrganization,
  donationInfo,
}: Props) => {
  const [, setError] = useState("");

  const onClickSubmit = async () => {
    const res = await onSubmit();

    if (res?.response?.data?.message || res?.response?.data?.error) {
      setError(res?.response?.data?.message || res?.response?.data?.error);
    }
  };

  useEffect(() => {
    const keyHandler = async (e: any) => {
      if (e.keyCode === 13) {
        await onClickSubmit();
      }
    };
    window.addEventListener("keydown", keyHandler, false);
    return () => {
      window.removeEventListener("keydown", keyHandler, false);
    };
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <>
      <Modal
        setShowModal={() => closeModal && closeModal()}
        customClass={`eventDetailsModal`}
        isCancel
        closeModal={closeModal}
      >
        <div className="header">
          {/* <button onClick={onClickSubmit}>Close</button> */}
          <Button buttonName="Close" onClick={onClickSubmit}></Button>
          <p className="app-modal-title">{"Event Details"}</p>
        </div>

        <div className="detailsModalBody">
          <div className="detailModalLeft">
            <div className="">
              <EditableInput
                isLoading={false}
                name={"name"}
                isEdit={false}
                value={name}
                label={"Name"}
              />
            </div>

            <div className="">
              <EditableInput
                isLoading={false}
                name={"venue"}
                isEdit={false}
                value={venue}
                label={"Venue"}
              />
            </div>
            <div className="">
              <EditableInput
                isLoading={false}
                name={"date"}
                isEdit={false}
                value={date}
                label={"Date"}
              />
            </div>

            <div className="event-time">
              <div className="start-time">
                <EditableInput
                  isLoading={false}
                  name={"startTime"}
                  isEdit={false}
                  value={startTime}
                  label={"Start Time"}
                />
              </div>

              <div className="end-time">
                <EditableInput
                  isLoading={false}
                  name={"endTime"}
                  isEdit={false}
                  value={endTime}
                  label={"End Time"}
                />
              </div>
            </div>
          </div>

          <div className="detailsTableRight">
            <div className="">
              <EditableInput
                isLoading={false}
                name={"enableDonationRoundUp"}
                isEdit={false}
                value={enableDonationRoundUp ? "Yes" : "No"}
                label={"Enable Donation Roundup"}
              />
            </div>

            <div className="detail-group donation-organization-field">
              <EditableInput
                isLoading={false}
                name={"donationOrganization"}
                isEdit={false}
                value={donationOrganization ? donationOrganization : "Organization"}
                label={"Donation Organization"}
              />
            </div>

            <div className="detail-group donation-info-field">
              <EditableInput
                isLoading={false}
                name={"donationInfo"}
                isEdit={false}
                value={donationInfo ? donationInfo : "What is the organization about and where does the money go?"}
                label={"Donation info"}
              />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default EventDetailsModal;
