import React, { useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements
} from "@stripe/react-stripe-js";
import Button from 'components/common/button';
import { reconcilePaymentApi, updatePaymentMethodApi, fetchPaymentDetails} from "services/billing";
import ApiModal from "components/common/apiErrorModal";
import InfoModal from 'components/common/infoModal';
import config from 'config/config';

export default function CheckoutForm({fees = 0, clientSecret = '', vendorId = 0, numberOfVenues = 0, goNextStep}) {
  const stripe = useStripe();
  const elements = useElements();

  const[message, setMessage] = useState({
    text: "",
    show: false,
    card: true,
  });

  const [isLoading, setIsLoading] = useState(false);

  const[error, setError] = useState({
    message:"",
    status: false
  });

  // const retrievePaymentIntentData = async (clientSecret) => {
  //   const paymentIntentData = await stripe.retrievePaymentIntent(clientSecret);

  //   return paymentIntentData;
  // }

  const reconcilePayment = async (vendorId, amount, numberOfVenues) => {
    return reconcilePaymentApi(amount, vendorId, numberOfVenues);
  }

  const updatePaymentStatus = async (vendorId, amount, numberOfVenues, paymentMethodId, paymentType, status) => {
    return updatePaymentMethodApi(amount, vendorId, numberOfVenues, paymentType, status, paymentMethodId);
  }

  const fetchPaymentDetailsAsync = async (paymentMethodId) => {
    return await fetchPaymentDetails(paymentMethodId);
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    setIsLoading(true);

    const checkPaymentReconcile =  await reconcilePayment(vendorId, fees, numberOfVenues);
 
    if (checkPaymentReconcile) {
      if (!stripe || !elements) {
        // Stripe.js hasn't yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }
  
      console.log('config.defaults.fe_url', config.defaults.fe_url);
      const redirectUrl = config.defaults.fe_url || 'https://staging-vendor.crowdchow.app';
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        confirmParams: {
          // Make sure to change this to your payment completion page
          return_url: `${redirectUrl}/onboarding`,
        },
        redirect: "if_required"
      });
  
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Otherwise, your customer will be redirected to
      // your `return_url`. For some payment methods like iDEAL, your customer will
      // be redirected to an intermediate site first to authorize the payment, then
      // redirected to the `return_url`.
      console.log('err', error);
      console.log('payment intent', paymentIntent);
      if (error?.type === "card_error" || error?.type === "validation_error") {
        setError({
          text: error?.message || '',
          show: true,
        });
      } else if (error) {
        setError({
          text: "An unexpected error occurred.",
          show: true,
        });
      } else if (paymentIntent) {
        if (paymentIntent.status === 'succeeded') {
            const paymentMethod = await fetchPaymentDetailsAsync(paymentIntent.payment_method);

            if (paymentMethod?.type === 'card') {
                setMessage({
                    text: `The subscription fee has been successfully charged to the card ending in ${paymentMethod?.last_4 || ''}. Thank you.`,
                    show: true,
                    card: true,
                  });
            } else {
                setMessage({
                    text: `We are processing your subscription fee. Bank payments take 2-3 business days. We’ll notify you if there is an issue. Thank you.`,
                    show: true,
                    card: false,
                  });
            }
        

          await updatePaymentStatus(vendorId, fees, numberOfVenues, paymentIntent.payment_method, 'CARD', 'PAID');
        } else if (paymentIntent.status === 'processing') {
          setMessage({
            text: "Your payment is processing. It will take 2-3 business days for payment processing.",
            show: true,
          });
          await updatePaymentStatus(vendorId, fees, numberOfVenues, paymentIntent.payment_method, 'BANK', 'PROCESSING');
        }
        
      }  
    } else {
      setError({
        text: "Price has been updated. Please refresh",
        show: true,
      });
    }

    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs"
  }

  return (
    <form id="payment-form" className="billingInvoice_bank_form" onSubmit={handleSubmit}>
      {clientSecret && <>
      <PaymentElement id="payment-element" options={paymentElementOptions} onChange={() => setMessage('')} />
      <div className="billingInvoice_bank_payBtn">
        <Button
          type={"submit"}
          parentClass="roundedButton"
          buttonName={'Pay ' + fees}
          isActiveDisabled={false}
          loading={isLoading}
          loadingText={"Processing..."}
          dropShadow={false} />
      </div></>}
      {/* Show any error or success messages */}
      {error.status && (
            <ApiModal
                setIsShow={() => setError({ ...error, status: false })}
                title="Payment Processing Error"
                message={error.message} />
            )}
      {message.show && (
            <InfoModal
                title="Payment Processed"
                description={message.text}
                onSubmit={() => { setMessage({ show: false }); goNextStep(); } } 
                submitButtonName={message.card ? 'Okay': 'Got It'}/>
            )}
    </form>
  );
}