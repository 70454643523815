import BottomBar from 'components/common/bottomBar';
import TableSkeleton from 'components/common/skeleton/tableSkeleton';
import { Table, TableBody } from 'components/common/table';
import NoData from 'components/noData';
import { UserActions } from 'contexts/reducers/user';
import { ChangeEvent, Fragment, useEffect, useState } from 'react';
import Crowd from './crowd';
import CrowdHeader from './header';
import SearchInput from 'components/common/searchInput';
import { useDebouncedCallback } from 'use-debounce';
import { useNavigate } from 'react-router-dom';
import { formatPhone, removeSpecialCharsAndWhiteSpaces } from 'util/formatPhone';
import { useInfiniteScroll } from 'hooks/useInfiniteScroll';
import MoreDataSkeleton from 'components/common/moreDataSkeleton';
import { useLimit } from 'hooks/useLimit';


const Crowds = ({ loading, fetchCrowds, crowdList, vendor_id, page, setPage, queryParams, setQueryParams }: any) => {
    const navigate = useNavigate();
    const {customers, _count} = crowdList;

    const {skeletonsCount} = useLimit();

    const fetchMoreCrowds = async () => {
        const nextPage = Math.ceil(queryParams.offset/queryParams.limit)+1;
        setPage(nextPage);

        return await fetchCrowdList({...queryParams, offset: nextPage*queryParams.limit})
    }

    const { handleScroll, scrollTop } = useInfiniteScroll(fetchMoreCrowds, 560);

    const formattedCustomers = customers.map((item: any) => {
        const phoneNumber = removeSpecialCharsAndWhiteSpaces(item.phone);
        const formattedPhone = formatPhone(phoneNumber);
        return {
            ...item,
            phone: formattedPhone
        }
    })
    const [search, setSearch] = useState('');
 

    const debouncedSearch = useDebouncedCallback((search: string) => {
        setPage(0)
        setQueryParams({
            ...queryParams,
            search,
            offset: 0
        })
    }, 600)

    const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
        const {value} = e.target;
        setSearch(value);
        debouncedSearch(value);
    }

    const onClickOption = (item: any) => {
        const {id, first_name, last_name} = item;
        
        // localStorage.setItem("activeTab", `${activeTab}`);
        localStorage.setItem("customer_name", `${first_name || ''}${last_name ? ` ${last_name}` : ''}`)
        navigate(`orders/${id}`);
    }

    const fetchCrowdList = async (queryParams: any) => {
        setQueryParams(queryParams);

        const res = await fetchCrowds(vendor_id ? {...queryParams, vendor_id} : queryParams);
        return res;
    }

    useEffect(() => {
        if(customers.length){
            return;
        }

        if(search)
        fetchCrowdList(vendor_id ? {...queryParams, vendor_id} : queryParams);
        else
        fetchCrowdList(vendor_id ? {...queryParams, vendor_id, search:''} : {...queryParams, search:''});


        /* eslint-disable react-hooks/exhaustive-deps */
    },[]);
    

    const isFetchingMore = loading[UserActions.GET_MORE_CROWDS];
    const getCrowdLoading = loading[UserActions.GET_CROWDS];
    return (
        <>  
            <div className="super-admin_addVendorContainer_action">
                <div></div>
                
                <SearchInput isClear placeholder="Search" value={search} onChange={onSearch}/>
            </div>

            <div className="admin-data-table crowd-table">
                <Table>
                    <CrowdHeader />
                    <TableBody
                        onScroll={(e: any) => handleScroll(e, customers.length < _count)}
                        isShowBlur={_count > skeletonsCount && scrollTop < 10}
                    >
                        {getCrowdLoading ? (
                            <TableSkeleton height={54} count={skeletonsCount} />
                        ):(
                            formattedCustomers?.length > 0 ? (
                                formattedCustomers.map((item: any) => {
                                    const {id} = item;
                                    return (
                                        <Fragment key={id}>
                                            <Crowd
                                                data={item}
                                                nonClickableRow
                                                onClickOption={() => onClickOption(item)}
                                            />
                                        </Fragment>
                                    )
                                })
                            ):(
                                <NoData description="No Customers Yet" />
                            )
                        )}
                        <MoreDataSkeleton  
                            isShow={queryParams.offset > 0 && isFetchingMore}
                        />
                    </TableBody>
                </Table>
            </div>
            <BottomBar 
                showSearch
            />
        </>
    )
};

export default Crowds;
