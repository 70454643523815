import moment from "moment";
import React, { FC, useState } from "react";
import EventDetailsModal from "./EventDetailsModal";

interface Props {
  date: string;
  name: string;
  time: string;
  index: number;
  venue?: string;
  enableDonationRoundUp?: boolean;
  donationOrganization?: string;
  donationInfo?: string;
  endTime?: string;
}

const SingleUpcomingEvent: FC<Props> = ({
  date,
  name,
  time,
  index,
  venue,
  enableDonationRoundUp,
  donationOrganization,
  donationInfo,
  endTime,
}) => {
  const [showInfoModal, setShowInfoModal] = useState<boolean>(false);

  const formattedTime = moment(`${date} ${time}`)
    .format("h:mm A")
    .replace("M", "");
  const formttedDate = moment(date).format("D ddd");

  const sprtedDayDate = formttedDate.split(" ");
  const sDate = sprtedDayDate[0];
  const sDay = sprtedDayDate[1];

  const color = index % 3;
    const  timeArray = formattedTime.split(" ");

    console.log(timeArray);


  return (
    <>
      {" "}
      <div className="upcomingEventItem">
        <div className="eventTime">
          <div className="date-day">
            <div className="date">{sDate}</div>
            <div className="day">{sDay}</div>
          </div>
          <p className="time"><><span>{timeArray[0]}</span><span>{timeArray[1]}</span></></p>
        </div>

        <div
          onClick={() => {
            setShowInfoModal(true);
            console.log("clicking");
          }}
          className={`eventDescription ${
            color === 0
              ? "eventBgBlue"
              : color === 1
              ? "eventBgGreen"
              : color === 2
              ? "eventBgRed"
              : ""
          }`}
        >
          {name}
        </div>
      </div>
      {showInfoModal && (
        <EventDetailsModal
          onSubmit={() => setShowInfoModal(false)}
          closeModal={() => setShowInfoModal(false)}
          name={name}
          date={moment(date).format("M/D/YY")}
          venue={venue || "Norman High"}
          startTime={moment(`${date} ${time}`).format("M:hh A")}
          endTime={moment(`${date} ${endTime}`).format("M:hh A")}
          enableDonationRoundUp={enableDonationRoundUp}
          donationOrganization={donationOrganization || ""}
          donationInfo={donationInfo || ""}
        />
      )}
    </>
  );
};

export default SingleUpcomingEvent;
