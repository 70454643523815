import LeadsCore from "components/leads";
import { FC } from "react";

interface Props {
    fromLocation?: any;

}


const Leads: FC<Props> = ({fromLocation}) => {
    return (
        <LeadsCore fromLocation = {fromLocation} />
    )
};

export default Leads;
