import { TableHead, TableHeading } from "components/common/table";
import React from "react"

const CrowdHeader = () => {
    return (
        <TableHead>
            <TableHeading customClass="name">
                Name
            </TableHeading>
            <TableHeading customClass="phone">
                Phone
            </TableHeading>
            <TableHeading customClass="email">
                Email
            </TableHeading>
            <TableHeading customClass="last-order">
                Last Order
            </TableHeading>
            <TableHeading customClass="option">
                Options
            </TableHeading>
        </TableHead>
    )
};

export default CrowdHeader;
