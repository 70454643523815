import Button from "components/common/button";
import { Modal } from "components/common/modal";
import moment from "moment";
import { useContext, useState } from "react";
import { Label, Skeleton } from "components/common/skeleton";
import ApiModal from "components/common/apiErrorModal";
import Price from "components/reports/normalAdmin/price";
import RefundModal from "components/orders/refundModal";
import { ReportContext } from "contexts/report";
import { ReportActions } from "contexts/reducers/report";

const deliveryTypeMapper = {
    DELIVERY_PICKUP_STATION: 'Delivery to Pickup Station',
    PICKUP: 'Pickup at Concession'
}

export const insignificantZerosRegex = /^0+\./;

const OrderDetailsVendor = ({closeModal, itemCount}:any) => {
    const { orderDetail, updateOrderStatus, loading } = useContext(ReportContext);
    const { order } = orderDetail;

    const [error, setError] = useState('');
    const [orderData, setOrderData] = useState<any>(null!);
    const {
        order_id='', 
        card_last4='', 
        order_date='', 
        schedule_type='',
        delivery_type='', 
        items=[], 
        status='' ,
        pricing,
        id
    } = order || {};

    const onClickRefund = () => {
        setOrderData(order);
    }

    const onRefund = async () => {

        console.log({id});


        const res = await updateOrderStatus({
            order_id: id,
            status: 'REFUND'
        });
        

        if(res?._status?.code === 200){
            setOrderData(null!);
            closeModal();
        }

        const errorMessage = res?.response?.data?.message || res?.response?.data?.error;
        if(errorMessage){
            setError(errorMessage);
        }
    }

    //const fullName = localStorage.getItem("customer_name");
    const refundLoading = loading[ReportActions.UPDATE_ORDER_STATUS];
    const orderDetailsLoading = loading[ReportActions.GET_ORDER_DETAIL];
    const isCompleted = status === 'COMPLETED' && !orderDetailsLoading;
    const isTip = pricing?.tip && pricing?.tip !== "0" && pricing?.tip !== "0.00";
    const isDonation = pricing?.donation_amount && pricing?.donation_amount !== "0" && pricing?.donation_amount !== "0.00";



    return (
        <>
            <Modal
                setShowModal={() => closeModal()}
                customClass="order-detail-modal"
            >
                {/* <h2 className="app-modal-title">{fullName ? `${fullName}'s` : ''} Order</h2> */}
                <div className="orders-confirm">
                    <div className="order-body">
                        {orderDetailsLoading ? (
                            <>
                                <Skeleton height={31} />
                                <div style={{borderBottom: "1px solid #222222", marginTop: "4px"}}></div>
                            </>
                        ):(
                            <p className="order-number">Order #{order_id}</p>
                        )}
                        <div className="order-payment-time">
                            <div className="order-group">
                                {orderDetailsLoading ? (
                                    <Label width="90%" />
                                ):(
                                    <label>Payment Card Last 4</label>
                                )}
                                {orderDetailsLoading ? (
                                    <Skeleton />
                                ):(
                                    <p>{card_last4 || ''}</p>
                                )}
                            </div>
                            <div className="order-group">
                                {orderDetailsLoading ? (
                                    <Label width="90%" />
                                ):(
                                    <label>Date and Time</label>
                                )}
                                {orderDetailsLoading ? (
                                    <Skeleton />
                                ):(
                                    <p>{order_date ? moment(order_date).format("M/D/YY h:mm A") : ''}</p>
                                )}
                            </div>
                        </div>
                        <div className="order-pickup">
                            {orderDetailsLoading ? (
                                <Skeleton width="60%" />
                            ):(
                                <p>{deliveryTypeMapper[delivery_type as keyof typeof deliveryTypeMapper] || ''}</p>
                            )}
                            
                            {orderDetailsLoading ? (
                                <Skeleton width="30%" />
                            ):(
                                <p>{schedule_type || ''}</p>
                            )}
                            
                        </div>
                        <div className="items-container">
                            {orderDetailsLoading ? (
                                <>
                                    {[...new Array(itemCount)].map((_: any, index: number) => {
                                        return (
                                            <div key={index} className="order-items">
                                                <div className="item-name-container">
                                                    <div className="item-name-inner-container">
                                                        <p className="item-name">
                                                            <Skeleton />
                                                        </p>
                                                    </div>
                                                </div>
                                                <p className="item-price">
                                                    <Skeleton />
                                                </p>
                                            </div>
                                        )
                                    })}
                                </>
                                
                            ):(
                                items.map((item: any) => {
                                    const { name, price, variant, options, quantity } = item;
                                    const qtyVariantClass = variant && (quantity > 1 || quantity > "1") ? 'variant-2' : '';
                                    const simpleClass = variant || (quantity > 1 || quantity > "1") ? 'variant-1' : '';
                                    return (
                                        <div className="order-items">
                                            <div className="item-name-container">
                                                <div className="item-name-inner-container">
                                                    {(quantity > 1 || quantity > "1") && (
                                                        <span className="item-quantity">{quantity + "x"}</span>
                                                    )}
                                                    <p className={`item-name ${qtyVariantClass ? qtyVariantClass : simpleClass}`}>{name || ''}</p>
                                                    {variant && (
                                                        <span className="item-variant">{variant || ''}</span>
                                                    )}
                                                </div>
                                                {options.length > 0 && (
                                                    <div className="item-options">
                                                        {options.map((option: any, index: number) => {
                                                            const {name} = option;
                                                            return (
                                                                <span style={{marginLeft: index > 0 ? 11 : 0}}>{name}</span>
                                                            )
                                                        })}
                                                    </div>
                                                )}
                                            </div>

                                            <p className="item-price">
                                                <Price amount={price} />
                                            </p>
                                        </div>
                                    )
                                })
                            )}
                        </div>
                    </div>
                </div>
                <div className="other-details">
                    {(isDonation || isTip) && (
                        <div className="order-other-detail">
                            {orderDetailsLoading ? (
                                <Skeleton height={33}/>
                            ):(
                                <p>Donation</p>
                            )}
                                
                            <p>
                                {orderDetailsLoading ? (
                                    <Skeleton height={33}/>
                                ):(
                                    // <>{(isDonation ? pricing?.donation_amount : pricing?.tip)?.replace(insignificantZerosRegex, '.')}</>
                                    <Price amount={(isDonation ? pricing?.donation_amount : pricing?.tip)?.replace(insignificantZerosRegex, '.')} />
                                )}
                            </p>
                        </div>
                    )}
                    <div className="order-other-detail">
                        {orderDetailsLoading ? (
                            <Skeleton />
                        ):(
                            <p>Subtotal</p>
                        )}
                        
                        <p>
                            {orderDetailsLoading ? (
                                <Skeleton />
                            ):(
                                <Price amount={pricing?.sub_total?.replace(insignificantZerosRegex, '.')} />
                            )}
                        </p>
                    </div>
                    <div className="order-other-detail">
                        {orderDetailsLoading ? (
                            <Skeleton />
                        ):(
                            <p>Crowd Chow Fee</p>
                        )}
                        
                        <p>
                            {orderDetailsLoading ? (
                                <Skeleton />
                            ):(
                                <Price amount={pricing?.crowd_chow_fee?.replace(insignificantZerosRegex, '.')} />
                            )}
                        </p>
                    </div>
                    <div className="order-other-detail">
                        {orderDetailsLoading ? (
                            <Skeleton />
                        ):(
                            <p>Credit Card Fee</p>
                        )}
                        
                        <p>
                            {orderDetailsLoading ? (
                                <Skeleton />
                            ):(
                                <Price amount={pricing?.credit_card_fee?.replace(insignificantZerosRegex, '.')} />
                            )}
                        </p>
                    </div>
                    <div className="order-other-detail total">
                        {orderDetailsLoading ? (
                            <Skeleton />
                        ):(
                            <p>Total</p>
                        )}
                        
                        <p>
                            {orderDetailsLoading ? (
                                <Skeleton />
                            ):(
                                <Price amount={pricing?.total?.replace(insignificantZerosRegex, '.')} />
                            )}
                        </p>
                    </div>
                </div>
                {isCompleted && (
                    <div className="refund-order-bttn">
                        <Button buttonName="Refund Order" onClick={onClickRefund}  />
                    </div>
                )}
            </Modal>
            {orderData && (
                <RefundModal 
                    title={`Refund Order #${order?.order_id}`}
                    description={`
                        Are you sure you want to refund ${'this'} order of $${order?.pricing?.total?.replace(insignificantZerosRegex, '.')}? This
                        cannot be undone.
                    `}
                    submitButtonName="Refund"
                    closeModal={() => setOrderData(null!)}
                    onSubmit={() => onRefund()}
                    submitLoading={refundLoading}
                    isCancel
                />
            )}

            {error && (
                <ApiModal 
                    title="Refund Failed"
                    message={error}
                    setIsShow={() => setError('')}
                />
            )}
        </>
    )
}

export default OrderDetailsVendor;