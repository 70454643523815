import { Modal, ModalHeader, ModalBody, ModalFooter } from "components/common/modal";
import { useEffect, useState } from "react";
import ApiModal from "../apiErrorModal";

interface Props {
    closeModal?: Function;
    title: string;
    onSubmit: Function;
    isDisabled?: boolean;
    description: string;
    submitButtonName?: string;
    submitLoading?: boolean;
    isCancel?: boolean;
    width?: number;
    height?: number;
    customClass?: string
    cancelButtonName?: string
}

const InfoModal = ({width, submitLoading, closeModal, title, description, onSubmit, isDisabled, submitButtonName, isCancel=true, cancelButtonName}: Props) => {

    const [error, setError] = useState('');

    const onClickSubmit = async () => {
        const res = await onSubmit();

        if(res?.response?.data?.message || res?.response?.data?.error){
            setError(res?.response?.data?.message || res?.response?.data?.error);
        }
    }


    useEffect(() => {
        const keyHandler = async (e: any) => {
            if(e.keyCode === 13){
                await onClickSubmit();
            }
        }
        window.addEventListener('keydown', keyHandler, false);
        return () => {
            window.removeEventListener('keydown', keyHandler, false);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    },[]);

    return (
        <>
            <Modal
                setShowModal={() => closeModal && closeModal()}
                width={width || 342}
                customClass={`delete-modal ${error ? 'disabled' : ''}`}
            >
                <ModalHeader>
                    <p className="app-modal-title">{title || ''}</p>
                </ModalHeader>
                
                <ModalBody>
                    <div>
                        <p style={{color: '#fff'}}>
                            {description || ''}
                        </p>
                    </div>
                </ModalBody>

                <ModalFooter 
                    onCancel={isCancel ? closeModal :  undefined}
                    onSubmit={onClickSubmit}
                    cancelDisabled={isDisabled}
                    submitDisabled={isDisabled}
                    submitLoading={submitLoading}
                    submitButtonName={submitButtonName}
                    cancelButtonName={cancelButtonName}
                />
            </Modal>

            {error && (
                <ApiModal 
                    title={`${title} Failed`}
                    description={error}
                    setIsShow={() => setError('')}
                />
            )}
        </>
    )
};

export default InfoModal;
