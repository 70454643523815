import Cookies from "js-cookie";

const cookiesKey = {
    access_token: "crowd-chow-token",
    refresh_token: "refresh-token"
}

export const setAuthCookies = (data: any) => {
    Object.keys(data).forEach((key: string) => {
        const cookieKey = cookiesKey[key as keyof typeof cookiesKey];
        cookieKey
        ? Cookies.set(cookieKey, `${data[key]}`)
        : Cookies.set(key, `${data[key]}`);
    });
}