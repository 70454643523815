interface Props {
    isError?: boolean;
    width?: number;
    height?: number;
    fill?: string;
    customClass?: string;
    onClick?: (e: any) => void;
}

const CancelButton = ({onClick, isError, customClass, width=33, height=33, fill='#E94929'}: Props) => {
    return (
        <svg onClick={onClick} className={`cursor-pointer ${customClass || ''}`} width={isError ? '23' : width} height={isError ? '23' : height} viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_3552_3896)">
                <path d="M15.4971 30.8525C24.0575 30.8525 30.9971 24.1368 30.9971 15.8525C30.9971 7.56827 24.0575 0.852539 15.4971 0.852539C6.93666 0.852539 -0.00292969 7.56827 -0.00292969 15.8525C-0.00292969 24.1368 6.93666 30.8525 15.4971 30.8525Z" fill={isError ? '#106cd0' : fill}/>
            </g>
            <path d="M10.5205 20.8539L21.2015 10.1729" stroke="white" strokeWidth="3.5"/>
            <path d="M10.5202 10.1729L21.2012 20.8539" stroke="white" strokeWidth="3.5"/>
            <defs>
                <filter id="filter0_d_3552_3896" x="-0.00292969" y="0.852539" width="33" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dx="2" dy="2"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3552_3896"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3552_3896" result="shape"/>
                </filter>
            </defs>
        </svg>
    )
};

export default CancelButton;
