import EditableActions from "components/common/editableActions";
import Draggable from "react-draggable";
import CanteenIcon from "./canteenIcon";
import { useContext, useEffect, useRef, useState } from "react";
import { AuthContext } from "contexts/auth";
import Cookies from "js-cookie";
import Label from "components/common/label";
import Button from "components/common/button";
import { uploadResource } from "services/common";
import { isWithinBound, useIsInBound } from "hooks/useIsInBound";
import * as _ from 'lodash';
import { DEFAULT_POSITIONS } from "..";
import SaveIcon from "../SaveIcon";
import ImageModal from "./imageModal";

const VenueMap = ({
    venueDetails, 
    activeTab, 
    setPositions, 
    positions, 
    onReplaceMap, 
    setEdit,
    setStationCords,
    updateVenue,
    edit,
    updateLoading,
    stationCords
}:any) => {
    const { user } = useContext(AuthContext);
    const isSuperAdmin = user?.role_id === 1 || Cookies.get("role_id") === '1';
    const [success, setSuccess] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [changed, setIsChanged] = useState(false);
    const [showDiagram, setShowDiagram] = useState(false);

    const inputRef = useRef<any>(null);

    const onClickEdit = () => {
        setEdit(true);
    }

    const onClickSubmit = () => {
        setSuccess(true);
        onReplaceMap();
       
    }

    const onDragEnd = async (e: any, ui: any) => {
        const currentEl = document.getElementById(ui?.node.id);
        const mapContainer = document.getElementById("map-container");

        const isInBound = isWithinBound(currentEl, mapContainer);

        const {x, y} = currentEl?.getBoundingClientRect() as any || {};

        if(!isInBound){
            return;
        }


        
        setStationCords((prev:any) => ({
            ...prev,
            [ui?.node?.id]: {x, y}
        }));
        
        setPositions((prev:any) => ({
            ...prev,
            [`${ui?.node?.id}_formatted`]: {x: ui.x, y: ui.y}
        }))
    }

    const onDrag = (e: any, ui: any) => {
        
        setIsChanged(true);
    }

    const getStationsValues = () => {
        const stataion_1_values = venueDetails?.vendor?.map?.station_1?.split(', ');
        const stataion_2_values = venueDetails?.vendor?.map?.station_2?.split(', ');
        const stataion_3_values = venueDetails?.vendor?.map?.station_3?.split(', ');
        const stataion_4_values = venueDetails?.vendor?.map?.station_4?.split(', ');
        const concession_1_values = venueDetails?.vendor?.map?.concession_1?.split(', ');

        return {
            stataion_1_values: stataion_1_values || [],
            stataion_2_values: stataion_2_values || [],
            stataion_3_values: stataion_3_values || [],
            stataion_4_values: stataion_4_values || [],
            concession_1_values: concession_1_values || []
        }
    }

    const onClickReset = () => {
        setPositions(DEFAULT_POSITIONS);
        setIsChanged(true);
    }

    const uploadImage = async (e: any) => {
        if(e.target.files?.length > 0){
            const file = e.target.files[0];
            
            setImageLoading(true);
            const res = await uploadResource({
                file,
                image_for: 'VENUE'
            })

            if(res && res.url){
                await updateVenue({
                    map_url: res.url
                });
                setImageLoading(false);
            }else{
                setImageLoading(false);
            }
        }
    }

    const onClickDiagram = () => {
        setShowDiagram(true);
    }

    useEffect(() => {
        if(venueDetails?.vendor && activeTab===1){
            const {x=0, y=0} = document.getElementById("map-container")?.getBoundingClientRect() as any || {};
            const firstStationBound = document.getElementById("default_station_1")?.getBoundingClientRect() as any || {};
            const secondStationBound = document.getElementById("default_station_2")?.getBoundingClientRect() as any || {};
            const thirdStationBound = document.getElementById("default_station_3")?.getBoundingClientRect() as any || {};
            const forthStationBound = document.getElementById("default_station_4")?.getBoundingClientRect() as any || {};
            const concessionBound = document.getElementById("default_concession_1")?.getBoundingClientRect() as any || {};
            
            const {
                stataion_1_values,
                stataion_2_values,
                stataion_3_values,
                stataion_4_values,
                concession_1_values
            } = getStationsValues();

            const station_1_formatted = {
                x: (Number(stataion_1_values[0]) && stataion_1_values?.length > 0) ? -(firstStationBound.x-x-Number(stataion_1_values[0])) : 0, 
                y: (Number(stataion_1_values[1]) && stataion_1_values?.length > 0) ? -(firstStationBound.y-y-Number(stataion_1_values[1]))+1 : 0
            }
            const station_2_formatted = {
                x: (Number(stataion_2_values[0]) && stataion_2_values?.length > 0) ? -(secondStationBound.x-x-Number(stataion_2_values[0])) : 0, 
                y: (Number(stataion_2_values[1]) && stataion_2_values?.length > 0) ? -(secondStationBound.y-y-Number(stataion_2_values[1]))+1 : 0
            }
            const station_3_formatted = {
                x: (Number(stataion_3_values[0]) && stataion_3_values?.length > 0) ? -(thirdStationBound.x-x-Number(stataion_3_values[0])) : 0, 
                y: (Number(stataion_3_values[1]) && stataion_3_values?.length > 0) ? -(thirdStationBound.y-y-Number(stataion_3_values[1]))+1 : 0
            }
            const station_4_formatted = {
                x: (Number(stataion_4_values[0]) && stataion_4_values?.length > 0) ? -(forthStationBound.x-x-Number(stataion_4_values[0])) : 0, 
                y: (Number(stataion_4_values[1]) && stataion_4_values?.length > 0) ? -(forthStationBound.y-y-Number(stataion_4_values[1]))+1 : 0
            }
            const concession_1_formatted = {
                x: (Number(concession_1_values[0]) && concession_1_values?.length > 0) ? -(concessionBound.x-x-Number(concession_1_values[0]))-5 : 0, 
                y: (Number(concession_1_values[1]) && concession_1_values?.length > 0) ? -(concessionBound.y-y-Number(concession_1_values[1]))-14 : 0
            }

            const _positions = {
                station_1_formatted,
                stataion_1_placed: station_1_formatted.x !== 0 && station_1_formatted.y !== 0,
                station_2_formatted,
                stataion_2_placed: station_2_formatted.x !== 0 && station_2_formatted.y !== 0,
                station_3_formatted,
                stataion_3_placed: station_3_formatted.x !== 0 && station_3_formatted.y !== 0,
                station_4_formatted,
                stataion_4_placed: station_4_formatted.x !== 0 && station_4_formatted.y !== 0,
                concession_1_formatted,
                concession_1_placed: concession_1_formatted.x !== 0 && concession_1_formatted.y !== 0,
            }

            setPositions(_positions);

            const station_1_cords = {
                x: (Number(stataion_1_values[0]) && stataion_1_values?.length > 0) ? (x+Number(stataion_1_values[0])) : 0, 
                y: (Number(stataion_1_values[1]) && stataion_1_values?.length > 0) ? (y+Number(stataion_1_values[1]))+1 : 0
            }
            const station_2_cords = {
                x: (Number(stataion_2_values[0]) && stataion_2_values?.length > 0) ? (x+Number(stataion_2_values[0])) : 0, 
                y: (Number(stataion_2_values[1]) && stataion_2_values?.length > 0) ? (y+Number(stataion_2_values[1]))+1 : 0
            }
            const station_3_cords = {
                x: (Number(stataion_3_values[0]) && stataion_3_values?.length > 0) ? (x+Number(stataion_3_values[0])) : 0, 
                y: (Number(stataion_3_values[1]) && stataion_3_values?.length > 0) ? (y+Number(stataion_3_values[1]))+1 : 0
            }
            const station_4_cords = {
                x: (Number(stataion_4_values[0]) && stataion_4_values?.length > 0) ? (x+Number(stataion_4_values[0])) : 0, 
                y: (Number(stataion_4_values[1]) && stataion_4_values?.length > 0) ? (y+Number(stataion_4_values[1]))+1 : 0
            }
            const concession_1_cords = {
                x: (Number(concession_1_values[0]) && concession_1_values?.length > 0) ? (x+Number(concession_1_values[0]))-5 : 0, 
                y: (Number(concession_1_values[1]) && concession_1_values?.length > 0) ? (y+Number(concession_1_values[1]))-14 : 0
            }

            setStationCords({
                station_1:  {x:station_1_cords.x, y:station_1_cords.y},
                station_2:  {x:station_2_cords.x, y:station_2_cords.y},
                station_3:  {x:station_3_cords.x, y:station_3_cords.y},
                station_4:  {x:station_4_cords.x, y:station_4_cords.y},
                concession_1: {x:concession_1_cords.x, y:concession_1_cords.y},
            })
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[venueDetails, activeTab]);

    const {vendor} = venueDetails || {};

    const {isConcessionBound, isStationsBound} = useIsInBound(positions);
    const {concession_1_formatted: concession_default_position, ...stationsDefaultPos} = DEFAULT_POSITIONS;
    const {concession_1_formatted, ...stationsPos} = positions;

    const isStationsDefaultPosition = _.isEqual(stationsPos, stationsDefaultPos);
    const isConcessionDefaultPosition = _.isEqual(concession_1_formatted, concession_default_position);

    const isStationsPlaced = (changed && isStationsBound) //|| (changed && !isStationsBound && isStationsDefaultPosition);
    const isConcessionPlaced = (changed && isConcessionBound) //|| (changed && !isConcessionBound && isConcessionDefaultPosition);
    const isSubmitEnable = isStationsPlaced || isConcessionPlaced || (isStationsDefaultPosition && isConcessionDefaultPosition);

    useEffect(()=>{
        const sucessTimer = setTimeout(()=>{
            if(success)
            setSuccess(false);
        },3000);
 
 
        return ()=>{
            clearTimeout(sucessTimer)
        }
    }, [success]);
 

    return (
        <>
            <div className="map-diagram venue-map-container">
                
                {isSuperAdmin && (
                    <div className="upload-diagram">
                        <div className="upload-diagram-img">
                            <Label text="Diagram" inputLabel />
                            <div className="diagram-img">
                                {venueDetails?.vendor?.map?.url_diagram ? (
                                    <img 
                                        className="cursor-pointer" 
                                        onClick={onClickDiagram} 
                                        src={venueDetails.vendor.map.url_diagram} 
                                        alt="Dia"
                                    />
                                ):(
                                    <div className="no-map-img">
                                        <p className="heading">No Diagram Uploaded</p>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="diagram-card">
                            <p className="card-heading">Uploaded Diagram</p>
                            <p className="card-desc">The vendor admin uploaded this image to be used as a guide for creating the map.</p>
                        </div>
                    </div>
                )}
                <div className={`replace-map-img ${edit ? 'edit-map-container' : ''}`}>
                    <div className="replace-img">
                        <div id="map-container" className="map-img-replace">
                            {vendor?.map?.url ? (
                                <img src={vendor.map.url} alt="Diagram" />
                            ):(
                                <div className="no-map-img">
                                    <p className="heading">No Map Uploaded</p>
                                    <p className="desc">Use Upload Map Image above to select an image from your device. Then you can configure concession and Chow Station locations for the venue.</p>
                                </div>
                            )}
                            
                        </div>
                        <input type="file" ref={inputRef} style={{display: 'none'}} onChange={uploadImage} />
                        <Button 
                            buttonName={`${vendor?.map?.url ? 'Replace' : 'Upload'} Map Image`}
                            onClick={() => {
                                inputRef?.current?.click();
                            }}
                            customClass="replace-img-btn"
                            loading={imageLoading}
                            
                        />
                    </div> 
                    
                    <div className={`drag-stations ${edit ? 'edit-stations' : ''}`}>
                        <div style={{opacity: edit ? 1: 0}} className="station-card">
                            <p className="desc">Drag stations/concession to the venue map. Press Reset to start over.</p>
                        </div>
                        <div className="stations-details">
                            <p style={{opacity: edit ? 1: 0}} className="station-heading">Stations</p>
                            <div className="drag-multiple-stations">
                                <div key={activeTab} className="stations">
                                    <Draggable
                                        position={{x:0, y:0}}
                                    >
                                        <div style={{opacity: edit ? 0.4 : 0}} id="default_station_1" className="station-number">
                                            1
                                        </div>
                                    </Draggable>
                                    <Draggable
                                        position={{x:0, y:0}}
                                    >
                                        <div style={{opacity: edit ? 0.4 : 0}} id="default_station_2" className="station-number">
                                            2
                                        </div>
                                    </Draggable>
                                    <Draggable
                                        position={{x:0, y:0}}
                                    >
                                        <div style={{opacity: edit ? 0.4 : 0}} id="default_station_3" className="station-number">
                                            3
                                        </div>
                                    </Draggable>
                                    <Draggable
                                        position={{x:0, y:0}}
                                    >
                                        <div style={{opacity: edit ? 0.4 : 0}} id="default_station_4" className="station-number">
                                            4
                                        </div>
                                    </Draggable>
                                </div>

                                <div key={activeTab} className="stations">
                                    <Draggable
                                        position={positions?.station_1_formatted}
                                        onDrag={(e, ui) => edit && onDrag(e, ui)}
                                        onStop={(e, ui) => edit && onDragEnd(e, ui)}
                                    >
                                        <div style={{opacity: (edit || positions?.stataion_1_placed || isStationsBound) ? 1 : 0}} id="station_1" className="station-number">
                                            1
                                        </div>
                                    </Draggable>
                                    <Draggable
                                        position={positions?.station_2_formatted}
                                        onDrag={(e, ui) => edit && onDrag(e, ui)}
                                        onStop={(e, ui) => edit && onDragEnd(e, ui)}
                                    >
                                        <div style={{opacity: (edit || positions?.stataion_2_placed || isStationsBound) ? 1 : 0}} id="station_2" className="station-number">
                                            2
                                        </div>
                                    </Draggable>
                                    <Draggable
                                        position={positions?.station_3_formatted}
                                        onDrag={(e, ui) => edit && onDrag(e, ui)}
                                        onStop={(e, ui) => edit && onDragEnd(e, ui)}
                                    >
                                        <div style={{opacity: (edit || positions?.stataion_3_placed || isStationsBound) ? 1 : 0}} id="station_3" className="station-number">
                                            3
                                        </div>
                                    </Draggable>
                                    <Draggable
                                        position={positions?.station_4_formatted}
                                        onDrag={(e, ui) => edit && onDrag(e, ui)}
                                        onStop={(e, ui) => edit && onDragEnd(e, ui)}
                                    >
                                        <div style={{opacity: (edit || positions?.stataion_4_placed || isStationsBound) ? 1 : 0}} id="station_4" className="station-number">
                                            4
                                        </div>
                                    </Draggable>
                                </div>
                            </div>
                            <p style={{opacity: edit ? 1: 0}} className="station-heading">Concessions</p>
                            <div className="multiple-canteen">
                                <Draggable
                                    position={{x:0, y:0}}
                                >
                                    <div style={{opacity: edit ? 0.4 : 0}} id="default_concession_1" className="food-canteen">
                                        <CanteenIcon />
                                    </div>
                                </Draggable>
                                <Draggable
                                    position={{...positions?.concession_1_formatted, y: positions?.concession_1_formatted.y}}
                                    onDrag={(e, ui) => edit && onDrag(e, ui)}
                                    onStop={(e, ui) => edit && onDragEnd(e, ui)}
                                    onStart={(e: any, data: any) => {
                                        data.y += -14;
                                        data.x += -5;
                                    }}
                                >
                                    <div style={{opacity: (edit || positions?.concession_1_placed || isConcessionBound) ? 1 : 0}} id="concession_1" className="food-canteen">
                                        <CanteenIcon />
                                    </div>
                                </Draggable> 
                            </div>
                            <div style={{opacity: edit ? 1: 0}} className="rest-stations">
                                <button onClick={onClickReset} className="bttn bttn_cancel" disabled={isConcessionDefaultPosition && isStationsDefaultPosition} >Reset</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="edit-actions-bttns">
                <EditableActions
                    editIconCustomStyles={{textAlign: 'right'}}
                    editDisabled={!venueDetails?.vendor?.map?.url}
                    onClickEdit={onClickEdit}
                    onClickSubmit={onClickSubmit}
                    isEditMode={edit}
                    onClickCross={() => setEdit(false)}
                    disabled={updateLoading || imageLoading}
                    disableSubmit={!isSubmitEnable}
                />
            </div>
            {
                <div className={`save-order ${success ? "save-order-animation" : ""}`}>
                 <span>   <SaveIcon /> Saved</span>
                </div>
            }

            {showDiagram && (
                <ImageModal 
                    imgSrc={venueDetails.vendor.map.url_diagram}
                    closeModal={() => setShowDiagram(false)}
                />
            )}
        </>
    )
};

export default VenueMap;