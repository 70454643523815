import LeftArrow from './leftArrow';
import RightArrow from './rightArrow';
import Slider from "react-slick";
import { ChangeEvent, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { VendorActions } from 'contexts/reducers/vendor';
import { uploadResource } from 'services/common';
import { ImageFor } from 'interfaces/vendor';
import FoodImageLibrarySkeleton from 'components/common/skeletons/foodImageLibrarySkeleton';
import SearchInput from 'components/common/searchInput';
import { OnboardingContext } from 'contexts/onboarding';
import CrossButton from 'components/common/crossButton';
import ApiModal from 'components/common/apiErrorModal';
import ButtonLoader from 'components/common/button/loader';

const IMAGES_PER_PAGE = 18;
const ESCAPE_KEY = 27;

const FoodImageLibrary = ({closeModal, onSelectImage}: any) => {
    const { foodImageLibrary, loading } = useContext(OnboardingContext);
    const {images} = foodImageLibrary || {};

    const [error, setError] = useState('');
    const [upload, setUpload] = useState(false);
    const [uploading, setLoading] = useState(false);
    const [search, setSearch] = useState('');
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);
    const [searchSlide, setSearchSlide] = useState(false);
    const sliderRef = useRef<any>(null);

    const filteredImages = useMemo(() => {
        if(!images){
            return [];
        }
        const filteredData = images.filter((item: any) => item?.name?.toLowerCase()?.includes(search?.toLowerCase()));
        return filteredData;
    },[search, images]);
    
    const dotsCount = Math.ceil(filteredImages.length/IMAGES_PER_PAGE);

    const [noOfDots, setNoOfDots] = useState(dotsCount);

    const dots = [...new Array(noOfDots)];

    const onClickClose = () => {
        document.body.style.overflowY = 'unset';
        closeModal();
    }
    
    const onClickArrow = (page: number) => {
        sliderRef.current?.slickGoTo(page);
    }

    const onSearch= (e: ChangeEvent<HTMLInputElement>) => {
        setSearch(e.target.value);
        setActiveSlideIndex(0);
    }

    const onClickImage = (item: any) => {
        onSelectImage(item);
        onClickClose();
    }

    const onChangeImage = async (e: any) => {
        const files = e.target.files;
        if(files && files.length > 0){
            const file = files[0];

            if(file.type !== 'image/png'){
                setError('Wrong format! Only png image is allowed.');
                return;
            }

            setLoading(true);
            const res = await uploadResource({file, image_for: ImageFor.Variant});
            setLoading(false)
            if(res?.url){
                onClickImage({image_url: res.url});
            }
            
        }
    }

    useEffect(() => {
        setNoOfDots(Math.ceil(filteredImages.length/IMAGES_PER_PAGE));
    },[filteredImages])

    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        rows: 3,
        slidesPerRow: 6,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                rows: 3,
                slidesPerRow: 4,
              }
            },
            {
                breakpoint: 768,
                settings: {
                  rows: 2,
                  slidesPerRow: 3,
                }
            },
            {
                breakpoint: 600,
                settings: {
                  rows: 2,
                  slidesPerRow: 3,
                }
            },
            {
                breakpoint: 480,
                settings: {
                  rows: 2,
                  slidesPerRow: 2,
                }
            }
        ],
        beforeChange: (_: number, newIndex: number) => {
            setActiveSlideIndex(newIndex);
        },
        appendDots: (dots: any) => {
            setNoOfDots(dots.length);
            return <ul>{dots}</ul>
        }
    };

    useEffect(() => {
        document.body.style.overflowY = 'hidden';
        const handler = (e: any) => {
          if(e.keyCode === ESCAPE_KEY){
            onClickClose();
          }
        }
    
        window.addEventListener('keydown', handler);
    
        return () => {
          window.removeEventListener('keydown', handler);
          document.body.style.overflowY = 'unset';
        }
    
        /* eslint-disable react-hooks/exhaustive-deps */
      },[])

    const imagesLoading = loading.includes(VendorActions.SET_FOOD_IMAGE_LIBRARY);
    
    return (
        <>
            <div className="foodLibraryModal">
                <div className="foodLibraryModal_container">
                    <div className="foodLibraryModal_container_titleDescMob">
                        <h2>Food Image Library</h2>
                        <p>Select an image from our library or tap Upload Image to choose an image from your device.</p>
                    </div>
                    <div className="foodLibraryModal_container_header">
                        <div>
                            <div className={`uploadImg`}>
                                <input 
                                    accept='image/png' 
                                    disabled={uploading || imagesLoading} 
                                    onChange={onChangeImage} 
                                    type='file' 
                                    id='uploadImage'
                                />
                                <label htmlFor="uploadImage"
                                >
                                    {uploading ? 'Uploading...' : 'Upload Image'}
                                    {uploading && (
                                        <ButtonLoader />
                                    )}
                                </label>
                                
                            </div>
                        </div>
                        <div className="foodLibraryModal_container_header_titleDesc">
                            <h2>Food Image Library</h2>
                            <p>Select an image from our library or tap Upload Image to choose an image from your device.</p>
                        </div>
                        <div className="foodLibraryModal_container_header_searchBar">
                            <SearchInput
                                value={search}
                                onChange={onSearch}
                                parentClass='foodLibraryModal_container_header_searchBar_searchConatiner'
                                searchIconClass='searchIcon'
                            />
                            <div className="foodLibraryModal_container_header_searchBar_close">
                                <CrossButton
                                    onClick={onClickClose}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="foodLibraryModal_container_header_searchBar_close mob-screen">
                        <CrossButton
                            onClick={onClickClose}
                        />
                    </div>
                    <div key={search} className="foodLibraryModal_container_body">
                        {imagesLoading ? (
                            <div className='library-modal-skeleton'>
                                <FoodImageLibrarySkeleton 
                                    count={18}
                                />
                            </div>
                        ):(
                            <Slider ref={sliderRef} {...settings}>
                                {filteredImages.map((item: any, index: number) => {
                                    return (
                                        <div className='cursor-pointer' key={index} onClick={() => onClickImage(item)}>
                                            <div className='food-item-img'>
                                                <img style={{width: 133, borderRadius: 8}} src={item.image_url} alt='' />
                                            </div>
                                            <p>{item.name}</p>
                                        </div>
                                    )
                                })}
                            </Slider>
                        )}
                    </div>
                    <div className='foodLibraryModal_container_footer'>
                        <div>
                            <LeftArrow
                                onClick={() => onClickArrow(activeSlideIndex-1)}
                                disbaled={activeSlideIndex < 1}
                            />
                        </div>
                        {dots.map((_: any, index: number) => {
                            const isActive = index === activeSlideIndex
                            return (
                                <div>
                                    <svg onClick={() => onClickArrow(index)} className='cursor-pointer' xmlns="http://www.w3.org/2000/svg" width="31" height="31" viewBox="0 0 31 31" fill="none">
                                        <g filter="url(#filter0_d_3149_1550)">
                                            <path d="M14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49187 22.5081 0 14.5 0C6.49187 0 0 6.49187 0 14.5C0 22.5081 6.49187 29 14.5 29Z" fill={isActive ? '#27C1C9': '#868383'}/>
                                        </g>
                                        <defs>
                                            <filter id="filter0_d_3149_1550" x="0" y="0" width="31" height="31" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                                <feOffset dx="2" dy="2"/>
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3149_1550"/>
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3149_1550" result="shape"/>
                                            </filter>
                                        </defs>
                                    </svg>
                                </div>
                            )
                        })}
                        <div>
                            <RightArrow 
                                onClick={() => onClickArrow(activeSlideIndex+1)}
                                disbaled={activeSlideIndex >= (dots.length-1)}
                            />
                        </div>
                    </div>
                    <div className={`foodLibraryModal_container_header mob-screen ${searchSlide ? 'openSearchBar' : ''}`}>
                        <div>
                            <div onClick={() => setUpload(true)} className={`uploadImg ${upload ? 'uploadImg_open' : ''}`}>
                                <input disabled={uploading || imagesLoading} onChange={onChangeImage} type='file' id='uploadImage' />
                                <label htmlFor="uploadImage">{uploading ? 'Uploading...' : 'Upload Image'}</label>
                                <label className="addVendor-btn-mob" htmlFor="uploadImage">
                                    <div className="plus">
                                        <span></span>
                                        <span></span>
                                    </div>
                                </label>
                            </div>
                        </div>
                        <div>
                            <svg onClick={() => setSearchSlide(true)} xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                                <circle cx="27" cy="27" r="27" fill={searchSlide ? '#ffffff' : '#FDC13D'} />
                                <mask id="mask0_3906_1281" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="15" y="15" width="24" height="24">
                                    <path d="M39 15H15V39H39V15Z" fill="white"/>
                                </mask>
                                <g mask="url(#mask0_3906_1281)">
                                    <path d="M38.2068 36.6759L30.9418 29.4109C32.2925 27.6715 32.9296 25.4827 32.7232 23.2901C32.5168 21.0975 31.4825 19.066 29.831 17.6091C28.1794 16.1523 26.0347 15.3797 23.8335 15.4486C21.6323 15.5175 19.5401 16.4227 17.9828 17.9799C16.4256 19.5372 15.5204 21.6294 15.4515 23.8306C15.3826 26.0318 16.1552 28.1765 17.6121 29.828C19.0689 31.4796 21.1004 32.5139 23.293 32.7202C25.4856 32.9266 27.6744 32.2896 29.4138 30.9389L36.6758 38.2069C36.8789 38.4099 37.1542 38.524 37.4413 38.524C37.7284 38.524 38.0038 38.4099 38.2068 38.2069C38.4099 38.0039 38.5239 37.7285 38.5239 37.4414C38.5239 37.1543 38.4099 36.8789 38.2068 36.6759ZM24.1188 30.6009C22.8368 30.6009 21.5836 30.2207 20.5176 29.5085C19.4517 28.7962 18.6208 27.7839 18.1302 26.5995C17.6396 25.415 17.5113 24.1117 17.7614 22.8543C18.0115 21.5969 18.6288 20.442 19.5354 19.5354C20.4419 18.6289 21.5969 18.0116 22.8543 17.7614C24.1116 17.5113 25.415 17.6397 26.5994 18.1303C27.7838 18.6209 28.7962 19.4517 29.5084 20.5177C30.2207 21.5837 30.6008 22.8369 30.6008 24.1189C30.599 25.8375 29.9155 27.4851 28.7002 28.7003C27.485 29.9155 25.8374 30.599 24.1188 30.6009Z"fill={searchSlide ? '#0075C9' : "#5F5F5F"} />
                                </g>
                            </svg>
                        </div>
                        <div className={`${searchSlide ? 'searchSlide' : ''} foodLibraryModal_container_header_searchBar`}>
                            <SearchInput
                                value={search}
                                onChange={onSearch}
                                parentClass='foodLibraryModal_container_header_searchBar_searchConatiner'
                                searchIconClass='searchIcon'
                            />
                        </div>
                    </div>
                    {searchSlide && (
                        <div className='slideSearchBar-overlay' onClick={() => setSearchSlide(false)}></div>
                    )}
                </div>
            </div>
            <div className='modalOverlay'></div>

            {error && (
                <ApiModal 
                    setIsShow={() => setError('')}
                    message={error}
                    title='Upload Image Failed'

                />
            )}
        </>
    )
}

export default FoodImageLibrary;