import {useState,useContext,useEffect} from "react";
import { AuthContext } from "contexts/auth";
import blueTick from 'assets/images/blue-tick.png'
import { connectStripe, disconnectStripe, saveStripeAccount} from 'services/connect-stripe';
import Cookies from "js-cookie";
import ApiModal from "components/common/apiErrorModal";
import { CHECKLIST } from "interfaces/vendor";
import { OnboardingContext } from "contexts/onboarding";
import Button from "components/common/button";
import stripLogo from "assets/images/stripe_icon.svg";

interface Props {
    loading: string[];
    selectedStep: string;
    setSelectedStep: Function;
    stripeConnect: any;
    markStripeConnected: Function;
}

const ConnectStripe = ({loading, selectedStep,setSelectedStep,stripeConnect, markStripeConnected}: Props) => {
    const {user} = useContext(AuthContext)
    const {fetchonboardingDetails, vendorOnboardingDetails} = useContext(OnboardingContext)
    
    const [vendorId,setVendorId] = useState<number>(0)
    const[isDisabled, setIsDisabled] = useState<boolean>(false);
    const[isConnectStripeDisabled, setIsConnectStripeDisabled] = useState<boolean>(false);
    const[isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
    const[isLoading, setIsLoading] = useState<boolean>(false);
    const[error, setError] = useState<any>({
        message:"",
        status: false
    });

    useEffect(() => {
        const userVendorIdString: string | undefined | number = user?.vendor_id || Cookies.get("vendor_id");
        let vendor_id: number;
        if (typeof userVendorIdString === "string") {
            vendor_id = parseInt(userVendorIdString, 10);
        } else if (typeof userVendorIdString === "number") {
            vendor_id = userVendorIdString;
        } else {
            vendor_id = 0;
        }
        setVendorId(vendor_id);
    },[user])

    const getOnboardingDetails= async()=> {
        console.log("Getting onboarding details...")
            const res =await fetchonboardingDetails();
            if (!res) {
                setError({
                    message: "Something went wrong! Please try again.",
                    status: true
                })
                return;
            }
        setIsDisabled((vendorOnboardingDetails?.connected_account_stripe != null) ? vendorOnboardingDetails?.connected_account_stripe.connected : false)
        setIsSaveDisabled((vendorOnboardingDetails?.connected_account_stripe != null) ? !vendorOnboardingDetails?.connected_account_stripe.connected : true)
    }

   useEffect(() => {
        setIsDisabled(stripeConnect?.connected)
        setIsSaveDisabled(!stripeConnect?.connected)
    },[stripeConnect])

    const openStripeURLInWindow=(url: string)=> {
        const handleTabClose = () => {
            setIsConnectStripeDisabled(false)
            popup?.removeEventListener("unload", handleTabClose);
        }
        const handleCallbackMessage = async (message: MessageEvent) => {
            window.removeEventListener("message", handleCallbackMessage)
            popup?.close();
            if (message.data === "true") {
                await getOnboardingDetails()
                // setRefresh(!refresh);
            } else {
                setError({
                    message: "Cannot connect stripe account. Please try again.",
                    status: true
                })
            }
        }
        const popup = window.open(url, "_blank");
        popup?.addEventListener("unload", handleTabClose);
        window.addEventListener("message", handleCallbackMessage);
    } 

    const onDisconnectStripe = async () => {
        if(vendorId){
            setIsConnectStripeDisabled(true)
            setIsSaveDisabled(true)
            const res = await disconnectStripe({vendor_id:vendorId} );
            if(!res?.vendor?.stripe_connected){
                setIsDisabled(res?.vendor?.stripe_connected);
                setIsConnectStripeDisabled(false)
            } else {
                setIsSaveDisabled(false)
                setIsConnectStripeDisabled(false)
                setError({
                    message: "Cannot disconnect stripe account. Please try again.",
                    status: true
                })
            }
            
        }
    }

    const onConnectStripe = async () => {        
        if(vendorId){
            setIsConnectStripeDisabled(true)
            const res = await connectStripe({vendor_id:vendorId} );
            if(res){
                setTimeout(() => {
                    openStripeURLInWindow(res["url"])
                });
            }
        }
    }

    const onSave = async () => {
        if(vendorId){
            setIsSaveDisabled(true)
            setIsLoading(true);
            const res = await saveStripeAccount({vendor_id:vendorId} );
            if(res?.onboarding_step === "VENUE_SETUP"){
                markStripeConnected();
                setSelectedStep(CHECKLIST.Venues);
            }
            else{
                setIsSaveDisabled(false)
                setError({
                    message: "Cannot save and proceed.",
                    status: true
                })
            }
            setIsLoading(false);
        }
    }

    return (
        <div className="connectStripe">
            <p className="connectStripe_title">Connect Stripe Account</p>
            <p className="connectStripe_desc">Crowd Chow requires a connected Stripe account to accept payments from customers. All sales and donations are deposited to the connected Stripe account.</p>
            <p className="connectStripe_desc">Click the button below to open the Stripe website (in a new window) to connect your account to Crowd Chow. If you do not have a Stripe account, you can create one.</p>
            <div className="stripeOption">
                <div className="stripeOption_dottedBorder">
                    <div className="stripe-logo">
                        <img src={stripLogo} alt="Stripe Logo" />
                    </div>
                    <div className="stripOption_connection">
                        <div>
                            <div className="stripeOption_labelView">
                                <p className="stripeOption_label">Connected Email</p>
                            </div>
                            {!isDisabled &&(
                                <div className="stripeOption_instructionView">
                                    <p className="stripeOption_normalDesc">Click <i className="stripeOption_italicDesc">Connect Stripe</i> below to open Stripe portal.</p>
                                </div>
                                )
                            }
                        </div>
                        {isDisabled &&(
                            <div className="stripeOption_instructionView">
                                <p className="stripeOption_normalDesc">{vendorOnboardingDetails?.connected_account_stripe?.email}</p>
                                <img className="stripeOption_blueTick" src={blueTick} alt='Blue Tick' />
                            </div>
                            )}
                        <div className="stripeOption_connectStripeBtn">
                            <Button
                                buttonName={!isDisabled ?"Connect Stripe":"Disconnect Stripe"}
                                customClass={!isDisabled ?"bttn":"cancel"}
                                disabled={isConnectStripeDisabled}
                                onClick={!isDisabled ? onConnectStripe: onDisconnectStripe}
                            />
                                
                        </div>
                    </div>
                </div>
                <div className="stripeOption_saveBtn">
                    <Button 
                        disabled={isSaveDisabled}
                        type="submit"
                        onClick={onSave}
                        buttonName="Save and Proceed"
                        loading={isLoading}
                        loadingText="Saving..."
                    />
                        
                </div>
            </div>
            {error.status && (
                <ApiModal 
                    setIsShow={() => setError({...error, status: false})}
                    title="Stripe Connection Error"
                    message={error.message}
                />
            )}          
        </div>
    )

}

export default ConnectStripe