import { FC, useState } from 'react'
import SuperAdminReportsDropDown from '../DropDowns/SuperAdminReportsDropDown';
import HeaderSkeleton from './HeaderSkeleton';
import EventLabel from 'components/reports/common/eventLabel';




interface EventsData {
  avg_sales: string;
  collected_fees: string;
  count: number;
  donations: string;
  total_sales: string;
}

interface Props {
  events_data?: EventsData;
  isPrint?: boolean
  isLoading?: boolean
  offSet? : number
}


const enum SaleOptions {
  AVG_SALE = 'AVG_SALE',
  DONATIONS = 'DONATIONS'
}
const SaleOptionsMapper = {
  AVG_SALE: 'Avg. Sale',
  DONATIONS: 'Donations'
}

const EventsHeader:FC<Props> = ({events_data, isPrint, isLoading, offSet}) => {
  const [saleOrDonations, setSaleOrDonations] = useState<string>(SaleOptions.AVG_SALE);

  const val = saleOrDonations === SaleOptions.AVG_SALE ? events_data?.avg_sales : events_data?.donations;

  return (
  
    <div className="superEventsReportsHeader">
        {isLoading && offSet === 0 ? <HeaderSkeleton /> : (
            <EventLabel 
            value={events_data?.count || 0}
            label='Events'
            customClassParent='events'
            />
        )}

        {isLoading && offSet === 0 ? <HeaderSkeleton /> : (
            <EventLabel 
            value={events_data?.total_sales}
            label='Total Sales'
            customClassParent='events'
            />
        )}
        {isLoading && offSet === 0 ? <HeaderSkeleton /> : (
            <>
            {isPrint ? (
                <EventLabel 
                value={val}
                label={SaleOptionsMapper[saleOrDonations as keyof typeof SaleOptionsMapper]}
                customClassParent='avg-sale'
                />
            ):(
                <EventLabel 
                value={val}
                customClassParent='avg-sale'
                >
                <SuperAdminReportsDropDown
                    value={saleOrDonations}
                    options={[{label: 'Avg. Sale', value: 'AVG_SALE'},{label: 'Donations', value: 'DONATIONS'}]}
                    onChange={(e)=> setSaleOrDonations(e.target.value)}
                    disabled={false} 
                />
                </EventLabel>
            )}
            </>
        )}
        { isLoading && offSet === 0 ? <HeaderSkeleton /> : (
            <EventLabel 
            value={events_data?.collected_fees}
            label="Collected Fees"
            customClassParent='fees'
            />
        )}
    </div>
 
  )
}

export default EventsHeader
