const PromotionalCard = ({children, isEmbeds}: {children: React.ReactNode, isEmbeds?: boolean}) => {
    return (
        <>
            <div className={`signAge-card ${isEmbeds ? 'embed' : ''}`}>
                {children}
            </div>
        </>
    )
};

export default PromotionalCard;
