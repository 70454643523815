import Actions from "components/vendorDetails/sectionHeader/actions";

interface Props {
    isEditMode?: boolean;
    onClickCross?: Function;
    onClickSubmit?: Function;
    activeDisabled?: boolean;
    disabled?: boolean;
    editDisabled?: boolean;
    onClickEdit?: Function;
    editIconCustomStyles?: any;
    disableSubmit?: boolean;
    setSuccess?:Function
}

const EditableActions = ({disableSubmit, editIconCustomStyles, onClickEdit, isEditMode, onClickCross, onClickSubmit, activeDisabled, disabled, editDisabled, setSuccess}: Props) => {
    return (
        <>
            {isEditMode ? (
                <Actions
                    onClickCross={onClickCross}
                    onClickSubmit={onClickSubmit}
                    submitDisabled={activeDisabled}
                    disableSubmit={disableSubmit}
                    isLoading={disabled}
                    setSuccess = {setSuccess}
                />
            ):(
                <div style={{...(editIconCustomStyles && editIconCustomStyles)}} onClick={(e: any) => !disabled && !editDisabled && onClickEdit && onClickEdit(e)} className={`${(disabled || editDisabled) ? 'disabled' : 'cursor-pointer'} edit-icon`}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                        <path d="M19.9503 5.29162L26.4353 11.7736L10.0243 28.1806L3.54632 21.6986L19.9503 5.29162ZM31.3503 3.72862L28.4603 0.837625C27.9225 0.301226 27.1939 0 26.4343 0C25.6747 0 24.9461 0.301226 24.4083 0.837625L21.6353 3.61062L28.1173 10.0926L31.3473 6.86263C31.5542 6.65743 31.7184 6.41336 31.8306 6.14445C31.9428 5.87555 32.0006 5.58711 32.0009 5.29575C32.0012 5.00438 31.9439 4.71584 31.8322 4.44672C31.7206 4.1776 31.5568 3.93421 31.3503 3.72862ZM0.154322 30.8106C0.127148 30.9328 0.131494 31.0599 0.166952 31.1799C0.202411 31.3 0.267816 31.409 0.357018 31.4968C0.44622 31.5846 0.556287 31.6483 0.67687 31.6818C0.797453 31.7154 0.924589 31.7177 1.04632 31.6886L8.26932 29.9376L1.79132 23.4546L0.154322 30.8106Z" fill="white"/>
                    </svg>
                </div>
            )}
            
        </>
    )
};

export default EditableActions;
