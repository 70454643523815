import SuperStatsHeader from "./SuperStatsHeader";
import SuperDashboardBody from "./SuperDashboardBody";
import { useContext, useEffect } from "react";
import { SuperAdminDashboardContex } from "contexts/superAdminDashboard";
import HeaderSkeleton from "../Skeleton/HeaderSkeleton";
import { Skeleton } from "components/common/skeleton";
import { superAdminDashboardActions } from "contexts/reducers/superAdminDashboard";
import { useNavigate } from "react-router-dom";

const SuperAdminDashboard = () => {
  const { fetchDashboardData, dashboardQueryParams, dashboardData, loading } =
    useContext(SuperAdminDashboardContex);
  const { new_leads } = dashboardData;
  const isLoading = loading[superAdminDashboardActions.GET_DASHBOARD_DATA];

  const navigate = useNavigate();

  const fetchData = async () => {
    await fetchDashboardData(dashboardQueryParams, superAdminDashboardActions.GET_DASHBOARD_DATA);
  }

  useEffect(() => {
    fetchData();

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);


  return (
    <div className="dashboard">
      <h2 className="dashboard_pageHeading">Dashboard</h2>
      {isLoading ? (
        <div className="newLeadSkeleton">
          {" "}
          <Skeleton width={200} height={50} />{" "}
        </div>
      ) : (
        !isLoading &&
        new_leads ? (
          <button onClick={() =>
            navigate("/vendors", { state: { from: "superDashboard" } })
          } className="superDashboardBtn">
            <span
              
              className="livenowLink"
            >
              {new_leads} New Lead{new_leads > 1 ? "s" : ""}
            </span>
          </button>
        ) : <></>
      )}

      {isLoading ? <HeaderSkeleton /> : <SuperStatsHeader />}

      <SuperDashboardBody />
    </div>
  );
};

export default SuperAdminDashboard;
