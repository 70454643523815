import { ReactNode } from 'react';
import {DropdownIndicator} from './svg'

import Select, { components, FormatOptionLabelMeta, GroupBase, StylesConfig } from 'react-select';

const DefaultSingleValue = ({ children, ...props }: any) => (
    <components.SingleValue {...props}>{children}</components.SingleValue>
);

const selectStyles: StylesConfig<any, false> = {
  control: (provided) => ({
    ...provided,
    minWidth: 240,
    margin: 8,
  }),
  menu: () => ({ boxShadow: 'inset 0 1px 0 rgba(0, 0, 0, 0.1)' }),
};

interface IProps {
    options: any;
    onChange: (value: any) => void;
    formatOptionLabel?: (
      data: any,
      formatOptionLabelMeta: FormatOptionLabelMeta<any>
    ) => ReactNode;
    placeholder: string;
    formatGroupLabel?: (group: GroupBase<any>) => ReactNode;
    value?: any;
    SingleValue?: any;
    onOpen?: any
    onClose?:any
    onTextChange?: any
    defaultValue?: any;
    setIsOpen: Function;
    isOpen: boolean;
  }

export const SearchableSelect = ({
    options,
    onChange,
    formatOptionLabel,
    placeholder,
    formatGroupLabel,
    SingleValue,
    isOpen,
    setIsOpen,
    value,
    onOpen,
    onClose,
    onTextChange,
    defaultValue
  }: IProps) => {
//   const [isOpen, setIsOpen] = useState(false);
//   const [value, setValue] = useState<any | null>();

  return (
    <Dropdown
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      target={
        <div onClick={() => setIsOpen(true)} className={`${false ? 'disabled' : 'cursor-pointer'} event-dropDown`}>
            <p className="selected-option">{value?.label || (placeholder || 'Select Event')}</p>
            <span className="downArrow">
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
                    <path d="M1.3418 0.773438L7.7938 7.22544L14.2418 0.773438" stroke="#303030" stroke-width="1.5" stroke-linecap="round"/>
                </svg>
            </span>
        </div>
      }
    >
      <Select
        autoFocus
        formatOptionLabel={formatOptionLabel}
        formatGroupLabel={formatGroupLabel}
        backspaceRemovesValue={false}
        classNamePrefix="react__select"
        components={{ 
            DropdownIndicator, 
            IndicatorSeparator: null,
            SingleValue: ({ children, ...props }) =>
            SingleValue ? (
                <SingleValue {...props} />
            ) : (
                <DefaultSingleValue {...props}>{children}</DefaultSingleValue>
            ),
        }}
        controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        menuIsOpen
        onChange={(newValue) => {
        //   setValue(newValue);
          setIsOpen(false);
          onChange(newValue)
        }}
        options={options}
        placeholder=""
        styles={selectStyles}
        tabSelectsValue={false}
        value={value}
        onInputChange={onTextChange}
        defaultValue={defaultValue}
      />
    </Dropdown>
  );
};

// styled components

const Menu = (props: JSX.IntrinsicElements['div']) => {
  const shadow = 'hsla(218, 50%, 10%, 0.1)';
  return (
    <div
      style={{
        backgroundColor: 'white',
        borderRadius: 4,
        boxShadow: `0 0 0 1px ${shadow}, 0 4px 11px ${shadow}`,
        marginTop: 8,
        position: 'absolute',
        zIndex: 2,
        width: '100%'
      }}
      {...props}
    />
  );
};
const Blanket = (props: JSX.IntrinsicElements['div']) => (
  <div
    style={{
      bottom: 0,
      left: 0,
      top: 0,
      right: 0,
      position: 'fixed',
      zIndex: 1,
    }}
    {...props}
  />
);
const Dropdown = ({
  children,
  isOpen,
  target,
  onClose,
}: {
  children?: ReactNode;
  readonly isOpen: boolean;
  readonly target: ReactNode;
  readonly onClose: () => void;
}) => (
  <div style={{ position: 'relative' }}>
    {target}
    {isOpen ? <Menu>{children}</Menu> : null}
    {isOpen ? <Blanket onClick={onClose} /> : null}
  </div>
);


