import CheckMarkButton from "components/common/checkMarkButton";
import CrossButton from "components/common/crossButton";
import { Modal, ModalBody, ModalHeader } from "components/common/modal";
import { useDebounceEffect } from "hooks/useDebounceEffect";
import { useEffect, useRef, useState } from "react";
import ReactCrop, { PixelCrop, type Crop } from 'react-image-crop'
import 'react-image-crop/src/ReactCrop.scss'
import { canvasPreview } from "util/cropImage";

const CropImageModal = ({closeModal, image, onCrop, fileType, loading}: any) => {
    const [crop, setCrop] = useState<Crop>({
      unit: 'px', // Can be 'px' or '%'
      x: 25,
      y: 25,
      width: 600,
      height: 600
    });
    const imgRef = useRef<any>(null);
    const previewCanvasRef = useRef<any>(document.createElement('canvas'));
    const blobUrlRef = useRef('');
    
    const [zoom,] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<any>({
      unit: 'px',
      x: 25,
      y: 25,
      width: 600,
      height: 600
    });
    // const [croppedImage, setCroppedImage] = useState<any>(null!);

    const onCropComplete = (crop: PixelCrop) => {
      setCroppedAreaPixels(crop);
    }

    const showCroppedImage = async () => {
      const currImage = imgRef.current
      const previewCanvas = previewCanvasRef.current
      
      if (!currImage || !previewCanvas || !croppedAreaPixels) {
        throw new Error('Crop canvas does not exist')
      }
    
      // This will size relative to the uploaded image
      // size. If you want to size according to what they
      // are looking at on screen, remove scaleX + scaleY
      const scaleX = 1 //currImage.naturalWidth / currImage.width
      const scaleY = 1 //currImage.naturalHeight / currImage.height
    
      const offscreen:any = new OffscreenCanvas(
        croppedAreaPixels.width * scaleX,
        croppedAreaPixels.height * scaleY,
      )
      const ctx:any = offscreen.getContext('2d')
      if (!ctx) {
        throw new Error('No 2d context')
      }
    
      ctx.drawImage(
        previewCanvas,
        0,
        0,
        previewCanvas.width,
        previewCanvas.height,
        0,
        0,
        offscreen.width,
        offscreen.height,
      )
        // You might want { type: "image/jpeg", quality: <0 to 1> } to
        // reduce image size

      const blob = await offscreen.convertToBlob({
        type: fileType,
        quality: 0.7
      });
  
      if (blobUrlRef.current) {
        URL.revokeObjectURL(blobUrlRef.current)
      }
      blobUrlRef.current = URL.createObjectURL(blob);
      
      onCrop(blob);
    }

    useDebounceEffect(
      async () => {
        if (
          croppedAreaPixels?.width &&
          croppedAreaPixels?.height &&
          imgRef.current &&
          previewCanvasRef.current
        ) {
          // We use canvasPreview as it's much faster than imgPreview.
          canvasPreview(
            imgRef.current,
            previewCanvasRef.current,
            croppedAreaPixels,
            zoom,
          )
        }
      },
      100,
      [croppedAreaPixels, zoom],
    )

    useEffect(() => {
      const selectionEl = document.querySelector(".ReactCrop__crop-selection") as any;
      if(selectionEl){
        selectionEl?.click();
      }
      
    },[])
    
    return (
        <Modal
            setShowModal={closeModal}
            width={1000}
            height={835}
            customStyles={{
                maxWidth: 'unset'
            }}
            customClass="crop-image-modal"
        >
            <ModalHeader
              customClass="header"
            >
              <div className="modal-actions">
                <CrossButton 
                  onClick={closeModal}
                  disabled={loading}
                />
                <CheckMarkButton 
                  onClick={showCroppedImage}
                  disabled={loading}
                  width={47}
                  height={47}
                />
              </div>
              <p className="app-modal-title">Crop Image</p>
            </ModalHeader>
               
            <ModalBody>
                <ReactCrop 
                    crop={crop} 
                    onChange={(c: any, percentageCrop: any) => {console.log(c); setCrop(c)}}
                    onComplete={onCropComplete}
                    locked
                    className="reactCrop"
                >
                  
                  <img alt="Crop Img" style={{width: "auto", height: "auto", maxWidth: "unset"}} ref={imgRef} src={image} />
                </ReactCrop>
            </ModalBody>

            {/* <ModalFooter
                onCancel={closeModal}
                onSubmit={showCroppedImage}
                submitButtonName="Crop and Upload"
                submitLoading={loading}
            /> */}
        </Modal>
    )
};

export default CropImageModal;