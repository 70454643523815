import { SearchableSelect } from "components/common/customReactSelect/customReactSelect";
import Label from "components/common/label";
import { VendorContext } from "contexts/vendor";
import { useContext, useState } from "react";
import { useDebouncedCallback } from "use-debounce";

const VendorDropdown = ({ onSelectVendor, vendorList, setSelectedVendor, selectedVendor }: any) => {
 
  const {fetchVendors, } = useContext(VendorContext)
  const { vendors } = vendorList;
  //const [defaultValue, setDefaultValue] = useState({label: "All Vendors", value: 0})
  


  
  const [vendorOpen, setVendorOpen] = useState(false);  

    const allVendorObject = {name: "All vendors" , id: 0};
  
  const onClickVendor = (item: any) => {
    console.log(item);
    const selectedVend = vendors?.find((vend: any) => vend?.id === item?.value);

    if(selectedVend)
        {
    setSelectedVendor(selectedVend);
    onSelectVendor(selectedVend);
    }
    else {
        setSelectedVendor(allVendorObject);
        onSelectVendor(allVendorObject);

    }




  };

//   const dropdownToggle = () => {
//     setVendorOpen((prev: boolean) => !prev);
    
//   };

  // console.log('selectedVendor',selectedVendor);


  const onOpen = ()=>{
  //  setSelectedVendor(null!)
   
  
  }


//   const placeHolderText = vendorOpen ? "" : "Select Vendor";
    const placeHolderText = "Select Vendor";

 // const defaultValue = vendorOpen ? "" : {label: "All Vendors", value: 0};
  
  
  const debouncedSearch = useDebouncedCallback((value: string) => {
    fetchVendors({search: value})
    },100)


  const onTextChange = (val: any)=>{
      debouncedSearch(val);
    

  }






  return (
    <div className="custom-dropdown">
      <Label text="Vendor" inputLabel />

      <div
        className={`custom-dropdown-searchable ${vendorOpen ? "report-vendor-dropdown" : ""}`}
      >
        <SearchableSelect
          placeholder={placeHolderText}
        //   value={
        //     selectedVendor
        //       ? { label: selectedVendor.name, value: selectedVendor.id }
        //       : null
        //   }
          onChange={(value: any) => onClickVendor(value)}
          options={[{label: "All Vendors", value: 0},...vendors.map((vendor: any) => ({
            label: vendor.name,
            value: vendor.id,
          }))]}
          isOpen={vendorOpen}
          setIsOpen={setVendorOpen}
          onOpen={onOpen}
          onTextChange={onTextChange}
        //   defaultValue={defaultValue}
          value={selectedVendor ? {label: selectedVendor.name, value: selectedVendor.id} : null}

       />
      </div>
      {vendorOpen && (
        <div className="overlay" onClick={() => setVendorOpen(false)}></div>
      )}
    </div>
  );
};

export default VendorDropdown;
// function useMeme(arg0: () => void) {
//     throw new Error("Function not implemented.");
// }

