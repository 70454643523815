const checkDisability = (fields: any, errors: any, NOT_EMPTY_FIELDS?: any) => {
    let newObj = fields ? {...fields} : {};
    if(NOT_EMPTY_FIELDS?.length > 0){
        Object.keys(newObj).forEach((key: string) => {
            const isExists = NOT_EMPTY_FIELDS.includes(key);
            if(isExists){
                delete newObj[key as keyof typeof newObj]
            }
        });
    }
   
    const errorsMapper = Object.keys(errors || {}).length > 0 || 
    Object
    .keys(newObj)
    .some((key: string) => {
        const isEmpty = !newObj[key as keyof typeof newObj];
        return isEmpty;
    });

    return errorsMapper;
}

export const useIsDisabled = (fields: any, errors: any, NOT_EMPTY_FIELDS?: any) => {
    return checkDisability(fields, errors, NOT_EMPTY_FIELDS)
}

export const useAllDisabled = (fields: any, errors: any, NOT_EMPTY_FIELDS?: any) => {
    return Object.keys(fields || {}).some((form: any) => {
        const disabled = checkDisability(fields[form], errors[form], NOT_EMPTY_FIELDS);
        return disabled;
    })
}