import { CITY_CHAR_LIMIT } from "appConstants";
import ApiModal from "components/common/apiErrorModal";
import CheckMarkButton from "components/common/checkMarkButton";
import CrossButton from "components/common/crossButton";
import Input from "components/common/input";
import { Modal } from "components/common/modal";
import PhoneField from "components/common/phoneField";
import Select from "components/common/select";
import ZipField from "components/common/zipField";
import AddressField from "components/signup/venueSetup/addressField";
import { useFormik } from "formik";
import { useIsDisabled } from "hooks/useIsDisabled";
import { useState } from "react";
import { deleteObjectProp } from "util/deleteObjectProp";
import { addVendorSchema } from "util/validationSchema/addVendor";

const USA_STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];
const VENDOR_TYPES_OPTIONS = ["District", "School", "College", "Other"];

interface Props {
  closeModal: Function;
  addVendor: Function;
  loading: boolean;
  title?: string;
  leadId?: number;
  onAdd?: Function;
  setFlash?: Function;
  data?: any;
  isLead?: boolean;
}

const AddVendorModal = ({
  isLead,
  closeModal,
  addVendor,
  title,
  loading,
  leadId,
  onAdd,
  setFlash,
  data,
}: Props) => {
  const { vendor_name, state, city, county, vendor_type, high_schools } =
    data || {};
  const initialValues = {
    name: vendor_name || "",
    address: "",
    phone: "",
    city: city || "",
    state: state || "",
    zipcode: "",
    county: county || "",
    vendor_type: vendor_type || "",
    high_schools: high_schools || "",
  };

  const [error, setError] = useState("");
  const [zipcodeDisabled, setZipcodeDisabled] = useState(false);

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: addVendorSchema,
    onSubmit: async (values: any) => {
      console.log(values);
      const res = await addVendor({
        ...values,
        ...(leadId && { lead_id: leadId }),
      });

      if (res?._status?.code === 201) {
        onAdd ? onAdd(res) : closeModal();
        if (setFlash) {
          setFlash(true);
          setTimeout(() => {
            setFlash(false);
          }, 1000);
        }
      }

      res?.response?.data?.message && setError(res.response.data.message);
    },
  });
  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setFieldValue,
    setErrors,
    handleBlur,
    setValues,
  } = formik;

  const onClickCancel = (fieldName: string) => {
    const newErrors = deleteObjectProp(errors, fieldName);

    newErrors && setErrors && setErrors(newErrors);
  };

  const onClickPlace = (addressData: any) => {
    setZipcodeDisabled(!!addressData.zipcode);
    const state = addressData?.state;
    const stateIndex = USA_STATES.findIndex((item: string) => item === state);
    if (stateIndex === -1) {
      const option = document.createElement("option");
      option.text = addressData?.state;
      option.value = addressData?.state;
      const selectEl = document.getElementById("state-select");
      selectEl?.appendChild(option);
    }

    const { formatted_address, ...restAddressData } = addressData;
    setValues({
      ...values,
      ...restAddressData,
      ...(formatted_address && { address: formatted_address }),
    });

    let errorFields: string[] = [];
    if (errors.city && restAddressData?.city) {
      errorFields.push("city");
    }
    if (errors.state && restAddressData?.state) {
      errorFields.push("state");
    }
    if (errors.zipcode && restAddressData.zipcode) {
      errorFields.push("zipcode");
    }
    if (errors.county && restAddressData.county) {
      errorFields.push("zipcode");
    }

    const updatedErrors = deleteAllProps(errors, errorFields);
    setErrors(updatedErrors);
  };

  const deleteAllProps = (obj: any, fields: string[]) => {
    let newObj = obj;
    fields.forEach((field: string) => {
      newObj = deleteObjectProp(newObj, field);
    });

    return newObj;
  };

  const isDisabled = useIsDisabled(values, errors, ["high_schools"]);
  const isLoading = loading;

  console.log(values);

  return (
    <>
      <Modal
        setShowModal={() => closeModal()}
        customClass={`add-modal ${error ? "disabled" : ""}`}
      >
        <div className="header">
          <div className="modal-actions">
            <CrossButton onClick={closeModal} disabled={isLoading} />
            <CheckMarkButton
              onClick={handleSubmit}
              width={47}
              height={47}
              activeDisabled={isDisabled}
              disabled={isLoading}
              isForm
            />
          </div>
          <p className="app-modal-title">{title || "Add Vendor"}</p>
        </div>

        <div className="modal__body ">
          <div className="add-lead-form">
            <div className="row">
              <div className="five-columns-md four-columns-sm columns">
                <Input
                  name="name"
                  label={isLead ? "Vendor Name" : "Name"}
                  placeholder={(errors?.name as string) || "Enter Name"}
                  value={values?.name}
                  onChange={handleChange}
                  isError={!!errors?.name}
                  errors={errors}
                  setErrors={setErrors}
                  width="100%"
                />
              </div>
              <div className="four-columns-md four-columns-sm columns">
                <Select
                  label="Vendor Type"
                  placeholder={(errors.vendor_type as string) || "Select Type"}
                  name="vendor_type"
                  value={values?.vendor_type}
                  onChange={handleChange}
                  id="vendor_type-select"
                  isError={!!errors?.vendor_type}
                  width="100%"
                  options={VENDOR_TYPES_OPTIONS.map((item: any) => ({
                    label: item,
                    value: item,
                  }))}
                  onClickCross={() => onClickCancel("vendor_type")}
                />
              </div>
              {values.vendor_type === "District" && (
                <div className="three-columns-md four-columns-sm columns">
                  <Input
                    name="high_schools"
                    label={"High Schools"}
                    placeholder={
                      (errors?.high_schools as string) || "Enter High Schools"
                    }
                    value={values?.high_schools}
                    onChange={handleChange}
                    isError={!!errors?.high_schools}
                    width="100%"
                    onClickCross={() => onClickCancel("high_schools")}
                  />
                </div>
              )}
            </div>

            <div className="row">
              {/* <Input
                                    name="address"
                                    label="Address"
                                    placeholder={errors?.address as string || 'Enter Address'}
                                    value={values?.address}
                                    onChange={handleChange}
                                    isError={!!(errors?.address)}
                                    errors={errors}
                                    setErrors={setErrors}
                                    width="70%"
                                /> */}

              <AddressField
                setFormValue={setFieldValue}
                onClickPlace={onClickPlace}
                address={values.address || ""}
                error={errors.address}
                onBlur={handleBlur}
                onClickCross={(fieldName: string) => onClickCancel(fieldName)}
              />
            </div>

            <div className="row">
              <div className="five-columns-sm columns">
                <Input
                  label="City"
                  name="city"
                  placeholder={(errors?.city as string) || "Enter City"}
                  value={values?.city}
                  onChange={handleChange}
                  isError={!!errors?.city}
                  errors={errors}
                  setErrors={setErrors}
                  maxLength={CITY_CHAR_LIMIT}
                />
              </div>
              <div className="four-columns-sm columns">
                <Select
                  label="State"
                  placeholder={(errors.state as string) || "XX"}
                  name="state"
                  value={values?.state}
                  onChange={handleChange}
                  id="state-select"
                  isError={!!errors?.state}
                  options={USA_STATES.map((item: any) => ({
                    label: item,
                    value: item,
                  }))}
                  onClickCross={() => onClickCancel("state")}
                />
              </div>
            </div>
            <div className="row">
              <div className="three-columns-sm columns">
                <ZipField
                  label="Zip"
                  name="zipcode"
                  placeholder={(errors?.zipcode as string) || "Enter Zip"}
                  value={values?.zipcode}
                  onChange={handleChange}
                  isError={!!errors?.zipcode}
                  errors={errors}
                  setErrors={setErrors}
                  disabled={zipcodeDisabled}
                />
              </div>
              <div className="five-columns-sm columns">
                <Input
                  name="county"
                  label="County"
                  placeholder={(errors?.county as string) || "Enter County"}
                  value={values?.county}
                  onChange={handleChange}
                  isError={!!errors?.county}
                  errors={errors}
                  setErrors={setErrors}
                  width="100%"
                />
              </div>
              <div className="four-columns-sm columns">
                <PhoneField
                  label="Phone"
                  placeholder={(errors?.phone as string) || "123-456-7890"}
                  name="phone"
                  value={values?.phone}
                  isError={!!errors?.phone}
                  errors={errors}
                  setErrors={setErrors}
                  setValue={setFieldValue}
                  errorMessage={errors.phone}
                  width={200}
                />
              </div>
            </div>
            {/* county            */}
          </div>
        </div>

        <div className="footer"></div>
      </Modal>

      {error && (
        <ApiModal
          title="Add Vendor Failed"
          message={error}
          setIsShow={() => setError("")}
        />
      )}
    </>
  );
};

export default AddVendorModal;
