import { Modal, ModalHeader, ModalBody } from "components/common/modal";
import { useState } from "react";
import ApiModal from "components/common/apiErrorModal";
import Button from "components/common/button";

interface Props {
  closeModal?: Function;
  title: string;
  isDisabled?: boolean;
  description: string;
  submitButtonName?: string;
  submitLoading?: boolean;
  isCancel?: boolean;
  width?: number;
  height?: number;
  customClass?: string;
  cancelButtonName?: string;
  onEmail: Function;
  onPayNow: Function;
  paymentLoading: any
}

const BillingPaymentModal = ({
  width,
  submitLoading,
  closeModal,
  title,
  description,
  onEmail,
  onPayNow,
  paymentLoading,
}: Props) => {
  const [error, setError] = useState("");

  // const onClickSubmit = async () => {
  //     const res = await onSubmit();

  //     if(res?.response?.data?.message || res?.response?.data?.error){
  //         setError(res?.response?.data?.message || res?.response?.data?.error);
  //     }
  // }

  // useEffect(() => {
  //     const keyHandler = async (e: any) => {
  //         if(e.keyCode === 13){
  //             await onClickSubmit();
  //         }
  //     }
  //     window.addEventListener('keydown', keyHandler, false);
  //     return () => {
  //         window.removeEventListener('keydown', keyHandler, false);
  //     }
  //     /* eslint-disable react-hooks/exhaustive-deps */
  // },[]);

  return (
    <>
      <Modal
        setShowModal={() => closeModal && closeModal()}
        width={width || 342}
        customClass={`delete-modal ${error ? "disabled" : ""}`}
      >
        <ModalHeader>
          <p className="app-modal-title onboarding-pay-now-modal-heading">
            {title || ""}
          </p>
        </ModalHeader>

        <ModalBody customClass="onboarding-pay-now-modal">
          <div>
            <p style={{ color: "#fff" }}>{description || ""}</p>
          </div>
        </ModalBody>
     
        <div className={"app-modal-footer"}>
    
          
          <Button
            buttonName={"Email"}
            cancelButton
            onClick={onEmail}
            customStyles={{
              borderRadius: 40,
              backgroundColor: "#26cad3",
            }}
            disabled={submitLoading}
            loading = {paymentLoading}
          />
      
          <Button
            buttonName={"Pay Now"}
            customStyles={{
              borderRadius: 40,
            }}
            onClick={onPayNow}
            loading={submitLoading}
            disabled = {paymentLoading}
          />
        </div>
      </Modal>

      {error && (
        <ApiModal
          title={`${title} Failed`}
          description={error}
          setIsShow={() => setError("")}
        />
      )}
    </>
  );
};

export default BillingPaymentModal;
