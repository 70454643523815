import moment from "moment";
import { useEffect, useRef, useState } from "react";

const Note = ({onClickNote, data}: any) => {
    const { id, subject, created_at, name_initials } = data;
    const subjectRef = useRef<any>(null);
    const [showTooltip, setTooltip] = useState(false);

    useEffect(() => {
        if(data?.subject && subjectRef.current){
            setTooltip(subjectRef.current ? (subjectRef.current.scrollWidth - subjectRef.current.clientWidth > 0) : false);
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[data, subjectRef.current])

    return (
        <div onClick={() => onClickNote(data)} key={id} className="note cursor-pointer">
            <div className={`${showTooltip ? 'show-truncate-tooltip' : ''} subject-line-name-container`}>
                <div ref={subjectRef} className="subject-line-name">{subject}</div>
                {showTooltip && subject &&  (
                    <span className="truncate-tooltip">{subject}</span>
                )}
            </div>
            
            <div className="subject-note-detail">
                <div className="note-date">{created_at ? moment(created_at).format('M/D/YY') : ''}</div>
                <div className="group-name">{name_initials}</div>
            </div>
        </div>  
    )
};

export default Note;
