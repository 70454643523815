import {TableData, TableRow} from "components/common/table";
import moment from "moment";
import Email from "./email";

interface IProps {
    data: any;
    nonClickableRow?: boolean;
    onClickOption?: Function;
}

const Crowd = ({
    data,
    nonClickableRow=false,
    onClickOption
}: IProps) => {

    const {first_name, last_name, phone, email, is_current_email_verified, updated_email, last_order} = data || {};
    
    const fullName = `${last_name}${first_name ? `, ${first_name}` : ''}`
    return (
        <>
            <TableRow nonClickableRow={nonClickableRow}>
                <TableData customClass="name">
                    {fullName}
                </TableData>
                <TableData customClass="phone">
                    {phone}
                </TableData>
                <TableData isTooltip={false} customClass="email">
                    <Email 
                        isEmailVerified={is_current_email_verified}
                        updatedEmail={updated_email}
                        email={email}
                    />
                </TableData>
                <TableData customClass="last-order">
                    {last_order ? moment(last_order).format("M/D/YY") : ''}
                </TableData>
                <TableData onClickData={onClickOption} cursorPointer customClass="option">
                    $
                </TableData>
            </TableRow>
        </>
    )
};

export default Crowd;
