import { useNavigate } from "react-router-dom";
import Button from "../button";

const PageNotFound = () => {
    const navigate = useNavigate();
    const onClickDashboard = () => {
        navigate("/dashboard");
    }

    return (
        <div className="page-not-found-container">
            <p className="ooops">Ooops...</p>
            <p className="page-not-found">Page Not Found</p>
            <p className="page-not-found-text">The page you are looking for doesn't exist. Go back to dashboard.</p>
            
            <Button 
                buttonName="Dashboard"
                onClick={onClickDashboard}
                width="10%"
                parentClass="page-not-found-button"
            />
        </div>
    )
}

export default PageNotFound;