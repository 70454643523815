import VendorProvider from "contexts/vendor";
import { useRoleId } from "hooks/useRoleId";
import { Navigate, Outlet, useLocation } from "react-router";

const SuperAdminRoutes = () => {

    const location = useLocation();
   
    const roleId = useRoleId();
    
    return roleId ===1? 
    <VendorProvider>    
        <Outlet />
    </VendorProvider>
    : <Navigate to="/*" state={{ from: location }} />
};

export default SuperAdminRoutes;
