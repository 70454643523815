import React from 'react'

const homeIcon = () => {
  return (
<svg xmlns="http://www.w3.org/2000/svg" width="24" height="21" viewBox="0 0 24 21" fill="none">
<g opacity="0.4">
<path d="M23.4071 9.45918L19.4045 6.31543V2.68173C19.4045 2.47597 19.2383 2.30782 19.0352 2.30782H16.8195C16.6164 2.30782 16.4502 2.47597 16.4502 2.68173V3.99487L12.698 1.04782C12.2984 0.73406 11.7396 0.73406 11.3402 1.04782L0.630961 9.45918C0.147483 9.83908 0.0597009 10.5435 0.434801 11.0326C0.809732 11.5219 1.50558 11.6111 1.98905 11.2312L11.465 3.7886C11.465 3.7886 11.7327 3.54782 12.0188 3.54782C12.305 3.54782 12.5724 3.78826 12.5724 3.78826L22.049 11.2313C22.2509 11.3899 22.4901 11.4668 22.7273 11.4668C23.0578 11.4668 23.3851 11.3176 23.6032 11.0328C23.9783 10.5433 23.8905 9.83891 23.4071 9.45918Z" fill="black"/>
<path d="M21.3606 11.4684L13.0813 4.96555C12.9208 4.83961 12.6583 4.83961 12.4979 4.96555L4.21871 11.4684C4.058 11.5943 3.927 11.8659 3.927 12.0713V20.6243C3.927 20.8299 4.09328 20.9982 4.29636 20.9982H10.2048C10.408 20.9982 10.5741 20.8299 10.5741 20.6243V14.6429C10.5741 14.4373 10.7404 14.269 10.9435 14.269H14.6363C14.8393 14.269 15.0056 14.4373 15.0056 14.6429V20.6243C15.0056 20.8299 15.1719 20.9982 15.375 20.9982H21.2836C21.4867 20.9982 21.6529 20.8299 21.6529 20.6243V12.0713C21.6529 11.8659 21.5216 11.5943 21.3612 11.4684H21.3606Z" fill="black"/>
</g>
<path d="M23.4071 8.64668L19.4045 5.50293V1.86923C19.4045 1.66347 19.2383 1.49532 19.0352 1.49532H16.8195C16.6164 1.49532 16.4502 1.66347 16.4502 1.86923V3.18237L12.698 0.23532C12.2984 -0.07844 11.7396 -0.07844 11.3402 0.23532L0.630961 8.64668C0.147483 9.02658 0.0597009 9.731 0.434801 10.2201C0.809732 10.7094 1.50558 10.7986 1.98905 10.4187L11.465 2.9761C11.465 2.9761 11.7327 2.73532 12.0188 2.73532C12.305 2.73532 12.5724 2.97576 12.5724 2.97576L22.049 10.4188C22.2509 10.5774 22.4901 10.6543 22.7273 10.6543C23.0578 10.6543 23.3851 10.5051 23.6032 10.2203C23.9783 9.73083 23.8905 9.02641 23.4071 8.64668Z" fill="white"/>
<path d="M20.5899 10.6559L12.3107 4.15305C12.1502 4.02711 11.8877 4.02711 11.7273 4.15305L3.44808 10.6559C3.28737 10.7818 3.15637 11.0534 3.15637 11.2588V19.8118C3.15637 20.0174 3.32265 20.1857 3.52573 20.1857H9.43415C9.6374 20.1857 9.80351 20.0174 9.80351 19.8118V13.8304C9.80351 13.6248 9.96979 13.4565 10.1729 13.4565H13.8656C14.0687 13.4565 14.235 13.6248 14.235 13.8304V19.8118C14.235 20.0174 14.4013 20.1857 14.6044 20.1857H20.5129C20.716 20.1857 20.8823 20.0174 20.8823 19.8118V11.2588C20.8823 11.0534 20.751 10.7818 20.5906 10.6559H20.5899Z" fill="white"/>
</svg>
  )
}

export default homeIcon
