import { useState } from "react";

interface IProps {
  class?: string;
  onClick?: Function;
}

const ResetIcon = (props: IProps) => {
  const { onClick } = props;

  const [rotateIconClass, setRotateIconClass] = useState("");

  const onClickIcon = () => {
    setRotateIconClass("rotate-refresh-icon");
    setTimeout(() => {
      setRotateIconClass("");
    }, 1000);
    onClick && !rotateIconClass && onClick();
  };
  return (
    <svg
      width="25"
      height="25"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      xlinkTitle="Reset"
      className={`cursor-pointer reset-icon ${rotateIconClass}`}
      onClick={onClickIcon}
    >
      <path
        d="M16.464 0C12.3411 0 8.57047 1.46133 5.67447 3.87857V1.51716C5.67447 0.767391 5.04687 0.159577 4.27247 0.159577C3.88538 0.159577 3.53499 0.31153 3.28129 0.557231C3.02759 0.802932 2.87069 1.14228 2.87069 1.51716V7.14614C2.87069 7.89591 3.49829 8.50373 4.27247 8.50373H10.0847C10.8589 8.50373 11.4865 7.89591 11.4865 7.14614C11.4865 6.77104 11.3296 6.4317 11.0759 6.186C10.8222 5.9403 10.4718 5.78834 10.0847 5.78834H7.55706C9.96194 3.82171 13.0701 2.63637 16.464 2.63637C24.0933 2.63637 30.278 8.62611 30.278 16.0149C30.278 23.4036 24.0933 29.3934 16.464 29.3934C9.66654 29.3934 4.0159 24.6385 2.86452 18.3759C2.80293 18.0423 2.75442 17.7044 2.71932 17.3628C2.46466 16.9088 1.96801 16.6006 1.39721 16.6006C0.763333 16.6006 0.221043 16.9806 0 17.5181C0.0461659 17.9994 0.114457 18.4746 0.203704 18.9427C1.62421 26.3891 8.36401 32.0295 16.464 32.0295C25.5966 32.0295 33 24.8596 33 16.0149C33 7.17014 25.5966 0 16.464 0Z"
        fill="white"
      />
    </svg>
  );
};

export default ResetIcon;
