import { useContext, useEffect, useRef, useState } from "react";
import DeleteIcon from "components/common/deleteIcon";
import InfoModal from "components/common/infoModal";
import Label from "components/common/label";
import { Modal } from "components/common/modal";
import { VendorActions } from "contexts/reducers/vendor";
import { VendorContext } from "contexts/vendor";
import { useFullName } from "hooks/useRoleId";
import moment from "moment";
import Paragraph from "components/common/paragaraph";

let timeoutId: any = null;

const NoteModal = ({data, closeModal}: any) => {
    const { removeNote, loading } = useContext(VendorContext);

    const messageRef = useRef<any>(null);

    const [confirmModal, setConfirmModal] = useState(false);
    const [showIndicator, setShowIndicator] = useState<any>(null!);
    const [isScrolled, setIsScrolled] = useState(false);

    useEffect(() => {
        if(data?.note && messageRef.current){
            const isShowIndicator = messageRef.current?.scrollHeight > messageRef.current?.clientHeight;

            if(isShowIndicator && showIndicator === null){
                timeoutId = setTimeout(() => {
                    setShowIndicator(true);
                },5000);
            }
            

            return () => {
                timeoutId && clearTimeout(timeoutId);
            }
        }
    },[data, showIndicator]);

    const onScrollMessage = (e: any) => {
        if(e.target.scrollTop > 10){
            setShowIndicator(false);
            setIsScrolled(true)
        }else{
            setIsScrolled(false)
        }
    }

    const onClickDelete = () => {
        setConfirmModal(true);
    }

    const DeleteNote = async () => {
        const res = await removeNote(data.lead_id, data.id);

        if(res?.status === 204){
            setConfirmModal(false);
            closeModal();
        }
    }
    
    const fullName = useFullName();
    const {created_at, subject, note} = data;
    const deleteLoading = loading[VendorActions.DELETE_NOTE];
    return (
        <>
            <Modal
                setShowModal={closeModal}
                customClass={`add-modal notes-modal vendor-notes-details ${confirmModal ? 'disabled' : ''}`}
            >

                <div className="header">
                    <h2 className="app-modal-title">Note Details</h2>
                    <div className="modal-actions">
                        <DeleteIcon
                            onClick={onClickDelete}
                        />
                    </div>
                </div>

                <div className="modal__body ">
                    <div className="add-lead-form">
                        <div className="subject-author-row">
                            <div className="detail-group">
                                <Label text="Subject" />
                                <Paragraph
                                    customClass="field-text"
                                >
                                    {subject}
                                </Paragraph>
                            </div>
                            <div className="detail-group author">
                                <Label text="Author" />
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <p className="field-text author-name">{fullName}</p>
                                    <p className="field-text">{created_at ? moment(created_at).format("M/D/YY h:mm A") : ''}</p>
                                </div>
                            </div>
                        </div>
                        <div className="detail-group">
                            <Label text="Message" />
                            <p ref={messageRef} onScroll={onScrollMessage} className="field-text note-modal-message">{note}</p>
                        </div>
                    </div>
                </div>

                <div className={`see-more ${!isScrolled ? '' : 'see-more-deactivate'}`}>
                    {showIndicator && (
                        <div className="scroll-indicator"></div>
                    )}
                </div>
            </Modal>

            {confirmModal && (
                <InfoModal 
                    title="Delete Note"
                    description={`Are you sure you want to delete ${fullName ? `${fullName}'s` : 'this'} note?`}
                    submitButtonName="Delete"
                    onSubmit={DeleteNote}
                    submitLoading={deleteLoading}
                    isCancel
                    height={400}
                    closeModal={() => setConfirmModal(false)}
                />
            )}
        </>
    )
};

export default NoteModal;
