const CanteenIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="39" viewBox="0 0 34 39" fill="none">
            <path d="M30.4635 22.6953H3.51953V38.9173H30.4635V22.6953Z" fill="#7F4F26"/>
            <path d="M29.8156 24.4143C29.9504 24.4143 30.0796 24.3608 30.1748 24.2655C30.2701 24.1702 30.3236 24.041 30.3236 23.9063V22.6953H29.3066V23.9063C29.3066 24.041 29.3601 24.1702 29.4554 24.2655C29.5507 24.3608 29.6799 24.4143 29.8146 24.4143" fill="#49341F"/>
            <path d="M28.1057 24.4143C28.2404 24.4143 28.3696 24.3608 28.4649 24.2655C28.5602 24.1702 28.6137 24.041 28.6137 23.9063V22.6953H27.5967V23.9063C27.5967 24.041 27.6502 24.1702 27.7455 24.2655C27.8408 24.3608 27.9699 24.4143 28.1047 24.4143" fill="#49341F"/>
            <path d="M26.3957 24.4143C26.4624 24.4143 26.5285 24.4012 26.5901 24.3756C26.6517 24.3501 26.7077 24.3127 26.7549 24.2655C26.8021 24.2183 26.8395 24.1623 26.865 24.1007C26.8906 24.0391 26.9037 23.973 26.9037 23.9063V22.6953H25.8877V23.9063C25.8877 24.041 25.9412 24.1702 26.0365 24.2655C26.1318 24.3608 26.261 24.4143 26.3957 24.4143Z" fill="#49341F"/>
            <path d="M24.6858 24.4143C24.8205 24.4143 24.9497 24.3608 25.045 24.2655C25.1402 24.1702 25.1938 24.041 25.1938 23.9063V22.6953H24.1768V23.9063C24.1768 24.041 24.2303 24.1702 24.3255 24.2655C24.4208 24.3608 24.55 24.4143 24.6848 24.4143" fill="#49341F"/>
            <path d="M22.9758 24.4143C23.1105 24.4143 23.2397 24.3608 23.335 24.2655C23.4303 24.1702 23.4838 24.041 23.4838 23.9063V22.6953H22.4668V23.9063C22.4668 24.041 22.5203 24.1702 22.6156 24.2655C22.7109 24.3608 22.8411 24.4143 22.9758 24.4143Z" fill="#49341F"/>
            <path d="M21.2658 24.4143C21.3325 24.4143 21.3986 24.4012 21.4602 24.3756C21.5219 24.3501 21.5779 24.3127 21.625 24.2655C21.6722 24.2183 21.7096 24.1623 21.7352 24.1007C21.7607 24.0391 21.7738 23.973 21.7738 23.9063V22.6953H20.7568V23.9063C20.7568 24.041 20.8104 24.1702 20.9056 24.2655C21.0009 24.3608 21.1311 24.4143 21.2658 24.4143Z" fill="#49341F"/>
            <path d="M19.5598 24.4143C19.6945 24.4143 19.8237 24.3608 19.919 24.2655C20.0143 24.1702 20.0678 24.041 20.0678 23.9063V22.6953H19.0508V23.9063C19.0508 24.041 19.1043 24.1702 19.1996 24.2655C19.2948 24.3608 19.424 24.4143 19.5588 24.4143" fill="#49341F"/>
            <path d="M17.8459 24.4143C17.9806 24.4143 18.1098 24.3608 18.2051 24.2655C18.3004 24.1702 18.3539 24.041 18.3539 23.9063V22.6953H17.3379V23.9063C17.3379 24.041 17.3914 24.1702 17.4867 24.2655C17.5819 24.3608 17.7112 24.4143 17.8459 24.4143Z" fill="#49341F"/>
            <path d="M16.136 24.4143C16.2707 24.4143 16.3999 24.3608 16.4952 24.2655C16.5904 24.1702 16.644 24.041 16.644 23.9063V22.6953H15.627V23.9063C15.627 24.041 15.6805 24.1702 15.7757 24.2655C15.871 24.3608 16.0002 24.4143 16.1349 24.4143" fill="#49341F"/>
            <path d="M14.426 24.4143C14.5607 24.4143 14.6899 24.3608 14.7852 24.2655C14.8805 24.1702 14.934 24.041 14.934 23.9063V22.6953H13.917V23.9063C13.917 23.973 13.9301 24.0391 13.9557 24.1007C13.9812 24.1623 14.0186 24.2183 14.0658 24.2655C14.113 24.3127 14.169 24.3501 14.2306 24.3756C14.2922 24.4012 14.3583 24.4143 14.425 24.4143" fill="#49341F"/>
            <path d="M12.7151 24.4143C12.8498 24.4143 12.979 24.3608 13.0743 24.2655C13.1695 24.1702 13.2231 24.041 13.2231 23.9063V22.6953H12.2061V23.9063C12.2061 24.041 12.2596 24.1702 12.3548 24.2655C12.4501 24.3608 12.5793 24.4143 12.7141 24.4143" fill="#49341F"/>
            <path d="M11.006 24.4143C11.1407 24.4143 11.2699 24.3608 11.3652 24.2655C11.4605 24.1702 11.514 24.041 11.514 23.9063V22.6953H10.501V23.9063C10.501 24.041 10.5545 24.1702 10.6498 24.2655C10.745 24.3608 10.8742 24.4143 11.009 24.4143" fill="#49341F"/>
            <path d="M9.2951 24.4143C9.42983 24.4143 9.55905 24.3608 9.65432 24.2655C9.74959 24.1702 9.80311 24.041 9.80311 23.9063V22.6953H8.78711V23.9063C8.78711 24.041 8.84063 24.1702 8.9359 24.2655C9.03117 24.3608 9.16037 24.4143 9.2951 24.4143Z" fill="#49341F"/>
            <path d="M7.58612 24.4143C7.72085 24.4143 7.85007 24.3608 7.94534 24.2655C8.0406 24.1702 8.09412 24.041 8.09412 23.9063V22.6953H7.07812V23.9063C7.07812 24.041 7.13164 24.1702 7.22691 24.2655C7.32218 24.3608 7.45139 24.4143 7.58612 24.4143Z" fill="#49341F"/>
            <path d="M5.87617 24.4143C6.0109 24.4143 6.14011 24.3608 6.23537 24.2655C6.33064 24.1702 6.38416 24.041 6.38416 23.9063V22.6953H5.36816V23.9063C5.36816 24.041 5.42169 24.1702 5.51696 24.2655C5.61223 24.3608 5.74144 24.4143 5.87617 24.4143Z" fill="#49341F"/>
            <path d="M4.1662 24.4143C4.30093 24.4143 4.43015 24.3608 4.52541 24.2655C4.62068 24.1702 4.67421 24.041 4.67421 23.9063V22.6953H3.6582V23.9063C3.6582 24.041 3.71173 24.1702 3.807 24.2655C3.90226 24.3608 4.03147 24.4143 4.1662 24.4143Z" fill="#49341F"/>
            <path d="M29.2617 26.2988H4.72168V37.7598H29.2617V26.2988Z" fill="#C38F63"/>
            <mask id="mask0_4492_7476" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="4" y="26" width="26" height="12">
                <path d="M29.2617 26.2998H4.72168V37.7608H29.2617V26.2998Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_4492_7476)">
                <path d="M14.8521 11.7506L13.5166 12.8193L36.1622 41.1173L37.4977 40.0486L14.8521 11.7506Z" fill="#AF7D4F"/>
                <path d="M12.1824 13.8891L10.8477 14.958L33.4966 43.2397L34.8314 42.1708L12.1824 13.8891Z" fill="#AF7D4F"/>
                <path d="M9.51324 16.026L8.17773 17.0947L30.8234 45.3927L32.1589 44.324L9.51324 16.026Z" fill="#AF7D4F"/>
                <path d="M6.8443 18.1637L5.50879 19.2324L28.1544 47.5304L29.4899 46.4616L6.8443 18.1637Z" fill="#AF7D4F"/>
                <path d="M4.17438 20.3023L2.83887 21.3711L25.4845 49.6691L26.82 48.6003L4.17438 20.3023Z" fill="#AF7D4F"/>
                <path d="M1.50543 22.44L0.169922 23.5088L22.8155 51.8068L24.1511 50.738L1.50543 22.44Z" fill="#AF7D4F"/>
                <path d="M-1.16526 24.5776L-2.5 25.6465L20.149 53.9282L21.4837 52.8593L-1.16526 24.5776Z" fill="#AF7D4F"/>
            </g>
            <path d="M29.6079 11.7529H29.1689V22.6949H29.6079V11.7529Z" fill="white"/>
            <path d="M4.814 11.7529H4.375V22.6949H4.814V11.7529Z" fill="white"/>
            <path d="M30.8211 0.314453H3.16113V5.92945H30.8211V0.314453Z" fill="#C6432A"/>
            <path d="M30.8211 5.92969H3.16113V6.72669H30.8211V5.92969Z" fill="#A12C1C"/>
            <path d="M29.6072 11.7526H33.2072L30.8152 6.72656H27.7422L29.6072 11.7526Z" fill="#EA4326"/>
            <path d="M26.0039 11.7526H29.6039L27.7439 6.72656H24.6709L26.0039 11.7526Z" fill="white"/>
            <path d="M22.3967 11.7526H25.9967L24.6677 6.72656H21.5967L22.3967 11.7526Z" fill="#EA4326"/>
            <path d="M18.7935 11.7526H22.3935L21.5935 6.72656H18.5205L18.7935 11.7526Z" fill="white"/>
            <path d="M15.1885 11.7526H18.7885L18.5225 6.72656H15.4495L15.1885 11.7526Z" fill="#EA4326"/>
            <path d="M11.585 11.7526H15.185L15.451 6.72656H12.378L11.585 11.7526Z" fill="white"/>
            <path d="M7.97949 11.7526H11.5795L12.3795 6.72656H9.30849L7.97949 11.7526Z" fill="#EA4326"/>
            <path d="M0.769531 11.7526H4.36953L6.22953 6.72656H3.16053L0.769531 11.7526Z" fill="#EA4326"/>
            <path d="M4.375 11.7526H7.975L9.304 6.72656H6.236L4.375 11.7526Z" fill="white"/>
            <path d="M31.4094 13.7979C31.6457 13.7979 31.8798 13.7513 32.0982 13.6609C32.3166 13.5704 32.515 13.4378 32.6822 13.2707C32.8493 13.1035 32.9819 12.9051 33.0724 12.6867C33.1628 12.4683 33.2094 12.2343 33.2094 11.9979V11.7549H29.6094V11.9979C29.6094 12.4753 29.799 12.9331 30.1366 13.2707C30.4741 13.6082 30.932 13.7979 31.4094 13.7979Z" fill="#C6432A"/>
            <path d="M27.8059 13.7979C28.2833 13.7979 28.7411 13.6082 29.0787 13.2707C29.4162 12.9331 29.6059 12.4753 29.6059 11.9979V11.7549H26.0059V11.9979C26.0059 12.4753 26.1955 12.9331 26.5331 13.2707C26.8706 13.6082 27.3285 13.7979 27.8059 13.7979Z" fill="#F4F4F5"/>
            <path d="M24.2004 13.7979C24.6778 13.7979 25.1356 13.6082 25.4732 13.2707C25.8107 12.9331 26.0004 12.4753 26.0004 11.9979V11.7549H22.4004V11.9979C22.4004 12.4753 22.59 12.9331 22.9276 13.2707C23.2652 13.6082 23.723 13.7979 24.2004 13.7979Z" fill="#C6432A"/>
            <path d="M20.5959 13.7979C20.8323 13.7979 21.0663 13.7513 21.2847 13.6609C21.5031 13.5704 21.7016 13.4378 21.8687 13.2707C22.0358 13.1035 22.1684 12.9051 22.2589 12.6867C22.3493 12.4683 22.3959 12.2343 22.3959 11.9979V11.7549H18.7959V11.9979C18.7959 12.2343 18.8425 12.4683 18.9329 12.6867C19.0234 12.9051 19.156 13.1035 19.3231 13.2707C19.4903 13.4378 19.6887 13.5704 19.9071 13.6609C20.1255 13.7513 20.3595 13.7979 20.5959 13.7979Z" fill="#F4F4F5"/>
            <path d="M16.9904 13.7979C17.2268 13.7979 17.4609 13.7513 17.6793 13.6609C17.8976 13.5704 18.0961 13.4378 18.2632 13.2707C18.4304 13.1035 18.563 12.9051 18.6534 12.6867C18.7439 12.4683 18.7904 12.2343 18.7904 11.9979V11.7549H15.1904V11.9979C15.1904 12.4753 15.3801 12.9331 15.7176 13.2707C16.0552 13.6082 16.513 13.7979 16.9904 13.7979Z" fill="#C6432A"/>
            <path d="M13.3869 13.7979C13.8643 13.7979 14.3221 13.6082 14.6597 13.2707C14.9973 12.9331 15.1869 12.4753 15.1869 11.9979V11.7549H11.5869V11.9979C11.5869 12.4753 11.7766 12.9331 12.1141 13.2707C12.4517 13.6082 12.9095 13.7979 13.3869 13.7979Z" fill="#F4F4F5"/>
            <path d="M9.78145 13.7979C10.2588 13.7979 10.7167 13.6082 11.0542 13.2707C11.3918 12.9331 11.5814 12.4753 11.5814 11.9979V11.7549H7.98145V11.9979C7.98145 12.4753 8.17109 12.9331 8.50865 13.2707C8.84622 13.6082 9.30406 13.7979 9.78145 13.7979Z" fill="#C6432A"/>
            <path d="M6.17695 13.7979C6.65434 13.7979 7.11218 13.6082 7.44975 13.2707C7.78731 12.9331 7.97695 12.4753 7.97695 11.9979V11.7549H4.37695V11.9979C4.37695 12.4753 4.56659 12.9331 4.90416 13.2707C5.24173 13.6082 5.69956 13.7979 6.17695 13.7979Z" fill="#F4F4F5"/>
            <path d="M2.57148 13.7979C3.04887 13.7979 3.50671 13.6082 3.84428 13.2707C4.18184 12.9331 4.37148 12.4753 4.37148 11.9979V11.7549H0.771484V11.9979C0.771484 12.4753 0.961127 12.9331 1.29869 13.2707C1.63626 13.6082 2.09409 13.7979 2.57148 13.7979Z" fill="#C6432A"/>
            <path d="M23.5424 21.9666H26.9814C27.1902 21.9666 27.3905 21.8836 27.5382 21.7359C27.6859 21.5883 27.7689 21.388 27.7689 21.1791C27.7689 20.9702 27.6859 20.7699 27.5382 20.6223C27.3905 20.4746 27.1902 20.3916 26.9814 20.3916H23.5424C23.3335 20.3916 23.1332 20.4746 22.9855 20.6223C22.8378 20.7699 22.7549 20.9702 22.7549 21.1791C22.7549 21.388 22.8378 21.5883 22.9855 21.7359C23.1332 21.8836 23.3335 21.9666 23.5424 21.9666Z" fill="#EACCA4"/>
            <path d="M22.9833 21.7586H27.5833C27.7372 21.7586 27.8847 21.6975 27.9934 21.5887C28.1022 21.48 28.1633 21.3325 28.1633 21.1786C28.1633 21.0248 28.1022 20.8773 27.9934 20.7685C27.8847 20.6597 27.7372 20.5986 27.5833 20.5986H22.9833C22.8295 20.5986 22.682 20.6597 22.5732 20.7685C22.4644 20.8773 22.4033 21.0248 22.4033 21.1786C22.4033 21.3325 22.4644 21.48 22.5732 21.5887C22.682 21.6975 22.8295 21.7586 22.9833 21.7586Z" fill="#DF0624"/>
            <path d="M23.5424 22.6717H26.9814C27.1902 22.6717 27.3905 22.5887 27.5382 22.441C27.6859 22.2933 27.7689 22.093 27.7689 21.8842C27.7689 21.6753 27.6859 21.475 27.5382 21.3273C27.3905 21.1796 27.1902 21.0967 26.9814 21.0967H23.5424C23.3335 21.0967 23.1332 21.1796 22.9855 21.3273C22.8378 21.475 22.7549 21.6753 22.7549 21.8842C22.7549 22.093 22.8378 22.2933 22.9855 22.441C23.1332 22.5887 23.3335 22.6717 23.5424 22.6717Z" fill="#FFB167"/>
            <path d="M14.4796 20.235H21.5236C21.58 20.2312 21.6329 20.2062 21.6715 20.1649C21.7101 20.1235 21.7316 20.0691 21.7316 20.0125C21.7316 19.956 21.7101 19.9015 21.6715 19.8602C21.6329 19.8189 21.58 19.7938 21.5236 19.79H14.4796C14.4231 19.7938 14.3702 19.8189 14.3316 19.8602C14.293 19.9015 14.2715 19.956 14.2715 20.0125C14.2715 20.0691 14.293 20.1235 14.3316 20.1649C14.3702 20.2062 14.4231 20.2312 14.4796 20.235Z" fill="#324B4E"/>
            <path d="M15.1115 22.6704H20.8915L21.1095 18.6094H14.8945L15.1115 22.6704Z" fill="#DF0624"/>
            <path d="M14.8414 18.6106H21.1604C21.1893 18.6106 21.217 18.5991 21.2375 18.5786C21.2579 18.5582 21.2694 18.5305 21.2694 18.5016C21.2694 18.4727 21.2579 18.4449 21.2375 18.4245C21.217 18.4041 21.1893 18.3926 21.1604 18.3926H14.8414C14.8125 18.3926 14.7848 18.4041 14.7643 18.4245C14.7439 18.4449 14.7324 18.4727 14.7324 18.5016C14.7324 18.5305 14.7439 18.5582 14.7643 18.5786C14.7848 18.5991 14.8125 18.6106 14.8414 18.6106Z" fill="#324B4E"/>
            <mask id="mask1_4492_7476" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="14" y="18" width="8" height="5">
                <path d="M14.8936 18.6094L15.1116 22.6704H20.8916L21.1096 18.6094H14.8936Z" fill="white"/>
            </mask>
            <g mask="url(#mask1_4492_7476)">
                <path d="M19.3147 15.8402L13.1797 20.208L13.3264 20.4141L19.4614 16.0463L19.3147 15.8402Z" fill="#F18B68"/>
                <path d="M19.6653 16.3324L13.5303 20.7002L13.677 20.9063L19.812 16.5385L19.6653 16.3324Z" fill="#F18B68"/>
                <path d="M20.0168 16.8255L13.8818 21.1934L14.0286 21.3995L20.1636 17.0316L20.0168 16.8255Z" fill="#F18B68"/>
                <path d="M20.3674 17.3187L14.2324 21.6865L14.3792 21.8926L20.5142 17.5248L20.3674 17.3187Z" fill="#F18B68"/>
                <path d="M20.719 17.8119L14.584 22.1797L14.7307 22.3858L20.8657 18.018L20.719 17.8119Z" fill="#F18B68"/>
                <path d="M21.0696 18.305L14.9346 22.6729L15.0813 22.879L21.2163 18.5111L21.0696 18.305Z" fill="#F18B68"/>
                <path d="M21.4211 18.7982L15.2861 23.166L15.4329 23.3721L21.5679 19.0043L21.4211 18.7982Z" fill="#F18B68"/>
                <path d="M21.7717 19.2904L15.6367 23.6582L15.7835 23.8643L21.9184 19.4965L21.7717 19.2904Z" fill="#F18B68"/>
                <path d="M22.1223 19.7836L15.9873 24.1514L16.134 24.3575L22.269 19.9897L22.1223 19.7836Z" fill="#F18B68"/>
                <path d="M22.4739 20.2767L16.3389 24.6445L16.4856 24.8506L22.6206 20.4828L22.4739 20.2767Z" fill="#F18B68"/>
                <path d="M22.8244 20.7699L16.6895 25.1377L16.8362 25.3438L22.9712 20.976L22.8244 20.7699Z" fill="#F18B68"/>
            </g>
            <path d="M6.36914 19.9497H12.9591L12.8461 19.5967H6.36914V19.9497Z" fill="#CD2822"/>
            <path d="M12.9906 20.2833C12.9906 20.2833 13.3796 20.1733 13.0416 19.9443H6.38063C6.38063 19.9443 6.04263 20.0553 6.30463 20.2203L6.76162 20.4883L9.72863 20.8673L12.9906 20.2833Z" fill="#F4CD22"/>
            <path d="M6.0957 19.4747C6.1457 18.0387 7.7657 16.8867 9.7567 16.8867C11.7477 16.8867 13.3677 18.0397 13.4177 19.4747H6.0957Z" fill="#E06E25"/>
            <path d="M6.86035 19.3485C6.87042 18.9899 6.95585 18.6374 7.11111 18.3139C7.26636 17.9904 7.48795 17.7032 7.76147 17.471C8.035 17.2388 8.35434 17.0668 8.69872 16.9661C9.0431 16.8654 9.40481 16.8383 9.76035 16.8865C11.6923 16.8865 13.2753 17.9725 13.4123 19.3485H6.86035Z" fill="#F7802A"/>
            <path d="M13.1316 19.4746L13.4186 19.7966L12.8716 19.6196L13.0446 20.0366L12.6376 19.6696L12.5046 20.0366L11.9846 19.6576L11.6646 20.1506L11.6116 19.6196L11.2516 20.2076L10.9516 19.7716L10.1716 20.2076L9.89164 19.6766L9.18565 20.1196L8.59264 19.5756L7.92664 20.0496L7.60064 19.6196L7.19264 19.7776L6.58664 19.7206L6.30664 20.0116V19.4746H13.1316Z" fill="#43A340"/>
            <path d="M11.1658 19.4746L10.9318 20.0746L10.2868 19.4746L9.64182 20.0746L9.38281 19.4746H11.1658Z" fill="#2C823C"/>
            <path d="M8.88604 20.0756L8.16504 19.4756H9.15804L8.88604 20.0756Z" fill="#2C823C"/>
            <path d="M6.37257 21.0649H12.9956C13.0655 21.0679 13.1338 21.0431 13.1856 20.9958C13.2373 20.9486 13.2682 20.8829 13.2716 20.8129V20.4339C13.2684 20.3638 13.2376 20.2977 13.1859 20.2503C13.1341 20.2029 13.0657 20.1779 12.9956 20.1809L9.68656 20.5599L6.37756 20.1809C6.30743 20.1779 6.23898 20.2029 6.18723 20.2503C6.13549 20.2977 6.10468 20.3638 6.10156 20.4339V20.8129C6.10493 20.8829 6.13586 20.9486 6.18758 20.9958C6.23929 21.0431 6.3076 21.0679 6.37756 21.0649" fill="#391616"/>
            <path d="M6.91315 22.6955H12.6032C12.8194 22.6955 13.0269 22.6095 13.1798 22.4566C13.3327 22.3037 13.4187 22.0962 13.4187 21.88C13.4187 21.6637 13.3327 21.4563 13.1798 21.3033C13.0269 21.1504 12.8194 21.0645 12.6032 21.0645H6.91315C6.69687 21.0645 6.48945 21.1504 6.33651 21.3033C6.18357 21.4563 6.09766 21.6637 6.09766 21.88C6.09766 22.0962 6.18357 22.3037 6.33651 22.4566C6.48945 22.6095 6.69687 22.6955 6.91315 22.6955Z" fill="#CD6024"/>
            <path d="M12.6032 21.0649C12.6872 21.0318 12.778 21.0198 12.8677 21.0298C12.9574 21.0398 13.0433 21.0717 13.1179 21.1225C13.1926 21.1733 13.2536 21.2416 13.2958 21.3214C13.338 21.4012 13.3601 21.4901 13.3601 21.5804C13.3601 21.6706 13.338 21.7596 13.2958 21.8394C13.2536 21.9192 13.1926 21.9875 13.1179 22.0383C13.0433 22.0891 12.9574 22.1209 12.8677 22.1309C12.778 22.141 12.6872 22.1289 12.6032 22.0959H6.91317C6.82917 22.1289 6.73836 22.141 6.64864 22.1309C6.55893 22.1209 6.47302 22.0891 6.39841 22.0383C6.32379 21.9875 6.26272 21.9192 6.22052 21.8394C6.17832 21.7596 6.15625 21.6706 6.15625 21.5804C6.15625 21.4901 6.17832 21.4012 6.22052 21.3214C6.26272 21.2416 6.32379 21.1733 6.39841 21.1225C6.47302 21.0717 6.55893 21.0398 6.64864 21.0298C6.73836 21.0198 6.82917 21.0318 6.91317 21.0649H12.6032Z" fill="#F78029"/>
            <path d="M12.4326 18.9615C12.4326 18.9827 12.4389 19.0033 12.4507 19.0209C12.4624 19.0385 12.4791 19.0522 12.4987 19.0603C12.5182 19.0684 12.5397 19.0706 12.5605 19.0664C12.5812 19.0623 12.6003 19.0521 12.6153 19.0372C12.6302 19.0222 12.6404 19.0031 12.6446 18.9824C12.6487 18.9616 12.6466 18.9401 12.6385 18.9205C12.6304 18.901 12.6167 18.8843 12.5991 18.8725C12.5815 18.8608 12.5608 18.8545 12.5396 18.8545C12.5112 18.8545 12.484 18.8658 12.464 18.8858C12.4439 18.9059 12.4326 18.9331 12.4326 18.9615Z" fill="#F49F58"/>
            <path d="M11.9316 18.2877C11.9316 18.3088 11.9379 18.3295 11.9497 18.3471C11.9614 18.3647 11.9781 18.3784 11.9977 18.3865C12.0172 18.3946 12.0388 18.3967 12.0595 18.3926C12.0803 18.3885 12.0993 18.3783 12.1143 18.3633C12.1293 18.3484 12.1395 18.3293 12.1436 18.3085C12.1477 18.2878 12.1456 18.2663 12.1375 18.2467C12.1294 18.2272 12.1157 18.2105 12.0981 18.1987C12.0805 18.1869 12.0598 18.1807 12.0386 18.1807C12.0103 18.1807 11.983 18.1919 11.963 18.212C11.9429 18.2321 11.9316 18.2593 11.9316 18.2877Z" fill="#F49F58"/>
            <path d="M11.6263 19.0088C11.6357 19.0279 11.6505 19.0436 11.6688 19.0542C11.6872 19.0648 11.7083 19.0697 11.7294 19.0682C11.7506 19.0668 11.7708 19.0592 11.7876 19.0462C11.8044 19.0333 11.8169 19.0156 11.8237 18.9955C11.8304 18.9755 11.831 18.9538 11.8255 18.9334C11.8199 18.9129 11.8084 18.8946 11.7924 18.8807C11.7764 18.8668 11.7566 18.858 11.7356 18.8553C11.7146 18.8527 11.6932 18.8563 11.6743 18.8658C11.649 18.8785 11.6298 18.9006 11.6208 18.9274C11.6118 18.9542 11.6138 18.9835 11.6263 19.0088Z" fill="#F49F58"/>
            <path d="M11.1771 18.4913C11.1864 18.5103 11.2012 18.5261 11.2196 18.5366C11.238 18.5472 11.2591 18.5521 11.2802 18.5507C11.3014 18.5492 11.3216 18.5416 11.3384 18.5286C11.3552 18.5157 11.3677 18.4981 11.3745 18.478C11.3812 18.4579 11.3818 18.4362 11.3763 18.4158C11.3707 18.3954 11.3592 18.377 11.3432 18.3631C11.3272 18.3492 11.3074 18.3404 11.2864 18.3378C11.2653 18.3351 11.244 18.3388 11.2251 18.3483C11.1998 18.3609 11.1806 18.3831 11.1716 18.4099C11.1626 18.4367 11.1646 18.4659 11.1771 18.4913Z" fill="#F49F58"/>
            <path d="M10.2855 18.2286C10.2948 18.2476 10.3096 18.2634 10.328 18.2739C10.3464 18.2845 10.3675 18.2894 10.3886 18.288C10.4097 18.2866 10.43 18.2789 10.4468 18.2659C10.4635 18.253 10.4761 18.2354 10.4828 18.2153C10.4896 18.1952 10.4902 18.1735 10.4846 18.1531C10.4791 18.1327 10.4676 18.1143 10.4516 18.1004C10.4356 18.0865 10.4158 18.0777 10.3948 18.0751C10.3737 18.0724 10.3524 18.0761 10.3335 18.0856C10.3082 18.0982 10.289 18.1204 10.28 18.1472C10.271 18.174 10.273 18.2032 10.2855 18.2286Z" fill="#F49F58"/>
            <path d="M9.58624 17.7012C9.59562 17.7202 9.61043 17.736 9.62879 17.7466C9.64715 17.7572 9.66825 17.7621 9.68939 17.7606C9.71054 17.7592 9.73078 17.7515 9.74756 17.7386C9.76434 17.7257 9.77689 17.708 9.78364 17.6879C9.79038 17.6678 9.791 17.6462 9.78543 17.6257C9.77986 17.6053 9.76834 17.587 9.75234 17.5731C9.73634 17.5592 9.71657 17.5504 9.69555 17.5477C9.67452 17.5451 9.65318 17.5487 9.63424 17.5582C9.60898 17.5709 9.58975 17.593 9.58076 17.6198C9.57176 17.6466 9.57374 17.6759 9.58624 17.7012Z" fill="#F49F58"/>
            <path d="M10.4984 18.9415C10.5077 18.9605 10.5225 18.9763 10.5409 18.9868C10.5593 18.9974 10.5803 19.0023 10.6015 19.0009C10.6226 18.9994 10.6429 18.9918 10.6597 18.9788C10.6764 18.9659 10.689 18.9482 10.6957 18.9282C10.7025 18.9081 10.7031 18.8864 10.6975 18.866C10.692 18.8455 10.6805 18.8272 10.6645 18.8133C10.6484 18.7994 10.6287 18.7906 10.6077 18.7879C10.5866 18.7853 10.5653 18.789 10.5463 18.7985C10.5211 18.8111 10.5019 18.8333 10.4929 18.8601C10.4839 18.8869 10.4858 18.9161 10.4984 18.9415Z" fill="#F49F58"/>
            <path d="M8.56378 18.9415C8.57316 18.9605 8.58797 18.9763 8.60633 18.9868C8.62469 18.9974 8.64579 19.0023 8.66693 19.0009C8.68807 18.9994 8.70832 18.9918 8.7251 18.9788C8.74188 18.9659 8.75443 18.9482 8.76117 18.9282C8.76791 18.9081 8.76854 18.8864 8.76297 18.866C8.7574 18.8455 8.74588 18.8272 8.72988 18.8133C8.71388 18.7994 8.69411 18.7906 8.67309 18.7879C8.65206 18.7853 8.63072 18.789 8.61178 18.7985C8.58651 18.8111 8.56729 18.8333 8.5583 18.8601C8.5493 18.8869 8.55128 18.9161 8.56378 18.9415Z" fill="#F49F58"/>
            <path d="M8.56378 17.4424C8.57316 17.4614 8.58797 17.4772 8.60633 17.4878C8.62469 17.4984 8.64579 17.5033 8.66693 17.5018C8.68807 17.5004 8.70832 17.4927 8.7251 17.4798C8.74188 17.4669 8.75443 17.4492 8.76117 17.4291C8.76791 17.409 8.76854 17.3874 8.76297 17.367C8.7574 17.3465 8.74588 17.3282 8.72988 17.3143C8.71388 17.3004 8.69411 17.2916 8.67309 17.2889C8.65206 17.2863 8.63072 17.2899 8.61178 17.2994C8.58651 17.3121 8.56729 17.3342 8.5583 17.361C8.5493 17.3878 8.55128 17.4171 8.56378 17.4424Z" fill="#F49F58"/>
            <path d="M10.8528 17.7012C10.8622 17.7202 10.877 17.736 10.8954 17.7466C10.9138 17.7572 10.9348 17.7621 10.956 17.7606C10.9771 17.7592 10.9974 17.7515 11.0142 17.7386C11.0309 17.7257 11.0435 17.708 11.0502 17.6879C11.057 17.6678 11.0576 17.6462 11.052 17.6257C11.0465 17.6053 11.035 17.587 11.019 17.5731C11.0029 17.5592 10.9832 17.5504 10.9622 17.5477C10.9411 17.5451 10.9198 17.5487 10.9008 17.5582C10.8756 17.5709 10.8564 17.593 10.8474 17.6198C10.8384 17.6466 10.8403 17.6759 10.8528 17.7012Z" fill="#F49F58"/>
            <path d="M9.46613 18.6368C9.4755 18.6558 9.49031 18.6716 9.50867 18.6821C9.52704 18.6927 9.54813 18.6976 9.56927 18.6962C9.59042 18.6948 9.61066 18.6871 9.62744 18.6741C9.64422 18.6612 9.65678 18.6436 9.66352 18.6235C9.67026 18.6034 9.67088 18.5817 9.66531 18.5613C9.65974 18.5408 9.64823 18.5225 9.63222 18.5086C9.61622 18.4947 9.59646 18.4859 9.57543 18.4833C9.55441 18.4806 9.53307 18.4843 9.51412 18.4938C9.48886 18.5064 9.46963 18.5286 9.46064 18.5554C9.45165 18.5822 9.45362 18.6114 9.46613 18.6368Z" fill="#F49F58"/>
            <path d="M7.70538 18.4415C7.71476 18.4605 7.72957 18.4763 7.74793 18.4868C7.7663 18.4974 7.78738 18.5023 7.80852 18.5009C7.82967 18.4994 7.84991 18.4918 7.86669 18.4788C7.88347 18.4659 7.89603 18.4482 7.90277 18.4282C7.90951 18.4081 7.91014 18.3864 7.90457 18.366C7.899 18.3455 7.88748 18.3272 7.87148 18.3133C7.85548 18.2994 7.83572 18.2906 7.81469 18.2879C7.79366 18.2853 7.77232 18.289 7.75338 18.2985C7.72812 18.3111 7.70889 18.3333 7.6999 18.3601C7.6909 18.3869 7.69288 18.4161 7.70538 18.4415Z" fill="#F49F58"/>
            <path d="M8.77863 18.1651C8.788 18.1841 8.80281 18.1999 8.82117 18.2105C8.83954 18.221 8.86063 18.2259 8.88177 18.2245C8.90292 18.2231 8.92316 18.2154 8.93994 18.2025C8.95672 18.1895 8.96928 18.1719 8.97602 18.1518C8.98276 18.1317 8.98339 18.1101 8.97782 18.0896C8.97225 18.0692 8.96073 18.0508 8.94473 18.037C8.92873 18.0231 8.90896 18.0142 8.88793 18.0116C8.86691 18.0089 8.84557 18.0126 8.82663 18.0221C8.80137 18.0348 8.78213 18.0569 8.77314 18.0837C8.76415 18.1105 8.76612 18.1398 8.77863 18.1651Z" fill="#F49F58"/>
        </svg>
    )
};

export default CanteenIcon;
