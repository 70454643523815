interface Props{
    width?: number;
    height?: number;
    onClick?: Function;
}

const ImpersonateIcon = ({onClick, width=28, height=29}: Props) => {
    return (
        <svg
            onClick={() => onClick && onClick()}
            className="cursor-pointer"
            xmlns="http://www.w3.org/2000/svg" 
            width={width} 
            height={height} 
            viewBox="0 0 28 29" 
            fill="none">
            <path d="M16.4801 12.3555L16.4731 12.3615V12.3565L16.4801 12.3555Z" fill="#DBA18F"/>
            <path d="M16.2578 12.5613C16.3247 12.4898 16.3966 12.423 16.4728 12.3613L16.1138 12.7193L16.2578 12.5613Z" fill="#DBA18F"/>
            <path d="M17.1589 14.1143C17.0402 13.7422 16.9798 13.3539 16.9799 12.9633V11.9883C16.8099 12.1073 16.6379 12.2233 16.4799 12.3553L16.1169 12.7183C15.9432 12.913 15.7583 13.0974 15.5629 13.2703C15.3957 13.4203 15.211 13.5495 15.0129 13.6553C14.6476 13.7972 14.253 13.847 13.8639 13.8003V14.9643L13.8389 17.1003H13.8619C14.5618 17.0864 15.2493 16.9126 15.8717 16.5921C16.4942 16.2716 17.035 15.813 17.4529 15.2513L17.4789 15.2023C17.5029 15.2093 17.3109 14.5953 17.1589 14.1163" fill="#E0A579"/>
            <path d="M13.8599 14.9659V13.8019C13.8099 13.8019 13.7599 13.8019 13.7079 13.8019C13.6559 13.8019 13.6079 13.8019 13.5519 13.8019C13.4959 13.8019 13.4339 13.8019 13.3739 13.7949C13.3309 13.7949 13.2879 13.7949 13.2459 13.7899C13.1779 13.7829 13.1139 13.7699 13.0459 13.7579C13.0169 13.7519 12.9839 13.7499 12.9549 13.7429C12.866 13.7219 12.7797 13.6907 12.6979 13.6499C12.2176 13.3631 11.7942 12.9902 11.4489 12.5499C11.1671 12.284 10.8611 12.045 10.5349 11.8359L10.4949 13.1909C10.4891 13.3909 10.4589 13.5893 10.4049 13.7819C10.2699 14.2679 10.0139 15.1959 10.0439 15.1869C10.5264 15.8517 11.1789 16.3743 11.9329 16.6999C12.392 16.9009 12.8814 17.0239 13.3809 17.0639C13.4939 17.0719 13.6069 17.0809 13.7199 17.0809H13.8299L13.8599 14.9659Z" fill="#EAB081"/>
            <path d="M18.1362 7.78267L17.8512 10.0697L17.8262 10.2627L17.6432 11.7387C17.6233 11.902 17.546 12.053 17.4252 12.1647L15.8732 13.5967L15.2872 14.1397C15.1045 14.3082 14.8657 14.4027 14.6172 14.4047L13.7722 14.4107L12.9292 14.4167C12.6797 14.4183 12.4385 14.3267 12.2532 14.1597L11.8592 13.8017L10.2112 12.3117C10.0227 12.1417 9.90075 11.9102 9.86716 11.6587L9.68616 10.2877L9.56716 9.38767L9.36716 7.84467C9.35816 7.43167 9.35216 7.02067 9.34416 6.60967C9.44416 5.69267 9.05016 4.94567 9.31116 4.05567C9.35399 3.86714 9.43489 3.68935 9.5489 3.53321C9.66291 3.37707 9.80763 3.24587 9.97416 3.14767C10.1498 3.06455 10.3409 3.01892 10.5352 3.01367C11.5722 3.11421 12.5684 3.4694 13.4352 4.04767C14.0139 4.24303 14.6398 4.24898 15.2222 4.06467C15.4165 4.02036 15.6079 3.96392 15.7952 3.89567C16.0451 3.75786 16.3255 3.68475 16.6109 3.68301C16.8963 3.68126 17.1776 3.75093 17.4292 3.88567C17.6452 3.98699 17.8266 4.14952 17.9511 4.35307C18.0755 4.55662 18.1374 4.79224 18.1292 5.03067C18.1662 5.94267 18.1352 6.86367 18.1392 7.77867" fill="#FFC28E"/>
            <path d="M9.90191 7.82339C9.83891 7.41639 9.79191 7.09239 9.76291 7.00239C9.73391 6.91239 9.69091 6.81439 9.59291 6.79339C9.53991 6.78239 9.48091 6.84439 9.43491 6.81739C9.08191 6.61139 8.86291 6.84739 8.75191 7.17739C8.68869 7.33659 8.6638 7.50842 8.67926 7.67901C8.69472 7.8496 8.7501 8.01416 8.84091 8.15939C9.05091 8.55939 9.18391 8.99739 9.35991 9.41239C9.40491 9.51939 9.59791 10.1264 9.75991 10.1254L10.5289 10.1194C10.2729 10.1194 10.0479 8.75439 9.90391 7.81939" fill="#FFC28E"/>
            <path d="M17.6002 7.76411C17.6387 7.4514 17.7289 7.14725 17.8672 6.86411C17.9432 6.71111 18.1112 6.53211 18.2752 6.61111C18.4941 6.74794 18.6606 6.95431 18.7482 7.19711C18.8912 7.53111 18.7482 7.70612 18.6132 8.02512C18.387 8.72688 18.102 9.4083 17.7612 10.0621L16.9922 10.0681C17.1933 9.57071 17.3604 9.06019 17.4922 8.54011C17.5442 8.26011 17.5642 8.00911 17.6012 7.76211" fill="#FFC28E"/>
            <path d="M9.73886 8.54792C9.66386 7.84792 9.60986 7.14792 9.47786 6.45592C9.41523 5.49128 9.72764 4.53972 10.3499 3.79992C10.4282 3.659 10.5535 3.55001 10.7039 3.49192C10.8146 3.47742 10.9271 3.48563 11.0346 3.51607C11.142 3.5465 11.2422 3.59851 11.3289 3.66892C11.9858 4.01297 12.6681 4.30616 13.3699 4.54592C14.0986 4.57349 14.8283 4.53803 15.5509 4.43992C16.0369 4.33492 16.6949 3.98392 17.1879 4.26692C17.5879 4.49792 17.6989 5.21692 17.8639 5.63692L17.8919 9.10992L18.3469 7.72192C18.4766 7.24131 18.5652 6.75055 18.6119 6.25492C18.7124 5.75088 18.7461 5.23576 18.7119 4.72292C18.6111 4.38049 18.4701 4.05119 18.2919 3.74192C18.1774 3.30085 17.92 2.91015 17.5599 2.63092C17.0497 2.16118 16.3803 1.90244 15.6869 1.90692C16.0939 1.83092 16.8399 1.94192 17.2469 1.86592C15.9521 1.31981 14.5501 1.07498 13.1469 1.14992C12.9255 1.18659 12.7059 1.23359 12.4879 1.29092C11.5865 1.49864 10.7388 1.89351 9.99986 2.44992C9.50334 2.93226 9.13726 3.53264 8.93586 4.19492C8.74094 4.93452 8.71157 5.70801 8.84985 6.46026C8.98813 7.21251 9.29066 7.92499 9.73586 8.54692" fill="#393B42"/>
            <path d="M17.826 10.2617L17.644 11.7367C17.6236 11.8999 17.5464 12.0507 17.426 12.1627L15.874 13.5947L15.288 14.1377C15.1053 14.3062 14.8665 14.4007 14.618 14.4027L13.773 14.4087L12.93 14.4147C12.6805 14.4164 12.4394 14.3247 12.254 14.1577L11.86 13.8007L10.212 12.3107C10.0237 12.1408 9.90206 11.9092 9.86899 11.6577L9.68799 10.2877C10.1993 10.6071 10.7123 10.9241 11.227 11.2387C11.4575 11.3909 11.7181 11.4919 11.991 11.5349C12.2639 11.5778 12.5428 11.5617 12.809 11.4877C13.3713 11.3955 13.944 11.386 14.509 11.4597C14.7652 11.5161 15.0281 11.5367 15.29 11.5207C15.9765 11.3798 16.6211 11.0817 17.173 10.6497C17.249 10.6047 17.815 10.3497 17.826 10.2617Z" fill="#F7BB8D"/>
            <path d="M10.106 14.7285L9.99 14.7735C9.867 14.8225 7.446 16.1165 4.876 17.1785C3.83878 17.6068 2.9203 18.2792 2.19866 19.1386C1.47701 19.998 0.973523 21.0189 0.731 22.1145C0.352 23.8285 0.1 28.0935 0 28.6895H13.644V15.9965C12.3585 15.9679 11.117 15.523 10.106 14.7285Z" fill="#2F5464"/>
            <path d="M26.555 21.946C26.3165 20.8684 25.8257 19.8629 25.1228 19.0121C24.42 18.1613 23.5252 17.4895 22.512 17.052L17.518 14.627C16.449 15.5152 15.1029 16.0013 13.713 16.001H13.645V28.69H27.319C27.211 28.061 26.938 23.678 26.555 21.946Z" fill="#1C4354"/>
            <path d="M8.49805 5.338L8.55505 5.357C8.55505 5.35 8.55505 5.344 8.55505 5.338C10.1945 5.88913 11.9134 6.16717 13.643 6.161V0C12.2616 0.0504815 10.9535 0.634749 9.99393 1.62991C9.03438 2.62508 8.49816 3.95358 8.49805 5.336" fill="#2F5464"/>
            <path d="M18.868 5.32495C18.8647 3.93811 18.3218 2.60699 17.3542 1.61346C16.3866 0.619918 15.0703 0.0419595 13.684 0.00195312H13.644V6.16195H13.693C15.4531 6.17345 17.2026 5.89021 18.869 5.32395" fill="#1C4354"/>
            <path d="M8.5879 5.1513C8.49892 5.1187 8.40438 5.10402 8.3097 5.10809C8.21503 5.11215 8.12209 5.1349 8.03624 5.17501C7.95039 5.21512 7.87331 5.27181 7.80944 5.34181C7.74557 5.41182 7.69616 5.49376 7.66407 5.58292C7.63198 5.67208 7.61783 5.76671 7.62243 5.86136C7.62704 5.95601 7.65031 6.04881 7.6909 6.13444C7.7315 6.22007 7.78862 6.29682 7.85898 6.3603C7.92935 6.42377 8.01156 6.47271 8.1009 6.5043C9.87952 7.14372 11.7548 7.47251 13.6449 7.4763V6.0373C11.9212 6.03395 10.211 5.73425 8.5889 5.1513" fill="#1C4354"/>
            <path d="M19.6991 5.58323C19.6671 5.49427 19.6179 5.41249 19.5543 5.34257C19.4907 5.27265 19.4139 5.21596 19.3284 5.17575C19.2428 5.13553 19.1502 5.11257 19.0558 5.10819C18.9613 5.10381 18.867 5.1181 18.7781 5.15023C17.131 5.74231 15.3933 6.04215 13.6431 6.03623V7.47523H13.6841C15.5872 7.47599 17.4761 7.14713 19.2671 6.50323C19.4465 6.43864 19.5929 6.30543 19.6741 6.1329C19.7553 5.96037 19.7636 5.76265 19.6991 5.58323Z" fill="#183948"/>
            <path d="M13.4141 8.33931C13.4141 8.76431 12.7421 9.10831 11.9141 9.10831C11.0861 9.10831 10.4141 8.76331 10.4141 8.33931C10.4141 7.91531 11.0861 7.57031 11.9141 7.57031C12.7421 7.57031 13.4141 7.91531 13.4141 8.33931Z" fill="#E2CFCF"/>
            <path d="M17.3711 8.21822C17.3711 8.64322 16.6991 8.98722 15.8711 8.98722C15.0431 8.98722 14.3711 8.64222 14.3711 8.21822C14.3711 7.79422 15.0431 7.44922 15.8711 7.44922C16.6991 7.44922 17.3711 7.79422 17.3711 8.21822Z" fill="#E2CFCF"/>
            <path d="M18.5842 6.74023L14.4742 7.17823C14.0002 7.22883 13.5222 7.22883 13.0482 7.17823L8.93817 6.74023C8.93817 6.74023 8.68617 7.04923 8.93817 7.04923C9.19017 7.04923 9.03817 8.41223 10.3712 9.24423C10.6659 9.45862 11.0081 9.59836 11.3686 9.65148C11.7292 9.7046 12.0972 9.66951 12.4412 9.54923C12.7716 9.36761 13.0599 9.11815 13.2872 8.81723C13.6192 8.36723 13.7112 8.29223 14.1252 8.64123C14.4284 8.97282 14.7694 9.26783 15.1412 9.52024C15.5154 9.61887 15.9068 9.63326 16.2872 9.56236C16.6677 9.49146 17.0276 9.33704 17.3412 9.11023C18.5202 8.25223 18.3362 7.04923 18.5872 7.04923C18.8382 7.04923 18.5872 6.74023 18.5872 6.74023M11.8232 8.84023C11.2682 8.84023 10.8232 8.54623 10.8232 8.18523C10.8232 7.82423 11.2732 7.73623 11.8232 7.73623C12.2573 7.78418 12.6729 7.9384 13.0332 8.18523C12.707 8.52307 12.2844 8.75184 11.8232 8.84023ZM15.8232 8.84023C15.362 8.75184 14.9394 8.52307 14.6132 8.18523C14.9735 7.9384 15.3891 7.78418 15.8232 7.73623C16.3782 7.73623 16.8232 7.82423 16.8232 8.18523C16.8232 8.54623 16.3732 8.84023 15.8232 8.84023Z" fill="#393B42"/>
            <path d="M15.1621 12.0531H12.3521L13.0221 11.8711H14.5531L15.1621 12.0531Z" fill="#E0A579"/>
            <path d="M12.8018 12.5664H14.7158L14.2598 12.8754H13.2208L12.8018 12.5664Z" fill="#E0A579"/>
            <path d="M12.1339 8.3013C12.1341 8.35538 12.1182 8.4083 12.0883 8.45335C12.0584 8.49839 12.0158 8.53354 11.9658 8.55432C11.9159 8.57511 11.8609 8.5806 11.8079 8.5701C11.7548 8.5596 11.7061 8.53358 11.6679 8.49534C11.6296 8.4571 11.6036 8.40836 11.5931 8.35531C11.5826 8.30226 11.5881 8.24729 11.6089 8.19736C11.6296 8.14744 11.6648 8.10481 11.7098 8.07489C11.7549 8.04497 11.8078 8.0291 11.8619 8.0293C11.9339 8.02956 12.003 8.0583 12.0539 8.10926C12.1049 8.16021 12.1336 8.22924 12.1339 8.3013Z" fill="#393B42"/>
            <path d="M15.7892 8.3013C15.7894 8.35533 15.7735 8.40821 15.7436 8.45324C15.7138 8.49826 15.6712 8.53341 15.6213 8.55423C15.5715 8.57504 15.5165 8.58059 15.4635 8.57017C15.4105 8.55975 15.3618 8.53383 15.3235 8.49569C15.2852 8.45755 15.2591 8.40891 15.2485 8.35593C15.2379 8.30294 15.2432 8.248 15.2638 8.19806C15.2845 8.14811 15.3195 8.10542 15.3644 8.07538C15.4093 8.04533 15.4621 8.0293 15.5162 8.0293C15.5884 8.0293 15.6577 8.05792 15.7088 8.1089C15.76 8.15988 15.7889 8.22907 15.7892 8.3013Z" fill="#393B42"/>
            <path d="M13.0962 10.7852C13.0962 10.7852 13.9442 11.2482 14.4062 10.7852C14.5062 11.4532 13.1732 11.5042 13.0962 10.7852Z" fill="#E0A579"/>
        </svg>
    )
};

export default ImpersonateIcon;
