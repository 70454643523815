import React, { FC, useRef, useState } from "react";
import UpComingEventListItem from "./UpComingEventListItem";
import SuperAdminReportsDropDown from "components/reports/superAdmin/DropDowns/SuperAdminReportsDropDown";
import { useIsSuperAdmin } from "hooks/useIsSuperAdmin";
import UpComingEventSkeleton from "./Skeleton/UpComingEventSkeleton";
import { Skeleton } from "components/common/skeleton";

const ActiveVendorDropDownOptions = [
    { label: "Upcoming Events Next 30 Days", value: "UPCOMING_EVENT_NEXT_30_DAYS" },
  { label: "Completed Events Last 30 Days", value: "COMPLETED_EVENTS_LAST_30_DAYS"}
 
];

interface Props {
  eventsList?: any;
  events_count: number;
  isLoading?: boolean;
  fetchData: () => Promise<void>;
  event_offset: number;
  isEventFetching: boolean;
  fetchEvents?:Function
  dashboardQueryParams?:any
  eventsLoading?: any 
  
}

const UpComingEvents: FC<Props> = ({ eventsList, events_count, isLoading, isEventFetching, fetchData, event_offset, fetchEvents, dashboardQueryParams, eventsLoading }) => {
  const [actiVendorOption, setActiveVendorOption] = useState<string>(
    ActiveVendorDropDownOptions[0].value
  );
  const [scrollTopVal, setScrollTopVal] = useState(0);

  const fetchingEventsRef = useRef<any>(false);

  const isSuperAdmin = useIsSuperAdmin();

  const toalEvents = eventsList?.reduce((acc:number, curr: any) => {
    acc += curr?.events?.length;
    return acc;
  },0)

  const isRecordsRemaining = toalEvents < events_count;
  const handleScroll = async (e: any) => {
    const { scrollTop, scrollHeight } = e.target;
    setScrollTopVal(scrollTop);

    if (scrollTop > 0 && isRecordsRemaining && !fetchingEventsRef.current && scrollTopVal < scrollTop){
      const scrollValue = scrollTop + 495
      const threshold = scrollHeight - (scrollHeight * 0.15);

      if (scrollValue >= threshold) {
        fetchingEventsRef.current = true;
        await fetchData();
        fetchingEventsRef.current = false;
      }
    }
  };

  const activeVendorsOptionsChangeHandler = (
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
   
    setActiveVendorOption(e.target.value);
    fetchEvents && fetchEvents({...dashboardQueryParams, offset: 0, event_filter: e.target.value});
    console.log("RAN");
  };

  return (
    <div className="upComingEvents">
      <div className="lastUpcomingEvents">
        <div className="select-container">
          <SuperAdminReportsDropDown
            value={actiVendorOption}
            options={ActiveVendorDropDownOptions}
            onChange={activeVendorsOptionsChangeHandler}
          />
        </div>

        {!isSuperAdmin ? (
          <p ></p>
        ) : (
          <p className="totalEvents">{events_count}</p>
        )}
      </div>

      <div className="upcomingEventsItemsParent">
        <div onScroll={handleScroll} className="upcomingEventsItems scroll">
          {(isLoading || eventsLoading) && event_offset === 0 ? (
            <UpComingEventSkeleton isStatic = {false} />
          ) : eventsList.length<1 ? <UpComingEventSkeleton isStatic = {true} /> : (
            <>
              {eventsList?.map((month_events: any, index:number) => {
                const isMonthNameAlreadyExists = eventsList?.slice(0, index)?.some((list: any) => list.name?.toLowerCase() === month_events?.name?.toLowerCase())

                return (
                    <UpComingEventListItem
                      monthEvents={month_events.events}
                      monthName={month_events.name}
                      isMonthNameAlreadyExists={isMonthNameAlreadyExists}
                    />
                )
              })}
              {isEventFetching && event_offset > 0 && (
                <div
                  className="upComingEventSkeloton"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <div className="SkeletonEvents">
                    <div className="dateTime">
                      <Skeleton />
                    </div>
                    <div className="eventName">
                      <Skeleton />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          {toalEvents > 10 && scrollTopVal < 10 && (
            <div className='more-data'></div>
          )}
        </div>
      </div>
    </div>
  );
};

export default UpComingEvents;
