import { FC } from "react";

interface Props  {
    isDisabled? : boolean,
}
const BookIcon: FC<Props> = ({isDisabled}) => {
    return (
        <>
            {/* {!isDisabled && <svg className="active-tab-svg" width="39" height="30" viewBox="0 0 39 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.7409 2.05195C4.30179 2.07729 3.95557 2.4404 3.95557 2.88795V20.6213C3.95557 21.1111 4.36934 21.4995 4.85912 21.4742C8.76046 21.2968 12.6618 21.6684 16.4702 22.572L18.6405 23.0871C18.9022 23.1462 19.164 22.952 19.164 22.6733V4.12929C19.164 3.93507 19.0289 3.75773 18.8347 3.71551L15.6511 2.98929C12.0707 2.17862 8.39734 1.86618 4.7409 2.05195Z" fill="#696969"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M35.2589 2.05195C31.6025 1.86618 27.9291 2.17862 24.3571 2.98929L21.1736 3.71551C20.9793 3.75773 20.8442 3.92662 20.8442 4.12929V22.6817C20.8442 22.952 21.0976 23.1546 21.3678 23.0955L23.538 22.5804C27.338 21.6768 31.2478 21.3053 35.1407 21.4826C35.6305 21.508 36.0442 21.1195 36.0442 20.6297V2.88795C36.0442 2.44884 35.698 2.07729 35.2589 2.05195ZM28.4442 18.1471C25.1762 18.1471 22.5331 15.504 22.5331 12.236C22.5331 8.96796 25.1762 6.32484 28.4442 6.32484C31.7122 6.32484 34.3553 8.96796 34.3553 12.236C34.3553 15.504 31.7122 18.1471 28.4442 18.1471Z" fill="#696969"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.8702 16.2635C27.8027 16.2635 27.7436 16.2381 27.7013 16.1959C27.6591 16.1537 27.6338 16.0861 27.6338 16.0101V11.2475C27.6338 11.1715 27.6591 11.1124 27.7013 11.0617C27.752 11.011 27.8027 10.9941 27.8702 10.9941H28.8836C28.9596 10.9941 29.0187 11.0195 29.0693 11.0617C29.12 11.1124 29.1369 11.1715 29.1369 11.2475V16.0101C29.1369 16.0861 29.1116 16.1453 29.0693 16.1959C29.0187 16.2466 28.9596 16.2635 28.8836 16.2635H27.8702Z" fill="#696969"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.8448 10.1838C27.7773 10.1838 27.7182 10.1585 27.676 10.1163C27.6253 10.0656 27.6084 10.0065 27.6084 9.9305V9.11983C27.6084 9.04383 27.6337 8.98472 27.676 8.93405C27.7266 8.88339 27.7773 8.84961 27.8448 8.84961H28.9088C28.9848 8.84961 29.044 8.87494 29.0946 8.93405C29.1453 8.98472 29.1791 9.04383 29.1791 9.11983V9.9305C29.1791 10.0065 29.1537 10.0656 29.0946 10.1163C29.044 10.1669 28.9848 10.1838 28.9088 10.1838H27.8448Z" fill="#696969"/>
                <path opacity="0.4" fill-rule="evenodd" clip-rule="evenodd" d="M1.84444 4.53418H2.68889V21.0008C2.68889 21.9297 3.44889 22.6897 4.37778 22.6897H10.1791C12.0031 22.6897 13.8187 22.9008 15.592 23.3146L20 24.3533L24.408 23.3146C26.1813 22.9008 27.9969 22.6897 29.8209 22.6897H35.6222C36.5511 22.6897 37.3111 21.9297 37.3111 21.0008V4.53418H38.1556C38.62 4.53418 39 4.91418 39 5.37862V22.6897C39 23.6186 38.24 24.3786 37.3111 24.3786H29.8209C28.1236 24.3786 26.4431 24.5728 24.788 24.9613L19.9916 26.0928L15.1951 24.9613C13.5484 24.5728 11.8596 24.3786 10.1707 24.3786H2.68889C1.76 24.3786 1 23.6186 1 22.6897V5.37862C1 4.91418 1.38 4.53418 1.84444 4.53418Z" fill="#696969"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M34.2589 0.0519548C30.6025 -0.133823 26.9291 0.178621 23.3571 0.989288L20.1736 1.71551C19.9793 1.75773 19.8442 1.92662 19.8442 2.12929V20.6817C19.8442 20.952 20.0976 21.1546 20.3678 21.0955L22.538 20.5804C26.338 19.6768 30.2478 19.3053 34.1407 19.4826C34.6305 19.508 35.0442 19.1195 35.0442 18.6297V0.887955C35.0442 0.448844 34.698 0.0772881 34.2589 0.0519548ZM27.4442 16.1471C24.1762 16.1471 21.5331 13.504 21.5331 10.236C21.5331 6.96796 24.1762 4.32484 27.4442 4.32484C30.7122 4.32484 33.3553 6.96796 33.3553 10.236C33.3553 13.504 30.7122 16.1471 27.4442 16.1471Z" fill="#26CAD3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.8702 14.2635C26.8027 14.2635 26.7436 14.2381 26.7013 14.1959C26.6591 14.1537 26.6338 14.0861 26.6338 14.0101V9.24747C26.6338 9.17147 26.6591 9.11236 26.7013 9.0617C26.752 9.01103 26.8027 8.99414 26.8702 8.99414H27.8836C27.9596 8.99414 28.0187 9.01947 28.0693 9.0617C28.12 9.11236 28.1369 9.17147 28.1369 9.24747V14.0101C28.1369 14.0861 28.1116 14.1453 28.0693 14.1959C28.0187 14.2466 27.9596 14.2635 27.8836 14.2635H26.8702Z" fill="#26CAD3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.8448 8.18383C26.7773 8.18383 26.7182 8.1585 26.676 8.11628C26.6253 8.06561 26.6084 8.0065 26.6084 7.9305V7.11983C26.6084 7.04383 26.6337 6.98472 26.676 6.93405C26.7266 6.88339 26.7773 6.84961 26.8448 6.84961H27.9088C27.9848 6.84961 28.044 6.87494 28.0946 6.93405C28.1453 6.98472 28.1791 7.04383 28.1791 7.11983V7.9305C28.1791 8.0065 28.1537 8.06561 28.0946 8.11628C28.044 8.16694 27.9848 8.18383 27.9088 8.18383H26.8448Z" fill="#26CAD3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.844444 2.53418H1.68889V19.0008C1.68889 19.9297 2.44889 20.6897 3.37778 20.6897H9.17911C11.0031 20.6897 12.8187 20.9008 14.592 21.3146L19 22.3533L23.408 21.3146C25.1813 20.9008 26.9969 20.6897 28.8209 20.6897H34.6222C35.5511 20.6897 36.3111 19.9297 36.3111 19.0008V2.53418H37.1556C37.62 2.53418 38 2.91418 38 3.37862V20.6897C38 21.6186 37.24 22.3786 36.3111 22.3786H28.8209C27.1236 22.3786 25.4431 22.5728 23.788 22.9613L18.9916 24.0928L14.1951 22.9613C12.5484 22.5728 10.8596 22.3786 9.17067 22.3786H1.68889C0.76 22.3786 0 21.6186 0 20.6897V3.37862C0 2.91418 0.38 2.53418 0.844444 2.53418Z" fill="#4285F4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.7409 0.0519548C3.30179 0.0772881 2.95557 0.440399 2.95557 0.887955V18.6213C2.95557 19.1111 3.36934 19.4995 3.85912 19.4742C7.76046 19.2968 11.6618 19.6684 15.4702 20.572L17.6405 21.0871C17.9022 21.1462 18.164 20.952 18.164 20.6733V2.12929C18.164 1.93507 18.0289 1.75773 17.8347 1.71551L14.6511 0.989288C11.0707 0.178621 7.39734 -0.133823 3.7409 0.0519548Z" fill="#26CAD3"/>
            </svg>} */}
            <svg className="non-active-svg" xmlns="http://www.w3.org/2000/svg" width="31" height="20" viewBox="0 0 31 20" fill="none">
                <g opacity="0.4">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M4.3085 1.03902C3.97875 1.05804 3.71875 1.33072 3.71875 1.66681V14.9837C3.71875 15.3515 4.02948 15.6432 4.39728 15.6242C7.327 15.491 10.2567 15.7701 13.1167 16.4486L14.7464 16.8354C14.943 16.8798 15.1396 16.7339 15.1396 16.5247V2.599C15.1396 2.45314 15.0381 2.31998 14.8923 2.28827L12.5016 1.74291C9.81282 1.13414 7.05432 0.899505 4.3085 1.03902Z" fill="#222222"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.2261 1.03902C24.4803 0.899505 21.7218 1.13414 19.0394 1.74291L16.6487 2.28827C16.5028 2.31998 16.4014 2.4468 16.4014 2.599V16.531C16.4014 16.7339 16.5916 16.8861 16.7945 16.8417L18.4243 16.4549C21.2779 15.7764 24.214 15.4974 27.1373 15.6305C27.5051 15.6496 27.8159 15.3579 27.8159 14.9901V1.66681C27.8159 1.33706 27.5559 1.05804 27.2261 1.03902ZM22.1086 13.1257C19.6545 13.1257 17.6696 11.1408 17.6696 8.68672C17.6696 6.23261 19.6545 4.24776 22.1086 4.24776C24.5627 4.24776 26.5476 6.23261 26.5476 8.68672C26.5476 11.1408 24.5627 13.1257 22.1086 13.1257Z" fill="#222222"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6776 11.709C21.6268 11.709 21.5824 11.69 21.5507 11.6582C21.519 11.6265 21.5 11.5758 21.5 11.5187V7.94219C21.5 7.88512 21.519 7.84073 21.5507 7.80268C21.5888 7.76464 21.6268 7.75195 21.6776 7.75195H22.4385C22.4956 7.75195 22.54 7.77098 22.578 7.80268C22.6161 7.84073 22.6288 7.88512 22.6288 7.94219V11.5187C22.6288 11.5758 22.6097 11.6202 22.578 11.6582C22.54 11.6963 22.4956 11.709 22.4385 11.709H21.6776Z" fill="#222222"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M21.658 7.14452C21.6073 7.14452 21.5629 7.12549 21.5312 7.09379C21.4932 7.05574 21.4805 7.01135 21.4805 6.95428V6.3455C21.4805 6.28843 21.4995 6.24404 21.5312 6.20599C21.5692 6.16794 21.6073 6.14258 21.658 6.14258H22.457C22.5141 6.14258 22.5585 6.1616 22.5966 6.20599C22.6346 6.24404 22.66 6.28843 22.66 6.3455V6.95428C22.66 7.01135 22.6409 7.05574 22.5966 7.09379C22.5585 7.13183 22.5141 7.14452 22.457 7.14452H21.658Z" fill="#222222"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.13414 2.90234H2.76828V15.268C2.76828 15.9656 3.339 16.5363 4.03655 16.5363H8.39308C9.76282 16.5363 11.1262 16.6949 12.4579 17.0056L15.7681 17.7856L19.0783 17.0056C20.41 16.6949 21.7734 16.5363 23.1431 16.5363H27.4997C28.1972 16.5363 28.7679 15.9656 28.7679 15.268V2.90234H29.4021C29.7509 2.90234 30.0362 3.18771 30.0362 3.53648V16.5363C30.0362 17.2339 29.4655 17.8046 28.7679 17.8046H23.1431C21.8685 17.8046 20.6066 17.9504 19.3637 18.2422L15.7618 19.0919L12.1599 18.2422C10.9233 17.9504 9.65502 17.8046 8.38674 17.8046H2.76828C2.07072 17.8046 1.5 17.2339 1.5 16.5363V3.53648C1.5 3.18771 1.78536 2.90234 2.13414 2.90234Z" fill="#222222"/>
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.53994 0.0390156C3.21019 0.0580398 2.9502 0.330719 2.9502 0.666813V13.9837C2.9502 14.3515 3.26092 14.6432 3.62872 14.6242C6.55844 14.491 9.48816 14.7701 12.3481 15.4486L13.9779 15.8354C14.1744 15.8798 14.371 15.7339 14.371 15.5247V1.599C14.371 1.45314 14.2696 1.31998 14.1237 1.28827L11.733 0.742909C9.04427 0.134136 6.28576 -0.100495 3.53994 0.0390156Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.4576 0.0390156C23.7117 -0.100495 20.9532 0.134136 18.2708 0.742909L15.8801 1.28827C15.7343 1.31998 15.6328 1.4468 15.6328 1.599V15.531C15.6328 15.7339 15.8231 15.8861 16.026 15.8417L17.6557 15.4549C20.5093 14.7764 23.4454 14.4974 26.3688 14.6305C26.7366 14.6496 27.0473 14.3579 27.0473 13.9901V0.666813C27.0473 0.337061 26.7873 0.0580398 26.4576 0.0390156ZM21.3401 12.1257C18.8859 12.1257 16.9011 10.1408 16.9011 7.68672C16.9011 5.23261 18.8859 3.24776 21.3401 3.24776C23.7942 3.24776 25.779 5.23261 25.779 7.68672C25.779 10.1408 23.7942 12.1257 21.3401 12.1257Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.909 10.709C20.8583 10.709 20.8139 10.69 20.7822 10.6582C20.7505 10.6265 20.7314 10.5758 20.7314 10.5187V6.94219C20.7314 6.88512 20.7505 6.84073 20.7822 6.80268C20.8202 6.76464 20.8583 6.75195 20.909 6.75195H21.67C21.727 6.75195 21.7714 6.77098 21.8095 6.80268C21.8475 6.84073 21.8602 6.88512 21.8602 6.94219V10.5187C21.8602 10.5758 21.8412 10.6202 21.8095 10.6582C21.7714 10.6963 21.727 10.709 21.67 10.709H20.909Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.8895 6.14452C20.8387 6.14452 20.7944 6.12549 20.7626 6.09379C20.7246 6.05574 20.7119 6.01135 20.7119 5.95428V5.3455C20.7119 5.28843 20.7309 5.24404 20.7626 5.20599C20.8007 5.16794 20.8387 5.14258 20.8895 5.14258H21.6885C21.7456 5.14258 21.7899 5.1616 21.828 5.20599C21.866 5.24404 21.8914 5.28843 21.8914 5.3455V5.95428C21.8914 6.01135 21.8724 6.05574 21.828 6.09379C21.7899 6.13183 21.7456 6.14452 21.6885 6.14452H20.8895Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.36558 1.90234H1.99972V14.268C1.99972 14.9656 2.57045 15.5363 3.268 15.5363H7.62453C8.99427 15.5363 10.3577 15.6949 11.6894 16.0056L14.9996 16.7856L18.3098 16.0056C19.6415 15.6949 21.0048 15.5363 22.3746 15.5363H26.7311C27.4287 15.5363 27.9994 14.9656 27.9994 14.268V1.90234H28.6335C28.9823 1.90234 29.2677 2.18771 29.2677 2.53648V15.5363C29.2677 16.2339 28.6969 16.8046 27.9994 16.8046H22.3746C21.1 16.8046 19.838 16.9504 18.5951 17.2422L14.9932 18.0919L11.3913 17.2422C10.1547 16.9504 8.88646 16.8046 7.61819 16.8046H1.99972C1.30217 16.8046 0.731445 16.2339 0.731445 15.5363V2.53648C0.731445 2.18771 1.01681 1.90234 1.36558 1.90234Z" fill="white"/>
            </svg>

            <svg className="svg-mob" xmlns="http://www.w3.org/2000/svg" width="31" height="20" viewBox="0 0 31 20" fill="none">
                <g opacity="0.4">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.3085 1.67573C3.97875 1.69476 3.71875 1.96744 3.71875 2.30353V15.6204C3.71875 15.9882 4.02948 16.2799 4.39728 16.2609C7.327 16.1277 10.2567 16.4068 13.1167 17.0853L14.7464 17.4721C14.943 17.5165 15.1396 17.3707 15.1396 17.1614V3.23571C15.1396 3.08986 15.0381 2.95669 14.8923 2.92499L12.5016 2.37963C9.81282 1.77086 7.05432 1.53622 4.3085 1.67573Z" fill="#222222"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M27.2261 1.67573C24.4803 1.53622 21.7218 1.77086 19.0394 2.37963L16.6487 2.92499C16.5028 2.95669 16.4014 3.08352 16.4014 3.23571V17.1677C16.4014 17.3707 16.5916 17.5229 16.7945 17.4785L18.4243 17.0916C21.2779 16.4131 24.214 16.1341 27.1373 16.2673C27.5051 16.2863 27.8159 15.9946 27.8159 15.6268V2.30353C27.8159 1.97378 27.5559 1.69476 27.2261 1.67573ZM22.1086 13.7624C19.6545 13.7624 17.6696 11.7776 17.6696 9.32344C17.6696 6.86933 19.6545 4.88447 22.1086 4.88447C24.5627 4.88447 26.5476 6.86933 26.5476 9.32344C26.5476 11.7776 24.5627 13.7624 22.1086 13.7624Z" fill="#222222"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.6776 12.3457C21.6268 12.3457 21.5824 12.3267 21.5507 12.295C21.519 12.2633 21.5 12.2125 21.5 12.1555V8.57891C21.5 8.52184 21.519 8.47745 21.5507 8.4394C21.5888 8.40135 21.6268 8.38867 21.6776 8.38867H22.4385C22.4956 8.38867 22.54 8.4077 22.578 8.4394C22.6161 8.47745 22.6288 8.52184 22.6288 8.57891V12.1555C22.6288 12.2125 22.6097 12.2569 22.578 12.295C22.54 12.333 22.4956 12.3457 22.4385 12.3457H21.6776Z" fill="#222222"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M21.658 7.78124C21.6073 7.78124 21.5629 7.76221 21.5312 7.7305C21.4932 7.69246 21.4805 7.64807 21.4805 7.59099V6.98222C21.4805 6.92515 21.4995 6.88076 21.5312 6.84271C21.5692 6.80466 21.6073 6.7793 21.658 6.7793H22.457C22.5141 6.7793 22.5585 6.79832 22.5966 6.84271C22.6346 6.88076 22.66 6.92515 22.66 6.98222V7.59099C22.66 7.64807 22.6409 7.69246 22.5966 7.7305C22.5585 7.76855 22.5141 7.78124 22.457 7.78124H21.658Z" fill="#222222"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.13414 3.53906H2.76828V15.9048C2.76828 16.6023 3.339 17.173 4.03655 17.173H8.39308C9.76282 17.173 11.1262 17.3316 12.4579 17.6423L15.7681 18.4223L19.0783 17.6423C20.41 17.3316 21.7734 17.173 23.1431 17.173H27.4997C28.1972 17.173 28.7679 16.6023 28.7679 15.9048V3.53906H29.4021C29.7509 3.53906 30.0362 3.82442 30.0362 4.1732V17.173C30.0362 17.8706 29.4655 18.4413 28.7679 18.4413H23.1431C21.8685 18.4413 20.6066 18.5872 19.3637 18.8789L15.7618 19.7286L12.1599 18.8789C10.9233 18.5872 9.65502 18.4413 8.38674 18.4413H2.76828C2.07072 18.4413 1.5 17.8706 1.5 17.173V4.1732C1.5 3.82442 1.78536 3.53906 2.13414 3.53906Z" fill="#222222"/>
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.54043 0.675734C3.21068 0.694759 2.95068 0.967438 2.95068 1.30353V14.6204C2.95068 14.9882 3.26141 15.2799 3.62921 15.2609C6.55893 15.1277 9.48865 15.4068 12.3486 16.0853L13.9783 16.4721C14.1749 16.5165 14.3715 16.3707 14.3715 16.1614V2.23571C14.3715 2.08986 14.2701 1.95669 14.1242 1.92499L11.7335 1.37963C9.04475 0.770855 6.28625 0.536224 3.54043 0.675734Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M26.458 0.675734C23.7122 0.536224 20.9537 0.770855 18.2713 1.37963L15.8806 1.92499C15.7348 1.95669 15.6333 2.08352 15.6333 2.23571V16.1677C15.6333 16.3707 15.8235 16.5229 16.0265 16.4785L17.6562 16.0916C20.5098 15.4131 23.4459 15.1341 26.3693 15.2673C26.7371 15.2863 27.0478 14.9946 27.0478 14.6268V1.30353C27.0478 0.973779 26.7878 0.694759 26.458 0.675734ZM21.3405 12.7624C18.8864 12.7624 16.9016 10.7776 16.9016 8.32344C16.9016 5.86933 18.8864 3.88447 21.3405 3.88447C23.7947 3.88447 25.7795 5.86933 25.7795 8.32344C25.7795 10.7776 23.7947 12.7624 21.3405 12.7624Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.909 11.3467C20.8583 11.3467 20.8139 11.3276 20.7822 11.2959C20.7505 11.2642 20.7314 11.2135 20.7314 11.1564V7.57989C20.7314 7.52282 20.7505 7.47843 20.7822 7.44038C20.8202 7.40233 20.8583 7.38965 20.909 7.38965H21.67C21.727 7.38965 21.7714 7.40867 21.8095 7.44038C21.8475 7.47843 21.8602 7.52282 21.8602 7.57989V11.1564C21.8602 11.2135 21.8412 11.2579 21.8095 11.2959C21.7714 11.334 21.727 11.3467 21.67 11.3467H20.909Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.89 6.78124C20.8392 6.78124 20.7948 6.76221 20.7631 6.7305C20.7251 6.69246 20.7124 6.64807 20.7124 6.59099V5.98222C20.7124 5.92515 20.7314 5.88076 20.7631 5.84271C20.8012 5.80466 20.8392 5.7793 20.89 5.7793H21.689C21.746 5.7793 21.7904 5.79832 21.8285 5.84271C21.8665 5.88076 21.8919 5.92515 21.8919 5.98222V6.59099C21.8919 6.64807 21.8729 6.69246 21.8285 6.7305C21.7904 6.76855 21.746 6.78124 21.689 6.78124H20.89Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.36558 2.53906H1.99972V14.9048C1.99972 15.6023 2.57045 16.173 3.268 16.173H7.62453C8.99427 16.173 10.3577 16.3316 11.6894 16.6423L14.9996 17.4223L18.3098 16.6423C19.6415 16.3316 21.0048 16.173 22.3746 16.173H26.7311C27.4287 16.173 27.9994 15.6023 27.9994 14.9048V2.53906H28.6335C28.9823 2.53906 29.2677 2.82442 29.2677 3.1732V16.173C29.2677 16.8706 28.6969 17.4413 27.9994 17.4413H22.3746C21.1 17.4413 19.838 17.5872 18.5951 17.8789L14.9932 18.7286L11.3913 17.8789C10.1547 17.5872 8.88646 17.4413 7.61819 17.4413H1.99972C1.30217 17.4413 0.731445 16.8706 0.731445 16.173V3.1732C0.731445 2.82442 1.01681 2.53906 1.36558 2.53906Z" fill="white"/>
            </svg>
        </>
    )
};

export default BookIcon;
