import { useEffect, useRef, useState } from "react";

interface IProps {
    children: any,
    cursorPointer?: boolean;
    customClass?: string;
    onClickData?: Function;
    isTooltip?: boolean;
}

const TableData = ({ children, onClickData, cursorPointer, customClass, isTooltip = true }: IProps) => {
    const customerRef = useRef<any>(null);
    const [tooltip, setTooltip] = useState(false);

    useEffect(() => {
        if (children && customerRef.current) {
            setTooltip(customerRef.current.scrollWidth - customerRef.current.clientWidth > 0)
        }
    }, [children, customerRef])
    return (
        <div onClick={() => onClickData && onClickData()} className={`td ${isTooltip ? 'show-truncate-tooltip' : ''} ${cursorPointer ? 'cursor-pointer' : ''} ${customClass || ''}`}>
            <p ref={customerRef}>{children}</p>
            {isTooltip && tooltip && (
                <span className="truncate-tooltip">{children}</span>
            )}
        </div>
    )
};

export default TableData;
