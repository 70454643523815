/* eslint-disable no-useless-escape */
import * as Yup from 'yup';
import { phoneValidation, zipValidation } from './common';

export const addVenueSchema = Yup.object({
    name: Yup.string().trim().required('Name is required'),
    phone: phoneValidation,
    address: Yup.string().trim().required('Address is required'),
    city: Yup.string().trim().required('City is required'),
    state: Yup.string().required('State is required'),
    zipcode: zipValidation
})