import React, { FC, useContext, useRef, useState } from "react";
import TableHeader from "./TableHeader";
import OrderListItem from "./OrderListItem";
import { ReportContext } from "contexts/report";
import { ReportActions } from "contexts/reducers/report";
import { Skeleton } from "components/common/skeleton";
//import { ReportContext } from 'contexts/report'



interface Props {
  activeTab: number;
  fetchOrders: () => Promise<any>
}




const VendorReportItemsTable: FC<Props> = ({activeTab, fetchOrders}) => {
  //const {selectedEvent, selectedVenue} = useContext(ReportContext);
  const [scrollTopVal, setScrollTopVal] = useState(0);
  const fetchingEventsRef = useRef<boolean>(false);
  const {
    allOrders,
    loading,
    fetchOrderDetail,
    orderQueryParams,
    
  } = useContext(ReportContext);
  const { orders, _count } = allOrders;

  const emptyRowsCount = orders?.length < 10 ? 10 - orders.length : 0;
  const emptyRows = emptyRowsCount > 0 ? [...new Array(emptyRowsCount)] : [];
  const isLoading = loading[ReportActions.GET_ORDERS];
  const fetchingMore = loading[ReportActions.GET_MORE_ORDERS];
  const slaesLoading = loading[ReportActions.GET_EVENT_SALES];
  const eventDetailsLoading = loading[ReportActions.GET_EVENT_DETAILS];

  const handleClickOrder = async (orderId: any) => {
    await fetchOrderDetail(orderId);
  };

  const isRecordsRemaining = orders.length < _count;
  const handleScroll = async (e: any) => {
    const { scrollTop, scrollHeight } = e.target;
    setScrollTopVal(scrollTop);

    if (
      scrollTop > 0 &&
      isRecordsRemaining &&
      !fetchingEventsRef.current &&
      scrollTopVal < scrollTop
    ) {
      const scrollValue = scrollTop + 510;
      const threshold = scrollHeight - scrollHeight * 0.15;

      if (scrollValue >= threshold) {
        fetchingEventsRef.current = true;

        await fetchOrders();
        fetchingEventsRef.current = false;
      }
    }
  };

  return (
    <div className="vendorItemsTable">
      <TableHeader activeTab = {activeTab} />
      {isLoading || slaesLoading || eventDetailsLoading ? (
        <div className="vendor-item-table-body">
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
          <Skeleton />
        </div>
      ) : (
        <div onScroll={handleScroll} className="vendor-item-table-body">
          {orders?.map((order: any, index: number) => (
            <OrderListItem
              key={index}
              order={order}
              onClickOrder={handleClickOrder}
            />
          ))}
          {orderQueryParams.offset > 0 &&
            fetchingMore &&
            isRecordsRemaining && (
              <>
                <Skeleton />
                <Skeleton />
                <Skeleton />
                <Skeleton />
              </>
            )}
          {emptyRows?.map((_, index) => (
            <OrderListItem key={index + "_oli"} isEmpty={true} />
          ))}
          {orders?.length > 10 && scrollTopVal < 10 && (
            <div className="more-data"></div>
          )}
        </div>
      )}
    </div>
  );
};

export default VendorReportItemsTable;
