import { VendorContext } from "contexts/vendor";
import { useContext, useEffect, useRef, useState } from "react"
import { Skeleton } from "./common/skeleton";
import { VendorActions } from "contexts/reducers/vendor";

const VendorName = () => {
    const { vendorDetails, loading } = useContext(VendorContext);
    const vendorRef = useRef<any>(null);

    const isLoading = loading[VendorActions.GET_VENDOR_DETAILS];
    const [tooltip, setTooltip] = useState(false);

    useEffect(() => {
        if(vendorDetails?.vendor?.name && vendorRef?.current){
            setTooltip(vendorRef?.current ? vendorRef?.current?.scrollWidth - vendorRef?.current?.clientWidth > 0 : false)
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[vendorDetails, vendorRef?.current])

    return (
        <div className={`sideTextRotate ${tooltip ? 'show-full-name' : ''}`}>
            <p ref={vendorRef} className={`${tooltip ? '' : ''}`}>
                {isLoading ? <Skeleton height={26} /> : <><span>Vendor:</span> {vendorDetails?.vendor?.name || ''}</>}
            </p>
        </div>
    )
}
export default VendorName;