import React, { FC, useContext } from 'react'
import EventsHeader from '../events/EventsHeader'
import { SuperAdminReportContext } from 'contexts/superAdminReport';
import { SuperAdminReportActions } from 'contexts/reducers/superAdminReports';
import DatesBody from './DatesBody';
import VenueName from 'components/reports/normalAdmin/venueName';



interface Props {
    activeTab?: any
}



const Dates: FC<Props> = ({activeTab}) => {



    const { eventList, loading,  datesQueryParams, selectedVendor } = useContext(SuperAdminReportContext);
    const { events_data } = eventList;
    const isLoading = loading[SuperAdminReportActions.GET_EVENTS];
    const {offset} = datesQueryParams;


    
  return (
    <div className="superAdminEventsReports">
    <VenueName isSuperReport = {true} venueName={!selectedVendor ? "All Vendors" : selectedVendor?.name} />
    <EventsHeader isLoading = {isLoading} events_data={events_data} offSet={offset} />
    <DatesBody activeTab = {activeTab}   /> 

    </div>
)
  
}

export default Dates
