import { Modal, ModalHeader } from "components/common/modal";
import { useContext } from "react";
import { Table, TableBody, TableData, TableHead, TableHeading, TableRow } from "components/common/table";
import TableSkeleton from "components/common/skeleton/tableSkeleton";
import NoData from "components/noData";
import { VendorContext } from "contexts/vendor";
import { VendorActions } from "contexts/reducers/vendor";
import { BillingStatus } from "interfaces/vendor";
import { getBillingStatusFormattedValue } from "util/getBillingStatus";



const HistoryModal = ({closeModal}: any) => {
    const {billingHistory, vendorDetails, loading} = useContext(VendorContext);
    const {vendor} = vendorDetails;

    const onClickStatus = (invoice_pdf: string) => {
        invoice_pdf && window.open(invoice_pdf, '_blank', 'noopener,noreferrer');
    }

    const historyLoading = loading[VendorActions.SET_BILLING_HISTORY];

    const {subscriptions} = billingHistory || {};
    return (
        <>
            <Modal customClass="refund-modal subscription-history-modal"
                setShowModal={() => closeModal()}
            >
                <ModalHeader>
                    <p className="app-modal-title">Subscription History</p>
                </ModalHeader>
                <div style={{marginTop: 59}} className="subscription-history-table">
                    <Table>
                        <TableHead>
                            <TableHeading customClass="plan">
                                Plan
                            </TableHeading>
                            <TableHeading customClass="period">
                                Period
                            </TableHeading>
                            <TableHeading customClass="total">
                                Total
                            </TableHeading>
                        </TableHead>

                        <TableBody>
                            {historyLoading ? (
                                <TableSkeleton count={4} />
                            ) : (
                                subscriptions?.length > 0 ? (
                                    subscriptions.map((order: any) => {
                                        const { invoice_pdf, status, subscription_type, period, vendor_type, amount } = order || {};
                                        const statuFormatted = getBillingStatusFormattedValue(vendor?.subscription?.billing_statuses_formatted, status);
                                        const isAmount = amount && amount !== "0" && amount !== "0.00";
                                        const total = isAmount ? `$${amount}` : statuFormatted;
                                        const isPaid = status?.toLowerCase() === BillingStatus.PAID.toLowerCase();
                                        return (
                                            <TableRow nonClickableRow>
                                                <TableData customClass="plan">
                                                    {`${subscription_type}${vendor_type ? ` - ${vendor_type}` : ''}`}
                                                </TableData>
                                                <TableData customClass="period">
                                                    {period || ''}
                                                </TableData>
                                                <TableData customClass={`total ${isAmount ? 'total-bold' : ''}`}>
                                                    {total}
                                                    {isAmount && (
                                                        <span
                                                            onClick={() => isPaid && onClickStatus(invoice_pdf)} 
                                                            className={`${isPaid ? 'cursor-pointer' : ''} total ${isAmount ? 'total-bold' : ''}`}
                                                        >
                                                            {` (${statuFormatted})`}
                                                        </span>
                                                    )}
                                                </TableData>
                                            </TableRow>
                                        )
                                    })
                                ):(
                                    <NoData description="No History" />
                                )
                            )}
                        </TableBody>
                    </Table>
                    {/* {subscriptions?.length && (
                        <Pagination
                            count={Math.ceil(subscriptions.length/LIMIT)}
                            activePage={page}
                            onClickArrow={onPageChange}
                        />
                    )} */}
                </div>
            </Modal>
        </>
    )
};

export default HistoryModal;
