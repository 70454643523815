import { IVendorAdminDashboard } from "interfaces/VendorAdminDashboard";
import { createContext, useReducer } from "react";
import VendorAdminDashboardReducer, { VendorAdminDashboardActions} from "./reducers/vendorAdminDashboard";
import timezone from 'moment-timezone';
import { getDashboardData } from "services/SuperAdminDasboard";




const initialState =  {
  loading: {
    GET_DASHBOARD_DATA: true
  },

   dashboardData: {
          weekly_sales: 0,
          new_leads: 0,
          live_events: 0,
          total_customers: 0,
          events: [],
          current_live_events: [],
          events_count: 0,
          data: [],
          data_count: 0
  
      },

  upCompingEvents: {},
  setLoading: () => {},
  resetLoading: ()=>{},
  fetchDashboardData: ()=>{},
  setDashboardReducer: () => {},

  dashboardQueryParams: {
  
      timezone: timezone.tz.guess(),
      filter: 'TOP_10_SELLING_ITEMS_ALL_TIME',
      limit: 15,
      offset: 0,
      event_filter: 'UPCOMING_EVENT_NEXT_30_DAYS',
      event_offset:0,
      event_limit:15,


  },


}




export const VendorAdminDashboardContext = createContext<IVendorAdminDashboard>(null!)




const VendorAdminDashboardContextProvider = ({children}: any)=>{
const [{loading,dashboardData, dashboardQueryParams}, dispatch] = useReducer(VendorAdminDashboardReducer, initialState)


const setDashboardData = (data: any, type: string) => {
    dispatch({
        type: type,
        payload: data
    })
}




const fetchDashboardData = async (dashboardReq: any, type: string) => {
  setLoading(type);
  const res = await getDashboardData(dashboardReq);
  resetLoading(type);

  if(res?._status?.code === 200){
    setDashboardData(
        {...res.data, event_offset: dashboardReq?.event_offset || 0, offset: dashboardReq?.offset || 0},
        type
    );
  }

  return res;
}

//events  : [...Array(5)].flatMap(() => res.data.events), events_count:20 ,

const setDashboardReducer = (type: string, data: any) => {
  dispatch({
      type: type,
      payload: data
  })
}

const setLoading = (loading: string) => {

  dispatch({type: VendorAdminDashboardActions.SET_DASHBOARD_LOADING, payload: loading})
 


}

const resetLoading = (loading: string) => {
dispatch({type: VendorAdminDashboardActions.RESET_DASHBOARD_LOADING, payload: loading})


}





return <VendorAdminDashboardContext.Provider value={{
  loading,
  dashboardData,
  setLoading,
  resetLoading,
  fetchDashboardData,
  setDashboardReducer,
  dashboardQueryParams,


}}>

{children}
</VendorAdminDashboardContext.Provider>
}

export default VendorAdminDashboardContextProvider