import { OnboardingContextProvider } from "interfaces/onboarding";

export enum OnboardingActions {
    SET_ONBOARDING_DETAILS = 'SET_ONBOARDING_DETAILS',
    SET_VENDOR_LOADING = 'SET_VENDOR_LOADING',
    RESET_VENDOR_LOADING = 'RESET_VENDOR_LOADING',
    SET_ACCEPT_AGREEMENT = 'SET_ACCEPT_AGREEMENT',
    SET_SELECTED_STEP = 'SET_SELECTED_STEP',
    SET_VENUES = 'SET_VENUES',
    SET_RESOURCE_LIBRARY = 'SET_RESOURCE_LIBRARY',
    SET_FOOD_IMAGE_LIBRARY = 'SET_FOOD_IMAGE_LIBRARY',
    SET_MENU = 'SET_MENU',
    SET_SAVE_AND_SUBMIT = 'SET_SAVE_AND_SUBMIT',
    SEND_EMAIL_AGREEMENT = 'SEND_EMAIL_AGREEMENT'
}


export const OnboardReducer = (state: OnboardingContextProvider, action: any) => {
    const { type, payload } = action
    switch (type) {
        case OnboardingActions.SET_VENDOR_LOADING:
            return {
                ...state,
                loading: [...state.loading, payload]
            }
        case OnboardingActions.RESET_VENDOR_LOADING:
            return {
                ...state,
                loading: state.loading.filter((item) => item !== payload)
            }
        case OnboardingActions.SET_ONBOARDING_DETAILS:
            console.log(payload);
            return {
                ...state,
                vendorOnboardingDetails: payload
            }
        case OnboardingActions.SET_SELECTED_STEP:
            return {
                ...state,
                selectedStep: payload
            }
        case OnboardingActions.SET_VENUES:
            return {
                ...state,
                venues: payload
            }
        case OnboardingActions.SET_RESOURCE_LIBRARY:
            return {
                ...state,
                resourceLibrary: payload
            }
        case OnboardingActions.SET_FOOD_IMAGE_LIBRARY:
            return {
                ...state,
                foodImageLibrary: payload
            }
        case OnboardingActions.SET_MENU:
            return {
                ...state,
                menu: payload
            }
        default:
            return state
    }
}
