import { useContext, useState } from "react";
import EventDropdown from "../dropdowns/eventDropdown";
import VenueDropdown from "../dropdowns/venueDropdown";
import { ReportContext } from "contexts/report";
import { useIsSuperAdmin } from "hooks/useIsSuperAdmin";
import VendorDropdown from "../dropdowns/VendorDropdown";


const Dropdowns = () => {
  const {
    setSelectedVenue,
    selectedVenue,
    selectedEvent,
    setSelectedEvent,
    fetchReprtEvents,
    fetchEventSales,
    fetchAllOrder, 
    setSelectedVendor, 
    fetchVenues, 
    selectedVendor,
    orderQueryParams,
    setOrderQueryParams,
    setVenueOpen,
    fetchAllEvents,
    setEventOpen,
    eventOpen
  } = useContext(ReportContext);



  const [queryParams] = useState({
    offset: 0,
    limit: 10,
    search: ''
});
  const isSuperAdmin = useIsSuperAdmin();

  const onSetVenue = (venue: any) => {
    setSelectedVenue(venue);
    setSelectedEvent(null!);
  };

  const onSelectVenue = async (item: any) => {
    onSetVenue(item);

    isSuperAdmin
    ? await fetchAllEvents({venue_id: item.id, vendor_id: isSuperAdmin && selectedVendor ? selectedVendor.id : null,})
    : await fetchReprtEvents({ venue_id: item.id });
  
    setEventOpen(true);
  };

  const onSelectEvent = async (event: any, timeZone: string) => {
    // setEventOpen(false);
    await  fetchEventSales({ event_id: event.id, timezone: timeZone });
  
    setOrderQueryParams((prev: {offset: number, limit: number, sort_order: string}) => ({...prev, offset: 0}));
    await  fetchAllOrder({event_id: event.id, venue_id: selectedVenue.id, ...orderQueryParams, offset: 0})

  };




  const onSetVendor = (vendor: any) => {
    setSelectedVendor(vendor);
    setSelectedEvent(null!);
    setSelectedVenue(null!)
  };





const onSelectVendor = async (item: any) => {
  onSetVendor(item);
  const id = item?.id;

  if(id){
    await fetchVenues({vendor_id: id, ...queryParams});
    setVenueOpen(true)
  }

  
  
}








  return (
    <>


      {isSuperAdmin ? 
      <VendorDropdown 
      onSelectVendor = {onSelectVendor}
      selectedVendor = {selectedVendor}

      
      /> : 
       <></>}
      
      <VenueDropdown
        selectedVenue={selectedVenue}
        onSelectVenue={onSelectVenue}
      />

      <EventDropdown
        selectedVenue={selectedVenue}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
        onSelectEvent={onSelectEvent}
        eventOpen={eventOpen}
        setEventOpen={setEventOpen}
      />
    </>
  );
};

export default Dropdowns;
