import { ChangeEvent } from "react";

interface IProps {
    name?: string;
    onChange?: (e: ChangeEvent<any>) => void;
    customClass?: string;
    checked?: boolean;
    disabled?: boolean;
    customStyles?: any;
    width?: number | string;
    height?: number | string;
    parentClass?: string;
}

const Switch = ({
    name, 
    width, 
    height, 
    onChange, 
    checked, 
    disabled=false, 
    customStyles, 
    customClass,
    parentClass
}: IProps) => {
    return (
        <div>
            <label className={`toggle ${parentClass}`}>
                <input
                    name={name}
                    className="toggle-checkbox"
                    type="checkbox"
                    onChange={onChange}
                    checked={checked}
                    disabled={disabled}
                />
                <div
                    className={`toggle-switch ${customClass || ''}`}
                    style={{
                        ...(customStyles && customStyles),
                        ...(width && {width}),
                        ...(height && {height})
                    }}
                ></div>
            </label>
        </div>
    )
};

export default Switch;
