import { createContext, useReducer, useState } from "react"
import { getVenueList } from "services/vendor";
// import { getVenue } from "services/venue";
import { VendorListRequest } from "interfaces/vendor";
import { QrUrlUpdateRequest, ResourcesContextProvider } from "interfaces/promotionalResources";
import { PromotionalResourcesReducer, ResourcesActions } from "./reducers/promotionalResources";
import { getQrUrl, putQrUrl } from "services/promotionalResources";


const initialState: ResourcesContextProvider = {
    loading: {
        [ResourcesActions.GET_VENUES]: true
    },
    setLoading: () => {},
    resetLoading: () => {},
    venueList: {
        venues: [],
        _count: 0
    },
    fetchVenues: () => Promise<any>,
    signageQrUrl: null!,
    fetchQrUrl: ()=> Promise<any>,
    updateQrUrl: ()=> Promise<any>,
    signType: null,
    signageLandingPage: null!,
    embedLandingPage: "venue",
    signageVenue: null!,
    embedVenue: null!,
    embedQrUrl: null!,
    widgetColor: '#4285f4',
    setSignType: () => {},
    setSignageLandingPage: () => {},
    setEmbedLandingPage: () => {},
    setSignageVenue: () => {},
    setEmbedVenue: () => {},
    setWidgetColor: () => {}


}


export const PromotionalResourcesContext = createContext<ResourcesContextProvider>(null!);

const PromotionalResourcesProvider = ({ children }: any) => {

    const [{ loading, venueList, signageQrUrl, embedQrUrl }, dispatch] = useReducer(PromotionalResourcesReducer, initialState);
    const [signType, setSignType] = useState<any>(null);
    const [signageLandingPage, setSignageLandingPage] = useState<any>(initialState.signageLandingPage);
    const [embedLandingPage, setEmbedLandingPage] = useState<any>(initialState.embedLandingPage);
    const [signageVenue, setSignageVenue] = useState<any>(null);
    const [embedVenue, setEmbedVenue] = useState<any>(null);
    const [widgetColor, setWidgetColor] = useState<any>(initialState.widgetColor);

    const fetchQrUrl = async (venue_id: string, isEmbed: boolean) =>{
        setLoading(isEmbed ? ResourcesActions.SET_EMBED_QR_URL : ResourcesActions.GET_QR_URL);
        const res = await getQrUrl(venue_id);  
        resetLoading(isEmbed ? ResourcesActions.SET_EMBED_QR_URL : ResourcesActions.GET_QR_URL);

        if(res?._status?.code ===200)
        {
            dispatch({
                type: isEmbed ? ResourcesActions.SET_EMBED_QR_URL : ResourcesActions.GET_QR_URL,
                payload: res.data
            })
        }

        return res;
    }




    const updateQrUrl = async (resource_id: string, qrUrlUpdateRequest: QrUrlUpdateRequest)=> {

    setLoading(ResourcesActions.GET_QR_URL);
    const res = await putQrUrl(resource_id, qrUrlUpdateRequest);
    resetLoading(ResourcesActions.GET_QR_URL);


    if(res?._status?.code ===200)
    {
        dispatch({
            type: ResourcesActions.GET_QR_URL,
            payload: res.data
        })
    }

    return res;




    }


    const setLoading = (loading: string) => {
        dispatch({
            type: ResourcesActions.SET_VENUE_LOADING,
            payload: loading
        })
    }

    const resetLoading = (loading: string) => {
        dispatch({
            type: ResourcesActions.RESET_VENUE_LOADING,
            payload: loading
        })
    }

    const fetchVenues = async (venueListReq: VendorListRequest) => {
        setLoading(ResourcesActions.GET_VENUES);
        const res = await getVenueList(venueListReq);
        resetLoading(ResourcesActions.GET_VENUES);

        if(res?._status?.code === 200){
            dispatch({
                type: ResourcesActions.GET_VENUES,
                payload: res.data
            });
        }

        return res;
    }

    return <PromotionalResourcesContext.Provider value={{
        loading,
        setLoading,
        resetLoading,
        fetchVenues,
        venueList,
        fetchQrUrl,
        updateQrUrl,
        signType,
        signageLandingPage,
        embedLandingPage,
        signageVenue,
        embedVenue,
        signageQrUrl,
        embedQrUrl,
        setSignType,
        setSignageLandingPage,
        setEmbedLandingPage,
        setSignageVenue,
        setEmbedVenue,
        setWidgetColor,
        widgetColor
    }}>
        {children}
    </PromotionalResourcesContext.Provider>
}
export default PromotionalResourcesProvider;