export const enum VendorStatuses {
CREATED = "Created",
LAPSED = "Lapsed",
NEEDS_REVIEW = "Needs Review",
ONBOARDING =   "Onboarding",
VP_ONBOARDING =   "VP Onboarding",
WELCOME =  "Welcome",
AT_RISK =   "At Risk",
SCHEDULED =  "Scheduled", 
ACTIVE = "Active"   
}



export enum VendorStatus {
    Created = 'CREATED',
    Welcome = 'WELCOME',
    Vp_Onboarding = 'VP_ONBOARDING',
    Needs_Review = 'NEEDS_REVIEW',
    Onboarding = 'ONBOARDING',
    SCHEDULED = 'SCHEDULED',
    Active = 'ACTIVE',
    AT_RISK = 'AT_RISK',
    Lapsed = 'LAPSED'
}


export enum VendorTypes {
    DISTRICT = "District",
    SCHOOL = "School",
    COLLEGE = "College",  
    OTHER = "Other"
}



export const enum VendorStatusColors {
    CREATED = '#106CD0',
    WELCOME = '#B712C9',
    VP_ONBOARDING = '#FDC13D',
    NEEDS_REVIEW = '#E94929',
    ONBOARDING = '#27C1C9',
    SCHEDULED='#490A68',
    ACTIVE = '#41BB86',
    AT_RISK= '#23D414',
    LAPSED = '#c1b6b6',

}

export const enum LeadsStatuses {
    NEW = 'NEW',
    SALES_KIT_SENT = 'SALES_KIT_SENT',
    VENDOR_CREATED = 'VENDOR_CREATED',
    ONBOARDING = 'ONBOARDING',
    CONVERTED = 'CONVERTED'


}
export const enum LeadsStatusColors {
    NEW= "#E94929",
    SALES_KIT_SENT= "#B712C9",
    VENDOR_CREATED= "#106CD0",
    ONBOARDING= "#FDC13D",
    CONVERTED= "#27C1C9",

}