import { useEffect } from 'react';
import ReactDOM from 'react-dom';

const ESCAPE_KEY = 27;

const Modal = ({children, innerConatinerClass, customStyles, setShowModal, closeModal, customClass, width, height, isCancel}: any) => {

  const onCloseModal = () => {
    setShowModal && setShowModal(false);
    closeModal && closeModal();
    document.body.style.overflowY = 'unset';
  }

  useEffect(() => {
    document.body.style.overflowY = 'hidden';

    /* eslint-disable react-hooks/exhaustive-deps */
  },[]);

  useEffect(() => {
    const handler = (e: any) => {
      if(e.keyCode === ESCAPE_KEY){
        onCloseModal();
      }
    }

    window.addEventListener('keydown', handler);

    return () => {
      window.removeEventListener('keydown', handler);
      document.body.style.overflowY = 'unset';
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  },[])

  return ReactDOM.createPortal(
    <>
      <div className={`venueInstructionModal app-modal ${customClass || ''}`}>
        <div style={{...(customStyles && customStyles), ...(width && {width}), ...(height && {height})}} className={`app-modal-container ${innerConatinerClass || ''}`}>
          {children}
        </div>
      </div>
      <div onClick={!isCancel ? onCloseModal: ()=>{}} className="modalBackGround"></div>
    </>,
    document.getElementById('modal-root') as any
  );
};

export default Modal;
