import EditableActions from "components/common/editableActions";
import { useEffect, useRef, useState } from "react";

interface IProps {
    onClick?: (e: any) => void;
    title: string;
    isEditable?: boolean;
    isEditMode?: boolean;
    onClickCross?: Function;
    onClickSubmit?: Function;
    editDisabled?: boolean;
    submitDisabled?: boolean;
    isLoading?: boolean;
    isHistory?: boolean;
    onClickHistory?: Function;
    isHistoryDisabled?: boolean;
    subheading?: string;
    onClickSubHeading?: Function;
    disableSubmit?: boolean;
    setSuccess?: Function
}

const SectionHeader = ({
    title, 
    onClick, 
    onClickSubmit, 
    onClickCross, 
    isEditMode, 
    isEditable = true,
    editDisabled,
    submitDisabled,
    isLoading,
    isHistory,
    onClickHistory,
    isHistoryDisabled,
    subheading,
    onClickSubHeading,
    disableSubmit,
    setSuccess
}:IProps) => {
    const titleRef = useRef<any>(null);
    const [tooltip, setTooltip] = useState(false);

    useEffect(() => {
        if(title && titleRef.current){
            setTooltip(titleRef?.current ? titleRef?.current?.scrollWidth - titleRef?.current?.clientWidth > 0 : false);
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[title, titleRef.current]);

    return (
        <div className="edit-header">
            {title && (
                <div className={`edit-title-container show-truncate-tooltip ${isEditMode ? 'title-editMode' : ''}`}>
                    <p ref={titleRef} className="edit-title">
                        {title}
                        {subheading && (
                            <span className="cursor-pointer" onClick={() => onClickSubHeading && onClickSubHeading()}>{subheading}</span>
                        )}
                    </p>
                    {tooltip && (
                        <span className="truncate-tooltip">{title}</span>
                    )}
                </div>
            )}
            <div className="subscription-header-action">
                {isHistory && (
                    <>
                        {isHistoryDisabled ? (
                            <span className="history-icon">
                                <svg className="disabled" xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                                    <g opacity="0.4">
                                    <path d="M16.464 0.970703C12.3411 0.970703 8.57047 2.43203 5.67447 4.84928V2.48787C5.67447 1.73809 5.04687 1.13028 4.27247 1.13028C3.88538 1.13028 3.53499 1.28223 3.28129 1.52793C3.02759 1.77364 2.87069 2.11298 2.87069 2.48787V8.11684C2.87069 8.86661 3.49829 9.47443 4.27247 9.47443H10.0847C10.8589 9.47443 11.4865 8.86661 11.4865 8.11684C11.4865 7.74175 11.3296 7.4024 11.0759 7.1567C10.8222 6.911 10.4718 6.75905 10.0847 6.75905H7.55706C9.96194 4.79241 13.0701 3.60707 16.464 3.60707C24.0933 3.60707 30.278 9.59682 30.278 16.9856C30.278 24.3743 24.0933 30.3641 16.464 30.3641C9.66654 30.3641 4.0159 25.6092 2.86452 19.3466C2.80293 19.013 2.75442 18.6751 2.71932 18.3335C2.46466 17.8795 1.96801 17.5713 1.39721 17.5713C0.763333 17.5713 0.221043 17.9513 0 18.4888C0.0461659 18.9701 0.114457 19.4454 0.203704 19.9134C1.62421 27.3598 8.36401 33.0002 16.464 33.0002C25.5966 33.0002 33 25.8303 33 16.9856C33 8.14084 25.5966 0.970703 16.464 0.970703Z" fill="white"/>
                                    <path d="M16.931 18.5648V9.6508C16.931 8.94296 16.3385 8.36914 15.6076 8.36914C14.8767 8.36914 14.2842 8.94296 14.2842 9.6508V19.3732C14.2842 19.8672 14.5773 20.3172 15.0376 20.5299L21.7829 23.6471C22.4426 23.9519 23.2325 23.6812 23.5473 23.0423C23.862 22.4035 23.5825 21.6384 22.9227 21.3336L16.9308 18.5646L16.931 18.5648Z" fill="white"/>
                                    </g>
                                </svg>
                            </span>
                        ):(
                            <span className="history-icon" onClick={() => !isHistoryDisabled && onClickHistory && onClickHistory()}>
                                <svg className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="33" height="33" viewBox="0 0 33 33" fill="none">
                                    <path d="M16.464 0.970703C12.3411 0.970703 8.57047 2.43203 5.67447 4.84928V2.48787C5.67447 1.73809 5.04687 1.13028 4.27247 1.13028C3.88538 1.13028 3.53499 1.28223 3.28129 1.52793C3.02759 1.77364 2.87069 2.11298 2.87069 2.48787V8.11684C2.87069 8.86661 3.49829 9.47443 4.27247 9.47443H10.0847C10.8589 9.47443 11.4865 8.86661 11.4865 8.11684C11.4865 7.74175 11.3296 7.4024 11.0759 7.1567C10.8222 6.911 10.4718 6.75905 10.0847 6.75905H7.55706C9.96194 4.79241 13.0701 3.60707 16.464 3.60707C24.0933 3.60707 30.278 9.59682 30.278 16.9856C30.278 24.3743 24.0933 30.3641 16.464 30.3641C9.66654 30.3641 4.0159 25.6092 2.86452 19.3466C2.80293 19.013 2.75442 18.6751 2.71932 18.3335C2.46466 17.8795 1.96801 17.5713 1.39721 17.5713C0.763333 17.5713 0.221043 17.9513 0 18.4888C0.0461659 18.9701 0.114457 19.4454 0.203704 19.9134C1.62421 27.3598 8.36401 33.0002 16.464 33.0002C25.5966 33.0002 33 25.8303 33 16.9856C33 8.14084 25.5966 0.970703 16.464 0.970703Z" fill="white"/>
                                    <path d="M16.931 18.5648V9.6508C16.931 8.94296 16.3385 8.36914 15.6076 8.36914C14.8767 8.36914 14.2842 8.94296 14.2842 9.6508V19.3732C14.2842 19.8672 14.5773 20.3172 15.0376 20.5299L21.7829 23.6471C22.4426 23.9519 23.2325 23.6812 23.5473 23.0423C23.862 22.4035 23.5825 21.6384 22.9227 21.3336L16.9308 18.5646L16.931 18.5648Z" fill="white"/>
                                </svg>
                            </span>
                            
                        )}
                    </>
                )}

                {isEditable && (
                    <EditableActions 
                        activeDisabled={submitDisabled}
                        disabled={isLoading}
                        editDisabled={editDisabled}
                        isEditMode={isEditMode}
                        onClickCross={onClickCross}
                        onClickSubmit={onClickSubmit}
                        onClickEdit={onClick}
                        disableSubmit={disableSubmit}
                        setSuccess= {setSuccess}
                    />
                )}
            </div>
        </div>
    )
};

export default SectionHeader;
