import React from "react";
import { Skeleton } from "components/common/skeleton";

const TableSkeleton = () => {
  const divStyle = {
    borderRadius: "40px",
  };

  const labelStyle = {
    borderRadius: "0px",
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "10px",
        alignItems: "center",
        width: "960px",
        marginBottom: "18px",
      }}
    >
      <Skeleton height={87} width={367} customStyles={divStyle} />
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "15px",
          width: "202px",
        }}
      >
        <Skeleton height={20} customStyles={labelStyle} />
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <Skeleton width={85} height={35} customStyles={labelStyle} />
          <Skeleton width={70} height={25} customStyles={labelStyle} />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          width: "194px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <Skeleton width={85} height={35} customStyles={labelStyle} />
          <Skeleton width={86} height={28} customStyles={labelStyle} />
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "20px",
            alignItems: "center",
          }}
        >
          <Skeleton width={85} height={35} customStyles={labelStyle} />
          <Skeleton width={70} height={25} customStyles={labelStyle} />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "122px",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            gap: "20px",
            alignItems: "center",
            position: "relative",
            top: "-23px",
          }}
        >
          <Skeleton width={60} height={35} customStyles={labelStyle} />
          <Skeleton width={70} height={25} customStyles={labelStyle} />
        </div>
      </div>
    </div>
  );
};

export default TableSkeleton;
