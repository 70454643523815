import { useContext, useEffect, useState } from "react"
import VenueDropdown from "../dropdowns/venueDropdown";
import { ReportContext } from "contexts/report";
import StartDateDropdown from "../dropdowns/startDate";
import EndDateDropdown from "../dropdowns/endDate";
import moment from "moment";
import timezone from "moment-timezone";
import VendorDropdown from "../dropdowns/VendorDropdown";
import { useIsSuperAdmin } from "hooks/useIsSuperAdmin";

const Dropdowns = () => {
    const { setSelectedVenue, selectedVenue, startDate, endDate, setStartDate, setEndDate, fetchEventDetails, setOrderQueryParams, fetchAllOrder, orderQueryParams, setSelectedVendor, setSelectedEvent, fetchVenues, selectedVendor } = useContext(ReportContext);

    const isSuperAdmin = useIsSuperAdmin()
    const [queryParams] = useState({
        offset: 0,
        limit: 10,
        search: ''
    });
    const onSetVenue = (venue: any) => {
        setSelectedVenue(venue);
    }

    const fetchOrders = async (data: any) => {
        setOrderQueryParams((prev: {offset: number, limit: number, sort_order: string}) => ({...prev, offset: 0}));
        fetchAllOrder({venue_id: selectedVenue?.id, start_date: startDate, end_date: endDate, ...data, ...orderQueryParams, offset: 0})
    }

    const onSelectVenue = async(item: any) => {
        onSetVenue(item);

        startDate &&
        endDate &&
        await fetchEventDetails({venue_id: item.id, start_date: startDate, end_date: endDate});


        startDate && endDate && 
        fetchOrders({venue_id: item.id})

    }

    const onSelectDate = (date: any) => {
        console.log('date', date)
        setStartDate(moment(date).format('YYYY-MM-DD'));
        endDate && 
        selectedVenue && 
        fetchEventDetails({venue_id: selectedVenue.id, start_date: moment(date).format('YYYY-MM-DD'), end_date: endDate});



        endDate && 
        selectedVenue && 
        fetchOrders({start_date: moment(date).format('YYYY-MM-DD')})


    }

    const onSelectEndDate = (date: any) => {
        setEndDate(moment(date).format('YYYY-MM-DD'));
        startDate && 
        selectedVenue && 
        fetchEventDetails({venue_id: selectedVenue.id, start_date: startDate, end_date: moment(date).format('YYYY-MM-DD')});


        startDate && 
        selectedVenue && 
        fetchOrders({end_date: moment(date).format('YYYY-MM-DD')})


    }

    const onSetVendor = (vendor: any) => {
        setSelectedVendor(vendor);
        setSelectedEvent(null!);
        setSelectedVenue(null!)
      };
    
    
    
    
    
    const onSelectVendor = async (item: any) => {
        onSetVendor(item);
        const id = item?.id;
        id && fetchVenues({vendor_id: id, ...queryParams});
    
    
      
    }

    useEffect(() => {
        if(selectedVenue && !startDate && !endDate){
            const fromDate = moment().subtract(7, 'd').format("YYYY-MM-DD");
            const toDate = moment().tz(timezone.tz.guess()).format("YYYY-MM-DD");
            console.log("ji", fromDate, toDate)
            setStartDate(fromDate);
            setEndDate(toDate)
            fetchEventDetails({venue_id: selectedVenue.id, start_date: fromDate, end_date: toDate});
        }
    },[selectedVenue, startDate, endDate, setStartDate, setEndDate, fetchEventDetails])
    
    return (
        <>
    
            {isSuperAdmin ?  <VendorDropdown 
             onSelectVendor = {onSelectVendor}
             selectedVendor = {selectedVendor}
             
             />: <></>}

            <VenueDropdown
                selectedVenue={selectedVenue}
                onSelectVenue={onSelectVenue}
            />

            <div className="date-dropdowns">
                <StartDateDropdown
                    onSelectDate={(date: any) => onSelectDate(date)}
                    disabled={!selectedVenue}
                    value={startDate}
                />
            </div>
            <div className="date-dropdowns">
                <EndDateDropdown
                    onSelectEndDate={(date: any) => onSelectEndDate(date)}
                    disabled={!selectedVenue}
                    value={endDate}
                />

                  

            </div>
        </>
    )
};

export default Dropdowns;
