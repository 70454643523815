import config from 'config/config';
import axios from 'services/core/axios';
import { getQueryString } from 'util/getQueryString';

export const getReportEvents = async (reportReq: any) => {
    const { venue_id, limit, search } = reportReq;

    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/reports/venues/${venue_id}/events${limit ? `?limit=${limit}` : ''}${search ? `?search=${search}` : ''}`);
        return res?.data

    } catch (error) {
        return error;
    }
}

export const getAllEvents = async (allEventReq: any) => {
    console.log( {allEventReq } )
    const { venue_id, search, vendor_id } = allEventReq;
    let query_string = search ? `?search=${search}` : '';
    if(vendor_id) query_string+= ( (query_string ? '&' : '?') + `vendor_id=${vendor_id}`);
    
    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/reports/venues/${venue_id}/events${query_string}`);
        return res?.data

    } catch (error) {
        return error;
    }
}

export const getEventItems = async (eventItemsData:{sort_order: string, sort_by: string, event_id: number}) => {
    const { event_id, ...body } = eventItemsData;
    const queryString = getQueryString(body);

    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/reports/venues/events/${event_id}/items${queryString}`);
        return res?.data

    } catch (error) {
        return error;
    }
}

export const getEventSales = async (eventSaleReq: any) => {
    const {event_id, timezone} = eventSaleReq;
    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/reports/venues/events/${event_id}/sales?timezone=${timezone}`);
        return res?.data

    } catch (error) {
        return error;
    }
}

export const getEventDetails = async (eventSaleReq: any) => {
    const {venue_id, ...body} = eventSaleReq;
    const queryString = getQueryString(body);
    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/reports/venues/${venue_id}/sales${queryString}`);
        return res?.data

    } catch (error) {
        return error;
    }
}

export const getRefundsOrder = async (refundDataReq: any) => {
    const {event_id, ...body} = refundDataReq;
    const queryString = getQueryString(body);
    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/reports/venues/events/${event_id}/orders/refunds${queryString}`);
        return res?.data

    } catch (error) {
        return error;
    }
}


export const getAllOrders = async (allOrdersRequest: any) => {
const queryString = getQueryString(allOrdersRequest);
try {
    const res = await axios.get(`${config.defaults.api_url}/v1/reports/orders${queryString}`);
    return res?.data
}
catch (error) {
    return error;
}


}

export const getOrderDetail = async (order_Id: any) => {
 
    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/vendors/customers/orders/${order_Id}`);
        return res?.data
    }
    catch (error) {
        return error;
    }
    
    
    }

    export const getVendorVenueEventForSuperAdmin = async (reqParams: any) => {
        const queryString = getQueryString(reqParams);
        try {
            const res = await axios.get(`${config.defaults.api_url}/v1/admins/vendor-venue-events${queryString}`);
            return res?.data
        }
        catch (error) {
            return error;
        }
        
        
        }
      