import { AuthContext } from "contexts/auth"
import { useContext } from "react"

const AddButtonMobile = ({onClick, active, addButtonClass, showAdd}: any) => {
    const { openSidebar, openSearchbar } = useContext(AuthContext);
    return (
        <div onClick={() => showAdd && onClick()} className={`addVendor-btn-mob ${addButtonClass || ''} ${active ? 'active-btn' : ''} ${(openSearchbar || openSidebar) ? 'abc-add-btn' : ''}`}>
            {showAdd && (
                <div>
                    <div className="plus">
                        <span></span>
                        <span></span>
                    </div>
                </div>
            )}
        </div>
    )
}

export default AddButtonMobile;