import React from "react"

const MoreDataBlur = ({isShow}: {isShow: boolean}) => {
    return (
        <>
            {isShow && (
                <div className='more-data-table-scroll'></div>
            )}
        </>
    )
};

export default MoreDataBlur;
