import config from 'config/config';
import { LoginRequest, RefreshTokenRequest, RegisterRequest, ResetPasswordRequest, UpdatePasswordRequest, UpdateUserRequest } from 'interfaces/auth';
import axios from 'services/core/axios';

export const login = async (loginReq: LoginRequest) => {
    const {x_device_token, ...body} = loginReq
    try {
        const res = await axios.post(`${config.defaults.api_url}/v1/auth/login`, body, {
            headers: {
                'x-device-token': x_device_token
            }
        });
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const updatePassword = async (updatePasswordReq: UpdatePasswordRequest) => {
    try {
        const res = await axios.put(`${config.defaults.api_url}/v1/users/current/change/password`, updatePasswordReq);
        return res?.data;

    } catch (error) {
        return error;
    }
}

export const resetPassword = async (resetPasswordReq: ResetPasswordRequest) => {

    try {
        const { data: { data } } = await axios.put(`${config.defaults.api_url}/v1/auth/reset/password`, resetPasswordReq);
        return data

    } catch (error) {
        return null
    }
}

export const register = async (registerReq: RegisterRequest) => {
    const {x_device_token, ...body} = registerReq
    try {
        const { data: { data } } = await axios.post(`${config.defaults.api_url}/v1/auth/register`, body, {
            headers: {
                'x_device_token': x_device_token
            }
        });
        return data

    } catch (error) {
        return null
    }
}

export const refreshToken = async (refreshTokenReq: RefreshTokenRequest) => {
    try {
        const { data: { data } } = await axios.put(`${config.defaults.api_url}/v1/auth/refresh`, refreshTokenReq);
        return data

    } catch (error) {
        return null
    }
}

export const refreshExpiredRefreshToken = async () => {
    try {
        const { data: { data } } = await axios.put(`${config.defaults.api_url}/v1/auth/refresh/refresh-token`);
        return data

    } catch (error) {
        return null
    }
}

export const getImpersonationToken = async (admin_id: number) => {

    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/auth/admin/${admin_id}/impersonate`);
        return res?.data

    } catch (error) {
        return null
    }
}

export const putUser = async (updateUserReq: UpdateUserRequest) => {
    try {
        const res = await axios.put(`${config.defaults.api_url}/v1/users/current`, updateUserReq);
        return res?.data

    } catch (error) {
        return error
    }
}