import { Skeleton } from "components/common/skeleton";
import { useCalculateLength } from "hooks/useCalculateLength";
// import React, { useRef, useState } from "react";

const ReportHeader = ({
  event_sales,
  events,
  isLoading,
  isDataFetched,
  activeTab,
  items,
}: any) => {
  const isEvent = isDataFetched && !isLoading;
  const toalOrders =
    event_sales?.total_orders?.toString()?.length > 4
      ? "9999+"
      : event_sales?.total_orders;

  const itemCount = event_sales?.total_items > 0 ? event_sales?.total_items : 0;
  const itemLabel =
    items?.length === 1 || event_sales?.items?.length === 1 ? "Item" : "Items";

  const gorssSaleClass = useCalculateLength(event_sales?.gross_sales);
  const avgSaleClass = useCalculateLength(event_sales?.average_order_total);
  const donationClass = useCalculateLength(event_sales?.total_tips);

//   const [toolTip, setToolTip] = useState(true);




//   const customRef = useRef<any>(null);
  return (
    <div className="superEventsReportsHeader">
        <div className="summary-total orders">
            {isEvent ? (
            <div className="hightlight-number">{toalOrders}</div>
            ) : (
            <Skeleton
                customStyles={{ borderRadius: 7 }}
                width={82}
                height={35}
                animation={false}
            />
            )}

            {isEvent ? (
            <p className="sec-label">Orders</p>
            ) : (
            <Skeleton
                customStyles={{ borderRadius: 7 }}
                width={82}
                height={22}
                animation={false}
            />
            )}
        </div>

        {event_sales?.donation_enabled ? (
        <div className="summary-total donation">
            {isEvent ? (
                <div className={`hightlight-number ${donationClass}`}>
                {event_sales?.total_tips}
                </div>
            ) : (
                <Skeleton
                customStyles={{ borderRadius: 7 }}
                width={82}
                height={35}
                animation={false}
                />
            )}
            {isEvent ? (
                <p className="sec-label">Donations</p>
            ) : (
                <Skeleton
                customStyles={{ borderRadius: 7 }}
                width={82}
                height={22}
                animation={false}
                />
            )}
        </div>
      ) : (
        <></>
      )}

        <div className="summary-total items">
            {isEvent ? (
            <div className="hightlight-number">
                {activeTab === 0 ? itemCount : event_sales?.items?.length}
            </div>
            ) : (
            <Skeleton
                customStyles={{ borderRadius: 7 }}
                width={53}
                height={35}
                animation={false}
            />
            )}
            {isEvent ? (
            <p className="sec-label">{itemLabel}</p>
            ) : (
            <Skeleton
                customStyles={{ borderRadius: 7 }}
                width={53}
                height={22}
                animation={false}
            />
            )}
        </div>
        <div className="summary-total net-sales">
            {isEvent ? (
            <div className={`hightlight-number ${gorssSaleClass}`}>
                {event_sales?.gross_sales}
            </div>
            ) : (
            <Skeleton
                customStyles={{ borderRadius: 7 }}
                width={82}
                height={35}
                animation={false}
            />
            )}
            {isEvent ? (
            <p className="sec-label">Net Sales</p>
            ) : (
            <Skeleton
                customStyles={{ borderRadius: 7 }}
                width={82}
                height={22}
                animation={false}
            />
            )}
        </div>
        <div className="summary-total avg-sale">
            {isEvent ? (
            <div className={`hightlight-number ${avgSaleClass}`}>
                {event_sales?.average_order_total}
            </div>
            ) : (
            <Skeleton
                customStyles={{ borderRadius: 7 }}
                width={82}
                height={35}
                animation={false}
            />
            )}
            {isEvent ? (
            <p className="sec-label">Avg Sale</p>
            ) : (
            <Skeleton
                customStyles={{ borderRadius: 7 }}
                width={82}
                height={22}
                animation={false}
            />
            )}
        </div>

        {activeTab !== 0 ? (
        <div className="summary-total events">
          {isEvent ? (
            <div className="hightlight-number">
              {events ? events.count : ""}
            </div>
          ) : (
            <Skeleton
              customStyles={{ borderRadius: 7 }}
              width={82}
              height={35}
              animation={false}
            />
          )}

          {isEvent ? (
            <p className="sec-label">Events</p>
          ) : (
            <Skeleton
              customStyles={{ borderRadius: 7 }}
              width={82}
              height={22}
              animation={false}
            />
          )}
        </div>
      ) : (
        <></>
      )}
    </div>
  );
};

export default ReportHeader;
