import { useContext, useEffect } from "react"
import CheckList from "./checklist";
import Agreement from "./agreement";
import { CHECKLIST } from "interfaces/vendor";
import VenueSetup from "./venueSetup";
import Menu from "./menu";
import SubmitForReview from "./submitForReview";
import ConnectStripe from "./connectStripe";
import Billing from "./billing";
import { OnboardingContext } from "contexts/onboarding";
import { AuthContext } from "contexts/auth";
import BottomBar from "components/common/bottomBar";

const OnBoarding = () => {
    const { 
        addVenues, 
        acceptAgreement,
        markBillingPaid,
        markStripeConnected,
        loading, 
        fetchonboardingDetails, 
        vendorOnboardingDetails, 
        setSelectedStep, 
        selectedStep,
        emailAgreement,
        setOnboardingDetails
    } = useContext(OnboardingContext);

    const { onboardingDetails, setOnboardingDetails: setOnboarding } = useContext(AuthContext);

    const onAgreeTerms = async () => {
        setSelectedStep(CHECKLIST.Billing);
        setOnboarding({...onboardingDetails, onboarding_step: CHECKLIST.Billing, agreement_accepted: true})
        
        const result = await acceptAgreement();
        
        if(!result || !result.accepted){
            setSelectedStep(CHECKLIST.Agreement);
            setOnboarding({...onboardingDetails, onboarding_step: CHECKLIST.Agreement, agreement_accepted: false})
        }
    }

    const selectOnboardingStep = (step: string) => {
        if(vendorOnboardingDetails?.agreement_accepted && step !== CHECKLIST.Agreement){
            setSelectedStep(step);
        }
    }

    const getOnboardingDetails = async () => {
        const res = await fetchonboardingDetails();
        if(res?._status?.code === 200){
            setOnboarding(res.data);
        }
        
    }

    useEffect(() => {
        onboardingDetails
        ? setOnboardingDetails(onboardingDetails)
        : getOnboardingDetails();

        /* eslint-disable react-hooks/exhaustive-deps */
    },[]);

    const onClickSubmit = () => {
        setSelectedStep(CHECKLIST.Submit_For_Review);
        setOnboarding({...onboardingDetails, onboarding_step: "SUBMIT_REVIEW", menu_completed: true});
    }


    console.log(vendorOnboardingDetails);

    return (
        <>
            <CheckList 
                setSelectedStep={selectOnboardingStep} 
                selectedStep={selectedStep}
                vendorOnboardingDetails={vendorOnboardingDetails}
            />
            <div className="signup-body_onboardingContainer">
                {selectedStep === CHECKLIST.Agreement && (
                    <Agreement 
                        agreement={vendorOnboardingDetails?.agreement} 
                        selectedStep={selectedStep} 
                        loading={loading} 
                        onAgree={onAgreeTerms}
                        emailAgreement={emailAgreement}
                    />
                )}
                {selectedStep === CHECKLIST.Billing && (
            
                    <Billing 
                        loading={loading}
                        setSelectedStep={(step: string) => {
                            setSelectedStep(step);
                            setOnboarding({...onboardingDetails, billing: {paid: true}})
                        }}
                        selectedStep={selectedStep}
                        markBillingPaid={markBillingPaid}
                        vendorDetails = {vendorOnboardingDetails}
                    />
                )}
                {selectedStep === CHECKLIST.Connect_Stripe_Account && 
                    <ConnectStripe 
                        stripeConnect={vendorOnboardingDetails?.connected_account_stripe}
                        loading={loading}
                        setSelectedStep={(step: string) => {
                            setSelectedStep(step);
                            setOnboarding({...onboardingDetails, connected_account_stripe: {connected: true}})
                        }}
                        selectedStep={selectedStep}
                        markStripeConnected={markStripeConnected}
                    />
                }
                {selectedStep === CHECKLIST.Menu && <Menu onClick={onClickSubmit} />}
                {selectedStep === CHECKLIST.Venues && (
                    <VenueSetup 
                        addVenues={addVenues}
                        loading={loading}
                        setSelectedStep={(step: string) => {
                            setSelectedStep(step);
                            setOnboarding({...onboardingDetails, onboarding_step: 'MENU'})
                        }}
                        selectedStep={selectedStep}
                    />
                )}
                {selectedStep === CHECKLIST.Submit_For_Review && (
                    <SubmitForReview />
                )}
            </div>
            <BottomBar />
        </>

    )
};

export default OnBoarding;
