import React from "react"

const SelectContainer = ({children}: {children: React.ReactNode}) => {
    return (
        <div className="sign-type-location">
            <div className="body-type">
                {children}
            </div>
        </div>
    )
};

export default SelectContainer;
