
import Input from "components/common/input";
import AddressField from "./addressField";
import { ChangeEvent, useState } from "react";
import { deleteObjectProp } from "util/deleteObjectProp";
import PhoneField from "components/common/phoneField";

interface IProps {
    setFormsData: Function;
    formData: any;
    onAddCustomerPhone: Function;
    handleChange: (e: ChangeEvent<any>) => void;
    errors: any;
    touched: any;
    handleBlur: any;
    setFieldError?: Function;
}

const VendorContact = ({handleBlur, setFieldError, handleChange, onAddCustomerPhone, formData, setFormsData, errors}: IProps) => {
    const [zipcodeDisabled, setZipcodeDisabled] = useState(false);

    const onClickPlace = async (data: any) => {
        setZipcodeDisabled(!!data.zipcode);
        setFormsData('contactForm', {
            ...formData,
            address: data.address,
            zipcode: data.zipcode
        })
    }

    const setFormValue = (name: string, value: any) => {
        setFormsData('contactForm', {
            ...formData,
            [name]: value
        })
    }

    const onClickCancel = (fieldName: string) => {
        const newErrors = deleteObjectProp(errors['contactForm'], fieldName)
        
        newErrors && setFieldError && setFieldError('contactForm', newErrors)
    }

    return (
        <div className="venue_venueForm contact-form">
            <div className="venue_venueForm_header contact">
                Vendor Contact Information
            </div>
            <div className="venue_venueForm_body">
                <div className="form_container">
                    <div className="venue_venueForm_body_info">
                        <div className="venue_venueForm_body_formGroup name">
                            <Input
                                label="Name"
                                placeholder={errors.contactForm?.name || 'Enter Vendor Name'}
                                isError={!!(errors.contactForm?.name)}
                                name="contactForm.name"
                                value={formData?.name || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onClickCross={() => onClickCancel('name')}
                            />
                        </div>
                        <AddressField
                            id={'autoComplete-contact'}
                            setFormValue={setFormValue}
                            onClickPlace={onClickPlace}
                            address={formData?.address || ''}
                            error={errors.contactForm?.address}
                            onBlur={handleBlur}
                            onClickCross={(fieldName: string) => onClickCancel(fieldName)}
                            
                        />
                        <div className="venue_venueForm_body_info_formGroupInline">
                            <div className="venue_venueForm_body_formGroup zip">
                                <Input
                                    label="Zip"
                                    placeholder={errors.contactForm?.zipcode || 'Enter Zip'}
                                    name="contactForm.zipcode"
                                    value={formData?.zipcode || ''}
                                    onChange={(e: any) => {
                                        if(e.target.value.length > 5){
                                            return;
                                        }
                                        handleChange(e)
                                    }}
                                    pattern="^(0|[1-9][0-9]*)"
                                    onBlur={handleBlur}
                                    onClickCross={() => onClickCancel('zipcode')}
                                    isError={!!(errors.contactForm?.zipcode)}
                                    type="number"
                                    disabled={zipcodeDisabled}
                                />
                            </div>
                            <div className="venue_venueForm_body_formGroup number">
                                <PhoneField 
                                    label="Phone"
                                    placeholder={errors.contactForm?.phone || '405-123-4567'}
                                    name="contactForm.phone"
                                    value={formData?.phone || ''}
                                    setValue={setFormsData}
                                    onClickCancel={() => onClickCancel('phone')}
                                    errors={errors}
                                    errorMessage={errors.contactForm?.phone}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="venue_venueForm_body_customerFacing">
                        <p className="desc">When customers have issues related to orders or errant charges they are directed to the vendor’s email and/or phone. The phone for each venue will take priority if specified. </p>
                        <div className="venue_venueForm_body_formGroup address">
                            <Input
                                label="Customer Facing Email"
                                name="contactForm.customer_facing_email"
                                placeholder={errors.contactForm?.customer_facing_email || 'Enter Issues Email'}
                                value={formData?.customer_facing_email || ''}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onClickCross={() => onClickCancel('customer_facing_email')}
                                isError={!!(errors.contactForm?.customer_facing_email)}
                                type="email"
                            />
                        </div>
                        <div className="venue_venueForm_body_formGroup address">
                            <PhoneField 
                                label="Customer Facing Phone"
                                name="contactForm.customer_facing_phone"
                                placeholder={errors.contactForm?.customer_facing_phone || '405-123-4567'}
                                value={formData?.customer_facing_phone}
                                setValue={(name: string, val: any) => {
                                    setFormsData(name, val);
                                    onAddCustomerPhone(val, formData.customer_facing_phone)
                                }}
                                onClickCancel={() => onClickCancel('customer_facing_phone')}
                                errors={errors}
                                errorMessage={errors.contactForm?.customer_facing_phone}
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default VendorContact;
