import { useRoleId } from "hooks/useRoleId";
import {  useLocation, Navigate, Outlet } from "react-router";

const VendorAdminRoutes = () => {
    const location = useLocation();
    const roleId = useRoleId();


    return roleId === 2
    ? <Outlet />
    : <Navigate to="*" state={{ from: location }} />
};

export default VendorAdminRoutes;
