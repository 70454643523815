import { AuthContext } from "contexts/auth";
import Cookies from "js-cookie"
import { useContext } from "react";
import {jwtDecode} from 'jwt-decode';
import { useToken } from "./useToken";

export const useRoleId = () => {
    const token = useToken();

    const decodedToken:any = token ? jwtDecode(token) : null;
    
    return decodedToken?.role;
}

export const useFullName = () => {
    const { user } = useContext(AuthContext);
    const token = user?.access_token || Cookies.get("crowd-chow-token");

    const decodedToken:any = token ? jwtDecode(token) : null;
    
    return `${decodedToken?.first_name}${decodedToken?.last_name ? ` ${decodedToken?.last_name}` : ''}`;
}

export const getInitials = (fullName: string) => {
    const splittedName = fullName.split(" ");

    let initials = "";
    splittedName.forEach((name: string) => {
        initials += name[0];
    });
    
    return initials;
}

export const useInitials = (fullName: string) => {
    return getInitials(fullName);
}