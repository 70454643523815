import { useContext } from "react";
import UpComingEvents from "../UpComingEvents";
import MostActiveVendors from "./MostActiveVendors";
import { SuperAdminDashboardContex } from "contexts/superAdminDashboard";
import { superAdminDashboardActions } from "contexts/reducers/superAdminDashboard";

const SuperDashboardBody = () => {
  const {
    dashboardData,
    loading,
    fetchDashboardData,
    dashboardQueryParams,
    setDashboardReducer,
  } = useContext(SuperAdminDashboardContex);
  const { events, events_count } = dashboardData;

  const fetchData = async (isEvent: boolean) => {
    const { event_limit, event_offset, offset, limit } = dashboardQueryParams;

    let newParams = {
      ...dashboardQueryParams,
      event_offset: event_limit * (Math.ceil(event_offset / event_limit) + 1),
    };
    if (!isEvent) {
      newParams = {
        ...dashboardQueryParams,
        offset: limit * (Math.ceil(offset / limit) + 1),
      };
    }

    setDashboardReducer(
      superAdminDashboardActions.SET_DASHBOARD_QUERY_PARAMS,
      newParams
    );

    let type = superAdminDashboardActions.GET_MORE_EVENTS_DATA;
    if (!isEvent) {
      type = superAdminDashboardActions.GET_MORE_SALES_DATA;
    }

    const res = await fetchDashboardData(newParams, type);

    return res;
  };

  const fetchEvents = async (queryParams: any) => {
    setDashboardReducer(superAdminDashboardActions.SET_DASHBOARD_QUERY_PARAMS, {
      ...queryParams,
      event_limit: 15,
      event_offset: 0,
    });
    const res = await fetchDashboardData(
      { ...queryParams, event_limit: 15, event_offset: 0 },
      superAdminDashboardActions.GET_EVENTS_DATA
    );
    return res;
  };

  const isLoading = loading[superAdminDashboardActions.GET_DASHBOARD_DATA];
  return (
    <div className="vendorDashboardBody">
      <UpComingEvents
        eventsList={events}
        events_count={events_count}
        isLoading={isLoading}
        isEventFetching={
          loading[superAdminDashboardActions.GET_MORE_EVENTS_DATA]
        }
        fetchData={() => fetchData(true)}
        event_offset={dashboardQueryParams.event_offset}
        fetchEvents={fetchEvents}
        dashboardQueryParams={dashboardQueryParams}
        eventsLoading={loading[superAdminDashboardActions.GET_EVENTS_DATA]}
      />
      <MostActiveVendors fetchData={() => fetchData(false)} />
    </div>
  );
};

export default SuperDashboardBody;
