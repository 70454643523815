interface Props {
    currentStep: string;
    selectedStep: string;
    onClickBox?: Function;
    isCompleted: boolean;
    onboarding_via_email?: boolean
}

const CheckListBox = ({isCompleted, onClickBox, currentStep, selectedStep, onboarding_via_email}: Props) => {

    return (
        <div onClick={() => onClickBox && onClickBox(currentStep)} role={onClickBox ? "button" : ""} className={`signup-body_checklist_items_checkListItem ${selectedStep === currentStep ? 'checklistComplete' : ''}`}>
            <span>
                {
                    onboarding_via_email ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51" fill="none">
                            <g filter="url(#filter0_d_1605_695)">
                                <path d="M25.5 42C36.2696 42 45 33.2696 45 22.5C45 11.7304 36.2696 3 25.5 3C14.7304 3 6 11.7304 6 22.5C6 33.2696 14.7304 42 25.5 42Z" fill="#E94929"/>
                                <path d="M35.047 18.0869L32.59 15.6299L23.2958 24.9241L18.4062 20.035L15.9492 22.492L23.2934 29.8358L35.047 18.0869Z" fill="white"/>
                            </g>
                            <defs>
                                <filter id="filter0_d_1605_695" x="0" y="0" width="51" height="51" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dy="3"/>
                                    <feGaussianBlur stdDeviation="3"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.565 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1605_695"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1605_695" result="shape"/>
                                </filter>
                            </defs>
                        </svg>
                    ) : isCompleted ? (
                        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51" fill="none">
                            <g filter="url(#filter0_d_1605_695)">
                                <path d="M25.5 42C36.2696 42 45 33.2696 45 22.5C45 11.7304 36.2696 3 25.5 3C14.7304 3 6 11.7304 6 22.5C6 33.2696 14.7304 42 25.5 42Z" fill="#27C1C9"/>
                                <path d="M35.047 18.0869L32.59 15.6299L23.2958 24.9241L18.4062 20.035L15.9492 22.492L23.2934 29.8358L35.047 18.0869Z" fill="white"/>
                            </g>
                            <defs>
                                <filter id="filter0_d_1605_695" x="0" y="0" width="51" height="51" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dy="3"/>
                                    <feGaussianBlur stdDeviation="3"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.565 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1605_695"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1605_695" result="shape"/>
                                </filter>
                            </defs>
                        </svg>
                    ) : (
                        <svg xmlns="http://www.w3.org/2000/svg" width="51" height="51" viewBox="0 0 51 51" fill="none">
                            <g filter="url(#filter0_d_1605_695)">
                                <path d="M25.5 42C36.2696 42 45 33.2696 45 22.5C45 11.7304 36.2696 3 25.5 3C14.7304 3 6 11.7304 6 22.5C6 33.2696 14.7304 42 25.5 42Z" fill="#484545"/>
                                <path d="M35.047 18.0869L32.59 15.6299L23.2958 24.9241L18.4062 20.035L15.9492 22.492L23.2934 29.8358L35.047 18.0869Z" fill="white"/>
                            </g>
                            <defs>
                                <filter id="filter0_d_1605_695" x="0" y="0" width="51" height="51" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dy="3"/>
                                    <feGaussianBlur stdDeviation="3"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.565 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1605_695"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_1605_695" result="shape"/>
                                </filter>
                            </defs>
                        </svg>
                    )}
                
                
            </span>
            <span className="signup-body_checklist_items_checkListItem_ItemContent">{currentStep}</span>
        </div>
    )
};

export default CheckListBox;
