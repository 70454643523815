interface IProps {
    children: any, 
    cursorPointer?: boolean; 
    customClass?: string;
}

const TableHead = ({children, customClass}: IProps) => {
    return (
        <div className={`thead ${customClass || ''}`}>
            {children}
        </div>
    )
};

export default TableHead;
