import { ReportContext } from "contexts/report";
import React, { FC, useContext, useState } from "react";


interface Props{

  activeTab: number
}




const TableHeader: FC<Props> = ({activeTab}) => {



  const {selectedEvent, selectedVenue, fetchAllOrder, setOrderQueryParams, startDate, endDate } =  useContext(ReportContext)
  const [isAscnding, setIsAscnding] = useState<boolean>(true);




  const handleSortClick = async(e: any)=>{
    setIsAscnding((prev)=> !prev)
    setOrderQueryParams((prev: { limit: number; offset: number, sort_order: string }) => ({
      ...prev,
      offset: 0, 
      sort_order: isAscnding ?  'asc' : 'desc'
    }));


    await fetchAllOrder({
      venue_id: selectedVenue?.id,
      event_id: activeTab === 0 && selectedEvent ?  selectedEvent.id :"",
      start_date: activeTab === 1 ? startDate: "",
      end_date: activeTab === 1 ? endDate: "",
      offset:0,
      limit:20,
      sort_order: isAscnding ? 'asc' : 'desc'
        
    });




  }



console.log(activeTab);


  return (
    <div className="vendor-item-table-header">
      <div style={{cursor:'pointer'}} onClick={handleSortClick} className="th order">
        <p>Odrer No.</p>


        <div className="downArrow">         
         {isAscnding ? <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="15"
            viewBox="0 0 20 15"
            fill="none"
          >
            <g filter="url(#filter0_d_9482_3137)">
              <path
                d="M15.1225 0.5L10 8.105L4.87746 0.5L15.1225 0.5Z"
                stroke="#222222"
                shapeRendering="crispEdges"
              />
            </g>
            <defs>
              <filter
                id="filter0_d_9482_3137"
                x="0.937866"
                y="0"
                width="18.1243"
                height="15"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="3" />
                <feGaussianBlur stdDeviation="1.5" />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.517647 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_9482_3137"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_9482_3137"
                  result="shape"
                />
              </filter>
            </defs>
          </svg> : <svg
  xmlns="http://www.w3.org/2000/svg"
  width="20"
  height="15"
  viewBox="0 0 20 15"
  fill="none"
>
  <g transform="scale(1,-1) translate(0,-15)">
    <g filter="url(#filter0_d_9482_3137)">
      <path
        d="M15.1225 0.5L10 8.105L4.87746 0.5L15.1225 0.5Z"
        stroke="#222222"
        shapeRendering="crispEdges"
      />
    </g>
  </g>
  <defs>
    <filter
      id="filter0_d_9482_3137"
      x="0.937866"
      y="0"
      width="18.1243"
      height="15"
      filterUnits="userSpaceOnUse"
      colorInterpolationFilters="sRGB"
    >
      <feFlood floodOpacity="0" result="BackgroundImageFix" />
      <feColorMatrix
        in="SourceAlpha"
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
        result="hardAlpha"
      />
      <feOffset dy="3" />
      <feGaussianBlur stdDeviation="1.5" />
      <feComposite in2="hardAlpha" operator="out" />
      <feColorMatrix
        type="matrix"
        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.517647 0"
      />
      <feBlend
        mode="normal"
        in2="BackgroundImageFix"
        result="effect1_dropShadow_9482_3137"
      />
      <feBlend
        mode="normal"
        in="SourceGraphic"
        in2="effect1_dropShadow_9482_3137"
        result="shape"
      />
    </filter>
  </defs>
</svg>}




        </div>










      </div>

      <div className="th time">Time</div>
      <div className="th item">Items</div>
      <div className="th total">Total</div>
      <div className="th status">Status</div>
    </div>
  );
};

export default TableHeader;
