const VenuePreviewCard = ({venuePicture}: {venuePicture: string}) => {
    return (
        <div className="sign-priview">
            {venuePicture ? (
                <div className="priview-img">
                    <img src={venuePicture} alt="Preview" />
                </div>
            ):(
                <p className="no-preview-example">No Venue Example</p>
            )}
            <div className="label-container">
                <label className="rotate-label">Venue Example</label>
            </div>
        </div>
    )
};

export default VenuePreviewCard;
