import { TableData, TableRow } from "components/common/table";
import { useEffect, useRef, useState } from "react";
import { getFullName } from "util/getFullName";

const Item = ({data}: any) => {

    const reasonRef = useRef<HTMLElement>(null);
    const customerRef = useRef<any>(null);
    const itemRef = useRef<HTMLElement>(null);

    const [, setTooltip] = useState({
        cancellationReason: false,
        customerName: false,
        itemName: false
    })

    const { item_name, cancellation_reason, order_id, users } = data;
    const { first_name, last_name } = users || {};
    const fullName = getFullName(first_name, last_name);

    useEffect(() => {
        if(data.users?.first_name && customerRef.current){
            setTooltip((prev: any) => ({
                ...prev,
                customerName: customerRef.current ? customerRef.current.scrollWidth - customerRef.current.clientWidth > 0 : false
            }))
        }

        if(data.cancellation_reason && reasonRef.current){
            setTooltip((prev: any) => ({
                ...prev,
                cancellationReason: reasonRef.current ? reasonRef.current.scrollWidth - reasonRef.current.clientWidth > 0 : false
            }))
        }

        if(data.item_name && itemRef.current){
            setTooltip((prev: any) => ({
                ...prev,
                itemName: itemRef.current ? itemRef.current.scrollWidth - itemRef.current.clientWidth > 0 : false
            }))
        }
    },[data, customerRef, reasonRef, itemRef])

    return (
        <TableRow nonClickableRow>
            <TableData customClass="order-number">
                {order_id || ''}
            </TableData>
            <TableData customClass="customer-name">
                {item_name || ''}
            </TableData>
            <TableData customClass="customer-name">
                {fullName || ''}
            </TableData>
            <TableData customClass="refund-reason">
                {cancellation_reason || ''}
            </TableData>
        </TableRow>
    )
};

export default Item;
