import { useContext } from "react";
import SingleStat from "../SingleStat";
import { SuperAdminDashboardContex } from "contexts/superAdminDashboard";

const SuperStatsHeader = () => {
  const { dashboardData } = useContext(SuperAdminDashboardContex);
  const { weekly_sales, live_events, total_customers, users_online } =
    dashboardData;

  const weekly_sales_string = Math.abs(weekly_sales)
    .toString()
    .replace(".", "");

  const live_events_string = Math.abs(live_events).toString().replace(".", "");
  const total_customers_string = Math.abs(total_customers)
    .toString()
    .replace(".", "");

  const users_online_string = Math.abs(users_online)
    .toString()
    .replace(".", "");


  return (
    <div className="vendorStatsHeader">
      <SingleStat
        lable="Weekly Sales"
        customClass={`vendorStats weeklySaleStat ${
          weekly_sales_string.length === 5
            ? "figuerLengthFive"
            : weekly_sales_string.length === 6
            ? "figuerLengthSix"
            : ""
        }`}
        stat={weekly_sales}
        isWeeklySale={true}
      />
      <SingleStat
        lable={live_events === 1 ? "Live Event" : "Live Events"}
        customClass={`vendorStats liveEventsStat  ${
          live_events_string.length === 5
            ? "figuerLengthFive"
            : live_events_string.length === 6
            ? "figuerLengthSix"
            : ""
        } `}
        stat={live_events}
      />
      <SingleStat
        lable={total_customers === 1 ? "total Customer" : "total Customers"}
        customClass={`vendorStats totalCustomersStat ${
          total_customers_string.length === 5
            ? "figuerLengthFive"
            : total_customers_string.length === 6
            ? "figuerLengthSix"
            : ""
        } `}
        stat={total_customers}
      />
      <SingleStat
        lable={users_online === 1 ? "Customer Online" : "Customers Online"}
        customClass={`vendorStats usersOnlineStat ${
          users_online_string.length === 5
            ? "figuerLengthFive"
            : users_online_string.length === 6
            ? "figuerLengthSix"
            : ""
        }`}
        stat={users_online}
      />
    </div>
  );
};

export default SuperStatsHeader;
