import { FC } from "react";

interface Props  {
    isDisabled? : boolean,
}

const HierarchyIcon : FC<Props> = ({isDisabled}) => {





    return (
        <>
            {/* {!isDisabled && <svg className="active-tab-svg" width="42" height="35" viewBox="0 0 42 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.4" clipPath="url(#clip0_2816_339)">
                <path d="M26.1978 3.15039H17.9028C17.4037 3.15039 16.999 3.55882 16.999 4.06264V10.9801C16.999 11.4839 17.4037 11.8923 17.9028 11.8923H26.1978C26.6969 11.8923 27.1016 11.4839 27.1016 10.9801V4.06264C27.1016 3.55882 26.6969 3.15039 26.1978 3.15039Z" fill="#696969"/>
                <path d="M26.1978 25.4414H17.9028C17.4037 25.4414 16.999 25.8498 16.999 26.3537V33.2711C16.999 33.7749 17.4037 34.1833 17.9028 34.1833H26.1978C26.6969 34.1833 27.1016 33.7749 27.1016 33.2711V26.3537C27.1016 25.8498 26.6969 25.4414 26.1978 25.4414Z" fill="#696969"/>
                <path d="M41.0962 25.4414H32.8013C32.3021 25.4414 31.8975 25.8498 31.8975 26.3537V33.2711C31.8975 33.7749 32.3021 34.1833 32.8013 34.1833H41.0962C41.5953 34.1833 42 33.7749 42 33.2711V26.3537C42 25.8498 41.5953 25.4414 41.0962 25.4414Z" fill="#696969"/>
                <path d="M11.2988 25.4414H3.00389C2.50474 25.4414 2.1001 25.8498 2.1001 26.3537V33.2711C2.1001 33.7749 2.50474 34.1833 3.00389 34.1833H11.2988C11.798 34.1833 12.2026 33.7749 12.2026 33.2711V26.3537C12.2026 25.8498 11.798 25.4414 11.2988 25.4414Z" fill="#696969"/>
                <path d="M38.4124 17.2988H5.69287V20.6344H38.4124V17.2988Z" fill="#696969"/>
                <path d="M8.61495 19.0947H5.69287V23.3594H8.61495V19.0947Z" fill="#696969"/>
                <path d="M23.5139 19.0947H20.5918V23.3594H23.5139V19.0947Z" fill="#696969"/>
                <path d="M23.5139 14.0811H20.5918V18.3457H23.5139V14.0811Z" fill="#696969"/>
                <path d="M38.4128 19.0947H35.4907V23.3594H38.4128V19.0947Z" fill="#696969"/>
                </g>
                <g clipPath="url(#clip1_2816_339)">
                <path d="M24.0977 0H15.8027C15.3036 0 14.8989 0.408429 14.8989 0.912251V7.82969C14.8989 8.33351 15.3036 8.74194 15.8027 8.74194H24.0977C24.5968 8.74194 25.0015 8.33351 25.0015 7.82969V0.912251C25.0015 0.408429 24.5968 0 24.0977 0Z" fill="#4285F4"/>
                <path d="M24.0977 22.291H15.8027C15.3036 22.291 14.8989 22.6994 14.8989 23.2033V30.1207C14.8989 30.6245 15.3036 31.033 15.8027 31.033H24.0977C24.5968 31.033 25.0015 30.6245 25.0015 30.1207V23.2033C25.0015 22.6994 24.5968 22.291 24.0977 22.291Z" fill="#26CAD3"/>
                <path d="M38.9961 22.291H30.7012C30.202 22.291 29.7974 22.6994 29.7974 23.2033V30.1207C29.7974 30.6245 30.202 31.033 30.7012 31.033H38.9961C39.4953 31.033 39.8999 30.6245 39.8999 30.1207V23.2033C39.8999 22.6994 39.4953 22.291 38.9961 22.291Z" fill="#26CAD3"/>
                <path d="M9.19874 22.291H0.903794C0.404642 22.291 0 22.6994 0 23.2033V30.1207C0 30.6245 0.404642 31.033 0.903794 31.033H9.19874C9.69789 31.033 10.1025 30.6245 10.1025 30.1207V23.2033C10.1025 22.6994 9.69789 22.291 9.19874 22.291Z" fill="#26CAD3"/>
                <path d="M36.3123 14.1484H3.59277V17.484H36.3123V14.1484Z" fill="#484545"/>
                <path d="M6.51486 15.9443H3.59277V20.209H6.51486V15.9443Z" fill="#484545"/>
                <path d="M21.4138 15.9443H18.4917V20.209H21.4138V15.9443Z" fill="#484545"/>
                <path d="M21.4138 10.9307H18.4917V15.1953H21.4138V10.9307Z" fill="#484545"/>
                <path d="M36.3127 15.9443H33.3906V20.209H36.3127V15.9443Z" fill="#484545"/>
                </g>
                <defs>
                <clipPath id="clip0_2816_339">
                <rect width="39.9" height="31.0333" fill="white" transform="translate(2.1001 3.15039)"/>
                </clipPath>
                <clipPath id="clip1_2816_339">
                <rect width="39.9" height="31.0333" fill="white"/>
                </clipPath>
                </defs>
            </svg>} */}
            <svg className="non-active-svg" width="36" height="33" viewBox="0 0 36 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.4">
                <path d="M19.7566 10.0864H13.8691C13.5149 10.0864 13.2277 10.3763 13.2277 10.7339V15.6436C13.2277 16.0012 13.5149 16.2911 13.8691 16.2911H19.7566C20.1109 16.2911 20.3981 16.0012 20.3981 15.6436V10.7339C20.3981 10.3763 20.1109 10.0864 19.7566 10.0864Z" fill="#222222"/>
                <path d="M19.7566 25.9073H13.8691C13.5149 25.9073 13.2277 26.1972 13.2277 26.5548V31.4645C13.2277 31.8221 13.5149 32.112 13.8691 32.112H19.7566C20.1109 32.112 20.3981 31.8221 20.3981 31.4645V26.5548C20.3981 26.1972 20.1109 25.9073 19.7566 25.9073Z" fill="#222222"/>
                <path d="M30.3308 25.9073H24.4434C24.0891 25.9073 23.8019 26.1972 23.8019 26.5548V31.4645C23.8019 31.8221 24.0891 32.112 24.4434 32.112H30.3308C30.6851 32.112 30.9723 31.8221 30.9723 31.4645V26.5548C30.9723 26.1972 30.6851 25.9073 30.3308 25.9073Z" fill="#222222"/>
                <path d="M9.18137 25.9073H3.29389C2.93961 25.9073 2.6524 26.1972 2.6524 26.5548V31.4645C2.6524 31.8221 2.93961 32.112 3.29389 32.112H9.18137C9.53565 32.112 9.82285 31.8221 9.82285 31.4645V26.5548C9.82285 26.1972 9.53565 25.9073 9.18137 25.9073Z" fill="#222222"/>
                <path d="M28.4258 20.1291H5.20251V22.4966H28.4258V20.1291Z" fill="#222222"/>
                <path d="M7.27651 21.4031H5.20251V24.43H7.27651V21.4031Z" fill="#222222"/>
                <path d="M17.8507 21.4031H15.7767V24.43H17.8507V21.4031Z" fill="#222222"/>
                <path d="M17.8507 17.8452H15.7767V20.8721H17.8507V17.8452Z" fill="#222222"/>
                <path d="M28.426 21.4031H26.352V24.43H28.426V21.4031Z" fill="#222222"/>
                </g>
                <path d="M19.0662 9.36371H13.1787C12.8244 9.36371 12.5372 9.6536 12.5372 10.0112V14.921C12.5372 15.2786 12.8244 15.5685 13.1787 15.5685H19.0662C19.4205 15.5685 19.7077 15.2786 19.7077 14.921V10.0112C19.7077 9.6536 19.4205 9.36371 19.0662 9.36371Z" fill="white"/>
                <path d="M19.0662 25.1846H13.1787C12.8244 25.1846 12.5372 25.4745 12.5372 25.8321V30.7419C12.5372 31.0995 12.8244 31.3894 13.1787 31.3894H19.0662C19.4205 31.3894 19.7077 31.0995 19.7077 30.7419V25.8321C19.7077 25.4745 19.4205 25.1846 19.0662 25.1846Z" fill="white"/>
                <path d="M29.6404 25.1846H23.7529C23.3987 25.1846 23.1115 25.4745 23.1115 25.8321V30.7419C23.1115 31.0995 23.3987 31.3894 23.7529 31.3894H29.6404C29.9947 31.3894 30.2819 31.0995 30.2819 30.7419V25.8321C30.2819 25.4745 29.9947 25.1846 29.6404 25.1846Z" fill="white"/>
                <path d="M8.49094 25.1846H2.60346C2.24918 25.1846 1.96198 25.4745 1.96198 25.8321V30.7419C1.96198 31.0995 2.24918 31.3894 2.60346 31.3894H8.49094C8.84522 31.3894 9.13242 31.0995 9.13242 30.7419V25.8321C9.13242 25.4745 8.84522 25.1846 8.49094 25.1846Z" fill="white"/>
                <path d="M27.7354 19.4064H4.51208V21.7739H27.7354V19.4064Z" fill="white"/>
                <path d="M6.58608 20.6804H4.51208V23.7073H6.58608V20.6804Z" fill="white"/>
                <path d="M17.1603 20.6804H15.0863V23.7073H17.1603V20.6804Z" fill="white"/>
                <path d="M17.1603 17.1226H15.0863V20.1495H17.1603V17.1226Z" fill="white"/>
                <path d="M27.7356 20.6804H25.6616V23.7073H27.7356V20.6804Z" fill="white"/>
            </svg>
            <svg className="svg-mob" width="30" height="23" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M18.7566 0.722656H12.8691C12.5148 0.722656 12.2276 1.01255 12.2276 1.37014V6.27992C12.2276 6.63751 12.5148 6.9274 12.8691 6.9274H18.7566C19.1108 6.9274 19.398 6.63751 19.398 6.27992V1.37014C19.398 1.01255 19.1108 0.722656 18.7566 0.722656Z" fill="#222222"/>
                <path d="M18.7566 16.5435H12.8691C12.5148 16.5435 12.2276 16.8334 12.2276 17.191V22.1008C12.2276 22.4584 12.5148 22.7483 12.8691 22.7483H18.7566C19.1108 22.7483 19.398 22.4584 19.398 22.1008V17.191C19.398 16.8334 19.1108 16.5435 18.7566 16.5435Z" fill="#222222"/>
                <path d="M29.3308 16.5435H23.4433C23.089 16.5435 22.8018 16.8334 22.8018 17.191V22.1008C22.8018 22.4584 23.089 22.7483 23.4433 22.7483H29.3308C29.6851 22.7483 29.9723 22.4584 29.9723 22.1008V17.191C29.9723 16.8334 29.6851 16.5435 29.3308 16.5435Z" fill="#222222"/>
                <path d="M8.18131 16.5435H2.29383C1.93955 16.5435 1.65234 16.8334 1.65234 17.191V22.1008C1.65234 22.4584 1.93955 22.7483 2.29383 22.7483H8.18131C8.53559 22.7483 8.82279 22.4584 8.82279 22.1008V17.191C8.82279 16.8334 8.53559 16.5435 8.18131 16.5435Z" fill="#222222"/>
                <path d="M27.4257 10.7654H4.20245V13.1329H27.4257V10.7654Z" fill="#222222"/>
                <path d="M6.27645 12.0394H4.20245V15.0663H6.27645V12.0394Z" fill="#222222"/>
                <path d="M16.8507 12.0394H14.7767V15.0663H16.8507V12.0394Z" fill="#222222"/>
                <path d="M16.8507 8.48153H14.7767V11.5084H16.8507V8.48153Z" fill="#222222"/>
                <path d="M27.4259 12.0394H25.3519V15.0663H27.4259V12.0394Z" fill="#222222"/>
                <path d="M18.0661 0H12.1787C11.8244 0 11.5372 0.289889 11.5372 0.647486V5.55726C11.5372 5.91486 11.8244 6.20475 12.1787 6.20475H18.0661C18.4204 6.20475 18.7076 5.91486 18.7076 5.55726V0.647486C18.7076 0.289889 18.4204 0 18.0661 0Z" fill="white"/>
                <path d="M18.0661 15.8209H12.1787C11.8244 15.8209 11.5372 16.1108 11.5372 16.4684V21.3782C11.5372 21.7357 11.8244 22.0256 12.1787 22.0256H18.0661C18.4204 22.0256 18.7076 21.7357 18.7076 21.3782V16.4684C18.7076 16.1108 18.4204 15.8209 18.0661 15.8209Z" fill="white"/>
                <path d="M28.6404 15.8209H22.7529C22.3986 15.8209 22.1114 16.1108 22.1114 16.4684V21.3782C22.1114 21.7357 22.3986 22.0256 22.7529 22.0256H28.6404C28.9946 22.0256 29.2819 21.7357 29.2819 21.3782V16.4684C29.2819 16.1108 28.9946 15.8209 28.6404 15.8209Z" fill="white"/>
                <path d="M7.49088 15.8209H1.6034C1.24912 15.8209 0.961914 16.1108 0.961914 16.4684V21.3782C0.961914 21.7357 1.24912 22.0256 1.6034 22.0256H7.49088C7.84516 22.0256 8.13236 21.7357 8.13236 21.3782V16.4684C8.13236 16.1108 7.84516 15.8209 7.49088 15.8209Z" fill="white"/>
                <path d="M26.7353 10.0427H3.51202V12.4102H26.7353V10.0427Z" fill="white"/>
                <path d="M5.58602 11.3167H3.51202V14.3436H5.58602V11.3167Z" fill="white"/>
                <path d="M16.1603 11.3167H14.0863V14.3436H16.1603V11.3167Z" fill="white"/>
                <path d="M16.1603 7.75887H14.0863V10.7858H16.1603V7.75887Z" fill="white"/>
                <path d="M26.7355 11.3167H24.6615V14.3436H26.7355V11.3167Z" fill="white"/>
            </svg>
        </>
    )
}

export default HierarchyIcon;