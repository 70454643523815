import React, { FC, useContext, useEffect,  } from "react";

import EventsTableHeader from "../events/EventsTable/EventsTableHeader";
import EventsTableBody from "../events/EventsTable/EventsTableBody";
import { SuperAdminReportContext } from "contexts/superAdminReport";
import { SuperAdminReportActions } from "contexts/reducers/superAdminReports";
import moment from "moment";
import StartDateDropdown from "components/reports/normalAdmin/dropdowns/startDate";
import EndDateDropdown from "components/reports/normalAdmin/dropdowns/endDate";
import VendorDropdown from "../DropDowns/SuperAdminvendorDropDown";
import { VendorContext } from "contexts/vendor";



interface Props {
  isPrint?: boolean;  
  activeTab?: any
}

const DatesBody: FC<Props> = ({ isPrint = false, activeTab }) => {
  const { fetchReprtEvents, datesQueryParams, setReportReducer, datesStartDate, setDatesEndDate, datesEndDate, setDatesStartDate, setSelectedVendor, selectedVendor } = useContext(
    SuperAdminReportContext
  );


  const {vendorList} = useContext(VendorContext)
  





  
  

useEffect(() => {
    !isPrint && fetchReprtEvents({...datesQueryParams, vendor_id: 0});
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);



  const onSelectDate = (date: any)=>{
    setDatesStartDate(date)
    const newQueryParams = {
        ...datesQueryParams,
        start_date: moment(date).format("YYYY-MM-DD"),
      };
     
      setReportReducer(
        SuperAdminReportActions.SET_DATES_QUERY_PARAMS,
        newQueryParams
      );
  
      !isPrint && fetchReprtEvents(newQueryParams);

  }

  const onSelectEndDate = (date: any)=>{
    setDatesEndDate(date)
    const newQueryParams = {
        ...datesQueryParams,
        end_date: moment(date).format("YYYY-MM-DD"),
          
      };

      setReportReducer(
        SuperAdminReportActions.SET_DATES_QUERY_PARAMS,
        newQueryParams
      );
  
      !isPrint && fetchReprtEvents(newQueryParams);

  }



  const onChangeVendor =  (vendor: any)=>{
    const newQueryParams = {
      ...datesQueryParams,
      vendor_id: vendor.id,
      offset: 0,
    };

    setReportReducer(
      SuperAdminReportActions.SET_EVENT_QUERY_PARAMS,
      newQueryParams
    );
    fetchReprtEvents(newQueryParams);



  }










  return (
    <div className="superEventReportsBody">
      <div className="action-dropdown-container event-detail-table-header">

        <div className="vendor-searchable-dropdown">
          <VendorDropdown 
            onSelectVendor = {onChangeVendor}
            selectedVendor = {selectedVendor}
            setSelectedVendor = {setSelectedVendor}
            vendorList = {vendorList}
          />
        </div>
          <div className="date-dropdowns">
                <StartDateDropdown
                    onSelectDate={(date: any) => onSelectDate(date)}
                    value={datesStartDate}
                />
            </div>
            <div className="date-dropdowns">
                <EndDateDropdown
                    onSelectEndDate={(date: any) => onSelectEndDate(date)}
                    value={datesEndDate}
                />
            </div>
            </div>

      <div className="allEventsTable">
        <EventsTableHeader activeTab = {activeTab} isPrint={isPrint} />
        <EventsTableBody activeTab = {activeTab} />
      </div>
    </div>
  );
};

export default DatesBody;
