import SimpleLoader from "components/common/SimpleLoader";

interface IProps {
    showDropdown: boolean;
    setShowDropdown: Function;
    isSendingSalesKit: boolean;
    vendorOption: any;
    onSelectOption: Function;
}

const SendSalesKitDropdown = ({setShowDropdown, showDropdown, isSendingSalesKit, vendorOption, onSelectOption}: IProps) => {
  return (
    <>
        <div onClick={() => setShowDropdown((prev: boolean) => !prev)} className="bttn">
            Send Sales Kit
            <svg
                className="downArrow"
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="9"
                viewBox="0 0 15 9"
                fill="none"
            >
                <path
                    d="M1 1L7.45166 7.45166L13.9033 1"
                    stroke="#303030"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                />
            </svg>
        </div>
        <div
            className={`action-dropDwon-options-box ${
            showDropdown ? "option-box-open" : ""
            }`}
        >
            <div
                onClick={() => onSelectOption("Send Sales Kit")}
                className={`dropDown-option ${isSendingSalesKit ? "disabled" : ""}`}
            >
                Send Sales Kit
                {isSendingSalesKit && <SimpleLoader />}
            </div>
            <div
                onClick={() => onSelectOption(vendorOption)}
                className="dropDown-option"
            >
                {vendorOption}
            </div>
        </div>
    </>
  );
};

export default SendSalesKitDropdown;
