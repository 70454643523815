export const getBillingStatusEnumValue = (statuses: {label: string, value: string}[], formattedStatus: string) => {
    const selectedStatus = statuses?.find((item: {label: string, value: string}) => item?.label === formattedStatus);

    return selectedStatus?.value;
}

export const getBillingStatusFormattedValue = (statuses: {label: string, value: string}[], enumValue: string) => {
    const selectedStatus = statuses?.find((item: {label: string, value: string}) => item?.value === enumValue);

    return selectedStatus?.label;
}