/* eslint-disable no-useless-escape */
import { formatPhone, removeSpecialCharsAndWhiteSpaces } from "util/formatPhone";
import Input from "../input";
import { useDebouncedCallback } from "use-debounce";

const PhoneField = ({isError, errorMessage, width, errors, setErrors, name, value, setValue, placeholder, onClickCancel, label}:any) => {

    const phoneFormatting = useDebouncedCallback((fieldName: string, value: any) => {
        const updatedValue = formatPhone(value);
        setValue(fieldName, updatedValue);
    },600)

    
    return (
        <>
            <Input
                label={label}
                placeholder={placeholder}
                name={name}
                value={value || ''}
                onChange={(e: any) => {
                    const val = e.target.value;
                    const lastEnterdChar = val[val.length-1];
                    const regexPatt = /[!@#$%^&*,.<>?\/]/;
                    const filteredValue = removeSpecialCharsAndWhiteSpaces(val);
                    if(
                        filteredValue.length > 10 || 
                        regexPatt.test(lastEnterdChar) ||
                        (lastEnterdChar >= 'a' && lastEnterdChar <= 'z') || 
                        (lastEnterdChar >= 'A' && lastEnterdChar <= 'Z')){
                        return;
                    }

                    setValue(name, val);
                    phoneFormatting(e.target.name, filteredValue);
                }}
                onClickCross={onClickCancel ? () => onClickCancel('phone') : undefined}
                isError={!!errorMessage}
                width={width}
                errors={errors}
                setErrors={setErrors}
            />
        </>
    )
};

export default PhoneField;
