import { useEffect, useRef, useState } from "react";
import LeftArrow from "./leftArrow";
import RightArrow from "./rightArrow";
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { usePaginationData } from "hooks/usePaginationData";

interface Props {
    count: number;
    activePage: number;
    onClickArrow: (page: any) => void;
    showNavigation?: boolean;
    customParentStyle?: any;
}

const Pagination = ({count, customParentStyle, activePage, onClickArrow, showNavigation=true}: Props) => {
    const sliderRef = useRef<any>(null);

    const {perPage} = usePaginationData();

    const isLargePagination = count > perPage; //true 
    const middle = Math.ceil(perPage/2);

    const [startIndex, setStartIndex] = useState(-1);
    const [lastIndex, setLastIndex] = useState(perPage-1);

    const fadeoutStartingPages = isLargePagination && startIndex > -1;
    const fadeoutEndingPages = isLargePagination && activePage <= (count-middle-1);

    const dots = new Array(count).fill(0);

    const updateStartAndEndInices = (page: number, slidesCount: number) => {
        if(isLargePagination && page >= middle){
            const newIndex = page < slidesCount-(middle-1) ? page-(middle-1) : slidesCount-perPage
            setStartIndex(newIndex);
        }else{
            setStartIndex((prev: any) => prev > -1 ? -1 : prev)
        }
        
        if(isLargePagination && page < slidesCount-(middle-1)){
            page >= middle ? setLastIndex(page+middle-1) : setLastIndex(perPage-1);
        }
    }

    const onClickPage = (page: number, slidesCount: number) => {
        updateStartAndEndInices(page, slidesCount);

        onClickArrow(page);
    }


    useEffect(() => {
        if(activePage){
            new Array(activePage).fill(0).forEach((_: any, index: number) => {
                sliderRef?.current?.splide?.go(index+1);
            })
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[activePage]);

    return count > 0 ? (
        <>
            <div style={{...(customParentStyle && customParentStyle)}} className="pagination">
                <div>
                    {showNavigation && (
                        <LeftArrow
                            onClick={() => {
                                isLargePagination
                                ? sliderRef?.current?.splide?.go('<')
                                : onClickPage(activePage-1, count);
                            }}
                            disbaled={activePage < 1}
                        />
                    )}
                </div>
                <div style={{display: isLargePagination ? 'flex' : 'flex'}} className="paginationDots">
                    {isLargePagination ? (
                        <Splide
                            onMove={(splide: any, newIndex: number) => {
                                onClickPage(newIndex, splide?.Components?.Slides?.getLength());
                                console.log('onMove', splide?.Components?.Slides?.getLength());
                            }}
                            ref={sliderRef}
                            options={ {
                                width : 840,
                                gap   : '1rem',
                                arrows: false,
                                focus: 'center',
                                pagination: false,
                                perPage: count > perPage ? 21 : count,

                                breakpoints: {
                                    1180: {
                                        width : 750,
                                        perPage: 15,
                                    },
                                    1050: {
                                        width : 600,
                                        perPage: 13,
                                    },
                                    950: {
                                        width : 480,
                                        perPage: 11,
                                    },
                                    865: {
                                        width : 353,
                                        perPage: 9,
                                    },
                                    768: {
                                        width : 203,
                                        perPage: 5,
                                    },
                                    450: {
                                        width : 200,
                                        perPage: 3,
                                    },
                                }
                            } 
                        }
                        
                        >
                            {dots.map((_: any, index: number) => {
                                const isActive = index === activePage
                                const disableStartingPages = (
                                    perPage > 3 ? (index === startIndex || index === startIndex+1) : index === startIndex
                                ) && fadeoutStartingPages;
                                // console.log('disableStartingPages', disableStartingPages, fadeoutStartingPages);
                                const disableEndPages = (
                                    perPage > 3 ? (index === lastIndex || index === lastIndex-1) : index === lastIndex
                                ) && fadeoutEndingPages;

                                const isSmallStartingPage = index === startIndex && fadeoutStartingPages;
                                const isSmallEndPage = index === lastIndex && fadeoutEndingPages;
                                return (
                                    <SplideSlide>
                                        <div
                                            data-id={index}
                                            className={`cursor-pointer ${(disableEndPages || disableStartingPages) ? 'disabled' : ''}`}
                                            onClick={() => {
                                                isLargePagination 
                                                ? sliderRef?.current?.splide?.go(index)
                                                : onClickPage(index, count);
                                            }} key={index}
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                width={`${(isSmallEndPage || isSmallStartingPage) ? '27' : '31'}`} 
                                                height={`${(isSmallEndPage || isSmallStartingPage) ? '27' : '31'}`} 
                                                viewBox="0 0 31 31" 
                                                fill="none"
                                            >
                                                <g filter="url(#filter0_d_3149_1550)">
                                                    <path d="M14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49187 22.5081 0 14.5 0C6.49187 0 0 6.49187 0 14.5C0 22.5081 6.49187 29 14.5 29Z" fill={isActive ? '#27C1C9': '#868383'}/>
                                                </g>
                                                <defs>
                                                    <filter id="filter0_d_3149_1550" x="0" y="0" width="31" height="31" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                                        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                                        <feOffset dx="2" dy="2"/>
                                                        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
                                                        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3149_1550"/>
                                                        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3149_1550" result="shape"/>
                                                    </filter>
                                                </defs>
                                            </svg>
                                        </div>
                                    </SplideSlide>
                                )
                            })}
                        </Splide>
                    ):(
                        dots.map((_: any, index: number) => {
                            const isActive = index === activePage;
                            return (
                                <div 
                                    className="cursor-pointer"
                                    onClick={() => onClickPage(index, count)} key={index}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg" 
                                        width="31"
                                        height="31"
                                        viewBox="0 0 31 31" 
                                        fill="none"
                                    >
                                        <g filter="url(#filter0_d_3149_1550)">
                                            <path d="M14.5 29C22.5081 29 29 22.5081 29 14.5C29 6.49187 22.5081 0 14.5 0C6.49187 0 0 6.49187 0 14.5C0 22.5081 6.49187 29 14.5 29Z" fill={isActive ? '#27C1C9': '#868383'}/>
                                        </g>
                                        <defs>
                                            <filter id="filter0_d_3149_1550" x="0" y="0" width="31" height="31" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                                <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                                <feOffset dx="2" dy="2"/>
                                                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
                                                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3149_1550"/>
                                                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3149_1550" result="shape"/>
                                            </filter>
                                        </defs>
                                    </svg>
                                </div>
                            )
                        })
                    )}
                    
                </div>
                <div>
                {showNavigation && (
                        <RightArrow
                            onClick={() => {
                                isLargePagination
                                ? sliderRef?.current?.splide?.go('>')
                                : onClickPage(activePage+1, count);
                                
                            }}
                            disbaled={activePage >= (dots.length-1)}
                        />
                )}
                </div>
            </div>
        </>
    ):(
        <></>
    )
};

export default Pagination;
