interface Props {
    onImageUpload: Function;
    venueName: string;
    venue_logo: string;
    loading: boolean;
    isError?: boolean;
}
const VenueLogo = ({onImageUpload, venueName, venue_logo, loading, isError}: Props) => {
    return (
        <div className="venue-event-logo-detail">
            <div className="logo-detail">
                <h3 className="logo-detail-heading">{venueName} Logo</h3>
                <p className="logo-detail-desc">The Venue logo is used across the system, most prominently on the Event Selection screen. Logo should be a 600 x 600 transparent PNG.</p>
            </div>
            <div style={{border: isError ? '2px solid red' : ''}} className="event-detail">
                <div className="event-logo">
                    {venue_logo ? (
                        <img 
                            src={venue_logo}
                            alt="Logo"
                        />
                    ):(
                        <div className="add-event-logo">
                            <p>Logo</p>
                        </div>
                    )}

                </div>
                <div className="event-info">
                    <p className="event-vs">Example High vs <br /> Example North High</p>
                    <p className="event-time">Today, 6:00 PM</p>
                </div>
            </div>
            <div className="choose-event-logo">
                <div className={`choose-logo-img ${loading ? 'disabled' : ''}`}>
                    <input 
                        disabled={loading} 
                        name="venue_logo" 
                        onChange={(e: any) => onImageUpload(e)}
                        type="file"
                        accept="image/png"
                    />
                    {loading ? 'Uploading...' : 'Choose Image'}
                </div>
            </div>
        </div>
    )
}

export default VenueLogo;