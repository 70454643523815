import { getClassName } from "hooks/useCalculateLength";

interface IProps {
    children?: React.ReactNode;
    value: number | string | undefined;
    label?: string;
    customClassParent?: string;
}

const EventLabel = ({children, value, label, customClassParent}: IProps) => {
    const isDecimalValue = typeof value === "string" && value?.includes(".");
    const lengthClass = typeof value === "string" && isDecimalValue ? getClassName(value?.replace(".", "")?.length) : '';
    return (
        <div className={`summary-total ${customClassParent || ''}`}> 
            <div className={`hightlight-number ${lengthClass}`}>
                {value} 
            </div>
            {label ? (
                <p className='sec-label'>
                    {label}
                </p>
            ):(
                <>{children}</>
            )}
        </div>
    )
}

export default EventLabel;