const UpButton = ({showAdd, isButtonExists}: any) => (
    <a href="#appContainerTop" className={`up-button ${(showAdd || isButtonExists) ? 'right-align-btn' : ''}`}>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={56}
        height={56}
        fill="none"
    >
        <circle
            cx={28}
            cy={28}
            r={28}
            fill="#F2BC47"
            transform="rotate(180 28 28)"
        />
        <path
            stroke="#5F5F5F"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M38.5 32 28 21.5 17.5 32"
        />
    </svg>
    </a>
)
export default UpButton
