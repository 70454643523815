import { ChangeEvent, useCallback, useEffect, useRef, useState } from "react";
import { Modal } from "../modal";
import Button from "../button";
import { useDropzone } from "react-dropzone";

interface Props {
  message?: string;
  setIsShow?: Function;
  closeButtonName?: string;
  title?: string;
  description?: string;
  closeModal?: Function;
  uploadPDF: Function;
}

const UploadPDFModal = ({
  title,
  message,
  setIsShow,
  closeModal,
  closeButtonName,
  description,
  uploadPDF,
}: Props) => {
  const inputRef = useRef<any>(null);
  const [file, setFile] = useState<any>(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const onUploadPDF = async (files: FileList | null) => {
    if (files && files.length > 0) {
      setLoading(true);
      const file = files[0];

      if (file?.type !== "application/pdf") {
        if (!error)
          setError("Your file type is not PDF! Please, upload only PDF file.");
        return;
      }

      setFile(file);

      await uploadPDF(file);
      setLoading(false);
      setIsShow && setIsShow();
      closeModal && closeModal();
    }
  };

  useEffect(() => {
    const handler = (e: any) => {
      e.stopPropagation();
      if (e.keyCode === 13 || e.keyCode === 27) {
        onClose(e);
      }
    };
    window.addEventListener("keydown", handler, true);

    return () => {
      window.removeEventListener("keydown", handler, true);
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const onClose = (e: any) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsShow && setIsShow();
    closeModal && closeModal();
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    onUploadPDF(acceptedFiles);
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div className={"mainContent-backDrop"}>
      <Modal setShowModal={onClose} innerConatinerClass="modal-agreement-pdf">
        <div {...getRootProps()} className="modal-dotted-border">
          <input
            ref={inputRef}
            {...getInputProps()}
            type="file"
            accept="application/pdf"
            onChange={(e: ChangeEvent<HTMLInputElement>) =>
              onUploadPDF(e.target.files)
            }
          />
          <div className="error-modal-header">
            <h6 className="app-modal-title agreement-title-text">
              {title || "Upload Agreement PDF"}
            </h6>
          </div>
          <div className="modal__body">
            <p className="agreement-drag-test">
              {description ||
                message ||
                "Drag a file here or select Choose File below to upload PDF."}
            </p>
            <div className="agreement-button-container">
              <Button
                onClick={(e: any) => {
                  // if(isData){
                  //     e.stopPropagation();
                  //     onClickCancel();
                  // }
                }}
                type="button"
                customClass="agreement-choose-file"
                loading={loading}
                loadingText="Uploading..."
                buttonName={"Choose File"}
              />
              {file && (
                <p
                  style={{
                    marginTop: 2,
                    color: "white",
                    fontSize: 14,
                    marginBottom: "0",
                    height: "21px",
                    overflow: "hidden",
                  }}
                >
                  {file?.name || ""}
                </p>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </div>
  );
};
export default UploadPDFModal;
