import { ReportContext } from "contexts/report";
import { useContext, useState } from "react";
import Dropdowns from "./dropdowns";
import { ReportActions } from "contexts/reducers/report";
import EventSales from "../eventSales";
import VenueName from "../venueName";
import SuperAdminReportsDropDown from "components/reports/superAdmin/DropDowns/SuperAdminReportsDropDown";
import Items from "../items";
import VendorReportHeader from "../VendorReportHeader";
import AllOrders from "../Orders";
import { Skeleton } from "components/common/skeleton";


const Dates = ({activeTab}: any) => {
    const { selectedVenue, reportDates, loading, startDate, endDate, fetchAllOrder, orderQueryParams, setOrderQueryParams } = useContext(ReportContext);

    const { events, events_sales } = reportDates;
    const isLoading = loading[ReportActions.GET_EVENT_DETAILS];
    const [ordersSaleOption, setOrdersaleOption] = useState('item_sales')

    const fetchOrders = async () => {
        setOrderQueryParams((prev: { limit: number; offset: number, sort_order: string }) => {
            return {
                ...prev,
                offset: (Math.ceil(prev.offset / prev.limit) + 1) * prev.limit,
            }
        });
        // console.log('orderQueryParams', orderQueryParams, Math.ceil(orderQueryParams.offset / orderQueryParams.limit) + 1);
        const res = await fetchAllOrder({
            venue_id: selectedVenue.id, 
            start_date: startDate, 
            end_date: endDate, 
            ...orderQueryParams, 
            offset: (Math.ceil(orderQueryParams.offset / orderQueryParams.limit) + 1) * orderQueryParams.limit,
        });

        return res;
    }


    const ordersSaleOptionChangeHandler = (e:React.ChangeEvent<HTMLSelectElement> )=>{
        setOrdersaleOption(e.target.value);
        setOrderQueryParams((prev: {offset: number, limit: number, sort_order: string}) => ({...prev, offset: 0}));
        if(e.target.value === 'orders')
        fetchAllOrder({venue_id: selectedVenue.id, start_date: startDate, end_date: endDate, ...orderQueryParams, offset:0})

    }

    return (
        <>
            <VenueName venueName={selectedVenue?.name || ''} />
            <div className="reports-container">
                <div className="action-dropdown-container">
                    <Dropdowns />
                </div>
                <div className="tabs"></div>
                <div className="events-other-details dates">
                    <div className="vendorAdmin-reports-header">
                        <div className="event-detail">
                            <div className="school-logo">
                        
                                {!selectedVenue ? <Skeleton width={70} animation= {false}/> : isLoading ? <Skeleton width={70} animation= {false}/> : 
                                events?.venue_logo ?  <img src={events?.venue_logo} alt="logo"/> : <></>
                                }
                                
                                {/* {!!selectedVenue  ? <img src={Logo} alt="logo"/>: <Skeleton width={70} animation= {false}/>} */}
                            </div>
                        </div>
                        <VendorReportHeader 
                            events = {events} 
                            event_sales = {events_sales}  
                            isLoading={isLoading}  
                            isDataFetched={!!selectedVenue}/>
                    </div>
                
                    <div>
                        <EventSales 
                            isLoading={isLoading}
                            event_sales={events_sales}
                            isRefund={events_sales?.refunds_count > 0 ? true: false}
                            isDataFetched={!!selectedVenue}
                        />

                    </div>
                    <div className="divider"></div>

                    <div className="item-sale-dropdown">  
                        {!!selectedVenue ?  
                        <SuperAdminReportsDropDown 
                        options={[ {label: 'Item Sales', value: 'item_sales'}, {label: 'Orders', value: 'orders'} ] } 
                        value={ordersSaleOption} onChange={ordersSaleOptionChangeHandler}  /> : <Skeleton width={200} animation = {false}/> }
                    </div>
                    {ordersSaleOption==='item_sales' ? <Items  items = {events_sales?.items} activeTab = {activeTab} isDataFetched={!!selectedVenue} />: <AllOrders fetchOrders={fetchOrders} activeTab={activeTab} />  }
                </div> 
            </div>
        </>
    )
}

export default Dates;