export const getWidget = (widgetColor: string, isPreview: boolean, qrCodeUrl: string, short_name: string) => {
    const marketingUrl = window.location.host === 'vendor.crowdchow.app' ? 'https://crowdchow.app' : "https://staging.crowdchow.app/";
    return getWidgetHtml(widgetColor, marketingUrl, isPreview, qrCodeUrl, short_name);
}


const getWidgetHtml = (color: string, marketingUrl: string, isPreview: boolean, qrcolorUrl: string, short_name: string) => {
    const qrUrl = qrcolorUrl;
    const hostName = window.location.host?.includes("localhost") ? `http://${window.location.host}` : `https://${window.location.host}`;
    const widgetHtml = `<div style="width: 100%; max-width: 243px; margin: 25px 0;">
        <style>
            @media (min-width: 577px) {
                #tap-me {display: none;}
                #body-spacing {padding-bottom: 30px !important;}
                #cc_ww_qr_code_mobile {display: none;}
                #cc_ww_qr_code_desktop {display: block;}
            }
            @media (max-width: 576px) {
                #cc_ww_qr_code_mobile {display: block;}
                #cc_ww_qr_code_desktop {display: none;}
            }
        </style>
        <link rel="preconnect" href="https://fonts.googleapis.com">
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
        <link href="https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800&display=swap" rel="stylesheet">
        <div style="padding: 8px 10px 4px; background-color: #fdc13d; border-radius: 8px 8px 0px 0px;">
            <h2 style="font-size: 16px; font-weight: 700; line-height: 18.78px; color: #231f20; margin: 0; font-family: 'Raleway', sans-serif; text-align: center; width: 100%; max-width: 100%;">
                ${short_name} Concession App
            </h2>
        </div>
        <div style="background-color: ${color}; padding: 9px 19px 30px 19px; overflow: hidden; border-radius: 0px 0px 8px 8px;">
            <p style="font-size: 15px; font-weight: 500; line-height: 18px; color: white; font-family: 'Raleway', sans-serif; margin: 0; margin-bottom: 15px; text-align: left; width: 100%; max-width: 100%;">
                Order concession food from your phone at the next ${short_name} event using 
                <a href='${marketingUrl}' target="_blank" style="font-weight: 700; color: white; text-decoration: none;">
                    Crowd Chow
                </a>.
            </p>
            <div style="width: 135px; height: 136px; margin: 0 auto; position: relative;">
                <div style="position: absolute; top: -21px; left: -54px;">
                    <img src="${hostName}/images/widget/burger.png" alt="Burger" style="width: auto; max-width: unset; height: auto;" />
                </div>
                <div id="cc_ww_qr_code_desktop">
                    <img src="${qrUrl}" alt="QR Code" style="width: 100%; max-width: unset; height: 100%;" />
                </div>
                <div id="cc_ww_qr_code_mobile" style="width: 100%; height: 100%;">
                    <a href="${qrUrl}" target="_blank">
                        <img src="${qrUrl}" alt="QR Code" style="width: 100%; max-width: unset; height: 100%;" />
                    </a>
                </div>
                <div style="position: absolute; right: -51px; bottom: -33px;">
                    <img src="${hostName}/images/widget/glass.png" alt="Glass" style="width: auto; max-width: unset; height: auto;" />
                </div>
            </div>
            <div id="tap-me" style="margin-top: 6px; text-align: center; padding-right: 4px; @media (min-width: 577px) {display: none;}">
                <img src="${hostName}/images/widget/tap-me.png" style="width: auto; max-width: unset; height: auto;" />
            </div>
        </div>
    </div>`

    return widgetHtml;
}