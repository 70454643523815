const RightArrow = ({onClick, disbaled}: {onClick: Function, disbaled: boolean}) => {
    return (
        <>
            {disbaled ? (
                <svg className="flip" onClick={() => !disbaled && onClick()} xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                    <g filter="url(#filter0_d_3149_1554)">
                        <path opacity="0.053" d="M0 14.5L25 0V29L0 14.5Z" fill="#D9D9D9"/>
                    </g>
                    <defs>
                        <filter id="filter0_d_3149_1554" x="0" y="0" width="27" height="31" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dx="2" dy="2"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3149_1554"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3149_1554" result="shape"/>
                        </filter>
                    </defs>
                </svg>
            ):(
                <svg className="cursor-pointer" onClick={() => onClick()} xmlns="http://www.w3.org/2000/svg" width="27" height="31" viewBox="0 0 27 31" fill="none">
                    <g filter="url(#filter0_d_3149_1552)">
                    <path d="M25 14.5L0 29V0L25 14.5Z" fill="#FDC13D"/>
                    </g>
                    <defs>
                    <filter id="filter0_d_3149_1552" x="0" y="0" width="27" height="31" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dx="2" dy="2"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3149_1552"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3149_1552" result="shape"/>
                    </filter>
                    </defs>
                </svg>
            )}
        </>
    )
};

export default RightArrow;
