import { FC, useEffect, useRef, useState } from "react";



interface Props {
    venueName: string
    isSuperReport?: boolean
}



const VenueName: FC<Props> = ({venueName, isSuperReport }) => {
    const vendorRef = useRef<any>(null);
    const [tooltip, setTooltip] = useState(false);

    useEffect(() => {
        if(venueName && vendorRef?.current){
            setTooltip(vendorRef?.current ? vendorRef?.current?.scrollWidth - vendorRef?.current?.clientWidth > 0 : false)
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[venueName, vendorRef?.current])

    return (
        <div className={`school-name-rotate ${tooltip ? 'show-full-name' : ''}`}>
            <p ref={vendorRef}>{venueName ? <><span> {isSuperReport && venueName.toLowerCase() !== "all vendors" ?  "Vendor:": isSuperReport && venueName.toLowerCase() === "all vendors" ? "" : "Venue:" }</span> {venueName}</> : ''}</p>
        </div>
        
    )
};

export default VenueName;
