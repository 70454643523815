interface Props {
    switchSkeleton?: boolean;
    customStyles?: any;
    inputSkeleton?: boolean;
    customClass?: string;
    color?: string;
    animation?: boolean;
    width?: string | number;
    height?: number;
}

const Skeleton = ({
    switchSkeleton, 
    customStyles,
    customClass,
    color,
    width,
    height,
    animation = true
}: Props) => {
    return (
        <div 
            style={{
                ...(customStyles && customStyles),
                ...(width && {width}),
                ...(height && {height}),
                ...(color && {color})
            }} 
            className={`data-skeleton ${animation ? 'skeleton-animation' : ''} ${switchSkeleton ? 'switch-skeleton' : ''} ${customClass || ''}`}
        >
        </div>
    )
};

export default Skeleton;
