import PromotionalCard from "../common/promotionalCard";
import Description from "../common/decription";
import SignagePreview from "../common/signagePreviewCard";
import SelectOptions from "../common/selectOption";
import Dropdown from "../common/dropdown";
import { useContext, useEffect, useRef, useState } from "react";
import { PromotionalResourcesContext } from "contexts/promotionalResources";
import ColorBox from "./colorBox";
import { getWidget } from "util/getWidgets";
import { generateQrCode } from "util/qrCodeHelpers";
import { uploadResource } from "services/common";
import HTMLModal from "./htmlModal";
import EditableActionsSingleInput from "./EditableActionsSingleInput";
import { Skeleton } from "components/common/skeleton";
import ApiModal from "components/common/apiErrorModal";

const Embeds = () => {
    const { widgetColor, setWidgetColor, signageQrUrl, venueList, embedVenue, setEmbedVenue, embedLandingPage, setEmbedLandingPage, updateQrUrl} = useContext(PromotionalResourcesContext);
    const {venues} = venueList;

    
    const [loading, setLoading] = useState(false);
    const [widgetHtml, setWidgetHtml] = useState('');
    const [qrCodeUrl, setQrCodeUrl] = useState('');
    const [showModal, setShowModal] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const shortNameRef = useRef<HTMLInputElement>(null);
    const [shortNameEqual, setShortNameEqual] = useState<boolean>(true);
    const [shortName, setShortName] = useState<string>("");
    const [error, setError] = useState('');
   







    const generateQrCodeImage = async (qrCodeUrl: string, qrCodeName: string) => {
        const qrCode = generateQrCode(qrCodeUrl, 170, 170);
        await qrCode.getRawData("png");
        const canvas = qrCode._canvas;
        const base64Image = canvas.toDataURL(`image/png`);
        const pngImageBytes = await fetch(base64Image).then((res) => res.arrayBuffer());
        const file = new Blob([pngImageBytes], { type: "image/png" });

        const res = await uploadResource({file, image_for: 'QrCode'});
        if(res?.url){
            return res.url;
        }

        return null;
    }

    const generateWidgetPreview = async (landingPageType: string, color: string, venueId: number, short_name: string) => {
        setLoading(true);
        let data = null;
        if(landingPageType === 'venue'){
            data = signageQrUrl?.venues?.find((item: any) => item.id === venueId);
        }else{
            data = signageQrUrl?.vendor
        }

       // const qrCodeName = `qr_code_${data?.short_code}`;
        let qrCodeUrl;
        const qrCodeName = `qr_code_${short_name}`;
        if(landingPageType==="vendor")
        {
            // eslint-disable-next-line no-useless-escape
        qrCodeUrl = await generateQrCodeImage(data?.url_one.replace(/\/[^\/]*$/, "/" + short_name.toLowerCase()) , qrCodeName);
        }else if(landingPageType==="venue")
        {
            // eslint-disable-next-line no-useless-escape
          qrCodeUrl = await generateQrCodeImage (data?.url_one.replace(/\/[^/]+$/, "/" + short_name.toLowerCase()), qrCodeName);
        }                                                                                                                             
      //  const qrCodeUrl = await generateQrCodeImage(landingPageType === "vendor" ? data?.url_one.replace(/\/[^\/]*$/, "/" + short_name.toLocaleLowerCase()): data?.url_one.replace(/\/[^/]+$/, "/" + short_name.toLocaleLowerCase()), qrCodeName);
       // const qrCodeUrl = await generateQrCodeImage(data?.url_one, qrCodeName);

        
        if(qrCodeUrl){
            const html = getWidget(color, true, qrCodeUrl, short_name.toUpperCase());
            setWidgetHtml(html);
            setQrCodeUrl(qrCodeUrl);
        }
         
        setLoading(false);
    }

    const onChangeLandingPageType = (e: any) => {
        setIsEdit(false);
       if(e.target.value === "venue" && embedVenue) {

        const venueData = signageQrUrl?.venues?.find((item: any) => item?.id === embedVenue);

        if(venueData?.color_code)
        {  
            setWidgetColor(venueData?.color_code)
            generateWidgetPreview(e.target.value, venueData?.color_code, embedVenue, venueData.short_code)     

        }
        else {
            setWidgetColor("#4285f4");
            generateWidgetPreview(e.target.value, "#4285f4", embedVenue, venueData.short_code)
        }
        } else {

            setWidgetColor("#4285f4");
            setWidgetHtml("");

        }
        
        if(e.target.value === "vendor")
        {
            const vendorData = signageQrUrl?.vendor;
            if(vendorData?.color_code){
                setWidgetColor(vendorData?.color_code);
                generateWidgetPreview(e.target.value, vendorData?.color_code, embedVenue, vendorData.short_code)
                

            }else{

                setWidgetColor("#4285f4");
                generateWidgetPreview(e.target.value, "#4285f4", embedVenue, vendorData.short_code)
            } 
        }
        
                setEmbedLandingPage(e.target.value);
        

    }

    const onChangeVenue = (e: any) => {
        setIsEdit(false);
    
    e.target.value && setEmbedVenue(parseInt(e.target.value));
    const venueData = signageQrUrl?.venues?.find((item: any) => item?.id.toString() === e.target.value);
  
    
    
     setWidgetColor(venueData?.color_code? venueData?.color_code: "#4285f4");
     e.target.value && generateWidgetPreview(embedLandingPage, venueData?.color_code? venueData?.color_code: "#4285f4", parseInt(e.target.value), venueData.short_code);
    }


    const onChangeColor = (color: string) => {
        
        setWidgetColor(color);

        if(embedLandingPage === "venue")
        {
        if(embedVenue){
        const html = getWidget(color, true, qrCodeUrl, shortName.toUpperCase());
        setWidgetHtml(html);
            }
        }
        else {
            const html = getWidget(color, true, qrCodeUrl, shortName.toUpperCase());
            setWidgetHtml(html);

        }
        

        
        if(embedLandingPage==="vendor")
        {
            const vendorData = signageQrUrl?.vendor;
            updateQrUrl(vendorData.id, {resource_type: "VENDOR",  color_code: color});
        }
        else {
            if(embedVenue){
                const venueData = signageQrUrl?.venues?.find((item: any) => item?.id === embedVenue);
                updateQrUrl(venueData.id, {resource_type: "VENUE", color_code: color});
            }
        }






    }

    const onClickViewHtml = () => {
        setShowModal(true);
    }



    const submitShortName = async ()=>{

        if(embedLandingPage === "vendor")
        {
            
            const updatedShortName = shortNameRef.current?.value;
            const vendorData = signageQrUrl?.vendor;
            const result = await updateQrUrl(vendorData.id, {resource_type: "VENDOR", short_code: updatedShortName?.toLowerCase(), color_code: widgetColor});

            if(result?.response?.data?.error){
                setError(result.response.data.message);
                setShortName(vendorData.short_code)
            }else
            {
          generateWidgetPreview(embedLandingPage, widgetColor, embedVenue, shortName);
          
            }

        }else 
        if(embedLandingPage=== "venue")
        {
            if(embedVenue)
            {
                
                const updatedShortName = shortNameRef.current?.value;
                const venueData = signageQrUrl?.venues?.find((item: any) => item?.id === embedVenue);
               const result = await updateQrUrl(venueData.id, {resource_type: "VENUE", short_code: updatedShortName, color_code: widgetColor});

               if(result?.response?.data?.error){
                setError(result.response.data.message);
                setShortName(venueData.short_code)
            }else{
            generateWidgetPreview(embedLandingPage, widgetColor, embedVenue, shortName);
            
            }

                

            }
        }


        setIsEdit(false);

    }


         const onClickEditShortName = ()=>{
            setIsEdit(true);

            if(embedLandingPage === "venue")
            {
            
            const venueData = signageQrUrl?.venues?.find((item: any) => item?.id === embedVenue);
            if(venueData?.short_code?.toUpperCase()===shortName?.toUpperCase()) 
            setShortNameEqual(true)
            }
            else
            {
            const vendorData = signageQrUrl?.vendor;
            if(vendorData?.short_code?.toUpperCase()=== shortName?.toUpperCase()) 
            setShortNameEqual(true)
            }
     

    }


        const onCross = () => {

        const venueData = signageQrUrl?.venues?.find((item: any) => item?.id === embedVenue);
        const vendorData = signageQrUrl?.vendor;

        if(embedLandingPage==="venue")
        {setShortName(venueData?.short_code)}
        else{
            setShortName(vendorData?.short_code)
        }


        setIsEdit(false);
        }

        const handlShortNameChange = (e:any)=>{
        setShortName(e.target.value);
        const venueData = signageQrUrl?.venues?.find((item: any) => item?.id === embedVenue);
        const vendorData = signageQrUrl?.vendor;
        // console.log(e.target.value, vendorData.short_code);

        if(embedLandingPage==="vendor")
        {
            if(e.target.value.toUpperCase()=== vendorData?.short_code.toUpperCase())
            {
                setShortNameEqual(true);
            }else
            {
                setShortNameEqual(false);
            }
        }

        if(embedLandingPage==="venue"){
           if(e.target.value.toUpperCase() === venueData?.short_code.toUpperCase())
           {
            setShortNameEqual(true);
           }else {
            setShortNameEqual(false);
           }
        }

        }
      
      
        useEffect(()=>{
            if(embedLandingPage === "venue")
            {
            const venueData = signageQrUrl?.venues?.find((item: any) => item?.id === embedVenue);
            
            setShortName(venueData?.short_code)
            }
            else
            {
            const vendorData = signageQrUrl?.vendor;
            console.log(vendorData?.short_code);
            setShortName(vendorData?.short_code)
            
            }
    
            
            /* eslint-disable react-hooks/exhaustive-deps */
    
        }, [embedLandingPage, embedVenue])


      


       

        
    const isVendor = embedLandingPage === 'vendor';
    const isDisabled = isVendor ? !embedLandingPage : !embedLandingPage || !embedVenue;
    
    
        
  console.log(shortName);

    return (
        <>
            <div className="signAge">
                <Description
                    title="Website Embed"
                    description="You have completed the onboarding checklist. Your venue maps and menu will be 
                    reviewed by the Crowd Chow team. Once we confirm everything looks good, you will receive an email with."
                />
                <PromotionalCard
                    isEmbeds
                >
                    <SelectOptions
                        heading="Select Options"
                        isButtonDisabled={isDisabled}
                        isButtonLoading={loading}
                        buttonName="View HTML"
                        onClickButton={onClickViewHtml}
                    >
                        <Dropdown
                            value={embedLandingPage}
                            placeholder="Select Sign Type"
                            options={[{label: 'Venue Landing Page', value: 'venue'},{label: 'Vendor Landing Page', value: 'vendor'}]}
                            onChange={onChangeLandingPageType}
                        />

                        <Dropdown
                            value={embedVenue}
                            placeholder={isVendor ? null! : "Select Venue"}
                            options={ isVendor ? [{label: "", value: null!}] :  venues.map((venue: any) => ({label: venue.name, value: venue.id}))}
                            onChange={onChangeVenue}
                            disabled={isVendor}
                        />
                        <div className="short-name">
                            <p className="short-name-label">Short Name:</p>
                            <div className="venue-short-name">
                              
                         
                         {/* {!isEdit && (embedLandingPage === "venue" ? venueData?.short_code.toUpperCase() || '' : vendorData.short_code.toUpperCase())} */}
                         
                         {!isEdit && shortName?.toUpperCase()}
                        
                         {isEdit && <input 
                         
                         type="text"
                         ref={shortNameRef}
                         value={shortName.toUpperCase()}
                         onChange={handlShortNameChange}
                         maxLength={8}
                     
                         />}      
                               
                        
                        
                        {embedVenue || embedLandingPage==="vendor" ? 
                        <EditableActionsSingleInput
                          isEditMode={isEdit}
                          onClickCross={onCross}
                          onClickEdit={onClickEditShortName}
                          activeDisabled={isDisabled}
                          onClickSubmit={submitShortName}
                        //   disabled={updateLoading}
                           disableSubmit={shortNameEqual}
                         
                        
                        /> : null}
                            </div>


                     
                        </div>
                        <div className="qr-palet-color">
                            <p className="palet-label">Color:</p>
                            <div className="color-palet">
                                <ColorBox setWidgetColor={onChangeColor} color="#4285f4" />
                                <ColorBox setWidgetColor={onChangeColor} color="#fd2b13" />
                                <ColorBox setWidgetColor={onChangeColor} color="#f5207b" />
                                <ColorBox setWidgetColor={onChangeColor} color="#25cad3" />
                                <ColorBox setWidgetColor={onChangeColor} color="#f36701" />
                                <ColorBox setWidgetColor={onChangeColor} color="#01673E" />
                            </div>
                        </div>
                    </SelectOptions>

                   
                    <SignagePreview>
                    {loading ? <Skeleton height={280} width={240}/> :
                        widgetHtml ? (

                            <div dangerouslySetInnerHTML={{__html: widgetHtml}} />
                        ):(
                            <p className="no-preview-example">No Preview</p>
                        )}
                        
                    </SignagePreview>

                </PromotionalCard>
            </div>

            {showModal && widgetHtml && (
                <HTMLModal 
                    closeModal={() => setShowModal(false)}
                    html={widgetHtml}
                />
            )}

        {error && (
                <ApiModal 
                    setIsShow={() => setError('')}
                    title="Short Name Update Failed"
                    message={error}
                />
            )}
        </>
    )
};

export default Embeds;
