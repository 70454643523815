import { ResourcesContextProvider } from "interfaces/promotionalResources";


export enum ResourcesActions {
    GET_VENUES = 'GET_VENUES',
    RESET_VENUE_LOADING = 'RESET_VENUE_LOADING',
    SET_VENUE_LOADING = 'SET_VENUE_LOADING',
    GET_VENUE_DETAILS = 'GET_VENUE_DETAILS',
    GET_QR_URL = 'GET_QR_URL',
    SET_EMBED_QR_URL = 'SET_EMBED_QR_URL'
}

export const PromotionalResourcesReducer = (state: ResourcesContextProvider, action: any) => {

    const { type, payload } = action

    switch (type) {

     
        case ResourcesActions.SET_VENUE_LOADING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [payload]: true
                }
            }
        case ResourcesActions.RESET_VENUE_LOADING:
            const newLoadingState = {
                ...state.loading
            }
            delete newLoadingState[payload];
            return {
                ...state,
                loading: newLoadingState
            }
        case ResourcesActions.GET_VENUES:
            return {
                ...state,
                venueList: payload
            }

        case ResourcesActions.GET_QR_URL: 
            return {
                ...state, 
                signageQrUrl: payload
            }
   
        case ResourcesActions.SET_EMBED_QR_URL: 
            return {
                ...state, 
                embedQrUrl: payload
            }
        default:
            return state


    }

}