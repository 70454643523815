import { useContext } from "react";
import TableSkeleton from "components/common/skeleton/tableSkeleton";
import NoData from "components/noData";
import { ReportActions } from "contexts/reducers/report";
import { ReportContext } from "contexts/report";
import { Table, TableBody, TableHead, TableHeading } from "components/common/table";
import Item from "./item";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import MoreDataSkeleton from "components/common/moreDataSkeleton";

const RefundItems = ({page, fetchMoreData}:any) => {
    const {refundOrders, loading} = useContext(ReportContext);
    const {_items, _items_count} = refundOrders;

    const {handleScroll, scrollTop} = useInfiniteScroll(fetchMoreData)

    const getRefundOrdersLoading = loading[ReportActions.SET_REFUND_ORDER];
    return (
        <>
            <div className="refund-table">
                <Table>
                    <TableHead>
                        <TableHeading customClass="order-number">
                            Order Number
                        </TableHeading>
                        <TableHeading customClass="customer-name">
                            Item Name
                        </TableHeading>
                        <TableHeading customClass="customer-name">
                            Customer
                        </TableHeading>
                        <TableHeading customClass="refund-reason">
                            Refund Reason
                        </TableHeading>
                    </TableHead>

                    <TableBody
                        onScroll={(e: any) => handleScroll(e, _items.length < _items_count)}
                        isShowBlur={_items_count > 10 && scrollTop < 10}
                    >
                        {getRefundOrdersLoading ? (
                            <TableSkeleton count={10} />
                        ) : (
                            _items?.length > 0 ? (
                                _items.map((item: any) => {
                                    return (
                                        <Item data={item} />
                                    )
                                })
                            ):(
                                <></>
                            )
                        )}
                        <MoreDataSkeleton
                            isShow={page > 0 && loading[ReportActions.SET_MORE_REFUND_ORDER]}
                        />
                    </TableBody>
                </Table>
            </div>
            {!getRefundOrdersLoading && _items?.length === 0 ? (
                <NoData description="No Items" />
            ):(
                <></>
            )}
        </>
    )
};

export default RefundItems;
