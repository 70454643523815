import { TabPanel, Tabs } from "components/common/tabs";
import { useContext } from "react";
import SuperAdmins from "./superAdmins";
import CrowdSection from "./crowds";
import { UserContext } from "contexts/user";
import { AuthContext } from "contexts/auth";
import Cookies from "js-cookie";
import { useIsSuperAdmin } from "hooks/useIsSuperAdmin";
import Staff from "./staff";
import Admins from "./admins";

const UserSection = () => {
    const { setActiveTab, activeTab, adminList } = useContext(UserContext);
    const { user } = useContext(AuthContext);

    const isSuperAdmin = useIsSuperAdmin();

    const vendorId = user?.vendor_id || Number(Cookies.get("vendor_id"));
    const tabsList = isSuperAdmin ? ['Super', 'Vendor', 'Crowd'] : ['Admins', 'Staff', 'Crowd'];

    const { _customersCount, _staffCount, _count } = adminList;
    return (
        <div className="users">
            <div className="users__container">
                <Tabs
                    tabs={tabsList}
                    activeTab={activeTab}
                    onClickTab={setActiveTab}
                    tabLabels={isSuperAdmin ? null : {
                        0: _count,
                        1: _staffCount,
                        2: _customersCount
                    }}
                >
                    <TabPanel
                        selected={activeTab === 0}
                    >
                        {isSuperAdmin ? <SuperAdmins vendor_id={isSuperAdmin ? null : vendorId} /> : <Admins vendorId={vendorId} />}
                    </TabPanel>
                    <TabPanel
                        selected={activeTab === 1}
                    >
                        {/* isue was that instead of admin, the vendors component was rendered here in case of super admin  */}
                        {isSuperAdmin ? <Admins /> : <Staff vendor_id={vendorId} />}
                    </TabPanel>
                    <TabPanel
                        selected={activeTab === 2}
                    >
                        <CrowdSection vendor_id={isSuperAdmin ? null : vendorId} />
                    </TabPanel>
                </Tabs>
            </div>
        </div>
    )
};

export default UserSection;
