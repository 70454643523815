import { ReportContext } from "contexts/report";
import React, { useContext, useState } from "react";
import Dropdowns from "./dropdowns";
import { ReportActions } from "contexts/reducers/report";
import SeeAllEvents from "./seeAll";
import EventSales from "../eventSales";
import timezone from 'moment-timezone'
import VenueName from "../venueName";
import RefreshIcon from "components/common/refreshIcon";
import SuperAdminReportsDropDown from "components/reports/superAdmin/DropDowns/SuperAdminReportsDropDown";
import VendorReportHeader from "../VendorReportHeader";
import Items from "../items";
import AllOrders from "../Orders";
import { Skeleton } from "components/common/skeleton";
import moment from "moment";

const Events = ({activeTab}: any) => {
    const { eventDetails, setOrderQueryParams, selectedVenue, loading, seeAll, setSeeAll, fetchEventSales, setSelectedEvent, selectedEvent, orderQueryParams, fetchAllOrder } = useContext(ReportContext);

    const { event } = eventDetails;
    const {items} = eventDetails;
    
    const isLoading = loading[ReportActions.GET_EVENT_SALES];

    
    const [ordersSaleOption, setOrdersaleOption] = useState('item_sales')

    const onClickEvent = async (event: any) => {
        setSelectedEvent(event);
        await fetchEventSales({event_id: event?.id, timezone: timezone.tz.guess()});
        setOrderQueryParams((prev: {offset: number, limit: number, sort_order: string}) => ({...prev, offset: 0}));
        await fetchAllOrder({event_id: event?.id, venue_id: selectedVenue?.id, ...orderQueryParams, offset: 0})

        setSeeAll(false);
    }

    const onClickRefreshIcon = async () => {

        if(selectedEvent){
            fetchEventSales({event_id: selectedEvent?.id, timezone: timezone.tz.guess()})
        }
    }

    const fetchOrders = async () => {
        setOrderQueryParams((prev: {offset: number, limit: number, sort_order: string}) => ({...prev, offset: (Math.ceil(prev.offset / prev.limit) + 1) * prev.limit}));
        const res = await fetchAllOrder({
            event_id: selectedEvent.id, 
            venue_id: selectedVenue.id, 
            ...orderQueryParams,
            offset: (Math.ceil(orderQueryParams.offset / orderQueryParams.limit) + 1) * orderQueryParams.limit,
        })
        return res;
    }

    const ordersSaleOptionChangeHandler = (e:React.ChangeEvent<HTMLSelectElement> )=>{
        setOrdersaleOption(e.target.value);
        setOrderQueryParams((prev: {offset: number, limit: number, sort_order: string}) => ({...prev, offset: 0}));
        if(e.target.value === 'orders') 
        fetchAllOrder({event_id: selectedEvent.id, venue_id: selectedVenue.id, ...orderQueryParams, offset:0})



    }

    const sTime = moment(`${event?.date} ${event?.start_time}`).format("h:mm A");
    const eTime = moment(`${event?.date} ${event?.end_time}`).format("h:mm A");

    const formttedDate = moment(event?.date).calendar(null, {
      // when the date is closer, specify custom values
      lastWeek: "M/D/YY",
      lastDay: "[Yesterday]",
      sameDay: "[Today]",
      nextDay: "[Tomorrow]",
      nextWeek: "M/D/YY",
      sameElse: "M/D/YY"
    });

    return (
        <>
            {seeAll ? (
                <SeeAllEvents setSeeAll = {setSeeAll}  onClickEvent={onClickEvent} />
            ): (
                <>
                    <VenueName venueName={selectedVenue?.name || ''} />
                    <div className="reports-container">
                        <div className="action-dropdown-container">
                            <Dropdowns />
                            {event?.is_live && (
                                <div className="refresh-icon">
                                    <RefreshIcon onClick={onClickRefreshIcon} />
                                </div>
                            )}
                        </div>
                        <div className="events-other-details">
                        <div className="vendorAdmin-reports-header">
                            <div className="event-detail">
                                <div className="school-logo">

                                {!selectedEvent ? <Skeleton width={70} height={100} animation = {false}/> : isLoading ?  
                                <Skeleton width={70} height={100}  />:
                                
                                <img src={event?.venue_logo} alt="logo"/>
                                  
                                  } 
                                
                                </div>
                                <div className="event-name-date">
                                    {!selectedEvent ?  <Skeleton customClass="live-event-label-skeleton" animation = {false}/> : 
                                    isLoading ? <Skeleton customClass="live-event-label-skeleton"/> :
                                    event?.is_live ? <p className="live-event">Live Event</p> : <></>
}
                                   
                                    {!selectedEvent ? <Skeleton customClass="school-name-skeleton" animation = {false}/> : 
                                    isLoading ? <Skeleton customClass="school-name-skeleton" animation = {false}/>:    
                                    <p className="school-name">{event?.name}</p> } 

                                    
                                  
                                    {!selectedEvent ? <Skeleton customClass="day-time-skeleton" animation = {false}/> : 
                                    isLoading ? <Skeleton customClass="day-time-skeleton" />  :
                                     <p className="event-date">{formttedDate} {sTime} - {eTime}</p> }
                                </div>
                            </div>
                            <VendorReportHeader 
                                events = {event} 
                                event_sales = {eventDetails.event_sales}  
                                isLoading={isLoading}  
                                isDataFetched={!!selectedEvent}
                                activeTab = {activeTab}
                                items = {eventDetails.items}   
                            />
                   
                </div>
                
                <div>
                <EventSales 
                        isLoading={isLoading}
                        event_sales={eventDetails.event_sales}
                        isRefund={eventDetails?.event_sales?.refunds_count > 0 ? true : false}
                        isDataFetched={!!selectedEvent}
                    />

                </div>


                
                
                <div className="divider"></div>
                        





                        <div className="item-sale-dropdown">    
                        {!!selectedEvent ? 
                        <SuperAdminReportsDropDown 
                        options={[ {label: 'Item Sales', value: 'item_sales'},{label: 'Orders', value: 'orders'}] } 
                        value={ordersSaleOption} onChange={ordersSaleOptionChangeHandler}  /> : <Skeleton width={200} animation = {false}/> }
                        </div>

                    {ordersSaleOption==='item_sales' ? <Items items = {items} activeTab = {activeTab} isDataFetched = {!!selectedEvent}/>: <AllOrders fetchOrders={fetchOrders} activeTab = {activeTab} />}
                        
                          
                        </div>
                    </div>
                </>
            )}
        </>
    )
}

export default Events;