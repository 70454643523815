import { useState } from "react";
import { selectText } from "util/select";

let timeoutId: any = null;

export const useCopyToClipboard = () => {
    const [isCopied, setisCopied] = useState(false);

    const onClickCopy = async (htmlElement: any, html: string) => {
        selectText(htmlElement);
        await navigator.clipboard.writeText(html);
        setCopyToClipboardTooltip();
    }

    const setCopyToClipboardTooltip = () => {
        setisCopied(true);

        if(timeoutId){
            clearTimeout(timeoutId);
        }

        timeoutId = setTimeout(() => {
            setisCopied(false);
        },1500);
    }

    return {
        onClickCopy,
        isCopied
    }
}