import { AuthContextProvider } from "interfaces/auth"

export enum AuthActions {
    SET_USER = 'SET_USER',
    SET_AUTH_LOADING = 'SET_AUTH_LOADING',
    RESET_AUTH_LOADING = 'RESET_AUTH_LOADING',
    RESET_PASSWORD = 'RESET_PASSWORD',
    GET_IMPERSONATION_TOKEN = 'GET_IMPERSONATION_TOKEN',
    UPDATE_PASSWORD = 'UPDATE_PASSWORD',
    SET_ONBOARDING_DETAILS = 'SET_ONBOARDING_DETAILS'
}


export const AuthReducer = (state: AuthContextProvider, action: any) => {
    const { type, payload } = action
    switch (type) {
        case AuthActions.SET_USER:
            return {
                ...state,
                user: payload
            }
        case AuthActions.SET_AUTH_LOADING:
            return {
                ...state,
                loading: [...state.loading, payload]
            }
        case AuthActions.RESET_AUTH_LOADING:
            return {
                ...state,
                loading: state.loading.filter((item) => item !== payload)
            }
        case AuthActions.SET_ONBOARDING_DETAILS:
            return {
                ...state,
                onboardingDetails: payload
            }
        default:
            return state
    }
}
