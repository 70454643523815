import AddVendorModal from "components/vendors/vendorsCore/addModal";
import { VendorActions } from "contexts/reducers/vendor";
import { VendorContext } from "contexts/vendor";
import { useContext, useEffect, useRef, useState } from "react";
import moment from "moment";
import { LeadsStatus, VendorStatus } from "interfaces/vendor";
import { useNavigate } from "react-router-dom";
import InfoModal from "components/common/infoModal";
import NoteIcon from "assets/images/notesIcon.png";
import AddNotesModal from "./addNotesModal";
import NoteModal from "./noteModal";
import { useLeadTooltip } from "hooks/useLeadTooltip";
import Note from "./note";
import SendSalesKitDropdown from "./salesKitDropdown";
import Button from "components/common/button";

export const vendorStatusMapper = {
  ACTIVE: VendorStatus.Active,
  CREATED: VendorStatus.Created,
  NEED_REVIEW: VendorStatus.Needs_Review,
  WELCOME: VendorStatus.Welcome,
  VP_ONBOARDING: VendorStatus.Vp_Onboarding,
  LAPSED: VendorStatus.Lapsed,
};

const AccordianBody = ({ data, onSendSalesKit }: any) => {
  const {
    fetchLeads,
    queryParams,
    addVendor,
    loading,
    sendSalesLead,
    addLeadNote,
    setLead,
  } = useContext(VendorContext);

  const [tooltips, setTooltips] = useState({
    name: false,
    state: false,
    city: false,
    email: false,
    where_heard: false,
    role: false,
    rep: false,
    lastSent: false,
  });
  const [showModal, setShowModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [vendorId, setVendorId] = useState(null);
  const [showAddNotesModal, setShowAddNotesModal] = useState(false);
  const [active, setActive] = useState(false);
  const [selectedNote, setSelectedNote] = useState<any>(null);

  const navigate = useNavigate();
  const dropdownRef = useRef<any>(null);
  const cityRef = useRef<any>(null);
  const stateRef = useRef<any>(null);
  const nameRef = useRef<any>(null);
  const roleRef = useRef<any>(null);
  const emailRef = useRef<any>(null);
  const whereHearedRef = useRef<any>(null);
  const lastSentRef = useRef<any>(null);
  const repRef = useRef<any>(null);

  const {
    id,
    name,
    state,
    city,
    email,
    where_heard,
    role,
    sales_kit,
    vendor_id,
    status,
    vendor,
    notes,
  } = data || {};

  const isSendingSalesKit = loading[VendorActions.SEND_SALES_LEAD];

  const onSelectOption = async (val: string) => {
    if (isSendingSalesKit) {
      return;
    }
    if (val === "Create Vendor") {
      setShowModal(true);
    } else if (val === "Go to Vendor" || val === "View Vendor") {
      vendor_id && navigate(`/vendors/${vendor_id}`);
    } else {
      await sendSalesLead(data);
      const { statusList, ...restParams } = queryParams;
      fetchLeads(restParams);
    }

    setShowDropdown(false);
  };

  const onClickAddNotes = () => {
    setShowAddNotesModal(true);
  };

  const onClickNote = (note: any) => {
    setSelectedNote({ ...note, lead_id: id });
  };

  useEffect(() => {
    const handler = (e: any) => {
      if (dropdownRef.current?.contains(e.target)) {
        return;
      }
      setShowDropdown(false);
    };

    if (showDropdown) {
      window.addEventListener("click", handler, false);
    }

    return () => {
      window.removeEventListener("click", handler, false);
    };
  }, [showDropdown]);

  useLeadTooltip(
    setTooltips,
    {
      cityRef,
      stateRef,
      nameRef,
      emailRef,
      whereHearedRef,
      roleRef,
      lastSentRef,
      repRef,
    },
    data
  );

  const isConverted = status === LeadsStatus.CONVERTED;

  const vendorOption = vendor_id
    ? isConverted &&
      ((vendor && vendor.status === "NOT_YET") || vendor?.status === "LAPSED")
      ? "Go to Vendor"
      : "View Vendor"
    : "Create Vendor";

  return (
    <>
      <div className="accordian-content">
        <div className="accordian-body">
          <div className="city-status">
            <div className="vendor-detail-group">
              <label className="vendor-group-label">City</label>
              <div className="vendor-detial-text-group">
                <p ref={cityRef} className="vendor-detial-text">
                  {city}
                </p>
                {tooltips.city && <span className="tooltip">{city}</span>}
              </div>
            </div>
            <div className="vendor-detail-group">
              <label className="vendor-group-label">State</label>
              <div className="vendor-detial-text-group">
                <p ref={stateRef} className="vendor-detial-text">
                  {state}
                </p>
                {tooltips.state && <span className="tooltip">{state}</span>}
              </div>
            </div>
          </div>
          <div className="name-emailAddress">
            <div className="vendor-detail-group">
              <label className="vendor-group-label">Full Name</label>
              <div className="vendor-detial-text-group">
                <p ref={nameRef} className="vendor-detial-text">
                  {name}
                </p>
                {tooltips.name && <span className="tooltip">{name}</span>}
              </div>
            </div>
            <div className="vendor-detail-group">
              <label className="vendor-group-label">Email</label>
              <div className="vendor-detial-text-group">
                <p ref={emailRef} className="vendor-detial-text">
                  {email}
                </p>
                {tooltips.email && <span className="tooltip">{email}</span>}
              </div>
            </div>
          </div>
          <div className="heard-role">
            <div className="vendor-detail-group">
              <label className="vendor-group-label">How They Heard</label>
              <div className="vendor-detial-text-group">
                <p ref={whereHearedRef} className="vendor-detial-text">
                  {where_heard}
                </p>
                {tooltips.where_heard && (
                  <span className="tooltip">{where_heard}</span>
                )}
              </div>
            </div>
            <div className="vendor-detail-group">
              <label className="vendor-group-label">Role</label>
              <div className="vendor-detial-text-group">
                <p ref={roleRef} className="vendor-detial-text">
                  {role}
                </p>
                {tooltips.role && <span className="tooltip">{role}</span>}
              </div>
            </div>
          </div>
          <div className="notes">
            <div className="vendor-detail-group">
              <div
                onClick={() => setActive((prev) => !prev)}
                className={`cursor-pointer notes-icon ${
                  active ? "active" : ""
                }`}
              >
                <img src={NoteIcon} alt="Note Icon" />
                {!active && (
                  <div className="edit-number">{notes?.length || ""}</div>
                )}
              </div>
            </div>
            {active && (
              <div className="cursor-pointer vendor-detail-group">
                <div onClick={onClickAddNotes} className={`add-btn`}>
                  <div className="plus">
                    <span></span>
                    <span></span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            style={{ alignItems: "normal" }}
            className={`${active ? "notes-box" : "sales-kit"}`}
          >
            {active ? (
              <>
                {notes.map((item: any) => {
                  return (
                    <Note
                      data={{ ...item, name_initials: item?.admin_name }}
                      onClickNote={onClickNote}
                    />
                  );
                })}

                {[...new Array(notes.length < 3 ? 3 - notes.length : 0)].map(
                  (_: any, index: number) => {
                    return (
                      <div key={index} className="note skeleton">
                        <div className="subject-line-name"></div>
                        <div className="subject-note-detail">
                          <div className="note-date"></div>
                          <div className="group-name"></div>
                        </div>
                      </div>
                    );
                  }
                )}
              </>
            ) : (
              <>
                <div className="last-sent">
                  <div>
                    <div className="vendor-detail-group">
                      <div>
                        <label className="vendor-group-label">
                          {isConverted ? "Conversion Date" : "Last Sent"}
                        </label>
                        <div className="vendor-detial-text-group">
                          <p className="vendor-detial-text">
                            {isConverted
                              ? vendor?.conversion_date
                                ? moment(vendor?.conversion_date).format(
                                    "M/D/YY hh:mm A"
                                  )
                                : "Not Yet"
                              : sales_kit?.last_sent
                              ? moment(sales_kit?.last_sent).format("M/D/YY")
                              : "Not Yet"}
                          </p>
                          {tooltips.lastSent && (
                            <span className="tooltip">
                              {moment(
                                sales_kit?.last_sent
                                  ? sales_kit?.last_sent
                                  : vendor?.conversion_date
                              ).format(
                                isConverted ? "M/D/YY hh:mm A" : "M/D/YY"
                              )}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="vendor-detail-group">
                    <label className="vendor-group-label">
                      {sales_kit?.sent_by ? "Sent By" : "Rep"}
                    </label>
                    <div className="vendor-detial-text-group">
                      <p className="vendor-detial-text">
                        {sales_kit?.sent_by || vendor?.rep || "Not Yet"}
                      </p>
                      {tooltips.rep && (
                        <span className="tooltip">
                          {sales_kit?.sent_by || vendor?.rep}
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div>
                  {isConverted && (
                    <div
                      style={{ marginLeft: 5 }}
                      className="vendor-detail-group"
                    >
                      <label className="vendor-group-label">
                        Subscription Status
                      </label>
                      <div className="vendor-detial-text-group">
                        <p className="vendor-detial-text">
                          {vendorStatusMapper[
                            (vendor?.status ||
                              "") as keyof typeof vendorStatusMapper
                          ] || "Not Yet"}
                        </p>
                      </div>
                    </div>
                  )}
                  <div className="send-kit-btn">
                    <div ref={dropdownRef} className="action-dropDown">
                      {vendor?.status === "ACTIVE" ? (
                        <Button
                          buttonName="Go to Vendor"
                          onClick={() =>
                            vendor_id && navigate(`/vendors/${vendor_id}`)
                          }
                          customStyles={{
                            textAlign: "center",
                          }}
                          dropShadow={false}
                        />
                      ) : (
                        <SendSalesKitDropdown
                          onSelectOption={onSelectOption}
                          vendorOption={vendorOption}
                          isSendingSalesKit={isSendingSalesKit}
                          showDropdown={showDropdown}
                          setShowDropdown={setShowDropdown}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
      {showModal && (
        <AddVendorModal
          closeModal={() => setShowModal(false)}
          addVendor={addVendor}
          loading={loading[VendorActions.ADD_VENDOR]}
          leadId={id}
          onAdd={(res: any) => {
            if (res?.data?.vendor?.id) {
              setLead({
                ...data,
                vendor_id: res?.data?.vendor?.id,
                status: LeadsStatus.VENDOR_CREATED,
              });
              setVendorId(res?.data?.vendor?.id);
              setShowModal(false);
            }
          }}
          data={data}
          isLead
        />
      )}

      {!!vendorId && (
        <InfoModal
          title="Vendor Created"
          description="New vendor is created successfully."
          submitButtonName="Go to Vendor"
          onSubmit={() => {
            navigate(`/vendors/${vendorId}`);
            setVendorId(null);
          }}
          closeModal={() => setVendorId(null)}
          isCancel
        />
      )}

      {showAddNotesModal && (
        <AddNotesModal
          closeModal={() => setShowAddNotesModal(false)}
          addNote={(data: any) => addLeadNote({ ...data, lead_id: id })}
          isLoading={loading[VendorActions.ADD_NOTE_LEAD]}
        />
      )}

      {selectedNote && (
        <NoteModal
          closeModal={() => setSelectedNote(null)}
          data={selectedNote}
        />
      )}
    </>
  );
};

export default AccordianBody;
