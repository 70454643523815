export const enum VendorBillingStatuses {
    NOT_YET = 'NOT_YET', // NOT USED ANY MORE. WILL BE REMOVED SOON.
    NEW = 'NEW',
    UNPAID = 'UNPAID',
    PAID = 'PAID',
    LATE = 'LATE',
    LAPSED = 'LAPSED',
    COMPED = 'COMPED',
    PROCESSING = 'PROCESSING'
}
