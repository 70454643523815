export interface OnboardingContextProvider {
    loading: string[];
    vendorOnboardingDetails: VendorDetails;
    acceptAgreement: Function;
    markBillingPaid: Function;
    markStripeConnected: Function;
    setLoading: Function;
    resetLoading: Function;
    fetchonboardingDetails: Function;
    selectedStep: string;
    setSelectedStep: Function;
    addVenues: Function;
    getImageLibrary: Function;
    fetchLibraryResource: Function;
    foodImageLibrary: any;
    menu: any;
    addCSVMenu: Function;
    emailAgreement: Function;
    setOnboardingDetails: Function;
}


export interface VendorDetails {
    onboarding_pending: boolean;
    onboarding_step: string;
    agreement: string;
    agreement_accepted: boolean;
    connected_account_stripe: VendorConnectedStripeAccount
    venue_details: any;
    app_setup: any;
    vendor: any;
    training_videos: any[];
    flyers: any[],
    venues: any;
    venues_allowed: number;
    billing: any;
    menu_completed: boolean;
    venue_completed: boolean;
    onboarding_via_email: boolean;


}

export interface VendorConnectedStripeAccount {
    connected: boolean;
    email: string
}

export interface Venue {
    name: string;
    address: string;
    state: string;
    zipcode: string;
    phone: string;
    city: string;
    latitude: number;
    longitude: number;
    venue_type: string;
    concession_pickup: boolean;
    chow_stations: boolean;
    seat_delivery: boolean;
    map_url: string;
}

export interface PostVenueRequest {
    venues: Venue[];
    name: string;
    address: string;
    zipcode: string;
    phone: string;
    customer_facing_email: string;
    customer_facing_phone: string;
    vendor_id: number;
}

export enum CHECKLIST {
    Agreement = 'Agreement',
    Billing = 'Billing',
    Connect_Stripe_Account = 'Connect Stripe Account',
    Venues = 'Venue Setup',
    Menu = 'Menu',
    Submit_For_Review = 'Submit for Review'
}

export enum ImageFor {
    Item = 'ITEM',
    Option = 'OPTION',
    Venue = 'VENUE',
    Vendor = 'VENDOR',
    Variant = 'VARIANT',
    Map = 'MAP'
}

export interface LibraryResourceRequest {
    offset?: number;
    limit?: number;
}

export interface ImageLibraryRequest extends LibraryResourceRequest{
    web_only: boolean;
}

export interface ResourceRequest extends LibraryResourceRequest{
    web_only?: boolean;
}

export interface MenuRequest {
    file: File;
    data?: any;
    confirm?: 'false' | 'true';
}

export const checkListMapper = {
    'AGREEMENT': CHECKLIST.Agreement,
    'BILLING': CHECKLIST.Billing,
    'CONNECT_STRIPE_ACCOUNT': CHECKLIST.Connect_Stripe_Account,
    'VENUE_SETUP': CHECKLIST.Venues,
    'MENU': CHECKLIST.Menu,
    'SUBMIT_REVIEW': CHECKLIST.Submit_For_Review
}