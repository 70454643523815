import React, { useState } from "react"

const Variants = ({variant_1_price, variant_2_price, variant_3_price, price, name, description, variant_1_name, variant_2_name, variant_3_name}: any) => {

    const [selectedVariant, setSelectedVariant] = useState(1);

    const onClickVariant = (variant: number) => {
        setSelectedVariant(variant);
    }

    const isAnyVariant = variant_1_name || variant_2_name || variant_3_name;
    const currentPrice = isAnyVariant 
    ? ( selectedVariant === 1 ? variant_1_price : selectedVariant === 2 ? variant_2_price : selectedVariant === 3 ? variant_3_price : '') 
    : price;
    
    
    return (
        <>
            <div className="food-name">
                <div>
                    <p className="name">{name || ''}</p>
                    <p className="food-price">{currentPrice || ''}</p>
                </div>
                <div className='oz-size'>
                    {variant_1_name && (
                        <span onClick={() => onClickVariant(1)} className={`${selectedVariant === 1 ? 'active' : ''}`}>{variant_1_name}</span>
                    )}
                    {variant_2_name && (
                        <span onClick={() => onClickVariant(2)} className={`${selectedVariant === 2 ? 'active' : ''}`}>{variant_2_name}</span>
                    )}
                    {variant_3_name && (
                        <span onClick={() => onClickVariant(3)} className={`${selectedVariant === 3 ? 'active' : ''}`}>{variant_3_name}</span>
                    )}
                </div>
                {description && (
                    <p className="desc">{description || ''}</p>
                )}
            </div>
            
        </>
    )
};

export default Variants;
