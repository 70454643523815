/* eslint-disable no-useless-escape */
import { useContext, useEffect, useState } from "react";
import SectionHeader from "./sectionHeader";
import Label from "components/common/label";
import * as Yup from "yup";
import { useFormik } from "formik";
import { VendorDetailSections } from ".";
import { useIsDisabled } from "hooks/useIsDisabled";
import { VendorContext } from "contexts/vendor";
import { VendorActions } from "contexts/reducers/vendor";
import ApiModal from "components/common/apiErrorModal";
import {LabeledInputSkeleton} from "components/common/skeleton";
import { emailValidation, phoneValidation } from "util/validationSchema/common";
import PhoneField from "components/common/phoneField";
import EditableInput from "components/common/editableInput";
import { EMAIL_CHAR_LIMIT } from "appConstants";
import SaveIcon from "components/venue/SaveIcon";
import { AuthContext } from "contexts/auth";

const CustomerContactInfo = ({ data, setActiveEdit, activeEdit }: any) => {
    const { updateVendorDetails, loading } = useContext(VendorContext);

    const [error, setError] = useState("");
    const [isEdit, setIsEdit] = useState(false);
    const [success, setSuccess] = useState(false);
    const { customer_facing_email, customer_facing_phone} = data || {};
    const {setVendorDetailsEditing} = useContext(AuthContext)

    const initialValues = {
        customer_facing_email: customer_facing_email || "",
        customer_facing_phone: customer_facing_phone || "",
    };

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: Yup.object({
            customer_facing_email: emailValidation,
            customer_facing_phone: phoneValidation,
        }),
        onSubmit: async (values: any) => {
            const res = await updateVendorDetails(values);

            if (res?.response?.data?.message) {
                setError(res?.response?.data?.message);
            }

            if (res?._status?.code === 200) {
                setSuccess(true);
                setIsEdit(false);
                setActiveEdit("");
                setVendorDetailsEditing(false)

            }
        },
    });

    const {
        values,
        errors,
        handleReset,
        setErrors,
        handleChange,
        handleSubmit,
        setFieldValue
    } = formik;

    const onEdit = () => {
        setIsEdit(true);
        setActiveEdit(VendorDetailSections.CUSTOMER_INFO);
        setVendorDetailsEditing(true)
    };

    const onClickCross = () => {
        setIsEdit(false);
        handleReset(initialValues);
        setActiveEdit("");
        setVendorDetailsEditing(false)
    };

    const isDisabled = useIsDisabled(values, errors);
    const isLoading = loading[VendorActions.UPDATE_VENDOR_DETAILS];
    const vendorLoading = loading[VendorActions.GET_VENDOR_DETAILS];

    useEffect(()=>{
        const sucessTimer = setTimeout(()=>{
             if(success)
             setSuccess(false);
         },3000);
 
 
         return ()=>{
             clearTimeout(sucessTimer)
         }
         
     }, [success])


    return (
        <>
         {
            <div className={`save-order ${success ? "save-order-animation" : ""}`}>
                 <span>   <SaveIcon /> Saved</span>
                </div>
            }
            <SectionHeader
                title="Customer Facing Contact Info"
                onClick={() => onEdit()}
                isEditMode={isEdit}
                onClickCross={onClickCross}
                onClickSubmit={() => handleSubmit()}
                editDisabled={
                    activeEdit && activeEdit !== VendorDetailSections.CUSTOMER_INFO
                }
                submitDisabled={isDisabled}
                isLoading={isLoading || vendorLoading}
            />
            <p className="edit-details-desc">
            When a customer uses Intercom for support, they are offered the email or phone for the vendor.
            </p>
            <div className="admin-info">
                <div className="detail-group customer-facing-email">
                    <EditableInput
                        label="Customer Facing Email"
                        placeholder={errors.customer_facing_email as string || "Enter Email"}
                        name="customer_facing_email"
                        value={values.customer_facing_email}
                        onChange={handleChange}
                        maxLength={EMAIL_CHAR_LIMIT}
                        isError={!!errors.customer_facing_email}
                        errors={errors}
                        setErrors={setErrors}
                        isLoading={vendorLoading}
                        isEdit={isEdit}
                    />
                </div>
                <div className="detail-group customer-facing-phone">
                    {vendorLoading ? (
                        <LabeledInputSkeleton />
                    ) : (
                        <>
                           <Label inputLabel={isEdit} text="Customer Facing Phone" />
                            {isEdit ? (
                                <PhoneField
                                    placeholder={errors.customer_facing_phone as string || "Enter Phone"}
                                    name="customer_facing_phone"
                                    value={values.customer_facing_phone}
                                    setValue={setFieldValue}
                                    onChange={handleChange}
                                    errorMessage={errors.customer_facing_phone as string}
                                    errors={errors}
                                    setErrors={setErrors}
                                />
                            ) : (
                                <p className="field-text">
                                    {values.customer_facing_phone || "Not Yet"}
                                </p>
                            )}
                        </>
                    )}
                    
                </div>
            </div>
            {error && (
                <ApiModal
                    title="Update Vendor Failed"
                    message={error}
                    setIsShow={() => setError("")}
                />
            )}
        </>
    );
};

export default CustomerContactInfo;
