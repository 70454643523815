export const usePaginationData = () => {
    // console.log('width', window.innerWidth);
    let data = {
        perPage: 21
    }
    const width = window.innerWidth;
    switch(true){
        case width > 0 && width <= 450: 
            data.perPage = 3;
            break;
        case width > 450 && width <= 768: 
            data.perPage = 5;
            break;
        case width > 768 && width <= 865: 
            data.perPage = 9;
            break;
        case width > 865 && width <= 950:
            data.perPage = 13;
            break;
        case width > 950 && width <= 1180:
            data.perPage = 15;
            break;
        default:
    }

    return data;
}