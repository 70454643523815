import ApiModal from "components/common/apiErrorModal";
import Switch from "components/common/switch";
import { useEffect, useState } from "react";
import SaveIcon from "../SaveIcon";

const VenueOrderTypes = ({vendor, setVenueDetails, updateVenue, updateLoading}: any) => {

    const [error, setError] = useState('');
    const [success, setSuccess] = useState('');

    const onSwitch = async (switchData: any) => {
        setSuccess("");
        if(updateLoading){
            return;
        }
        setVenueDetails({
            vendor: {
                ...vendor,
                [switchData.key]: !switchData.state
            }
        })
        const res = await updateVenue({[switchData.key]: !switchData.state});

        if(res?._status?.code === 200){
            setSuccess(`${switchData.name} ${switchData.state ? 'disabled' : 'enabled'} successfully!`);
        }

        if(res?.response?.data?.message){
            setError(res.response.data.message);
            setVenueDetails({
                vendor: {
                    ...vendor,
                    [switchData.key]: switchData.state
                }
            })
        }
    }

    const onCancel = () => {
        setError('')
    }


    useEffect(()=>{
       const sucessTimer = setTimeout(()=>{
            if(success)
            setSuccess('');
        },3000);


        return ()=>{
            clearTimeout(sucessTimer)
        }
        
    }, [success])

console.log(success);

    return (
        <>
            <div className="edit-header order-type">
                <p className="edit-title">Venue Order Types</p>
            </div>
            <div className="venueTypes">
                <p className="switchLabel">Concession Pickup</p>
                <Switch
                    checked={vendor?.accepting_concession_orders}
                    onChange={() => onSwitch({name: 'Concession Pickup', state: vendor?.accepting_concession_orders, key: 'accepting_concession_orders'})}
                />
            </div>
            <div className="venueTypes">
                <p className="switchLabel">Pickup Stations</p>
                <Switch 
                    checked={vendor?.accepting_station_pickups}
                    onChange={() => onSwitch({name: 'Pickup Stations', state: vendor?.accepting_station_pickups, key: 'accepting_station_pickups'})}
                />
            </div>
            <div className="venueTypes">
                <p className="switchLabel">Seat Delivery</p>
                <Switch 
                    checked={vendor?.accepting_delivery_orders}
                    onChange={() => onSwitch({name: 'Seat Delivery', state: vendor?.accepting_delivery_orders, key: 'accepting_delivery_orders'})}
                />
            </div>
            <div className="venueTypes small">
                <p className="switchLabel">Sections</p>
                <Switch 
                    checked={vendor?.has_section}
                    onChange={() => onSwitch({name: 'Sections', state: vendor?.has_section, key: 'has_section'})}
                    parentClass="toggle-small"
                    disabled
                    customStyles={{"opacity":"0.4"}}
                />
            </div>
            <div className="venueTypes small">
                <p className="switchLabel">Rows</p>
                <Switch 
                    checked={vendor?.has_rows}
                    onChange={() => onSwitch({name: 'Rows', state: vendor?.has_rows, key: 'has_rows'})}
                    parentClass="toggle-small"
                    disabled
                    customStyles={{"opacity":"0.4"}}
                />
            </div>

            {error && (
                <ApiModal
                    title="Venue Update Failed"
                    message={error}
                    setIsShow={onCancel}
                />
            )}

            {
            <div className={`save-order ${success ? "save-order-animation" : ""}`}>
                 <span>   <SaveIcon /> Saved</span>
                </div>
            }
        </>
    )
};

export default VenueOrderTypes;
