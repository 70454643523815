const PlaceMenuItem = ({place, onClickPlace}: any) => {
    // console.log('test', place);
    const {description, matched_substrings} = place;
    const address = description?.split('');
    // console.log('address', address);
    const matched = address?.splice(matched_substrings?.offset, matched_substrings?.length);
    // console.log('matched', matched);
    return (
        
        <div onClick={onClickPlace} className="pac-item">
            <span className="pac-icon pac-icon-marker">

            </span>
            <span className="pac-item-query">
                <span className="pac-matched">{matched || ''}</span>{address || ''}
            </span>
        </div>
        
    )
}

export default PlaceMenuItem;