import { createContext, useCallback, useReducer, useState } from "react";
import { SuperAdminReportActions, SuperAdminReportReducer } from "./reducers/superAdminReports";
import { getAllEvents, getEventDetails, getEventItems, getEventSales, getReportEvents } from "services/superAdminReports";
import { getVenueList } from "services/vendor";
import { ISuperAdminReportContext } from "interfaces/superAdminReport";
import timezone from 'moment-timezone';
import { getAllOrders } from "services/reports";
import moment from "moment";

const initialState: ISuperAdminReportContext = {
    loading: {
        GET_EVENTS: true
    },
    eventList: {
        events: [],
        count: 0,
        events_data: {
            avg_sales: '0.00',
            collected_fees: '0.00',
            count: 0,
            donations: '0.00', 
            total_sales: '0.00'
        }
    },
    eventItems: {
        items: [],
        event: null!
    },
    venueList: {
        venues: [],
        _count: 0
    },
    allEvents: {
        events: [],
        count: 0,
        events_data: {
            avg_sales: '0.00',
            collected_fees: '0.00',
            count: 0,
            donations: '0.00', 
            total_sales: '0.00'
        }
    },
    eventDetails: {
        event: null!,
        event_sales: null!,
        items: [],
        formattedItems: [],
        printFormattedItems: []
    },
    reportDates: {
        events: null!,
        events_sales: null!
    },
    selectedVenue: null!,
    selectedVendor: null!,
    setSelectedVendor: ()=>{},
    setLoading: () => {},
    resetLoading: () => {},
    fetchReprtEvents: () => Promise<any>,
    fetchEventItems: () => Promise<any>,
    fetchEventSales: () => Promise<any>,
    fetchVenues: () => Promise<any>,
    fetchAllEvents: () => Promise<any>,
    setSelectedVenue: () => Promise<any>,
    seeAll: false,
    setSeeAll: () => {},
    fetchEventDetails: () => Promise<any>,
    startDate: null!, 
    setStartDate: () => {},
    endDate: null!, 
    setEndDate: () => {},
    setAllEvents: () => {},
    setEvents: () => {},
    refundOrders: {
        _orders: [],
        _items: [],
        _items_count: 0,
        _orders_count: 0
    },
    eventQueryParams: {
        sort_by: 'date',
        timezone: timezone.tz.guess(),
        sort_order: '',
        filter_by: 'LAST_24_HOURS',
        limit: 10,
        offset: 0, 
        vendor_id: null!
    },

    datesQueryParams: {
        sort_by: 'date',
        timezone: timezone.tz.guess(),
        sort_order: '',
        limit: 10,
        offset: 0,
        start_date: moment().subtract(7, 'd').format("YYYY-MM-DD"),
        end_date: moment().format("YYYY-MM-DD"),
        vendor_id: null!
        
    },


    setReportReducer: () => {},
    downloadEventReport: () => {},
    datesStartDate: moment().subtract(7, 'd').format("M/D/YY"),
    datesEndDate: moment().format("M/D/YY"),
    setDatesStartDate: ()=>{},
    setDatesEndDate: ()=>{},



    //from here start for common context
    setAllOrders: ()=>{},
    setEventItems: ()=>{},
    selectedEvent: null!,
    setSelectedEvent: () => {},
    allOrders: {
        orders: [],
        _count:0
    },
    fetchAllOrder: ()=>Promise<any>,



    setEventDetails: ()=>{}, 
    setReportDetails: ()=>{}, 
    venueOpen: false, 
    orderDetail: {
        id: 0,
        order_id: 0,
        card_last4: "",
        order_date: "",
        delivery_type: "",
        items: [
                {
                  id: 0,
                  name: "",
                  price: 0,
                  variant: "",
                  quantity: 0,
                  options: [
                    {
                      id: 4,
                      name: "",
                      price: 0
                    }
                  ]
                }
              ],
              pricing: {
                tip: "",
                sub_total: 0,
                crowd_chow_fee: 0,
                tax: 0,
                total: 0
              }
            
          
    },
    fetchOrderDetail: ()=>Promise<any>,
    updateOrderStatus: () => Promise<any>,
    fetchRefundsOrder: ()=> Promise<any>,
    setVenueOpen: ()=>{},
    setOrderDetail: ()=>{},
    




  
}

export const SuperAdminReportContext = createContext<ISuperAdminReportContext>(null!)

const SuperAdminReportProvider = ({ children }: any) => {

    const [{ 
        refundOrders, 
        loading, 
        reportDates, 
        selectedVenue,
        eventDetails, 
        allEvents, 
        eventList, 
        eventItems, 
        venueList,
        eventQueryParams, 
        selectedEvent, 
        allOrders, 
        orderDetail, 
        datesQueryParams
        
    }, dispatch] = useReducer(SuperAdminReportReducer, initialState);
    const [seeAll, setSeeAll] = useState(false);
    const [startDate, setStartDate] = useState(null!);
    const [endDate, setEndDate] = useState(null!);
    const [venueOpen, setVenueOpen] = useState(true);
    const [datesStartDate, setDatesStartDate ] = useState(initialState.datesStartDate);
    const [datesEndDate, setDatesEndDate] = useState(initialState.datesEndDate);
    const [selectedVendor, setSelectedVendor] = useState(initialState.selectedVendor);

    

    const setEvents = (data: any) => {
        dispatch({
            type: SuperAdminReportActions.GET_EVENTS,
            payload: data
        })
    }




    const setReportReducer = (type: string, data: any) => {

        dispatch({
            type: type,
            payload: data
        })
    }
    
    const setSelectedVenue = (venue: any) => {
        dispatch({
            type: SuperAdminReportActions.SET_SELECTED_VENUE,
            payload: venue
        })
    }
    
    const setLoading = (loading: string) => {
        dispatch({
            type: SuperAdminReportActions.SET_REPORT_LOADING,
            payload: loading
        })
    }

    const resetLoading = (loading: string) => {
        dispatch({
            type: SuperAdminReportActions.RESET_REPORT_LOADING,
            payload: loading
        })
    }

    const fetchVenues = async (venueListReq: any) => {
        setLoading(SuperAdminReportActions.GET_VENUES)
        const res = await getVenueList(venueListReq);
        resetLoading(SuperAdminReportActions.GET_VENUES);

        if(res?._status?.code === 200){
            dispatch({
                type: SuperAdminReportActions.GET_VENUES,
                payload: res.data
            })
        }
    }

    const downloadEventReport = async (reportReq: any) => {
        setLoading(SuperAdminReportActions.GET_EVENT_REPORT_DOWNLOAD);
        const res = await getReportEvents(reportReq);
        resetLoading(SuperAdminReportActions.GET_EVENT_REPORT_DOWNLOAD);

        if(res?._status?.code === 200){
            setEvents({...res.data, offset: reportReq?.offset || 0});
        }
    }

    const fetchReprtEvents = useCallback(async (reportReq: any) => {
        setLoading(SuperAdminReportActions.GET_EVENTS);
        const res = await getReportEvents(reportReq);
        resetLoading(SuperAdminReportActions.GET_EVENTS);

        if(res?._status?.code === 200){
            setEvents({...res.data, offset: reportReq?.offset || 0});
        }
    },[])

    const setAllEvents = (data: any) => {
        dispatch({
            type: SuperAdminReportActions.GET_ALL_EVENTS,
            payload: data
        })
    }

    const fetchAllEvents = async (allEventReq: any) => {
        setLoading(SuperAdminReportActions.GET_ALL_EVENTS);
        const res = await getAllEvents(allEventReq);
        resetLoading(SuperAdminReportActions.GET_ALL_EVENTS);

        if(res?._status?.code === 200){
            setAllEvents(res.data);
        }
    }
    
    const fetchEventItems = async (eventItemsData: any) => {
        setLoading(SuperAdminReportActions.GET_EVENT_ITEMS);
        const res = await getEventItems(eventItemsData);
        resetLoading(SuperAdminReportActions.GET_EVENT_ITEMS);

        if(res?._status?.code === 200){
            dispatch({
                type: SuperAdminReportActions.GET_EVENT_ITEMS,
                payload: res.data
            });
        }
    }
    
    const fetchEventSales = async (eventSaleReq: any) => {
        setLoading(SuperAdminReportActions.GET_EVENT_SALES);
        const res = await getEventSales(eventSaleReq);
        resetLoading(SuperAdminReportActions.GET_EVENT_SALES);

        if(res?._status?.code === 200){
            let updatedFormatItems:any=[];
            let i=0;
            while(i < res.data?.items?.length){
                updatedFormatItems.push(res.data.items?.slice(i,i+14));
                i += 14;
            }

            let printFormattedItems = [];
            i=0;
            while(i < res.data?.items?.length){
                printFormattedItems.push(res.data.items?.slice(i,i+2));
                i += 2;
            }
            dispatch({
                type: SuperAdminReportActions.GET_EVENT_SALES,
                payload: {
                    ...res.data,
                    formattedItems: updatedFormatItems,
                    printFormattedItems: printFormattedItems
                }
            });
        }
    }

    const fetchEventDetails = async (eventDetailsReq: any) => {
        setLoading(SuperAdminReportActions.GET_EVENT_DETAILS);
        const res = await getEventDetails(eventDetailsReq);
        resetLoading(SuperAdminReportActions.GET_EVENT_DETAILS);

        if(res?._status?.code === 200){
            let formattedItems: any  = [];
            let i=0;
            while(i<res?.data?.events_sales?.items.length){
                formattedItems.push(res?.data?.events_sales?.items.slice(i, i+14));
                i+=14;
            }
            
            let printFormattedItems=[];
            i=0;
            while(i<res?.data?.events_sales?.items.length){
                printFormattedItems.push(res?.data?.events_sales?.items.slice(i, i+2));
                i+=2;
            }
            dispatch({
                type: SuperAdminReportActions.GET_EVENT_DETAILS,
                payload: {
                    ...res.data, 
                    events_sales: {
                        ...res.data.events_sales,
                        formattedItems: formattedItems,
                        printFormattedItems: printFormattedItems
                    }
                }
            });
        }
    }


        //from here
        const setEventItems = (data: any) => {
        dispatch({
            type: SuperAdminReportActions.GET_EVENT_ITEMS,
            payload: data
        })
    }

    const setSelectedEvent = (data: any) => {
        dispatch({
            type: SuperAdminReportActions.SET_SELECTED_EVENT,
            payload: data
        })
    }

    const fetchAllOrder = async (ordersReq: any) =>{
        setLoading(SuperAdminReportActions.GET_ORDERS);
        const res = await getAllOrders(ordersReq);
        resetLoading(SuperAdminReportActions.GET_ORDERS);

        if(res?._status?.code === 200){
            dispatch({
                type: SuperAdminReportActions.GET_ORDERS,
                payload: res.data
            })
        }
    }

    const setAllOrders = (data: any) => {
        dispatch({
            type: SuperAdminReportActions.GET_ORDERS,
            payload: data
        })
    }



    const setEventDetails = (data: any) => {
        // dispatch({
        //     type: ReportActions.GET_EVENT_SALES,
        //     payload: data
        // })
    }


    const setReportDetails = (data: any) => {
        dispatch({
            // type: ReportActions.GET_EVENT_DETAILS,
            // payload: data
        })
    }


    const fetchOrderDetail = async (order_id: any)=>{
        // setLoading(ReportActions.GET_ORDER_DETAIL);
        // const res = await getOrderDetail(order_id);
        // resetLoading(ReportActions.GET_ORDER_DETAIL);
      
        // if(res?._status?.code === 200){
        //     dispatch({
        //         type: ReportActions.GET_ORDER_DETAIL,
        //         payload: res.data
        //     })
        // }
        

    }

    const updateOrderStatus = async (updateReq: any) => {
        // setLoading(ReportActions.UPDATE_ORDER_STATUS);
        // const res:any = await putOrderStatus(updateReq);
        // resetLoading(ReportActions.UPDATE_ORDER_STATUS);
        
        // if(res?._status?.code === 200){
        //     dispatch({
        //         type: UserActions.UPDATE_ORDER_STATUS,
        //         payload: vendor_id
        //     });
        // }

        //return res;
    }

    const fetchRefundsOrder = async (refundDatReq: any) => {
        // setLoading(ReportActions.SET_REFUND_ORDER);
        // const res = await getRefundsOrder(refundDatReq);
        // resetLoading(ReportActions.SET_REFUND_ORDER);

        // if(res?._status?.code === 200){
        //     dispatch({
        //         type: ReportActions.SET_REFUND_ORDER,
        //         payload: res.data
        //     })
        // }
    }

    const setOrderDetail = (data: any) => {
        // dispatch({
        //     type:ReportActions.GET_ORDER_DETAIL,
        //     payload: data

        // })

    }



    return <SuperAdminReportContext.Provider value={{
        loading,
        resetLoading,
        setLoading,
        fetchReprtEvents,
        eventList,
        fetchEventItems,
        eventItems,
        fetchEventSales,
        fetchVenues,
        venueList,
        fetchAllEvents,
        allEvents,
        eventDetails,
        selectedVenue,
        setSelectedVenue,
        seeAll,
        setSeeAll,
        reportDates,
        fetchEventDetails,
        startDate, 
        setStartDate,
        endDate, 
        setEndDate,
        refundOrders,
        setAllEvents,
        setEvents,
        eventQueryParams,
        setReportReducer,
        downloadEventReport,
        datesQueryParams,
        datesStartDate,
        setDatesStartDate, 
        datesEndDate, 
        setDatesEndDate,
        selectedVendor, 
        setSelectedVendor,



        //from here
        setEventItems,
        selectedEvent,
        setSelectedEvent, 
        allOrders, 
        fetchAllOrder, 
        setAllOrders,
        setEventDetails,
        setReportDetails,
        venueOpen, 
        orderDetail, 
        fetchOrderDetail,
        updateOrderStatus,
        fetchRefundsOrder, 
        setVenueOpen,
        setOrderDetail,


    }}>
        {children}
    </SuperAdminReportContext.Provider>
}
export default SuperAdminReportProvider;