import ApiModal from "components/common/apiErrorModal";
import CheckMarkButton from "components/common/checkMarkButton";
import CrossButton from "components/common/crossButton";
import Input from "components/common/input";
import { Modal, ModalBody, ModalHeader } from "components/common/modal";
import { AuthContext } from "contexts/auth";
import { AuthActions } from "contexts/reducers/auth";
import { useFormik } from "formik";
import { useIsDisabled } from "hooks/useIsDisabled";
import { useContext, useState } from "react";
import { changePasswordSchema } from "util/validationSchema/changePasswordSchema";

const initialValues = {
    current_password: '',
    password: '',
    confirm_password: ''
}

const ChangePasswordModal = ({isForce, closeModal, setPasswordChange, logoutUser}: any) => {
    const { changePassword, loading } = useContext(AuthContext);

    const [error, setError] = useState('');


    const formik = useFormik({
        initialValues,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: changePasswordSchema,
        onSubmit: async (values: any) => {
            const {confirm_password, ...rest} = values;
            const res = await changePassword({
                ...rest,
                ...(isForce && {temp_pass_update: true})
            });

            if(res?._status?.code === 200){
                setPasswordChange && setPasswordChange(true);
                isForce ? logoutUser && logoutUser() : closeModal && closeModal();
            }

            if(res?.response?.data?.message){
                setError(res?.response?.data?.message);
            }
        }
    });
    const {values, errors, handleChange, handleSubmit, setErrors} = formik;

    const handleCloseModal = () => {
        closeModal && closeModal();
    }
    
    const isActiveDisabled = useIsDisabled(values, errors);
    const isLoading = loading.includes(AuthActions.UPDATE_PASSWORD);
    return (
        <>
            <Modal
                setShowModal={() => handleCloseModal()}
                customClass={`add-modal ${error ? 'disabled' : ''}`}
            >
                <ModalHeader>
                    <div className="modal-actions">
                        <CrossButton
                            onClick={handleCloseModal}
                            disabled={isLoading}
                        />
                        <CheckMarkButton
                            onClick={handleSubmit}
                            width={47}
                            height={47}
                            activeDisabled={isActiveDisabled}
                            disabled={isLoading}
                            isForm
                        />
                    </div>
                    <p className="app-modal-title">Change Password</p>
                </ModalHeader>

                <ModalBody>
                    <Input
                        type="password"
                        name="current_password"
                        label="Current Password"
                        placeholder={errors.current_password as string || 'Enter Current Password'}
                        value={values.current_password}
                        onChange={handleChange}
                        errors={errors}
                        isError={!!(errors?.current_password)}
                        setErrors={setErrors}
                        showHidePassword
                        width="50%"
                    />
                    <Input
                        type="password"
                        name="password"
                        label="New Password"
                        placeholder={errors.password as string || 'Enter New Password'}
                        value={values.password}
                        onChange={handleChange}
                        errors={errors}
                        isError={!!(errors?.password)}
                        setErrors={setErrors}
                        showHidePassword
                        width="50%"
                    />
                    <Input
                        type="password"
                        name="confirm_password"
                        label="Confirm New Password"
                        placeholder={errors.confirm_password as string || 'Confirm New Password'}
                        value={values.confirm_password}
                        onChange={handleChange}
                        errors={errors}
                        isError={!!(errors?.confirm_password)}
                        setErrors={setErrors}
                        showHidePassword
                        width="50%"
                    />
                </ModalBody>
            </Modal>

            {error && (
                <ApiModal 
                    title="Change Password Failed"
                    message={error}
                    setIsShow={() => setError('')}
                />
            )}
        </>
    )
};

export default ChangePasswordModal;
