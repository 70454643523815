import ApiModal from "components/common/apiErrorModal";
import CheckMarkButton from "components/common/checkMarkButton";
import CrossButton from "components/common/crossButton";
import Input from "components/common/input";
import { Modal, ModalBody, ModalHeader } from "components/common/modal";
import { useFormik } from "formik";
import { useIsDisabled } from "hooks/useIsDisabled";
import { useState } from "react";
import { addAdminSchema } from "util/validationSchema/adminSchemas";

interface Props {
    closeModal: Function;
    setFlash?: Function;
    addAdmin: Function;
    loading: boolean;
}

const initialValues = {
    first_name: '',
    last_name: '',
    email: ''
}

const AddStaffModal = ({addAdmin, loading, closeModal, setFlash}: Props) => {
    const [error, setError] = useState('');

    const formik = useFormik({
        initialValues,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: addAdminSchema,
        onSubmit: async (values: any) => {
            const res = await addAdmin(values);

            if(res?._status?.code === 201){
                closeModal();
                if(setFlash && res?.data?.recordIndex !== undefined && res?.data?.recordIndex !== null){
                    setFlash(res?.data?.recordIndex);
                }
            }

            if(res?.response?.data?.message){
                setError(res.response?.data?.message);
            }
        }
    });
    const {values, errors, handleChange, handleSubmit, setErrors} = formik;
    
    const isDisabled = useIsDisabled(values, errors);
    const isLoading = loading;

    return (
        <>
            <Modal
                setShowModal={() => closeModal()}
                customClass={`add-modal ${error ? 'disabled' : ''}`}
            >
                <ModalHeader>
                    <div className="modal-actions">
                        <CrossButton
                            onClick={closeModal}
                            disabled={isLoading}
                        />
                        <CheckMarkButton
                            onClick={handleSubmit}
                            width={47}
                            height={47}
                            activeDisabled={isDisabled}
                            disabled={isLoading}
                            isForm
                        />
                    </div>
                    <p className="app-modal-title">Add Staff</p>
                </ModalHeader>

                <ModalBody>
                    <div className="add-lead-form">
                        <div className="row">
                            <Input
                                name="first_name"
                                label="First Name"
                                placeholder={errors.first_name as string || 'Enter First Name'}
                                value={values?.first_name}
                                onChange={handleChange}
                                isError={!!(errors?.first_name)}
                                errors={errors}
                                setErrors={setErrors}
                                width="50%"
                            />
                        </div>
                        <div className="row">
                            <Input
                                name="last_name"
                                label="Last Name"
                                placeholder={errors.last_name as string || 'Enter Last Name'}
                                value={values?.last_name}
                                onChange={handleChange}
                                isError={!!(errors?.last_name)}
                                errors={errors}
                                setErrors={setErrors}
                                width="50%"
                            />
                        </div>
                        <div className="row">
                            <Input 
                                label="Email"
                                placeholder={errors.email as string || 'Enter Email'}
                                name="email"
                                value={values?.email}
                                onChange={handleChange}
                                isError={!!(errors?.email)}
                                errors={errors}
                                setErrors={setErrors}
                                width="65%"
                            />
                        </div>                   
                    </div> 
                </ModalBody>
            </Modal>

            {error && (
                <ApiModal 
                    title="Add Staff Failed"
                    message={error}
                    setIsShow={() => setError('')}
                />
            )}
        </>
    )
};

export default AddStaffModal;
