import { useIsSuperAdmin } from "hooks/useIsSuperAdmin";
import { useLocation } from "react-router-dom";

const PrintIcon = ({onClick, disable, seeAll}: any) => {

    const isSuperAdmin = useIsSuperAdmin();
    const location = useLocation();

    return (
        <div onClick={() => !disable && onClick()} className={`hide-on-print report-icon ${disable ? 'disabled' : 'cursor-pointer'} ${location.pathname.includes('reports') && !isSuperAdmin && seeAll ? 'event-print': ''} ${location.pathname.includes('details') && isSuperAdmin && !seeAll ? 'eventDetail-print': ''} `}>
            <svg xmlns="http://www.w3.org/2000/svg" width="67" height="51" viewBox="0 0 67 51" fill="none">
                <mask id="mask0_5257_1198" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="2" y="2" width="65" height="49">
                    <path d="M67.0002 2.71094H2.07617V51.0009H67.0002V2.71094Z" fill="white"/>
                </mask>
                <g mask="url(#mask0_5257_1198)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M12.1562 8.20312H56.9242C57.6307 8.20654 58.3074 8.48873 58.807 8.98834C59.3066 9.48795 59.5887 10.1646 59.5922 10.8711V17.2441H9.49219V10.8711C9.4918 10.521 9.5604 10.1742 9.69409 9.85059C9.82778 9.52697 10.0239 9.23288 10.2713 8.98511C10.5187 8.73733 10.8125 8.54073 11.1359 8.40656C11.4593 8.27238 11.806 8.20326 12.1562 8.20312Z" fill="black"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.04518 46.3641H65.0312C65.5562 46.3586 66.0577 46.1458 66.4265 45.7721C66.7953 45.3984 67.0015 44.8942 67.0002 44.3691V19.2131C67.0003 18.9545 66.9495 18.6984 66.8506 18.4595C66.7517 18.2205 66.6066 18.0034 66.4238 17.8206C66.2409 17.6377 66.0238 17.4926 65.7848 17.3937C65.5459 17.2948 65.2898 17.244 65.0312 17.2441H4.04518C3.78657 17.244 3.53047 17.2948 3.29152 17.3937C3.05257 17.4926 2.83546 17.6377 2.65259 17.8206C2.46973 18.0034 2.3247 18.2205 2.22579 18.4595C2.12689 18.6984 2.07605 18.9545 2.07618 19.2131V44.3691C2.07483 44.8942 2.28102 45.3984 2.64983 45.7721C3.01864 46.1458 3.52018 46.3586 4.04518 46.3641Z" fill="black"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M67.0002 21.4421V19.2131C67.0003 18.9545 66.9495 18.6984 66.8506 18.4595C66.7516 18.2205 66.6066 18.0034 66.4238 17.8206C66.2409 17.6377 66.0238 17.4926 65.7848 17.3937C65.5459 17.2948 65.2898 17.244 65.0312 17.2441H4.04517C3.78656 17.244 3.53046 17.2948 3.29151 17.3937C3.05256 17.4926 2.83545 17.6377 2.65259 17.8206C2.46972 18.0034 2.32469 18.2205 2.22579 18.4595C2.12688 18.6984 2.07604 18.9545 2.07617 19.2131V21.4411L67.0002 21.4421Z" fill="black"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M4.04518 46.3657H65.0312C65.5561 46.3599 66.0575 46.1471 66.4262 45.7734C66.795 45.3998 67.0013 44.8956 67.0002 44.3706V42.1426H2.07618V44.3706C2.07509 44.8956 2.28137 45.3998 2.65012 45.7734C3.01887 46.1471 3.52026 46.3599 4.04518 46.3657Z" fill="black"/>
                    <path d="M59.383 37.7637H9.66699V40.2507H59.383V37.7637Z" fill="black"/>
                    <path d="M54.383 37.7637H14.667V51.0017H54.383V37.7637Z" fill="black"/>
                    <path d="M54.383 37.7637H14.667V39.1107H54.383V37.7637Z" fill="black"/>
                    <path d="M54.383 2.71094H14.667V17.2449H54.383V2.71094Z" fill="black"/>
                    <path d="M46.555 38.4551H17.543V39.8021H46.555V38.4551Z" fill="black"/>
                    <path d="M51.508 40.793H17.543V42.14H51.508V40.793Z" fill="black"/>
                    <path d="M51.508 45.4707H17.543V46.8177H51.508V45.4707Z" fill="black"/>
                    <path d="M40.364 43.1328H17.543V44.4798H40.364V43.1328Z" fill="black"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M60.342 23.6719C60.7164 23.6695 61.0765 23.8154 61.3435 24.0778C61.6105 24.3401 61.7629 24.6976 61.7671 25.0719C61.7671 25.4499 61.6168 25.8123 61.3496 26.0795C61.0824 26.3467 60.72 26.497 60.342 26.497C59.9641 26.497 59.6017 26.3467 59.3345 26.0795C59.0672 25.8123 58.917 25.4499 58.917 25.0719C58.9212 24.6976 59.0735 24.3401 59.3406 24.0778C59.6076 23.8154 59.9677 23.6695 60.342 23.6719Z" fill="black"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M55.342 23.6719C55.7164 23.6695 56.0765 23.8154 56.3435 24.0778C56.6105 24.3402 56.7629 24.6976 56.7671 25.0719C56.7671 25.4499 56.6168 25.8123 56.3496 26.0795C56.0824 26.3467 55.72 26.497 55.342 26.497C54.9641 26.497 54.6017 26.3467 54.3345 26.0795C54.0672 25.8123 53.917 25.4499 53.917 25.0719C53.921 24.6975 54.0732 24.34 54.3403 24.0776C54.6074 23.8151 54.9676 23.6692 55.342 23.6719Z" fill="black"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M50.3411 23.6719C50.7155 23.6692 51.0757 23.8151 51.3428 24.0776C51.6099 24.34 51.7622 24.6975 51.7661 25.0719C51.7661 25.2591 51.7293 25.4443 51.6577 25.6172C51.5861 25.7901 51.481 25.9472 51.3486 26.0795C51.2163 26.2118 51.0594 26.3169 50.8865 26.3885C50.7136 26.4601 50.5282 26.497 50.3411 26.497C50.1539 26.497 49.9688 26.4601 49.7959 26.3885C49.623 26.3169 49.4658 26.2118 49.3335 26.0795C49.2012 25.9472 49.0963 25.7901 49.0247 25.6172C48.953 25.4443 48.916 25.2591 48.916 25.0719C48.92 24.6975 49.0723 24.34 49.3394 24.0776C49.6065 23.8151 49.9666 23.6692 50.3411 23.6719Z" fill="black"/>
                    <path d="M54.383 15.2754H14.667V17.2444H54.383V15.2754Z" fill="black"/>
                </g>
                <mask id="mask1_5257_1198" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="0" y="0" width="65" height="49">
                    <path d="M65.0002 0.710938H0.0761719V49.0009H65.0002V0.710938Z" fill="white"/>
                </mask>
                <g mask="url(#mask1_5257_1198)">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M10.1562 6.20312H54.9242C55.6307 6.20654 56.3074 6.48873 56.807 6.98834C57.3066 7.48795 57.5887 8.16458 57.5922 8.87112V15.2441H7.49219V8.87112C7.4918 8.52098 7.5604 8.1742 7.69409 7.85059C7.82778 7.52697 8.02393 7.23288 8.27133 6.98511C8.51873 6.73733 8.81251 6.54073 9.13593 6.40656C9.45934 6.27238 9.80605 6.20326 10.1562 6.20312Z" fill="#323B3F"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.04518 44.3641H63.0312C63.5562 44.3586 64.0577 44.1458 64.4265 43.7721C64.7953 43.3984 65.0015 42.8942 65.0002 42.3691V17.2131C65.0003 16.9545 64.9495 16.6984 64.8506 16.4595C64.7517 16.2205 64.6066 16.0034 64.4238 15.8206C64.2409 15.6377 64.0238 15.4926 63.7848 15.3937C63.5459 15.2948 63.2898 15.244 63.0312 15.2441H2.04518C1.78657 15.244 1.53047 15.2948 1.29152 15.3937C1.05257 15.4926 0.835457 15.6377 0.652592 15.8206C0.469727 16.0034 0.324697 16.2205 0.225792 16.4595C0.126887 16.6984 0.076047 16.9545 0.0761784 17.2131V42.3691C0.0748301 42.8942 0.281021 43.3984 0.64983 43.7721C1.01864 44.1458 1.52018 44.3586 2.04518 44.3641Z" fill="#5F5F5F"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M65.0002 19.4421V17.2131C65.0003 16.9545 64.9495 16.6984 64.8506 16.4595C64.7516 16.2205 64.6066 16.0034 64.4238 15.8206C64.2409 15.6377 64.0238 15.4926 63.7848 15.3937C63.5459 15.2948 63.2898 15.244 63.0312 15.2441H2.04517C1.78656 15.244 1.53046 15.2948 1.29151 15.3937C1.05256 15.4926 0.83545 15.6377 0.652586 15.8206C0.469721 16.0034 0.324691 16.2205 0.225786 16.4595C0.126881 16.6984 0.0760407 16.9545 0.0761721 17.2131V19.4411L65.0002 19.4421Z" fill="#4F575B"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M2.04518 44.3657H63.0312C63.5561 44.3599 64.0575 44.1471 64.4262 43.7734C64.795 43.3998 65.0013 42.8956 65.0002 42.3706V40.1426H0.0761761V42.3706C0.0750895 42.8956 0.281366 43.3998 0.650119 43.7734C1.01887 44.1471 1.52026 44.3599 2.04518 44.3657Z" fill="#323B3F"/>
                    <path d="M57.383 35.7637H7.66699V38.2507H57.383V35.7637Z" fill="#323B3F"/>
                    <path d="M52.383 35.7637H12.667V49.0017H52.383V35.7637Z" fill="#EBEBEB"/>
                    <path d="M52.383 35.7637H12.667V37.1107H52.383V35.7637Z" fill="#E0E1E1"/>
                    <path d="M52.383 0.710938H12.667V15.2449H52.383V0.710938Z" fill="#EBEBEB"/>
                    <path d="M44.555 36.4551H15.543V37.8021H44.555V36.4551Z" fill="#5F5F5F"/>
                    <path d="M49.508 38.793H15.543V40.14H49.508V38.793Z" fill="#5F5F5F"/>
                    <path d="M49.508 43.4707H15.543V44.8177H49.508V43.4707Z" fill="#5F5F5F"/>
                    <path d="M38.364 41.1328H15.543V42.4798H38.364V41.1328Z" fill="#5F5F5F"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M58.342 21.6719C58.7164 21.6695 59.0765 21.8154 59.3435 22.0778C59.6105 22.3401 59.7629 22.6976 59.7671 23.0719C59.7671 23.4499 59.6168 23.8123 59.3496 24.0795C59.0824 24.3467 58.72 24.497 58.342 24.497C57.9641 24.497 57.6017 24.3467 57.3345 24.0795C57.0672 23.8123 56.917 23.4499 56.917 23.0719C56.9212 22.6976 57.0735 22.3401 57.3406 22.0778C57.6076 21.8154 57.9677 21.6695 58.342 21.6719Z" fill="#F86041"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M53.342 21.6719C53.7164 21.6695 54.0765 21.8154 54.3435 22.0778C54.6105 22.3402 54.7629 22.6976 54.7671 23.0719C54.7671 23.4499 54.6168 23.8123 54.3496 24.0795C54.0824 24.3467 53.72 24.497 53.342 24.497C52.9641 24.497 52.6017 24.3467 52.3345 24.0795C52.0672 23.8123 51.917 23.4499 51.917 23.0719C51.921 22.6975 52.0732 22.34 52.3403 22.0776C52.6074 21.8151 52.9676 21.6692 53.342 21.6719Z" fill="#BBD700"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M48.3411 21.6719C48.7155 21.6692 49.0757 21.8151 49.3428 22.0776C49.6099 22.34 49.7622 22.6975 49.7661 23.0719C49.7661 23.2591 49.7293 23.4443 49.6577 23.6172C49.5861 23.7901 49.481 23.9472 49.3486 24.0795C49.2163 24.2118 49.0594 24.3169 48.8865 24.3885C48.7136 24.4601 48.5282 24.497 48.3411 24.497C48.1539 24.497 47.9688 24.4601 47.7959 24.3885C47.623 24.3169 47.4658 24.2118 47.3335 24.0795C47.2012 23.9472 47.0963 23.7901 47.0247 23.6172C46.953 23.4443 46.916 23.2591 46.916 23.0719C46.92 22.6975 47.0723 22.34 47.3394 22.0776C47.6065 21.8151 47.9666 21.6692 48.3411 21.6719Z" fill="#BBD700"/>
                    <path d="M52.383 13.2754H12.667V15.2444H52.383V13.2754Z" fill="#E0E1E1"/>
                </g>
            </svg>
        </div>
    )
};

export default PrintIcon;
