interface Props {
    isError: boolean;
    errorsList: any;
    error: string;
}

const MenuErrors = ({isError, errorsList, error}: Props) => {
    return (
        <>
            <div className={`fileError fileErrorFadeOut ${error ? 'fileErrorFadeIn' : ''}`}>
                    <div className="warning">
                        <svg width="30" height="27" viewBox="0 0 30 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g filter="url(#filter0_d_3286_2508)">
                                <path d="M13.4611 1.47807C13.5492 1.32723 13.6753 1.2021 13.8268 1.11514C13.9783 1.02818 14.1499 0.982422 14.3246 0.982422C14.4993 0.982422 14.6709 1.02818 14.8224 1.11514C14.9739 1.2021 15.1 1.32723 15.1881 1.47807L27.7711 23.0211C27.8587 23.1729 27.9049 23.345 27.9051 23.5203C27.9052 23.6956 27.8593 23.8678 27.7719 24.0198C27.6844 24.1717 27.5586 24.298 27.407 24.386C27.2554 24.4739 27.0834 24.5205 26.9081 24.5211H1.74209C1.56681 24.5205 1.39475 24.4739 1.24315 24.386C1.09156 24.298 0.965746 24.1717 0.878336 24.0198C0.790925 23.8678 0.744984 23.6956 0.745117 23.5203C0.745251 23.345 0.791453 23.1729 0.879094 23.0211L13.4611 1.47807Z" fill="#FDC13D"/>
                            </g>
                            <path d="M14.235 16.5884C14.085 16.5884 13.955 16.5384 13.845 16.4384C13.745 16.3384 13.685 16.1934 13.665 16.0034L13.11 10.1684L13.095 10.0184C13.095 9.70844 13.2 9.46344 13.41 9.28344C13.63 9.09344 13.905 8.99844 14.235 8.99844C14.565 8.99844 14.835 9.09344 15.045 9.28344C15.265 9.46344 15.375 9.70844 15.375 10.0184L15.36 10.1834L14.805 16.0034C14.785 16.1934 14.72 16.3384 14.61 16.4384C14.51 16.5384 14.385 16.5884 14.235 16.5884ZM14.235 19.7384C13.915 19.7384 13.65 19.6334 13.44 19.4234C13.23 19.2034 13.125 18.9334 13.125 18.6134C13.125 18.2934 13.23 18.0284 13.44 17.8184C13.65 17.5984 13.915 17.4884 14.235 17.4884C14.555 17.4884 14.82 17.5984 15.03 17.8184C15.24 18.0284 15.345 18.2934 15.345 18.6134C15.345 18.9334 15.24 19.2034 15.03 19.4234C14.82 19.6334 14.555 19.7384 14.235 19.7384Z" fill="black"/>
                            <defs>
                                <filter id="filter0_d_3286_2508" x="0.745117" y="0.982422" width="29.1602" height="25.5391" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                                    <feOffset dx="2" dy="2"/>
                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3286_2508"/>
                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3286_2508" result="shape"/>
                                </filter>
                            </defs>
                        </svg>
                    </div>
                    <p>The format of your CSV is not correct. Please keep the header row of the template in place and make sure you are saving as CSV.</p>
            </div>
            <div className={`errorTableFadeOut ${isError && !error ? 'errorTableFadeIn' : ''}`}>
                
                
                {isError && !error && (
                    <div className="errorTableHeader">
                        <p className="row-col">Row</p>
                        <p className="name">Name</p>
                        <p className="price">Price</p>
                        <p className="category">Categories</p>
                        <p className="option">Options</p>
                    </div>
                )}
                <div className="errorTableBody">
                    {errorsList.map((err: any, index: number) => {
                        const {
                            name, 
                            price, 
                            category_1, 
                            category_2, 
                            category_3,
                            option_1_name, 
                            option_2_name, 
                            option_3_name,
                            option_4_name,
                            option_5_name,
                            option_1_price,
                            option_2_price,
                            option_3_price,
                            option_4_price,
                            option_5_price,
                            variant_1_name,
                            variant_1_price,
                            variant_2_name,
                            variant_2_price,
                            variant_3_name,
                            variant_3_price,
                            modifiedErrors,
                            row
                        } = err;

                        const { 
                            isVariantError,
                            isOptionError,
                            isCategoryError
                        } = modifiedErrors || {};
                        console.log('modifiedErrors', variant_1_price);
                        return (
                            <>
                                <div className="errorTableRow">
                                    <div className="row-col">
                                        <p>{row}</p>
                                    </div>
                                    <div className={`name ${isVariantError ? 'nameOnly' : ''} ${modifiedErrors?.name ? 'error' : ''}`}>
                                        <p>{name || ''}</p>
                                        {isVariantError && (
                                            <div>
                                                {(modifiedErrors?.variant_1_name || modifiedErrors?.variant_1_price) && (
                                                    <span>
                                                        <p>{variant_1_name}</p>
                                                        <p>{variant_1_price}</p>
                                                    </span>
                                                )}
                                                {(modifiedErrors?.variant_2_name || modifiedErrors?.variant_2_price) && (
                                                    <span>
                                                        <p>{variant_2_name}</p>
                                                        <p>{variant_2_price}</p>
                                                    </span>
                                                )}
                                                {(modifiedErrors?.variant_3_name || modifiedErrors?.variant_3_price) && (
                                                    <span>
                                                        <p>{variant_3_name}</p>
                                                        <p>{variant_3_price}</p>
                                                    </span>
                                                )}
                                            </div>
                                        )}
                                        {(modifiedErrors?.name || isVariantError) && (
                                            <div className="toolTip">
                                                <ol>
                                                    {modifiedErrors?.name && <li>{modifiedErrors.name}</li>}
                                                    {modifiedErrors?.variant_1_name && <li>{modifiedErrors.variant_1_name}</li>}
                                                    {modifiedErrors?.variant_2_name && <li>{modifiedErrors.variant_2_name}</li>}
                                                    {modifiedErrors?.variant_3_name && <li>{modifiedErrors.variant_3_name}</li>}
                                                    {modifiedErrors?.variant_1_price && <li>{modifiedErrors.variant_1_price}</li>}
                                                    {modifiedErrors?.variant_2_price && <li>{modifiedErrors.variant_2_price}</li>}
                                                    {modifiedErrors?.variant_3_price && <li>{modifiedErrors.variant_3_price}</li>}
                                                </ol>
                                            </div>
                                        )}
                                    </div>
                                    <div className={`price ${modifiedErrors?.price ? 'error' : ''}`}>
                                        <p>{price || ''}</p>
                                        {modifiedErrors?.price && <div className="toolTip">{modifiedErrors.price}</div>}
                                    </div>
                                    <div className={`category ${isCategoryError ? 'error' : ''}`}>
                                        <p>{`${category_1 || ''}${category_2 ? `, ${category_2}` : ''}${category_3 ? `, ${category_3}` : ''}`}</p>
                                        {isCategoryError && (
                                            <div className="toolTip">
                                                <ol>
                                                    {modifiedErrors?.category_1 && <li>{modifiedErrors?.category_1}</li>}
                                                    {modifiedErrors?.category_2 && <li>{modifiedErrors?.category_2}</li>}
                                                    {modifiedErrors?.category_3 && <li>{modifiedErrors?.category_3}</li>}
                                                </ol>
                                            </div>
                                        )}
                                    </div>
                                    <div className={`option ${isOptionError ? 'error' : ''}`}>
                                        <div>
                                            {option_1_name && <p>{`${option_1_name} ${(Number(option_1_price))?.toFixed(2)?.replace(/^0+/,"") || ''}`}</p>}
                                            {option_2_name && <p>{`${option_2_name} ${(Number(option_2_price))?.toFixed(2)?.replace(/^0+/,"") || ''}`}</p>}
                                            {option_3_name && <p>{`${option_3_name} ${(Number(option_3_price))?.toFixed(2)?.replace(/^0+/,"") || ''}`}</p>}
                                        </div>
                                        <div>
                                            {option_4_name && <p>{`${option_4_name} ${(Number(option_4_price))?.toFixed(2)?.replace(/^0+/,"") || ''}`}</p>}
                                            {option_5_name && <p>{`${option_5_name} ${(Number(option_5_price))?.toFixed(2)?.replace(/^0+/,"") || ''}`}</p>}
                                        </div>
                                        {isOptionError && (
                                            <div className="toolTip">
                                                <ol>
                                                    {modifiedErrors?.option_1_name && <li>{modifiedErrors?.option_1_name}</li>}
                                                    {modifiedErrors?.option_2_name && <li>{modifiedErrors?.option_2_name}</li>}
                                                    {modifiedErrors?.option_3_name && <li>{modifiedErrors?.option_3_name}</li>}
                                                    {modifiedErrors?.option_4_name && <li>{modifiedErrors?.option_4_name}</li>}
                                                    {modifiedErrors?.option_5_name && <li>{modifiedErrors?.option_5_name}</li>}
                                                    {modifiedErrors?.option_1_price && <li>{modifiedErrors?.option_1_price}</li>}
                                                    {modifiedErrors?.option_2_price && <li>{modifiedErrors?.option_2_price}</li>}
                                                    {modifiedErrors?.option_3_price && <li>{modifiedErrors?.option_3_price}</li>}
                                                    {modifiedErrors?.option_4_price && <li>{modifiedErrors?.option_4_price}</li>}
                                                    {modifiedErrors?.option_5_price && <li>{modifiedErrors?.option_5_price}</li>}
                                                </ol>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                    
                            </>
                        )
                    })}
                    {isError && !error && [...new Array(errorsList.length < 7 ? 7 - errorsList.length : 0)].map(() => {
                        return (
                            <div className="errorTableRow">
                                <div className="name">
                                    <p></p>
                                </div>
                                <div className="price">
                                    <p></p>
                                    
                                </div>
                                <div className="category">
                                    <p></p>
                                </div>
                                <div className="option">
                                    <div>
                                        
                                    </div>
                                    <div>
                                        
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
};

export default MenuErrors;