import MenuImageBox from "./menuImageBox";
import Variants from "./variant";

interface Props {
    isData: boolean;
    isError: boolean;
    data: any;
    onClickOpen: Function;
    noImageIndices: number[];
}
const MenuResults = ({noImageIndices, data, isError, isData, onClickOpen}: Props) => {
    return (
        <div className={`resultsTableFadeOut ${!isError && isData ? 'resultsTableFadeIn' : ''}`}>
            <div className="results-table header">
                <div className="food-image">
                    Image
                </div>
                <div className='food-name'>
                    <p>Name</p>
                    <p>Price</p>
                </div>
                <div className='food-categories'>
                    Categories
                </div>
                <div className='food-option'>
                    Options
                </div>
            </div>
            <div className="resultsTableBody">
                {data.map((item: any, i: number) => {
                    const {
                        name,
                        description,
                        price,
                        category_1,
                        category_1_color,
                        category_2,
                        category_2_color,
                        category_3,
                        category_3_color,
                        variant_1_name,
                        variant_1_price,
                        variant_2_name,
                        variant_2_price,
                        variant_3_name,
                        variant_3_price,
                        option_1_name,
                        option_1_price,
                        option_2_name,
                        option_2_price,
                        option_3_name,
                        option_3_price,
                        option_4_name,
                        option_4_price,
                        option_5_name,
                        option_5_price,
                        item_image
                    } = item;

                    const noImage = noImageIndices.includes(i);

                    return (
                        <div className="results-table">
                            <div onClick={() => onClickOpen(name, i)} className={`food-image ${(item_image || noImage) ? 'flip-image' : ''}`}>
                                <div className='flip'>
                                    <div className='addFoodImg'>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                                            <g clipPath="url(#clip0_3147_942)">
                                                <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#26CAD3"/>
                                                <path d="M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29Z" stroke="white" strokeWidth="2"/>
                                                <path d="M8.5 15H21.5" stroke="white" strokeWidth="3"/>
                                                <path d="M15 9L15 21" stroke="white" strokeWidth="3"/>
                                            </g>
                                            <defs>
                                                <clipPath id="clip0_3147_942">
                                                    <rect width="30" height="30" fill="white"/>
                                                </clipPath>
                                            </defs>
                                        </svg>
                                    </div>
                                    <MenuImageBox
                                        item_image={item_image}
                                        noImage={noImage}
                                    />
                                </div>
                            </div>
                            
                            <Variants
                                name={name}
                                description={description}
                                price={price}
                                variant_1_name={variant_1_name}
                                variant_2_name={variant_2_name}
                                variant_3_name={variant_3_name}
                                variant_1_price={variant_1_price}
                                variant_2_price={variant_2_price}
                                variant_3_price={variant_3_price}
                            />
                            
                            <div className="food-categories">
                                {category_1 && <label style={{backgroundColor: category_1_color}} className="category-1">{category_1}</label>}
                                {category_2 && <label style={{backgroundColor: category_2_color}} className="category-2">{category_2}</label>}
                                {category_3 && <label style={{backgroundColor: category_3_color}} className="category-3">{category_3}</label>}
                            </div>
                            <div className='food-option'>
                                {(option_1_name || option_2_name || option_3_name) && (
                                    <div>
                                        {option_1_name && <label>{`${option_1_name} ${(Number(option_1_price))?.toFixed(2)?.replace(/^0+/,"") || ''}`}</label>}
                                        {option_2_name && <label>{`${option_2_name} ${(Number(option_2_price))?.toFixed(2)?.replace(/^0+/,"") || ''}`}</label>}
                                        {option_3_name && <label>{`${option_3_name} ${(Number(option_3_price))?.toFixed(2)?.replace(/^0+/,"") || ''}`}</label>}
                                    </div>
                                )}
                                {(option_4_name || option_5_name) && (
                                    <div>
                                        {option_4_name && <label>{`${option_4_name} ${(Number(option_4_price))?.toFixed(2)?.replace(/^0+/,"") || ''}`}</label>}
                                        {option_5_name && <label>{`${option_5_name} ${(Number(option_5_price))?.toFixed(2)?.replace(/^0+/,"") || ''}`}</label>}
                                    </div>
                                )}
                            </div>
                        </div>
                    )
                })}
            </div>    
        </div>
    )
};

export default MenuResults;
