/* eslint-disable no-useless-escape */
import * as Yup from 'yup';
import { phoneValidation, zipValidation } from './common';

export const addVendorSchema = Yup.object({
    name: Yup.string().trim().required('Name is required'),
    address: Yup.string().trim().required('Address is Required'),
    phone: phoneValidation,
    city: Yup.string().trim().required('City is required'),
    state: Yup.string().required('State is required'),
    zipcode: zipValidation, 
    county: Yup.string().trim().required('County is required'),
    vendor_type: Yup.string().trim().required('Type is required'),
    high_schools: Yup.number().nullable()
})