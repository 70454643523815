import ApiModal from "components/common/apiErrorModal";
import Button from "components/common/button";
import CrowdChowLogo from "components/common/crowdChowLogo";
import Input from "components/common/input";
import { AuthContext } from "contexts/auth";
import { AuthActions } from "contexts/reducers/auth";
import { useFormik } from "formik";
import { useIsDisabled } from "hooks/useIsDisabled";
import Cookies from "js-cookie";
import { useContext, useEffect,  useState } from "react";
import { useNavigate } from "react-router-dom";
import { loginSchema } from "util/validationSchema/loginSchema";

const Login = () => {
    const {loginUser, loading} = useContext(AuthContext);
    //const [wasError, setWasError] = useState<boolean>(false);

    const [error, setError] = useState('');
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues:{
            email: '',
            password: ''
        },
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: loginSchema,
        onSubmit: async (values: any) => {
        onLogin(values);
        }
    });
    const {values, errors, handleChange, handleSubmit, setErrors} = formik;

    const onLogin = async (form: any) => {
        const result = await loginUser({...form, x_device_token: process.env.REACT_APP_X_DEVICE_TOKEN || ""});

        if(result?.response?.data?.error){
            setError(result.response.data.message);
        }
    }

    useEffect(() => {
        const token = Cookies.get("crowd-chow-token");
        const impersonationToken = Cookies.get("impersonation_token");
        if(token || impersonationToken){
            navigate("/dashboard", {state: {active: ''}})
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[]);


    // useEffect(()=>{
    //     console.log(error);
    //     setWasError((prev)=> !prev);
    // }, [error]);

    const isDisabled = useIsDisabled(values, errors);
    const isLoading = loading.includes(AuthActions.SET_USER);


 
    return (
        <>
            <div className="login_page">
                <div className="login_page_container">
                    <div className="login_page_container_logo">
                        <CrowdChowLogo />
                    </div>
                    <h2 className="login_page_container_pageTitle">
                        Vendor Portal
                    </h2>
                    <form onSubmit={handleSubmit} className="login_page_container_login-form">
                        <div className="login_page_container_login-form_field-group">
                            <Input 
                                label="Email"
                                onChange={handleChange} 
                                value={values.email} 
                                type="email" 
                                name="email"
                                placeholder={errors.email as string || "Enter Email"}
                                setErrors={setErrors}
                                errors={errors}
                                isError={!!errors.email}
                            />
                        </div>
                        <div className="login_page_container_login-form_field-group">
                            <Input 
                                label="Password"
                                onChange={handleChange} 
                                value={values.password} 
                                type="password" 
                                name="password" 
                                placeholder={errors.password as string || "Enter Password"}
                                setErrors={setErrors}
                                isError={!!errors.password}
                                errors={errors}
                                showHidePassword
                            />
                        </div>
                        <Button 
                            type="submit"
                            buttonName="Log In"
                            loading={isLoading}
                            isActiveDisabled={isDisabled}
                        />
                    </form>
                </div>
                    <button onClick={() => navigate("/forgot-password")} className="login_page_forgot-btn">Forgot Password?</button>
            </div>

            {error && (
                <ApiModal 
                    setIsShow={() => setError('')}
                    title="Authentication Failed"
                    message={error}
                />
            )}
        </>
    )
}

export default Login;