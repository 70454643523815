import BottomBar from "components/common/bottomBar";
import { TabPanel, Tabs} from "components/common/tabs";
import { useContext, useEffect, useRef, useState } from "react";
import { putVenue } from "services/venue";
import { useNavigate, useParams } from "react-router-dom";
import VenueMap from "./map";
import { Skeleton } from "components/common/skeleton";
import BackButton from "components/backButton";
import Details from "./details";
import { useRoleId } from "hooks/useRoleId";
import { VendorContext } from "contexts/vendor";
import { VenueContext } from "contexts/venues";
import { VendorActions } from "contexts/reducers/vendor";
import { VenueActions } from "contexts/reducers/venue";

export const DEFAULT_POSITIONS = {
    station_1_formatted: {
        x: 0, 
        y: 0
    },
    stataion_1_placed: false,
    station_2_formatted: {
        x: 0, 
        y: 0
    },
    stataion_2_placed: false,
    station_3_formatted: {
        x: 0, 
        y: 0
    },
    stataion_3_placed: false,
    station_4_formatted: {
        x: 0, 
        y: 0
    },
    stataion_4_placed: false,
    concession_1_formatted: {
        x: 0,
        y: 0
    },
    concession_1_placed: false
}

const Venue = () => {
    const roleId = useRoleId();
    const isSuperAdmin = roleId === 1;
    const {fetchVenueDetails, loading} = useContext(isSuperAdmin ? VendorContext : VenueContext as any) as any;

    const venueDetailsLoading = loading[isSuperAdmin ? VendorActions.GET_VENUE_DETAILS : VenueActions.GET_VENUE_DETAILS];
    const vendorRef = useRef<any>(null);
    const navigate = useNavigate();
    const { id } = useParams();
    
    const [tooltip, setTooltip] = useState(false);
    const [positions, setPositions] = useState(DEFAULT_POSITIONS);
    const [edit, setEdit] = useState(false);
    
    const [updateLoading, setUpdateLoading] = useState(false);
    const [, setLoading] = useState(false);
    const [venueDetails, setVenueDetails] = useState<any>(null!);
    const [activeTab, setActiveTab] = useState(0);
    const [stationCords, setStationCords] = useState({});

    const {vendor} = venueDetails || {};
   

    const onReplaceMap = async () => {
        const {x, y} = document.getElementById("map-container")?.getBoundingClientRect() as any;
        
        // console.log('currVals', stBound.x, stBound.y);
        const newCords = Object.keys(stationCords).reduce((obj: any, currentKey:any, index:number) => {
            const cords = document.getElementById(currentKey)?.getBoundingClientRect() as any || {};
            const defaultCords = document.getElementById(`default_${currentKey}`)?.getBoundingClientRect() as any || {};

            let newValue = null;
            
            if(cords.x === defaultCords.x && cords.y === defaultCords.y){
                newValue = {
                    x: 0,
                    y: 0,
                }
            }else{
                newValue = {
                    x: Math.abs(currentKey === 'concession_1' ? cords.x - x + 5 : cords.x - x + 0),
                    y: Math.abs(currentKey === 'concession_1' ? cords.y - y + 14 : cords.y - y - 1),
                }
            }
            
            obj[currentKey] = `${newValue.x}, ${newValue.y}`;
            return obj;
        },stationCords);

        const isEmpty = Object.keys(stationCords).length === 0;
        const data = isEmpty ? {
            station_1: "0, 0",
            station_2: "0, 0",
            station_3: "0, 0",
            station_4: "0, 0",
            concession_1: "0, 0",
        }
        :{
            station_1: newCords.station_1,
            station_2: newCords.station_2,
            station_3: newCords.station_3,
            station_4: newCords.station_4,
            concession_1: newCords.concession_1
        }
    
        setStationCords({});

        const res:any = await updateVenue(data);
        
        if(res?._status?.code === 200){
            setEdit(false);
      
            
        }

        return res;
    }

    const updateVenue = async (updateVenueReq: any) => {
        setUpdateLoading(true);
        
        const res = await putVenue({
            venue_id: id,
            ...updateVenueReq
        });
        setUpdateLoading(false);
        if(res?._status?.code === 200){
            setVenueDetails(res.data);
            setEdit(false);
        }

        return res;
    }

    const fetchDetails = async (id: string) => {
        setLoading(true);
        const res = await fetchVenueDetails(id);
        setLoading(false);

        if(res?._status?.code === 200){
            
            setVenueDetails(res.data);
        }
    }

    useEffect(() => {

        if(id){
            fetchDetails(id);
        }
        
        /* eslint-disable react-hooks/exhaustive-deps */
    },[]);

    useEffect(() => {
        if(vendor?.name && vendorRef?.current){
            setTooltip(vendorRef?.current ? vendorRef?.current?.scrollWidth - vendorRef?.current?.clientWidth > 0 : false)
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[vendor, vendorRef?.current])

    return (
        <>
            <div className="super-admin">
                <BackButton onClick={() => navigate(-1)} />
                <div className={`sideTextRotate ${tooltip ? 'show-full-name' : ''}`}>
                    <p ref={vendorRef} className={`${tooltip ? '' : ''}`}>{vendor?.name ? <><span>Venue:</span> {vendor?.name}</> : <Skeleton height={30} />}</p>
                </div>
                <div className="super-admin_admin-detail-container">
                    <Tabs
                        tabs={['Details', 'Map']}
                        activeTab={activeTab}
                        onClickTab={(tabIndex: number) => {
                            setActiveTab(tabIndex);
                            if(activeTab !== tabIndex){
                                setPositions(DEFAULT_POSITIONS);
                                setEdit(false);
                            }
                        }}
                        customClass=""
                    >
                        <TabPanel
                            selected={activeTab === 0}
                        >
                            <Details
                                vendor={vendor} 
                                updateVenue={updateVenue}
                                updateLoading={updateLoading}
                                venueDetailsLoading={venueDetailsLoading}
                                setLoading={setLoading}
                                setVenueDetails={setVenueDetails}
                            />
                        </TabPanel>

                        <TabPanel
                            selected={activeTab === 1}
                        >
                            <VenueMap 
                                setPositions={setPositions}
                                positions={positions}
                                venueDetails={venueDetails}
                                activeTab={activeTab}
                                setEdit={setEdit}
                                edit={edit}
                                onReplaceMap={onReplaceMap}
                                updateVenue={updateVenue}
                                setStationCords={setStationCords}
                                stationCords={stationCords}
                                updateLoading={updateLoading}
                            />
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
            <BottomBar />
        </>
    )
}

export default Venue;