import Vendors from "components/vendors";
import { useLocation } from "react-router-dom";




const VendorList = () => {
    const location = useLocation();
    const fromLocation = location.state;

    return (
        <>
            <Vendors fromLocation = {fromLocation}/>
        </>
    )
}

export default VendorList;