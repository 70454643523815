import { useContext, useEffect, useState } from "react";
import DeleteIcon from "components/common/deleteIcon";
import EditIcon from "components/common/editIcon";
import Button from "components/common/button";
import SearchInput from "components/common/searchInput";
import moment from "moment";
import Pagination from "components/common/pagination";
import { useDebouncedCallback } from "use-debounce";
import AddStaffModal from "./addStaffModal";
import DeleteModal from "components/vendors/vendorsCore/deleteModal";
import BottomBar from "components/common/bottomBar";
import { Admin } from "interfaces/vendor";
import EditStaffModal from "./editStaffModal";
import TableSkeleton from "components/common/skeleton/tableSkeleton";
import NoData from "components/noData";
import { UserContext } from "contexts/user";
import { UserActions } from "contexts/reducers/user";
import { Table, TableBody, TableData, TableHead, TableHeading, TableRow } from "components/common/table";
import { useLimit } from "hooks/useLimit";
import SaveIcon from "components/venue/SaveIcon";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import MoreDataSkeleton from "components/common/moreDataSkeleton";

const enum ModalType {
    Login = 'login',
    Edit = 'edit',
    Delete = 'Delete',
    Add = 'add'
}

const MODAL_INIT_STATE = {
    type: '',
    data: null!
}

interface IProps {
    vendor_id: number | null;
}

const Staff = ({vendor_id}: IProps) => {
    const { fetchStaff, deleteStaff, staffList, loading, addStaff, updateStaff, staffPage:page, setStaffPage:setPage, staffQueryParam:queryParams, setStaffQueryParam:setQueryParams } = useContext(UserContext);

    const {limit, skeletonsCount} = useLimit();

    const [success, setSuccess] = useState(false);
    const [flash, setFlash] = useState<any>(-1);
    const [modal, setModal] = useState<any>(MODAL_INIT_STATE);
    const [search, setSearch] = useState('');

    const fetchMoreStaff = async () => {
        const nextPage = Math.ceil(queryParams.offset/queryParams.limit)+1
        setPage(nextPage)
        const res = await fetchStaff(({...queryParams, offset: nextPage*queryParams.limit}));
        return res;
    }

    const { handleScroll, scrollTop } = useInfiniteScroll(fetchMoreStaff, 560);
  

    const onClicArrow = (page: number) => {

        getStaff({
            ...queryParams,
            offset: page*limit
        })
        setPage(page)
    }
    const debouncedSearch = useDebouncedCallback((value: string) => {
        setPage(0)
        getStaff({
            ...queryParams,
            offset: 0,
            search: value
        })
    },600)

    const onSearch = (e: any) => {
        setSearch(e.target.value);
        debouncedSearch(e.target.value);
    }

    const onDeleteStaff = async () => {
        const res = await deleteStaff(modal.data.id);

        if(res?.status === 204){
            setModal(MODAL_INIT_STATE);
        }
    }

    const onClickEdit = (staff: any) => {
        setModal({
            type: ModalType.Edit,
            data: staff
        });
    }

    const onAddStaff = async (data: any) => {
        const res = await addStaff({...data, vendor_id});
        return res;
    }

    const getStaff = async (params: any) => {
        setQueryParams(params);
        const res = await fetchStaff({...params, vendor_id});
        return res;
    }

    const onSetFlash = async (recordIndex: number) => {

        const newPage = Math.floor(recordIndex / 10);
        setPage(newPage);
        if(newPage > 0 && page !== newPage){
            await getStaff({
                ...queryParams,
                offset: newPage*queryParams.limit,
    
            })
        }

        const offsetIndex = recordIndex % queryParams.limit;

        setFlash(offsetIndex);
        setTimeout(() => {
            setFlash(-1);
        },1000);
    }

    useEffect(() => {
        !admins.length && getStaff({...queryParams, search:''});

        /* eslint-disable react-hooks/exhaustive-deps */
    },[]);




    useEffect(()=>{
        const sucessTimer = setTimeout(()=>{
             if(success)
             setSuccess(false);
         },3000);
 
 
         return ()=>{
             clearTimeout(sucessTimer)
         }
         
    }, [success])



    
    const { admins, _count } = staffList;
    const noOfPages = Math.ceil(_count > queryParams.limit ? _count/queryParams.limit : 0);
    const getStaffLoading = loading[UserActions.SET_STAFF];
    const isDeleteLoading = loading[UserActions.DELETE_STAFF];
    const isEditStaffLoading = loading[UserActions.EDIT_STAFF];

    return (
        <>  

            
            {
            <div className={`save-order ${success ? "save-order-animation" : ""}`}>
                 <span>   <SaveIcon /> Saved</span>
                </div>
            }


            <div className="super-admin_addVendorContainer_action">
                <div className="addVendor-btn">
                    <Button
                        buttonName="Add Staff"
                        onClick={() => setModal({...modal, type: ModalType.Add})}
                    />
                </div>
                
                <SearchInput isClear onChange={onSearch} value={search} placeholder="Search"/>
            </div>
            <div className="admin-tab-table">
                <Table>
                    <TableHead>
                        <TableHeading customClass="name">
                            Name
                            {/* <svg className="polygon-table" xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
                                <path d="M7 10.0154L12.259 1L1.74104 1L7 10.0154ZM7 12L0 0L14 0L7 12Z" fill="#FDC13D"></path>
                            </svg> */}
                        </TableHeading>
                        <TableHeading customClass="email">
                            Email
                        </TableHeading>
                        <TableHeading customClass="last-login">
                            Last login
                        </TableHeading>
                        <TableHeading customClass="option">
                            Options
                        </TableHeading>
                    </TableHead>
                    <TableBody
                        onScroll={(e: any) => handleScroll(e, admins.length < _count)}
                        isShowBlur={_count > skeletonsCount && scrollTop < 10}
                    >
                        {getStaffLoading ? (
                            <TableSkeleton count={skeletonsCount} />
                        ) : (
                            admins?.length > 0 ? (
                                admins.map((staff: Admin, index: number) => {
                                    const { last_name, first_name, email, last_login } = staff;
                                    return (
                                        <TableRow nonClickableRow flash={index === flash} cursorPointer={true}>
                                            <TableData customClass="name">
                                                {`${last_name}${first_name ? `, ${first_name}` : ''}`}
                                            </TableData>
                                            <TableData customClass="email">
                                                {email}
                                            </TableData>
                                            <TableData customClass="last-login">
                                                {last_login ? moment(last_login).format("M/D/YY") : 'Not Yet'}
                                            </TableData>
                                            <TableData customClass="option">
                                                <EditIcon 
                                                    onClick={() => onClickEdit(staff)}
                                                />
                                                <DeleteIcon
                                                    onClick={() => setModal({type: ModalType.Delete, data: staff})}
                                                />
                                                
                                            </TableData>
                                        </TableRow>
                                    )
                                })
                            ):(
                                <NoData description="No Staff" />
                            )
                        )}
                        <MoreDataSkeleton 
                            isShow={loading[UserActions.SET_MORE_STAFF]}
                        />
                    </TableBody>
                </Table>
            </div>
            <Pagination
                activePage={page}
                count={noOfPages}
                onClickArrow={onClicArrow}
            />

            {modal.type === ModalType.Delete && (
                <DeleteModal
                    onSuccess={onDeleteStaff}
                    setIsShow={() => setModal(MODAL_INIT_STATE)}
                    disabled={isDeleteLoading}
                    title="Delete Staff"
                    name={`${modal.data?.first_name || ''} ${modal.data?.last_name || ''}`}
                />
            )}

            {modal.type === ModalType.Add && (
                <AddStaffModal
                    loading={loading[UserActions.ADD_STAFF]}
                    addAdmin={onAddStaff}
                    closeModal={() => setModal(MODAL_INIT_STATE)}
                    setFlash={onSetFlash}
                />
            )}

            {modal.type === ModalType.Edit && (
                <EditStaffModal
                    closeModal={() => setModal(MODAL_INIT_STATE)}
                    data={modal.data}
                    updateAdmin={(data: any) => updateStaff({...data, staff_id: modal.data.id})}
                    loading={isEditStaffLoading}
                    title="Edit Staff"
                    setSuccess = {setSuccess}
                />
            )}

            <BottomBar
                showAdd
                showSearch
                onClickAdd={() => setModal({...modal, type: ModalType.Add})}
            />
        </>
    )
}

export default Staff;