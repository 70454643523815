import { Modal, ModalHeader } from "components/common/modal";
import { useContext } from "react";
import { Table, TableBody, TableData, TableHead, TableHeading, TableRow } from "components/common/table";
import TableSkeleton from "components/common/skeleton/tableSkeleton";
import NoData from "components/noData";
// import Pagination from "components/common/pagination";
import { VendorContext } from "contexts/vendor";
import { VendorActions } from "contexts/reducers/vendor";
import moment from "moment";


const InvoiceHistoryModal = ({closeModal}: any) => {
    const {invoiceHistory, loading} = useContext(VendorContext);

    // const [page, setPage] = useState(0);

    // const onPageChange = (page: number) => {
    //     setPage(page);
    // }

    const onClickAmount = (url: string) => {
        url && window.open(url, '_blank', 'noopener,noreferrer');
    }
    const historyLoading = loading[VendorActions.SET_INVOICE_HISTORY];

    const {invoices} = invoiceHistory;
    return (
        <>
            <Modal 
                customClass="refund-modal invoice-history-modal"
                setShowModal={() => closeModal()}
                width={917}
            >
                <ModalHeader>
                    <p className="app-modal-title">Invoice Send History</p>
                </ModalHeader>
                <div style={{marginTop: 59}} className="invoice-history-table">
                    <Table>
                        <TableHead>
                            <TableHeading customClass="date-sent">
                                Date Sent
                            </TableHeading>
                            <TableHeading customClass="invoice-description">
                                Description
                            </TableHeading>
                            <TableHeading customClass="email">
                                Email
                            </TableHeading>
                            <TableHeading customClass="total">
                                Total
                            </TableHeading>
                        </TableHead>

                        <TableBody>
                            {historyLoading ? (
                                <TableSkeleton count={4} />
                            ) : (
                                invoices?.length > 0 ? (
                                    invoices.map((invoice: any) => {
                                        const { email, date, invoice_amount, description, invoice_url} = invoice || {};
                                        return (
                                            <TableRow nonClickableRow>
                                                <TableData customClass="plan">
                                                    {date ? moment(date).format("M/D/YY") : ''}
                                                </TableData>
                                                <TableData customClass="invoice-description">
                                                    {description}
                                                </TableData>
                                                <TableData customClass={`email`}>
                                                    {email}
                                                </TableData>
                                                <TableData cursorPointer onClickData={() => onClickAmount(invoice_url)} customClass="total">
                                                    {`$${invoice_amount}`}
                                                </TableData>
                                            </TableRow>
                                        )
                                    })
                                ):(
                                    <NoData description="No Invoices" />
                                )
                            )}
                        </TableBody>
                    </Table>
                    {/* {subscriptions?.length && (
                        <Pagination
                            count={Math.ceil(invoices.length/LIMIT)}
                            activePage={page}
                            onClickArrow={onPageChange}
                        />
                    )} */}
                </div>
            </Modal>
        </>
    )
};

export default InvoiceHistoryModal;
