import { UserContextProvider } from "interfaces/user";

export enum UserActions {
    SET_SUPER_ADMINS = 'SET_SUPER_ADMINS',
    SET_LOADING = 'SET_LOADING',
    RESET_LOADING = 'RESET_LOADING',
    SET_USER_LOADING = 'SET_USER_LOADING',
    RESET_USER_LOADING = 'RESET_USER_LOADING',
    GET_VENDORS = 'GET_VENDORS',
    DELETE_VENDOR = 'DELETE_VENDOR',
    ADD_VENDOR = 'ADD_VENDOR',
    GET_CROWDS = 'GET_CROWDS',
    GET_ORDERS = 'GET_ORDERS',
    GET_ORDER_DATAILS = 'GET_ORDER_DATAILS',
    UPDATE_ORDER_STATUS = 'UPDATE_ORDER_STATUS',
    SET_ACTIVE_TAB = 'SET_ACTIVE_TAB',
    ADD_ADMIN = 'ADD_ADMIN',
    EDIT_ADMIN = 'EDIT_ADMIN',
    DELETE_ADMIN = 'DELETE_ADMIN',
    ADD_SUPER_ADMIN = 'ADD_SUPER_ADMIN',
    EDIT_SUPER_ADMIN = 'EDIT_SUPER_ADMIN',
    DELETE_SUPER_ADMIN = 'DELETE_SUPER_ADMIN',
    SET_ADMINS = 'SET_ADMINS',
    SET_STAFF = 'SET_STAFF',
    DELETE_STAFF = 'DELETE_STAFF',
    ADD_STAFF = 'ADD_STAFF',
    EDIT_STAFF = 'EDIT_STAFF',
    SET_ADMINS_MORE = 'SET_ADMINS_MORE',
    SET_MORE_SUPER_ADMINS = 'SET_MORE_SUPER_ADMINS',
    GET_MORE_VENDORS = 'GET_MORE_VENDORS',
    GET_MORE_CROWDS = 'GET_MORE_CROWDS',
    SET_MORE_STAFF = 'SET_MORE_STAFF',
    GET_MORE_ORDERS = 'GET_MORE_ORDERS'
}


export const UserReducer = (state: UserContextProvider, action: any) => {
    const { type, payload } = action
    switch (type) {
        case UserActions.EDIT_SUPER_ADMIN:
            return {
                ...state,
                superAdmins: {
                    ...state.superAdmins,
                    admins: state.superAdmins.admins.map((admin: any) => admin.id === payload.admin_id ? ({...admin, ...payload}) : admin)
                }
            }
        case UserActions.SET_SUPER_ADMINS:{
            const {offset, ...restPayload} = payload;
            return {
                ...state,
                superAdmins: {
                    ...restPayload,
                    admins: offset ? [...state.superAdmins.admins, ...restPayload.admins] : restPayload.admins
                }
            }
        }
        case UserActions.SET_USER_LOADING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [payload]: true
                }
            }
        case UserActions.RESET_USER_LOADING:
            const newLoadingState = {
                ...state.loading
            }
            delete newLoadingState[payload];
            return {
                ...state,
                loading: newLoadingState
            }
        case UserActions.GET_VENDORS:{
            const {offset, ...restPayload} = payload;
            return {
                ...state,
                vendorList: {
                    ...restPayload,
                    vendors: offset ? [...state.vendorList.vendors, ...restPayload.vendors] : restPayload.vendors
                }
            }
        }
        case UserActions.DELETE_VENDOR:
            return {
                ...state,
                vendorList: {
                    ...state.vendorList,
                    vendors: state.vendorList.vendors.filter((item: any) => item.id !== payload),
                    _count: state.vendorList._count - 1
                }
            }
        case UserActions.ADD_VENDOR:
            return {
                ...state,
                vendorList: {
                    ...state.vendorList,
                    vendors: [payload, ...state.vendorList.vendors],
                    _count: state.vendorList._count + 1
                }
            }
        case UserActions.GET_CROWDS:{
            const {offset, ...restPayload} = payload;
            return {
                ...state,
                crowdList: {
                    ...restPayload,
                    customers: offset ? [...state.crowdList.customers, ...restPayload.customers] : restPayload.customers
                }
            }
        }
        case UserActions.GET_ORDERS:
            const {offset, ...restPayload} = payload;
            return {
                ...state,
                orderList: {
                    ...restPayload,
                    orders: offset ? [...state.orderList.orders, ...restPayload.orders] : restPayload.orders
                }
            }
        case UserActions.GET_ORDER_DATAILS:
            return {
                ...state,
                orderDetails: payload
            }
        case UserActions.SET_ACTIVE_TAB:
            return {
                ...state,
                activeTab: payload
            }
        case UserActions.ADD_SUPER_ADMIN:
            return{
                ...state,
                superAdmins: payload
            }
        case UserActions.DELETE_SUPER_ADMIN:
            return {
                ...state,
                superAdmins: {
                    ...state.superAdmins,
                    admins: state.superAdmins.admins.filter((item: any) => item.id !== payload),
                    _count: state.superAdmins._count - 1
                }
            }
        case UserActions.EDIT_ADMIN:
            return {
                ...state,
                adminList: {
                    ...state.adminList,
                    admins: state.adminList.admins.map((admin: any) => admin.id === payload.admin_id ? ({...admin, ...payload}) : admin)
                }
            }
        case UserActions.SET_ADMINS: {
            const {offset, ...restPayload} = payload;

            return {
                ...state,
                adminList: {
                    ...restPayload,
                    admins: offset ? [...state.adminList.admins, ...restPayload.admins] : restPayload.admins
                }
            }
        }
        case UserActions.DELETE_ADMIN:
            return {
                ...state,
                adminList: {
                    ...state.adminList,
                    admins: state.adminList.admins.filter((item: any) => item.id !== payload),
                    _count: state.adminList._count - 1
                }
            }
        case UserActions.ADD_ADMIN:
            return{
                ...state,
                adminList: payload
            }
        case UserActions.DELETE_STAFF:
            return{
                ...state,
                staffList: {
                    ...state.staffList,
                    admins: state.staffList.admins.filter((item: any) => item.id !== payload),
                    _count: state.staffList._count - 1
                }
            }
        case UserActions.ADD_STAFF:
            return{
                ...state,
                staffList: payload
            }
        case UserActions.SET_STAFF:
            return {
                ...state,
                staffList: payload
            }
        case UserActions.EDIT_STAFF:
            return {
                ...state,
                staffList: {
                    ...state.staffList,
                    admins: state.staffList.admins.map((item: any) => item.id === payload.staff_id ? ({...item, ...payload}) : item)
                }
            }
        default:
            return state
    }
}
