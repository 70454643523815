import { useContext } from "react";
import TableSkeleton from "components/common/skeleton/tableSkeleton";
import NoData from "components/noData";
import { ReportActions } from "contexts/reducers/report";
import { ReportContext } from "contexts/report";
import { getFullName } from "util/getFullName";
import { Table, TableBody, TableData, TableHead, TableHeading, TableRow } from "components/common/table";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import MoreDataSkeleton from "components/common/moreDataSkeleton";

const LIMIT = 15;

const RefundOrders = ({page, fetchMoreData}:any) => {
    const {refundOrders, loading} = useContext(ReportContext);

    const {_orders, _orders_count} = refundOrders;
    const getRefundOrdersLoading = loading[ReportActions.SET_REFUND_ORDER];

    const {handleScroll, scrollTop} = useInfiniteScroll(fetchMoreData);
    
    const orders = _orders.slice(page*LIMIT, (page+1)*LIMIT);

    return (
        <>
            <div className="refund-table">
                <Table>
                    <TableHead>
                        <TableHeading customClass="order-number">
                            Order Number
                        </TableHeading>
                        <TableHeading customClass="customer-name">
                            Customer
                        </TableHeading>
                        <TableHeading customClass="refund-reason">
                            Refund Reason
                        </TableHeading>
                    </TableHead>

                    <TableBody
                        onScroll={(e: any) => handleScroll(e, orders.length < _orders_count)}
                        isShowBlur={_orders_count > 10 && scrollTop < 10}
                    >
                        {getRefundOrdersLoading ? (
                            <TableSkeleton count={10} />
                        ) : (
                            _orders?.length > 0 ? (
                                orders.map((order: any) => {
                                    const { order_id, cancellation_reason, users } = order;
                                    const fullName = getFullName(users.first_name, users.last_name);
                                    return (
                                        <TableRow nonClickableRow>
                                            <TableData customClass="order-number">
                                                {order_id}
                                            </TableData>
                                            <TableData customClass="customer-name">
                                                {fullName}
                                            </TableData>
                                            <TableData customClass="refund-reason">
                                                {cancellation_reason}
                                            </TableData>
                                        </TableRow>
                                    )
                                })
                            ):(
                                <></>
                            )
                        )}
                        <MoreDataSkeleton 
                            isShow={page > 0 && loading[ReportActions.SET_MORE_REFUND_ORDER]}
                        />
                    </TableBody>
                </Table>
                
            </div>
            {!getRefundOrdersLoading && _orders?.length === 0 ? (
                <NoData description="No Items" />
            ):(
                <></>
            )}
        </>
    )
};

export default RefundOrders;
