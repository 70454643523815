const LoginIcon = ({onClick}: any) => {
    return (
        <svg className="switch-icon cursor-pointer" onClick={onClick} xmlns="http://www.w3.org/2000/svg" width="24" height="27" viewBox="0 0 24 27" fill="none">
            <mask id="mask0_3554_5358" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="27">
                <path d="M23.8984 0H0.898438V27H23.8984V0Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_3554_5358)">
                <path d="M20.6664 1.54305H8.95338C8.1836 1.53745 7.44303 1.83741 6.89412 2.37714C6.34521 2.91686 6.03279 3.65227 6.02539 4.42205V10.079H7.4034V4.42205C7.40736 4.01457 7.57276 3.6253 7.86333 3.3396C8.1539 3.05391 8.5459 2.89511 8.95338 2.89805H20.6654C21.0729 2.89511 21.4649 3.05391 21.7555 3.3396C22.046 3.6253 22.2114 4.01457 22.2154 4.42205V22.578C22.2114 22.9855 22.046 23.3748 21.7555 23.6605C21.4649 23.9462 21.0729 24.105 20.6654 24.102H8.95338C8.5459 24.105 8.1539 23.9462 7.86333 23.6605C7.57276 23.3748 7.40736 22.9855 7.4034 22.578V16.043H6.02539V22.581C6.03279 23.3508 6.34521 24.0862 6.89412 24.626C7.44303 25.1657 8.1836 25.4656 8.95338 25.46H20.6654C21.4352 25.4656 22.1758 25.1657 22.7247 24.626C23.2736 24.0862 23.586 23.3508 23.5934 22.581V4.42205C23.586 3.65245 23.2737 2.91719 22.725 2.37749C22.1763 1.8378 21.436 1.53772 20.6664 1.54305Z" fill="white"/>
                <path d="M11.3508 18.1588C11.2834 18.2241 11.2298 18.3022 11.1932 18.3886C11.1565 18.475 11.1377 18.5679 11.1377 18.6618C11.1377 18.7556 11.1565 18.8485 11.1932 18.9349C11.2298 19.0213 11.2834 19.0995 11.3508 19.1648C11.4878 19.2979 11.6713 19.3724 11.8623 19.3724C12.0534 19.3724 12.2369 19.2979 12.3738 19.1648L18.0958 13.5818C18.1627 13.5162 18.2157 13.4379 18.252 13.3515C18.2882 13.2652 18.3069 13.1724 18.3069 13.0788C18.3069 12.9851 18.2882 12.8924 18.252 12.806C18.2157 12.7197 18.1627 12.6414 18.0958 12.5758L12.4188 6.94878C12.2819 6.81566 12.0984 6.74121 11.9073 6.74121C11.7163 6.74121 11.5328 6.81566 11.3958 6.94878C11.329 7.0144 11.276 7.09268 11.2397 7.17905C11.2035 7.26541 11.1848 7.35813 11.1848 7.45178C11.1848 7.54544 11.2035 7.63815 11.2397 7.72452C11.276 7.81088 11.329 7.88915 11.3958 7.95477L15.7958 12.3138H1.85785C1.77121 12.3104 1.68477 12.3245 1.60372 12.3553C1.52267 12.3861 1.44867 12.4329 1.38616 12.493C1.32365 12.5531 1.27392 12.6252 1.23995 12.705C1.20599 12.7848 1.18848 12.8706 1.18848 12.9573C1.18848 13.044 1.20599 13.1298 1.23995 13.2096C1.27392 13.2894 1.32365 13.3614 1.38616 13.4215C1.44867 13.4816 1.52267 13.5285 1.60372 13.5593C1.68477 13.5901 1.77121 13.6042 1.85785 13.6008H16.0228L11.3508 18.1588Z" fill="white"/>
            </g>
        </svg>
    )
};

export default LoginIcon;
