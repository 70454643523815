import { Modal, ModalHeader, ModalBody, ModalFooter } from "components/common/modal";
import { AuthContext } from "contexts/auth";
import { AuthActions } from "contexts/reducers/auth";
import { useContext, useEffect } from "react";

interface Props {
    closeModal: Function;
    handleSubmit: Function;
    isDisabled?: boolean;
    firstName?: string;
    lastName?: string;
    name?: string;
}

const LoginAsModal = ({
    closeModal, 
    firstName,
    lastName,
    handleSubmit, 
    isDisabled,
    name
}: Props) => {
    const { loading } = useContext(AuthContext);

    const isLoading = loading.includes(AuthActions.GET_IMPERSONATION_TOKEN);
    const fullName = name || `${firstName || ''} ${lastName || ''}`;

    useEffect(() => {
        const keyHandler = async (e: any) => {
            if(e.keyCode === 13){
                await handleSubmit();
            }
        }
        window.addEventListener('keydown', keyHandler, false);
        return () => {
            window.removeEventListener('keydown', keyHandler, false);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    },[]);




    return (
        <Modal
            setShowModal={() => closeModal()}
        >
            <ModalHeader>
                <p className="app-modal-title">Login as {fullName || ''}</p>
            </ModalHeader>
            <ModalBody>
                <p style={{color: '#fff'}}>
                    Switch to {fullName || ''}'s account to troubleshoot issues? You can return to your account by using the sidebar menu.
                </p>
            </ModalBody>

            <ModalFooter 
                onCancel={closeModal}
                onSubmit={() => handleSubmit()}
                submitActiveDisabled={isDisabled}
                submitLoading={isLoading}
                submitButtonName="Switch"
            />
        </Modal>
    )
};

export default LoginAsModal;
