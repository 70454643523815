import Label from "components/common/label";
import { ReportContext } from "contexts/report";
import { useContext, useRef } from "react"
import moment from "moment";
import timezone from 'moment-timezone'
import { ReportActions } from "contexts/reducers/report";
import { useIsSuperAdmin } from "hooks/useIsSuperAdmin";
import {components} from 'react-select';
import { useDebouncedCallback } from "use-debounce";
import { SearchableSelect } from "components/common/customReactSelect/customReactSelect";

const SingleValueFormat = ({children, ...props}: any) => {
    const selectedOption = props.getValue()?.length > 0 ? props.getValue()[0] : null;
    return (
        <components.SingleValue {...props}>
            <div className="option-box">
                <p className="event-name" style={{marginBottom: 0}}>{selectedOption?.label || ''}</p>
            </div>
        </components.SingleValue>
    )
}

const EventDropdown = ({isItems, selectedVenue, selectedEvent, setSelectedEvent, onSelectEvent}: any) => {
    const {selectedVendor , fetchAllEvents, fetchReprtEvents, eventList, loading, setSeeAll, eventOpen, setEventOpen, setAllEvents, allEvents } = useContext(ReportContext);

    const dropdownRef = useRef<any>();
    const isSuperAdmin = useIsSuperAdmin();
    // const [queryParams, setQueryParams] = useState({
    //     offset: 0,
    //     limit: 10
    // });

    const onClickEvent = (event: any) => {
        // setOpen(false);
        const selectedEv = events.find((ev: any) => ev.id === event.value);
        selectedEv && setSelectedEvent(selectedEv);
        
        const timeZone = timezone.tz.guess();
        selectedEv && onSelectEvent(selectedEv, timeZone);
        // setEventOpen && setEventOpen(undefined);
    }

    const onClickSeeAll = () => {
        setSeeAll(true);
        setAllEvents({
            events: [],
            _count: 0
        })
    }



    // const onOpen = () => {
    //     setSelectedEvent(null!);
    
    //     resetOrders({ orders: [],
    //         _count:0}) 
        
    //     setEventDetails({
    //         event: null!,
    //         event_sales: null!,
    //         items: [],
    //         formattedItems: [],
    //         printFormattedItems: []
    //     });
       
    // }

    const formatOptionLabel = ({ value }: any) => {
        const selectedEv = events?.find((ev: any) => ev.id === value);
        if(!selectedEv){
            return <></>;
        }
        const { id, name, date, start_date, start_time } = selectedEv;
        let eventDate = null;
        if(date){
            eventDate = date;
        }else if(start_date && start_time){
            eventDate = `${start_date} ${start_time}`;
        }
        return (
            <div key={id} onClick={() => onClickEvent(value)} className="option-box">
                <p className="event-name">{name || ''}</p>
                <p className="event-date">{eventDate ? moment(eventDate).format("dddd, MMMM D") : ''}</p>
            </div>
        )
    };

    const {events} = isSuperAdmin ? allEvents : eventList;
    // const {events} = allEvents;




    const isDisbaled = !selectedVenue || loading[ReportActions.GET_EVENTS];
    const eventItems = events?.slice(0,10);

    const groupedOptions = [
        {
          label: 'Last 10 Events',
          options: eventItems?.map((event: any) => ({...event, label: event.name, value: event.id})),
        }
    ];

    const formatGroupLabel = (data: any) => {
        return (
            <div className="header">
                <p className="title">{data.label}</p>
                <a onClick={onClickSeeAll} href="#/" className="seeAll">See All</a>
            </div>
          )
    };
    
    // const dropdownToggle = () => {
    //     setEventOpen((prev: boolean) => !prev) 
    //     setVendorOpen(false);
    //     setVenueOpen(false);
    // }

    const placeHolderText = eventOpen ? "" : "Select Event";
  

    const debouncedSearch = useDebouncedCallback ( async (value: string) => {
        
    isSuperAdmin
    ? await fetchAllEvents({venue_id: selectedVenue?.id, vendor_id: isSuperAdmin && selectedVendor ? selectedVendor.id : null, search: value })
    : await fetchReprtEvents({ venue_id: selectedVenue?.id, search: value });
     
    
     },100)
    
    const onTextChange = (val: any)=> {
      debouncedSearch(val);    

    }

    return (
        <div ref={dropdownRef} className="event-dropDown-container custom-dropdown">
            <Label disabled={isDisbaled} text="Event" inputLabel />
            <div className={`${ eventOpen  ? 'report-event-dropdown' : ''}`}>
                {/* <ReactSelect
                    placeholder={placeHolderText}
                    value={selectedEvent ? {label: selectedEvent.name, value: selectedEvent.id} : null}
                    onChange={(value: any) => onClickEvent(value)}
                    options={groupedOptions}
                    formatOptionLabel={formatOptionLabel}
                    isOpen={eventOpen}
                    setIsOpen={setEventOpen}
                    formatGroupLabel={formatGroupLabel}
                    SingleValue={SingleValueFormat}
                    onOpen={onOpen}
                    onTextChange={onTextChange}
                /> */}
                <SearchableSelect
                    value={selectedEvent ? {label: selectedEvent.name, value: selectedEvent.id} : null}
                    placeholder={placeHolderText}
                    onChange={(value: any) => onClickEvent(value)}
                    options={groupedOptions}
                    formatOptionLabel={formatOptionLabel}
                    formatGroupLabel={formatGroupLabel}
                    SingleValue={SingleValueFormat}
                    isOpen={eventOpen}
                    setIsOpen={setEventOpen}
                    onTextChange={onTextChange}
                />
            </div>
            {eventOpen && (
                <div className="overlay" onClick={() => setEventOpen(false)}></div>
            )}
        </div>
    )
};

export default EventDropdown;
