export enum VendorStatusColors {
    CREATED = '#106CD0',
    WELCOME = '#B712C9',
    VP_ONBOARDING = '#FDC13D',
    NEEDS_REVIEW = '#E94929',
    ONBOARDING = '#27C1C9',
    SCHEDULED='#490A68',
    ACTIVE = '#41BB86',
    AT_RISK= '#23D414',
    LAPSED = '#c1b6b6',
}

export enum VendorStatus {
    CREATED = 'Created',
    WELCOME = 'Welcome',
    VP_ONBOARDING = 'VP Onboarding',
    NEEDS_REVIEW = 'Needs Review',
    ONBOARDING = 'Onboarding',
    SCHEDULED='Scheduled',
    ACTIVE = 'Active',
    AT_RISK= 'At Risk',
    LAPSED = 'Lapsed',

}