import { FC, useContext, useRef, useState } from "react";
import { SuperAdminReportContext } from "contexts/superAdminReport";
import moment from "moment";
import { SuperAdminReportActions } from "contexts/reducers/superAdminReports";
import TableSkeleton from "../../TableSkeleton";
import { useNavigate } from "react-router-dom";
import { getClassName } from "hooks/useCalculateLength";


interface Props {
  activeTab?: any
}


const EventsTableBody:FC<Props> = ({activeTab}) => {
  const {
    eventList,
    fetchReprtEvents,
    eventQueryParams,
    setReportReducer,
    loading,
    datesQueryParams
  } = useContext(SuperAdminReportContext);
  const fetchingEventsRef = useRef<any>(false);
  const contRef = useRef<HTMLDivElement | null>(null);

  const navigate = useNavigate();

  const [scrollTopVal, setScrollTopVal] = useState(0);

  const isRecordsRemaining = eventList.events.length < eventList.count;
  const handleScroll = async (e: any) => {
    const { scrollTop, scrollHeight } = e.target;
    setScrollTopVal(scrollTop);

    const isFetching = fetchingEventsRef.current;
    if (scrollTop > 0 && isRecordsRemaining && !isFetching && !isLoading) {
      const scrollValue = scrollTop + 495;
      const threshold = scrollHeight - scrollHeight * 0.15;

      if (scrollValue >= threshold) {
        fetchingEventsRef.current = true;

        
        if(activeTab === 0) {
        const newQueryParams = {
          ...eventQueryParams,
          offset:
            eventQueryParams.limit *
            (Math.ceil(eventQueryParams.offset / eventQueryParams.limit) + 1),
        };
        setReportReducer(
          SuperAdminReportActions.SET_EVENT_QUERY_PARAMS,
          newQueryParams
        );
        await fetchReprtEvents(newQueryParams);
        fetchingEventsRef.current = false;
        }else {

          const newQueryParams = {
            ...datesQueryParams,
            offset:
              datesQueryParams.limit *
              (Math.ceil(datesQueryParams.offset / datesQueryParams.limit) + 1),
          };
          setReportReducer(
            SuperAdminReportActions.SET_DATES_QUERY_PARAMS,
            newQueryParams
          );
          await fetchReprtEvents(newQueryParams);
          fetchingEventsRef.current = false;


        }



      }
    }
  };

  const isLoading = loading[SuperAdminReportActions.GET_EVENTS];

  return (
    <div ref={contRef} className="singEvent" onScroll={handleScroll}>




      { activeTab === 0 && eventQueryParams.offset === 0  && isLoading ? (
        <>
          <TableSkeleton />
          <TableSkeleton />
          <TableSkeleton />
          <TableSkeleton />
          <TableSkeleton />
        </>
      ) : activeTab === 1 && datesQueryParams.offset === 0  && isLoading ? (
        <>
          <TableSkeleton />
          <TableSkeleton />
          <TableSkeleton />
          <TableSkeleton />
          <TableSkeleton />
        </>
      )  :   eventList.events.length > 0 ? (
        [...eventList.events, { isLoading: true }].map(
          (event: any, index: number) => {
            const {
              avg_sales,
              donations,
              items,
              sales,
              vendor_name,
              venue_logo,
              is_live,
              name,
              start_date,
              start_time,
              isLoading,
            } = event;
            const donationClass = getClassName(donations?.replace(".", "")?.length);
            const avgSaleClass = getClassName(avg_sales?.replace(".", "")?.length)
            const time = moment(`${start_date} ${start_time}`).format("h:mm A");
            const formttedDate = moment(start_date).calendar(null, {
              // when the date is closer, specify custom values
              lastWeek: "M/D/YY",
              lastDay: "[Yesterday]",
              sameDay: "[Today]",
              nextDay: "[Tomorrow]",
              nextWeek: "M/D/YY",
              sameElse: "M/D/YY",
            });
            return (
              <>
                {!isLoading ? (
                  <div key={index} className="event-detail-row">
                    <div
                      onClick={() => {
                        navigate("/reports/details", {
                          state: {
                            vendor_name: event.vendor_name,
                            venue_name: event.venue_name,
                            event_id: event.id,
                          },
                        });
                      }}
                      style={{ cursor: "pointer" }}
                      className="event-detail"
                    >
                      <div className="school-logo">
                        <img src={venue_logo} alt="img" />
                      </div>
                      <div className="event-name-date">
                        <p className="school-name">{name}</p>
                        <p className="event-date">
                          {formttedDate}, {time}
                        </p>
                      </div>
                      {is_live && (
                        <span className="live-event-label">Live Event</span>
                      )}
                    </div>

                    <div className="school-sales">
                      <p className="school-name">{vendor_name}</p>
                      <div className="event-sales">
                        <div>{sales}</div>
                        <p>Sales</p>
                      </div>
                    </div>

                    <div className="donations-avg">
                      <div className="event-sales">
                        <div className={donationClass}>{donations}</div>
                        <p>Donations</p>
                      </div>
                      <div className="event-sales">
                        <div className={avgSaleClass}>{avg_sales}</div>
                        <p>Avg Sale</p>
                      </div>
                    </div>

                    <div className="total-items">
                      <div className="event-sales">
                        <div>{items || 0}</div>
                        <p>Items</p>
                      </div>
                    </div>
                  </div>
                ) : isRecordsRemaining ? (
                  <TableSkeleton key={index} />
                ) : (
                  <></>
                )}
              </>
            );
          }
        )
      )
      
      
      
      
      
      
      : (
        <p className="no-event">No Events</p>
      )}


      
      
      
      {eventList.events.length > 0 && scrollTopVal < 10 && (
        <div className="more-data"></div>
      )}
    </div>
  );
};

export default EventsTableBody;
