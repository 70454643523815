import MoreDataBlur from "../moreDataBlur";

interface IProps {
    children: any,
    customClass?: string;
    onScroll?: Function;
    isShowBlur?: boolean;
}

const TableBody = ({children, customClass, onScroll, isShowBlur=false}: IProps) => {
    const isLargerHeight = window.innerHeight > 1100;
    return (
        <>
            <div onScroll={(e) => onScroll && onScroll(e)} className={`${customClass || 'tbody'} ${isLargerHeight ? 'larger-height' : ''}`}>
                {children}
            </div>
            <MoreDataBlur isShow={isShowBlur} />
        </>
    )
};

export default TableBody;
