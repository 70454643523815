import config from 'config/config';
import axios from 'services/core/axios';
import { getQueryString } from 'util/getQueryString';


export const getDashboardData = async (dataReq: any) => {
    const queryString = getQueryString(dataReq);

    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/dashboard/statistics${queryString}`);
        return res?.data

    } catch (error) {
        return error;
    }
}



