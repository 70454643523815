import { IReportContext } from "interfaces/reports"

export enum ReportActions {
    SET_REPORT_LOADING = 'SET_REPORT_LOADING',
    RESET_REPORT_LOADING = 'RESET_REPORT_LOADING',
    GET_EVENTS = 'GET_EVENTS',
    GET_EVENT_ITEMS = 'GET_EVENT_ITEMS',
    GET_EVENT_SALES = 'GET_EVENT_SALES',
    GET_VENUES = 'GET_VENUES',
    GET_ALL_EVENTS = 'GET_ALL_EVENTS',
    SET_SELECTED_VENUE = 'SET_SELECTED_VENUE',
    GET_EVENT_DETAILS = 'GET_EVENT_DETAILS',
    SET_SELECTED_EVENT = 'SET_SELECTED_EVENT',
    SET_REFUND_ORDER = 'SET_REFUND_ORDER',
    SET_ITEMS_SELECTED_EVENT = 'SET_ITEMS_SELECTED_EVENT',
    GET_ORDERS = 'GET_ORDERS',
    SET_ORDERS= 'SET_ORDERS', 
    GET_ORDER_DETAIL = 'GET_ORDER_DETAIL',
    UPDATE_ORDER_STATUS= ' UPDATE_ORDER_STATUS',
    SET_SELECTED_VENDOR = 'SET_SELECTED_VENDOR',
    GET_MORE_ORDERS = 'GET_MORE_ORDERS',
    SET_EVENT_LIST= "SET_EVENT_LIST",
    SET_VENUE_LIST='SET_VENUE_LIST',
    SET_ALL_ORDERS = 'SET_ALL_ORDERS',
    GET_VENDOR_VENUE_EVENT = "GET_VENDOR_VENUE_EVENT",
    SET_MORE_REFUND_ORDER = 'SET_MORE_REFUND_ORDER'
}


export const ReportReducer = (state: IReportContext, action: any) => {
    const { type, payload } = action
    switch (type) {
        case ReportActions.SET_REPORT_LOADING:
            
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [payload]: true
                }
            }
        case ReportActions.RESET_REPORT_LOADING:
            const newLoadingState = {
                ...state.loading
            }
            delete newLoadingState[payload];
            return {
                ...state,
                loading: newLoadingState
            }
        case ReportActions.GET_EVENTS:
            return {
                ...state,
                eventList: payload
            }

            case ReportActions.SET_VENUE_LIST:
                return {
                    ...state,
                    venueList: payload
                }

            case ReportActions.GET_ORDER_DETAIL:
            return {
                ...state,
                orderDetail: payload

            }

        case ReportActions.GET_EVENT_ITEMS:
            return {
                ...state,
                eventItems: payload
            }
        case ReportActions.GET_VENUES:
            return {
                ...state,
                venueList: payload
            }
        case ReportActions.GET_ALL_EVENTS:
            return {
                ...state,
                allEvents: payload
            }

        case ReportActions.SET_EVENT_LIST: 
        return {...state, 
                eventList: payload
        }    

        case ReportActions.SET_ALL_ORDERS: 
        return {
            ...state, 
            allOrders: payload
        }   

        case ReportActions.GET_ORDERS:{

            const {offset, ...restPayload} = payload;
            return {
                ...state,
                allOrders: offset === 0
                ? {_count:restPayload?.count , orders: restPayload?.orders }
                : {...state.allOrders, orders: [...state.allOrders.orders, ...restPayload?.orders]}
            } 
        }

        case ReportActions.GET_EVENT_SALES:
            return {
                ...state,
                eventDetails: {
                    ...payload,
                    event_sales: {
                        ...payload?.event_sales,
                        ...(payload?.event_sales?.gross_sales !== undefined && {gross_sales: payload.event_sales.gross_sales.toString()}),
                        ...(payload?.event_sales?.refund_fees !== undefined && {refund_fees: payload.event_sales.refund_fees.toString()}),
                        ...(payload?.event_sales?.refunds !== undefined && {refunds: payload.event_sales.refunds.toString()}),
                        ...(payload?.event_sales?.total_service_fee !== undefined && {total_service_fee: payload.event_sales.total_service_fee.toString()}),
                        ...(payload?.event_sales?.total_tax !== undefined && {total_tax: payload.event_sales.total_tax.toString()}),
                        ...(payload?.event_sales?.total_tips !== undefined && {total_tips: payload.event_sales.total_tips.toString()}),
                        ...(payload?.event_sales?.average_order_total !== undefined && {average_order_total: payload.event_sales.average_order_total.toString()}),
                        ...(payload?.event_sales?.total_orders !== undefined && {total_orders: payload.event_sales.total_orders.toString()})
                    }
                }
            }
        case ReportActions.SET_SELECTED_VENUE:
            return {
                ...state,
                selectedVenue: payload
            }
        case ReportActions.GET_EVENT_DETAILS:
            return {
                ...state,
                reportDates: {
                    ...payload,
                    events_sales: {
                        ...payload?.events_sales,
                        ...(payload?.events_sales?.gross_sales !== undefined && {gross_sales: payload.events_sales.gross_sales.toString()}),
                        ...(payload?.events_sales?.refund_fees !== undefined && {refund_fees: payload.events_sales.refund_fees.toString()}),
                        ...(payload?.events_sales?.refunds !== undefined && {refunds: payload.events_sales.refunds.toString()}),
                        ...(payload?.events_sales?.total_service_fee !== undefined && {total_service_fee: payload.events_sales.total_service_fee.toString()}),
                        ...(payload?.events_sales?.total_tax !== undefined && {total_tax: payload.events_sales.total_tax.toString()}),
                        ...(payload?.events_sales?.total_tips !== undefined && {total_tips: payload.events_sales.total_tips.toString()}),
                        ...(payload?.events_sales?.delivery_fee !== undefined && {delivery_fee: payload.events_sales.delivery_fee.toString()}),
                        ...(payload?.event_sales?.average_order_total !== undefined && {average_order_total: payload.event_sales.average_order_total.toString()}),
                        ...(payload?.event_sales?.total_orders !== undefined && {total_orders: payload.event_sales.total_orders.toString()})
                    }
                }
            }
        case ReportActions.SET_SELECTED_EVENT:
            return {
                ...state,
                selectedEvent: payload
            }
            case ReportActions.SET_SELECTED_VENDOR:
            return {
                ...state,
                selectedVendor: payload
            }
        case ReportActions.SET_REFUND_ORDER: {
            const {offset, ...restPayload} = payload;
            return {
                ...state,
                refundOrders: {
                    ...restPayload,
                    _orders: offset ? [...state.refundOrders._orders, ...restPayload._orders] : restPayload._orders,
                    _items: offset ? [...state.refundOrders._items, ...restPayload._items] : restPayload._items
                }
            }
        }
        case ReportActions.SET_ITEMS_SELECTED_EVENT:
            return {
                ...state,
                itemsSelectedEvent: payload
            }

            case ReportActions.GET_VENDOR_VENUE_EVENT:
            return {
                ...state,
                vendorsVenuesEvents : payload
            }




        default:
            return state
    }








}