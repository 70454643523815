import Button from "components/common/button";
import { Modal } from "components/common/modal";

interface Props {
  message?: string;
  setIsShow: Function;
  closeButtonName?: string;
  title?: string;
  successBtnName?: string;
  onSuccess: Function;
  disabled?: boolean;
  disabledModal?: boolean;
  loading?: boolean;
  yesBtnText?: string;
  name: string;
}

const DeleteModal = ({
  loading,
  title,
  onSuccess,
  disabled,
  disabledModal,
  message,
  setIsShow,
  yesBtnText = "Delete",
  name,
}: Props) => {
  return (
    <div className={"mainContent-backDrop"}>
      <Modal
        className={disabledModal ? "disabled" : ""}
        setShowModal={() => setIsShow()}
      >
        <div className="app-modal-title">
          <span className="rounded"></span>
          <h6 className="modal__header-title">{title || "Access Denied"}</h6>
        </div>
        <div className="modal__body">
          {name ? (
            <p className="BodyParagraph">
              Are you sure you want to delete{" "}
              <span className="vendor-name">{name}</span>?
            </p>
          ) : (
            <p className="BodyParagraph">
              {message || "Something went wrong. Please try again later."}
            </p>
          )}
        </div>
        <div className="app-modal-footer">
          {/* <button onClick={() => setIsShow()} disabled={disabled} className="bttn bttn_cancel delete-cancel">Cancel</button> */}
          <Button
            buttonName="Cancel"
            customClass="bttn_cancel delete-cancel"
            onClick={setIsShow}
            disabled={disabled}
          />

          {/* <button className="bttn delete-cancel">Delete</button> */}
          <Button
            buttonName={yesBtnText}
            customClass="delete-cancel"
            onClick={onSuccess}
            disabled={loading || disabled}
            loading={loading}
          />
        </div>
      </Modal>
    </div>
  );
};
export default DeleteModal;
