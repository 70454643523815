import Collapse from "@kunukn/react-collapse";
import { useContext, useEffect, useState } from "react";
import AccordianBody from "./accordianBody";
import { TableData, TableRow } from "components/common/table";
import Button from "components/common/button";
import { PromotionalResourcesContext } from "contexts/promotionalResources";
import Dropdown from "../common/dropdown";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import CopyToClipboardTooltip from "components/common/copyToClipboard";

interface IProps {
  text: string;
  isCopy?: boolean;
}

const TemplateRow = ({ text, isCopy = false }: IProps) => {
  const { signageQrUrl, venueList } = useContext(PromotionalResourcesContext);
  const { promotional_templates } = signageQrUrl || {};

  const { onClickCopy, isCopied } = useCopyToClipboard();

  const [open, setOpen] = useState(false);
  const [venueId, setVenueId] = useState<any>(null);

  const onChangeVenue = (e: any) => {
    if (e.target.value) {
      const venueId = parseInt(e.target.value);
      setVenueId(venueId);
    }
  };

  const clickCopyAnnouncement = (e: any) => {
    e?.stopPropagation();
    const announcementEl = document
      .querySelector("#accordian-body")
      ?.querySelector("p");
    onClickCopy(announcementEl, promotional_templates?.email_blast_event);
  };

  useEffect(() => {
    if (venueList.venues?.length > 0) {
      setVenueId(venueList?.venues[0].id);
    }
  }, [venueList]);

  const selectedVenue = signageQrUrl?.venues?.find(
    (item: any) => item.id === venueId
  );
  const dropdownVenue = venueList?.venues?.find(
    (item: any) => venueId === item.id
  );

  text = text?.replaceAll("[venue_url]", selectedVenue?.url_one);
  text = text?.replaceAll("[venue_vendor]", dropdownVenue?.name);
  return (
    <div className="table-row-collapse">
      <TableRow onClickRow={() => setOpen((prev: boolean) => !prev)}>
        <TableData customClass="purpose">Event Announcement</TableData>
        <TableData
          onClickData={(e: any) => e?.stopPropagation()}
          isTooltip={false}
          customClass="vendor-venue"
        >
          {/* Norman Public Schools */}
          <Dropdown
            onChange={onChangeVenue}
            options={venueList?.venues?.map((item: any) => ({
              value: item.id,
              label: item?.name,
            }))}
            placeholder="Choose..."
            value={venueId}
          />
        </TableData>

        <TableData isTooltip={false} customClass="options">
          {isCopy ? (
            <Button buttonName="Copy" onClick={clickCopyAnnouncement} />
          ) : (
            <></>
          )}
        </TableData>

        <TableData isTooltip={false} customClass="email-blast-arrow arrow">
          <svg
            className={open ? "rotate-dropdown-icon" : ""}
            xmlns="http://www.w3.org/2000/svg"
            width="41"
            height="41"
            viewBox="0 0 41 41"
            fill="none"
          >
            <g clip-path="url(#clip0_8046_183)">
              <mask
                id="mask0_8046_183"
                style={{ maskType: "luminance" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="0"
                width="41"
                height="41"
              >
                <path
                  d="M40.4512 40.451L40.4512 0L0.000171661 -1.76817e-06L0.000169893 40.451L40.4512 40.451Z"
                  fill="white"
                />
              </mask>
              <g mask="url(#mask0_8046_183)">
                <g filter="url(#filter0_d_8046_183)">
                  <path
                    d="M27.9484 25.8116L30.8174 22.9426L20.2244 12.3496L9.63139 22.9426L12.5004 25.8116L20.2244 18.0876L27.9484 25.8116Z"
                    fill="white"
                  />
                </g>
              </g>
            </g>
            <defs>
              <filter
                id="filter0_d_8046_183"
                x="6.63135"
                y="12.3496"
                width="27.186"
                height="19.4629"
                filterUnits="userSpaceOnUse"
                colorInterpolationFilters="sRGB"
              >
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix
                  in="SourceAlpha"
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                  result="hardAlpha"
                />
                <feOffset dy="3" />
                <feGaussianBlur stdDeviation="1.5" />
                <feColorMatrix
                  type="matrix"
                  values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.557 0"
                />
                <feBlend
                  mode="normal"
                  in2="BackgroundImageFix"
                  result="effect1_dropShadow_8046_183"
                />
                <feBlend
                  mode="normal"
                  in="SourceGraphic"
                  in2="effect1_dropShadow_8046_183"
                  result="shape"
                />
              </filter>
              <clipPath id="clip0_8046_183">
                <rect
                  width="40.451"
                  height="40.451"
                  fill="white"
                  transform="translate(0 40.4512) rotate(-90)"
                />
              </clipPath>
            </defs>
          </svg>
        </TableData>
      </TableRow>
      <Collapse
        isOpen={open}
        className={`${!open ? "template-accordian" : ""}`}
      >
        <AccordianBody open={open} text={text} />
      </Collapse>
      <CopyToClipboardTooltip isCopied={isCopied} />
    </div>
  );
};

export default TemplateRow;
