import { AuthContext } from "contexts/auth"
import { useContext } from "react"

const SearchButtonMobile = ({onClick, showSearch}: any) => {
    const { openSidebar, openSearchbar, setOpenSearchbar, setOpenSideBar } = useContext(AuthContext);
    return (
        <div 
            className={`searchBar-btn`} 
            onClick={() => {
                if(!showSearch){
                    return;
                }
                setOpenSearchbar(!openSearchbar);
                setOpenSideBar(openSidebar ? false : openSidebar);
                
                onClick();  
            }}
        >
            {showSearch && (
                <svg xmlns="http://www.w3.org/2000/svg" width="54" height="54" viewBox="0 0 54 54" fill="none">
                    <circle cx="27" cy="27" r="27" fill={openSearchbar ? "#0075C9" : "#FDC13D"}/>
                    <mask id="mask0_3906_1281" style={{maskType: "luminance"}} maskUnits="userSpaceOnUse" x="15" y="15" width="24" height="24">
                    <path d="M39 15H15V39H39V15Z" fill="white"/>
                    </mask>
                    <g mask="url(#mask0_3906_1281)">
                    <path d="M38.2068 36.6759L30.9418 29.4109C32.2925 27.6715 32.9296 25.4827 32.7232 23.2901C32.5168 21.0975 31.4825 19.066 29.831 17.6091C28.1794 16.1523 26.0347 15.3797 23.8335 15.4486C21.6323 15.5175 19.5401 16.4227 17.9828 17.9799C16.4256 19.5372 15.5204 21.6294 15.4515 23.8306C15.3826 26.0318 16.1552 28.1765 17.6121 29.828C19.0689 31.4796 21.1004 32.5139 23.293 32.7202C25.4856 32.9266 27.6744 32.2896 29.4138 30.9389L36.6758 38.2069C36.8789 38.4099 37.1542 38.524 37.4413 38.524C37.7284 38.524 38.0038 38.4099 38.2068 38.2069C38.4099 38.0039 38.5239 37.7285 38.5239 37.4414C38.5239 37.1543 38.4099 36.8789 38.2068 36.6759ZM24.1188 30.6009C22.8368 30.6009 21.5836 30.2207 20.5176 29.5085C19.4517 28.7962 18.6208 27.7839 18.1302 26.5995C17.6396 25.415 17.5113 24.1117 17.7614 22.8543C18.0115 21.5969 18.6288 20.442 19.5354 19.5354C20.4419 18.6289 21.5969 18.0116 22.8543 17.7614C24.1116 17.5113 25.415 17.6397 26.5994 18.1303C27.7838 18.6209 28.7962 19.4517 29.5084 20.5177C30.2207 21.5837 30.6008 22.8369 30.6008 24.1189C30.599 25.8375 29.9155 27.4851 28.7002 28.7003C27.485 29.9155 25.8374 30.599 24.1188 30.6009Z" 
                        fill={`${openSearchbar ? '#fff' : '#5F5F5F'}`}
                    />
                    </g>
                </svg>
            )}
        </div>
    )
}

export default SearchButtonMobile;