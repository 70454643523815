import Label from "components/common/label";
import "react-datepicker/dist/react-datepicker.css";
import UTCDatePicker from "../dates/datePick";

const StartDateDropdown = ({ value, onSelectDate, disabled }: any) => {
 // const date = moment(value).format("M/D/YY");
//  console.log("hey",date, value);

  return (
    <>
      <div className="input-container">
        <Label customClass="input-label" disabled={disabled} text={"From"} />
        <div className="input-container">

                <UTCDatePicker 
                    startDate = {value} onChange = {onSelectDate} selected = {value} 
                    maxDate={new Date(new Date().toISOString().split("T")[0])}
                    disabled={disabled}
                    calendarClassName="datepicker-main-dates-section"
                />


          <svg
            className="calendar-side-arrow"
            xmlns="http://www.w3.org/2000/svg"
            width="15"
            height="9"
            viewBox="0 0 15 9"
            fill="none"
          >
            <path
              d="M1 0.773438L7.452 7.22544L13.9 0.773438"
              stroke="#303030"
              strokeWidth="1.5"
              strokeLinecap="round"
            />
          </svg>
        </div>
      </div>

    </>
  );
};

export default StartDateDropdown;
