import { ChangeEvent, useState } from "react";
import Label from "../label";
import CancelButton from "../inputCancelBtn";
import { deleteObjectProp } from "util/deleteObjectProp";
import ShowPassword from "assets/images/show.png";
import HidePassword from "assets/images/hide.png";
interface IProps {
  label?: string;
  type?: string;
  placeholder?: string;
  name?: string;
  value?: any;
  onChange?: (e: ChangeEvent<any>) => void;
  required?: boolean;
  onFocus?: (e: any) => void;
  onBlur?: (e: any) => void;
  pattern?: string;
  customClass?: string;
  customStyles?: any;
  width?: number | string;
  height?: number | string;
  isError?: boolean;
  onClickCross?: Function;
  errors?: any;
  setErrors?: Function;
  disabled?: boolean;
  maxLength?: number;
  minLength?: number;
  showHidePassword?: boolean;
  max?: any;
  min?: string | number;
}

const Input = ({
  placeholder,
  label,
  name,
  value,
  onChange,
  type,
  onFocus,
  required = false,
  pattern,
  customClass,
  onBlur,
  customStyles,
  width,
  height,
  isError,
  onClickCross,
  errors,
  setErrors,
  disabled,
  maxLength,
  minLength,
  showHidePassword,
  max,
  min,
}: IProps) => {
  const [show, setShow] = useState(false);

  const onClickCancel = () => {
    if (onClickCross) {
      onClickCross();
    } else {
      const newErrors = name ? deleteObjectProp(errors, name) : null;
      newErrors && setErrors && setErrors(newErrors);
    }
  };

  const is_error = isError || customClass?.includes("input-error");
  type = type === "password" && show ? "text" : type;

  const handleChange = (e: any) => {
    onChange && onChange(e);
    onClickCancel();
  };

  return (
    <div
      className="input-container"
      style={{ ...(width && { width }), ...(height && { height }) }}
    >
      {label && (
        <Label customClass="input-label" disabled={disabled} text={label} />
      )}
      <div className="input-container">
        <input
          style={{
            ...(customStyles && customStyles),
          }}
          className={`input ${is_error ? "input-error" : ""} ${
            customClass || ""
          }`}
          type={is_error ? "text" : type || "text"}
          placeholder={placeholder || ""}
          name={name}
          value={is_error ? placeholder : value}
          onChange={handleChange}
          onFocus={onFocus}
          onBlur={onBlur}
          pattern={pattern}
          required={required}
          disabled={disabled}
          maxLength={maxLength}
          minLength={minLength}
          max={max}
          min={min}
        />

        {is_error && (
          <div className="cancel-icon-container">
            <CancelButton
              onClick={() => onClickCancel()}
              customClass="input-cancel-icon"
              isError
            />
          </div>
        )}
        {!is_error && showHidePassword && (
          <div
            onClick={() => setShow((show) => !show)}
            className="show-hide-password-icon-container"
          >
            {show ? (
              <img
                width="25"
                height="25"
                src={HidePassword}
                alt="Hide Password"
              />
            ) : (
              <img
                width="25"
                height="25"
                src={ShowPassword}
                alt="Show Password"
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Input;
