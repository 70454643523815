import { ISuperAdminDasboardContext } from "interfaces/superAdminDashboard";
import { createContext, useReducer } from "react";
import { superAdminDashboardActions, superAdminDashboardReducer } from "./reducers/superAdminDashboard";
import { getDashboardData } from "services/SuperAdminDasboard";
import timezone from 'moment-timezone';

const initialState =  {
    loading: {
        GET_DASHBOARD_DATA: true
    },

     dashboardData: {
            weekly_sales: 0,
            new_leads: 0,
            live_events: 0,
            total_customers: 0,
            events: [],
            current_live_events: [],
            events_count: 0,
            data: [],
            data_count: 0
    
        },

    upCompingEvents: {},
    setLoading: () => {},
    resetLoading: ()=>{},
    fetchDashboardData: ()=>{},
    setDashboardReducer: () => {},

    dashboardQueryParams: {
    
        timezone: timezone.tz.guess(),
        filter: 'MOST_ACTIVE_VENDORS_LAST_30_DAYS',
        limit: 15,
        offset: 0,
        event_filter: 'UPCOMING_EVENT_NEXT_30_DAYS',
        event_offset:0,
        event_limit:15,


    },


}





export const SuperAdminDashboardContex = createContext<ISuperAdminDasboardContext>(null!)



const SuperAdminDashboarContextdProvider = ({children}: any) => {
    
const [{loading, dashboardData, dashboardQueryParams}, dispatch] = useReducer(superAdminDashboardReducer,initialState);




const setDashboardData = (data: any, type: string) => {
    dispatch({
        type: type,
        payload: data
    })
}




const fetchDashboardData = async (dashboardReq: any, type: string) => {
    setLoading(type);
    const res = await getDashboardData(dashboardReq);
    resetLoading(type);

    if(res?._status?.code === 200){
        setDashboardData(
            {...res.data, event_offset: dashboardReq?.event_offset || 0, offset: dashboardReq?.offset || 0},
            type
        );
    }

    return res;
}



const setDashboardReducer = (type: string, data: any) => {
    dispatch({
        type: type,
        payload: data
    })
}

const setLoading = (loading: string) => {

    dispatch({type: superAdminDashboardActions.SET_DASHBOARD_LOADING, payload: loading})
   

 
}

const resetLoading = (loading: string) => {
dispatch({type: superAdminDashboardActions.RESET_DASHBOARD_LOADING, payload: loading})


}












return <SuperAdminDashboardContex.Provider value={{
    loading,
    dashboardData,
    setLoading,
    resetLoading,
    fetchDashboardData,
    setDashboardReducer,
    dashboardQueryParams,
    
    }}>

            {children}


</SuperAdminDashboardContex.Provider>



}

export default SuperAdminDashboarContextdProvider

