import {TableData, TableRow} from "components/common/table";
import moment from "moment";

interface IProps {
    data: any;
    nonClickableRow?: boolean;
    onClickRow?: Function;
}

const OrderRow = ({
    data,
    onClickRow
}: IProps) => {

    const {order_id='', total='0.00', date='', items_count=0, status=''} = data || {};
    
    return (
        <>
            <TableRow cursorPointer onClickRow={onClickRow}>
                <TableData customClass="order-no">
                    {order_id}
                </TableData>
                <TableData customClass="order-date">
                    {date ? moment(date).format("M/D/YY") : ''}
                </TableData>
                <TableData cursorPointer customClass="order-items">
                    {items_count}
                </TableData>
                <TableData customClass="total-order">
                    {total}
                </TableData>
                <TableData customClass="order-status">
                    {status}
                </TableData>
                
            </TableRow>
        </>
    )
};

export default OrderRow;
