import { useEffect, useRef } from "react";

interface IProps {
    imgSrc: string;
    closeModal: Function;
}

const ImageModal = ({imgSrc, closeModal}:IProps) => {
    const contRef = useRef<HTMLDivElement | null>(null);
    useEffect(() => {
        const outsideHandler = (e: any) => {
            if(contRef.current?.contains(e.target)){
                return;
            }

            closeModal();
        }

        document.addEventListener("click", outsideHandler, true);

        return () => {
            document.removeEventListener("click", outsideHandler, true)
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[])

    return (
        <div className="show-diagram">
            <div ref={contRef} className="diagram-container">
                <img src={imgSrc} alt="Diagram View" />
            </div>
        </div>
    );
};

export default ImageModal;
