import { BillingStep } from "interfaces/billing";

interface Props {
    venueCount: number;
    setVenueCount: Function;
    venueFees: number;
    confirmVenueQuantity: Function;
    disabled: boolean;
}


const BillingVenue = ({venueCount,setVenueCount,venueFees,confirmVenueQuantity,disabled}: Props) => {
    
    return (
        <div className={disabled?"billingVenue disableVenue":"billingVenue"}>
            <div className="billingVenue_title"> 
                <p className="billingVenue_titleContent">Venues: {venueCount}</p>
            </div>
            <div className="billingVenue_body"> 
                <p className="billingVenue_bodyContent">${venueFees} Due Today</p>
                <div className="billingVenue_quantityBox">
                    <button className="svg_button" onClick={()=>{ venueCount > 1 ? setVenueCount(venueCount-1) : setVenueCount(1) }}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="39" viewBox="0 0 40 39" fill="none" className="billingVenue_quantityBoxRedSvg">
                            <path d="M20.146 39C30.9155 39 39.646 30.2696 39.646 19.5C39.646 8.73045 30.9155 0 20.146 0C9.37644 0 0.645996 8.73045 0.645996 19.5C0.645996 30.2696 9.37644 39 20.146 39Z" fill="#E94929"/>
                            <path d="M20.146 38.5C30.6394 38.5 39.146 29.9934 39.146 19.5C39.146 9.00659 30.6394 0.5 20.146 0.5C9.65259 0.5 1.146 9.00659 1.146 19.5C1.146 29.9934 9.65259 38.5 20.146 38.5Z" stroke="white"/>
                            <g opacity="0.323">
                                <path opacity="0.323" d="M20.146 39C30.9155 39 39.646 30.2696 39.646 19.5C39.646 8.73045 30.9155 0 20.146 0C9.37644 0 0.645996 8.73045 0.645996 19.5C0.645996 30.2696 9.37644 39 20.146 39Z" fill="black"/>
                            </g>
                            <text x="50%" y="55%" textAnchor="middle" fill="#FFFFFF" dominantBaseline="middle" className="billingVenue_svgtext">-</text>
                        </svg>
                    </button>
                    <svg xmlns="http://www.w3.org/2000/svg" width="36" height="34" viewBox="0 0 36 34" fill="none" className="billingVenue_quantityBoxBlueSvg">
                        <path d="M18.646 0H17.646C8.25716 0 0.645996 7.61116 0.645996 17C0.645996 26.3888 8.25716 34 17.646 34H18.646C28.0348 34 35.646 26.3888 35.646 17C35.646 7.61116 28.0348 0 18.646 0Z" fill="#27C1C9"/>
                        <text x="50%" y="55%" textAnchor="middle" fill="#FFFFFF" dominantBaseline="middle" className="billingVenue_quantity">{venueCount}</text>
                    </svg>
                    <button className="svg_button" onClick={()=>{venueCount < 99 && setVenueCount(venueCount + 1)}}>
                        <svg  xmlns="http://www.w3.org/2000/svg" width="40" height="39" viewBox="0 0 40 39" fill="none" className="billingVenue_quantityBoxGreenSvg">
                            <path d="M20.146 39C30.9155 39 39.646 30.2696 39.646 19.5C39.646 8.73045 30.9155 0 20.146 0C9.37644 0 0.645996 8.73045 0.645996 19.5C0.645996 30.2696 9.37644 39 20.146 39Z" fill="#1CC971"/>
                            <path d="M20.146 38.5C30.6394 38.5 39.146 29.9934 39.146 19.5C39.146 9.00659 30.6394 0.5 20.146 0.5C9.65259 0.5 1.146 9.00659 1.146 19.5C1.146 29.9934 9.65259 38.5 20.146 38.5Z" stroke="white"/>
                            <g opacity="0.323">
                                <path opacity="0.323" d="M20.146 39C30.9155 39 39.646 30.2696 39.646 19.5C39.646 8.73045 30.9155 0 20.146 0C9.37644 0 0.645996 8.73045 0.645996 19.5C0.645996 30.2696 9.37644 39 20.146 39Z" fill="black"/>
                            </g>
                            <text x="50%" y="55%" textAnchor="middle" fill="#FFFFFF" dominantBaseline="middle" className="billingVenue_svgtext">+</text>
                        </svg> 
                    </button>
                </div>
            </div>
            <div className="billingVenue_footer"> 
                <button 
                    className="billingVenue_footerContent"
                    type="submit"
                    onClick={()=>confirmVenueQuantity(BillingStep.Venue_Quantity)}
                >
                    Confirm Venue Quantity
                </button>
            </div>
        </div>
    )

}

export default BillingVenue;