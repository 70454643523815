const SortIcon = ({parentCustomStyle, onClick, ascending=false}:any) => {
    return (
        <span className="polygon-table" style={{...(parentCustomStyle && parentCustomStyle)}} onClick={() => onClick && onClick()}>
            {ascending ? (
                <svg style={{transform: 'rotate(180deg)'}} xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
                    <path d="M7 10.0154L12.259 1L1.74104 1L7 10.0154ZM7 12L0 0L14 0L7 12Z" fill="#FDC13D"/>
                </svg>
            ):(
                <svg className="flip" xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
                    <path d="M7 10.0154L12.259 1L1.74104 1L7 10.0154ZM7 12L0 0L14 0L7 12Z" fill="#FDC13D"/>
                </svg>
            )}
        </span>
    )
};

export default SortIcon;
