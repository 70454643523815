import { useEffect, useRef, useState } from "react";

const VenueName = ({name}: {name: string}) => {
    const nameRef = useRef<any>(null);
    const [showTooltip, setTooltip] = useState(false);

    useEffect(() => {
        if(name && nameRef.current){
            setTooltip(nameRef.current ? nameRef.current?.scrollWidth - nameRef.current?.clientWidth > 0 : false)
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[name, nameRef.current]);

    return (
        <>
            <p ref={nameRef}>{name || ''}</p>
            {showTooltip && (
                <span className="truncate-tooltip">{name}</span>
            )}
        </>
    )
};

export default VenueName;
