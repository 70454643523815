export enum BillingStep {
    Venue_Quantity = 'VENUE_QUANTITY',
    Invoicing = 'INVOICING',
}
export enum PayementMethod {
    Bank = 'BANK',
    Card = 'CARD',
}

export interface VendorBillingParams {
    vendor_id: number,
    quantity: number
}

export enum VendorSubscriptionType {
    Venue = "VENUE_SELECT",
    Subscription= "UNLIMITED"
}

export interface PaymentProcessingParams {
    vendor_id: number,
    amount: number,
    number_of_venues?: number,
    payment_method_id: string
}