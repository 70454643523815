import {
  CITY_CHAR_LIMIT,
  EMAIL_CHAR_LIMIT,
  NAME_CHAR_LIMIT,
  USA_STATES,
  VENDOR_NAME_CHAR_LIMIT,
} from "appConstants";
import ApiModal from "components/common/apiErrorModal";
import CheckMarkButton from "components/common/checkMarkButton";
import CrossButton from "components/common/crossButton";
import Input from "components/common/input";
import { Modal, ModalBody, ModalHeader } from "components/common/modal";
import Select from "components/common/select";
import { VendorActions } from "contexts/reducers/vendor";
import { VendorContext } from "contexts/vendor";
import { useFormik } from "formik";
import { useIsDisabled } from "hooks/useIsDisabled";
import { useContext, useState } from "react";
import { deleteObjectProp } from "util/deleteObjectProp";
import { addLeadSchema } from "util/validationSchema/addLeadSchema";

const ROLES = [
  { label: "Superintendent", value: "Superintendent" },
  { label: "Principle", value: "Principle" },
  { label: "Athletic Director", value: "Athletic Director" },
  { label: "Teacher", value: "Teacher" },
  { label: "Other", value: "Other" },
];

const vendorTypes = [
  { label: "District", value: "District" },
  { label: "School", value: "School" },
  { label: "College", value: "College" },
  { label: "Other", value: "Other" },
];

const AddLeadModal = ({
  data,
  title,
  addLead,
  closeModal,
  setFlash,
  isEdit,
  setSuccess
}: any) => {
  const { loading, updateLead } = useContext(VendorContext);

  const [error, setError] = useState("");


  const initialValues = {
    name: data?.name || "",
    vendor_name: data?.vendor_name || "",
    role: data?.role || "",
    number_of_venues: data?.venues || "",
    city: data?.city || "",
    state: data?.state || "",
    email: data?.email || "",
    vendor_type: data?.vendor_type || "",
    high_schools: data?.high_schools || "",
  };

  const addLeadReq = async (data: any) => {
    const res = await addLead(data);

    if (res?._status?.code === 201) {
      closeModal();
      if (setFlash) {
        setFlash(true);
        setTimeout(() => {
          setFlash(false);
        }, 1000);
      }
    }

    if (res?.response?.data?.message || res?.response?.status === 500) {
      setError(
        res.response.data.message ||
          "Something went wrong. Please try again later"
      );
    }
  };

  const updateLeadReq = async (updateData: any) => {
    const res = await updateLead({ ...updateData, lead_id: data.id });

    if (res?._status?.code === 200) {
        setSuccess(true);
        closeModal();
      
      
    }

    if (res?.response?.data?.message || res?.response?.status === 500) {
      setError(
        res.response.data.message ||
          "Something went wrong. Please try again later"
      );
    }
  };

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: addLeadSchema,
    onSubmit: async (values: any) => {
      isEdit ? updateLeadReq(values) : addLeadReq(values);
    },
  });
  const { values, errors, handleChange, handleSubmit, setErrors } = formik;

  const onClickCancel = (fieldName: string) => {
    const newErrors = deleteObjectProp(errors, fieldName);

    newErrors && setErrors && setErrors(newErrors);
  };

 

  const isDisabled = useIsDisabled(values, errors, ["high_schools"]);
  const isLoading =
    loading[VendorActions.ADD_LEAD] || loading[VendorActions.UPDATE_LEAD];

  return (
    <>
      <Modal
        setShowModal={() => closeModal()}
        customClass={`add-modal ${error ? "disabled" : ""}`}
      >
        <ModalHeader>
          <div className="modal-actions">
            <CrossButton onClick={closeModal} disabled={isLoading} />
            <CheckMarkButton
              onClick={handleSubmit}
              width={47}
              height={47}
              activeDisabled={isDisabled}
              disabled={isLoading}
              isForm
            />
          </div>
          <p className="app-modal-title">{title || "New Lead"}</p>
        </ModalHeader>

        <ModalBody>
          <div className="add-lead-form">
            <div className="row">
              <div className="five-columns-sm columns">
                <Input
                  name="name"
                  label="Full Name"
                  placeholder={(errors.name as string) || "Enter Full Name"}
                  value={values.name}
                  onChange={handleChange}
                  isError={!!errors.name}
                  errors={errors}
                  setErrors={setErrors}
                  maxLength={NAME_CHAR_LIMIT}
                />
              </div>
              <div className="seven-columns-sm columns email-input">
                <Input
                  name="email"
                  label="Email"
                  placeholder={(errors.email as string) || "Enter Email"}
                  value={values.email}
                  onChange={handleChange}
                  isError={!!errors.email}
                  errors={errors}
                  setErrors={setErrors}
                  maxLength={EMAIL_CHAR_LIMIT}
                />
              </div>
            </div>

            <div className="row">
              <div className="five-columns-md four-columns-sm columns vendor-name-input">
                <Input
                  label="Vendor Name"
                  placeholder={
                    (errors.vendor_name as string) || "Enter Vendor Name"
                  }
                  name="vendor_name"
                  value={values?.vendor_name}
                  onChange={handleChange}
                  isError={!!errors?.vendor_name}
                  errors={errors}
                  setErrors={setErrors}
                  maxLength={VENDOR_NAME_CHAR_LIMIT}
                />
              </div>

              <div className="four-columns-md four-columns-sm columns">
                <Select
                  label="Vendor Type"
                  placeholder={(errors.vendor_type as string) || "Select Type"}
                  name="vendor_type"
                  value={values?.vendor_type}
                  onChange={handleChange}
                  isError={!!errors?.vendor_type}
                  options={vendorTypes}
                  onClickCross={() => onClickCancel("vendor_type")}
                />
              </div>

              {values.vendor_type === "District" && (
                <div className="three-columns-md four-columns-sm columns">
                  <Input
                    name="high_schools"
                    label={"High Schools"}
                    placeholder={"Enter High Schools"}
                    value={values?.high_schools}
                    isError={!!errors?.high_schools}
                    onClickCross={() => onClickCancel("high_schools")}
                    onChange={handleChange}
                    width="100%"
                  />
                </div>
              )}
            </div>
            <div className="row">
              <div className="four-columns-sm columns">
                <Select
                  label="Role"
                  placeholder={(errors.role as string) || "Select Role"}
                  name="role"
                  value={values?.role}
                  onChange={handleChange}
                  isError={!!errors?.role}
                  options={ROLES}
                  onClickCross={() => onClickCancel("role")}
                />
              </div>
              <div className="four-columns-md six-columns-sm columns of-venues-input">
                <Input
                  label="# of Venues"
                  placeholder={
                    (errors.number_of_venues as string) || "Enter No. of Venues"
                  }
                  name="number_of_venues"
                  value={values?.number_of_venues}
                  onChange={handleChange}
                  isError={!!errors?.number_of_venues}
                  errors={errors}
                  setErrors={setErrors}
                />
              </div>
            </div>
            <div className="row">
              <div className="five-columns-sm columns">
                <Input
                  label="City"
                  placeholder={(errors.city as string) || "Enter City"}
                  name="city"
                  value={values?.city}
                  onChange={handleChange}
                  isError={!!errors?.city}
                  errors={errors}
                  setErrors={setErrors}
                  maxLength={CITY_CHAR_LIMIT}
                />
              </div>
              <div className="four-columns-sm columns state-dropdown">
                <Select
                  label="State"
                  placeholder={(errors.state as string) || "XX"}
                  name="state"
                  value={values?.state}
                  onChange={handleChange}
                  isError={!!errors?.state}
                  options={USA_STATES.map((item: any) => ({
                    label: item,
                    value: item,
                  }))}
                  onClickCross={() => onClickCancel("state")}
                />
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {error && (
        <ApiModal
          title="Add New Lead Failed"
          message={error}
          setIsShow={() => setError("")}
        />
      )}
    </>
  );
};

export default AddLeadModal;
