import config from 'config/config';
import { GetAdminRequest } from 'interfaces/common';
import { AddAdminRequest, AddLeadRequest, AddVendorRequest, EditAdminRequest, LeadsRequest, MenuRequest, PostVenueRequest, ResourceRequest, SendWelcomeEmail, UpdateLeadRequest, VendorDetailsRequest, VendorListRequest } from 'interfaces/vendor';
import axios from 'services/core/axios';
import { getQueryString } from 'util/getQueryString';

export const putAgreement = async () => {

    try {
        const { data: { data } } = await axios.put(`${config.defaults.api_url}/v1/vendors/agreement`);
        return data

    } catch (error) {
        return null
    }
}



export const getVendors = async () => {

    try {
        const { data: { data } } = await axios.get(`${config.defaults.api_url}/v1/vendors`);
        return data

    } catch (error) {
        return null
    }
}

export const postVenues = async (venueData: PostVenueRequest) => {
    const { vendor_id, ...body } = venueData;

    try {
        const { data: { data } } = await axios.post(`${config.defaults.api_url}/v1/vendors/${vendor_id}/venues`, body);
        return data

    } catch (error) {
        return null
    }
}

export const getLibraryResource = async (data: ResourceRequest) => {
    const queryString = Object.keys(data)
        .reduce((currentString: string, currentKey: string, i: number) => {
            const val = data[currentKey as keyof typeof data];
            currentString = val ? `${currentString}${i > 0 ? '&' : '?'}${currentKey}=${val}` : '';

            return currentString;
        }, '');

    try {
        const { data } = await axios.get(`${config.defaults.api_url}/v1/resources/library${queryString}`);
        return data

    } catch (error) {
        return error;
    }
}

export const addMenu = async (menuReq: MenuRequest) => {
    const { confirm, ...body } = menuReq;

    let formData: any = body;
    if (confirm === 'false') {
        formData = new FormData();
        Object.keys(body).forEach((key: string) => {
            const val = body[key as keyof typeof body]
            if (val) {
                formData.append(key, key === 'data' ? JSON.stringify(val) : val)
            }
        })
    }


    try {
        const { data } = await axios.post(`${config.defaults.api_url}/v1/import/menu?confirm=${confirm}`, formData);
        return data;

    } catch (error) {
        return error;
    }
}

export const getVendorList = async (vendorListReq?: VendorListRequest) => {
    const queryString = vendorListReq
        ? Object.keys(vendorListReq)
            .reduce((currentString: string, currentKey: string, i: number) => {
                const val = vendorListReq[currentKey as keyof typeof vendorListReq];
                currentString += (val !== null && val !== undefined && val !== '')
                    ? `${currentString.length > 0 ? '&' : '?'}${currentKey}=${val}`
                    : '';

                return currentString;
            }, '')
        : '';

    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/admins/vendor${queryString}`);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const removeVendor = async (vendor_id: number) => {
    try {
        const res = await axios.delete(`${config.defaults.api_url}/v1/admins/vendor/${vendor_id}`);
        return res

    } catch (error: any) {
        return error
    }
}

export const resettingVendor = async (vendor_id: number) => {
    try {
        const res = await axios.put(`${config.defaults.api_url}/v1/admins/vendor/${vendor_id}/reset`);

        return res;

    } catch (error: any) {
        return error
    }
}

export const getVendorDetails = async (vendor_id: number) => {
    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/admins/vendor/${vendor_id}`);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const getVendorOnboardingStatus = async (vendor_id: number) => {
    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/admins/vendor/${vendor_id}/onboarding`);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const postWelcomeEmail = async (emailBodyReq: SendWelcomeEmail) => {
    const { vendor_id, ...body } = emailBodyReq;
    try {
        const res = await axios.post(`${config.defaults.api_url}/v1/admins/vendor/${vendor_id}/prospect`, body);
        return res?.data;

    } catch (error: any) {
        return error
    }
}



export const putVendorDetails = async (putVendorDetilsReq: VendorDetailsRequest) => {
    const { vendor_id, ...body } = putVendorDetilsReq;
    try {
        const res = await axios.put(`${config.defaults.api_url}/v1/admins/vendor/${vendor_id}`, body);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const putVendorDetailsForBilling = async (putVendorDetilsReq: any) => {
    const { vendor_id, ...body } = putVendorDetilsReq;
    try { 
        const res = await axios.put(`${config.defaults.api_url}/v1/vendors/${vendor_id}/billing`, body);
        return res?.data;

    } catch (error: any) {
        return error
    }
}







export const putSubscriptionStatus = async (updateSubscriptionStatus: any) => {
    const { vendor_id, ...body } = updateSubscriptionStatus;
    try {
        const res = await axios.put(`${config.defaults.api_url}/v1/admins/vendor/${vendor_id}/status`, body);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const postVendor = async (addVendorReq: AddVendorRequest) => {
    try {
        const res = await axios.post(`${config.defaults.api_url}/v1/admins/vendor`, addVendorReq);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const getVenueList = async (venueListReq?: VendorListRequest) => {
    const queryString = getQueryString(venueListReq);

    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/vendors/venues${queryString}`);
        return res?.data;

    } catch (error: any) {
        return error
    }
}



export const getVenueListUpdated = async (venueListReq?: any) => {

    const { vendorId, ...restParams } = venueListReq;

    const queryString = getQueryString(restParams);

    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/reports/vendors/${vendorId}/venues${queryString}`);
        return res?.data;

    } catch (error: any) {
        return error
    }
}





export const removeVenue = async (venue_id: number) => {
    try {
        const res = await axios.delete(`${config.defaults.api_url}/v1/vendors/venues/${venue_id}`);
        return res

    } catch (error: any) {
        return error
    }
}

export const getAdmins = async (getAdminsReq: GetAdminRequest) => {
    const queryString = getQueryString(getAdminsReq);

    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/vendors/admins${queryString}`);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const postAdmin = async (addAdminReq: AddAdminRequest) => {
    try {
        const res = await axios.post(`${config.defaults.api_url}/v1/vendors/admins`, addAdminReq);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const removeAdmin = async (admin_id: number) => {
    try {
        const res = await axios.delete(`${config.defaults.api_url}/v1/vendors/admins/${admin_id}`);
        return res

    } catch (error: any) {
        return error
    }
}

export const putAdmin = async (editAdminReq: EditAdminRequest) => {
    const { admin_id, ...body } = editAdminReq;
    try {
        const res = await axios.put(`${config.defaults.api_url}/v1/vendors/admins/${admin_id}`, body);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const postVenue = async (addVenueReq: any) => {
    try {
        const res = await axios.post(`${config.defaults.api_url}/v1/vendors/venues`, addVenueReq);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const getLeads = async (vendorListReq?: LeadsRequest) => {

    console.log(vendorListReq)

    const queryString = getQueryString(vendorListReq);

    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/vendor-marketing/leads/${queryString}`);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const putLead = async (updateLeadsReq: UpdateLeadRequest) => {
    const { lead_id, ...body } = updateLeadsReq;
    try {
        const res = await axios.put(`${config.defaults.api_url}/v1/vendor-marketing/leads/${lead_id}`, body);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const removeLead = async (lead_id: number) => {

    try {
        const res = await axios.delete(`${config.defaults.api_url}/v1/vendor-marketing/lead/${lead_id}`);
        return res;

    } catch (error: any) {
        return error
    }
}

export const postLead = async (addLeadReq: AddLeadRequest) => {
    try {
        const res = await axios.post(`${config.defaults.api_url}/v1/vendor-marketing/leads`, addLeadReq);
        return res?.data;
    } catch (error: any) {
        return error
    }

}

export const putSalesLead = async (lead_id: number) => {
    try {
        const res = await axios.put(`${config.defaults.api_url}/v1/vendor-marketing/leads/${lead_id}/sales`);
        return res?.data;

    } catch (error: any) {
        return error
    }

}

export const approveVendorSerive = async (vendor_id: number) => {

    try {
        const res = await axios.put(`${config.defaults.api_url}/v1/admins/vendor/${vendor_id}/approve`);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const getCrowdList = async (crowdListReq: any) => {
    const queryString = getQueryString(crowdListReq);

    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/vendors/customers${queryString}`);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const getOrderList = async (orderListReq: any) => {
    const { customer_id, ...rest } = orderListReq;
    const queryString = getQueryString(rest);

    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/vendors/orders/customers/${customer_id}${queryString}`);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const getOrderDetails = async (order_id: number) => {
    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/vendors/customers/orders/${order_id}`);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const putOrderStatus = async (orderStatusReq: any) => {
    const { order_id, ...rest } = orderStatusReq
    try {
        const res = await axios.put(`${config.defaults.api_url}/v1/orders/${order_id}`, rest);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const addNotes = async (addNoteReq: any) => {
    const { lead_id, ...body } = addNoteReq;

    try {
        const res = await axios.post(`${config.defaults.api_url}/v1/vendor-marketing/leads/${lead_id}/notes`, body);
        return res?.data

    } catch (error) {
        return error;
    }
}

export const downloadEventReport = async (downloadEventReq: any) => {
    const { event_id, timezone } = downloadEventReq
    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/reports/venues/events/${event_id}/sales/download?timezone=${timezone}`, { responseType: "arraybuffer" });
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const downloadSalesReport = async (downloadSalesReq: any) => {
    const { venue_id, start_date, end_date } = downloadSalesReq
    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/reports/venues/${venue_id}/sales/download?start_date=${start_date}&end_date=${end_date}`, { responseType: "arraybuffer" });
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const downloadItemsReport = async (event_id: number) => {
    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/reports/venues/events/${event_id}/items/download`, { responseType: "arraybuffer" });
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const deleteNote = async (lead_id: number, note_id: number) => {
    try {
        const res = await axios.delete(`${config.defaults.api_url}/v1/vendor-marketing/leads/${lead_id}/notes/${note_id}`);
        return res;

    } catch (error) {
        return error;
    }
}

export const getVendorBillingHistory = async (vendor_id: number) => {
    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/vendors/${vendor_id}/subscription/history`);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const getInvoiceHistory = async (vendor_id: number) => {
    try {
        const res: any = await axios.get(`${config.defaults.api_url}/v1/vendors/${vendor_id}/invoices`);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const postAgreement = async (params: any) => {
    try {
        const res = await axios.post(`${config.defaults.api_url}/v1/vendors/agreement/`, params);
        return res?.data;

    } catch (error: any) {
        return error
    }
}


export const postAgreementType = async (params: any) => {
    try {
        const res = await axios.post(`${config.defaults.api_url}/v1/vendors/agreement`, params);
        return res?.data;

    } catch (error: any) {
        return error
    }
}


export const sendEmailAgreement = async (vendor_id: number) => {

    try {
        const res = await axios.put(`${config.defaults.api_url}/v1/vendors/${vendor_id}/agreement/email`);
        return res?.data

    } catch (error) {
        return null
    }
}

