import PromotionalResource from 'components/promotionalResources';

const Resource = () => {

    return (
        <>
            <PromotionalResource />
        </>
    )
}

export default Resource;