import { useEffect, useRef, useState } from "react";
import Input from "../input";
import Label from "../label";
import { LabeledInputSkeleton } from "../skeleton";

interface IProps {
    isLoading: boolean;
    isEdit: boolean;
    value: any;
    name: string;
    onChange?: Function;
    placeholder?: string;
    isError?: boolean;
    errors?: any;
    setErrors?: Function;
    label?: string;
    onClickCross?: Function;
    maxLength?: number
    isRenewal?: boolean
}

const EditableInput = ({maxLength, onClickCross, isLoading, label, isEdit, value, name, onChange, placeholder, isError, errors, setErrors, isRenewal }: IProps) => {
    const textRef = useRef<any>(null);
    const [tooltip, setTooltip] = useState(false);

    useEffect(() => {
        if (value && textRef.current) {
            setTooltip(textRef.current ? (textRef.current.scrollWidth - textRef.current.clientWidth > 0) : false)
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    }, [value, textRef.current]);

    
    



    return (
        <>
            {isLoading ? (
                <LabeledInputSkeleton />
            ) : (
                <>
                    {label && <Label inputLabel={isEdit} text={label} />}
                    {isEdit ? (
                        <Input
                            name={name}
                            value={value}
                            onChange={(e: any) => onChange && onChange(e)}
                            placeholder={placeholder}
                            isError={isError}
                            errors={errors}
                            setErrors={setErrors}
                            onClickCross={onClickCross}
                            maxLength={maxLength}
                        />
                    ) : (
                        <div className="show-truncate-tooltip">
                            <p ref={textRef} className="field-text">{value ||  'Not Yet'}</p>
                            {tooltip && (
                                <span className="truncate-tooltip">{value}</span>
                            )}
                        </div>
                    )}
                </>
            )}
        </>
    )
};

export default EditableInput;
