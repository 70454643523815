import { FC } from "react";

interface Props  {
    isDisabled? : boolean,
}

const ReportIcon: FC<Props> = ({isDisabled}) => {
    return (
        <>
            {/* {!isDisabled && <svg className="active-tab-svg" width="40" height="45" viewBox="0 0 40 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.4" clipPath="url(#clip0_6717_3543)">
                <path d="M8.68948 35.126H5.4013C4.36014 35.126 3.51611 35.97 3.51611 37.0112C3.51611 38.0523 4.36014 38.8963 5.4013 38.8963H8.68948C9.73064 38.8963 10.5747 38.0523 10.5747 37.0112C10.5747 35.97 9.73064 35.126 8.68948 35.126Z" fill="#696969" fillOpacity="0.411765"/>
                <path d="M18.3115 29.0371H15.2529C14.1484 29.0371 13.2529 29.9325 13.2529 31.0371V37.012C13.2529 38.1166 14.1484 39.012 15.2529 39.012H18.3115C19.4161 39.012 20.3115 38.1166 20.3115 37.012V31.0371C20.3115 29.9325 19.4161 29.0371 18.3115 29.0371Z" fill="#696969"/>
                <path d="M28.0424 21.6191H24.9839C23.8793 21.6191 22.9839 22.5146 22.9839 23.6191V37.1406C22.9839 38.2452 23.8793 39.1406 24.9839 39.1406H28.0424C29.147 39.1406 30.0424 38.2452 30.0424 37.1406V23.6191C30.0424 22.5146 29.147 21.6191 28.0424 21.6191Z" fill="#696969" fillOpacity="0.411765"/>
                <path d="M38 17.1162H34.9414C33.8368 17.1162 32.9414 18.0116 32.9414 19.1162V37.1401C32.9414 38.2446 33.8368 39.1401 34.9414 39.1401H38C39.1045 39.1401 40 38.2446 40 37.1401V19.1162C40 18.0116 39.1045 17.1162 38 17.1162Z" fill="#696969"/>
                <path d="M36.1249 11.6205L38.7915 9.22122L39.0587 2.44733L32.2906 2L29.6299 4.39352L36.398 4.84085L36.1249 11.6205Z" fill="#696969"/>
                <path d="M2.93525 23.0242C2.67963 23.0242 2.42402 22.9196 2.23811 22.7163C1.88954 22.3329 1.9244 21.7403 2.30783 21.3976L9.45352 14.9664L19.2774 17.8015L35.2826 4.76493C35.6835 4.4396 36.2702 4.49769 36.6014 4.89855C36.9267 5.29941 36.8686 5.88617 36.4677 6.21731L19.7015 19.8697L9.93571 17.052L3.56268 22.786C3.38258 22.9487 3.16182 23.0242 2.93525 23.0242Z" fill="#696969"/>
                </g>
                <path d="M6.68948 33.126H3.4013C2.36014 33.126 1.51611 33.97 1.51611 35.0112C1.51611 36.0523 2.36014 36.8963 3.4013 36.8963H6.68948C7.73064 36.8963 8.57467 36.0523 8.57467 35.0112C8.57467 33.97 7.73064 33.126 6.68948 33.126Z" fill="#26CAD3"/>
                <path d="M16.3115 27.0371H13.2529C12.1484 27.0371 11.2529 27.9325 11.2529 29.0371V35.012C11.2529 36.1166 12.1484 37.012 13.2529 37.012H16.3115C17.4161 37.012 18.3115 36.1166 18.3115 35.012V29.0371C18.3115 27.9325 17.4161 27.0371 16.3115 27.0371Z" fill="#4285F4"/>
                <path d="M26.0424 19.6191H22.9839C21.8793 19.6191 20.9839 20.5146 20.9839 21.6191V35.1406C20.9839 36.2452 21.8793 37.1406 22.9839 37.1406H26.0424C27.147 37.1406 28.0424 36.2452 28.0424 35.1406V21.6191C28.0424 20.5146 27.147 19.6191 26.0424 19.6191Z" fill="#26CAD3"/>
                <path d="M36 15.1162H32.9414C31.8368 15.1162 30.9414 16.0116 30.9414 17.1162V35.1401C30.9414 36.2446 31.8368 37.1401 32.9414 37.1401H36C37.1045 37.1401 38 36.2446 38 35.1401V17.1162C38 16.0116 37.1045 15.1162 36 15.1162Z" fill="#4285F4"/>
                <path className="arrow-1" d="M34.1249 9.62055L36.7915 7.22122L37.0587 0.447332L30.2906 0L27.6299 2.39352L34.398 2.84085L34.1249 9.62055Z" fill="#484545"/>
                <path className="arrow-2" d="M0.935253 21.0242C0.679634 21.0242 0.424016 20.9196 0.238112 20.7163C-0.110459 20.3329 -0.075602 19.7403 0.307826 19.3976L7.45352 12.9664L17.2774 15.8015L33.2826 2.76493C33.6835 2.4396 34.2702 2.49769 34.6014 2.89855C34.9267 3.29941 34.8686 3.88617 34.4677 4.21731L17.7015 17.8697L7.93571 15.052L1.56268 20.786C1.38258 20.9487 1.16182 21.0242 0.935253 21.0242Z" fill="#484545"/>
                <defs>
                <clipPath id="clip0_6717_3543">
                <rect width="38" height="37.1344" fill="white" transform="translate(2 7)"/>
                </clipPath>
                </defs>
            </svg>} */}
            
            <svg className="non-active-svg" xmlns="http://www.w3.org/2000/svg" width="26" height="25" viewBox="0 0 26 25" fill="none">
                <g opacity="0.4">
                    <path d="M5.2416 21.8652H3.108C2.43243 21.8652 1.88477 22.4129 1.88477 23.0885C1.88477 23.764 2.43243 24.3117 3.108 24.3117H5.2416C5.91718 24.3117 6.46484 23.764 6.46484 23.0885C6.46484 22.4129 5.91718 21.8652 5.2416 21.8652Z" fill="#222222"/>
                    <path d="M11.2822 17.9141H9.70215C8.87372 17.9141 8.20215 18.5856 8.20215 19.4141V22.8865C8.20215 23.7149 8.87372 24.3865 9.70215 24.3865H11.2822C12.1107 24.3865 12.7822 23.7149 12.7822 22.8865V19.4141C12.7822 18.5856 12.1107 17.9141 11.2822 17.9141Z" fill="#222222"/>
                    <path d="M17.5957 13.0996H16.0156C15.1872 13.0996 14.5156 13.7712 14.5156 14.5996V22.9688C14.5156 23.7972 15.1872 24.4688 16.0156 24.4688H17.5957C18.4241 24.4688 19.0957 23.7972 19.0957 22.9688V14.5996C19.0957 13.7712 18.4241 13.0996 17.5957 13.0996Z" fill="#222222"/>
                    <path d="M24.0566 10.1797H22.4766C21.6481 10.1797 20.9766 10.8513 20.9766 11.6797V22.9703C20.9766 23.7987 21.6481 24.4703 22.4766 24.4703H24.0566C24.8851 24.4703 25.5566 23.7987 25.5566 22.9703V11.6797C25.5566 10.8513 24.8851 10.1797 24.0566 10.1797Z" fill="#222222"/>
                    <path d="M23.0425 6.61161L24.7728 5.05477L24.9462 0.659401L20.5546 0.369141L18.8281 1.92222L23.2197 2.21248L23.0425 6.61161Z" fill="#222222"/>
                    <path d="M1.50719 14.0116C1.34133 14.0116 1.17547 13.9438 1.05484 13.8119C0.828665 13.5631 0.851283 13.1786 1.10008 12.9562L5.7367 8.7832L12.1111 10.6228L22.4964 2.16377C22.7565 1.95267 23.1372 1.99036 23.3521 2.25047C23.5632 2.51057 23.5255 2.8913 23.2654 3.10617L12.3863 11.9647L6.04957 10.1365L1.91431 13.8571C1.79745 13.9626 1.65421 14.0116 1.50719 14.0116Z" fill="#222222"/>
                </g>
                <path d="M4.34317 21.4961H2.20957C1.53399 21.4961 0.986328 22.0438 0.986328 22.7193C0.986328 23.3949 1.53399 23.9426 2.20956 23.9426H4.34317C5.01874 23.9426 5.5664 23.3949 5.5664 22.7193C5.5664 22.0438 5.01874 21.4961 4.34317 21.4961Z" fill="white"/>
                <path d="M10.3838 17.5449H8.80371C7.97528 17.5449 7.30371 18.2165 7.30371 19.0449V22.5173C7.30371 23.3458 7.97528 24.0173 8.80371 24.0173H10.3838C11.2122 24.0173 11.8838 23.3458 11.8838 22.5173V19.0449C11.8838 18.2165 11.2122 17.5449 10.3838 17.5449Z" fill="white"/>
                <path d="M16.6973 12.7305H15.1172C14.2888 12.7305 13.6172 13.402 13.6172 14.2305V22.5996C13.6172 23.428 14.2888 24.0996 15.1172 24.0996H16.6973C17.5257 24.0996 18.1973 23.428 18.1973 22.5996V14.2305C18.1973 13.402 17.5257 12.7305 16.6973 12.7305Z" fill="white"/>
                <path d="M23.1582 9.81055H21.5781C20.7497 9.81055 20.0781 10.4821 20.0781 11.3105V22.6011C20.0781 23.4296 20.7497 24.1011 21.5781 24.1011H23.1582C23.9866 24.1011 24.6582 23.4296 24.6582 22.6011V11.3105C24.6582 10.4821 23.9866 9.81055 23.1582 9.81055Z" fill="white"/>
                <path d="M22.1441 6.24247L23.8744 4.68562L24.0478 0.29026L19.6562 0L17.9297 1.55308L22.3213 1.84334L22.1441 6.24247Z" fill="white"/>
                <path d="M0.608757 13.6425C0.442894 13.6425 0.277032 13.5747 0.156404 13.4427C-0.0697725 13.1939 -0.0471548 12.8094 0.201639 12.587L4.83826 8.41406L11.2127 10.2536L21.5979 1.79462C21.858 1.58353 22.2388 1.62122 22.4536 1.88133C22.6647 2.14143 22.627 2.52216 22.3669 2.73703L11.4878 11.5956L5.15114 9.76735L1.01588 13.488C0.899017 13.5935 0.755772 13.6425 0.608757 13.6425Z" fill="white"/>
            </svg>

            <svg className="svg-mob" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 26 26" fill="none">
                <g opacity="0.4">
                    <path d="M5.2416 22.5029H3.108C2.43243 22.5029 1.88477 23.0506 1.88477 23.7262C1.88477 24.4017 2.43243 24.9494 3.108 24.9494H5.2416C5.91718 24.9494 6.46484 24.4017 6.46484 23.7262C6.46484 23.0506 5.91718 22.5029 5.2416 22.5029Z" fill="#222222"/>
                    <path d="M11.2827 18.5527H9.70264C8.87421 18.5527 8.20264 19.2243 8.20264 20.0527V23.5252C8.20264 24.3536 8.87421 25.0252 9.70264 25.0252H11.2827C12.1111 25.0252 12.7827 24.3536 12.7827 23.5252V20.0527C12.7827 19.2243 12.1111 18.5527 11.2827 18.5527Z" fill="#222222"/>
                    <path d="M17.5957 13.7373H16.0156C15.1872 13.7373 14.5156 14.4089 14.5156 15.2373V23.6064C14.5156 24.4349 15.1872 25.1064 16.0156 25.1064H17.5957C18.4241 25.1064 19.0957 24.4349 19.0957 23.6064V15.2373C19.0957 14.4089 18.4241 13.7373 17.5957 13.7373Z" fill="#222222"/>
                    <path d="M24.0566 10.8164H22.4766C21.6481 10.8164 20.9766 11.488 20.9766 12.3164V23.607C20.9766 24.4354 21.6481 25.107 22.4766 25.107H24.0566C24.8851 25.107 25.5566 24.4354 25.5566 23.607V12.3164C25.5566 11.488 24.8851 10.8164 24.0566 10.8164Z" fill="#222222"/>
                    <path d="M23.043 7.24931L24.7733 5.69246L24.9467 1.2971L20.5551 1.00684L18.8286 2.55991L23.2202 2.85017L23.043 7.24931Z" fill="#222222"/>
                    <path d="M1.50719 14.6493C1.34133 14.6493 1.17547 14.5815 1.05484 14.4496C0.828665 14.2008 0.851283 13.8163 1.10008 13.5939L5.7367 9.42089L12.1111 11.2605L22.4964 2.80146C22.7565 2.59036 23.1372 2.62806 23.3521 2.88816C23.5632 3.14826 23.5255 3.52899 23.2654 3.74386L12.3863 12.6024L6.04957 10.7742L1.91431 14.4948C1.79745 14.6003 1.65421 14.6493 1.50719 14.6493Z" fill="#222222"/>
                </g>
                <path d="M4.34317 22.1328H2.20957C1.53399 22.1328 0.986328 22.6805 0.986328 23.3561C0.986328 24.0316 1.53399 24.5793 2.20956 24.5793H4.34317C5.01874 24.5793 5.5664 24.0316 5.5664 23.3561C5.5664 22.6805 5.01874 22.1328 4.34317 22.1328Z" fill="white"/>
                <path d="M10.3843 18.1826H8.8042C7.97577 18.1826 7.3042 18.8542 7.3042 19.6826V23.155C7.3042 23.9835 7.97577 24.655 8.8042 24.655H10.3843C11.2127 24.655 11.8843 23.9835 11.8843 23.155V19.6826C11.8843 18.8542 11.2127 18.1826 10.3843 18.1826Z" fill="white"/>
                <path d="M16.6973 13.3672H15.1172C14.2888 13.3672 13.6172 14.0388 13.6172 14.8672V23.2363C13.6172 24.0648 14.2888 24.7363 15.1172 24.7363H16.6973C17.5257 24.7363 18.1973 24.0648 18.1973 23.2363V14.8672C18.1973 14.0388 17.5257 13.3672 16.6973 13.3672Z" fill="white"/>
                <path d="M23.1582 10.4463H21.5781C20.7497 10.4463 20.0781 11.1179 20.0781 11.9463V23.2369C20.0781 24.0653 20.7497 24.7369 21.5781 24.7369H23.1582C23.9866 24.7369 24.6582 24.0653 24.6582 23.2369V11.9463C24.6582 11.1179 23.9866 10.4463 23.1582 10.4463Z" fill="white"/>
                <path d="M22.1446 6.87919L23.8749 5.32234L24.0483 0.926979L19.6567 0.636719L17.9302 2.1898L22.3218 2.48006L22.1446 6.87919Z" fill="white"/>
                <path d="M0.608757 14.2792C0.442894 14.2792 0.277032 14.2114 0.156404 14.0794C-0.0697725 13.8306 -0.0471548 13.4461 0.201639 13.2237L4.83826 9.05078L11.2127 10.8903L21.5979 2.43134C21.858 2.22025 22.2388 2.25794 22.4536 2.51804C22.6647 2.77815 22.627 3.15888 22.3669 3.37375L11.4878 12.2323L5.15114 10.4041L1.01588 14.1247C0.899017 14.2302 0.755772 14.2792 0.608757 14.2792Z" fill="white"/>
            </svg>
            
        </>
    )
};

export default ReportIcon;
