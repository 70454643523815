import Button from "components/common/button";
import EditableActions from "components/common/editableActions";
import Input from "components/common/input";
import Label from "components/common/label";
import { AuthContext } from "contexts/auth";
import { AuthActions } from "contexts/reducers/auth";
import { useFormik } from "formik";
import { useIsDisabled } from "hooks/useIsDisabled";
import Cookies from "js-cookie";
import { useContext, useEffect, useState } from "react";
import { profileSchema } from "util/validationSchema/profileSchema";
import ChangePasswordModal from "./changePasswordModal";
import InfoModal from "components/common/infoModal";
import ApiModal from "components/common/apiErrorModal";
import BottomBar from "components/common/bottomBar";
import SaveIcon from "components/venue/SaveIcon";

const enum ModalType {
  Change_Password = "Change Password",
  Logout = "Logout",
}

const Profile = () => {
  const { user, updateUser, logoutUser, loading } = useContext(AuthContext);

  const [modal, setModal] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState("");
  const [passwordChanged, setPasswordChange] = useState<boolean>(false);
  const [success, setSuccess] = useState(false);

  const firstName = user?.first_name || Cookies.get("first_name");
  const lastName = user?.last_name || Cookies.get("last_name");
  const email = user?.email || Cookies.get("email");

  const initialValues = {
    email: email || "",
    first_name: firstName || "",
    last_name: lastName || "",
  };

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: profileSchema,
    onSubmit: async (values: any) => {
      const res = await updateUser(values);

      if (res?._status?.code === 200) {
        setIsEdit(false);
        setSuccess(true);
      }

      res?.response?.data?.message && setError(res?.response?.data?.message);
    },
  });

  const { values, errors, setErrors, handleChange, handleSubmit, handleReset } =
    formik;

  const onLogout = () => {
    logoutUser();
  };

  const onClickEdit = () => {
    setIsEdit(true);
  };

  useEffect(()=>{
    const sucessTimer = setTimeout(()=>{
         if(success)
         setSuccess(false);
     },3000);


     return ()=>{
         clearTimeout(sucessTimer)
     }
     
 }, [success]);


  const isDisabled = useIsDisabled(values, errors);
  const updateUserLoading = loading.includes(AuthActions.SET_USER);

  return (
    <>
      {
            <div className={`save-order ${success ? "save-order-animation" : ""}`}>
                 <span>   <SaveIcon /> Saved</span>
                </div>
            }

      <div className="super-admin">
        <div className="profile-container">
          <div className="profile-header row">
            <div className="profile-buttons five-columns-lg columns">
              <div className="change-password-btn">
                <button
                  onClick={() => setModal(ModalType.Change_Password)}
                  type="button"
                  className="bttn"
                >
                  Change Password
                </button>
              </div>
              <div className="log-out-btn">
                <Button
                  buttonName="Log Out"
                  onClick={() => setModal(ModalType.Logout)}
                  cancelButton
                />
              </div>
            </div>
            <div className="edit-user-details seven-columns-lg twelve-columns columns">
              <p className="profile-heading">User Details</p>
              <EditableActions
                isEditMode={isEdit}
                onClickEdit={onClickEdit}
                onClickCross={() => {
                  setIsEdit(false);
                  handleReset(initialValues);
                }}
                onClickSubmit={handleSubmit}
                activeDisabled={isDisabled}
                disabled={updateUserLoading}
              />
            </div>
          </div>

          <form className="user-detail-form five-columns-lg eight-columns-md ten-columns-sm columns column-ml-0">
            <div className="detail-group">
              <Label inputLabel={isEdit} text="Email" />
              {isEdit ? (
                <Input
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  errors={errors}
                  setErrors={setErrors}
                  isError={!!errors.email}
                  placeholder={(errors.email as string) || "Not Yet"}
                />
              ) : (
                <p className="field-text">{values.email || "Not Yet"}</p>
              )}
            </div>
            <div className="detail-group name-field ten-columns-sm columns column-ml-0">
              <Label inputLabel={isEdit} text="First Name" />
              {isEdit ? (
                <Input
                  name="first_name"
                  value={values.first_name}
                  onChange={handleChange}
                  errors={errors}
                  setErrors={setErrors}
                  isError={!!errors.first_name}
                  placeholder={(errors.first_name as string) || "Not Yet"}
                />
              ) : (
                <p className="field-text">{values.first_name || "Not Yet"}</p>
              )}
            </div>
            <div className="detail-group name-field ten-columns-sm columns column-ml-0">
              <Label inputLabel={isEdit} text="Last Name" />
              {isEdit ? (
                <Input
                  name="last_name"
                  value={values.last_name}
                  onChange={handleChange}
                  errors={errors}
                  setErrors={setErrors}
                  isError={!!errors.last_name}
                  placeholder={(errors.last_name as string) || ""}
                />
              ) : (
                <p className="field-text">{values.last_name || "Not Yet"}</p>
              )}
            </div>
          </form>
        </div>
      </div>
      <BottomBar />
      {modal === ModalType.Change_Password && (
        <ChangePasswordModal
          closeModal={() => setModal("")}
          setPasswordChange={setPasswordChange}
        />
      )}
      {modal === ModalType.Logout && (
        <InfoModal
          title="Logout"
          description="Are you sure you want to logout?"
          submitButtonName="Yes"
          closeModal={() => setModal("")}
          onSubmit={() => onLogout()}
          isCancel
        />
      )}

      {passwordChanged && (
        <InfoModal
          title="Password Changed"
          description="Please log in again using the new password."
          submitButtonName="Okay"
          closeModal={() => {
            setPasswordChange(false);
            logoutUser();
          }}
          onSubmit={() => {
            setPasswordChange(false);
            logoutUser();
          }}
          isCancel={false}
        />
      )}

      {error && (
        <ApiModal
          title="User Update Failed"
          description={error}
          setIsShow={() => setError("")}
        />
      )}
    </>
  );
};

export default Profile;
