interface Props {
  onClick: Function;
  width?: number;
  height?: number;
  disabled?: boolean;
}
const CrossButton = ({ disabled, onClick, width = 47, height = 47 }: Props) => {
  return (
    <svg
      onClick={() => !disabled && onClick()}
      className={`cursor-pointer modal-action-button ${
        disabled ? "disabled" : ""
      }`}
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 45 45"
      fill="none"
    >
      <path
        d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z"
        fill="#E94929"
      />
      <path
        d="M14.6445 30.354L30.3535 14.645"
        stroke="white"
        strokeWidth="3.5"
      />
      <path
        d="M14.6445 14.645L30.3535 30.354"
        stroke="white"
        strokeWidth="3.5"
      />
    </svg>

    // <svg onClick={() => !disabled && onClick()} className={`cursor-pointer modal-action-button ${disabled ? 'disabled' : ''}`} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="0 0 47 47" fill="none">
    //     <g filter="url(#filter0_d_3149_1556)">
    //         <path d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z" fill="#E94929"/>
    //     </g>
    //     <path d="M14.645 30.3535L30.354 14.6445" stroke="white" strokeWidth="3.5"/>
    //     <path d="M14.645 14.6445L30.354 30.3535" stroke="white" strokeWidth="3.5"/>
    //     <defs>
    //         <filter id="filter0_d_3149_1556" x="0" y="0" width="47" height="47" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
    //             <feFlood floodOpacity="0" result="BackgroundImageFix"/>
    //             <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
    //             <feOffset dx="2" dy="2"/>
    //             <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
    //             <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3149_1556"/>
    //             <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3149_1556" result="shape"/>
    //         </filter>
    //     </defs>
    // </svg>
  );
};

export default CrossButton;
