const FoodImageLibrarySkeleton = ({count}: {count: number}) => {
    return (
        <>
            {[...new Array(count)].map(() => {
                return (
                    <div className='food-item'>
                        <div className='image-box skeleton'></div>
                        <p className='image-text skeleton'></p>
                    </div>
                )
            })}
        </>
    )
};

export default FoodImageLibrarySkeleton;
