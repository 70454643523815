import { AuthContext } from "contexts/auth";
import Cookies from "js-cookie";
import { useContext } from "react";

export const useToken = () => {
    const { user } = useContext(AuthContext);
    const impersonationToken = user?.impersonation_token || Cookies.get("impersonation_token");

    if(impersonationToken){
        return impersonationToken;
    }

    const accessToken = user?.access_token || Cookies.get("crowd-chow-token");

    if(accessToken){
        return accessToken;
    }
}