import { useContext } from "react";
import { UserContext } from "contexts/user";
import Orders from "./orders";
import { useParams } from "react-router-dom";
import { useIsSuperAdmin } from "hooks/useIsSuperAdmin";
import { AuthContext } from "contexts/auth";
import Cookies from "js-cookie";

const OrderList = () => {
    const { fetchOrders, fetchOrderDetails, loading, orderList, orderDetails } = useContext(UserContext);
    const { user } = useContext(AuthContext);
    const { id } = useParams();
    const isSuperAdmin = useIsSuperAdmin();
    const vendor_id = user?.vendor_id || Cookies.get("vendor_id");
    return (
        <Orders
            fetchOrderDetails={fetchOrderDetails}
            fetchOrders={fetchOrders}
            loading={loading}
            orderList={orderList}
            customerId={id}
            orderDetails={orderDetails}
            vendorId={isSuperAdmin ? null : vendor_id}
        />
    )
};

export default OrderList;
