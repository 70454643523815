import { useEffect } from "react";
import { Modal } from "../modal";
import Button from "../button";
import BackButton from "components/backButton";
import PDFViewer from "../pdfViewer";

interface Props {
  title?: string;
  description?: string;
  setIsShow?: Function;
  closeModal?: Function;
  emailDescription?: Function;
  buttonText?: string;
  custom?: Boolean;
  onButtonClick?: Function;
}

const LargeTextDisplayModal = ({
  title,
  description,
  setIsShow,
  closeModal,
  emailDescription,
  buttonText,
  custom = false,
  onButtonClick,
}: Props) => {
  useEffect(() => {
    const handler = (e: any) => {
      e.stopPropagation();
      if (e.keyCode === 13 || e.keyCode === 27) {
        onClose(e);
      }
    };
    window.addEventListener("keydown", handler, true);

    return () => {
      window.removeEventListener("keydown", handler, true);
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const onClose = (e: any) => {
    if (e && typeof e.preventDefault === "function") {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsShow && setIsShow();
    closeModal && closeModal();
  };

  return (
    <div className={"mainContent-backDrop"}>
      <Modal setShowModal={onClose} customClass="agreement-display-modal">
        <div className="agreement-display-modal_header">
          <div className="agreement-display-modal_backBtn">
            <BackButton onClick={onClose}></BackButton>
          </div>

          <h1 className="agreement-display-modal_title">{title}</h1>
        </div>
        <Button
          buttonName={buttonText || ""}
          parentClass="agreement-display-modal_btn_email"
          onClick={onButtonClick}
          isActiveDisabled={false}
          dropShadow={false}
        />
        <div className="agreement-display-modal_body">
          {!custom && (
            <div
              style={{ padding: "10px" }}
              dangerouslySetInnerHTML={{ __html: description || "" }}
            />
          )}
          {custom && <PDFViewer url={description}></PDFViewer>}
        </div>
      </Modal>
    </div>
  );
};
export default LargeTextDisplayModal;
