import { Skeleton } from "components/common/skeleton";
import RefundIcon from "./icon";
import { useContext, useEffect, useRef, useState } from "react";
import { ReportContext } from "contexts/report";
import RefundOrderModal from "./refundOrderModal";
import Price from "./price";
import { QuestionMarkCircleBlack } from "components/common/questionMarkCircle";
import { insignificantZerosRegex } from "components/orders/details";

const EventSales = ({isLoading, event_sales, isDataFetched, isRefund}: any) => {
    const tooltipRef = useRef<HTMLParagraphElement | null>(null);
    const {fetchRefundsOrder, selectedEvent } = useContext(ReportContext);

    const [showModal, setShowModal] = useState(false);
    const [showTooltip, setShowTooltip] = useState(false);


    const isEvent = isDataFetched && !isLoading;
    //const toalOrders = event_sales?.total_orders?.toString()?.length > 4 ? '9999+' : event_sales?.total_orders;
   // const refundsCount = event_sales?.refunds_count?.toString()?.length > 4 ? '9999+' : event_sales?.refunds_count;


    const onClickRefund = async () => {
        isEvent && selectedEvent?.id && fetchRefundsOrder({event_id: selectedEvent.id, limit: 15, offset: 0});
        isEvent && setShowModal(true);
    }

    useEffect(() => {
        if(event_sales?.donation_organization && tooltipRef.current){
            setShowTooltip(tooltipRef.current ? tooltipRef.current?.scrollWidth - tooltipRef.current?.clientWidth > 0 : false)
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[tooltipRef.current, event_sales])

    return (
        <>
       
        <div className="sales-record">
        {event_sales?.tax_enabled ?
                <div className="sales-column">
                    <div className="event-detail-report">
                        {isEvent ? (
                            <p className="event-detail-report-heading">
                                Tax Collected:
                            </p>
                        ):(
                            <Skeleton customStyles={{borderRadius: 7}} animation={false} />
                        )}
                        
                        {isEvent ? (
                            <p className="event-name-detail">
                                <Price amount={event_sales?.total_tax?.replace(insignificantZerosRegex, '.')} />
                            </p>
                        ):(
                            <Skeleton customStyles={{borderRadius: 7}} animation={false} />
                        )}
                    </div>
                </div>
            : <></>}

           { event_sales?.delivery_fees_enabled ? <div className="sales-column">
                <div className="event-detail-report">
                    {isEvent ? (
                        <p className="event-detail-report-heading">
                            Delivery Fees:
                        </p>
                    ):(
                        <Skeleton customStyles={{borderRadius: 7}} animation={false} />
                    )}

                    {isEvent ? (
                        <p className="event-name-detail">
                            <Price amount={event_sales?.delivery_fee ? event_sales?.delivery_fee?.replace(insignificantZerosRegex, '.'):event_sales?.total_service_fee?.replace(insignificantZerosRegex, '.') } /> 
                        </p>
                    ):(
                        <Skeleton customStyles={{borderRadius: 7}} animation={false} />
                    )}
                </div>
            </div> : <></> }


        </div>
        <div className="sales-record">
            <div className="sales-column">
                <div className="refund-fee">
                    <div className="event-detail-report">
                        {isEvent ? (
                            <p className="event-detail-report-heading">
                                Refunds:
                                {isRefund && isEvent && (
                                    <span style={{marginLeft: 10}}>
                                        <RefundIcon disabled={!isEvent} onClick={() => onClickRefund()} />
                                    </span>
                                )}
                            </p>
                        ):(
                            <Skeleton customStyles={{borderRadius: 7}} animation={false} />
                        )}
                        
                        {isEvent ? (
                            <p className="event-name-detail">
                                <Price amount={event_sales?.refunds?.replace(insignificantZerosRegex, '.')} />
                            </p>
                        ):(
                            <Skeleton customStyles={{borderRadius: 7}} animation={false} />
                        )}
                    </div>
                </div>
            </div>
       
            <div className="sales-column">
                <div className="refund-fee padding-spacer">
                    <div className="event-detail-report">
                        {isEvent ? (
                            <p className="event-detail-report-heading">
                                Refund Fees:
                            </p>
                        ):(
                            <Skeleton customStyles={{borderRadius: 7}} animation={false} />
                        )}

                        {isEvent ? (
                             <p className="event-name-detail">
                                <Price amount={event_sales?.refund_fees?.replace(insignificantZerosRegex, '.')} />
                                <QuestionMarkCircleBlack text="Refund Fees" />
                            </p>
                        ):(
                            <Skeleton customStyles={{borderRadius: 7}} animation={false} />
                        )}
                    </div>
                    
                </div>
            </div>
        </div>
                
        <div className="sales-record">
          
            <div className="sales-column">
                {event_sales?.donation_organization && <div className="event-detail-report donation-org">
                    <p className="event-detail-report-heading">Donation Org:</p>
                    <div className="donate-name show-truncate-tooltip">
                        <p ref={tooltipRef} className="">
                            {isEvent ? event_sales?.donation_organization || '' : <Skeleton customStyles={{borderRadius: 7}} width={150} height={26} animation={false} />}
                        </p>
                        {showTooltip && (
                            <span className="truncate-tooltip">{event_sales?.donation_organization}</span>
                        )}
                    </div>
                </div>}
            </div>
        </div>

            {showModal && (
                <RefundOrderModal 
                    closeModal={() => setShowModal(false)}
                />
            )}
        </>
    )
};

export default EventSales;
