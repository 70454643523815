export const selectText = (htmlElement: any) => {
    if(htmlElement){
        const range = document.createRange();
        range.selectNodeContents(htmlElement);
        const sel:any = window.getSelection();

        if (sel.rangeCount > 0) {
            sel.removeAllRanges();
        }

        sel.addRange(range);
    }
}