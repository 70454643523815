import { useContext, useEffect, useState } from "react";
import SectionHeader from "./sectionHeader";
import Label from "components/common/label";
import Switch from "components/common/switch";
import QuestionMarkCircle from "components/common/questionMarkCircle";
import { useFormik } from "formik";
import * as Yup from "yup";
import { VendorDetailSections } from ".";
import { useIsDisabled } from "hooks/useIsDisabled";
import { VendorBillingStatuses } from "../../util/enums/VendorBillingStatuses";
import { VendorContext } from "contexts/vendor";
import ApiModal from "components/common/apiErrorModal";
import { VendorActions } from "contexts/reducers/vendor";
import { LabeledInputSkeleton } from "components/common/skeleton";
import Button from "components/common/button";
import InfoModal from "components/common/infoModal";
import Paragraph from "components/common/paragaraph";
import SaveIcon from "components/venue/SaveIcon";
import { AuthContext } from "contexts/auth";
import Select from "components/common/select";
import Input from "components/common/input";
import VendorHistoryModal from "./VendorHistoryModal";

const statusOptions: { label: string; value: string; enumValue: string }[] = [
  { label: "Created", value: "Created", enumValue: "CREATED" },
  { label: "Welcome", value: "Welcome", enumValue: "WELCOME" },
  {
    label: "Vp Onboarding",
    value: "Vp Onboarding",
    enumValue: "VP_ONBOARDING",
  },
  { label: "Needs Review", value: "Needs Review", enumValue: "NEEDS_REVIEW" },
  { label: "Active", value: "Active", enumValue: "ACTIVE" },
  { label: "Onboarding", value: "Onboarding", enumValue: "ONBOARDING" },
];

interface ApproveModal {
  title: string;
  description: string;
  type: string;
}

const MODAL_INIT_STATE = {
  title: "",
  description: "",
  type: "",
};

const ERROR_INIT_STATE = {
  type: "",
  message: "",
};

const VendorStatusSection = ({
  notPaid,
  data,
  setActiveEdit,
  activeEdit,
}: any) => {
  const { updateVendorDetails, loading, approveVendor } =
    useContext(VendorContext);
  const vendor_types_formatted = data?.vendor_types_formatted;
  const selectedOption = statusOptions.find(
    (option: any) => option.value === data?.status
  );
  const [success, setSuccess] = useState(false);
  const [modal, setModal] = useState<ApproveModal>(MODAL_INIT_STATE);
  const [error, setError] = useState(ERROR_INIT_STATE);
  const [isEdit, setIsEdit] = useState(false);
  const { setVendorDetailsEditing, vendorDetailsEditing } =
    useContext(AuthContext);
  const initialValues = {
    status: selectedOption?.value || data?.status || "",
    is_enabled: data?.is_enabled || false,
    event_enabled: data?.event_enabled || false,
    vendor_type: data?.vendor_type || "",
    high_schools: data?.billing?.high_schools || "",
  };

  const billingStatus = data?.billing?.status;
  const agreementSigned = data?.agreement_signed_on;

  const [historyModal, setHistoryModal] = useState<boolean>(false);

  const submitSaveHandler = async (values: any) => {
    const statusEnumVal = statusOptions.find(
      (option: any) => option.value === values.status
    );
    const res = await updateVendorDetails({
      ...values,
      high_schools:
        values.vendor_type !== "District" ? "" : values.high_schools,
      status: statusEnumVal?.enumValue || "",
    });
    if (res?.response?.data?.message) {
      setError({
        type: "vendor",
        message: res?.response?.data?.message,
      });
    }

    if (res?._status?.code === 200) {
      setSuccess(true);
      setIsEdit(false);
      setActiveEdit("");
      setVendorDetailsEditing(false);
      setModal(MODAL_INIT_STATE);
    }
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      status: Yup.string().required("Status is required"),
      high_schools: Yup.number().nullable(),
    }),
    onSubmit: async (values: any) => {
      if (values.vendor_type !== data?.vendor_type && agreementSigned === "") {
        setModal({
          type: "reset_agreement",
          title: "Reset Agreement",
          description: "Agreement will be reset.",
        });
      } else {
        submitSaveHandler(values);
      }
    },
  });

  const {
    values,
    errors,
    handleReset,
    handleChange,
    handleSubmit,
    handleBlur,
    setFieldValue,
    setErrors,
  } = formik;

  const onEdit = () => {
    setIsEdit(true);
    setActiveEdit(VendorDetailSections.VENDOR_STATUS);
    setVendorDetailsEditing(true);
  };

  const onClickCross = () => {
    setIsEdit(false);
    handleReset(initialValues);
    setActiveEdit("");
    setVendorDetailsEditing(false);
  };

  const onClickApprove = async () => {
    setModal({
      type: "confirmation",
      title: "Approve Vendor",
      description: "Are you sure you want to approve this vendor?",
    });
  };

  const onApprove = async () => {
    if (modal.type === "success") {
      setModal(MODAL_INIT_STATE);
      return;
    }

    const res = await approveVendor();

    if (res?._status?.code === 200) {
      setModal({
        title: "Approve Modal",
        description: "Vendor is approved successfully.",
        type: "success",
      });
    }

    if (res?.response?.data?.message) {
      setError({
        type: "approve",
        message: res.response.data.message,
      });
    }
  };

  const { is_enabled, event_enabled, ...restValues } = values;
  const isDisabled = useIsDisabled(restValues, errors, ["high_schools"]);
  const isLoading = loading[VendorActions.UPDATE_VENDOR_DETAILS];
  const vendorApproveLoading = loading[VendorActions.APPROVE_VEDNOR];
  const vendorLoading = loading[VendorActions.GET_VENDOR_DETAILS];

  useEffect(() => {
    const sucessTimer = setTimeout(() => {
      if (success) setSuccess(false);
    }, 3000);

    return () => {
      clearTimeout(sucessTimer);
    };
  }, [success]);

  const onChangeType = () => {
    submitSaveHandler(values);
  };

  return (
    <>
      {
        <div className={`save-order ${success ? "save-order-animation" : ""}`}>
          <span>
            {" "}
            <SaveIcon /> Saved
          </span>
        </div>
      }
      <SectionHeader
        title={VendorDetailSections.VENDOR_STATUS}
        onClick={() => onEdit()}
        isEditMode={isEdit}
        onClickCross={onClickCross}
        onClickSubmit={() => handleSubmit()}
        editDisabled={
          activeEdit && activeEdit !== VendorDetailSections.VENDOR_STATUS
        }
        submitDisabled={isDisabled}
        isLoading={isLoading || vendorLoading}
        isHistory={true}
        isHistoryDisabled={
          data?.vendor_status_history?.length > 0 ? false : true
        }
        onClickHistory={() => setHistoryModal(true)}
      />
      <div className="admin-info">
        <div className="detail-fields-group vendor-status">
          <div className="detail-group status">
            {vendorLoading ? (
              <LabeledInputSkeleton />
            ) : (
              <>
                <Label text="Status" />
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    flexWrap: "wrap",
                  }}
                >
                  <Paragraph customClass="field-text">
                    {values.status || "Not Yet"}
                  </Paragraph>
                  {values.status === "Needs Review" && (
                    <div className="status-approve">
                      <Button
                        buttonName="Approve"
                        onClick={onClickApprove}
                        customClass="status-approve-btn"
                        dropShadow={false}
                        disabled={
                          (data && data.venue_required_maps) ||
                          vendorDetailsEditing
                        }
                      />
                      <span className="field-info-tooltip">
                        <QuestionMarkCircle text="Vendor needs to first upload map image." />
                      </span>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          <div className="detail-group vendor-type">
            {vendorLoading ? (
              <LabeledInputSkeleton />
            ) : (
              <>
                <Label
                  inputLabel={
                    isEdit && billingStatus === VendorBillingStatuses.NEW
                  }
                  text="Type"
                />
                {isEdit && billingStatus === VendorBillingStatuses.NEW ? 
                (
                  <Select
                    name="vendor_type"
                    options={vendor_types_formatted}
                    customStyles={{
                      color: "black",
                    }}
                    value={values.vendor_type}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    isError={!!errors.vendor_type}
                    placeholder={
                      errors.vendor_type ? (errors.vendor_type as string) : ""
                    }
                  />
                ) : (
                  <p className="field-text">{values.vendor_type || ""}</p>
                )}
              </>
            )}
          </div>

          {values.vendor_type?.toLowerCase()?.includes("district") && (
            <div className="detail-group high-school">
              {vendorLoading ? (
                <LabeledInputSkeleton />
              ) : (
                <>
                  <Label inputLabel={isEdit} text="High Schools" />
                  {isEdit && billingStatus === VendorBillingStatuses.NEW ? (
                    <Input
                      type="text"
                      placeholder={
                        errors.high_schools
                          ? (errors.high_schools as string)
                          : ""
                      }
                      name="high_schools"
                      value={values.high_schools}
                      onChange={(e: any) =>
                        setFieldValue("high_schools", e.target.value)
                      }
                      onBlur={handleBlur}
                      isError={!!errors.high_schools}
                      errors={errors}
                      setErrors={setErrors}
                    />
                  ) : (
                    <p className="field-text">{values.high_schools || ""}</p>
                  )}
                </>
              )}
            </div>
          )}

          {/* 
                    <div className="detail-group">
                        {vendorLoading ? (
                            <LabeledInputSkeleton />
                        ) : (
                            <>
                                <Label text="Type" />
                                <div style={{ display: 'flex', alignItems: 'center' }}>
                                    <Paragraph customClass="field-text">
                                        {values.status || 'Not Yet'}
                                    </Paragraph>
                                    
                                </div>
                            </>
                        )}

                        
                    </div> */}
        </div>

        <div className="detail-fields-group vendor-status">
          <div className="detail-group vendor-enabled">
            {vendorLoading ? (
              <LabeledInputSkeleton />
            ) : (
              <>
                <Label text="Vendor is Enabled" />
                <div className="field-info-tooltip">
                  {isEdit ? (
                    <Switch
                      name="is_enabled"
                      checked={values.is_enabled}
                      onChange={handleChange}
                      disabled={!isEdit}
                      parentClass="toggle-small"
                    />
                  ) : (
                    <>
                      <p className="field-text">
                        {values.is_enabled ? "Yes" : "No"}
                      </p>
                      <QuestionMarkCircle />
                    </>
                  )}
                </div>
              </>
            )}
          </div>

          {/* </div>

                <div className="admin-info"> */}

          <div className="detail-group events-enabled">
            {vendorLoading ? (
              <LabeledInputSkeleton />
            ) : (
              <>
                <Label text="Events Enabled" />
                <div className="field-info-tooltip">
                  {isEdit ? (
                    <Switch
                      name="event_enabled"
                      checked={values.event_enabled}
                      onChange={handleChange}
                      disabled={!isEdit}
                      parentClass="toggle-small"
                    />
                  ) : (
                    <>
                      <p className="field-text">
                        {values.event_enabled ? "Yes" : "No"}
                      </p>
                      <QuestionMarkCircle />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
        </div>
      </div>

      {error.message && (
        <ApiModal
          title={
            error?.type === "approve"
              ? "Approve Failed"
              : "Vendor Update Failed"
          }
          message={error.message}
          setIsShow={() => setError(ERROR_INIT_STATE)}
        />
      )}

      {(modal.type === "confirmation" || modal.type === "success") && (
        <InfoModal
          title={modal.title}
          description={modal.description}
          closeModal={() => setModal(MODAL_INIT_STATE)}
          onSubmit={() => onApprove()}
          isCancel={modal.type === "confirmation"}
          submitLoading={vendorApproveLoading}
          submitButtonName={modal.type === "confirmation" ? "Approve" : ""}
          customClass={error.message ? "disabled" : ""}
        />
      )}

      {modal.type === "reset_agreement" && (
        <InfoModal
          title={modal.title}
          description={modal.description}
          closeModal={() => setModal(MODAL_INIT_STATE)}
          onSubmit={() => onChangeType()}
          customClass={error.message ? "disabled" : ""}
        />
      )}

      {historyModal ? (
        <VendorHistoryModal
          section={"status"}
          history={data?.status_history}
          closeModal={() => setHistoryModal(false)}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default VendorStatusSection;
