import moment from 'moment'
import React, { FC,  useState} from 'react'
import OrderDetails from '../Orders/OrderDetail'

interface Props {
  order?: any,
  onClickOrder?: (id: any)=> void
  isEmpty?: boolean
}



const OrderListItem: FC<Props> = ({order, onClickOrder, isEmpty}) => {
  
  //const {fetchOrderDetail} = useContext(ReportContext);

  const orderTime = order ? moment(order?.scheduled_time).format('h:mm A') : ""
  const [openOrderDetail, setOpenOrderdetail] = useState<boolean>(false)





const handleOrderClick =  async (e: any)=>{
  if(!isEmpty) {
setOpenOrderdetail(true);
onClickOrder && onClickOrder(order.id)
  }
}



  
  return (
    <>
    <div style={{cursor:'pointer'}} onClick={handleOrderClick} className='vendor-item-table-row'>
      <div className='td order'>
        {order?.order_id }
      </div>
      <div className='td time'>
        {orderTime}
      </div>
      <div className='td item'>
        {order?.items_count}
      </div>
      <div className='td total'>
        {order ? "$" : ""}{order?.subtotal}
      </div>
      <div className='td status'>
        {order?.status}
      </div>
    </div>
  
    
    {openOrderDetail && (
                <OrderDetails 
                    closeModal={() => setOpenOrderdetail(false)}
               
                />
            )}



  </>

  )
}

export default OrderListItem
