import { useContext, useState } from "react"
import { Modal, ModalHeader } from "components/common/modal";
import { VendorContext } from "contexts/vendor";
import SingleNote from "./note";
import { useParams } from "react-router-dom";
import { getInitials } from "hooks/useRoleId";
import AddNotesModal from "components/leads/addNotesModal";
import { VendorActions } from "contexts/reducers/vendor";

const NotesModal = ({closeModal, setSelectedNote}: any) => {
    const { vendorDetails, addNoteToVendor, loading } = useContext(VendorContext);
    const { vendor } = vendorDetails;
    const {notes} = vendor || {};
    
    const {id} = useParams();

    const [showAddNotesModal, setShowAddNotesModal] = useState(false);

    const onClickAdd = () => {
        setShowAddNotesModal(true);
    }

    const onClickNote = (note: any) => {
        const isNumber = id && !Number.isNaN(id);
        isNumber && setSelectedNote({...note, vendor_id: Number(id)});
    }

    return (
        <>
            <Modal
                innerConatinerClass="vendor-note-inner-container"
                customClass={`refund-modal vendor-note-modal ${showAddNotesModal ? 'disabled' : ''}`}
                setShowModal={() => closeModal()}
            >
                <ModalHeader
                    customClass="vendor-note-header"
                >
                    <div className="header-actions">
                        <p className="app-modal-title">Notes</p>
                        <svg onClick={onClickAdd} className="cursor-pointer" xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                            <circle cx="20" cy="20" r="20" fill="#FDC13D"/>
                            <rect x="19" y="11" width="2" height="18" rx="1" fill="#5F5F5F"/>
                            <rect x="29" y="19" width="2" height="18" rx="1" transform="rotate(90 29 19)" fill="#5F5F5F"/>
                        </svg>
                    </div>
                </ModalHeader>
                <div className={`notes-box`}>
                    {notes.map((item: any) => {
                        if(item?.admin_name){
                            item.name_initials = getInitials(item.admin_name);
                        }          
                        return (
                            <SingleNote data={item} onClickNote={onClickNote}/>
                        )
                    })}

                    {[...new Array(notes.length < 10 ? 10-notes.length : 0)].map((_: any, index: number) => {
                        return (
                            <div key={index} className="note skeleton">
                                <div className="subject-line-name"></div>
                                <div className="subject-note-detail">
                                    <div className="note-date"></div>
                                    <div className="group-name"></div>
                                </div>
                            </div>  
                        )
                    })}
                </div>
            </Modal>

            {showAddNotesModal && (
                <AddNotesModal
                    closeModal={() => setShowAddNotesModal(false)}
                    addNote={(data: any) => addNoteToVendor({...data, vendor_id: id})}
                    isLoading={loading[VendorActions.ADD_VENDOR_NOTE]}
                />
            )}
        </>
    )
};

export default NotesModal;
