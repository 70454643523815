import Button from "components/common/button";
import { Modal, ModalHeader, ModalBody } from "components/common/modal";

interface Props {
    closeModal?: Function;
    title: string;
    onSubmit: Function;
    isDisabled?: boolean;
    description: string;
    submitButtonName?: string;
    submitLoading?: boolean;
    isCancel?: boolean;
    width?: number;
}

const RefundModal = ({width, submitLoading, closeModal, title, description, onSubmit, isDisabled, submitButtonName, isCancel=true}: Props) => {

    return (
        <Modal
            setShowModal={() => closeModal && closeModal()}
            width={width || 342}
            customClass="refund-modal"
        >
            <ModalHeader>
                <p className="app-modal-title">{title || ''}</p>
            </ModalHeader>
               
            <ModalBody>
                <div>
                    <p style={{color: '#fff'}}>
                        {description || ''}
                    </p>
                </div>
            </ModalBody>

            {/* <ModalFooter 
                onCancel={isCancel ? closeModal :  undefined}
                onSubmit={onSubmit}
                cancelDisabled={isDisabled}
                submitDisabled={isDisabled}
                submitLoading={submitLoading}
                submitButtonName={submitButtonName}
            /> */}
            <div className={`app-modal-footer`}>
            
            <Button  
                buttonName={'Cancel'}
                customStyles={{
                    borderRadius: 40
                }}
                onClick={isCancel ? closeModal :  undefined}
                disabled={submitLoading}
            />

            <Button
                buttonName={"Refund"}
                cancelButton
                
                onClick={onSubmit}
                customStyles={{
                    borderRadius: 40
                }}
                disabled={isDisabled || submitLoading}
            />
        </div>
        </Modal>
    )
};

export default RefundModal;
