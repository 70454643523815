import { EMAIL_CHAR_LIMIT, NAME_CHAR_LIMIT } from "appConstants";
import ApiModal from "components/common/apiErrorModal";
import CheckMarkButton from "components/common/checkMarkButton";
import CrossButton from "components/common/crossButton";
import Input from "components/common/input";
import { Modal, ModalBody, ModalHeader } from "components/common/modal";
import Switch from "components/common/switch";
import { AuthContext } from "contexts/auth";
import { useFormik } from "formik";
import { useFullName } from "hooks/useRoleId";
import Cookies from "js-cookie";
import { FC, useContext, useEffect, useState } from "react";
import { billingContactSchema } from "util/validationSchema/billingContact";

const initialValues = {
  name: "",
  email: "",
};

interface Props {
  closeModal: () => void;
  cancelDisabled: boolean;
  onClickSubmit: (values: any) => void;
}

const BillingContactModal: FC<Props> = ({
  closeModal,
  cancelDisabled,
  onClickSubmit,
}) => {
  const { user } = useContext(AuthContext);
  const userEmail = user?.email || (Cookies.get("email") as string) || "";

  const [isSelfContact, setIsSelfContact] = useState(false);
  const [error, setError] = useState("");

  const fullName = useFullName();

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: billingContactSchema,
    onSubmit: async (values: any) => {
      //  console.log('values', values);
      onClickSubmit({ ...values, isSelf: isSelfContact });
    },
  });

  const {
    values,
    errors,
    handleChange,
    handleSubmit,
    setValues,
    resetForm,
    setErrors,
  } = formik;

  const onChange = (e: any) => {
    setIsSelfContact(e.target.checked);

    e.target.checked
      ? setValues({
          name: fullName,
          email: userEmail,
        })
      : resetForm();
  };

  useEffect(() => {
    if (user) {
      setValues({
        name: fullName,
        email: userEmail,
      });
      setIsSelfContact(true);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [user]);

  const isLoading = false;
  const isDisabled = false;

  console.log("userhere", userEmail);
  return (
    <>
      <Modal
        setShowModal={() => closeModal()}
        customClass={`add-modal billing-contact-modal ${
          error ? "disabled" : ""
        }`}
        isCancel={true}
        onSubmit={onClickSubmit}
      >
        <ModalHeader>
          <div className="modal-actions">
            <CrossButton
              onClick={closeModal}
              disabled={isLoading || cancelDisabled}
            />
            <CheckMarkButton
              onClick={handleSubmit}
              width={47}
              height={47}
              activeDisabled={isDisabled}
              disabled={isLoading}
              isForm
            />
          </div>
          <p className="app-modal-title">Billing Contact</p>
        </ModalHeader>

        <ModalBody>
          <div className="billing-contact-form-container">
            <p className="fonm-note">
              The billing contact receives subscription invoices via email. It
              is separate from all accounts.
            </p>
            <div className="billing-toggle">
              <p>I am the billing contact</p>
              <Switch checked={isSelfContact} onChange={onChange} />
            </div>

            <div className="contact-form-groups">
              <Input
                name="name"
                label="Billing Contact Name"
                placeholder={(errors.name as string) || "Enter Full Name"}
                value={values.name}
                onChange={handleChange}
                isError={!!errors.name}
                errors={errors}
                setErrors={setErrors}
                maxLength={NAME_CHAR_LIMIT}
              />

              <Input
                name="email"
                label="Billing Contact Email"
                placeholder={(errors.email as string) || "Enter Email"}
                value={values.email}
                onChange={handleChange}
                isError={!!errors.email}
                errors={errors}
                setErrors={setErrors}
                maxLength={EMAIL_CHAR_LIMIT}
              />
            </div>
          </div>
        </ModalBody>
      </Modal>
      {error && (
        <ApiModal
          title="Add New Lead Failed"
          message={error}
          setIsShow={() => setError("")}
        />
      )}
    </>
  );
};

export default BillingContactModal;
