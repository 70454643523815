import config from 'config/config';
import axios from 'services/core/axios';

export const removeVendorNote = async (vendor_id: number, note_id: number) => {
    try {
        const res = await axios.delete(`${config.defaults.api_url}/v1/vendors/${vendor_id}/notes/${note_id}`);
        return res

    } catch (error: any) {
        return error
    }
}

export const postVendorNote = async (postVendorNoteReq: any) => {
    const { vendor_id, ...body } = postVendorNoteReq;
    try {
        const res = await axios.post(`${config.defaults.api_url}/v1/vendors/${vendor_id}/notes`, body);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const getVenue = async (venue_id: string) => {
    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/vendors/venues/${venue_id}`);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const putVenue = async (updateVenueReq: any) => {
    const { venue_id } = updateVenueReq;
    try {
        const res = await axios.put(`${config.defaults.api_url}/v1/vendors/venues/${venue_id}`, updateVenueReq);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const postInvoice = async (postInvoiceReq: any) => {
    const { vendor_id, email } = postInvoiceReq;
    try {
        const res = await axios.post(`${config.defaults.api_url}/v1/vendors/${vendor_id}/invoice`, {email});
        return res?.data;

    } catch (error: any) {
        return error
    }
}


