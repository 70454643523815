import VendorBackButton from "components/vendorBackButton";
import VendorName from "components/vendorName";
import VendorTabs from "components/vendorTabs";
import { VendorContext } from "contexts/vendor";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";

const backRouteMapper = {
    0: '/vendors',
    1: '#/',
    2: '#/'
}

const Vendor = () => {
    const navigate = useNavigate();
   // const [activeTab, setActiveTab] = useState(0);
    const { queryParams, setQueryParams, activeTab,  setActiveTab, } = useContext(VendorContext);


    const onClickBack = () => {
        setQueryParams({ ...queryParams, search: '' })
        const route = backRouteMapper[activeTab as keyof typeof backRouteMapper]
        navigate(route);
    }


    return (
        <>

            <div className="super-admin">
                <VendorBackButton onClickBack={onClickBack} />
                <VendorName />

                <div className="super-admin_admin-detail-container">
                    <VendorTabs
                        setActiveTab={setActiveTab}
                        activeTab={activeTab}
                                 
                    />
                </div>
            </div>

        </>
    )
}

export default Vendor;