import Button from "components/common/button";
import CopyToClipboardTooltip from "components/common/copyToClipboard";
import CrossButton from "components/common/crossButton";
import {Modal} from "components/common/modal";
import { Skeleton } from "components/common/skeleton";
import { useCopyToClipboard } from "hooks/useCopyToClipboard";
import { Suspense, lazy } from "react";
import a11yDark from 'react-syntax-highlighter/dist/esm/styles/hljs/a11y-dark';

const SyntaxHighlighter = lazy(() => import('react-syntax-highlighter'))

interface Props {
    html: string;
    closeModal: Function;
}

const HTMLModal = ({html, closeModal}: Props) => {
    const {onClickCopy, isCopied} = useCopyToClipboard();

    const onClickCopyHtml = () => {
        const highlighter = document.querySelector("#highlighter-container")?.querySelector("pre");
        onClickCopy(highlighter, html);
    }

    return (
        <>
            <Modal
                setShowModal={() => closeModal()}
                customClass={`add-modal view-html-modal`}
            >
                <div className="header">
                    <Button buttonName="Copy" onClick={onClickCopyHtml} />
                    <p className="app-modal-title">View HTML</p>
                    <div className="modal-actions">
                        <CrossButton
                            onClick={closeModal}
                            disabled={false}
                        />
                    </div>
                </div>

                <div id="highlighter-container" className="modal__body ">
                    <Suspense fallback={<Skeleton width={900} height={600} />}>
                        <SyntaxHighlighter
                            language="htmlbars"
                            style={a11yDark}
                        >
                            {html}
                        </SyntaxHighlighter>
                    </Suspense>
                    <CopyToClipboardTooltip isCopied={isCopied} />
                </div>
            </Modal>
        </>
    )
};

export default HTMLModal;
