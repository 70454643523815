import { VendorDetails } from "interfaces/onboarding";

export const useVenuesInitValues = (onboardingDetails: VendorDetails, defaultVenueState: any) => {
    const {venues_allowed, vendor} = onboardingDetails || {};
    const initValues = {
        contactForm: {
            name: vendor?.name || '',
            address: vendor?.address || '',
            zipcode: vendor?.zipcode || '',
            phone: vendor?.phone || '',
            customer_facing_email: vendor?.customer_facing_email || '',
            customer_facing_phone: vendor?.customer_facing_phone || ''
        },
        ...([...new Array(venues_allowed || 3)].reduce((acc: any, _: any, indx: number) => {
            acc[`venue${indx+1}`] = {
                id: indx+1,
                ...defaultVenueState
            }
    
            return acc;
        },{}))
    }

    return initValues;
}