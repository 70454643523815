import React, { FC } from 'react'



interface Props {
  stat: any,
  lable: string,
  customClass: string, 
  isWeeklySale?: boolean
}

const SingleStat:FC<Props> = ({stat, lable, customClass, isWeeklySale}) => {
  return (
    <div className={customClass}>
    <div className='figures'>
        <span>{isWeeklySale ? "$": ""}</span>{stat}
    </div>
    <p className='figuresDetail'>{lable}</p>

    </div>
  )
}

export default SingleStat
