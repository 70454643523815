import AdminModal from "components/admins/addAdminModal";
import EditAdminModal from "components/admins/editAdminModal";
import BottomBar from "components/common/bottomBar";
import Button from "components/common/button";
import DeleteIcon from "components/common/deleteIcon";
import EditIcon from "components/common/editIcon";
import InfoModal from "components/common/infoModal";
import MoreDataSkeleton from "components/common/moreDataSkeleton";
import Pagination from "components/common/pagination";
import SearchInput from "components/common/searchInput";
import TableSkeleton from "components/common/skeleton/tableSkeleton";
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeading,
  TableRow,
} from "components/common/table";
import NoData from "components/noData";
import SaveIcon from "components/venue/SaveIcon";
import { UserActions } from "contexts/reducers/user";
import { UserContext } from "contexts/user";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import { useLimit } from "hooks/useLimit";
import moment from "moment";
import { ChangeEvent, Fragment, useContext, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { getFullName } from "util/getFullName";

const enum ModalType {
  Delete = "delete",
  Edit = "edit",
}

const INIT_STATE = {
  type: "",
  data: null!,
};

const SuperAdmins = ({ vendor_id }: any) => {
  const {
    fetchSuperAdmins,
    superAdmins,
    addSuperAdmin,
    loading,
    updateSuperAdmin,
    deleteSuperAdmin,
    adminsPage,
    setAdminsPage,
    superAdminQueryParam,
    setSuperAdminQueryParam,
  } = useContext(UserContext);

  const {limit, skeletonsCount} = useLimit();

  const [showModal, setShowModal] = useState<any>(INIT_STATE);
  const [flash, setFlash] = useState<any>(-1);
  const [showAdminModal, setShowAdminModal] = useState(false);
  const [search, setSearch] = useState("");
  const [success, setSuccess] = useState(false);

  const fetchMoreAdmins = async () => {
    const nextPage = Math.ceil(superAdminQueryParam.offset/superAdminQueryParam.limit)+1;

    setAdminsPage(nextPage);
    const res = await getSuperAdmins({
      ...superAdminQueryParam,
      offset: nextPage*superAdminQueryParam.limit
    });

    return res;
  }

  const { handleScroll, scrollTop } = useInfiniteScroll(fetchMoreAdmins, 560);

  const debouncedSearch = useDebouncedCallback((value: string) => {
    setAdminsPage(0);
    getSuperAdmins({
      ...superAdminQueryParam,
      offset: 0,
      search: value,
      limit: limit,
    });
  }, 600);

  const onAddNewAdmin = () => {
    setShowAdminModal(true);
  };

  const onPageChange = (page: number) => {
    getSuperAdmins({
      ...superAdminQueryParam,
      limit: limit,
      offset: page * limit,
    });
    setAdminsPage(page);
  };

  const onSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  const onClickDelete = (item: any) => {
    setShowModal({
      type: ModalType.Delete,
      data: item,
    });
  };

  const onClickEdit = (item: any) => {
    setShowModal({
      type: ModalType.Edit,
      data: item,
    });
  };

  const onDeleteAdmin = async () => {
    const isDeleted = await deleteSuperAdmin(showModal.data.id);

    if (isDeleted) {
      setShowModal(INIT_STATE);
    }

    return isDeleted;
  };

  const getSuperAdmins = async (params: any) => {
    setSuperAdminQueryParam(params);
    const res = await fetchSuperAdmins(
      vendor_id ? { ...params, vendor_id } : params
    );
    return res;
  };

  const onSetFlash = async (recordIndex: number) => {
    const newPage = Math.floor(recordIndex / 10);
    setAdminsPage(newPage);

    if (newPage > 0) {

      await getSuperAdmins({
        ...superAdminQueryParam,
        offset: newPage * limit,
      });
    }

    const offsetIndex = recordIndex % limit;

    setFlash(offsetIndex);
    setTimeout(() => {
      setFlash(-1);
    }, 1000);
  };

  useEffect(() => {
    if(admins.length) return;
    
    if (search) getSuperAdmins({ ...superAdminQueryParam, limit: limit });
    else getSuperAdmins({ ...superAdminQueryParam, limit: limit, search: "" });

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const { admins, _count } = superAdmins;

  const noOfPages = Math.ceil(_count > limit ? _count / limit : 0);
  const isLoading = loading[UserActions.SET_SUPER_ADMINS];
  const addAdminLoading = loading[UserActions.ADD_SUPER_ADMIN];
  const isDeleteLoading = loading[UserActions.DELETE_SUPER_ADMIN];

  useEffect(() => {
    const sucessTimer = setTimeout(() => {
      if (success) setSuccess(false);
    }, 3000);

    return () => {
      clearTimeout(sucessTimer);
    };
  }, [success]);

  return (
    <div>
      {
        <div className={`save-order ${success ? "save-order-animation" : ""}`}>
          <span>
            {" "}
            <SaveIcon /> Saved
          </span>
        </div>
      }
      <div className="super-admins-actions">
        <div className="addVendor-btn">
          <Button buttonName="Add Admin" onClick={onAddNewAdmin} width={175} />
        </div>
        <SearchInput
          isClear
          placeholder="Search"
          value={search}
          onChange={onSearch}
        />
      </div>

      <div className="admin-data-table">
        <Table>
          <TableHead>
            <TableHeading customClass="name">Name</TableHeading>
            <TableHeading customClass="email email-max">Email</TableHeading>

            <TableHeading customClass="last-event">
              <p>Last Login</p>
            </TableHeading>
            <TableHeading customClass="option">Options</TableHeading>
          </TableHead>
          <TableBody
            onScroll={(e: any) => handleScroll(e, admins.length > _count)}
            isShowBlur={_count > skeletonsCount && scrollTop < 10}
          >
            {isLoading ? (
              <TableSkeleton count={skeletonsCount} />
            ) : admins.length > 0 ? (
              admins.map((item: any, index: number) => {
                const { id, first_name, last_name, last_login, email } = item;
                const name = `${first_name || ""} ${last_name || ""}`;
                return (
                  <Fragment key={id}>
                    <TableRow
                      flash={index === flash}
                      nonClickableRow
                      cursorPointer={true}
                    >
                      <TableData customClass="name">{name}</TableData>
                      <TableData customClass="email email-max">
                        {email || ""}
                      </TableData>

                      <TableData customClass="last-event">
                        {last_login
                          ? moment(last_login).format("M/D/YY")
                          : "Not Yet"}
                      </TableData>
                      <TableData customClass="option">
                        <EditIcon onClick={() => onClickEdit(item)} />
                        <DeleteIcon onClick={() => onClickDelete(item)} />
                      </TableData>
                    </TableRow>
                  </Fragment>
                );
              })
            ) : (
              <NoData description="No Super Admins" />
            )}

            <MoreDataSkeleton
                isShow={superAdminQueryParam.offset && loading[UserActions.SET_MORE_SUPER_ADMINS]}
            />
          </TableBody>
        </Table>
      </div>

      <BottomBar
        showAdd
        showSearch
        onClickAdd={onAddNewAdmin}
        addActive={false}
      />

      <Pagination
        activePage={adminsPage}
        count={noOfPages}
        onClickArrow={onPageChange}
      />

      {showAdminModal && (
        <AdminModal
          addAdmin={addSuperAdmin}
          closeModal={() => setShowAdminModal(false)}
          setFlash={onSetFlash}
          loading={addAdminLoading}
          title="Add Super Admin"
        />
      )}

      {showModal.type === ModalType.Delete && (
        <InfoModal
          onSubmit={onDeleteAdmin}
          closeModal={() => setShowModal(INIT_STATE)}
          submitLoading={isDeleteLoading}
          title={`Delete ${getFullName(
            showModal.data?.first_name,
            showModal.data?.last_name
          )}`}
          description={`Are you sure you want to delete admin ${getFullName(
            showModal.data?.first_name,
            showModal.data?.last_name
          )} ?`}
          submitButtonName="Delete"
        />
      )}

      {showModal.type === ModalType.Edit && (
        <EditAdminModal
          closeModal={() => setShowModal(INIT_STATE)}
          data={showModal.data}
          updateAdmin={updateSuperAdmin}
          loading={loading[UserActions.EDIT_SUPER_ADMIN]}
          title="Edit Super Admin"
          setSuccess={setSuccess}
        />
      )}
    </div>
  );
};

export default SuperAdmins;
