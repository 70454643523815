import { AuthContext } from "contexts/auth";
import { useContext, useState } from "react";

interface Props {
    tabs: any;
    activeTab: number;
    onClickTab: Function;
    children: any;
    customClass?: string;
    disbaledIndex?: number[];
    isVendorDetails?: boolean;
    status?: string;
    onClickNotes?: Function;
    disabledNotes?: boolean;
    notesCount?: number;
    isNotesActive?: boolean;
    tabLabels?: any;
    isOnboardingPending?:any;

}

const Tabs = ({tabLabels, isNotesActive, disabledNotes, notesCount, isVendorDetails, status, children, customClass, tabs, activeTab, disbaledIndex, onClickTab, onClickNotes, isOnboardingPending}: Props) => {
    const [, setSelectedTab] = useState(tabs.length > 0 ? tabs[0] : '')
    const {vendorDetailsEditing} = useContext(AuthContext)
    const onSelectTab = (tab: string, index: number) => {
        setSelectedTab(tab.toLowerCase());
        onClickTab(index);
    }

    return (
        <>
            <div className="tabs-container">
                <div className={`tabs ${customClass || ""}`}>
                    {isVendorDetails && (
                        <span 
                        
                            onClick={() => !disabledNotes && onClickNotes && !vendorDetailsEditing && onClickNotes()} 
                            className={`${isNotesActive ? 'vendor-notes-active' : ''} ${disabledNotes ? 'disabled' : 'cursor-pointer'} vendor-details-notes`} style={ vendorDetailsEditing ? {opacity: 0.4}: {}}
                        >
                            <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" viewBox="0 0 22 21" fill="none">
                                <g clipPath="url(#clip0_6282_695)">
                                    <path d="M15.1669 19.2065C15.1669 19.5644 14.8809 19.8552 14.5291 19.8552H1.7633C1.41147 19.8552 1.12551 19.5644 1.12551 19.2065V1.79346C1.12551 1.43561 1.41147 1.14476 1.7633 1.14476H11.2459V3.72683C11.2459 4.37892 11.7678 4.90975 12.409 4.90975H15.1669V7.41975L16.2924 6.19443V4.60024C16.2924 4.54936 16.2882 4.49933 16.2816 4.44846C16.2882 4.41284 16.2924 4.37553 16.2924 4.33737C16.2924 4.13386 16.1874 3.95578 16.0306 3.85403C15.9989 3.81417 15.9656 3.77601 15.9297 3.74125L12.369 0.323925C12.3348 0.290854 12.2981 0.261175 12.2606 0.233192C12.158 0.0924288 11.9938 0 11.8079 0C11.7737 0 11.7403 0.00339188 11.7087 0.00932768C11.6628 0.00339188 11.6161 0 11.5694 0H1.7633C0.791193 0 0 0.804724 0 1.79346V19.2065C0 20.1953 0.791193 21 1.7633 21H14.5291C15.5012 21 16.2924 20.1953 16.2924 19.2065V13.9254L15.1669 15.1507V19.2065ZM12.3715 1.90285L14.3165 3.76499H12.409C12.3881 3.76499 12.3715 3.74803 12.3715 3.72683V1.90285Z" fill="white"/>
                                    <path d="M21.5963 5.47226L20.4308 4.36481C19.9172 3.87722 19.1127 3.90521 18.6333 4.42671L17.7471 5.3917L20.7709 8.26463L21.6572 7.29964C22.1366 6.77729 22.1091 5.95899 21.5963 5.47141V5.47226Z" fill="white"/>
                                    <path d="M10.2172 13.5916L9.28516 17.6848L13.2411 16.4646L20.4752 8.58777L17.4514 5.71484L10.2172 13.5916ZM10.8859 16.3425C10.8467 16.2712 10.79 16.1975 10.7191 16.1296C10.6474 16.0618 10.5716 16.0092 10.499 15.9744L10.875 14.3234L12.4799 15.8481L10.8859 16.3425ZM18.7003 8.53774C18.8445 8.67511 18.852 8.90491 18.7178 9.05161L13.4571 14.7797C13.322 14.9264 13.0961 14.934 12.9518 14.7975C12.8076 14.6609 12.8001 14.4303 12.9343 14.2836L18.195 8.55555C18.3301 8.40885 18.556 8.40122 18.7003 8.53774ZM17.639 8.02726L12.3782 13.7553C12.2432 13.902 12.0172 13.9096 11.873 13.7731C11.7288 13.6357 11.7213 13.4059 11.8555 13.2592L17.1162 7.5312C17.2513 7.3845 17.4772 7.37687 17.6214 7.51339C17.7657 7.64991 17.7732 7.88056 17.639 8.02726Z" fill="white"/>
                                    <path d="M9.28551 4.45215H3.21191V5.59691H9.28551V4.45215Z" fill="white"/>
                                    <path d="M9.28551 7.90234H3.21191V9.0471H9.28551V7.90234Z" fill="white"/>
                                    <path d="M9.28551 11.3525H3.21191V12.4973H9.28551V11.3525Z" fill="white"/>
                                    <path d="M5.9765 14.8027H3.21191V15.9475H5.9765V14.8027Z" fill="white"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_6282_695">
                                    <rect width="22" height="21" fill="white"/>
                                    </clipPath>
                                </defs>
                            </svg>
                            {!isNotesActive && !!notesCount && (
                                <div className="edit-number">{notesCount}</div>
                            )}
                        </span>
                    )}
                    {tabs.map((tabName: string, index: number) => {
                        const isDisbaled = disbaledIndex?.includes(index) || (isOnboardingPending && tabName !=="Signage");
                        const labelText = tabLabels && tabLabels[index];
                        return (
                            <p style={{cursor: isDisbaled ? 'default' : 'pointer'}} key={index} onClick={() => !isDisbaled && onSelectTab(tabName, index)} className={`tab-item tab-detail ${isDisbaled ? 'disabled' : ''} ${activeTab === index ? 'active' : ''}`}>{tabName}
                                {!!labelText && (
                                    <span className="active">{labelText}</span>
                                )}
                            </p>
                        )
                    })}
                    {isVendorDetails && status && (
                        <div className="status-label">
                            <p className="status-label-text">{status || ''}</p>
                        </div>
                    )}
                </div>
            </div>
            
            {children}
        </>
    )
}

export default Tabs;