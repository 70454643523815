import React, { useContext } from "react";
import UpComingEvents from "../../UpComingEvents";
import TopSellingProducts from "./TopSellingProducts";
import { VendorAdminDashboardContext } from "contexts/vendorAdminDashboard";
import { VendorAdminDashboardActions } from "contexts/reducers/vendorAdminDashboard";
import { superAdminDashboardActions } from "contexts/reducers/superAdminDashboard";
const VendorDashboardBody = () => {
  const { dashboardData, loading, dashboardQueryParams, fetchDashboardData, setDashboardReducer } = useContext(VendorAdminDashboardContext);
  const { events, events_count } = dashboardData;

    const fetchData = async (isEvent: boolean) => {
        const {event_limit, event_offset,limit, offset } = dashboardQueryParams;

        let newParams = {...dashboardQueryParams, event_offset: event_limit*((Math.ceil(event_offset/event_limit))+1)};
        if(!isEvent){
            newParams = {...dashboardQueryParams, offset: limit*((Math.ceil(offset/limit))+1)};
        }

        let type = superAdminDashboardActions.GET_MORE_EVENTS_DATA
        if(!isEvent){
            type = superAdminDashboardActions.GET_MORE_SALES_DATA
        }

        
        setDashboardReducer(VendorAdminDashboardActions.SET_DASHBOARD_QUERY_PARAMS, newParams);
        const res = await fetchDashboardData(newParams, type);
        return res;
    }



    const fetchEvents = async (queryParams: any)=> {

        setDashboardReducer(VendorAdminDashboardActions.SET_DASHBOARD_QUERY_PARAMS, {...queryParams, event_limit: 15, event_offset: 0});
        const res = await fetchDashboardData({...queryParams, event_limit: 15, event_offset: 0}, VendorAdminDashboardActions.GET_EVENTS_LOADING);
        return res;

    }


  const isLoading = loading[VendorAdminDashboardActions.GET_DASHBOARD_DATA];
  const eventsLoading = loading[VendorAdminDashboardActions.GET_EVENTS_LOADING];

  return (
    <div className="vendorDashboardBody">
        <UpComingEvents 
            isEventFetching={loading[VendorAdminDashboardActions.GET_MORE_EVENTS_DATA]} 
            event_offset={dashboardQueryParams.event_offset} 
            eventsList={events} 
            events_count={events_count} 
            isLoading={isLoading} 
            fetchData={() => fetchData(true)} 
            fetchEvents = {fetchEvents} 
            dashboardQueryParams = {dashboardQueryParams} 
            eventsLoading = {eventsLoading} 
        />
        <TopSellingProducts 
            fetchData={() => fetchData(false)}
        />
    </div>
  );
};
export default VendorDashboardBody;
