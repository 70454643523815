export const removeSpecialCharsAndWhiteSpaces = (value: string) => {
    return value.replaceAll(/[^A-Z0-9]/ig, '');
}

export const formatPhone = (value: any) => {
    let formattedPhone="";

    for(let i=0; i<value.length; i++){
        switch(i){
            case 0:
                formattedPhone += '(';
                break;
            case 3:
                formattedPhone += ') ';
                break;
            case 6:
                formattedPhone += '-';
                break;
            default:
        }

        formattedPhone += value[i];
    }

    return formattedPhone;
}