import React from "react"
import PlaceMenuItem from "./menuItem";

const PlacesDropdown = ({width, places, onClickPlace}: any) => {

    return (
        <div style={{...(width && {width})}} className="pac-container pac-logo">
            {places.map((item: any) => {
                return (
                    <PlaceMenuItem 
                        place={item}
                        onClickPlace={() => onClickPlace(item)}
                    />
                )
            })}
        </div>
    )
};

export default PlacesDropdown;
