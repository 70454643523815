import { SearchableSelect } from "components/common/customReactSelect/customReactSelect";
import Label from "components/common/label";
import { AuthContext } from "contexts/auth";
// import { ReportActions } from "contexts/reducers/report";
import { ReportContext } from "contexts/report";
import { useIsSuperAdmin } from "hooks/useIsSuperAdmin";
import Cookies from "js-cookie";
//import { useIsSuperAdmin } from "hooks/useIsSuperAdmin";
import { useContext } from "react";
import { useDebouncedCallback } from "use-debounce";


const VenueDropdown = ({ selectedVenue, onSelectVenue }: any) => {
  const { selectedVendor, fetchVenues, venueQueryParams, setAllEvents, venueList, setSelectedVenue, setEventList, setEventDetails, resetOrders, setEvents, setSelectedEvent, venueOpen, setVenueOpen, } =
    useContext(ReportContext);
const isSuperAdmin = useIsSuperAdmin()
  // const [open, setOpen] = useState<boolean | undefined>(venueOpen);
  const { user } = useContext(AuthContext);
  const allVenuesObject = {id: 0, name: 'All Venues'};

  const onClickVenue = (item: {name: string, value: number}) => {
    const selectedVen = venues?.find(ven => ven.id === item.value)
    
    onSelectVenue(selectedVen ? selectedVen : allVenuesObject);
    setEventDetails({
      event: null!,
      event_sales: null!,
      items: [],
      formattedItems: [],
      printFormattedItems: [],
    });

    setAllEvents({
      events: [],
      _count: 0,
    })
    setEvents({
      events: [],
      _count: 0,
    });
    setSelectedEvent(null!);
  };

//   const dropdownToggle = () => {
//     setVenueOpen((prev: boolean) => !prev);  
//     eventOpen && setEventOpen(false);
//     vendorOpen && setVendorOpen(false)
//   }
  
  const { venues } = venueList;

  const onOpen = ()=>{
    console.log("its here")
    setSelectedVenue(null);
    setEventList({events: [], _count:0});
    setSelectedEvent(null!);

    setEventDetails({
      event: null!,
      event_sales: null!,
      items: [],
      formattedItems: [],
      printFormattedItems: []
  });
  setAllEvents({
    events: [],
    _count: 0,
  })
  setEvents({
    events: [],
    _count: 0,
  });
  
  resetOrders({ orders: [],
    _count:0}) 
  }


  const onClose = ()=>{

   

  } 




  const venuesOptionsList: any = venues?.map(venue => ({label: venue.name, value: venue.id}))
  if(venuesOptionsList?.length>0) venuesOptionsList.unshift({value: allVenuesObject.id, label: allVenuesObject.name})

  const placeHolderText = venueOpen ? "" : "Select Venue"




  const debouncedSearch = useDebouncedCallback((value: string) => {

    if (!isSuperAdmin) {
      const id = user?.vendor_id || Cookies.get("vendor_id");
      id && fetchVenues(
        { vendor_id: id, ...venueQueryParams, search: value }
        
        );
      }
      else {

        const id = selectedVendor?.id
        id && fetchVenues(
          { vendor_id: id, ...venueQueryParams, search: value }
          
          );


      }
   
    },100)


  const onTextChange = (val: any)=>{
   
      debouncedSearch(val);
    

  }

  
  return (
    <div className="venue-dropDown-container custom-dropdown">
      <Label text="Venue" inputLabel />
      <div className={`${ venueOpen ? 'report-venue-dropdown' : ''}`}>
        <SearchableSelect 
          placeholder={placeHolderText}
          value={selectedVenue ? {label: selectedVenue.name, value: selectedVenue.id} : null}
          onChange={(value: any) => onClickVenue(value)}
          options={venuesOptionsList}
          isOpen={venueOpen}
          setIsOpen={setVenueOpen}
          onOpen={onOpen}
          onClose= {onClose}
          onTextChange={onTextChange}
        />
      </div>
      {venueOpen && <div className="overlay" onClick={() => setVenueOpen(false)}></div>}
    </div>
  );
};

export default VenueDropdown;
