import ApiModal from "components/common/apiErrorModal";
import CheckMarkButton from "components/common/checkMarkButton";
import CrossButton from "components/common/crossButton";
import Input from "components/common/input";
import { Modal, ModalHeader, ModalBody } from "components/common/modal";
import { useFormik } from "formik";
import { useIsDisabled } from "hooks/useIsDisabled";
import { Admin } from "interfaces/vendor";
import { useState } from "react";
import { editAdminSchema } from "util/validationSchema/adminSchemas";

interface Props {
    closeModal: Function;
    data: Admin;
    updateAdmin: Function;
    loading: boolean;
    title: string;
    setSuccess?: Function
}

const EditAdminModal = ({closeModal, updateAdmin, loading, data, title, setSuccess}: Props) => {
    const [error, setError] = useState('');

    const initialValues = {
        first_name: data?.first_name || '',
        last_name: data?.last_name || '',
        email: data?.email || ''
    }

    const formik = useFormik({
        initialValues,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: editAdminSchema,
        onSubmit: async (values: any) => {
            const res = await updateAdmin({...values, admin_id: data?.id});
            
            if(res?._status?.code === 200){
                
                setSuccess && setSuccess(true)
                closeModal();
                
            }

            if(res?.response?.data?.message || res?.response?.data?.error){
                setError(res?.response?.data?.message || res?.response?.data?.error)
            }
        }
    });
    const {values, errors, handleChange, setErrors, handleSubmit} = formik;
    
    const isDisabled = useIsDisabled(values, errors);

    return (
        <>
            <Modal
                setShowModal={() => closeModal()}
                customClass={`add-modal ${error ? 'disabled' : ''}`}
            >
                <ModalHeader>
                    <div className="modal-actions">
                        <CrossButton
                            onClick={closeModal}
                            disabled={loading}
                        />
                        <CheckMarkButton
                            onClick={handleSubmit}
                            width={47}
                            height={47}
                            activeDisabled={isDisabled}
                            disabled={loading}
                            isForm
                        />
                    </div>
                    <p className="app-modal-title">{title}</p>
                </ModalHeader>

                <ModalBody>
                    <div className="add-lead-form">
                        <div className="row">
                            <div className="six-columns-sm columns">
                                <Input
                                    name="first_name"
                                    label="First Name"
                                    placeholder={errors?.first_name as string || 'Enter first name'}
                                    value={values?.first_name}
                                    onChange={handleChange}
                                    isError={!!(errors?.first_name)}
                                    errors={errors}
                                    setErrors={setErrors}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="six-columns-sm columns">
                                <Input
                                    name="last_name"
                                    label="Last Name"
                                    placeholder={errors?.last_name as string || 'Enter last name'}
                                    value={values?.last_name}
                                    onChange={handleChange}
                                    isError={!!(errors?.last_name)}
                                    setErrors={setErrors}
                                    errors={errors}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="eight-columns-sm columns">
                                <Input 
                                    label="Email"
                                    placeholder={errors?.email as string || 'Enter Email'}
                                    name="email"
                                    value={values?.email}
                                    onChange={handleChange}
                                    isError={!!(errors?.email)}
                                    setErrors={setErrors}
                                    errors={errors}
                                />
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>

            {error && (
                <ApiModal 
                    title="Edit Admin Failed"
                    description={error}
                    setIsShow={() => setError('')}
                />
            )}
        </>
    )
};

export default EditAdminModal;
