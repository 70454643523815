import ProfilePage from 'components/profile';

const Profile = () => {
    
    console.log('profile rendered');
    return (
        <>
            <ProfilePage />
        </>
    )
}

export default Profile;