import ApiModal from "components/common/apiErrorModal";
import { ChangeEvent, useState } from "react";
import AddressField from "./addressField";
import InstructionModal from "../instructionModal";
import { uploadResource } from "services/common";
import { ImageFor } from "interfaces/vendor";
import Input from "components/common/input";
import Select from "components/common/select";
import Switch from "components/common/switch";
import Label from "components/common/label";
import { deleteObjectProp } from "util/deleteObjectProp";
import { DeleteIconBlack } from "components/common/deleteIcon";
import VenueLogo from "./venueLogo";
import CropImageModal from "./cropImageModal";
import PhoneField from "components/common/phoneField";

const USA_STATES = ['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY']
const imagesTypes = ['image/png', 'image/jpg', 'image/gif', 'image/jpeg']
interface IProps {
    venueName: string;
    id: string;
    formData: any;
    setFormsData: Function;
    name: string;
    handleChange: (e: ChangeEvent<any>) => void;
    errors: any;
    setErrors?: Function;
    onClickDelete?: Function;
    venuesCount: number;
}

const INIT_ERROR_STATE = {
    title: '',
    message: ''
}

const VenueForm = ({venuesCount, setErrors, onClickDelete, handleChange, venueName, id, formData, setFormsData, errors, ...props}: IProps) => {
    // const autoComplete = useRef<any>(null);
    const [error, setError] = useState(INIT_ERROR_STATE);
    const [imageUploading, setImageUploading] = useState(false);
    const [logoUploading, setLogoUploading] = useState(false);
    const [showInstructionModal, setShowInstructionModal] = useState(false);
    const [targetName, setTargetName] = useState<any>(null!);
    const [imgUrl, setImgUrl] = useState<any>('');
    const [fileType, setFileType] = useState<any>('');
    const [zipcodeDisabled, setZipcodeDisabled] = useState(false);


    const onClickPlace = (data: any) => {
        setZipcodeDisabled(!!data.zipcode);
        setFormsData(props.name, {
            ...formData,
            ...data
        });

        const state = data?.state;
        const stateIndex = USA_STATES.findIndex((item: string) =>  item === state);
        if(stateIndex === -1){
            const option = document.createElement("option");
            option.text = data?.state;
            option.value = data?.state;
            const selectEl = document.getElementById(`state-select-${id}`);
            selectEl?.appendChild(option);
        }
    }

    const setFormValue = (name: string, value: any) => {
        setFormsData(props.name, {
            ...formData,
            [name]: value
        })
    }

    
    const onImageUpload = async (fieldName: string, image: any) => {
        fieldName === 'map_url' ? setImageUploading(true) : setLogoUploading(true);
        const res = await uploadResource({
            file: image,
            image_for: ImageFor.Map
        });

        fieldName === 'map_url' ? setImageUploading(false) : setLogoUploading(false);
        if(res?.url){
            setFormValue(fieldName, res?.url);
            onClickCancel(fieldName);
            setTargetName(null!);
        }

        const errorMessage = res?.response?.data?.message || res?.response?.data?.error;
        if(errorMessage){
            setError({
                title: 'Image Upload Failed',
                message: errorMessage
            })
        }
    }

    const uploadImage = (e: ChangeEvent<HTMLInputElement>) => {
        if(e.target.files && e.target.files.length > 0){
            const file = e.target.files[0];
            if(!(['image/png', 'image/jpeg', 'image/jpg', 'image/gif' ].includes(file.type))){
                setError({
                    title: 'Upload Image Failed',
                    message: 'Wrong format! Only png, jpg, jpeg or gif image is allowed.'
                });
                return;
            }
            
            const objectURL = URL.createObjectURL(file);
            const img = document.createElement('img');

            img.onload = () => {
                if(img.width > 600 || img.height > 600){
                    file && setTargetName(e.target.name);
            
                    const url = URL.createObjectURL(file);
                    setImgUrl(url);
                    setFileType(file.type);
                }else{
                    onImageUpload(e.target.name, file);
                }

                URL.revokeObjectURL(objectURL);
            }

            img.src = objectURL;
        }
    }

    const onClickDelivery = () => {
        setError({
            title: 'Seat Delivery',
            message: 'This feature is not available yet.'
        });
    }

    const onClickCancel = (fieldName: string) => {
        const newErrors = deleteObjectProp(errors[props.name], fieldName)
        
        newErrors && setErrors && setErrors({
            ...errors,
            [props.name]: newErrors
        })
        
    }

    return (
        <>
            <div className="venue_venueForm">
                <div className="venue_venueForm_header">
                    {venueName || ''}
                </div>
                <div className="venue_venueForm_body">
                    <div className="venue_venueForm_body-form_container">
                        <div className="venue_venueForm_body_info">
                            <div className="venue_venueForm_body_formGroup name">
                                <Input
                                    value={formData?.name}
                                    label="Name"
                                    name={`${props.name}.name`}
                                    placeholder={errors[props.name]?.name || 'Enter Venue Name'}
                                    isError={!!(errors[props.name]?.name)}
                                    onChange={handleChange}
                                    onClickCross={() => onClickCancel('name')}
                                />
                            </div>
                            <AddressField
                                id={`autoComplete-${id}`}
                                setFormValue={setFormValue}
                                onClickPlace={onClickPlace}
                                address={formData?.address || ''}
                                error={errors[props.name]?.address}
                                onClickCross={(fieldName: string) => onClickCancel(fieldName)}
                            />
                            <div className="venue_venueForm_body_info_formGroupInline">
                                <div className="venue_venueForm_body_formGroup city">
                                    <Input
                                        label="City"
                                        name={`${props.name}.city`}
                                        type="text"
                                        onChange={handleChange}
                                        value={formData?.city || ''}
                                        placeholder={errors[props.name]?.city || 'Enter City'}
                                        onClickCross={() => onClickCancel('city')}
                                        isError={!!(errors[props.name]?.city)}
                                    />
                                </div>
                                <div className="venue_venueForm_body_formGroup state">
                                    <Label inputLabel text="State" />
                                    <Select
                                        placeholder={errors[props.name]?.state || 'XX'}
                                        isError={!!(errors[props.name]?.state)}
                                        name={`${props.name}.state`}
                                        id={`state-select-${id}`}
                                        value={formData?.state || ''}
                                        onChange={handleChange}
                                        options={
                                            USA_STATES.map((item: any) => ({label: item, value: item}))
                                        }
                                        onClickCross={() => onClickCancel('state')}
                                    />
                                </div>
                            </div>
                            <div className="venue_venueForm_body_info_formGroupInline">
                                <div className="venue_venueForm_body_formGroup zip">
                                    <Input
                                        type="number"
                                        label="Zip"
                                        name={`${props.name}.zipcode`}
                                        value={formData?.zipcode || ''}
                                        onChange={(e: any) => {
                                            if(e.target.value.length > 5){
                                                return;
                                            }
                                            handleChange(e);
                                        }}
                                        placeholder={errors[props.name]?.zipcode || 'Enter Zip'}
                                        customClass={errors[props.name]?.zipcode ? 'input-error' : ''}
                                        pattern="^(0|[1-9][0-9]*)"
                                        onClickCross={() => onClickCancel('zipcode')}
                                        isError={!!(errors[props.name]?.zipcode)}
                                        disabled={zipcodeDisabled}
                                    />
                                </div>
                                <div className="venue_venueForm_body_formGroup number">
                                    <PhoneField
                                        label="Phone"
                                        name={`${props.name}.phone`}
                                        placeholder={errors[props.name]?.phone || '405-123-4567'}
                                        value={formData?.phone || ''}
                                        setValue={setFormsData}
                                        onClickCancel={() => onClickCancel('phone')}
                                        errors={errors}
                                        errorMessage={errors[props.name]?.phone}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="venue_venueForm_body_types">
                            <div className="venue_venueForm_body_formGroup type">
                                <Label inputLabel text="Venue Type" />
                                <Select
                                    placeholder={errors[props.name]?.venue_type || 'Select Venue Type'}
                                    isError={!!(errors[props.name]?.venue_type)}
                                    id={`state-select-${id}`}
                                    name={`${props.name}.venue_type`}
                                    value={formData.venue_type}
                                    onChange={handleChange}
                                    options={[
                                        {label: 'Baseball', value: 'Baseball'},
                                        {label: 'Basketball', value: 'Basketball'},
                                        {label: 'Soccer', value: 'Soccer'},
                                        {label: 'Hockey', value: 'Hockey'},
                                        {label: 'Other', value: 'Other'}
                                    ]}
                                    onClickCross={() => onClickCancel('venue_type')}
                                />
                            </div>  
                            <div className="venue_venueForm_body_types_orderType">
                                <span className="venue_venueForm_body_types_orderType_typesLabel">Order Types</span>
                                <div className="venue_venueForm_body_types_orderType_toogleSwitch">
                                    <span>Concession Pickup</span>
                                    <Switch 
                                        name={`${props.name}.concession_pickup`}
                                        onChange={handleChange}
                                        checked={formData?.concession_pickup}
                                    />
                                </div>
                                <div className="venue_venueForm_body_types_orderType_toogleSwitch">
                                    <span>Chow Stations</span>
                                    <Switch
                                        name={`${props.name}.chow_stations`}
                                        onChange={handleChange}
                                        checked={formData?.chow_stations}
                                    />
                                </div>
                                <div className="venue_venueForm_body_types_orderType_toogleSwitch">
                                    <span>Seat Delivery</span>
                                    <Switch
                                        name={`${props.name}.seat_delivery`}
                                        onChange={onClickDelivery}
                                        checked={formData?.seat_delivery}
                                    />
                                </div>
                            </div>
                            <div className="venue_venueForm_body_types_orderType">
                                <span className="venue_venueForm_body_types_orderType_typesLabel">Scheduled Orders</span>
                                <div className="venue_venueForm_body_types_orderType_toogleSwitch">
                                    <span>Scheduled Orders</span>
                                    <Switch
                                        name={`${props.name}.scheduled_enabled`}
                                        checked={formData?.scheduled_enabled}
                                        onChange={handleChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="venue_venueForm_body_upload">
                            <Label inputLabel text="Diagram" />
                            <div style={{border: errors && errors[props.name]?.map_url ? '1px solid red' : 'none'}} className="venue_venueForm_body_upload_diagramVenue">
                                <div className={`${imageUploading ? 'disabled' : ''} cursor-pointer venue_venueForm_body_upload_diagramVenue_chooseImg`}>
                                    {imageUploading ? 'Uploading...' : 'Choose Image'}
                                    <input
                                        name="map_url"
                                        type="file"
                                        onChange={(e: any) => {
                                            const file = e.target?.files?.length > 0 ? e.target.files[0] : null;
                                            if(!imagesTypes.includes(file?.type) && file?.type !== undefined ){
                                                setError({
                                                    title: 'Upload Image Failed',
                                                    message: 'Wrong format! Only png, jpg, jpeg or gif image is allowed.'
                                                });
                                                return;
                                            }
                                            file && onImageUpload('map_url', file)
                                        }}
                                        disabled={imageUploading}
                                        accept="image/png, image/jpg, image/gif, image/jpeg"
                                    />
                                </div>
                                {formData?.map_url ? (
                                    <img src={formData?.map_url} alt="map" />
                                ):(
                                    <>
                                        <p>Upload a diagram of the venue.</p>
                                        <a 
                                            onClick={() => {
                                                // document.body.style.overflow = 'hidden';
                                                setShowInstructionModal(true);
                                            }} 
                                            href="#/"
                                        >
                                            View Instructions
                                        </a>
                                    </>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="del-icon">
                        <DeleteIconBlack disabled={venuesCount < 2} onClick={() => onClickDelete && onClickDelete(props.name)} />
                    </div>
                    
                </div>
                <VenueLogo 
                    onImageUpload={uploadImage}
                    venueName={venueName}
                    venue_logo={formData.venue_logo}
                    loading={logoUploading}
                    isError={!!(errors[props.name]?.venue_logo)}
                />
            </div>
            
            {error.message && (
                <ApiModal 
                    title={error.title}
                    message={error.message}
                    setIsShow={() => setError(INIT_ERROR_STATE)}
                />
            )}
            {showInstructionModal && (
                <InstructionModal 
                    setShowModal={setShowInstructionModal}
                />
            )}

            {targetName && (
                <CropImageModal 
                    closeModal={() => setTargetName(null!)}
                    image={imgUrl}
                    onCrop={(croppedImg: any) => onImageUpload(targetName, croppedImg)}
                    loading={targetName === 'map_url' ? imageUploading : logoUploading}
                    fileType={fileType}
                />
            )}
        </>
    )
};

export default VenueForm;
