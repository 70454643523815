
/* eslint-disable no-useless-escape */
import { ChangeEvent } from "react";
import Input from "../input";

const ZipField = ({disbaled, label, name, value, placeholder, onChange, isError, errors, setErrors}: any) => {
    const onChangeZip = (e: ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;

        // const regexPatt = /[^a-zA-Z!``@~$%&*()_,.?:"+{}=#<>\[\]|<>\/\\\^]/;
        const regexPatt = /^[0-9]/
        const isValidChar = value ? value?.split('')?.every((char: string) => regexPatt.test(char)) : true;
        if(!isValidChar || value.length > 5){
            return;
        }

        onChange(e);
    }
    return (
        <>
            <Input
                label={label}
                name={name || "zipcode"}
                placeholder={placeholder}
                value={value}
                onChange={onChangeZip}
                isError={isError}
                errors={errors}
                setErrors={setErrors}
                disabled={disbaled}
            />
        </>
    )
};

export default ZipField;
