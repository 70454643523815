import { AuthContext } from "contexts/auth";
import { VendorContext } from "contexts/vendor";
import React, { useContext, useEffect, useState } from "react";
import SectionHeader from "./sectionHeader";
import { VendorDetailSections } from ".";
import { useFormik } from "formik";
import * as Yup from "yup";
import { VendorBillingStatuses } from "../../util/enums/VendorBillingStatuses";
import { useIsDisabled } from "hooks/useIsDisabled";
import { VendorActions } from "contexts/reducers/vendor";
import EditableInput from "components/common/editableInput";
import LabelSkeleton from "components/common/skeleton/label";
import Label from "components/common/label";
import moment from "moment";
import Button from "components/common/button";
import ApiModal from "components/common/apiErrorModal";
import SaveIcon from "components/venue/SaveIcon";
import VendorHistoryModal from "./VendorHistoryModal";
import {VendorTypes} from "util/enums/Vendors"
import InfoModal from "components/common/infoModal";
import RenewalInput from "components/common/editableInput/RenewalInput";

const Billing = ({ data, setActiveEdit, activeEdit }: any) => {
  const { updateVendorDetails, loading } = useContext(VendorContext);
  
  const billing = data?.billing;
  const {
    current_rate,
    high_schools,
    history,
    invoice_created_date,
    invoice_url,
    renewal_fee,
    status,
  } = billing || {};

  const {vendor_type} = data || {}; 
  
  
  const invoiceDate = invoice_created_date !== "" ? invoice_created_date : 0;

  const [isEdit, setIsEdit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [showCompModal, setShowCompModal] = useState(false);
  const [billingModal, setBillingModal] = useState<boolean>(false);
  const billingStatus = data?.billing?.status;

  const [error, setError] = useState({
    status: null,
    message: "",
  });

  const { setVendorDetailsEditing, vendorDetailsEditing } =
    useContext(AuthContext);
  //const {status, createdAt, signupFee, renewalFee, highjSchools} = {data};
  // const status = "unpaid onboarding invoice";
  // const createdAt = 10/31/23;
  // const signupFee = 250;
  // const renewalFee = 20;
  // const highSchools = 2

  const initialValues = {
    status: status || "",
    invoice_created_date: invoice_created_date || "",
    current_rate: current_rate || "",
    renewal_fee: renewal_fee || "",
    high_schools: high_schools || "",
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      status: Yup.string().required("Required"),
      current_rate: Yup.number().required("Required"),
      renewal_fee: Yup.number().required("Required"),
      high_schools: Yup.number().required("Required"),
    }),
    onSubmit: async (values: any) => {

        if(values.current_rate === '0' && current_rate !==0) {
            setShowCompModal(true);
        }else {
            handleComp(false)
        }
    },
  });

  const {
    values,
    errors,
    handleReset,
    setErrors,
    handleChange,
    handleSubmit,
    
  } = formik;

  const onEdit = () => {
    setIsEdit(true);
    setActiveEdit(VendorDetailSections.BILLING);
    setVendorDetailsEditing(true);
  };

  const onClickCross = () => {
    setIsEdit(false);
    handleReset(initialValues);
    setActiveEdit("");
    setVendorDetailsEditing(false);
  };

  const onClickHistory = () => {
    setBillingModal(true);
  };

  const viewInvoice = () => {
    if (invoice_url) window.open(invoice_url, "_blank");
  };

  const handleComp = async (isComp: boolean)=>{

    let queryObject = isComp ? {
        ...values,
        current_rate: parseFloat(values.current_rate),
        high_schools: parseFloat(values.high_schools),
        renewal_fee: parseFloat(values.renewal_fee),
        conform: true
      } : {
        ...values,
        current_rate: parseFloat(values.current_rate),
        high_schools: parseFloat(values.high_schools),
        renewal_fee: parseFloat(values.renewal_fee),
      }


    const res = await updateVendorDetails(queryObject);

      if (res?.response?.data?.message) {
        setError({
          message: res?.response?.data?.message,
          status: res?.response?.status,
        });
      } else if (res?.response?.status === 500) {
        setError({
          message: "",
          status: res?.response?.status,
        });
      }

      if (res?._status?.code === 200) {
        setIsEdit(false);
        setActiveEdit("");
        setSuccess(true);
        setShowCompModal(false);
        setVendorDetailsEditing(false);
      }
  }

  useEffect(() => {
    const sucessTimer = setTimeout(() => {
      if (success) setSuccess(false);
    }, 3000);

    return () => {
      clearTimeout(sucessTimer);
    };
  }, [success]);

  console.log(values, errors);
  const isDisabled = useIsDisabled(values, errors, "[invoice_created_date]");
  const isLoading = loading[VendorActions.UPDATE_VENDOR_DETAILS];
  const vendorLoading = loading[VendorActions.GET_VENDOR_DETAILS];

  return (
    <div>
      <>
        {
          <div
            className={`save-order ${success ? "save-order-animation" : ""}`}
          >
            <span>
              {" "}
              <SaveIcon /> Saved
            </span>
          </div>
        }
        <SectionHeader
          title="Billing"
          onClick={() => onEdit()}
          isEditMode={isEdit}
          onClickCross={onClickCross}
          onClickSubmit={() => handleSubmit()}
          editDisabled={
            activeEdit &&
            (activeEdit !== VendorDetailSections.CUSTOMER_INFO ||
              billingStatus !== VendorBillingStatuses.NEW)
          }
          submitDisabled={isDisabled}
          isLoading={isLoading || vendorLoading}
          isHistory={true}
          isHistoryDisabled={vendorDetailsEditing || history?.length < 1}
          onClickHistory={onClickHistory}
        />
        <p className="edit-details-desc">
          Signup fee is prorated based on remaining days until July 1 yearly
          billing date. Fees are per high school for district vendors.{" "}
        </p>

        <div className="admin-info">
          <div className="billing-status-sec">
            <div className="detail-group customer-facing-email">
              <div
                style={{ display: "flex", alignItems: "center", gap: "17px" }}
              >
                {vendorLoading ? (
                  <LabelSkeleton />
                ) : (
                  <Label text="Status" />
                )}
                {vendorLoading ? (
                  <LabelSkeleton />
                ) : (
                  <>
                    {" "}
                    {invoiceDate === 0 ? (
                      <></>
                    ) : (
                      <Label
                        text={`Created ${moment(invoice_created_date).format(
                          "M/D/YY"
                        )}`}
                        customStyles={{
                          color: "#222",
                          backgroundColor: "#FDC13D",
                          marginLeft: "0",
                        }}
                      />
                    )}
                  </>
                )}
              </div>
              {(
                <p className="field-text fieldCapitalized">
                  {values.status.toLowerCase().split("_").join(" ") ||
                    "Not Yet"}
                </p>
              )}
            </div>
            {invoice_url ? (
              <div>
                <Button onClick={viewInvoice} buttonName="View Invoice" />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        <div style={{ display: "flex", flexWrap: "wrap", gap: "12px" }}>
          <div className="detail-group signUp-free">
            <EditableInput
              label= {vendor_type === VendorTypes.DISTRICT ? "Current Rate" : "Signup Fee"}
              placeholder={
                (errors.current_rate as string) || "Enter Signup Fee"
              }
              name="current_rate"
              value={values.current_rate}
              onChange={handleChange}
              isError={!!errors.current_rate}
              errors={errors}
              setErrors={setErrors}
              isLoading={vendorLoading}
              isEdit={isEdit}
            />
          </div>

          <div className="detail-group renewal-fee">
            <RenewalInput
              label={`Renewal ${vendor_type === VendorTypes.DISTRICT ? "Rate" : "Fee"}`}
              placeholder={
                (errors.renewal_fee as string) || "Enter Renewal Fee"
              }
              name="renewal_fee"
              value={values.renewal_fee}
              onChange={handleChange}
              isError={!!errors.renewal_fee}
              errors={errors}
              setErrors={setErrors}
              isLoading={vendorLoading}
              isEdit={isEdit}
              isRenewal = {true}
 
            />
          </div>

          <div className="detail-group high-school">
            <EditableInput
              label="High Schools"
              placeholder={
                (errors.high_schools as string) || "Enter High Schools"
              }
              name="high_schools"
              value={values.high_schools}
              onChange={handleChange}
              isError={!!errors.high_schools}
              errors={errors}
              setErrors={setErrors}
              isLoading={vendorLoading}
              isEdit={isEdit}
            />
          </div>
        </div>
        {error.status && (
          <ApiModal
            title={"Update Billing Failed"}
            message={error.message}
            setIsShow={() => setError({ status: null, message: "" })}
          />
        )}

        {showCompModal ? 
        <InfoModal title="Comp?" 
        description="Are you sure you want to comp the current period? This will cancel any invoices that are due." 
        onSubmit={()=>handleComp(true)}
        cancelButtonName="No"
        submitButtonName="Yes"
        isCancel
        submitLoading = {isLoading}
        closeModal={()=>setShowCompModal(false)}
        /> : <></>}

        {billingModal ? (
          <VendorHistoryModal
            section={"billing"}
            history={data?.billing?.history}
            closeModal={() => setBillingModal(false)}
          />
        ) : (
          <></>
        )}
      </>
    </div>
  );
};

export default Billing;
