import axios from "axios";
import config from "config/config";
import { AddAdminRequest } from "interfaces/user";
import { getQueryString } from "util/getQueryString";

export const getSuperAdmins = async (superAdminsReq: any) => {
    const queryString = getQueryString(superAdminsReq);
    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/admins/current${queryString}`);
        return res?.data

    } catch (error) {
        return null
    }
}

export const postSuperAdmin = async (addAdminReq: AddAdminRequest) => {
    try {
        const res = await axios.post(`${config.defaults.api_url}/v1/admins/current`, addAdminReq);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const putSuperAdmin = async (editAdminReq: any) => {
    const {admin_id, ...body} = editAdminReq;
    try {
        const res = await axios.put(`${config.defaults.api_url}/v1/admins/current/${admin_id}`, body);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const removeSuperAdmin = async (admin_id: number) => {
    try {
        const res = await axios.delete(`${config.defaults.api_url}/v1/admins/${admin_id}`);
        return res

    } catch (error: any) {
        return error
    }
}

export const getStaff = async (staffReq: any) => {
    const {vendor_id, ...body} = staffReq;
    const queryString = getQueryString(body);

    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/vendor/${vendor_id}/staff${queryString}`);
        return res?.data

    } catch (error: any) {
        return error
    }
}


export const postStaff = async (addStaffReq: any) => {
    try {
        const res = await axios.post(`${config.defaults.api_url}/v1/vendor/staff`, addStaffReq);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const putStaff = async (editStaffReq: any) => {
    const {staff_id, ...body} = editStaffReq;
    try {
        const res = await axios.put(`${config.defaults.api_url}/v1/vendor/staff/${staff_id}`, body);
        return res?.data;

    } catch (error: any) {
        return error
    }
}

export const removeStaff = async (id: number) => {
    try {
        const res = await axios.delete(`${config.defaults.api_url}/v1/vendor/staff/${id}`);
        return res

    } catch (error: any) {
        return error
    }
}