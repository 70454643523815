import { createContext, useReducer, useState } from "react";
import { UserContextProvider } from "interfaces/user"
import { UserActions, UserReducer } from "./reducers/user"
import { getStaff, getSuperAdmins, postStaff, postSuperAdmin, putStaff, putSuperAdmin, removeStaff, removeSuperAdmin} from "services/user";
import { AddVendorRequest, VendorListRequest } from "interfaces/vendor";
import { getAdmins, getCrowdList, getOrderDetails, getOrderList, getVendorList, postAdmin, postVendor, putAdmin, putOrderStatus, removeAdmin, removeVendor } from "services/vendor";
import { getActiveTab } from "util/getActiveTab";
import { useLocation } from "react-router-dom";

const initialState: UserContextProvider = {
    superAdmins: {
        admins: [],
        _count: 0
    },
    adminList: {
        admins: [],
        _count: 0
    },
    staffList: {
        admins: [],
        _count: 0
    },
    crowdList: {
        customers: [],
        _count: 0
    },
    orderList: {
        orders: [],
        _count: 0
    },
    loading: {
        [UserActions.GET_ORDER_DATAILS]: true
    },
    resetLoading: () => {},
    setLoading: () => {},
    fetchSuperAdmins: () => Promise<any>,
    fetchVendors: () => Promise<any>,
    deleteVendor: () => Promise<any>,
    addVendor: () => Promise<any>,
    vendorList: {
        vendors: [],
        _count: 0
    },
    fetchCrowds: () => Promise<any>,
    fetchOrders: () => Promise<any>,
    fetchOrderDetails: () => Promise<any>,
    updateOrderStatus: () => Promise<any>,
    addAdmin: () => Promise<any>,
    updateAdmin: () => Promise<any>,
    deleteAdmin: () => Promise<any>,
    fetchAdmins: () => Promise<any>,
    updateSuperAdmin: () => Promise<any>,
    deleteSuperAdmin: () => Promise<any>,
    addSuperAdmin: () => Promise<any>,
    fetchStaff: () => Promise<any>,
    deleteStaff: () => Promise<any>,
    addStaff: () => Promise<any>,
    updateStaff: () => Promise<any>,
    setActiveTab: () => {},
    orderDetails: {
        order: null!
    },
    activeTab: 0,
    crowedPage: 0, 
    setCrowedPage: ()=>{},
    crowedQueryParams: { 
        limit: window.innerHeight >= 1220 ? 20 : 15,
        offset: 0,
        sort: '',
        search: ''}, 
    setCrowedQueryParams: ()=>{},
    adminsPage: 0, 
    setAdminsPage: ()=>{}, 
    adminQueryParam: {
        limit: window.innerHeight >= 1100 ? 20 : 15,
        offset: 0,
        search: '',
        sort: ''
    },
    
    setAdminQeuryParam: ()=>{}, 
    superAdminQueryParam: {
        limit: window.innerHeight >= 1100 ? 20 : 15,
        offset: 0,
        search: '',
        sort: ''
    },
    setSuperAdminQueryParam: () => {},
    staffPage: 0, 
    setStaffPage: ()=>{},
    staffQueryParam: {
        limit: window.innerHeight >= 1100 ? 20 : 15,
        offset: 0,
        search: '',
        sort: ''
    }, 
    setStaffQueryParam: ()=>{},
    userVendorPage: 0,
    setUserVendorPage: ()=>{},
    userVendorQueryParams: {
        limit: window.innerHeight >= 1100 ? 20 : 15,
        offset: 0,
        sort: 'name',
        search: ''
    }, 
    setuservendorQueryParams : ()=>{}

}

export const UserContext = createContext<UserContextProvider>(null!)

const UserProvider = ({ children }: any) => {
    const location = useLocation();
    const [{ staffList, adminList, crowdList, vendorList, superAdmins, orderList, loading, orderDetails }, dispatch] = useReducer(UserReducer, initialState);
    
    const isOrderPage = location.pathname.includes("orders");
    const act = isOrderPage ? 0 : getActiveTab()
    const [activeTab, setActiveTab] = useState(act);
   
    const [adminsPage, setAdminsPage] = useState(initialState.adminsPage)
    const [staffPage, setStaffPage] = useState(initialState.staffPage);
    const [crowedPage, setCrowedPage] = useState(initialState.crowedPage);
    const [userVendorPage, setUserVendorPage] = useState(initialState.userVendorPage)
    const [crowedQueryParams, setCrowedQueryParams] = useState(initialState.crowedQueryParams);
    const [adminQueryParam, setAdminQeuryParam] = useState(initialState.adminQueryParam);
    const [staffQueryParam, setStaffQueryParam] = useState(initialState.staffQueryParam);
    const [userVendorQueryParams, setuservendorQueryParams] = useState(initialState.userVendorQueryParams);
    const [superAdminQueryParam, setSuperAdminQueryParam] = useState(initialState.superAdminQueryParam);

    const setLoading = (loading: string) => {
        dispatch({
            type: UserActions.SET_USER_LOADING,
            payload: loading
        })
    }

    const resetLoading = (loading: string) => {
        dispatch({
            type: UserActions.RESET_USER_LOADING,
            payload: loading
        })
    }

    const fetchStaff = async (staffReq: any) => {
        staffReq.offset ? setLoading(UserActions.SET_MORE_STAFF) : setLoading(UserActions.SET_STAFF);
        const res = await getStaff(staffReq);
        staffReq.offset ? resetLoading(UserActions.SET_MORE_STAFF) : resetLoading(UserActions.SET_STAFF);

        if(res?._status?.code === 200){
            dispatch({
                type: UserActions.SET_STAFF,
                payload: res.data
            });
        }

        return res;
    }

    const addStaff = async (addStaffReq: any) => {
        setLoading(UserActions.ADD_STAFF);
        const res = await postStaff(addStaffReq);
        resetLoading(UserActions.ADD_STAFF);

        if(res?._status?.code === 201){
            dispatch({
                type: UserActions.ADD_STAFF,
                payload: res.data
            });
        }

        return res;
    }

    const updateStaff = async (editAdminReq: any) => {
        setLoading(UserActions.EDIT_STAFF);
        const res = await putStaff(editAdminReq);
        resetLoading(UserActions.EDIT_STAFF);

        if(res?._status?.code === 200){
            dispatch({
                type: UserActions.EDIT_STAFF,
                payload: editAdminReq
            });
        }

        return res;
    }

    const deleteStaff = async (id: number) => {
        setLoading(UserActions.DELETE_STAFF);
        const res = await removeStaff(id);
        resetLoading(UserActions.DELETE_STAFF);

        if(res?.status === 204){
            dispatch({
                type: UserActions.DELETE_STAFF,
                payload: id
            });
        }

        return res;
    }

    const fetchAdmins = async (adminReq: any) => {
        adminReq.offset ? setLoading(UserActions.SET_ADMINS_MORE) : setLoading(UserActions.SET_ADMINS);
        const res = await getAdmins(adminReq);
        adminReq.offset ? resetLoading(UserActions.SET_ADMINS_MORE) : resetLoading(UserActions.SET_ADMINS);

        if(res?._status?.code === 200){
            dispatch({
                type: UserActions.SET_ADMINS,
                payload: {...res.data, offset: adminReq.offset}
            });
        }

        return res;
    }

    const addAdmin = async (addAdminReq: any) => {
        setLoading(UserActions.ADD_ADMIN);
        const res = await postAdmin(addAdminReq);
        resetLoading(UserActions.ADD_ADMIN);

        if(res?._status?.code === 201){
            dispatch({
                type: UserActions.ADD_ADMIN,
                payload: res.data
            });
        }

        return res;
    }

    const updateAdmin = async (editAdminReq: any) => {
        setLoading(UserActions.EDIT_ADMIN);
        const res = await putAdmin(editAdminReq);
        resetLoading(UserActions.EDIT_ADMIN);

        if(res?._status?.code === 200){
            dispatch({
                type: UserActions.EDIT_ADMIN,
                payload: editAdminReq
            });
        }

        return res;
    }

    const deleteAdmin = async (id: number) => {
        setLoading(UserActions.DELETE_ADMIN);
        const res = await removeAdmin(id);
        resetLoading(UserActions.DELETE_ADMIN);

        if(res?.status === 204){
            dispatch({
                type: UserActions.DELETE_ADMIN,
                payload: id
            });
        }

        return res;
    }
    

    const updateSuperAdmin = async (editSuperAdminReq: any) => {
        setLoading(UserActions.EDIT_SUPER_ADMIN);
        const res = await putSuperAdmin(editSuperAdminReq);
        resetLoading(UserActions.EDIT_SUPER_ADMIN);

        if(res?._status?.code === 200){
            dispatch({
                type: UserActions.EDIT_SUPER_ADMIN,
                payload: editSuperAdminReq
            });
        }

        return res;
    }

    const addSuperAdmin = async (addAdminReq: any) => {
        setLoading(UserActions.ADD_SUPER_ADMIN);
        const res = await postSuperAdmin(addAdminReq);
        resetLoading(UserActions.ADD_SUPER_ADMIN);

        if(res?._status?.code === 201){
            dispatch({
                type: UserActions.ADD_SUPER_ADMIN,
                payload: res.data
            });
        }

        return res;
    }

    const deleteSuperAdmin = async (id: number) => {
        setLoading(UserActions.DELETE_SUPER_ADMIN);
        const res = await removeSuperAdmin(id);
        resetLoading(UserActions.DELETE_SUPER_ADMIN);

        if(res?.status === 204){
            dispatch({
                type: UserActions.DELETE_SUPER_ADMIN,
                payload: id
            });
            return true;
        }

        return false;
    }

    const fetchOrders = async (orderReq: any) => {
        orderReq.offset ? setLoading(UserActions.GET_MORE_ORDERS) : setLoading(UserActions.GET_ORDERS);
        const res:any = await getOrderList(orderReq);
        orderReq.offset ? resetLoading(UserActions.GET_MORE_ORDERS) : resetLoading(UserActions.GET_ORDERS);

        if(res?._status?.code === 200){
            dispatch({
                type: UserActions.GET_ORDERS,
                payload: {...res.data, offset: orderReq.offset}
            })
        }

        return res;
    }

    const fetchOrderDetails = async (order_id: number) => {
        
        setLoading(UserActions.GET_ORDER_DATAILS);
        const res:any = await getOrderDetails(order_id);
        resetLoading(UserActions.GET_ORDER_DATAILS);

        if(res?._status?.code === 200){
            dispatch({
                type: UserActions.GET_ORDER_DATAILS,
                payload: res.data
            })
        }

        return res;
    }

    const addVendor = async (addVendorReq: AddVendorRequest) => {
        setLoading(UserActions.ADD_VENDOR);
        const res = await postVendor(addVendorReq);
        resetLoading(UserActions.ADD_VENDOR);

        if(res?._status?.code === 201){
            dispatch({
                type: UserActions.ADD_VENDOR,
                payload: res.data?.vendor
            })
        }

        return res;
    }
    
    const fetchSuperAdmins = async (superAdminsReq: any) => {
        superAdminsReq.offset ? setLoading(UserActions.SET_MORE_SUPER_ADMINS) : setLoading(UserActions.SET_SUPER_ADMINS);
        const res = await getSuperAdmins(superAdminsReq);
        superAdminsReq.offset ? resetLoading(UserActions.SET_MORE_SUPER_ADMINS) : resetLoading(UserActions.SET_SUPER_ADMINS);

        if(res?._status?.code === 200){
            dispatch({
                type: UserActions.SET_SUPER_ADMINS,
                payload: res.data
            })
        }

        return res;
    }

    const fetchCrowds = async (crowdReq: any) => {
        crowdReq.offset ? setLoading(UserActions.GET_MORE_CROWDS) : setLoading(UserActions.GET_CROWDS);
        const res:any = await getCrowdList(crowdReq);
        crowdReq.offset ? resetLoading(UserActions.GET_MORE_CROWDS) : resetLoading(UserActions.GET_CROWDS);

        if(res?._status?.code === 200){
            
            dispatch({
                type: UserActions.GET_CROWDS,
                payload: {...res.data, offset: crowdReq.offset}
            });
        }

        return res;
    }

    const fetchVendors = async (vendorListreq: VendorListRequest) => {
        vendorListreq.offset ? setLoading(UserActions.GET_MORE_VENDORS) : setLoading(UserActions.GET_VENDORS);
        const res = await getVendorList(vendorListreq);
        vendorListreq.offset ? resetLoading(UserActions.GET_MORE_VENDORS) : resetLoading(UserActions.GET_VENDORS);

        if(res?._status?.code === 200){
            dispatch({
                type: UserActions.GET_VENDORS,
                payload: {...res.data, offset: vendorListreq.offset}
            });
        }

        return res;
    }

    const deleteVendor = async (vendor_id: number) => {
        setLoading(UserActions.DELETE_VENDOR);
        const res:any = await removeVendor(vendor_id);
        resetLoading(UserActions.DELETE_VENDOR);
        
        if(res?.status === 204){
            dispatch({
                type: UserActions.DELETE_VENDOR,
                payload: vendor_id
            });
        }

        return res;
    }

    const updateOrderStatus = async (updateReq: any) => {
        setLoading(UserActions.UPDATE_ORDER_STATUS);
        const res:any = await putOrderStatus(updateReq);
        resetLoading(UserActions.UPDATE_ORDER_STATUS);

        return res;
    }

    return <UserContext.Provider value={{
        updateAdmin,
        superAdmins,
        loading,
        setLoading,
        resetLoading,
        fetchSuperAdmins,
        fetchVendors,
        vendorList,
        deleteVendor,
        addVendor,
        crowdList,
        fetchCrowds,
        orderList,
        fetchOrders,
        fetchOrderDetails,
        orderDetails,
        updateOrderStatus,
        setActiveTab,
        activeTab,
        addAdmin,
        deleteAdmin,
        fetchAdmins,
        deleteSuperAdmin,
        addSuperAdmin,
        updateSuperAdmin,
        adminList,
        fetchStaff,
        staffList,
        deleteStaff,
        addStaff,
        updateStaff, 
        crowedPage, 
        setCrowedPage,
        crowedQueryParams, 
        setCrowedQueryParams,
        adminsPage, 
        setAdminsPage, 
        adminQueryParam, 
        setAdminQeuryParam, 
        staffPage, 
        setStaffPage, 
        staffQueryParam, 
        setStaffQueryParam,
        userVendorPage, 
        setUserVendorPage, 
        userVendorQueryParams, 
        setuservendorQueryParams,
        setSuperAdminQueryParam,
        superAdminQueryParam
    }}>
        {children}
    </UserContext.Provider>
}
export default UserProvider;