import { Skeleton } from "../skeleton";

const MoreDataSkeleton = ({isShow}:{isShow: boolean}) => {
    return (
        <>
            {!!isShow && (
                <>
                    <Skeleton customClass="table-skeleton" />
                    <Skeleton customClass="table-skeleton" />
                </>
            )}
        </>
    );
};

export default MoreDataSkeleton;
