interface Props {
    children: any;
    selected?: boolean;
}

const TabPanel = ({children, selected}:Props) => {
    return (
        <>
            {selected ? children : null}
        </>
    )
}

export default TabPanel;