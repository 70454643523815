import { useContext, useEffect, useRef, useState } from "react";
import SectionHeader from "./sectionHeader";
import Label from "components/common/label";
import { useFormik } from "formik";
import * as Yup from "yup";
import { VendorDetailSections } from ".";
import { useIsDisabled } from "hooks/useIsDisabled";
import { VendorContext } from "contexts/vendor";
import ApiModal from "components/common/apiErrorModal";
import { VendorActions } from "contexts/reducers/vendor";
import { LabeledInputSkeleton } from "components/common/skeleton";
import Select from "components/common/select";
import moment from "moment";
import HistoryModal from "./historyModal";
import * as _ from "lodash";
import { deleteObjectProp } from "util/deleteObjectProp";
//import { useBillingStatusFormattedValue } from "hooks/useBillingStatus";
import SaveIcon from "components/venue/SaveIcon";
import { AuthContext } from "contexts/auth";

const notIncludedFields = [
  "last_payment",
  "last_payment_date",
  "joining_date",
  "venues",
  "high_schools",
];

const Subscription = ({ notPaid, data, setActiveEdit }: any) => {
  const {
    updateVendorDetails,
    fetchVendorBillingHistory,
    updateSubscriptionStatus,
    loading,
  } = useContext(VendorContext);
  const { subscription, subscriptions, has_subscription_history } = data || {};
  const [success, setSuccess] = useState(false);
  // console.log('subscription', subscription);
  const { vendorDetailsEditing, setVendorDetailsEditing } =
    useContext(AuthContext);
  // const {vendor} = vendorDetails || {};
  // const {has_subscription_history} = vendor || {};

  const { high_schools } = data || {};

  const planRef = useRef<HTMLParagraphElement>(null);
  const renewalPlanRef = useRef<HTMLParagraphElement>(null);

  const [showHistory, setShowHistory] = useState(false);

  const [tooltip, setTooltip] = useState({
    plan: false,
    renewalPlan: false,
  });

  const [isEdit, setIsEdit] = useState(false);
  const [error, setError] = useState<any>({
    status: null,
    message: "",
  });

  const {
    billing_status,
    billing_statuses_formatted,
    venues,
    vendor_type,
    current_rate,
    joined_data,
    last_payment_date,
    last_payment,
    plan,
    renewal_fee,
    subscription_type,
  } = subscription || {};

  const bollingStatusKey = billing_statuses_formatted?.find(
    (item: any) => item.label?.toLowerCase() === billing_status?.toLowerCase()
  )?.value;

  const initialValues = {
    billing_status: bollingStatusKey || "",
    plan: plan || "",
    renewal_plan: subscription_type || "",
    joining_date: joined_data || "",
    current_rate: current_rate || "",
    renewal_fee: renewal_fee || "",
    last_payment: last_payment || "",
    last_payment_date: last_payment_date || "",
    vendor_type: vendor_type || "District",
    venues: venues || 0,
    high_schools: high_schools || "",
  };

  const formik: any = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      billing_status: Yup.string().required("Required"),
      plan: Yup.string().required("Required"),
      renewal_plan: Yup.string().required("Required"),
      current_rate: Yup.number()
        .positive("Enter positive value only")
        .required("Required"),
      renewal_fee: Yup.number()
        .positive("Enter positive value only")
        .required("Required"),
      high_schools: Yup.string().nullable(),
      venues: Yup.number()
        .integer("Enter integer value only")
        .test(
          "Positive value",
          "Enter positive value only",
          (value: any) => value >= 0
        ),
    }),
    onSubmit: async (values: any) => {
      updateVendorDetails({ ...values }).then((res: any) => {
        if (res?.response?.data?.message) {
          setError({
            message: res?.response?.data?.message,
            status: res?.response?.status,
          });
        } else if (res?.response?.status === 500) {
          setError({
            message: "",
            status: res?.response?.status,
          });
        }

        if (res?._status?.code === 200) {
          setSuccess(true);
          setIsEdit(false);
          setActiveEdit("");
        }
      });

      const isSubscriptionStatusEqual = _.isEqual(
        initialValues.billing_status,
        values.billing_status
      );
      if (!isSubscriptionStatusEqual) {
        updateSubscriptionStatus({
          ...values,
          status: values.billing_status,
        }).then((res: any) => {
          if (res?.response?.data?.message) {
            setFieldValue("billing_status", initialValues.billing_status);
            setError({
              message: res?.response?.data?.message,
              status: res?.response?.status,
              type: "billing_status",
            });
          } else if (res?.response?.status === 500) {
            setFieldValue("billing_status", initialValues.billing_status);
            setError({
              message: "",
              status: res?.response?.status,
              type: "billing_status",
            });
          }

          if (res?._status?.code === 200) {
            setIsEdit(false);
            setActiveEdit("");
          }
        });
      }
    },
  });

  const {
    values,
    errors,
    setFieldValue,
    handleBlur,
    handleReset,
    setErrors,
    handleChange,
    handleSubmit,
  } = formik;

  const onEdit = () => {
    setIsEdit(true);
    setActiveEdit(VendorDetailSections.SUBSCRIPTION);
    setVendorDetailsEditing(true);
  };

  const onClickCross = () => {
    setIsEdit(false);
    handleReset(initialValues);
    setActiveEdit("");
    setVendorDetailsEditing(false);
  };

  const onSelectSubscription = (e: any) => {
    handleChange(e);
    const selectedSubscription = subscriptions?.find(
      (item: any) =>
        item?.subscription_type?.toLowerCase() === e.target.value?.toLowerCase()
    );

    if (selectedSubscription) {
      setFieldValue("current_rate", selectedSubscription?.yearly_fee);
    }
  };

  const onSelectRenewal = (e: any) => {
    handleChange(e);
    const selectedSubscription = subscriptions?.find(
      (item: any) =>
        item?.subscription_type?.toLowerCase() === e.target.value?.toLowerCase()
    );

    if (selectedSubscription) {
      setFieldValue("venues", selectedSubscription?.venues_allowed);
      setFieldValue("renewal_plan", selectedSubscription?.subscription_type);
      setFieldValue("renewal_fee", selectedSubscription?.renewal_fee);
    }
  };

  const onClickHistory = () => {
    setShowHistory(true);
    fetchVendorBillingHistory();
  };

  const onClickCancel = (fieldName: string) => {
    const newErrors = deleteObjectProp(errors, fieldName);

    newErrors && setErrors(newErrors);
  };

  useEffect(() => {
    if (subscription?.plan && !isEdit && planRef.current) {
      setTooltip((prev) => ({
        ...prev,
        plan: planRef.current
          ? planRef.current.scrollWidth - planRef.current.clientWidth > 0
          : false,
      }));
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [data, isEdit, planRef.current]);

  useEffect(() => {
    if (data && !isEdit && renewalPlanRef.current) {
      setTooltip((prev) => ({
        ...prev,
        renewalPlan: renewalPlanRef.current
          ? renewalPlanRef.current.scrollWidth -
              renewalPlanRef.current.clientWidth >
            0
          : false,
      }));
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [data, isEdit, renewalPlanRef.current]);

  const isDisabled = useIsDisabled(values, errors, notIncludedFields);
  const isLoading = loading[VendorActions.UPDATE_VENDOR_DETAILS];
  const vendorLoading = loading[VendorActions.GET_VENDOR_DETAILS];

  useEffect(() => {
    const sucessTimer = setTimeout(() => {
      if (success) setSuccess(false);
    }, 3000);

    return () => {
      clearTimeout(sucessTimer);
    };
  }, [success]);

  return (
    <>
      {
        <div className={`save-order ${success ? "save-order-animation" : ""}`}>
          <span>
            {" "}
            <SaveIcon /> Saved
          </span>
        </div>
      }
      <SectionHeader
        title={VendorDetailSections.SUBSCRIPTION}
        onClickSubmit={() => {
          handleSubmit();
        }}
        onClick={onEdit}
        isEditMode={isEdit}
        onClickCross={onClickCross}
        //  editDisabled={activeEdit && activeEdit !== VendorDetailSections.SUBSCRIPTION}
        editDisabled={true}
        submitDisabled={isDisabled}
        isLoading={isLoading || vendorLoading}
        isHistory={true}
        isHistoryDisabled={!has_subscription_history || vendorDetailsEditing}
        onClickHistory={onClickHistory}
      />
      <div className="admin-info">
        <div className="detail-fields-group subscription-billing">
          <div className="detail-group joined-date">
            {vendorLoading ? (
              <LabeledInputSkeleton />
            ) : (
              <>
                <Label
                  text={"Joined Date"}
                />
                <p className="field-text">
                  {values.joining_date
                    ? moment(values.joining_date).format("M/D/YY")
                    : "Not Yet"}
                </p>
              </>
            )}
          </div>
        </div>
        <div className="detail-fields-group subscription-billing">
          <div className={`detail-group plan`}>
            {vendorLoading ? (
              <LabeledInputSkeleton />
            ) : (
              <>
                <Label inputLabel={isEdit} text="Signup Plan" />
                {isEdit ? (
                  <Select
                    disabled
                    name="plan"
                    options={[{ label: "Unlimited", value: "Unlimited" }]}
                    customStyles={{
                      color: "black",
                      opacity: 0.4,
                    }}
                    value={values.plan}
                    onChange={onSelectSubscription}
                    onBlur={handleBlur}
                    isError={!!errors.plan}
                    placeholder={
                      errors.plan ? (errors.plan as string) : "Select Plan"
                    }
                    onClickCross={() => onClickCancel("plan")}
                  />
                ) : (
                  <div
                    className={`${tooltip.plan ? "show-truncate-tooltip" : ""}`}
                  >
                    <p ref={planRef} className="field-text">
                      {values.plan || "Not Yet"}
                    </p>
                    <span className="truncate-tooltip">
                      {values.plan || ""}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>

          {/* {!values.renewal_plan?.toLowerCase()?.includes("unlimited") && (
                        <div className="detail-group venues">
                            {vendorLoading ? (
                                <LabeledInputSkeleton />
                            ):(
                                <>
                                    <Label inputLabel={isEdit} text="Venues" />
                                    {isEdit ? (
                                        <Input
                                            type="number"
                                            placeholder={errors.venues ? errors.venues as string : ''}
                                            name="venues"
                                            value={values.venues}
                                            onChange={(e: any) => setFieldValue('venues', e.target.value ? Number(e.target.value) : e.target.value)}
                                            onBlur={handleBlur}
                                            isError={!!(errors.venues)}
                                            errors={errors}
                                            setErrors={setErrors}
                                        />
                                    ):(
                                        <p className="field-text">{values.venues || 'Not Yet'}</p>
                                    )}
                                </>
                            )}
                        </div>
                    )} */}

          <div className="detail-group renewal-plan">
            {vendorLoading ? (
              <LabeledInputSkeleton />
            ) : (
              <>
                <Label inputLabel={isEdit} text="Renewal Plan" />
                {isEdit ? (
                  <Select
                    disabled
                    name="renewal_plan"
                    options={[{ label: "Unlimited", value: "Unlimited" }]}
                    customStyles={{
                      color: "black",
                      opacity: 0.4,
                    }}
                    value={values.renewal_plan}
                    onChange={onSelectRenewal}
                    onBlur={handleBlur}
                    isError={!!errors.renewal_plan}
                    placeholder={
                      errors.renewal_plan
                        ? (errors.renewal_plan as string)
                        : "Select Renewal Plan"
                    }
                    onClickCross={() => onClickCancel("renewal_plan")}
                  />
                ) : (
                  <div
                    className={`${
                      tooltip.renewalPlan ? "show-truncate-tooltip" : ""
                    }`}
                  >
                    <p ref={renewalPlanRef} className="field-text">
                      {values.renewal_plan || "Not Yet"}
                    </p>
                    <span className="truncate-tooltip">
                      {values.renewal_plan || ""}
                    </span>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {error.status && (
        <ApiModal
          title={
            error.type === "billing_status"
              ? "Update Billing Status Failed"
              : "Update Vendor Failed"
          }
          message={error.message}
          setIsShow={() => setError({ status: null, message: "" })}
        />
      )}

      {showHistory && <HistoryModal closeModal={() => setShowHistory(false)} />}
    </>
  );
};

export default Subscription;
