import { useEffect } from "react";
import { useState } from "react";
import Stripe from "services/Stripe";
import ApiModal from "components/common/apiErrorModal";
import InfoModal from "components/common/infoModal";
import { BillingStep } from "interfaces/billing";
import Button from "components/common/button";
import { getClientSecret } from "services/billing";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "components/stripe/CheckoutForm";

interface IProps {
  vendorId: number;
  fees: number;
  cardDetails: { name: string; expiry: string; number: string; code: string };
  setCardDetails: Function;
  daysRemaining: number;
  venueCount: number;
  feesPerVenue: number;
  disabled: boolean;
  changeQuantity: Function;
  isSubscription: boolean;
  goNextStep: Function;
}

const BillingInvoiceForm = ({
  vendorId,
  fees,
  daysRemaining,
  venueCount,
  feesPerVenue,
  disabled,
  changeQuantity,
  isSubscription,
  goNextStep,
}: IProps) => {
  // const [isLoading, setIsLoading] = useState<boolean>(false);
  // const [togglePaymentMethod , setTogglePaymentMethod ] = useState<number>(1);
  const [clientSecret, setClientSecret] = useState("");
  // const [paidClicked, setPaidClicked] = useState(0);
  const appearance = {
    theme: "stripe",
  };

  const options = {
    clientSecret,
    appearance,
  };

  let stripe = new Stripe();
  const [error, setError] = useState<any>({
    message: "",
    status: false,
  });
  const [message, setMessage] = useState<any>({
    text: "",
    show: false,
  });

  const getSecretStripe = async () => {
    const res = await getClientSecret(fees, vendorId);
    if (res?.data?._status?.code === 201) {
      setClientSecret(res?.data?.data?.client_secret);
    }
  };

  useEffect(() => {
    if (fees && vendorId) getSecretStripe();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [fees, vendorId]);

  return (
    <>
      {!disabled ? (
        <>
          <div className="billingInvoice">
            <div className="billingInvoice_header_inline">
              {!isSubscription ? (
                <div>
                  <p className="billingInvoice_header_title">
                    Crowd Chow Subscription for {venueCount} Venue
                  </p>
                  <p className="billingInvoice_header_subTitle">
                    1 Venue at ${feesPerVenue}/venue. Prorated to{" "}
                    {daysRemaining} days. Renews on July 1,{" "}
                    {new Date().getFullYear() + 1}.
                  </p>
                </div>
              ) : (
                <div>
                  <p className="billingInvoice_header_title">
                    Crowd Chow Unlimited Subscription
                  </p>
                  <p className="billingInvoice_header_subTitle">
                    {fees}/yr prorated to {daysRemaining} days. Renews on July
                    1, {new Date().getFullYear() + 1}.
                  </p>
                </div>
              )}
              {!isSubscription && (
                <Button
                  buttonName="Change"
                  customClass="billingInvoice_header_quantityBtn"
                  type="button"
                  onClick={() => changeQuantity(BillingStep.Invoicing)}
                />
              )}
            </div>
            <div className="billingInvoice_header_line">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="2"
                viewBox="0 0 640 2"
                fill="none"
              >
                <path d="M0 1H640" stroke="#BCBCBC" />
              </svg>
            </div>
            {clientSecret && (
              //@ts-ignore
              <Elements options={options} stripe={stripe.loadStripe()}>
                <CheckoutForm
                  fees={fees}
                  clientSecret={clientSecret}
                  numberOfVenues={venueCount}
                  vendorId={vendorId}
                  goNextStep={goNextStep}
                />
              </Elements>
            )}

            {error.status && (
              <ApiModal
                setIsShow={() => setError({ ...error, status: false })}
                title="Payment Processing Error"
                message={error.message}
              />
            )}
            {message.show && (
              <InfoModal
                title="Payment Processed!"
                description={message.text}
                onSubmit={() => {
                  setMessage({ show: false });
                  goNextStep();
                }}
              />
            )}
          </div>
          <div className="billingInvoice_bank_bottom"></div>
        </>
      ) : (
        <div className="billingInvoice_skeleton">
          <div className="billingInvoice_skeleton_overlay">
            <div className="billingInvoice_skeleton_overlay_text">
              {isSubscription
                ? "Loading your billing details..."
                : "Confirm Venue Quantity Above"}
            </div>
          </div>
          <div className="billingInvoice_skeleton_container">
            <div className="billingInvoice_skeleton_inline">
              <div className="billingInvoice_skeleton_header_title"></div>
              <div className="billingInvoice_skeleton_header_subTitle"></div>
            </div>
            <div className="billingInvoice_skeleton_header_line">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="100%"
                height="2"
                viewBox="0 0 640 2"
                fill="none"
              >
                <path d="M0 1H640" stroke="#BCBCBC" />
              </svg>
            </div>
            <div className="billingInvoice_skeleton_body">
              <div className="billingInvoice_skeleton_body_switch"></div>
              <div className="billingInvoice_skeleton_body_info">
                <div className="billingInvoice_skeleton_body_info_formGroupInline">
                  <div className="billingInvoice_skeleton_body_formGroup">
                    <label></label>
                    <input
                      disabled
                      className="billingInvoice_skeleton_body_formGroup_input_group1"
                    />
                  </div>
                  <div className="billingInvoice_skeleton_body_formGroup">
                    <label></label>
                    <input
                      disabled
                      className="billingInvoice_skeleton_body_formGroup_input_group2"
                    />
                  </div>
                </div>
                <div className="billingInvoice_skeleton_body_info_formGroupInline">
                  <div className="billingInvoice_skeleton_body_formGroup">
                    <label></label>
                    <input
                      disabled
                      className="billingInvoice_skeleton_body_formGroup_input_group1"
                    />
                  </div>
                  <div className="billingInvoice_skeleton_body_formGroup">
                    <label></label>
                    <input
                      disabled
                      className="billingInvoice_skeleton_body_formGroup_input_group2"
                    />
                  </div>
                </div>
              </div>
              <div className="billingInvoice_skeleton_body_payBtn">
                <div className="payBttn"></div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default BillingInvoiceForm;
