import { ChangeEvent, useEffect, useRef, useState } from "react"
import VenueOrderTypes from "./venueOrderType";
import Button from "components/common/button";
import { LabeledInputSkeleton } from "components/common/skeleton";
import PhoneField from "components/common/phoneField";
import Label from "components/common/label";
import ZipField from "components/common/zipField";
import Select from "components/common/select";
import EditableInput from "components/common/editableInput";
import EditableActions from "components/common/editableActions";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { useIsDisabled } from "hooks/useIsDisabled";
import * as _ from 'lodash';
import { phoneValidation, zipValidation } from "util/validationSchema/common";
import { CITY_CHAR_LIMIT, NAME_CHAR_LIMIT } from "appConstants";
import { uploadResource } from "services/common";
import { ImageFor } from "interfaces/vendor";
import ApiModal from "components/common/apiErrorModal";
import CropImageModal from "components/signup/venueSetup/cropImageModal";
import AddressField from "components/signup/venueSetup/addressField";
import { deleteObjectProp } from "util/deleteObjectProp";
import { GetLatlong } from "util/googleApiHelpers";
import SaveIcon from "../SaveIcon";

interface IProps {
    vendor: any;
    updateVenue: Function;
    updateLoading: boolean;
    venueDetailsLoading: boolean;
    setLoading: Function;
    setVenueDetails: Function;
}

const USA_STATES = ['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY']

const Details = ({vendor, updateVenue, updateLoading, venueDetailsLoading, setLoading, setVenueDetails}: IProps) => {
    const inputRef = useRef<any>();
    const textRef = useRef<any>(null);

    const [isEdit, setIsEdit] = useState(false);
    const [error, setError] = useState('');
    const [tooltip, setTooltip] = useState(false);
    const [imgUrl, setImgUrl] = useState<any>('');
    const [imageLoading, setImageLoading] = useState(false);
    const [fileType, setFileType] = useState<any>('');
    const [latLong, setLatLong] = useState({
        latitude: '',
        longitude: ''
    })

    const [success, setSuccess] = useState('');
    const [zipcodeDisabled, setZipcodeDisabled] = useState(false);

    const initialValues = {
        name: vendor?.name || '',
        address: vendor?.address || '',
        zipcode: vendor?.zipcode || '',
        city: vendor?.city || '',
        state: vendor?.state || '',
        phone: vendor?.phone || '',
    }

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            address: Yup.string().required('Required'),
            city: Yup.string().required('Required'),
            state: Yup.string().required('Req'),
            zipcode: zipValidation,
            phone: phoneValidation,
        }),
        onSubmit: async (values: any) => {
            if(!latLong.latitude && !latLong.longitude){
                const formatted_address = `${values.address || ''}.,${values.city || ''}, ${values.state || ''} ${values.zipcode || ''}, USA`;
                GetLatlong(formatted_address, (data: any) => {
                    _updateVenue({...values, ...data});
                })
            }
            
            _updateVenue({
                ...values,
                ...(latLong.latitude && {latitude: latLong.latitude}),
                ...(latLong.longitude && {longitude: latLong.longitude})
            })
        }
    });
    const {values, errors, handleChange, setErrors, setFieldValue, handleSubmit, setValues} = formik;

    const _updateVenue = async (values: any) => {
        const res = await updateVenue(values);

        if(res?._status?.code === 200){
            setIsEdit(false);
            setSuccess('updated');

        }
    }


    const onClickPlace = (addressData: any) => {
        setZipcodeDisabled(!!addressData.zipcode);

        const state = addressData?.state;
        const stateIndex = USA_STATES.findIndex((item: string) =>  item === state);
        if(stateIndex === -1){
            const option = document.createElement("option");
            option.text = addressData?.state;
            option.value = addressData?.state;
            const selectEl = document.getElementById('state-select');
            selectEl?.appendChild(option);
        }

        const {formatted_address, latitude, longitude, ...restAddressData} = addressData;
        setValues({
            ...values,
            ...restAddressData,
            ...(formatted_address && {address: formatted_address})
        });
        setLatLong({
            latitude,
            longitude
        })

        let errorFields: string[] = [];
        if(errors.city && restAddressData?.city){
            errorFields.push('city')
        }

        if(errors.state && restAddressData?.state){
            errorFields.push('state');
        }

        if(errors.zipcode && restAddressData.zipcode){
            errorFields.push('zipcode');
        }

        // const updatedErrors = deleteAllProps(errors, errorFields);
        // setErrors(updatedErrors);
    }

    const onImageUpload = async (image: any) => {
        setImageLoading(true);
        const res = await uploadResource({file: image, image_for: ImageFor.Venue});

        if(res?.url){
            await updateVenue({
                logo_filename: res.url
            });
            setImgUrl(null!);
            setFileType('');
        }

        setImageLoading(false);

        const errorMessage = res?.response?.data?.message || res?.response?.data?.error;
        errorMessage && setError(errorMessage);
    }

    const onUploadVenueLogo = async (e: ChangeEvent<HTMLInputElement>) => {
        if(e.target.files && e.target.files.length > 0){
            const file = e.target.files[0];


             

          
                if(!(['image/png', 'image/jpeg', 'image/jpg', 'image/gif'].includes(file.type))){
                    setError('Wrong format! Only png, jpg, jpeg, or gif image is allowed.');
                    return;
                }

            const objectURL = URL.createObjectURL(file);
            const img = document.createElement('img');

            img.onload = () => {
                if(img.width > 600 || img.height > 600){
                    const url = URL.createObjectURL(file);
                    setImgUrl(url);
                    setFileType(file.type);
                }else{
                    onImageUpload(file);
                }

                URL.revokeObjectURL(objectURL);
            }

            img.src = objectURL;
        }
    }

    const onClickCancel = (fieldName: string) => {
        const newErrors = deleteObjectProp(errors, fieldName)
        
        newErrors && setErrors && setErrors(newErrors);
    }

    const onCross = () => {
        setIsEdit(false);
        setValues({...initialValues});
    }

    useEffect(() => {
        if(vendor?.zipcode){
            setZipcodeDisabled(true);
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[vendor])

    useEffect(() => {
        if (values.address && textRef.current) {
            setTooltip(textRef.current ? (textRef.current.scrollWidth - textRef.current.clientWidth > 0) : false)
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    }, [values, textRef.current]);

    useEffect(()=>{
        const sucessTimer = setTimeout(()=>{
             if(success)
             setSuccess('');
         },3000);
 
 
         return ()=>{
             clearTimeout(sucessTimer)
         }
         
     }, [success])

    const isEqual = _.isEqual(initialValues, values);
    const isDisabled = useIsDisabled(values, errors);

    return (
        <>
            <div className="super-admin_admin-detail-container_tab-content-container row">
                <div className="edit-details six-columns-lg columns">
                    <div className="edit-header">
                        <p className="edit-title">Venue Name and Location</p>
                        <div className="edit-icon">
                            <EditableActions
                                isEditMode={isEdit}
                                onClickCross={onCross}
                                onClickEdit={() => setIsEdit(true)}
                                activeDisabled={isDisabled}
                                onClickSubmit={handleSubmit}
                                disabled={updateLoading}
                                disableSubmit={isEqual}
                            />
                        </div>
                    </div>
                    <div className="admin-info">
                        <div className="detail-group vendor-name">
                            <EditableInput
                                name="name"
                                placeholder={errors.name as string || 'Enter name'}
                                value={values.name}
                                onChange={handleChange}
                                isError={!!(errors.name)}
                                errors={errors}
                                setErrors={setErrors}
                                isEdit={isEdit}
                                label="Name"
                                isLoading={venueDetailsLoading}
                                maxLength={NAME_CHAR_LIMIT}
                            />
                        </div>
                        <div className="detail-group vendor-email">
                            {venueDetailsLoading ? (
                                <LabeledInputSkeleton />
                            ) : (
                                <>
                                    {!isEdit && <Label inputLabel={isEdit} text="Address" />}
                                    {isEdit ? (
                                        <AddressField
                                            setFormValue={(field: string, val: any) => {
                                                setFieldValue(field, val);
                                                setLatLong({
                                                    latitude: '',
                                                    longitude: ''
                                                })
                                            }}
                                            onClickPlace={onClickPlace}
                                            address={values.address || ''}
                                            error={errors.address}
                                            onClickCross={(fieldName: string) => onClickCancel(fieldName)}
                                        />
                                    ) : (
                                        <div className="show-truncate-tooltip">
                                            <p ref={textRef} className="field-text">{values.address || 'Not Yet'}</p>
                                            {tooltip && (
                                                <span className="truncate-tooltip">{values.address}</span>
                                            )}
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        <div className="detail-fields-group address-fields">
                            <div className="detail-group vendor-city">
                                <EditableInput
                                    name="city"
                                    placeholder={errors.city as string || 'Enter city'}
                                    value={values.city}
                                    onChange={handleChange}
                                    isError={!!(errors.city)}
                                    errors={errors}
                                    setErrors={setErrors}
                                    label="City"
                                    isEdit={isEdit}
                                    isLoading={venueDetailsLoading}
                                    maxLength={CITY_CHAR_LIMIT}
                                />
                            </div>
                            <div className="detail-group vendor-state">
                                {venueDetailsLoading ? (
                                    <LabeledInputSkeleton />
                                ):(
                                    <>
                                        <Label inputLabel={isEdit} text="State" />
                                        {isEdit ? (
                                            <Select
                                                name="state"
                                                placeholder={errors.state as string || 'Enter state'}
                                                value={values.state}
                                                onChange={handleChange}
                                                isError={!!(errors.state)}
                                                options={USA_STATES.map((item: any) => ({label: item, value: item}))}
                                            />
                                        ):(
                                            <p className="field-text">{values.state || 'Not Yet'}</p>
                                        )}
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="detail-fields-group contact-fields">
                            <div className="detail-group vendor-zip">
                                {venueDetailsLoading ? (
                                    <LabeledInputSkeleton />
                                ):(
                                    <>
                                        <Label inputLabel={isEdit} text="Zip" />
                                        {isEdit ? (
                                            <ZipField
                                                name="zipcode"
                                                placeholder={errors.zipcode as string || 'Enter Zip'}
                                                value={values.zipcode}
                                                onChange={handleChange}
                                                isError={!!(errors.zipcode)}
                                                errors={errors}
                                                setErrors={setErrors}
                                                disbaled={zipcodeDisabled}
                                                
                                            />
                                        ):(
                                            <p className="field-text">{values.zipcode || 'Not Yet'}</p>
                                        )}
                                    </>
                                )}
                            </div>
                            <div className="detail-group vendor-phone-number">
                                {venueDetailsLoading ? (
                                    <LabeledInputSkeleton />
                                ):(
                                    <>
                                        <Label inputLabel={isEdit} text="Phone" />
                                        {isEdit ? (
                                            <PhoneField
                                                placeholder={errors?.phone as string || '123-456-7890'}
                                                name="phone"
                                                value={values?.phone}
                                                isError={!!(errors?.phone)}
                                                errors={errors}
                                                setErrors={setErrors}
                                                setValue={setFieldValue}
                                                errorMessage={errors.phone}
                                            />
                                        ):(
                                            <p className="field-text">{values.phone || 'Not Yet'}</p>
                                        )}
                                    </>
                                )}
                                
                            </div>
                        </div>
                    </div>
                    <div className="edit-header">
                        <p className="edit-title">Latitude and Longitude</p>
                    </div>
                    <p className="edit-details-desc">Latitude and Longitude is populated automatically using<br /> the address above.</p>
                    <div className="admin-info">
                        <div className="detail-group">
                            {venueDetailsLoading ? (
                                <LabeledInputSkeleton />
                            ):(
                                <>
                                    <Label text="Latitude" />
                                    <p className="field-text">{vendor?.latitude || 'Not Yet'}</p>
                                </>
                            )}
                        </div>
                        <div className="detail-group">
                            {venueDetailsLoading ? (
                                <LabeledInputSkeleton />
                            ):(
                                <>
                                    <Label text="Longitude" />
                                    <p className="field-text">{vendor?.longitude || 'Not Yet'}</p>
                                </>
                            )}
                        </div>
                    </div>
                </div>
                <div className="edit-details six-columns-lg columns right-VenueLogo">
                    <div className="edit-header">
                        <p className="edit-title">Venue Logo</p>
                    </div>
                    <p className="edit-details-desc">
                        The Venue logo is used across the system, most prominently on the Venue Select screen. Logo should be a 600 x 600 transparent PNG.
                    </p>
                    <div className="event">
                        <div className={`event-img ${!vendor?.logo_filename ? 'event-img-background' : ''}`}>
                            {vendor?.logo_filename && (
                                <img src={vendor?.logo_filename} alt="Logo Filename" />
                            )}
                            <p>Logo</p>
                        </div>
                        <div className="event-details">
                            <p className="school-event">Example High vs </p>
                            <p className="school-event">Example North High </p>
                            <p className="event-timing">Today, 6:00 PM</p>
                        </div>
                    </div>
                    <div className="change-venue-logo-btn">
                        <input 
                            style={{display: 'none'}} 
                            ref={inputRef} 
                            type="file" 
                            onChange={onUploadVenueLogo}
                            accept="image/png, image/jpeg, image/jpg"
                        />
                        <Button
                            buttonName="Change Venue Logo"
                            onClick={() => {
                                inputRef.current?.click();
                            }}
                            loading={imageLoading}
                        />
                    </div>
                    <VenueOrderTypes
                        setVenueDetails={setVenueDetails} 
                        updateVenue={updateVenue} 
                        vendor={vendor}
                        updateLoading={updateLoading}
                    />
                </div>
            </div>
            {error && (
                <ApiModal
                    title={error}
                    message={error}
                    setIsShow={() => setError('')}
                />
            )}

            {imgUrl && (
                <CropImageModal
                    closeModal={() => setImgUrl(null!)}
                    image={imgUrl}
                    onCrop={(croppedImg: any) => onImageUpload(croppedImg)}
                    loading={imageLoading}
                    fileType={fileType}
                />
            )}

            {
            <div className={`save-order ${success ? "save-order-animation" : ""}`}>
                 <span>   <SaveIcon /> Saved</span>
                </div>
            }
        </>
    )
};

export default Details;
