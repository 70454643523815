const config = {
    defaults: {
        namespace: 'Application',
        api_url: process.env.REACT_APP_API_URL || "",
        api_key: process.env.REACT_APP_API_KEY || "",
        stripe_key: process.env.REACT_APP_STRIPE_KEY || "",
        fe_url: process.env.REACT_APP_FE_URL || "",
    }
}

export default config;
