const LabelSkeleton = ({customStyle, width, animation = true}: any) => {
    return (
        <div 
            className={`label-skeleton ${animation ? 'skeleton-animation' : ''}`}
            style={{
                ...(customStyle && customStyle),
                ...(width && {width})
            }}
        ></div>
    )
};

export default LabelSkeleton;
