import axios from "axios";
import appConfig from "config/config"
import Cookies from "js-cookie";
import { refreshToken } from "services/auth";
import { clearAllCookies } from "util/clearAllCookies";
import { setAuthCookies } from "util/setAuthCookies";

axios.interceptors.request.use(async (config:any) => {
  const token = Cookies.get("crowd-chow-token");
  
  config.headers.apiKey = appConfig.defaults.api_key;

  const isLogin = config.url.includes("auth/login");

  if(isLogin){
    return config;
  }

  const isRefreshTokenReq = config.url.includes("auth/refresh");
  const isRefreshRefreshTokenReq = config.url.includes("auth/refresh/refresh-token");
  
  if(isRefreshTokenReq && !isRefreshRefreshTokenReq){
    config.headers.Authorization = `Bearer ${Cookies.get("refresh-token")}`;
  }else if(token){
    const impersonation_token = Cookies.get("impersonation_token");
    if(impersonation_token){
      config.headers['impersonation-token'] = impersonation_token;
    }
    config.headers.Authorization = `Bearer ${token}`;
  }
  
  return config;
});

const UNAUTHORIZED = 401;
const ACCESS_TOKEN_EXPIRATION_CODE = 112;
const REFRESH_TOKEN_EXPIRATION_CODE = 113;
const IMPERSONATION_TOKEN_EXPIRATION_CODE = 114;

axios.interceptors.request.use(
  request =>{
    return request
  },
  error=>error
  
)

axios.interceptors.response.use(
  response => response,
  async error => {
    const {status} = error?.response || {};
    try{
    if (status === UNAUTHORIZED) {
        clearAllCookies();
        window.location.href = '/login';
      
    } else if(status === 403){
      // console.log('test', error?.response?.data?.message, REFRESH_TOKEN_EXPIRATION_MESSAGE);
      if(error?.response?.data?.code === ACCESS_TOKEN_EXPIRATION_CODE){
        const token = Cookies.get("crowd-chow-token");
        if(token){
          const data = await refreshToken({access_token: token});
          if(data){
            setAuthCookies(data);
            const originalRequest = error.config;
            originalRequest.headers.Authorization = `Bearer ${data?.access_token}`;
            return axios(originalRequest);
          }  
        }
      }

      if(
        error?.response?.data?.code === REFRESH_TOKEN_EXPIRATION_CODE ||
        error?.response?.data?.code === IMPERSONATION_TOKEN_EXPIRATION_CODE
      ){
        clearAllCookies();
        window.location.href = '/login';
      }
      throw new Error(error);
      
    } else {    
      throw new Error(error);
    }
  }catch(err){
    return Promise.reject(error);
  }
 }
)

export default axios;