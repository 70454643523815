import { LeadsStatus } from "interfaces/vendor";
import Tick from 'assets/images/tick-white.png';
import {LeadsStatusColors} from "util/enums/Vendors"

interface IProps {
    status: string;
    onClickStatus: Function;
    disableAll: boolean;
    fromLocation?: any
    availableStatuses?: any
}

    const LeadStatuses = ({disableAll, status, onClickStatus, fromLocation,availableStatuses}: IProps) => {
        
        const singleStatusArray = availableStatuses ? availableStatuses.map((s: any)=>s.status ) : [];
        const checkStatusAvailable = (status: string) => singleStatusArray?.includes(status);
    





    return (
        <div className="status-bar">
            <div 
                onClick={() => !disableAll && checkStatusAvailable(LeadsStatus.NEW)  && onClickStatus(LeadsStatus.NEW)} 
                className={`cursor-pointer vendor-status ${disableAll || !checkStatusAvailable(LeadsStatus.NEW) ? 'disabled' : '' }`}
            >
                <div className="circle" style={{backgroundColor: LeadsStatusColors.NEW}}>
                    {status.includes(LeadsStatus.NEW) ? (
                        <img src={Tick} alt="Tick" />
                    ):(
                        null
                    )}
                </div>
                <p className="status-text">New</p>
            </div>
            <div 
                onClick={() => !disableAll && checkStatusAvailable(LeadsStatus.SALES_KIT_SENT) && onClickStatus(LeadsStatus.SALES_KIT_SENT)} 
                className={`cursor-pointer vendor-status ${disableAll || !checkStatusAvailable(LeadsStatus.SALES_KIT_SENT) ? 'disabled' : '' }`}
            >
                <div className="circle" style={{backgroundColor: LeadsStatusColors.SALES_KIT_SENT}}>
                    {status.includes(LeadsStatus.SALES_KIT_SENT) ? (
                        <img src={Tick} alt="Tick" />
                    ):(
                        null
                    )}
                </div>
                <p className="status-text">Sales Kit Sent</p>
            </div>
            <div 
                onClick={() => !disableAll && checkStatusAvailable(LeadsStatus.VENDOR_CREATED) && onClickStatus(LeadsStatus.VENDOR_CREATED)} 
                className={`cursor-pointer vendor-status ${disableAll || !checkStatusAvailable(LeadsStatus.VENDOR_CREATED) ? 'disabled' : '' }`}
            >
                <div className="circle" style={{backgroundColor: LeadsStatusColors.VENDOR_CREATED}}>
                    {status.includes(LeadsStatus.VENDOR_CREATED) ? (
                        <img src={Tick} alt="Tick" />
                    ):(
                        null
                    )}
                </div>
                <p className="status-text">Vendor Created</p>
            </div>
            <div 
                onClick={() => !disableAll && checkStatusAvailable(LeadsStatus.ONBOARDING) && onClickStatus(LeadsStatus.ONBOARDING)} 
                className={`cursor-pointer vendor-status ${disableAll || !checkStatusAvailable(LeadsStatus.ONBOARDING) ? 'disabled' : '' }`}
            >
                <div className="circle" style={{backgroundColor: LeadsStatusColors.ONBOARDING}}>
                    {status.includes(LeadsStatus.ONBOARDING) ? (
                        <img src={Tick} alt="Tick" />
                    ):(
                        null
                    )}
                </div>
                <p className="status-text">Onboarding</p>
            </div>
            <div 
                onClick={() => !disableAll && checkStatusAvailable(LeadsStatus.CONVERTED) && onClickStatus(LeadsStatus.CONVERTED)} 
                className={`cursor-pointer vendor-status ${disableAll || !checkStatusAvailable(LeadsStatus.CONVERTED) ? 'disabled' : '' }`}
            >
                <div className="circle" style={{backgroundColor: LeadsStatusColors.CONVERTED}}>
                    {status.includes(LeadsStatus.CONVERTED) ? (
                        <img src={Tick} alt="Tick" />
                    ):(
                        null
                    )}
                </div>
                <p className="status-text">Converted</p>
            </div>
        </div>
    )
};

export default LeadStatuses;
