import React, { useContext, useRef, useState } from "react";
import ActiveVendorsListItem from "./ActiveVendorsListItem";
import SuperAdminReportsDropDown from "components/reports/superAdmin/DropDowns/SuperAdminReportsDropDown";
import { SuperAdminDashboardContex } from "contexts/superAdminDashboard";
import { superAdminDashboardActions } from "contexts/reducers/superAdminDashboard";
import MostActivevendorSkeleton from "../Skeleton/MostActivevendorSkeleton";
const ActiveVendorDropDownOptions = [
  {
    label: "Most Active Vendor Last 30 Days",
    value: "MOST_ACTIVE_VENDORS_LAST_30_DAYS",
  },
  { label: "Most Sales Last 30 Days", value: "MOST_SALES_LAST_30_DAYS" },
  { label: "Most Customers", value: "MOST_CUSTOMERS" },
  { label: "Most Inactive Vendors", value: "MOST_INACTIVE_VENDORS" },
];
const MostActiveVendors = ({fetchData}:{fetchData: () => Promise<any>}) => {
    const fetchingEventsRef = useRef<boolean>(false);
    const [scrollTopVal, setScrollTopVal] = useState(0);
  const [activeVendorDropDownOption, setActiveVendorDropDownOption] =
    useState<string>(ActiveVendorDropDownOptions[0].value);
  const {
    fetchDashboardData,
    setDashboardReducer,
    dashboardQueryParams,
    dashboardData,
    loading,
    
  } = useContext(SuperAdminDashboardContex);
  const { data, data_count } = dashboardData;
  const { filter } = dashboardQueryParams;

    const activeVendorOptionsChangeHandler = async (
        e: React.ChangeEvent<HTMLSelectElement>
    ) => {
        setActiveVendorDropDownOption(e.target.value);
        const newQueryParams = { ...dashboardQueryParams, filter: e.target.value };
        setDashboardReducer(
        superAdminDashboardActions.SET_DASHBOARD_QUERY_PARAMS,
        newQueryParams
        );
        
        await fetchDashboardData({ ...dashboardQueryParams, filter: e.target.value }, superAdminDashboardActions.GET_ACTIVE_VENDORS);
    };

    const isRecordsRemaining = data.length < data_count;

    const handleScroll = async (e: any) => {
        const { scrollTop, scrollHeight } = e.target;

        setScrollTopVal(scrollTop);

        if (
            scrollTop > 0 &&
            isRecordsRemaining &&
            !fetchingEventsRef.current &&
            scrollTopVal < scrollTop
        ) {
            const scrollValue = scrollTop + 448;
            const threshold = scrollHeight - scrollHeight * 0.15;

            if (scrollValue >= threshold) {
                fetchingEventsRef.current = true;
                await fetchData();
                fetchingEventsRef.current = false;
            }
        }
    };

  const isLoading = loading[superAdminDashboardActions.GET_ACTIVE_VENDORS];
  const isDshboardLoading = loading[superAdminDashboardActions.GET_DASHBOARD_DATA]

  return (
    <div className="mostActiveVendors">
      <div className="select-container">
        <SuperAdminReportsDropDown
          value={activeVendorDropDownOption}
          options={ActiveVendorDropDownOptions}
          onChange={activeVendorOptionsChangeHandler}
        />
      </div>
      <div onScroll={handleScroll} className="activeEventsItems scroll">
        <div className="superactiveEventsorders">
          <div className="superEventOrder">
            {isLoading || isDshboardLoading ? (
              <MostActivevendorSkeleton isStatic = {false} />
            ) : data.length <1 ? <MostActivevendorSkeleton isStatic = {true} /> : (
              data &&
              data.map((data_item: any, index: number) => (
                <ActiveVendorsListItem
                  index={index}
                  name={data_item.name}
                  attribute={
                    filter === "MOST_ACTIVE_VENDORS_LAST_30_DAYS" ||
                    filter === "MOST_INACTIVE_VENDORS"
                      ? data_item.events
                      : filter === "MOST_SALES_LAST_30_DAYS"
                      ? data_item.sales
                      : filter === "MOST_CUSTOMERS"
                      ? data_item.customers
                      : ""
                  }
                  tag={
                    filter === "MOST_ACTIVE_VENDORS_LAST_30_DAYS" ||
                    filter === "MOST_INACTIVE_VENDORS"
                      ? "Events"
                      : filter === "MOST_SALES_LAST_30_DAYS"
                      ? "$"
                      : filter === "MOST_CUSTOMERS"
                      ? "Customers"
                      : ""
                  }
                />
              ))
            )}
          </div>
          {data_count > 10 && scrollTopVal < 10 && (
            <div className="scroll-data"></div>
          )}
        </div>
      </div>
    </div>
  );
};
export default MostActiveVendors;
