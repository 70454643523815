import { Table, TableBody, TableHead, TableHeading } from "components/common/table";
import TemplateRow from "./templateRow";

const EmailBlast = ({announcementText}:{announcementText: string}) => {
    
    return (
        <div className="table-container">
            <Table customClass="email-blast-table">
                <TableHead>
                    <TableHeading customClass="purpose">
                    Purpose
                    </TableHeading>
                    <TableHeading customClass="vendor-venue">
                        Vendor/Venue
                    </TableHeading>
                    <TableHeading customClass="options">
                        Options
                    </TableHeading>
                    <TableHeading customClass="arrow">
                    
                    </TableHeading>
                </TableHead>

                <TableBody>
                    <TemplateRow
                        text={announcementText}
                        isCopy
                    />
                </TableBody>
            </Table>
        </div>
    )
};

export default EmailBlast;
