import OnBoarding from 'components/signup/onboarding';
import OnboardingContainer from 'components/signup/onbardingContainer';
import OnboardingProvider from 'contexts/onboarding';

const OnBoardingPage = () => {

    return (
        <OnboardingProvider>
            <OnboardingContainer>
                <OnBoarding />
            </OnboardingContainer>
        </OnboardingProvider>
    )
}

export default OnBoardingPage;