import React from "react";

interface IProps {
  options: { label: string; value: any }[];
  placeholder?: string;
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  value: any;
  disabled?: boolean;
}

const Dropdown = ({
  options,
  placeholder,
  value,
  disabled,
  onChange,
}: IProps) => {
  return (
    <div className="selectBox">
      <select
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        style={{ opacity: disabled ? 0.4 : 1 }}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {placeholder && (
          <option value="" disabled selected>
            {placeholder}
          </option>
        )}
        {options.map((option: { label: string; value: any }) => {
          const { label, value } = option;
          return <option value={value}>{label}</option>;
        })}
      </select>
      <span className={`downArrow ${disabled ? "disabled" : ""}`}>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="15"
          height="9"
          viewBox="0 0 15 9"
          fill="none"
        >
          <path
            d="M1 1L7.45166 7.45166L13.9033 1"
            stroke="white"
            strokeWidth="1.5"
            strokeLinecap="round"
          />
        </svg>
      </span>
    </div>
  );
};

export default Dropdown;
