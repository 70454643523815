const RefundIcon = ({disabled, onClick}: any) => {
    return (
        <svg className={`${disabled ? 'disabled' : 'cursor-pointer'}`} onClick={onClick} width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="_&#195;&#142;&#195;&#147;&#195;&#136;_1" clipPath="url(#clip0_1021_125)">
                <path id="Vector" d="M18.2049 8.70485C17.7658 8.70485 17.4098 9.06083 17.4098 9.49995V17.4097H1.5902V1.5902H9.49995C9.93906 1.5902 10.295 1.23422 10.295 0.7951C10.295 0.355984 9.93906 0 9.49995 0H0.7951C0.355984 0 0 0.355984 0 0.7951V18.2048C0 18.6439 0.355984 18.9999 0.7951 18.9999H18.2048C18.6439 18.9999 18.9999 18.6439 18.9999 18.2048V9.49995C18.9999 9.06083 18.6439 8.70485 18.2048 8.70485H18.2049Z" fill="black"/>
                <path id="Vector_2" d="M18.2049 0H13.1452C12.7061 0 12.3501 0.355984 12.3501 0.7951C12.3501 1.23422 12.7061 1.5902 13.1452 1.5902H16.2854L9.50006 8.37551C9.18955 8.68602 9.18955 9.18944 9.50006 9.49995C9.81057 9.81046 10.314 9.81046 10.6245 9.49995L17.4098 2.71464V5.85481C17.4098 6.29393 17.7658 6.64991 18.2049 6.64991C18.644 6.64991 19 6.29393 19 5.85481V0.7951C19 0.355984 18.644 0 18.2049 0Z" fill="black"/>
            </g>
            <defs>
                <clipPath id="clip0_1021_125">
                    <rect width="19" height="19" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
};

export default RefundIcon;
