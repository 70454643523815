import { useRef, useState } from "react";

export const useInfiniteScroll = (fetchData: () => Promise<any>, diff?: number) => {
    const fetchRef = useRef<boolean>(false);
    const [scrollTopVal, setScrollTopVal] = useState(0);

    const handleScroll = async (e: any, isRecordsRemaining: boolean) => {
        const { scrollTop, clientHeight, scrollHeight } = e.target;
        setScrollTopVal(scrollTop);
    
        if (scrollTop > 0 && isRecordsRemaining && !fetchRef.current && scrollTopVal < scrollTop){
          const scrollValue = scrollTop+clientHeight
          const threshold = scrollHeight - (scrollHeight * 0.15);
    
          if (scrollValue >= threshold) {
            fetchRef.current = true;
            await fetchData();
            fetchRef.current = false;
            
          }
        }
    };

    return {
        scrollTop: scrollTopVal,
        handleScroll
    }
}