/* eslint-disable no-useless-escape */
import * as Yup from 'yup';
export const zipValidation = Yup.string().min(5, 'Zip is invalid').required('Zip is required');
export const phoneValidation = Yup
.string()
.test('phone_validation', 'Phone is invalid', val => {
    let pureValue = val;
    pureValue = pureValue?.replace('(', '');
    pureValue = pureValue?.replace(')', '');
    pureValue = pureValue?.replace(' ', '')
    pureValue = pureValue?.replace('-', '');
    return pureValue?.length === 10;
})
.required('Phone is required')
.matches(/^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}/, 'Must be US phone number')

export const emailValidation = Yup
.string()
.trim()
.matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Enter valid email')