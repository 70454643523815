/* eslint-disable no-useless-escape */

import { phoneValidation, zipValidation } from 'util/validationSchema/common';
import * as Yup from 'yup';

export const useValidationSchema = (initValues: any) => {
    const {contactForm, ...restValues} = initValues;

    let yupSchemas:any = {
        contactForm: Yup.object().shape({
            address: Yup.string().required('Address is required'),
            name: Yup.string().required('Name is required'),
            customer_facing_email: Yup
            .string()
            .matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Enter valid email')
            .when('customer_facing_phone', (_:any, schema:any, yup: any) => {
                if(!yup.context.contactForm['customer_facing_phone'] && !yup.context.contactForm['customer_facing_email']){
                    return Yup.string().required('Customer email is required')
                }
                return schema;
            }),
            zipcode: zipValidation,
            phone: phoneValidation,
            customer_facing_phone: Yup
            .string()
            .ensure()
            .when('customer_facing_email', (_:any, schema:any, yup: any) => {
                if(!yup.context.contactForm['customer_facing_email'] && yup.context.contactForm['customer_facing_phone']){
                    return Yup
                    .string()
                    .matches(/^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}/, 'Must be US phone number')
                }
                return schema;
            }),
        },[['customer_facing_email', 'customer_facing_phone']])
    };
    Object.keys(restValues).forEach((currentKey: any) => {
        if(currentKey?.includes('venue')){
            yupSchemas[currentKey] = Yup.object({
                address: Yup.string().required('Address is required'),
                name: Yup.string().required('Name is required'),
                city: Yup.string().required('City is required'),
                state: Yup.string().required('Req'),
                venue_type: Yup.string().required('Venue type is required'),
                zipcode: zipValidation,
                map_url: Yup.string().required('Required'),
                venue_logo: Yup.string().required('Required'),
                phone: phoneValidation
                .matches(/^(\([0-9]{3}\)|[0-9]{3})[\s\-]?[\0-9]{3}[\s\-]?[0-9]{4}/, 'Must be US phone number')
            });
        }
    });

    return yupSchemas;
}