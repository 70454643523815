import LoginAsModal from "components/admins/loginModal";
import DeleteIcon from "components/common/deleteIcon";
import EditIcon from "components/common/editIcon";
import LoginIcon from "components/common/loginIcon";
import ResetIcon from "components/common/resetIcon";
import { TableData, TableRow } from "components/common/table";
import { AuthContext } from "contexts/auth";
import { AuthActions } from "contexts/reducers/auth";
import Cookies from "js-cookie";
import moment from "moment";
import { useContext, useState } from "react";
import { VendorStatusColors } from "./VendorStatusColors";
import { VendorStatuses } from "util/enums/Vendors";

interface IProps {
  data: any;
  onClickDelete: Function;
  onClickEdit?: Function | null;
  onClickData?: Function;
  nonClickableRow?: boolean;
  onClickLoginAs?: Function;
  isLoginAs?: boolean;
  showStatus?: boolean;
  flash?: boolean;
  onClickReset?: Function;
}

const Vendor = ({
  data,
  onClickDelete,
  onClickData,
  nonClickableRow = false,
  onClickEdit,
  isLoginAs,
  flash,
  onClickReset,
}: IProps) => {
  const {
    fetchImpersonationToken,
    loading: authLoading,
    user: userData,
  } = useContext(AuthContext);

  const userRole = userData?.role_id || Cookies.get("role_id");
  const userEmail = userData?.email || Cookies.get("email") || "";

  const [selectedAdmin, setSelectedAdmin] = useState<any>(null!);

  const { id, name, venues, last_event, scheduled_events, status } = data;

  const onClickLogin = (data: any) => {
    setSelectedAdmin(data);
  };

  const onLoginAs = () => {
    fetchImpersonationToken(data.id);
  };

  const impersonationTokenLoading = authLoading.includes(
    AuthActions.GET_IMPERSONATION_TOKEN
  );
  //   const showStatus =
  //     status?.toLowerCase() === VendorStatus.Needs_Review.toLowerCase();

  const colorsForStatusCircle = (vendor_status: string) => {
    switch (vendor_status) {
      case VendorStatuses.ACTIVE:
        return VendorStatusColors.ACTIVE;
      case VendorStatuses.CREATED:
        return VendorStatusColors.CREATED;
      case VendorStatuses.LAPSED:
        return VendorStatusColors.LAPSED;
      case VendorStatuses.NEEDS_REVIEW:
        return VendorStatusColors.NEEDS_REVIEW;
      case VendorStatuses.ONBOARDING:
        return VendorStatusColors.ONBOARDING;
      case VendorStatuses.VP_ONBOARDING:
        return VendorStatusColors.VP_ONBOARDING;
      case VendorStatuses.WELCOME:
        return VendorStatusColors.WELCOME;
      case VendorStatuses.AT_RISK:
        return VendorStatusColors.AT_RISK;
      case VendorStatuses.SCHEDULED:
        return VendorStatusColors.SCHEDULED;
      default:
        return VendorStatusColors.NEEDS_REVIEW;
    }
  };

  return (
    <>
      <TableRow
        flash={flash}
        nonClickableRow={nonClickableRow}
        cursorPointer={true}
      >
        <TableData
          onClickData={() => onClickData && onClickData(id)}
          customClass="name"
        >
          {/* {showStatus && (
            <svg
              style={{ marginRight: "12px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <circle cx="10.5" cy="10.5" r="10.5" fill="#FD2B13" />
            </svg>
          )} */}
          {
            <svg
              style={{ marginRight: "12px" }}
              xmlns="http://www.w3.org/2000/svg"
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
            >
              <circle
                cx="10.5"
                cy="10.5"
                r="10.5"
                fill={colorsForStatusCircle(status)}
              />
            </svg>
          }
          <span>{name}</span>
        </TableData>
        <TableData
          onClickData={() => onClickData && onClickData(id)}
          customClass="venue"
        >
          {venues || 0}
        </TableData>
        <TableData
          onClickData={() => onClickData && onClickData(id)}
          customClass="scheduled-events"
        >
          {scheduled_events || 0}
        </TableData>
        <TableData
          onClickData={() => onClickData && onClickData(id)}
          customClass="last-event"
        >
          {last_event ? moment(last_event).format("M/D/YY") : "N/A"}
        </TableData>
        <TableData
          onClickData={() => onClickData && onClickData(id)}
          customClass="status"
        >
          {status}
        </TableData>
        <TableData customClass="option">
          {onClickEdit && <EditIcon onClick={() => onClickEdit(data.id)} />}
          {isLoginAs && <LoginIcon onClick={() => onClickLogin(data)} />}
          <DeleteIcon onClick={() => onClickDelete(data)} />
          {userRole &&
            +userRole === 1 &&
            status !== "Created" &&
            [
              "super.admin@crowdchow.app",
              "super@crowdchow.app",
              "superadmin@gmail.com",
            ].includes(userEmail) && (
              <ResetIcon
                onClick={() => onClickReset && onClickReset(data)}
              ></ResetIcon>
            )}
        </TableData>
      </TableRow>

      {selectedAdmin && (
        <LoginAsModal
          closeModal={() => setSelectedAdmin(null!)}
          name={selectedAdmin?.name}
          handleSubmit={() => onLoginAs()}
          isDisabled={impersonationTokenLoading}
        />
      )}
    </>
  );
};

export default Vendor;
