import React from "react"

interface Props {
    state: string;
    stepNo: number;
}

const StepCircle = ({stepNo, state}: Props) => {
    return (
        <div className={`number ${state}`}>
            <p>{stepNo}</p>
        </div>
    )
};

export default StepCircle;
