import { AuthContext } from "contexts/auth";
import { ChangeEvent, useContext } from "react";
import CrossIcon from 'assets/images/icons8-cross-35.png';
import IphoneIcon from 'assets/images/iphone-spinner.gif';

interface IProps {
    label?: string;
    placeholder?: string;
    name?: string;
    value?: any;
    onChange?: (e: ChangeEvent<any>) => void;
    onFocus?: (e: any) => void;
    onBlur?: (e: any) => void;
    customClass?: string;
    customStyles?: any
    width?: number | string;
    height?: number | string;
    isError?: boolean;
    parentClass?: string;
    searchIconClass?: string;
    isClear?: boolean;
    onClearInput?: Function;
    loading?: boolean;
}

const SearchInput = ({
    placeholder,
    name, 
    value, 
    onChange,
    onFocus,
    customClass,
    onBlur,
    customStyles,
    width,
    height,
    isError,
    parentClass,
    searchIconClass,
    onClearInput,
    isClear,
    loading
}: IProps) => {
    const { openSearchbar, openSidebar, setOpenSearchbar } = useContext(AuthContext);
    const showClear = isClear && value?.length > 0;

    const clearInput = () => {
        const e = {
            target: {
                value: ''
            }
        } as ChangeEvent<HTMLInputElement>;

        onChange && onChange(e)
        onClearInput && onClearInput();
    }
    return (
        <>
            <div className={`searchBar-mob ${openSearchbar ? 'mob-search-open' : openSidebar ? 'fadeOut' : ''}`}>
                <div className={`search-input-container ${parentClass || ''}`}>
                    <input
                        name={name}
                        value={value}
                        className={`search-input ${customClass || ''}`} 
                        type="text"
                        onChange={onChange}
                        onFocus={onFocus}
                        style={{
                            ...(customStyles && customStyles),
                            ...(width && {width}),
                            ...(height && {height})
                        }}
                        onBlur={onBlur}
                        placeholder={placeholder}
                    />
                    {loading ? (
                        <img className="search-loading-icon" src={IphoneIcon} alt="Iphone Loader" />
                    ):showClear ? (
                        <img onClick={clearInput} className="cursor-pointer clear-input-icon" src={CrossIcon} alt="clear" />
                    ):(
                        <svg className={`search-input-icon ${searchIconClass || ''}`} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <mask id="mask0_3554_5375" style={{maskType: 'luminance'}} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="24">
                                <path d="M24 0H0V24H24V0Z" fill="white"/>
                            </mask>
                            <g mask="url(#mask0_3554_5375)">
                                <path d="M23.2068 21.6759L15.9418 14.4109C17.2925 12.6715 17.9296 10.4827 17.7232 8.29007C17.5168 6.09748 16.4825 4.06598 14.831 2.60915C13.1794 1.15232 11.0347 0.379692 8.83349 0.448576C6.63229 0.517459 4.54009 1.42267 2.98285 2.97992C1.4256 4.53716 0.520389 6.62936 0.451505 8.83056C0.382622 11.0318 1.15524 13.1765 2.61207 14.828C4.0689 16.4796 6.10041 17.5139 8.293 17.7202C10.4856 17.9266 12.6744 17.2896 14.4138 15.9389L21.6758 23.2069C21.8789 23.4099 22.1542 23.524 22.4413 23.524C22.7284 23.524 23.0038 23.4099 23.2068 23.2069C23.4099 23.0039 23.5239 22.7285 23.5239 22.4414C23.5239 22.1543 23.4099 21.8789 23.2068 21.6759ZM9.11883 15.6009C7.83681 15.6009 6.58358 15.2207 5.51762 14.5085C4.45166 13.7962 3.62085 12.7839 3.13024 11.5995C2.63964 10.415 2.51127 9.11171 2.76138 7.85432C3.01149 6.59694 3.62884 5.44196 4.53536 4.53543C5.44189 3.62891 6.59687 3.01156 7.85425 2.76145C9.11164 2.51134 10.415 2.63971 11.5994 3.13031C12.7838 3.62092 13.7962 4.45173 14.5084 5.51769C15.2207 6.58365 15.6008 7.83688 15.6008 9.1189C15.599 10.8375 14.9155 12.4851 13.7002 13.7003C12.485 14.9155 10.8374 15.599 9.11883 15.6009Z" fill="#5F5F5F"/>
                            </g>
                        </svg>
                    )}
                </div>
            </div>
            <div onClick={() => setOpenSearchbar(false)} className={`invisible-overlay ${openSearchbar ? 'showOverlay' : ''}`}></div>
        </>
    )
};

export default SearchInput;
