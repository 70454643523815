import { insignificantZerosRegex } from "components/orders/details";
import { ReportContext } from "contexts/report";
import moment from "moment";
import React, { useContext } from "react"

const ItemPrint = React.forwardRef((props: any, ref: any) => {
    const { eventItems, selectedVenue } = useContext(ReportContext);
    const { event, items } = eventItems;
    return (
        <div ref={ref} className="venueReports">
            <div className="itemsTableContainer">
                <div className="d-flex reportHeaderAlignment">
                    <p className="reportsTopHeader">Crowd Chow Items Sales</p>
                </div>
                <div className="d-flex align-items-center">
                    <p className="reportsLabelHeader">Venue:</p>
                    <p className="reportsText">{selectedVenue?.name || ''}</p>
                </div>
                <div className="d-flex align-items-center">
                    <p className="reportsLabelHeader">Date:</p>
                    <p className="reportsText">
                        {event?.date ? `${moment(event?.date).format("M/D/YYYY")} ${moment(`${event?.date} ${event?.start_time}`).format("h:mm A")} - ${moment(`${event?.date} ${event?.end_time}`).format("h:mm A")}` : ''}
                    </p>
                </div>
                <div className="d-flex align-items-center">
                    <p className="reportsLabelHeader">Item Sales:</p>
                    <p className="reportsText">{event?.item_sales ? `$${event?.item_sales}` : ''}</p>
                </div>
                <table>
                    <tr>
                        <th className="tableColumnItmes">
                            Item
                        </th>
                        <th className="tableColumnOpt">
                            Options
                        </th>
                        <th className="tableColumnCount">
                            Count
                        </th>
                        <th className="tableColumnPrice">
                            Price
                        </th>
                        <th className="tableColumnTotal">
                            Total
                        </th>
                    </tr>
                    <tbody>
                        {items.map((item: any) => {
                            const {name, count, price, total, options} = item;
                            return (
                                <tr>
                                    <td>{name}</td>
                                    <td>
                                        {options?.map((option: any, index: number) => {
                                            const {name, price} = option;
                                            const isPrice = price !== "0" && price !== "0.00";
                                            return (
                                                <span style={{paddingLeft: 0}}>
                                                    {`${index ? ', ' : ''}${name}${isPrice ? ` (${price})` : ''}`}
                                                </span>
                                            )
                                        })}
                                    </td>
                                    <td>{count ? `${count}x` : ''}</td>
                                    <td>{price?.toString()?.replace(insignificantZerosRegex, '.') || ''}</td>
                                    <td>{total?.toString()?.replace(insignificantZerosRegex, '.') || ''}</td>
                                </tr>
                            )
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
});

export default ItemPrint;
