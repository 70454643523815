import { TabPanel, Tabs } from "components/common/tabs";
import { useContext, useState } from "react"
import Signage from "./signage";
import Templates from "./templates";
import Embeds from "./embeds";
import BottomBar from "components/common/bottomBar";
import { PromotionalResourcesContext } from "contexts/promotionalResources";
import { useOnboardingPending } from "hooks/useOnboardingPending";

const PromotionalResource = () => {
    const {  setSignType,  setSignageVenue,  setSignageLandingPage, setEmbedLandingPage, setEmbedVenue } = useContext(PromotionalResourcesContext);
    const [activeTab, setActiveTab] = useState(0);
    const isOnboardingPending = useOnboardingPending();




    const handleTabClick = (tab:number)=>{
        if(!isOnboardingPending){
        setActiveTab(tab)
        //reset Signage
        setSignType(null);
        setSignageVenue(null!);
        setSignageLandingPage(null!);

        //reset embeds
        setEmbedLandingPage("venue");
        setEmbedVenue(null!);
        }


    }
    
    
    return (
        <div className="promotional-resources">
            <div className="promotional-resources_container">
                <Tabs
                    tabs={['Signage', 'Templates', 'Embeds']}
                    activeTab={activeTab}
                    onClickTab={handleTabClick}
                    isOnboardingPending = {isOnboardingPending}
                >
                    <TabPanel
                        selected={activeTab === 0}
                    >
                        <Signage />
                    </TabPanel>

                    <TabPanel
                        selected={activeTab === 1}
                    >
                        <Templates />
                    </TabPanel>

                    <TabPanel
                        selected={activeTab === 2}
                    >
                        <Embeds />
                    </TabPanel>
                </Tabs>
            </div>
            <BottomBar />
        </div>
    )
};

export default PromotionalResource;