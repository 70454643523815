import Cookies from "js-cookie";

export const clearAllCookies = () => {
    const cookies = document.cookie.split(";");
    cookies.forEach((item: string) => {
        const key = item.split("=")[0]?.trim();
        if(key){
            Cookies.remove(key);
        }
    });
}