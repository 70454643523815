import Button from "components/common/button";
import SearchInput from "components/common/searchInput";
import VendorHeader from "./header";
import Vendor from "./vendor";
import { TableBody, Table } from "components/common/table";
import { Fragment, useContext, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate } from "react-router-dom";
import DeleteModal from "./deleteModal";
import AddVendorModal from "./addModal";
import BottomBar from "components/common/bottomBar";
import TableSkeleton from "components/common/skeleton/tableSkeleton";
import NoData from "components/noData";
import { VendorContext } from "contexts/vendor";
import { VendorActions } from "contexts/reducers/vendor";
import VendorStatuses from "./VendorStatuses";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import MoreDataSkeleton from "components/common/moreDataSkeleton";
import { useLimit } from "hooks/useLimit";

const   Vendors = ({
	isHeader = true,
	vendorSearch = "",
	isEdit,
	isLoginAs,
    fromLeads
}: any) => {
	const {
		addVendor,
		fetchVendors,
		vendorList,
		deleteVendor,
		resetVendor,
		loading,
		setLoading,
		page,
		setPage,
        setVenues,
        setAdmins,
        setAdminQeuryParam,
        setVenueQueryParams
	} = useContext(VendorContext);
	const navigate = useNavigate();

	const fetchMoreData = async () => {
        const nextPage = Math.ceil((queryParams.offset/queryParams.limit)+1);
        setPage(nextPage);

        const res = await fetchVendorList({ ...queryParams, offset: nextPage*queryParams.limit });
        return res;
    };

	const { handleScroll, scrollTop } = useInfiniteScroll(fetchMoreData, 560);

	const [flash, setFlash] = useState(false);
	// const [page, setPage] = useState(0);
	const [showModal, setShowModal] = useState(false);
	const [selectedVendor, setSelectedVendor] = useState<any>(null!);
	const [resettingVendor, setResettingVendor] = useState<any>(null!);
	const [search, setSearch] = useState("");
	const [statusList, setStatusList] = useState<any>([]);

	const {limit, skeletonsCount} = useLimit();

	const [queryParams, setQueryParams] = useState({
		limit: limit,
		offset: page * limit,
		sort: "",
		search: vendorSearch,
		filter: "",
	});

	const { vendors, _count, enable_filters } = vendorList;

	const debouncedSearch = useDebouncedCallback((search: string) => {
		setPage(0);
        setStatusList([]);
		fetchVendorList({
            ...queryParams,
            filter:"",
            search,
			offset: !search ? page * queryParams.limit : 0
        });
	}, 600);

	const onSearch = (value: string) => {
		//const {value} = e.target;
		setSearch(value);
		debouncedSearch(value);
	};

	const onClickDelete = async (data: any) => {
		setSelectedVendor(data);
	};

	const onClickReset = async (data: any) => {
		setResettingVendor(data);


        if (!resettingVendorLoading) {
			// Here, you can trigger your custom event or perform any action
			console.log("Resetting vendor action completed.");
			fetchVendors({
				...queryParams,
				offset: 0,
			});
		}
	};

	const onClickData = (id: number) => {

        setVenueQueryParams({
            limit: window.innerHeight > 1100 ? 20 : 15,
            offset: 0,
            sort: "",
        })
        setVenues({venues: [], _count: 0, offset: 0});
        
        setAdminQeuryParam({
            limit: window.innerHeight > 1100 ? 20 : 15,
            offset: 0,
            search: "",
            sort: "",
          })
        setAdmins({admins: [], _count: 0, _staffCount: 0, _customersCount: 0, offset: 0});

		setLoading(VendorActions.GET_VENDOR_DETAILS)
		navigate(`${id}`);
	};

	const onClickAddVendor = () => {
		setShowModal(true);
	};

	const onAddVendor = async (data: any) => {
		setLoading(VendorActions.ADD_VENDOR);

		if (!queryParams.offset) {
			const res = await addVendor(data);
			return res;
		} else {
			fetchVendorList({
				...queryParams,
				offset: 0,
			});
			const res = await addVendor(data);
			return res;
		}
	};

	const onDelete = async (id: number) => {
		const res = await deleteVendor(id);

		if (res?.status === 204) {
			setSelectedVendor(null!);
		}
	};

	const onReset = async (id: number) => {
		const res = await resetVendor(id);

		if (res?.status === 200) {
			setResettingVendor(null!);
		}
	};

	const onClickEdit = (id: number) => {
		navigate(`/vendors/${id}`);
	};

	const checkStatusAlreadyExists = (status: string) => {
		const match = statusList.filter((st: string) => status === st);
		if (match.length === 0) return false;
		else return true;
	};

	const onClickVendorStatus = (status: string) => {
		const statusAlreadyExists = checkStatusAlreadyExists(status);

		let updatedStatusList;
		if (statusAlreadyExists) {
			updatedStatusList = statusList.filter(
				(st: string) => st !== status
			);
		} else {
			updatedStatusList = [...statusList, status];
		}
		setStatusList([...updatedStatusList]);

		const statusesString = updatedStatusList.join(",");
		fetchVendorList({ ...queryParams, offset: 0, filter: statusesString });
	};

    const fetchVendorList = async (queryParams: any) => {
        setQueryParams(queryParams);
        const res = await fetchVendors(queryParams);
        return res
    }

	useEffect(() => {
		/* eslint-disable react-hooks/exhaustive-deps */
		vendorSearch && onSearch(vendorSearch);
	}, [vendorSearch]);

	useEffect(() => {
        !vendors.length && fetchVendorList(queryParams);


		/* eslint-disable react-hooks/exhaustive-deps */
	}, []);


	const getVendorLoading = loading[VendorActions.GET_VENDORS];
	const deleteVendorLoading = loading[VendorActions.DELETE_VENDOR];
	const addVendorLoading = loading[VendorActions.ADD_VENDOR];
	const resettingVendorLoading = loading[VendorActions.RESET_VENDOR];


    const isSearch = queryParams.search;


	return (
		<>
			{isHeader && (
				<div className="super-admin_addVendorContainer_action ">
					<div className="addVendor-btn">
						<Button
							buttonName="New Vendor"
							onClick={onClickAddVendor}
                            disabled = {isSearch}
						/>
					</div>

					<SearchInput
						isClear
						placeholder="Search"
						value={search}
						onChange={(e) => onSearch(e.target.value)}
					/>
				</div>
			)}
        
		    <div className="lead-container">
                { fromLeads ? <></>: 
				<VendorStatuses
					status={vendorSearch ? "": statusList}
					onClickStatus={onClickVendorStatus}
					disableAll={isSearch}
                    availableStatuses = {enable_filters}
				/> } 



				<div className="admin-data-table">
					{vendors?.length > 0 || getVendorLoading ? (
						<Table>
							<VendorHeader
								setQueryParams={fetchVendorList}
								queryParams={queryParams}
							/>
							<TableBody 
                                onScroll={(e: any) => handleScroll(e, vendors.length < _count)}
                                isShowBlur={_count > skeletonsCount && scrollTop < 10}
                            >
								{getVendorLoading ? (
									<div className="search-vendor">
										<TableSkeleton
											count={skeletonsCount}
										/>
									</div>
								) : (
									vendors.map((item: any, index: number) => {
										const { id } = item;
										return (
											<Fragment key={id}>
												<Vendor
													data={item}
													onClickDelete={
														onClickDelete
													}
													onClickData={onClickData}
													onClickEdit={
														isEdit
															? () =>
																	onClickEdit(
																		id
																	)
															: null
													}
													isLoginAs={isLoginAs}
													flash={index === 0 && flash}
													onClickReset={onClickReset}
												/>
											</Fragment>
										);
									})
								)}
                                <MoreDataSkeleton 
                                    isShow={queryParams.offset > 0 && loading[VendorActions.GET_MORE_VENDORS]} 
                                />
							</TableBody>
						</Table>
					) : (
						<NoData description="No Vendors" />
					)}
				</div>
			</div>
			<BottomBar
				showAdd
				showSearch
				onClickAdd={() => setShowModal(true)}
				addActive={showModal}
			/>
			{selectedVendor && (
				<DeleteModal
					successBtnName="Yes"
					title="Delete vendor"
					name={selectedVendor?.name}
					setIsShow={() => setSelectedVendor(null)}
					onSuccess={() => onDelete(selectedVendor?.id)}
					disabled={deleteVendorLoading}
				/>
			)}

			{resettingVendor && (
				<DeleteModal
					successBtnName="Yes"
					title="Reset vendor"
					message={`Are you sure, you want to reset vendor ${resettingVendor?.name}?`}
					name={resettingVendor?.name}
					setIsShow={() => setResettingVendor(null)}
					onSuccess={() => onReset(resettingVendor?.id)}
					disabled={resettingVendorLoading}
					yesBtnText={"Reset"}
				/>
			)}

			{showModal && (
				<AddVendorModal
					closeModal={() => setShowModal(false)}
					addVendor={onAddVendor}
					loading={addVendorLoading}
					setFlash={setFlash}
				/>
			)}
		</>
	);
};

export default Vendors;
