export const deleteObjectProp = (obj: any, fieldName: string) => {
    if(obj){
        const errorsCopy = {
            ...obj
        }

        delete errorsCopy[fieldName];

        return errorsCopy;
    }

    return null;
}