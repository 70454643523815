import { useMemo } from "react";
import MenuErrors from "./menuErrors";
import Results from './results'
import MenuSkeleton from "components/common/skeletons/menuSkeleton";

interface Props {
    csvData: any;
    onClickOpen: Function;
    error: string;
    noImageIndices: number[];
}

const MenuData = ({noImageIndices, error, csvData, onClickOpen}: Props) => {

    const {data} = csvData;
    const isError = csvData?.errors?.some((item: any) => item?.error) || error;
    const isData = data?.length > 0;

    const errorsList = useMemo(() => {
        if(!data){
            return [];
        }

        return data
        ?.map((item: any, index: number) => {
            return {
                ...item,
                row: index+1,
                modifiedErrors: [...item.errors, ...(Array.isArray(item?.errorAdmin) ? item.errorAdmin : [])].reduce((obj: any, current: any) => {
                    return {
                        ...obj,
                        isVariantError: obj?.isVariantError || current?.key?.includes("variant"),
                        isOptionError: obj?.isOptionError || current?.key?.includes("option"),
                        isCategoryError: obj?.isCategoryError || current?.key?.includes("category"),
                        isOneCategoryError: obj?.isOneCategoryError || (obj?.isCategoryError && current?.key?.includes("category")),
                        [current?.key]: current?.error
                    }
                },{})
            }
        })
        ?.filter((item: any) => item?.errors?.length > 0 || item?.errorAdmin?.length > 0);
    },[data]);

    return (
        <>
            <h2 className="menuSetup_menuBoxTitle">{isError ? 'Errors' : 'Results'}</h2>
            <p className="menuSetup_menuBoxDesc">
                {
                    isError || error
                    ? 'There were errors found in your CSV. Please correct them in the CSV and re-upload above. Hover over or tap the red highlighted errors to view details.' 
                    : 'Click on the item image to upload a new image or to select from our library. If you need to correct item details, click Cancel above and upload your corrected CSV.'
                }
            </p>
            <div style={{overflow: !isData ? 'hidden' : ''}} className={`menuSetup_menuBox ${isError ? 'errorsTable' : ''} ${!isData ? 'menuBox-hidden' : ''}`}>
                <MenuErrors
                    error={error}
                    errorsList={errorsList}
                    isError={isError}
                />
                <Results 
                    isData={isData}
                    isError={isError}
                    data={data}
                    onClickOpen={onClickOpen}
                    noImageIndices={noImageIndices}
                />
                <MenuSkeleton
                    isError={isError}
                    isData={isData}
                />
            </div>
        </>
    )
};

export default MenuData;
