import { Skeleton } from 'components/common/skeleton'
import React from 'react'

const HeaderSkeleton = () => {

    const cstmStyle = {
        borderRadius: '0px'       
      };



  return (
    <div className='summary-total events'>
        <Skeleton width={103} height={43.75} customStyles={cstmStyle}/>
        <Skeleton width={90} height={30} customStyles={cstmStyle}/>
      


    </div>
  )
}

export default HeaderSkeleton
