interface Props {
    width?: string | number;
    description?: string;
}

const NoData = ({width, description}: Props) => {
    return (
        <div style={{...(width && {width})}} className="no-data">
            <p className="description">{description || 'No data'}</p>
        </div>
    )
};

export default NoData;
