/* eslint-disable no-useless-escape */
import Label from "components/common/label";
import SectionHeader from "../sectionHeader";
import Input from "components/common/input";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { deleteObjectProp } from "util/deleteObjectProp";
import { useIsDisabled } from "hooks/useIsDisabled";
import Button from "components/common/button";
import { useContext, useState } from "react";
import { VendorContext } from "contexts/vendor";
import ApiModal from "components/common/apiErrorModal";
import { VendorActions } from "contexts/reducers/vendor";
import { useParams } from "react-router-dom";
import LabelSkeleton from "components/common/skeleton/label";
import { Skeleton } from "components/common/skeleton";
import InfoModal from "components/common/infoModal";
import moment from "moment";
import InvoiceHistoryModal from "./historyModal";

const Invoice = () => {
    const { vendorDetails, sendInvoice, fetchVendorInvoiceHistory, loading } = useContext(VendorContext);
    const { vendor } = vendorDetails;
    const { invoice_details } = vendor || {};
    const lastSent = invoice_details?.last_sent;
    


    const textToShow = lastSent ? `Renewal invoice was sent on ${moment(lastSent).format('M/D/YY')}.`:'';

    const {id} = useParams();
    const vendorId = id ? parseInt(id, 10) : 0;

    // const [isEdit, setIsEdit] = useState(!data?.email_sent);
    const [error, setError] = useState('');
    const [emailSent, setEmailSent] = useState(false);
    const [showHistory, setShowHistory] = useState(false);

    const initialValues = {
        email: ''
    }

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: Yup.object({
            email: Yup.string().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Enter valid email').required('Email is required'),
        }),
        onSubmit: async (values: any) => {
            const res = await sendInvoice({vendor_id: vendorId, ...values});

            if(res?._status?.code === 201){
                setEmailSent(true);
            }

            res?.response?.data?.message && setError(res.response.data.message);
        }
    });

    const {values, errors, setFieldValue, handleChange, setErrors, handleSubmit} = formik;

    const onClickCancel = (fieldName: string) => {
        const newErrors = deleteObjectProp(errors, fieldName);
        
        newErrors && setErrors && setErrors(newErrors);
    }

    const onClickView = () => {
        invoice_details?.invoice_pdf && window.open(invoice_details?.invoice_pdf, '_blank', 'noopener,noreferrer');

    }

    const onClickHistory = () => {
        setShowHistory(true);
        fetchVendorInvoiceHistory();
    }
    
    const isDisabled = useIsDisabled(values, errors);
    const isLoading = loading[VendorActions.SEND_INVOICE];
    const vendorLoading = loading[VendorActions.GET_VENDOR_DETAILS];

    return (
        <>
            <SectionHeader
                title="Invoice"
                subheading={invoice_details?.invoice_pdf ? " View" : ''}
                isEditable={false}
                onClickSubHeading={onClickView}
                isHistory
                onClickHistory={onClickHistory}
                isHistoryDisabled={vendorLoading}
            />
            <p className="edit-details-desc">
               {textToShow} To send a reminder invoice, enter the email of the billing contact and click Send. 
            </p>
            <div className="subscription-plan-form">
                <form onSubmit={handleSubmit}>
                    <div className="mutiple-gormGroups">
                        <div className="detail-group formGroup email">
                            <div style={{display: 'flex', alignItems: 'center'}}>
                                {vendorLoading ? (
                                    <LabelSkeleton />
                                ):(
                                    <Label inputLabel text="Email" />
                                )}
                            </div>
                            {vendorLoading ? (
                                <Skeleton />
                            ):(
                                <Input
                                    name="email"
                                    value={values.email} 
                                    onChange={(e: any) => {
                                        handleChange(e);
                                    }}
                                    isError={!!(errors.email)}
                                    placeholder={errors.email ? errors.email as string : ''}
                                    onClickCross={() => onClickCancel('email')}
                                />
                            )}
                        </div>
                        <Button 
                            buttonName="Send"
                            parentClass="sendBtn"
                            type="submit"
                            isActiveDisabled={isDisabled}
                            loading={isLoading}
                            dropShadow={false}
                            disabled={vendorLoading}
                        />
                    </div>
                </form>
                <p 
                    style={{
                        color: '#fff', 
                        marginTop: 12, 
                        marginBottom: 0, 
                        fontFamily: 'verdana-normal',
                        fontSize: 18
                    }}
                >
                    {lastSent ? `Renewal Invoice Sent ${moment(lastSent).format("M/D/YY")}` : ''}
                </p>
            </div>
            {error && (
                <ApiModal 
                    title="Send Invoice Failed"
                    message={error}
                    setIsShow={() => setError('')}
                />
            )}
            {emailSent && (
                <InfoModal
                    title="Send Invoice"
                    description={`Invoice is sent to ${values.email} successfully!`}
                    onSubmit={() => {
                        setFieldValue('email', '');
                        setEmailSent(false);
                    }}
                />
            )}

            {showHistory && (
                <InvoiceHistoryModal
                    closeModal={() => setShowHistory(false)}
                />
            )}
        </>
    )
};

export default Invoice;
