import { createContext, useReducer, useState } from "react";
import { ReportActions, ReportReducer } from "./reducers/report";
import { IReportContext } from "interfaces/reports";
import { getAllEvents, getEventDetails, getEventItems, getEventSales, getRefundsOrder, getReportEvents, getAllOrders, getOrderDetail, getVendorVenueEventForSuperAdmin } from "services/reports";
import { getVenueList, getVenueListUpdated, putOrderStatus } from "services/vendor";
import timezone from 'moment-timezone';

const initialState: IReportContext = {
    loading: {},
    eventList: {
        events: [],
        _count: 0
    },
    eventItems: {
        items: [],
        event: null!
    },
    venueList: {
        venues: [],
        _count: 0
    },
    allEvents: {
        events: [],
        _count: 0
    },
    eventDetails: {
        event: null!,
        event_sales: null!,
        items: [],
        formattedItems: [],
        printFormattedItems: []
    },
    reportDates: {
        events: null!,
        events_sales: null!
    },

    allOrders: {
        orders: [],
        _count:0
    },

    orderDetail: {
        id: 0,
        order_id: 0,
        card_last4: "",
        order_date: "",
        delivery_type: "",
        items: [
                {
                  id: 0,
                  name: "",
                  price: 0,
                  variant: "",
                  quantity: 0,
                  options: [
                    {
                      id: 4,
                      name: "",
                      price: 0
                    }
                  ]
                }
              ],
              pricing: {
                tip: "",
                sub_total: 0,
                crowd_chow_fee: 0,
                tax: 0,
                total: 0
              }
            
          
    },
    selectedVenue: null!,
    setLoading: () => {},
    resetLoading: () => {},
    fetchReprtEvents: () => Promise<any>,
    fetchEventItems: () => Promise<any>,
    fetchEventSales: () => Promise<any>,
    fetchVenues: () => Promise<any>,
    fetchAllEvents: () => Promise<any>,
    setSelectedVenue: () => Promise<any>,
    fetchAllOrder: ()=>Promise<any>,
    fetchOrderDetail: ()=>Promise<any>,
    seeAll: false,
    setSeeAll: () => {},
    fetchEventDetails: () => Promise<any>,
    fetchRefundsOrder: () => Promise<any>,
    updateOrderStatus: () => Promise<any>,
    getVenuesUpdated: ()=>Promise<any>,
    fetchVendorVenueEvent: ()=>Promise<any>,
    setEventDetails: () => {},
    setReportDetails: () => {},
    setEventItems: () => {},
    setSelectedEvent: () => {},
    selectedEvent: null!,
    startDate: null!, 
    setStartDate: () => {},
    endDate: null!, 
    setEndDate: () => {},
    setAllEvents: () => {},
    setAllOrders: ()=>{},
    setEvents: () => {},
    setOrderDetail: ()=>{},
    setOrderQueryParams: () => {},
    orderQueryParams: {
        offset: 0,
        limit: 20,
        sort_order: 'desc'
    },
    refundOrders: {
        _orders: [],
        _items: [],
        _items_count: 0,
        _orders_count: 0
    },
    venueOpen: true,
    setVenueOpen: ()=>{},

    downloadEventReport: () => {},
    eventQueryParams: {
        sort_by: 'date',
        timezone: timezone.tz.guess(),
        sort_order: '',
        filter_by: 'LAST_24_HOURS',
        limit: 10,
        offset: 0
    },
    eventOpen: false,
    setEventOpen: () => {},
    setReportReducer: () => {},
    setSelectedVendor: ()=>{},
    selectedVendor: null!,
    vendorOpen: false,
    setVendorOpen: ()=>{},
    setEventList: ()=>{},
    setVenueList: ()=>{},
    resetOrders: ()=>{},
    vendorsVenuesEvents: {
        events: [],
        search: false,
        vendors: [],
        venues: []
    },

    superAdminReportsQueryParams : {
        limit: 10, 
        offset: 0, 
        search:'',
        search_type: '',
        vendor_id:null!,
        venue_id: null!
    }, 
    setSuperAdminReportsQueryParams: ()=>{},

    venueQueryParams: {
        offset: 0,
        limit: 10,
        search: "",
    },

    setVenueQueryParams: ()=>{}
    
    
}

export const ReportContext = createContext<IReportContext>(null!)

const ReportProvider = ({ children }: any) => {

    const [{ vendorsVenuesEvents ,refundOrders, loading, reportDates, selectedVendor, selectedVenue, selectedEvent, eventDetails, allEvents, eventList, eventItems, venueList, allOrders, orderDetail, eventQueryParams }, dispatch] = useReducer(ReportReducer, initialState);
    const [seeAll, setSeeAll] = useState(false);
    const [startDate, setStartDate] = useState(null!);
    const [endDate, setEndDate] = useState(null!);
    const [venueOpen, setVenueOpen] = useState(true);
    const [vendorOpen, setVendorOpen] =  useState(initialState.vendorOpen);
    const [eventOpen, setEventOpen] = useState(initialState.eventOpen);
    const [superAdminReportsQueryParams, setSuperAdminReportsQueryParams] = useState(initialState.superAdminReportsQueryParams);
    
    const [venueQueryParams, setVenueQueryParams] = useState(initialState.venueQueryParams);

    const [orderQueryParams, setOrderQueryParams] = useState(initialState.orderQueryParams)

    const setEvents = (data: any) => {
        dispatch({
            type: ReportActions.GET_EVENTS,
            payload: data
        })
    }

    const updateOrderStatus = async (updateReq: any) => {
        setLoading(ReportActions.UPDATE_ORDER_STATUS);
        const res:any = await putOrderStatus(updateReq);
        resetLoading(ReportActions.UPDATE_ORDER_STATUS);
        
        // if(res?._status?.code === 200){
        //     dispatch({
        //         type: UserActions.UPDATE_ORDER_STATUS,
        //         payload: vendor_id
        //     });
        // }

        return res;
    }

    const setOrderDetail = (data: any) => {
        dispatch({
            type:ReportActions.GET_ORDER_DETAIL,
            payload: data

        })

    }

    const setAllOrders = (data: any) => {
        dispatch({
            type: ReportActions.GET_ORDERS,
            payload: data
        })
    }

    const resetOrders = (data: any) =>{
        dispatch({
            type: ReportActions.SET_ALL_ORDERS,
            payload: data
        })

    }

    const setSelectedVendor = (data: any)=> {
        dispatch({
            type: ReportActions.SET_SELECTED_VENDOR,
            payload: data
        })
    }

    const setVenueList = (data: any)=>{
        dispatch({
            type: ReportActions.SET_VENUE_LIST,
            payload: data
        })
    }

    const setSelectedEvent = (data: any) => {
        dispatch({
            type: ReportActions.SET_SELECTED_EVENT,
            payload: data
        })
    }

    const setEventDetails = (data: any) => {
        dispatch({
            type: ReportActions.GET_EVENT_SALES,
            payload: data
        })
    }

    const setReportDetails = (data: any) => {
        dispatch({
            type: ReportActions.GET_EVENT_DETAILS,
            payload: data
        })
    }

    const setEventItems = (data: any) => {
        dispatch({
            type: ReportActions.GET_EVENT_ITEMS,
            payload: data
        })
    }
    
    const setSelectedVenue = (venue: any) => {
        dispatch({
            type: ReportActions.SET_SELECTED_VENUE,
            payload: venue
        })
    }
    
    const setLoading = (loading: string) => {
        dispatch({
            type: ReportActions.SET_REPORT_LOADING,
            payload: loading
        })
    }

    const resetLoading = (loading: string) => {
        dispatch({
            type: ReportActions.RESET_REPORT_LOADING,
            payload: loading
        })
    }

    const fetchRefundsOrder = async (refundDatReq: any) => {
        refundDatReq.offset ? setLoading(ReportActions.SET_MORE_REFUND_ORDER) : setLoading(ReportActions.SET_REFUND_ORDER);
        const res = await getRefundsOrder(refundDatReq);
        refundDatReq.offset ? resetLoading(ReportActions.SET_MORE_REFUND_ORDER) : resetLoading(ReportActions.SET_REFUND_ORDER);

        if(res?._status?.code === 200){
            dispatch({
                type: ReportActions.SET_REFUND_ORDER,
                payload: {...res.data, offset: refundDatReq.offset}
            })
        }
    }


    const fetchAllOrder = async (ordersReq: any) =>{
        ordersReq.offset ? setLoading(ReportActions.GET_MORE_ORDERS) : setLoading(ReportActions.GET_ORDERS);
        const res = await getAllOrders(ordersReq);
        ordersReq.offset ? resetLoading(ReportActions.GET_MORE_ORDERS) : resetLoading(ReportActions.GET_ORDERS);

        if(res?._status?.code === 200){
            dispatch({
                type: ReportActions.GET_ORDERS,
                payload: {...res.data, offset: ordersReq.offset}
            })
        }
    }

    const fetchOrderDetail = async (order_id: any)=>{
        setLoading(ReportActions.GET_ORDER_DETAIL);
        const res = await getOrderDetail(order_id);
        resetLoading(ReportActions.GET_ORDER_DETAIL);
      
        if(res?._status?.code === 200){
            dispatch({
                type: ReportActions.GET_ORDER_DETAIL,
                payload: res.data
            })
        }
        

    }

    const getVenuesUpdated = async (venueListReq: any) => {
        setLoading(ReportActions.GET_VENUES)
        const res = await getVenueListUpdated(venueListReq);
        resetLoading(ReportActions.GET_VENUES);

        if(res?._status?.code === 200){
            dispatch({
                type: ReportActions.GET_VENUES,
                payload: res.data
            })
        }
    }

    const fetchVenues = async (venueListReq: any) => {
        setLoading(ReportActions.GET_VENUES)
        const res = await getVenueList(venueListReq);
        resetLoading(ReportActions.GET_VENUES);

        if(res?._status?.code === 200){
            dispatch({
                type: ReportActions.GET_VENUES,
                payload: res.data
            })
        }
    }

    const fetchReprtEvents = async (reportReq: any) => {
        setLoading(ReportActions.GET_EVENTS);
        const res = await getReportEvents(reportReq);
        resetLoading(ReportActions.GET_EVENTS);

        if(res?._status?.code === 200){
            setEvents(res.data);
        }
    }




    const setVendorsVenuesEvents = (data: any) => {
        dispatch({
            type: ReportActions.GET_VENDOR_VENUE_EVENT, 
            payload: data
        })
    }




    const setAllEvents = (data: any) => {
        dispatch({
            type: ReportActions.GET_ALL_EVENTS,
            payload: data
        })
    }

    const setEventList = (data: any) => {
        dispatch({
            type: ReportActions.SET_EVENT_LIST,
            payload: data
        })
    }


    const fetchAllEvents = async (allEventReq: any) => {
        setLoading(ReportActions.GET_ALL_EVENTS);
        const res = await getAllEvents(allEventReq);
        resetLoading(ReportActions.GET_ALL_EVENTS);

        if(res?._status?.code === 200){
            setAllEvents(res.data);
        }

        return res;
    }
    
    const fetchEventItems = async (eventItemsData: any) => {
        setLoading(ReportActions.GET_EVENT_ITEMS);
        const res = await getEventItems(eventItemsData);
        resetLoading(ReportActions.GET_EVENT_ITEMS);

        if(res?._status?.code === 200){
            dispatch({
                type: ReportActions.GET_EVENT_ITEMS,
                payload: res.data
            });
        }
    }






    
    const fetchEventSales = async (eventSaleReq: any) => {
        setLoading(ReportActions.GET_EVENT_SALES);
        const res = await getEventSales(eventSaleReq);
        resetLoading(ReportActions.GET_EVENT_SALES);

        if(res?._status?.code === 200){
            let updatedFormatItems:any=[];
            let i=0;
            while(i < res.data?.items?.length){
                updatedFormatItems.push(res.data.items?.slice(i,i+14));
                i += 14;
            }

            let printFormattedItems = [];
            i=0;
            while(i < res.data?.items?.length){
                printFormattedItems.push(res.data.items?.slice(i,i+2));
                i += 2;
            }
            dispatch({
                type: ReportActions.GET_EVENT_SALES,
                payload: {
                    ...res.data,
                    formattedItems: updatedFormatItems,
                    printFormattedItems: printFormattedItems
                }
            });
        }
    }


    const fetchVendorVenueEvent = async (reqParams: any)=> {
        setLoading(ReportActions.GET_VENDOR_VENUE_EVENT);
        const res = await getVendorVenueEventForSuperAdmin(reqParams);
        resetLoading(ReportActions.GET_VENDOR_VENUE_EVENT);

        if(res._status?.code===200){
           setVendorsVenuesEvents(res.data);
            
        }

        return res;

    }





    const fetchEventDetails = async (eventDetailsReq: any) => {
        setLoading(ReportActions.GET_EVENT_DETAILS);
        const res = await getEventDetails(eventDetailsReq);
        resetLoading(ReportActions.GET_EVENT_DETAILS);

        if(res?._status?.code === 200){
            let formattedItems: any  = [];
            let i=0;
            while(i<res?.data?.events_sales?.items.length){
                formattedItems.push(res?.data?.events_sales?.items.slice(i, i+14));
                i+=14;
            }
            
            let printFormattedItems=[];
            i=0;
            while(i<res?.data?.events_sales?.items.length){
                printFormattedItems.push(res?.data?.events_sales?.items.slice(i, i+2));
                i+=2;
            }
            dispatch({
                type: ReportActions.GET_EVENT_DETAILS,
                payload: {
                    ...res.data, 
                    events_sales: {
                        ...res.data.events_sales,
                        formattedItems: formattedItems,
                        printFormattedItems: printFormattedItems
                    }
                }
            });
        }
    }


    const downloadEventReport = async (reportReq: any) => {
        // setLoading(SuperAdminReportActions.GET_EVENT_REPORT_DOWNLOAD);
        // const res = await getReportEvents(reportReq);
        // resetLoading(SuperAdminReportActions.GET_EVENT_REPORT_DOWNLOAD);

        // if(res?._status?.code === 200){
        //     setEvents({...res.data, offset: reportReq?.offset || 0});
        // }
    }

    const setReportReducer = (type: string, data: any) => {

        dispatch({
            type: type,
            payload: data
        })
    }

    return <ReportContext.Provider value={{
        loading,
        resetLoading,
        setLoading,
        fetchReprtEvents,
        eventList,
        fetchEventItems,
        eventItems,
        fetchEventSales,
        fetchVenues,
        venueList,
        fetchAllEvents,
        allEvents,
        eventDetails,
        selectedVenue,
        setSelectedVenue,
        seeAll,
        setSeeAll,
        reportDates,
        fetchEventDetails,
        setEventDetails,
        setReportDetails,
        setEventItems,
        selectedEvent,
        setSelectedEvent,
        startDate, 
        setStartDate,
        endDate, 
        setEndDate,
        fetchRefundsOrder,
        refundOrders,
        setAllEvents,
        setEvents, 
        venueOpen, 
        setVenueOpen, 
        fetchAllOrder, 
        allOrders,
        setAllOrders,
        orderDetail, 
        setOrderDetail, 
        fetchOrderDetail,
        updateOrderStatus,
        downloadEventReport, 
        eventQueryParams, 
        setReportReducer,
        setSelectedVendor, 
        selectedVendor, 
        vendorOpen, 
        setVendorOpen,
        orderQueryParams,
        setOrderQueryParams,
        eventOpen,
        setEventOpen,
        setEventList,
        setVenueList, 
        resetOrders,
        getVenuesUpdated,
        fetchVendorVenueEvent,
        vendorsVenuesEvents,
        superAdminReportsQueryParams, 
        setSuperAdminReportsQueryParams,
        venueQueryParams, 
        setVenueQueryParams
    }}>
        {children}
    </ReportContext.Provider>
}
export default ReportProvider;