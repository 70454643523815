import { OnboardingContext } from "contexts/onboarding";
import { CHECKLIST } from "interfaces/vendor";
import { useContext } from "react";


const OnboardingContainer = (props: any) => {
    const { selectedStep } = useContext(OnboardingContext);

    const getClassName = (step: string) => {
        let className = '';
        switch(step){
            case CHECKLIST.Agreement:
                className = 'agreement';
                break;
            case CHECKLIST.Venues:
                className = 'venue-setup';
                break;
            case CHECKLIST.Billing:
                className = 'training-videos';
                break;
            case CHECKLIST.Menu:
                className = 'signage';
                break;
            case CHECKLIST.Submit_For_Review:
                className = 'submit-review';
                break;
            default:
                className = ''
        }

        return className;
    }

    return (
        <div className={`signup-body ${getClassName(selectedStep)}`}>
            {props.children}
        </div>
    )
}

export default OnboardingContainer;