import Description from "../common/decription";
import PromotionalCard from "../common/promotionalCard";
import SignagePreview from "../common/signagePreviewCard";
import VenuePreviewCard from "../common/venuePreviewCard";
import SelectOptions from "../common/selectOption";
import Dropdown from "../common/dropdown";
import { useContext, useEffect, useState } from "react";
import { PromotionalResourcesContext } from "contexts/promotionalResources";
import { AuthContext } from "contexts/auth";
import Cookies from "js-cookie";
import { useQrCodeGeneration } from "hooks/useQrCodeGeneration";
import { LandingPageType } from "interfaces/promotionalResources";
import * as pdfLib from 'pdfjs-dist/build/pdf.mjs';
import { Skeleton } from "components/common/skeleton";

if (process.env.NODE_ENV === "development") {
  pdfLib.GlobalWorkerOptions.workerSrc = new URL(
    "pdfjs-dist/build/pdf.worker.mjs",
    import.meta.url
  ).toString();
} else {
  pdfLib.GlobalWorkerOptions.workerSrc =
    "https://unpkg.com/pdfjs-dist@4.0.379/build/pdf.worker.mjs";
}

const widthMapper = {
  "2_3_GENERAL": 184,
  "3_2_GENERAL": 184,
  "7_11_CHOW_LANE": 184,
  "11_7_CHOW_LANE": 290,
  "8_5_11_COUNTER": 184,
};

const signageTypes = [
  { label: "General 2” x 3”", value: "2_3_GENERAL" },
  // {label: 'General 3” x 2”', value: '3_2_GENERAL'},
  { label: "Chow Lane 7” x 11”", value: "7_11_CHOW_LANE" },
  { label: "Chow Lane 11” x 7”", value: "11_7_CHOW_LANE" },
  { label: "Counter 8.5” x 11”", value: "8_5_11_COUNTER" },
];

const Signage = () => {
  const {
    fetchQrUrl,
    signageQrUrl,
    fetchVenues,
    venueList,
    signType,
    setSignType,
    signageVenue,
    setSignageVenue,
    signageLandingPage,
    setSignageLandingPage,
  } = useContext(PromotionalResourcesContext);
  const { venues } = venueList;

  const { user } = useContext(AuthContext);
  const id =
    user?.vendor_id || Cookies.get("vendor_id")
      ? Number(Cookies.get("vendor_id"))
      : 0;

  const { onClickDownload } = useQrCodeGeneration();

  const [loading, setLoading] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");
  const [signagePreview, setSignagePreview] = useState("");
  const [venueImage, setVenueImage] = useState("");

  const generatePdfPreview = async (
    venueId: number,
    signType: string,
    signageLandingPage: LandingPageType
  ) => {
    console.log(venueId);
    const venue =
      signageLandingPage === "vendor"
        ? id
        : venues?.find((venue: any) => venue?.id === venueId);

    if (!venue || !signType) {
      return;
    }

    setLoading(true);

    let finalUrl;
    if (signageLandingPage === "venue") {
      const url = signageQrUrl?.venues?.find(
        (item: any) => item?.id === venueId
      )?.url_two;
      finalUrl = url ? url + `?resource_type=${signType}` : "";
    } else {
      const url = signageQrUrl?.vendor["url_one"];
      finalUrl = url ? url + `?resource_type=${signType}` : "";
    }
    const pdfUrl = await onClickDownload(
      signType,
      finalUrl,
      signageLandingPage
    );
    if (!pdfUrl) {
      return;
    }

    try {
      pdfUrl && setPdfUrl(pdfUrl);
      const pdfDoc = await pdfLib.getDocument({ url: pdfUrl }).promise;
      const canvas = document.createElement("canvas");
      const page = await pdfDoc.getPage(1);

      const viewport = page.getViewport({ scale: 0.2 });
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      var render_context = {
        canvasContext: canvas.getContext("2d"),
        viewport: viewport,
      };

      await page.render(render_context).promise;
      let img = canvas.toDataURL("image/png");

      setSignagePreview(img);
    } catch (err: any) {
      console.log("err", err);
    } finally {
      setLoading(false);
    }
  };

  const onChangeVenue = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value) {
      const venueId = parseInt(e.target.value);
      setSignageVenue(venueId);
      generatePdfPreview(venueId, signType, signageLandingPage);
    }
  };

  const onChangeSignType = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const signType = e.target.value;
    setSignType(signType);

    // const imageMapper = signageToImageMapper[signType as keyof typeof signageToImageMapper];
    // if(imageMapper){
    //     setVenueImage(imageMapper);
    // }

    const imgUrl = signageQrUrl?.venue_signs?.find(
      (item: any) => item.sign_type?.toLowerCase() === signType?.toLowerCase()
    )?.image_url;
    imgUrl && setVenueImage(imgUrl);
    generatePdfPreview(signageVenue, signType, signageLandingPage);
  };

  const onClickSignage = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSignageLandingPage(e.target.value);
    generatePdfPreview(signageVenue, signType, e.target.value as any);
  };

  const onDownloadPdf = async () => {
    const alink = document.createElement("a");
    alink.href = pdfUrl;
    alink.target = "_blank";
    alink.download = `${signType}.pdf`;
    alink.click();
  };

  useEffect(() => {
    id && fetchVenues({ vendor_id: id });
    id && fetchQrUrl(id, false);

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const isVenue = signageLandingPage === "venue";
  //const isDisabled = !signType || (isVenue ? !signageVenue : false);

  const isDisabled =
    !signType ||
    (signageLandingPage !== "vendor" && signageLandingPage !== "venue") ||
    (isVenue && !signageVenue);

  // console.log('signType', signageVenue);
  return (
    <div className="signAge">
      <Description
        title="Wall and Floor Sign Generator"
        description="You have completed the onboarding checklist. 
                Your venue maps and menu will be reviewed by the Crowd Chow team. 
                Once we confirm everything looks good, you will receive an email 
                with instructions on how to continue onboarding in the Vendor App (iPad)."
      />
      <PromotionalCard>
        <SelectOptions
          heading="Select Sign Type and Location"
          isButtonDisabled={isDisabled}
          isButtonLoading={loading}
          onClickButton={onDownloadPdf}
        >
          <Dropdown
            value={signType}
            placeholder="Select Sign Type"
            options={signageTypes}
            onChange={onChangeSignType}
          />

          <Dropdown
            value={signageLandingPage}
            options={[
              { label: "Venue", value: "venue" },
              { label: "Vendor", value: "vendor" },
            ]}
            onChange={onClickSignage}
            placeholder="Select an Option"
            disabled={!signType}
          />

          {isVenue && (
            <Dropdown
              value={signageVenue}
              placeholder="Select Venue"
              options={venues.map((venue: any) => ({
                label: venue.name,
                value: venue.id,
              }))}
              onChange={onChangeVenue}
              disabled={!signType}
            />
          )}
        </SelectOptions>
        <SignagePreview>
          {loading && (
            <Skeleton
              height={238}
              width={widthMapper[signType as keyof typeof widthMapper] || 184}
            />
          )}
          {!loading &&
          ((signageLandingPage === "vendor" && signagePreview) ||
            (signageLandingPage === "venue" &&
              signageVenue &&
              signagePreview)) ? (
            <div className="priview-img">
              <img
                src={signagePreview}
                alt="Preview"
                style={{
                  maxWidth:
                    widthMapper[signType as keyof typeof widthMapper] || 184,
                  objectFit: "cover",
                }}
              />
            </div>
          ) : (
            <>{!loading && <p className="no-preview-example">No Preview</p>}</>
          )}
          <div className="label-container">
            <label className="rotate-label">Sign Preview</label>
          </div>
        </SignagePreview>
        <VenuePreviewCard venuePicture={venueImage} />
      </PromotionalCard>
    </div>
  );
};

export default Signage;
