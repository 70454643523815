import config from 'config/config';
import { ConnectStripeReq } from 'interfaces/connect-stripe';
import axios from 'services/core/axios';

export const connectStripe = async (req: ConnectStripeReq) => {

    try {
        const { data: { data } } = await axios.get(`${config.defaults.api_url}/v1/stripe/oauth/link?vendor_id=${req.vendor_id}&web=true`);
        return data;
    } catch (error) {
        return null;
    }
}

export const disconnectStripe = async (req: ConnectStripeReq) => {

    try {
        const { data: { data } } = await axios.put(`${config.defaults.api_url}/v1/admins/vendor/${req.vendor_id}`,{remove_stripe:true});
        return data;
    } catch (error) {
        return null;
    }
}
export const saveStripeAccount = async (req: ConnectStripeReq) => {

    try {
        const { data: { data } } = await axios.post(`${config.defaults.api_url}/v1/vendors/${req.vendor_id}/connected/stripe`);
        return data;
    } catch (error) {
        return null;
    }
}