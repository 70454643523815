import { Table, TableBody, TableHead, TableHeading } from "components/common/table";
// import { PromotionalResourcesContext } from "contexts/promotionalResources";
// import { useContext } from "react";
import TemplateRow from "./templateRow";

const SocialMediaTable = ({announcementText}: {announcementText: string}) => {
    return (
        <>
            <div className="table-container">
                <Table>
                    <TableHead>
                        <TableHeading customClass="purpose">
                            Vendor Name
                        </TableHeading>
                        <TableHeading customClass="vendor-venue">
                            Vendor/Venue
                        </TableHeading>
                        <TableHeading customClass="options">
                            Options
                        </TableHeading>
                        <TableHeading customClass="arrow">
                        
                        </TableHeading>
                    </TableHead>

                    <TableBody>
                        <TemplateRow 
                            text={announcementText}
                        />
                    </TableBody>
                </Table>
            </div>
        </>
    )
};

export default SocialMediaTable;
