import { Modal, ModalHeader } from "components/common/modal";
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeading,
  TableRow,
} from "components/common/table";
import NoData from "components/noData";


const VendorHistoryModal = ({ closeModal, history, section }: any) => {
  
  
    return (
    <>
      <Modal
        customClass="refund-modal subscription-history-modal"
        setShowModal={() => closeModal()}
      >
        <ModalHeader>
          <p className="app-modal-title">{`${section === "billing" ? "Billing" : " Vendor Status"} History`}</p>
        </ModalHeader>
        <div style={{ marginTop: 59 }} className="subscription-history-table">
          <Table>
            <TableHead>
              <TableHeading customClass="agreement_type">
                Status
              </TableHeading>
              <TableHeading customClass="total">{section === "billing" ? "Signup Fee" : "Vendor Type"}</TableHeading>
             
              <TableHeading customClass="total">{section === "billing" ? "Renewal Fee":""}</TableHeading>
              <TableHeading customClass="total">{section === "billing" ? "High School":""}</TableHeading>
              {section !=="billing" && <TableHeading customClass="total">Date</TableHeading>
}
            </TableHead>

            <TableBody>
              {history?.length > 0 ? (
                history?.map((historyItem: any) => {
                  return (
                    <TableRow nonClickableRow>
                      <TableData customClass={`agreement_type`}>
                        {historyItem?.status}
                      </TableData>
                      <TableData customClass={`total`}>
                        {section === "billing" ? historyItem?.sigup_fee : historyItem?.vendor_type}
                      </TableData>
                      <TableData customClass={`total`}>
                        {section === "billing"?  historyItem?.renewal_fee: ""}
                      </TableData>
                      <TableData customClass={`total`}>
                        {section === "billing" ? historyItem?.high_schools:"" }
                      </TableData>

                     {section !== "billing" && <TableData customClass={`total`}>
                        {historyItem?.date }
                      </TableData> }

                    </TableRow>
                  );
                })
              ) : (
                <NoData description="No History" />
              )}
            </TableBody>
          </Table>
        </div>
      </Modal>
    </>
  );
};

export default VendorHistoryModal;
