
import Button from 'components/common/button';

import StepCircle from './menu/stepCircle';
import { useNavigate } from 'react-router-dom';

export const enum FlyersClickedOptions {
    FLYER_1 = 1,
    FLYER_2 = 2,
    FLYER_3 = 3,
    FLYER_4 = 4,
    FLYER_5 = 5,
    FLYER_6 = 6,
}

export const enum FlyersType {
    FLYER_TYPE_1 = "2x3",
    FLYER_TYPE_2 = "4x6",
    FLYER_TYPE_3 = "6x4",
    FLYER_TYPE_4 = "test",
}



const SubmitForReview = () => {
   
   
    const navigate = useNavigate()

  


  






    return (
        <div className="submitReview">
            <h2 className="submitReview_title">Submitted for Review</h2>
            <p className="submitReview_desc">You have completed the onboarding checklist. Your venue maps and menu will be reviewed by the Crowd Chow team. Once we confirm everything looks good, you will receive an email with instructions on how to continue onboarding in the Vendor App (iPad).</p>
            <p className="submitReview_desc">In the meantime, please review the required hardware and generate your venue signage for printing. Once your onboarding is complete, you will use this Vendor Portal to access reports, billing, promotional resources, and guides/training videos.</p>
            <div className="submitReview_signFlyers">
            <h2 className="submitReview_title">Required Hardware</h2>
            <p className="submitReview_desc">To operate crowd chow, you will need an iPad and Bixolon label printer for each venue (3). Use the links below to purchase or use the model requirements to source elsewhere</p>
            </div>

            <div className='review-order'>
                <StepCircle 
                    stepNo={1}
                    state={'step-number'}
                />
                <div className="menuSetup_uploadFile">
                    <StepCircle 
                        stepNo={1}
                        state={'step-number'}
                    />
                    <div className='product-img'>
                        <img alt='printer' src="/images/widget/printer_1.png"/>
                    </div>
                    <div className='product-detail'> 
                        <p className="product-name">Bixolon Label Printer</p>
                        <p className='product-desc'>Bixolon Slp-Dx220BG Bluetooth Label Printer.</p>
                        <button type='button' className='view-amazon-btn'>View on Amazon</button>
                    </div>  
                </div>
            </div>

            <div className='review-order'>            
                <StepCircle 
                    stepNo={2}
                    state={'step-number'}
                />
                <div className="menuSetup_uploadFile">            
                    <StepCircle 
                        stepNo={2}
                        state={'step-number'}
                    />
                    <div className='product-img'>
                        <img alt='printer' src="/images/widget/tharmal-label.png"/>
                    </div>
                    <div className='product-detail'>
                        <p className="product-name">Thermal Labels</p>
                        <p className='product-desc'>2" x 1.5" (51 mm x 38 mm) Thermal Labels. 25.4mm diameter. </p>
                        <button type='button' className='view-amazon-btn'>View on Amazon</button>
                    </div> 
                </div>
            </div>
            <div className='review-order'>            
                <StepCircle 
                    stepNo={3}
                    state={'step-number'}
                />
                <div className="menuSetup_uploadFile">            
                    <StepCircle 
                        stepNo={3}
                        state={'step-number'}
                    />
                    <div className='product-img'>
                        <img alt='printer' src="/images/widget/tab.png"/>
                    </div>
                    <div className='product-detail'>
                        <p className="product-name">iPad</p>
                        <p className='product-desc'>Supported models: iPad Pro, 12th+ Gen iPad, 2nd Gen+ iPad Air, iOS 15</p>
                        <button type='button' className='view-amazon-btn'>View on Amazon</button>
                    </div>  
                </div>
            </div>

            <div className="submitReview_signFlyers">
            <h2 className="submitReview_title">Generate Signage</h2>
            <p className="submitReview_desc">Promoting Crowd Chow before, during and after events is the key to adoption and increased sales. Go to Promotional Resources to print your signage for events.</p>
           
            <div className="submitReview_go-promotional">
                <Button
                    buttonName='Go to Promotional Resources'
                    onClick={() => {navigate("/promotional-resources")}}
                />
            </div>
            </div>
            
        </div>
    )
}

export default SubmitForReview;