import {  FC, useContext } from "react";
import EventsHeader from "./EventsHeader";
import EventsBody from "./EventsBody";
import { SuperAdminReportContext } from "contexts/superAdminReport";
import { SuperAdminReportActions } from "contexts/reducers/superAdminReports";
import VenueName from "components/reports/normalAdmin/venueName";

interface Props {
    activeTab: any
}



const Events:FC<Props> = ({activeTab}) => {

const {selectedVendor} = useContext(SuperAdminReportContext)    

    const { eventList, loading, eventQueryParams } = useContext(SuperAdminReportContext);
    const { events_data } = eventList;
    const isLoading = loading[SuperAdminReportActions.GET_EVENTS];
    const {offset} = eventQueryParams;

    return (
        <div className="superAdminEventsReports">
        <VenueName isSuperReport = {true} venueName={!selectedVendor ? "All Vendors" : selectedVendor?.name} />
        <EventsHeader isLoading = {isLoading} events_data={events_data} offSet={offset} />
        <EventsBody activeTab = {activeTab}   /> 

        </div>
    )
};

export default Events;
