import { useRef, useState } from "react";
import { Skeleton } from "components/common/skeleton";
import Slider from "react-slick";
import ReportsPagination from "./pagination";
import ItemName from "./itemName";
import Price from "./price";

export const getMaxQuantityLengths = (items: any[]) => {
    let leftMaxQuantityLength: any = 0;
    let rightMaxQuantityLength: any = 0;

    const leftList: any[] = [];
    const rightList: any[] = [];
    for (let i=0; i<items?.length; i++){
        if(i%2 === 0){
            leftList.push(items[i]?.quantity?.toString()?.length)
        }else{
            rightList.push(items[i]?.quantity?.toString()?.length)
        }
    }

    if(window.innerWidth <= 850){
        leftMaxQuantityLength = Math.max(...leftList, ...rightList);
        rightMaxQuantityLength = leftMaxQuantityLength;
    }else{
        leftMaxQuantityLength = Math.max(...leftList);
        rightMaxQuantityLength = Math.max(...rightList);
    }
    

    return {
        leftMaxQuantityLength,
        rightMaxQuantityLength
    }
}

const EventItems = ({formattedItems, isLoading, isDataFetched}: any) => {

    const dotsCount = Math.ceil(formattedItems.length/8);
    const [noOfDots, setNoOfDots] = useState(dotsCount);
    const sliderRef = useRef<any>(null);
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);

    const settings = {
        dots: true,
        arrows: false,
        infinite: false,
        speed: 500,
        rows: 1,
        slidesPerRow: 1,
        responsive: [
            {
              breakpoint: 1024,
              settings: {
                rows: 1,
                slidesPerRow: 1,
              }
            },
            {
                breakpoint: 768,
                settings: {
                  rows: 1,
                  slidesPerRow: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                  rows: 1,
                  slidesPerRow: 1,
                }
            },
            {
                breakpoint: 480,
                settings: {
                  rows: 1,
                  slidesPerRow: 1,
                }
            }
        ],
        beforeChange: (_: number, newIndex: number) => {
            setActiveSlideIndex(newIndex);
        },
        appendDots: (dots: any) => {
            setNoOfDots(dots.length);
            return <ul>{dots}</ul>
        }
    };
    
    // console.log('formattedItems', formattedItems);
    const isEvent = isDataFetched;
    return (
        <>
            <div className="sales-items">
                <div className="sales-items-heading">
                    {isEvent ? isLoading ? <Skeleton height={28} /> : 'Item Sales:' : <Skeleton customStyles={{borderRadius: 7}} width={90} height={26} animation={false} />}
                </div>

                {isLoading ? (
                    <>
                        <div className="items-column skeleton">
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                            <Skeleton />
                        </div>
                    </>
                ):(
                    <div className="items-slider">
                        <Slider ref={sliderRef} {...settings}>
                            {formattedItems.map((items: any, index: number) => {
                                const { leftMaxQuantityLength, rightMaxQuantityLength } = getMaxQuantityLengths(items);
                                return (
                                    <div key={index} className="items-column">
                                        {items.map((item: any, itemIdx: number) => {
                                            const { id, name, quantity, variant_1, variant_2, variant_3, total_price } = item;
                                            const formattedQuantity = quantity?.toString()?.length > 4 ? '9999+' : quantity;
                                            return (
                                                <div key={id} className="sale-item-detail">
                                                    <div className="sale-qty">
                                                        <div className="qty">
                                                            {formattedQuantity ? (
                                                                <p className={`${quantity?.toString()?.length > 1 ? 'qty-indicator' : ''}`}>
                                                                    {formattedQuantity}
                                                                    <span>x</span>
                                                                </p>
                                                            ):(
                                                                <p>0</p>
                                                            )}
                                                        </div>
                                                        <ItemName 
                                                            name={name}
                                                            variant_1={variant_1}
                                                            variant_2={variant_2}
                                                            variant_3={variant_3}
                                                            className={`qty-food-item-${itemIdx%2===0 ? leftMaxQuantityLength : rightMaxQuantityLength}`}
                                                        />
                                                    </div>
                                                    <p className="price-item">
                                                        <Price amount={total_price} />
                                                    </p>
                                                </div>
                                            )
                                        })}
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                )}
            </div>
            {!!(formattedItems?.length > 1) && (
                <ReportsPagination
                    count={noOfDots} 
                    activePage={activeSlideIndex}
                    onClickArrow={(page: number) => {
                        sliderRef.current?.slickGoTo(page);
                    }}
                />
            )}
        </>
    )
};

export default EventItems;
