import Button from "../button";

interface IProps {
    customClass?: string;
    children?: any;
    onCancel?: Function;
    cancelDisabled?: boolean;
    submitActiveDisabled?: boolean;
    submitDisabled?: boolean;
    onSubmit?: Function;
    submitButtonName?: string;
    cancelButtonName?: string;
    submitLoading?: boolean;
}

const ModalFooter = ({
    children, 
    onSubmit, 
    cancelDisabled, 
    submitDisabled, 
    submitActiveDisabled, 
    onCancel, 
    customClass,
    submitButtonName,
    cancelButtonName,
    submitLoading
}: IProps) => {
    return (
        <div className={`app-modal-footer ${customClass || ''}`}>
            {onCancel ? 
                <Button
                    buttonName={cancelButtonName || "Cancel"}
                    cancelButton
                    onClick={onCancel}
                    customStyles={{
                        borderRadius: 40
                    }}
                    disabled={cancelDisabled || submitLoading}
                /> : <></>
            }
            <Button  
                buttonName={submitButtonName || 'Okay'}
                customStyles={{
                    borderRadius: 40
                }}
                onClick={onSubmit}
                disabled={submitDisabled}
                isActiveDisabled={submitActiveDisabled}
                loading={submitLoading}
            />
            {children && children}
        </div>
    )
};

export default ModalFooter;
