import VenueCore from "components/venue";

const Venue = () => {
    return (
        <>
            <VenueCore />
        </>
    )
};

export default Venue;
