interface IProps {
    children: any,
    customClass?: string;
}

const ModalHeader = ({children, customClass}: IProps) => {
    return (
        <div className={customClass || ''}>
            {children}
        </div>
    )
};

export default ModalHeader;
