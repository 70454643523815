import { useContext, useEffect, useState } from "react";
import VendorInfo from "./vendorInfo";
import CustomerContactInfo from "./customerContactInfo";
import VendorTaxFees from "./vendorTaxFees";
import Subscription from "./subscription";
import { useParams } from "react-router-dom";
import { VendorBillingStatuses } from "../../util/enums/VendorBillingStatuses";
import BottomBar from "components/common/bottomBar";
import {  VendorStatus } from "interfaces/vendor";
import { VendorContext } from "contexts/vendor";
import VendorStatusSection from "./vendorStatus";
import Invoice from "./invoice/index";
import BillingContact from "./billingContact";
import AgreementSection from "./agreement";
import { VendorActions } from "contexts/reducers/vendor";
import Billing from "./Billing";
import SendWelcomeEmailToProspect from "./SendWelcomeEmailToProspect";

export const enum VendorDetailSections {
  VENDOR_INFO = "Vendor Info",
  CUSTOMER_INFO = "Customer Info",
  SETTINGS = "Settings",
  VENDOR_STATUS = "Vendor Status",
  WELCOME_EMAIL = "Welcome Email",
  SUBSCRIPTION = "Subscription",
  SALES = "Sales",
  BILLING_CONTACT = "Billing Contact",
  INVOICE_NEW = "Invoice New",
  AGREEMENT = "Agreement",
  BILLING = "Billing",
}

const VendorDetails = () => {
  const { fetchVendorDetail, vendorDetails, loading } =
    useContext(VendorContext);
  const { vendor } = vendorDetails;

  const [activeEdit, setActiveEdit] = useState("");

  const { id } = useParams();

  useEffect(() => {
    id && fetchVendorDetail(parseInt(id, 10));

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const notPaid =
    vendor?.billing?.status?.toLowerCase() !==
    VendorBillingStatuses.PAID?.toLowerCase();
  const notYet = vendor?.billing?.status === VendorBillingStatuses.NEW;
  const isShowSendEmail =
    notYet &&
    ( vendor?.status?.toLowerCase() === VendorStatus.Vp_Onboarding.toLowerCase() ||
      vendor?.status?.toLowerCase() === VendorStatus.Created.toLowerCase() ||
      vendor?.status?.toLowerCase() === VendorStatus.Welcome.toLowerCase());
  const isShowInvoice =
    vendor?.billing?.status?.toLowerCase() ===
    VendorBillingStatuses.UNPAID?.toLowerCase() ||
    vendor?.billing?.status?.toLowerCase() ===
    VendorBillingStatuses.LATE?.toLowerCase();
  const isLoading = loading[VendorActions.GET_VENDOR_DETAILS];
  return (
    <>
      <div className="super-admin_admin-detail-container_tab-content-container row">
        <div className="edit-details six-columns-lg columns">
          <VendorInfo
            setActiveEdit={setActiveEdit}
            activeEdit={activeEdit}
            data={vendor}
          />

          <BillingContact
            setActiveEdit={setActiveEdit}
            activeEdit={activeEdit}
          />

          <CustomerContactInfo
            data={vendor}
            setActiveEdit={setActiveEdit}
            activeEdit={activeEdit}
          />

          <VendorTaxFees
            data={vendor}
            setActiveEdit={setActiveEdit}
            activeEdit={activeEdit}
          />
        </div>

        <div className="edit-details six-columns-lg columns right-VenueLogo">
          <VendorStatusSection
            notPaid={notPaid}
            data={vendor}
            setActiveEdit={setActiveEdit}
            activeEdit={activeEdit}
          />

        

          <Subscription
            notPaid={notPaid}
            data={vendor}
            setActiveEdit={setActiveEdit}
            activeEdit={activeEdit}
          />
          <Billing
            data={vendor}
            setActiveEdit={setActiveEdit}
            activeEdit={activeEdit}
          />

     {isShowInvoice && <Invoice />}


          <AgreementSection
            setActiveEdit={setActiveEdit}
            activeEdit={activeEdit}
          ></AgreementSection>

          {isShowSendEmail && !isLoading && (
            <SendWelcomeEmailToProspect
              data={vendor?.prospect_details}
              setActiveEdit={setActiveEdit}
              activeEdit={activeEdit}
              subscriptions={vendor?.subscriptions}
            />
          )}

          {/* <Sales data={sales} setActiveEdit={setActiveEdit} activeEdit={activeEdit} /> */}
        </div>
      </div>
      <BottomBar />
    </>
  );
};

export default VendorDetails;
