import { Modal, ModalHeader } from "components/common/modal";
import { useContext } from "react";
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeading,
  TableRow,
} from "components/common/table";
import NoData from "components/noData";
import { VendorContext } from "contexts/vendor";
import moment from "moment";

const AgreementHistoryModal = ({ closeModal }: any) => {
  const { vendorDetails } = useContext(VendorContext);
  const { vendor } = vendorDetails;

  return (
    <>
      <Modal
        customClass="refund-modal subscription-history-modal"
        setShowModal={() => closeModal()}
      >
        <ModalHeader>
          <p className="app-modal-title">Agreement History</p>
        </ModalHeader>
        <div style={{ marginTop: 59 }} className="subscription-history-table">
          <Table>
            <TableHead>
              <TableHeading customClass="agreement_type">
                Agreement Type
              </TableHeading>
              <TableHeading customClass="total">Active</TableHeading>
              <TableHeading customClass="total">User</TableHeading>
              <TableHeading customClass="total">Date</TableHeading>
            </TableHead>

            <TableBody>
              {vendor?.agreement_history?.length > 0 ? (
                vendor?.agreement_history?.map((agreement: any) => {
                  return (
                    <TableRow nonClickableRow>
                      <TableData customClass={`agreement_type`}>
                        {agreement?.agreement_type}
                      </TableData>
                      <TableData customClass={`total`}>
                        {agreement?.latest ? "Yes" : "No"}
                      </TableData>
                      <TableData customClass={`total`}>
                        {agreement?.admin_name || "N/A"}
                      </TableData>
                      <TableData customClass={`total`}>
                        {moment(agreement?.agreement_date).format("M/D/YY")}
                      </TableData>
                    </TableRow>
                  );
                })
              ) : (
                <NoData description="No History" />
              )}
            </TableBody>
          </Table>
        </div>
      </Modal>
    </>
  );
};

export default AgreementHistoryModal;
