import { TabPanel, Tabs } from "components/common/tabs";
import { FC, useContext, useEffect, useRef, useState } from "react";
import Events from "./events";
import Dates from "./dates";

import PrintIcon from "./printIcon";
import { useReactToPrint } from "react-to-print";
import Print from "./events/print";
import DatesPrint from "./dates/print";
import ItemPrint from "./items/print";
import timezone from "moment-timezone";
import {
  downloadEventReport,
  downloadItemsReport,
  downloadSalesReport,
} from "services/vendor";
import Cookies from "js-cookie";
import { AuthContext } from "contexts/auth";
import BottomBar from "components/common/bottomBar";
import { useIsSuperAdmin } from "hooks/useIsSuperAdmin";
import { useNavigate, useLocation } from "react-router-dom";
import BackButton from "../../backButton";

interface Props {
  eventList: any;
  fetchEventItems: Function;
  setEventItems: Function;
  fetchEventSales: Function;
  fetchVenues: Function;
  setSeeAll: Function;
  selectedVenue: any;
  selectedEvent: any;
  startDate: any;
  endDate: any;
  fetchReprtEvents: Function;
  eventDetails: any;
  seeAll: any;
  setAllOrders: Function;
  vendorList?: any;
  selectedVendor?: any;
  setSelectedVendor?: Function;
  setOrderQueryParams: Function;
  venueList?: any;
  setSelectedVenue?: Function;
  allEvents?: any;
  fetchAllEvents?: Function;
  setSelectedEvent?: Function;
  fetchAllOrder?: Function;
  orderQueryParams?: any;
  setVenueOpen?: Function;
  getVenuesUpdated?: Function
  fetchVendorVenueEvent? : Function, 
  venueQueryParams?: any,
  setVenueQueryParams?: Function, 
  superAdminReportsQueryParams?: any 
  vendorsVenuesEvents?:any, 
  setVenueList?: Function, 
  setAllEvents?: Function
 
}

const ReportTabs: FC<Props> = ({
  eventList,
  fetchEventItems,
  setEventItems,
  fetchEventSales,
  fetchVenues,
  setSeeAll,
  selectedVenue,
  selectedEvent,
  startDate,
  endDate,
  fetchReprtEvents,
  eventDetails,
  seeAll,
  setAllOrders,
  vendorList,
  selectedVendor,
  setSelectedVendor,
  setOrderQueryParams,
  venueList,
  setSelectedVenue,
  fetchAllEvents,
  allEvents,
  setSelectedEvent,
  fetchAllOrder,
  orderQueryParams,
  setVenueOpen,
  getVenuesUpdated,
  fetchVendorVenueEvent,
  venueQueryParams, 
  setVenueQueryParams,
  superAdminReportsQueryParams,
  vendorsVenuesEvents, 
  setVenueList, 
  setAllEvents
  
}) => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState(0);
  const [loading, setLoading] = useState(false);

  const isSuperAdmin = useIsSuperAdmin();
  const location = useLocation();

  const componentRef = useRef<any>();

  const onClickTab = (activeTab: number) => {
    setActiveTab(activeTab);

    if (selectedVenue && eventList?.events?.length === 0) {
      fetchReprtEvents({ venue_id: selectedVenue?.id });
    }

    if (
      selectedEvent &&
      activeTab === 0 &&
      selectedEvent?.id !== eventDetails?.event?.id
    ) {
      fetchEventSales({
        event_id: selectedEvent?.id,
        timezone: timezone.tz.guess(),
      });
    }

    if (selectedEvent && activeTab === 2) {
      fetchEventItems({
        event_id: selectedEvent?.id,
        sort_order: "ASC",
        sort_by: "quantity",
      });
    } else setEventItems({ items: [] });

    setSeeAll(false);

    setOrderQueryParams((prev: { offset: number; limit: number; sort_order: string }) => ({
      ...prev,
      offset: 0,
    }));

    if (selectedEvent && activeTab === 1) {
      setAllOrders({ count: 0, orders: [] });
    }

    if (selectedVenue && activeTab === 0) {
      setAllOrders({ count: 0, orders: [] });
    }
  };

  const onClickPrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const eventReport = async () => {
    setLoading(true);
    const timeZone = timezone.tz.guess();
    const res = await downloadEventReport({
      event_id: selectedEvent.id,
      timezone: timeZone,
    });
    setLoading(false);
    const blob = new Blob([res], { type: "text/plain" });
    const a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = `${selectedEvent.name?.toLowerCase()?.replace(/\s/g, "_")}_${
      selectedEvent.end_date
    }_report.csv`;
    a.click();
  };

  const salesReport = async () => {
    setLoading(true);
    const res = await downloadSalesReport({
      venue_id: selectedVenue?.id,
      start_date: startDate,
      end_date: endDate,
    });
    setLoading(false);
    const blob = new Blob([res], { type: "text/plain" });
    const a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = `${startDate}_${endDate}_sales_report.csv`;
    a.click();
  };

  const itemsReport = async () => {
    setLoading(true);
    const res = await downloadItemsReport(selectedEvent.id);
    setLoading(false);
    const blob = new Blob([res]);
    const a = window.document.createElement("a");
    a.href = window.URL.createObjectURL(blob);
    a.download = `${selectedEvent.name?.toLowerCase()?.replace(/\s/g, "_")}_${
      selectedEvent.end_date
    }_items_report.csv`;
    a.click();
  };

  const onClickDownload = () => {
    switch (activeTab) {
      case 0:
        eventReport();
        break;
      case 1:
        salesReport();
        break;
      case 2:
        itemsReport();
        break;
      default:
    }
  };

  useEffect(() => {
    const handler = (e: any) => {
      if (
        e.ctrlKey &&
        (e.key === "p" ||
          e.charCode === 16 ||
          e.charCode === 112 ||
          e.keyCode === 80)
      ) {
        e.cancelBubble = true;
        e.preventDefault();

        onClickPrint();
      }
    };
    window.addEventListener("keydown", handler, false);

    return () => {
      window.removeEventListener("keydown", handler, false);
    };

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const fetchSales = async (event: any, timeZone: any) => {


    if(event){
      
    await fetchEventSales({ event_id: event?.id, timezone: timeZone });
    setOrderQueryParams((prev: { offset: number; limit: number, sort_order: string }) => ({
      ...prev,
      offset: 0,
    }));
    fetchAllOrder &&
      (await fetchAllOrder({
        event_id: event?.id,
        venue_id: selectedVenue?.id,
        ...orderQueryParams,
        offset: 0,
  
  
      }));

    }
  };






  useEffect(() => {
    if (!isSuperAdmin) {
      const id = user?.vendor_id || Cookies.get("vendor_id");
      id && fetchVenues({ vendor_id: id, ...venueQueryParams });

    } 
    else 
    
    
    
    {

        setVenueOpen && setVenueOpen(false);
        const { event_id } = location.state || {};
     
        if(!fetchVendorVenueEvent){return}
        fetchVendorVenueEvent({...superAdminReportsQueryParams, event_id: event_id });

    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);



  useEffect(()=>{

    if(isSuperAdmin){
    const { vendor_name, venue_name, event_id } = location.state || {};

    const matchingVendor = vendorsVenuesEvents.vendors.find((vendor: any)=>vendor.name===vendor_name)        
    const matchingVenue =  matchingVendor?.venues.find((venue: any)=>venue.name=== venue_name);
    const matachingEvent = matchingVenue?.venue_events.find((event: any)=>event.id=== event_id);
    
    
    
    setSelectedEvent && setSelectedEvent(matachingEvent);
    setSelectedVenue && setSelectedVenue(matchingVenue);
    setSelectedVendor && setSelectedVendor(matchingVendor)
    
    setVenueList && setVenueList({venues: matchingVendor?.venues, count: matchingVendor?.venues.length})
    setAllEvents && setAllEvents({events: matchingVenue?.venue_events, count: matchingVenue?.venue_events?.length})  





    fetchSales(matachingEvent, timezone.tz.guess());
   
    console.log(matchingVenue?.venue_events);
    }


  },[vendorsVenuesEvents?.vendors])


  let isDisabled = !selectedVenue || !selectedEvent;
  if (activeTab === 1) {
    isDisabled = !selectedVenue?.id || !startDate || !endDate;
  }

  if (activeTab === 2) {
    isDisabled = !selectedEvent;
  }

  return (
    <>
      <div className="hide-on-print reports-main">
        {isSuperAdmin && !seeAll ? (
          <div className="backBtn">
            <BackButton
              onClick={() =>
                navigate("/reports", { state: { from: "superDashboard" } })
              }
            />
          </div>
        ) : (
          <></>
        )}

        <PrintIcon
          onClick={onClickPrint}
          disable={isDisabled}
          seeAll={seeAll}
        />
        <div
          className={`download-csv ${
            isDisabled || loading ? "disabled" : "cursor-pointer"
          } ${
            location.pathname.includes("reports") && !isSuperAdmin && seeAll
              ? "event-csv"
              : ""
          } ${location.pathname.includes("details") && isSuperAdmin && !seeAll
          ? "eventDetail-csv"
          : ""} `}
        >
          <svg
            onClick={() => !isDisabled && !loading && onClickDownload()}
            xmlns="http://www.w3.org/2000/svg"
            width="49"
            height="55"
            viewBox="0 0 49 55"
            fill="none"
          >
            <path
              d="M48.923 2H6.37451V54.3791H35.2492L48.923 42.0871V2Z"
              fill="black"
            />
            <path
              d="M35.249 54.38L48.9228 42.0879H35.249V54.38Z"
              fill="black"
            />
            <path
              d="M30.8022 11.6113H2V24.4977H30.8022V11.6113Z"
              fill="black"
            />
            <path
              d="M11.4637 16.9369C11.382 16.6424 11.3167 16.4871 11.194 16.3153C10.9487 15.9554 10.5563 15.7754 10.0494 15.7754C9.58341 15.7754 9.1829 15.9309 8.89677 16.2335C8.52076 16.6261 8.31634 17.2559 8.31634 18.0412C8.31634 19.4726 8.97848 20.3395 10.074 20.3395C10.589 20.3395 11.0141 20.1432 11.2675 19.7833C11.3982 19.5869 11.4637 19.4152 11.5209 19.0554L12.9434 19.2844C12.7798 19.9388 12.6246 20.2823 12.3303 20.634C11.8479 21.2147 11.0551 21.5256 10.0823 21.5256C9.10951 21.5256 8.35734 21.2311 7.82609 20.683C7.22933 20.0696 6.90234 19.129 6.90234 18.0493C6.90234 16.9697 7.25384 16.0046 7.91597 15.3664C8.44737 14.8593 9.17487 14.5977 10.0578 14.5977C11.0796 14.5977 11.8726 14.9494 12.3875 15.6282C12.6246 15.939 12.7472 16.2008 12.8617 16.6424L11.4637 16.9369Z"
              fill="black"
            />
            <path
              d="M15.0115 19.2651C15.0442 19.6004 15.0932 19.7559 15.2323 19.9276C15.453 20.2139 15.8372 20.3692 16.3196 20.3692C17.0308 20.3692 17.4805 20.042 17.4805 19.5185C17.4805 19.2649 17.3742 19.0604 17.1616 18.9296C16.9082 18.7661 16.6548 18.6842 15.7637 18.4798C15.0689 18.3161 14.7991 18.2098 14.4884 17.9889C14.0306 17.6454 13.7855 17.1464 13.7855 16.5411C13.7855 15.3387 14.7419 14.5781 16.2542 14.5781C17.227 14.5781 17.9627 14.8726 18.3879 15.4288C18.5923 15.7069 18.6985 15.9442 18.8047 16.4267L17.3659 16.5822C17.3251 16.3286 17.2679 16.2059 17.1533 16.0586C16.9652 15.8377 16.6383 15.7151 16.2459 15.7151C15.6083 15.7151 15.1833 16.0259 15.1833 16.4922C15.1833 16.7131 15.2813 16.893 15.4612 17.0239C15.6411 17.1384 15.8045 17.1958 16.2296 17.2939C17.1206 17.4821 17.513 17.5883 17.84 17.7355C18.1751 17.8827 18.4776 18.1362 18.6657 18.4471C18.8291 18.717 18.9109 19.0442 18.9109 19.4367C18.9109 20.7699 17.979 21.5307 16.3441 21.5307C15.2896 21.5307 14.4966 21.2362 14.0634 20.6882C13.7936 20.3528 13.6792 20.042 13.5894 19.404L15.0118 19.2649L15.0115 19.2651Z"
              fill="black"
            />
            <path
              d="M22.4668 19.6257C22.5403 19.8629 22.6058 20.0838 22.6303 20.1901C22.6794 20.0183 22.7366 19.8302 22.8019 19.6257L24.069 15.6014C24.208 15.168 24.2244 15.0861 24.2734 14.7344H25.9002C25.7939 14.9471 25.7039 15.1678 25.5078 15.7241L23.7665 20.5335C23.6195 20.926 23.5376 21.1878 23.5049 21.3842H21.7555C21.7146 21.1796 21.6329 20.9179 21.4938 20.5335L19.7526 15.7241C19.5808 15.2416 19.4664 14.9716 19.3521 14.7344H20.9788C21.0278 15.0534 21.085 15.2824 21.1832 15.6014L22.4666 19.6257H22.4668Z"
              fill="black"
            />
            <path
              d="M46.923 0H4.37451V52.3791H33.2492L46.923 40.0871V0Z"
              fill="#F6F6F6"
            />
            <path
              d="M33.249 52.38L46.9228 40.0879H33.249V52.38Z"
              fill="#B4B4B4"
            />
            <path
              d="M28.8022 9.61133H0V22.4977H28.8022V9.61133Z"
              fill="#41BB86"
            />
            <path
              d="M9.46375 14.9369C9.38204 14.6424 9.31667 14.4871 9.19396 14.3153C8.94869 13.9554 8.55634 13.7754 8.04945 13.7754C7.58341 13.7754 7.1829 13.9309 6.89677 14.2335C6.52076 14.6261 6.31634 15.2559 6.31634 16.0412C6.31634 17.4726 6.97848 18.3395 8.07396 18.3395C8.58902 18.3395 9.01405 18.1432 9.2675 17.7833C9.39823 17.5869 9.46374 17.4152 9.52094 17.0554L10.9434 17.2844C10.7798 17.9388 10.6246 18.2823 10.3303 18.634C9.84792 19.2147 9.05506 19.5256 8.08228 19.5256C7.10951 19.5256 6.35734 19.2311 5.82609 18.683C5.22933 18.0696 4.90234 17.129 4.90234 16.0493C4.90234 14.9697 5.25384 14.0046 5.91597 13.3664C6.44737 12.8593 7.17487 12.5977 8.05777 12.5977C9.07957 12.5977 9.87258 12.9494 10.3875 13.6282C10.6246 13.939 10.7472 14.2008 10.8617 14.6424L9.46375 14.9369Z"
              fill="white"
            />
            <path
              d="M13.0115 17.2651C13.0442 17.6004 13.0932 17.7559 13.2323 17.9276C13.453 18.2139 13.8372 18.3692 14.3196 18.3692C15.0308 18.3692 15.4805 18.042 15.4805 17.5185C15.4805 17.2649 15.3742 17.0604 15.1616 16.9296C14.9082 16.7661 14.6548 16.6842 13.7637 16.4798C13.0689 16.3161 12.7991 16.2098 12.4884 15.9889C12.0306 15.6454 11.7855 15.1464 11.7855 14.5411C11.7855 13.3387 12.7419 12.5781 14.2542 12.5781C15.227 12.5781 15.9627 12.8726 16.3879 13.4288C16.5923 13.7069 16.6985 13.9442 16.8047 14.4267L15.3659 14.5822C15.3251 14.3286 15.2679 14.2059 15.1533 14.0586C14.9652 13.8377 14.6383 13.7151 14.2459 13.7151C13.6083 13.7151 13.1833 14.0259 13.1833 14.4922C13.1833 14.7131 13.2813 14.893 13.4612 15.0239C13.6411 15.1384 13.8045 15.1958 14.2296 15.2939C15.1206 15.4821 15.513 15.5883 15.84 15.7355C16.1751 15.8827 16.4776 16.1362 16.6657 16.4471C16.8291 16.717 16.9109 17.0442 16.9109 17.4367C16.9109 18.7699 15.979 19.5307 14.3441 19.5307C13.2896 19.5307 12.4966 19.2362 12.0634 18.6882C11.7936 18.3528 11.6792 18.042 11.5894 17.404L13.0118 17.2649L13.0115 17.2651Z"
              fill="white"
            />
            <path
              d="M20.4668 17.6257C20.5403 17.8629 20.6058 18.0838 20.6303 18.1901C20.6794 18.0183 20.7366 17.8302 20.8019 17.6257L22.069 13.6014C22.208 13.168 22.2244 13.0861 22.2734 12.7344H23.9002C23.7939 12.9471 23.7039 13.1678 23.5078 13.7241L21.7665 18.5335C21.6195 18.926 21.5376 19.1878 21.5049 19.3842H19.7555C19.7146 19.1796 19.6329 18.9179 19.4938 18.5335L17.7526 13.7241C17.5808 13.2416 17.4664 12.9716 17.3521 12.7344H18.9788C19.0278 13.0534 19.085 13.2824 19.1832 13.6014L20.4666 17.6257H20.4668Z"
              fill="white"
            />
          </svg>
        </div>
        <Tabs
          tabs={["Events", "Dates"]}
          onClickTab={onClickTab}
          activeTab={activeTab}
        >
          <TabPanel selected={activeTab === 0}>
            <Events activeTab={activeTab} />
          </TabPanel>

          <TabPanel selected={activeTab === 1}>
            <Dates activeTab={activeTab} />
          </TabPanel>

          <TabPanel selected={activeTab === 2}>
            <></>
          </TabPanel>
        </Tabs>
      </div>
      <BottomBar showSearch={false} isButtonExists />
      {activeTab === 0 && (
        <div className="hide-print">
          <Print ref={componentRef} />
        </div>
      )}
      {activeTab === 1 && (
        <div className="hide-print">
          <DatesPrint ref={componentRef} />
        </div>
      )}
      {activeTab === 2 && (
        <div className="hide-print">
          <ItemPrint ref={componentRef} />
        </div>
      )}
    </>
  );
};

export default ReportTabs;
