import SortIcon from "components/common/sortIcon";
import { TableHead, TableHeading } from "components/common/table";
import { VendorContext } from "contexts/vendor";
import { useContext } from "react";

const LeadHeader = () => {
    const { setQueryParams, queryParams } = useContext(VendorContext);

    const onSort = (order: string, sort_by: string) => {
        setQueryParams({
            ...queryParams,
            sort_order: order,
            sort_by
        })
    }

    return (
        <TableHead>
            <TableHeading customClass="name">
            Vendor Name
            </TableHeading>
            <TableHeading customClass="venue">
                Venues
            </TableHeading>
            <TableHeading customClass="scheduled-events">
                <div 
                    onClick={() => onSort((queryParams.sort_order === 'DESC' || !queryParams.sort_order) ? 'ASC' : 'DESC', 'createdAt')} 
                    className="cursor-pointer" 
                    style={{display: 'flex', alignItems: 'flex-end', justifyContent: 'center'}}
                >
                    <div>
                        <p>Created</p>
                        <p>Date</p>
                    </div>
                    <SortIcon ascending={queryParams.sort_order === 'ASC'} />
                </div>
            </TableHeading>
            <TableHeading customClass="last-event">
                Type
            </TableHeading>
            <TableHeading customClass="status">
                Status
            </TableHeading>
            <TableHeading customClass="option">
                Options
            </TableHeading>
            <TableHeading customClass="accordian-dropdown">
            </TableHeading>
        </TableHead>
    )
};

export default LeadHeader;
