let googlePlacesService: google.maps.places.PlacesService | null = null;
const initPlacesService = () => {
    googlePlacesService = new google.maps.places.PlacesService(document.createElement('div'));
}
export const getGooglePlacesService = () => {
    if(!googlePlacesService){
        initPlacesService();
    }

    return googlePlacesService;
}

let googleAutocompleteService: google.maps.places.AutocompleteService | null = null;
const initAutocompleteService = () => {
    googleAutocompleteService = new google.maps.places.AutocompleteService();
}
export const getGoogleAutocompleteService = () => {
    if(!googleAutocompleteService){
        initAutocompleteService();
    }

    return googleAutocompleteService;
}


let googleGeocoderService: google.maps.Geocoder | null = null;
const initGeocoderService = () => {
    googleGeocoderService = new google.maps.Geocoder();
}
export const getGeocoderService = () => {
    if(!googleAutocompleteService){
        initGeocoderService();
    }

    return googleGeocoderService;
}

export const getAddressComponents = (addressObject: any) => {
    const data = {
        address: '',
        city: '',
        state: '',
        zipcode: '',
        latitude: 0,
        longitude: 0,
        county:''
    }

    for (let i = 0; i < addressObject.address_components.length; i++) {
        for (let j = 0; j < addressObject.address_components[i].types.length; j++) {
            if (addressObject.address_components[i].types[j] === "postal_code") {
                data.zipcode = addressObject.address_components[i].long_name;
            }
            if(addressObject.address_components[i].types[j] === "administrative_area_level_1"){                
                data.state = addressObject.address_components[i].short_name;
            }
            if(addressObject.address_components[i].types[j] === "locality"){
                data.city = addressObject.address_components[i].long_name;
            }
            if(addressObject.address_components[i].types[j] === "administrative_area_level_2"){
                data.county = addressObject.address_components[i].long_name;
            }
        }
    }

    return data;
}

export const setPlaceDetails = (placeService: any, place: any, setter: Function) => {
    const detailsOptions: google.maps.places.PlaceDetailsRequest = {
        placeId: place.place_id,
        fields: ['address_components','formatted_address']
    }

    // const placeService = getGooglePlacesService();
    if(placeService){
        placeService.getDetails(detailsOptions, (res: any, status: any) => {
            if(status === google.maps.places.PlacesServiceStatus.OK){
                setter(res);
            }
        })
    }
}

export const GetLatlong = (address: string, cb: Function) => {
    var geocoder = new google.maps.Geocoder();
    
    geocoder.geocode({
        'address': address
    }, function(results, status) {
        let latLong = null;

        if (status === google.maps.GeocoderStatus.OK) {
            const latitude = results[0].geometry.location.lat();
            const longitude = results[0].geometry.location.lng();

            latLong = {
                latitude,
                longitude
            }
            
        }

        cb(latLong);
    });
}