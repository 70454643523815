import React, { useContext } from "react";
import EventsHeader from "./EventsHeader";
import EventsBody from "./EventsBody";
import { SuperAdminReportContext } from "contexts/superAdminReport";

const EventsPrint = React.forwardRef((props: any, ref: any) => {
    
    const { eventList } = useContext(SuperAdminReportContext);
    const { events_data } = eventList;
    return (
        <div ref={ref} className="reports-main">
            <div className="superAdminEventsReports">
                <EventsHeader events_data={events_data} isPrint />
                <EventsBody isPrint /> 
            </div>
        </div>
    )
});

export default EventsPrint;
