import { useLocation, useNavigate } from "react-router-dom";
import { AnnouncementIcon, HierarchyIcon, ReportIcon, BookIcon, PaymentIcon } from "./menuIcons";
import Cookies from "js-cookie";
import InfoModal from "components/common/infoModal";
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "contexts/auth";
import ImpersonateIcon from "components/common/impersonationIcon";
import UserIcon from "./menuIcons/user";
import HomeIcon from "./menuIcons/home";
import { getAdminData } from "util/getAdminData";




const Sidebar = ({ openSidebar, openSearchbar }: any) => {
    const { user, setUser, onboardingDetails, setOnboardingDetails, setOpenSideBar } = useContext(AuthContext);
    const location = useLocation();
    const navigate = useNavigate();
    
    const [iconClicked, setIconClicked] = useState('');
    const {vendorDetailsEditing} = useContext(AuthContext);
    const { pathname } = location;
    const isDisabled = user?.onboarding_pending || onboardingDetails?.onboarding_pending || vendorDetailsEditing;

    const [showModal, setShowModal] = useState(false);

    const onClickExit = () => {
        setIconClicked('')
        setOnboardingDetails(null!);

        getAdminData((adminData: any) => {
            setUser(adminData);
            setShowModal(false);
            navigate("/");
        })

    }

    const onNavigate = (route: string) => {
        if(!isDisabled){
            const clikedIcon = getClickedIcon(route);
            setIconClicked(clikedIcon);
        }
        if(window.innerWidth > 600){
            if(isDisabled && route === '/promotional-resources' && onboardingDetails?.onboarding_step === 'SUBMIT_REVIEW'){
                navigate(route);
                return
            }
            else{
            (!isDisabled || route === '/profile') && navigate(route);
            return;
            }
        }
        
        navigate(isDisabled ? "/onboarding" : route);
    }

    const getClickedIcon = (path: string) => {
        let clickedIcon = '';
        switch(true){
            case path.includes('venues'):
            case path.includes('vendor'):
                clickedIcon = 'hierarchy';
                break;
            case path.includes('promotional-resources'):
                clickedIcon = 'announcement';
                break;
            case path.includes('reports'):
                clickedIcon = 'reports';
                break;
            case path.includes('guides'):
                clickedIcon = 'guides';
                break;
            case path.includes('users'):
                clickedIcon = 'users';
                break;
            case path.includes('payment'):
                clickedIcon = 'payments';
                break;
                case path.includes('dashboard'):
                case path ==='/':
                clickedIcon = 'dashboard';
                    break;
            default:
        }

        return clickedIcon;
    }

    useEffect(() => {
        if(location.state && 'active' in location.state){
            setIconClicked('');
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[location])

    useEffect(() => {
        const clickedIcon = getClickedIcon(pathname);
        setIconClicked(clickedIcon);
        
        /* eslint-disable react-hooks/exhaustive-deps */
    },[location.pathname])

    const isProfile = pathname === '/profile';

    const isImpersonate = Cookies.get("impersonation_token");
    const roleId = Cookies.get("role_id");
    const isSuperAdmin = roleId === '1';

    const isFadeOut = openSearchbar || !!(document.getElementById("app-modal"));

    const firstName = user?.first_name || Cookies.get("first_name");
    const lastName = user?.last_name || Cookies.get("last_name");
    const name = `${firstName} ${lastName}`


    
    return (
        <>
            <div className={`hide-on-print sideBar ${isSuperAdmin ? 'super-admin-sidebar' : 'vendor-sidebar'} ${openSidebar ? 'open' : isFadeOut ? 'fadeOut' : ''}`} style={vendorDetailsEditing ? {opacity: 0.4}: {}}>                <div>
                <div onClick={() => { 
                !vendorDetailsEditing && navigate(isDisabled ? "/onboarding": "/dashboard"); 
                 !vendorDetailsEditing && setIconClicked('dashboard')}} 
                 
                 className="cursor-pointer sideBar_logo">
                    <svg width="47" height="40" viewBox="0 0 47 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1658_92)">
                            <path d="M44.1098 8.33966L15.9498 14.6397C17.9798 16.1097 19.9098 17.7397 22.0898 18.9997C23.1498 19.6097 22.2498 21.2897 21.1798 20.6797C18.4298 19.0897 16.0898 16.9097 13.4098 15.2097L1.96983 17.7697C0.289827 18.1497 -0.0901728 20.3797 1.37983 21.2797L30.5098 39.2197C31.4898 39.8197 32.7698 39.4297 33.2498 38.3797L45.8898 10.5497C46.4298 9.35966 45.3798 8.05966 44.0998 8.34966L44.1098 8.33966Z" fill="#26CAD3" stroke="#231F20" strokeWidth="0.94" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M34.5899 18.0099L32.2699 18.1199L30.8499 6.57992L26.1299 5.45992L26.6199 3.16992L33.1199 4.66992L34.5899 18.0099Z" fill="#FD2B13" stroke="#231F20" strokeWidth="0.94" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M28.3999 9.16016L27.1699 15.1602L35.8299 11.5802L28.3999 9.16016Z" fill="#26CAD3" stroke="#231F20" strokeWidth="0.94" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M35.8301 11.5801L27.1701 15.1601L25.3301 24.2101L27.9401 25.0601L40.2901 13.0301L35.8301 11.5801Z" fill="#FD2B13" stroke="#231F20" strokeWidth="0.94" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M27.9399 25.0603L34.7599 27.2803L41.1199 13.3003L40.2899 13.0303L27.9399 25.0603Z" fill="#0075C9" stroke="#231F20" strokeWidth="0.94" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12.2254 1.91356L9.88443 3.33745C7.69031 4.67202 6.99351 7.53259 8.32808 9.72672L20.9872 30.5391C22.3218 32.7333 25.1823 33.4301 27.3765 32.0955L29.7174 30.6716C31.9116 29.337 32.6084 26.4764 31.2738 24.2823L18.6147 3.46991C17.2801 1.27579 14.4195 0.578986 12.2254 1.91356Z" fill="#FFC844" stroke="#231F20" strokeWidth="0.84" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M10.3266 0.716072L9.69433 1.10063C8.75534 1.67177 8.45714 2.89597 9.02828 3.83496L26.5515 32.6443C27.1226 33.5832 28.3468 33.8814 29.2858 33.3103L29.9181 32.9258C30.857 32.3546 31.1552 31.1304 30.5841 30.1914L13.0609 1.38212C12.4898 0.443134 11.2656 0.144932 10.3266 0.716072Z" fill="#FD2B13" stroke="#231F20" strokeWidth="0.84" strokeLinecap="round" strokeLinejoin="round" />
                            <path d="M12.3299 7.13C12.0999 6.43 12.2299 5.83 12.3399 5.28C12.4799 4.65 12.5799 4.15 12.1699 3.48L12.9499 3C13.5499 3.98 13.3799 4.77 13.2299 5.47C13.0899 6.1 12.9899 6.6 13.3999 7.27C13.8099 7.94 14.2999 8.08 14.9199 8.24C15.6099 8.43 16.3799 8.64 16.9799 9.62C17.5799 10.6 17.4099 11.39 17.2599 12.09C17.1199 12.72 17.0199 13.22 17.4299 13.89C17.8399 14.56 18.3299 14.7 18.9499 14.86C19.6399 15.05 20.4099 15.26 21.0099 16.24C21.6099 17.22 21.4399 18.01 21.2899 18.71C21.1499 19.34 21.0499 19.84 21.4599 20.51C21.8699 21.18 22.3599 21.32 22.9799 21.48C23.6699 21.67 24.4499 21.88 25.0399 22.86C25.6399 23.84 25.4699 24.63 25.3199 25.33C25.1799 25.96 25.0799 26.46 25.4899 27.13C25.8999 27.8 26.3899 27.94 27.0099 28.11C27.6999 28.3 28.4799 28.51 29.0699 29.49L28.2899 29.97C27.8799 29.3 27.3899 29.16 26.7699 28.99C26.0799 28.8 25.2999 28.59 24.7099 27.61C24.1099 26.62 24.2799 25.84 24.4299 25.14C24.5699 24.51 24.6699 24.01 24.2599 23.34C23.8499 22.67 23.3599 22.53 22.7399 22.37C22.0499 22.18 21.2799 21.97 20.6799 20.99C20.0799 20.01 20.2499 19.22 20.3999 18.52C20.5399 17.89 20.6399 17.39 20.2299 16.72C19.8199 16.05 19.3299 15.91 18.7099 15.75C18.0199 15.56 17.2499 15.35 16.6499 14.37C16.0499 13.39 16.2199 12.6 16.3699 11.9C16.5099 11.27 16.6099 10.77 16.1999 10.1C15.7899 9.43 15.2999 9.29 14.6799 9.13C13.9899 8.94 13.2199 8.73 12.6199 7.75C12.4899 7.53 12.3899 7.33 12.3299 7.13Z" fill="#231F20" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1658_92">
                                <rect width="46.51" height="39.96" fill="white" />
                            </clipPath>
                        </defs>
                    </svg>
                </div>
                <div className="sideBar_menu-list">
                    <div className={`slide-bg  ${iconClicked==="dashboard"? "home-tab-active" : iconClicked==="hierarchy"? "venues-tab-active": iconClicked==="announcement"? "promotional-tab-active": iconClicked==="reports" ? "reports-tab-active": iconClicked==="users"? "user-tab-active": iconClicked==="guides" ? "guid-tab-active": iconClicked==="payments" ? "billing-tab-active":"" }`}></div>
                   
                   
                    <div onClick={() => {onNavigate('/dashboard')}} className={`${pathname === '/dashboard' || pathname === '/' ? 'active-sidebar-tab' : ''} sideBar_menu-list_list-item${isDisabled ? '-disabled' : ''} ${iconClicked==="users"? "userActive-tab":""}`}>
                        <a href="#/" className="sidebar-home">
                            <HomeIcon />
                            <span>Dashboard</span>
                        </a>
                        <div className={`sideBar-links-mob user`}>
                            <div>
                                <HomeIcon />
                            </div>
                            <p className="link-text" style={{ marginTop: "2px" }}>Dashboard</p>
                        </div>
                    </div>
                   
                    <div onClick={() => {onNavigate(isSuperAdmin ? '/vendors' : '/venues')}} className={`${pathname?.includes(isSuperAdmin ? '/vendors' : '/venues') ? 'active-sidebar-tab' : ''} sideBar_menu-list_list-item${isDisabled ? '-disabled' : ''} ${iconClicked==="hierarchy"? "venueActive-tab":""}`}>
                        <a href="#/" className="sidebar-venue">
                            <HierarchyIcon />
                            <span>{isSuperAdmin ? 'Vendors' : 'Venues'}</span>
                        </a>
                        <div className={`sideBar-links-mob sidebar-venues-mob`}>
                            <div>
                                <HierarchyIcon  isDisabled = {isDisabled}/>
                            </div>
                            <p className="link-text">{isSuperAdmin ? 'Vendors' : 'Venues'}</p>
                        </div>
                    </div>
                    {!isSuperAdmin && (
                        <div onClick={() => {onNavigate('/promotional-resources')}} className={`${pathname === '/promotional-resources' ? 'active-sidebar-tab' : ''} sideBar_menu-list_list-item${isDisabled && onboardingDetails?.onboarding_step !=="SUBMIT_REVIEW" ? '-disabled' : ''} ${iconClicked==="announcement"? "promotionalActive-tab":""}`}>
                            <a href="#/" className="sidebar-announcment">
                                <AnnouncementIcon />
                                <span>Promotional Resources</span>
                            </a>
                            <div className={`sideBar-links-mob sidebar-announcment-mob`}>
                                <div>
                                    <AnnouncementIcon isDisabled = {isDisabled}/>
                                </div>
                                <p className="link-text">Promotional Resources</p>
                            </div>
                        </div>
                    )}

                    <div onClick={() => {onNavigate('/reports')}} className={`${pathname.includes("reports") ? 'active-sidebar-tab' : ''} sideBar_menu-list_list-item${isDisabled  ? '-disabled' : ''} ${iconClicked==="reports"? "reportsActive-tab":""}`}>
                        <a href="#/" className="sidebar-reports">
                            <ReportIcon />
                            <span>Reports</span>
                        </a>
                        <div className={`sideBar-links-mob`}>
                            <div className="report-svg-mob">
                                <ReportIcon isDisabled = {isDisabled}/>
                            </div>
                            <p className="link-text" style={{marginTop: "3px"}}>Reports</p>
                        </div>
                    </div>

                    {!isSuperAdmin && (
                        <div onClick={() => {onNavigate('/guides')}} className={`${pathname === '/guides' ? 'active-sidebar-tab' : ''} sideBar_menu-list_list-item${isDisabled  ? '-disabled' : ''} ${iconClicked==="guides"? "guidActive-tab":""}`}>
                            <a href="#/" className="sidebar-guid">
                                <BookIcon />
                                <span>Guides</span>
                            </a>
                            <div className={`sideBar-links-mob sidebar-guid-mob`}>
                                <div>
                                    <BookIcon isDisabled = {isDisabled}/>
                                </div>
                                <p className="link-text">Videos and Guides</p>
                            </div>
                        </div>
                    )}

                    <div onClick={() => {onNavigate('/users')}} className={`${pathname.includes("users") ? 'active-sidebar-tab' : ''} sideBar_menu-list_list-item${isDisabled ? '-disabled' : ''} ${iconClicked==="users"? "userActive-tab":""}`}>
                        <a href="#/" className="sidebar-user">
                            <UserIcon />
                            <span>Users</span>
                        </a>
                        <div className={`sideBar-links-mob user`}>
                            <div>
                                <UserIcon isDisabled = {isDisabled}/>
                            </div>
                            <p className="link-text" style={{ marginTop: "2px" }}>Users</p>
                        </div>
                    </div>

                    
                    {!isSuperAdmin && (
                        <div onClick={() => {onNavigate('/payment')}} className={`${pathname === '/payment' ? 'active-sidebar-tab' : ''} sideBar_menu-list_list-item${isDisabled ? '-disabled' : ''} ${iconClicked==="payments"? "billingActive-tab":""}`}>
                            <a href="#/" className="sidebar-billing">
                                <PaymentIcon />
                                <span>Payment</span>
                            </a>
                            <div className={`sideBar-links-mob sidebar-billing-mob`}>
                                <div>
                                    <PaymentIcon isDisabled = {isDisabled}/>
                                </div>
                                <p className="link-text">Billing</p>
                            </div>
                        </div>
                    )}

                </div>
            </div>
                <div className="sidebar-bottom">
                    {isImpersonate && (
                        <div className="btn-tooltip login-as">
                            <ImpersonateIcon 
                                onClick={() => {
                                    setShowModal(true);
                                    setOpenSideBar(false);
                                }} 
                            />
                            <span className="user-name-tooltip">{name}</span>
                        </div>
                    )}
                    <div className="cursor-pointer logOut-btn btn-tooltip">
                        <div onClick={() => {!vendorDetailsEditing && navigate('/profile'); !vendorDetailsEditing && setIconClicked('')}} >
                            <svg className="logOut-user active" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                                <circle cx="21" cy="21" r="21" fill={isProfile ? '#0075C9' : "#222222"} />
                                <mask id="mask0_4007_3841" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="11" y="7" width="19" height="28">
                                    <path d="M29.6609 7.7793H11.667V34.216H29.6609V7.7793Z" fill="white" />
                                </mask>
                                <g mask="url(#mask0_4007_3841)">
                                    <path d="M29.6619 21.1938V24.791C29.662 25.1028 29.6006 25.4116 29.4813 25.6997C29.362 25.9878 29.1871 26.2496 28.9665 26.47C28.746 26.6905 28.4842 26.8653 28.1961 26.9845C27.9079 27.1037 27.5991 27.165 27.2873 27.1648C26.9063 27.1568 26.5304 27.0749 26.1805 26.9237V33.0735C25.6464 33.3055 25.0977 33.5022 24.5379 33.6623C23.2803 34.0329 21.9756 34.2189 20.6645 34.2146C19.3509 34.2122 18.044 34.0263 16.7819 33.6623C16.2257 33.5036 15.6802 33.3098 15.1485 33.0821V26.9237C14.7987 27.0749 14.4228 27.1568 14.0417 27.1648C13.7299 27.1649 13.4211 27.1035 13.133 26.9842C12.8449 26.8649 12.5832 26.69 12.3627 26.4694C12.1423 26.2489 11.9674 25.9871 11.8482 25.699C11.729 25.4108 11.6678 25.1021 11.668 24.7902V21.193C11.6694 20.2891 12.0291 19.4226 12.6683 18.7835C13.3074 18.1443 14.1739 17.7846 15.0777 17.7832H16.5143C17.6894 18.736 19.1563 19.256 20.6692 19.256C22.1821 19.256 23.649 18.736 24.8241 17.7832H26.2614C27.1639 17.7865 28.0283 18.1471 28.6656 18.7861C29.3028 19.4251 29.661 20.2913 29.6619 21.1938Z" fill="#E94929" />
                                    <path d="M25.5204 12.6245C25.5211 13.5839 25.2374 14.5219 24.705 15.3201C24.1727 16.1183 23.4156 16.7406 22.5295 17.1085C21.6435 17.4764 20.6682 17.5733 19.7271 17.3869C18.786 17.2006 17.9212 16.7393 17.2422 16.0615C16.5632 15.3837 16.1004 14.5198 15.9124 13.579C15.7244 12.6382 15.8196 11.6628 16.1859 10.7761C16.5522 9.88937 17.1733 9.13119 17.9705 8.59742C18.7677 8.06366 19.7053 7.77827 20.6647 7.77734C21.9511 7.77652 23.1853 8.28655 24.0958 9.19533C25.0063 10.1041 25.5187 11.338 25.5204 12.6245Z" fill="#FDC13D" />
                                </g>
                            </svg>
                            <svg className="logOut-user-mob" xmlns="http://www.w3.org/2000/svg" width="42" height="42" viewBox="0 0 42 42" fill="none">
                                <circle cx="21" cy="21" r="21" fill={isProfile ? '#0075C9' : "#222222"} />
                                <mask id="mask0_4007_3841" style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="11" y="7" width="19" height="28">
                                    <path d="M29.6609 7.7793H11.667V34.216H29.6609V7.7793Z" fill="white" />
                                </mask>
                                <g mask="url(#mask0_4007_3841)">
                                    <path d="M29.6619 21.1938V24.791C29.662 25.1028 29.6006 25.4116 29.4813 25.6997C29.362 25.9878 29.1871 26.2496 28.9665 26.47C28.746 26.6905 28.4842 26.8653 28.1961 26.9845C27.9079 27.1037 27.5991 27.165 27.2873 27.1648C26.9063 27.1568 26.5304 27.0749 26.1805 26.9237V33.0735C25.6464 33.3055 25.0977 33.5022 24.5379 33.6623C23.2803 34.0329 21.9756 34.2189 20.6645 34.2146C19.3509 34.2122 18.044 34.0263 16.7819 33.6623C16.2257 33.5036 15.6802 33.3098 15.1485 33.0821V26.9237C14.7987 27.0749 14.4228 27.1568 14.0417 27.1648C13.7299 27.1649 13.4211 27.1035 13.133 26.9842C12.8449 26.8649 12.5832 26.69 12.3627 26.4694C12.1423 26.2489 11.9674 25.9871 11.8482 25.699C11.729 25.4108 11.6678 25.1021 11.668 24.7902V21.193C11.6694 20.2891 12.0291 19.4226 12.6683 18.7835C13.3074 18.1443 14.1739 17.7846 15.0777 17.7832H16.5143C17.6894 18.736 19.1563 19.256 20.6692 19.256C22.1821 19.256 23.649 18.736 24.8241 17.7832H26.2614C27.1639 17.7865 28.0283 18.1471 28.6656 18.7861C29.3028 19.4251 29.661 20.2913 29.6619 21.1938Z" fill="#E94929" />
                                    <path d="M25.5204 12.6245C25.5211 13.5839 25.2374 14.5219 24.705 15.3201C24.1727 16.1183 23.4156 16.7406 22.5295 17.1085C21.6435 17.4764 20.6682 17.5733 19.7271 17.3869C18.786 17.2006 17.9212 16.7393 17.2422 16.0615C16.5632 15.3837 16.1004 14.5198 15.9124 13.579C15.7244 12.6382 15.8196 11.6628 16.1859 10.7761C16.5522 9.88937 17.1733 9.13119 17.9705 8.59742C18.7677 8.06366 19.7053 7.77827 20.6647 7.77734C21.9511 7.77652 23.1853 8.28655 24.0958 9.19533C25.0063 10.1041 25.5187 11.338 25.5204 12.6245Z" fill="#FDC13D" />
                                </g>
                            </svg>
                        </div>
                        <span className="user-name-tooltip">{isImpersonate ? Cookies.get("fullName") : name}</span>
                    </div>
                </div>
            </div>
            {showModal && (
                <InfoModal
                    onSubmit={onClickExit}
                    submitButtonName="Yes"
                    closeModal={() => setShowModal(false)}
                    title={`Exit from ${name || ''}`}
                    description={`You want to exit from${name ? ` ${name}'s ` : ' '}account? You will be returned to your account.`}
                />
            )}
        </>
    )
}

export default Sidebar;