import { ISuperAdminReportContext } from "interfaces/superAdminReport"

export enum SuperAdminReportActions {
    SET_REPORT_LOADING = 'SET_REPORT_LOADING',
    RESET_REPORT_LOADING = 'RESET_REPORT_LOADING',
    GET_EVENTS = 'GET_EVENTS',
    GET_EVENT_ITEMS = 'GET_EVENT_ITEMS',
    GET_EVENT_SALES = 'GET_EVENT_SALES',
    GET_VENUES = 'GET_VENUES',
    GET_ALL_EVENTS = 'GET_ALL_EVENTS',
    SET_SELECTED_VENUE = 'SET_SELECTED_VENUE',
    GET_EVENT_DETAILS = 'GET_EVENT_DETAILS',
    SET_SELECTED_EVENT = 'SET_SELECTED_EVENT',
    SET_ITEMS_SELECTED_EVENT = 'SET_ITEMS_SELECTED_EVENT',
    SET_EVENT_QUERY_PARAMS = 'SET_EVENT_QUERY_PARAMS',
    GET_EVENT_REPORT_DOWNLOAD = 'GET_EVENT_REPORT_DOWNLOAD', 
    GET_ORDERS = 'GET_ORDERS',
    SET_DATES_QUERY_PARAMS = 'SET_DATES_QUERY_PARAMS'

}


export const SuperAdminReportReducer = (state: ISuperAdminReportContext, action: any) => {
    const { type, payload } = action
    switch (type) {
        case SuperAdminReportActions.SET_REPORT_LOADING:
            
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [payload]: true
                }
            }
        case SuperAdminReportActions.RESET_REPORT_LOADING:
            const newLoadingState = {
                ...state.loading
            }
            delete newLoadingState[payload];
            return {
                ...state,
                loading: newLoadingState
            }
        case SuperAdminReportActions.SET_EVENT_QUERY_PARAMS:
            return {
                ...state,
                eventQueryParams: payload
            }

        case SuperAdminReportActions.SET_DATES_QUERY_PARAMS:
            return {
                ...state,
                datesQueryParams: payload
            }


        case SuperAdminReportActions.GET_EVENTS:
            const {offset, ...reqPayload} = payload;
            return {
                ...state,
                eventList: offset === 0 
                ? reqPayload 
                : {
                    ...state.eventList,
                    events: [...state.eventList.events, ...reqPayload.events]
                }
            }
        case SuperAdminReportActions.GET_EVENT_ITEMS:
            return {
                ...state,
                eventItems: payload
            }
        case SuperAdminReportActions.GET_VENUES:
            return {
                ...state,
                venueList: payload
            }
        case SuperAdminReportActions.GET_ALL_EVENTS:
            return {
                ...state,
                allEvents: payload
            }
        case SuperAdminReportActions.GET_EVENT_SALES:
            return {
                ...state,
                eventDetails: {
                    ...payload,
                    event_sales: {
                        ...payload?.event_sales,
                        ...(payload?.event_sales?.gross_sales !== undefined && {gross_sales: payload.event_sales.gross_sales.toString()}),
                        ...(payload?.event_sales?.refund_fees !== undefined && {refund_fees: payload.event_sales.refund_fees.toString()}),
                        ...(payload?.event_sales?.refunds !== undefined && {refunds: payload.event_sales.refunds.toString()}),
                        ...(payload?.event_sales?.total_service_fee !== undefined && {total_service_fee: payload.event_sales.total_service_fee.toString()}),
                        ...(payload?.event_sales?.total_tax !== undefined && {total_tax: payload.event_sales.total_tax.toString()}),
                        ...(payload?.event_sales?.total_tips !== undefined && {total_tips: payload.event_sales.total_tips.toString()}),
                        ...(payload?.event_sales?.average_order_total !== undefined && {average_order_total: payload.event_sales.average_order_total.toString()}),
                        ...(payload?.event_sales?.total_orders !== undefined && {total_orders: payload.event_sales.total_orders.toString()})
                    }
                }
            }
        case SuperAdminReportActions.SET_SELECTED_VENUE:
            return {
                ...state,
                selectedVenue: payload
            }
        case SuperAdminReportActions.GET_EVENT_DETAILS:
            return {
                ...state,
                reportDates: {
                    ...payload,
                    events_sales: {
                        ...payload?.events_sales,
                        ...(payload?.events_sales?.gross_sales !== undefined && {gross_sales: payload.events_sales.gross_sales.toString()}),
                        ...(payload?.events_sales?.refund_fees !== undefined && {refund_fees: payload.events_sales.refund_fees.toString()}),
                        ...(payload?.events_sales?.refunds !== undefined && {refunds: payload.events_sales.refunds.toString()}),
                        ...(payload?.events_sales?.total_service_fee !== undefined && {total_service_fee: payload.events_sales.total_service_fee.toString()}),
                        ...(payload?.events_sales?.total_tax !== undefined && {total_tax: payload.events_sales.total_tax.toString()}),
                        ...(payload?.events_sales?.total_tips !== undefined && {total_tips: payload.events_sales.total_tips.toString()}),
                        ...(payload?.events_sales?.delivery_fee !== undefined && {delivery_fee: payload.events_sales.delivery_fee.toString()}),
                        ...(payload?.event_sales?.average_order_total !== undefined && {average_order_total: payload.event_sales.average_order_total.toString()}),
                        ...(payload?.event_sales?.total_orders !== undefined && {total_orders: payload.event_sales.total_orders.toString()})
                    }
                }
            }
        case SuperAdminReportActions.SET_SELECTED_EVENT:
            return {
                ...state,
                selectedEvent: payload
            }
        case SuperAdminReportActions.SET_ITEMS_SELECTED_EVENT:
            return {
                ...state,
                itemsSelectedEvent: payload
            }
            //from here onwards 
            case SuperAdminReportActions.GET_ORDERS: 
            return {
                ...state,
                allOrders: {_count:payload?.count , orders: payload?.orders }
            }
        default:
            return state
    }
}