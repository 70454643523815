import Admins from "components/admins";
import { TabPanel, Tabs } from "components/common/tabs";
import VendorDetails from "components/vendorDetails";
import Venues from "components/venues";
import { VendorActions } from "contexts/reducers/vendor";
import { VendorContext } from "contexts/vendor";
import { useContext, useState } from "react";
import { useParams } from "react-router";
import NotesModal from "./notesModal";
import NotesDetailsModal from "./notesDetailsModal";
import { AuthContext } from "contexts/auth";

const VendorTabs = ({ setActiveTab, activeTab, }: any) => {
    const { fetchVenues, venueList, deleteVenue, vendorDetails, fetchAdmins, adminList, deleteAdmin, addAdmin, loading, updateAdmin, addVenue,venueQueryParams,setVenueQueryParams, venuePage, setVenuePage, adminsPage, setAdminsPage, adminQueryParam, setAdminQeuryParam  } = useContext(VendorContext);
    const {vendorDetailsEditing} = useContext(AuthContext);
    
   // const { setActiveTab:setActiveTabIndx } = useContext(VendorContext);
    const {vendor} = vendorDetails || {};
    const disabledTabsIndices = [...(vendor?.show_venues ? [] : [1]), ...(vendor?.show_admins ? [] : [2]), ...(vendorDetailsEditing ? [0,1,2]:[])];

    const { id } = useParams();
    const vendorId = id ? parseInt(id, 10) : null;
    
    const [selectedNote, setSelectedNote] = useState<any>(null);
    const [showModal, setShowModal] = useState(false);

    const onClickNotes = () => {
        setShowModal(true);
    }

    const vendorsLoading = loading[VendorActions.GET_VENDOR_DETAILS];
    return (
        <>
            <Tabs
                tabs={['Details', 'Venues', 'Admins']}
                activeTab={activeTab}
                onClickTab={(ind: number) => {            
                    setActiveTab(ind);
                   // setActiveTabIndx(ind);
                }}
                disbaledIndex={disabledTabsIndices}
                isVendorDetails={true}
                status={vendorsLoading ? null : vendor?.status}
                onClickNotes={onClickNotes}
                disabledNotes={vendorsLoading}
                notesCount={vendor?.notes?.length}
                isNotesActive={showModal}
            >
                <TabPanel
                    selected={activeTab === 0}
                >
                    <VendorDetails />
                </TabPanel>
                <TabPanel
                    selected={activeTab === 1}
                >
                    <Venues 
                        id={id}
                        fetchVenues={fetchVenues}
                        venueList= {venueList} 
                        deleteVenue = {deleteVenue}
                        loading = {loading}
                        addVenue = {addVenue}
                        setBackActiveTab = {setActiveTab} 
                        queryParams = {venueQueryParams}
                        setQueryParams = {setVenueQueryParams}
                        page = {venuePage}
                        setPage = {setVenuePage}


                    />
                </TabPanel>
                <TabPanel
                    selected={activeTab === 2}
                >
                    <Admins
                        vendorId={vendorId}
                        fetchAdmins={fetchAdmins}
                        adminList={adminList}
                        deleteAdmin={deleteAdmin}
                        addAdmin={addAdmin}
                        loading={loading[VendorActions.ADD_ADMIN]}
                        updateAdmin={updateAdmin}
                        isDeleteLoading={loading[VendorActions.DELETE_ADMIN]}
                        isAdminsLoading={loading[VendorActions.GET_ADMINS]}
                        isEditAdminLoading={loading[VendorActions.EDIT_ADMIN]}
                        IsFetchingMore={loading[VendorActions.GET_MORE_ADMINS]}
                        page = {adminsPage}
                        setPage = {setAdminsPage}
                        queryParams = {adminQueryParam}
                        setQueryParams = {setAdminQeuryParam}
                        
                        
                    />
                </TabPanel>
            </Tabs>

            {showModal && !selectedNote && (
                <NotesModal 
                    closeModal={() => setShowModal(false)}
                    setSelectedNote={setSelectedNote}
                    selectedNote={selectedNote}
                />
            )}

            {selectedNote && (
                <NotesDetailsModal
                    data={selectedNote}
                    closeModal={() => setSelectedNote(null!)}
                />
            )}
        </>
    )
};

export default VendorTabs;
