import { FC } from "react";

interface Props  {
    isDisabled? : boolean,
}



const AnnouncementIcon: FC<Props> = ({isDisabled}) => {
    return (
        <>
            {/* { !isDisabled && <svg className="active-tab-svg" width="40" height="34" viewBox="0 0 40 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.4" clipPath="url(#clip0_2816_328)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M38.4869 15.9011C38.7162 15.4368 38.9651 14.9821 39.0676 14.6566C39.5118 13.2684 37.8573 9.41984 36.5102 8.7832C36.2564 8.66353 35.8269 8.58216 35.3975 8.49121C36.0466 9.8698 37.0471 12.1722 38.4869 15.9011Z" fill="#696969"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.1312 29.0697C20.0092 28.0119 19.9799 26.9444 19.897 25.5132C20.9414 25.0536 21.3319 24.8718 22.4105 24.4792C20.8292 18.5341 18.6329 15.0732 17.3591 13.3691C13.9915 14.7908 11.3365 15.8535 7.95425 17.2464C4.83558 18.5293 3.87899 20.7312 5.12841 23.7469C5.49933 24.642 5.86049 25.5419 6.24606 26.4275C6.95374 28.0693 9.34033 29.2134 11.1217 29.041C11.8392 28.9692 12.8006 29.3282 13.3326 29.8213C14.4942 30.9031 15.3873 32.253 16.5147 33.3826C17.5153 34.3831 19.8726 34.1342 21.1317 32.9566C22.3372 31.8269 21.2586 31.0036 20.6437 30.1181C20.4192 29.8021 20.1752 29.4383 20.1312 29.0745V29.0697Z" fill="#696969"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M39.8973 23.4838C39.2433 21.5212 38.2721 19.664 37.5986 17.7062C37.5791 17.6439 37.5693 17.5721 37.5644 17.4956C35.4951 12.0482 34.2505 9.35326 33.6405 8.14221C33.6209 8.11827 33.6014 8.08955 33.5916 8.06562C33.5624 8.00339 33.5331 7.94116 33.5038 7.87415C33.2793 7.44812 33.1768 7.28059 33.1719 7.2758L33.2158 7.24708C32.4106 5.43289 31.7273 3.57084 30.8634 1.78537C30.6487 1.34499 29.7067 0.895035 29.2626 1.0147C28.8185 1.13916 28.4622 1.93376 28.2718 2.50339C28.0229 3.24534 28.1401 4.12132 27.8131 4.8154C26.3001 8.06083 23.9135 10.5547 20.5801 12.0147C20.0481 12.2493 20.0774 12.2349 19.5454 12.4647C20.8876 14.2932 23.1375 17.8115 24.6993 23.6753C28.3646 22.4547 31.4784 22.1674 35.6073 23.7423C36.2906 24.0008 36.8567 24.5513 37.5205 24.872C38.1696 25.1879 39.1018 25.9442 39.5557 25.6331C40.0145 25.3219 40.1023 24.0965 39.8924 23.4838H39.8973Z" fill="#696969"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M5.29444 27.6195C5.29444 27.6195 3.10795 23.4023 3.26413 21.9424C3.26413 21.9424 1.39976 22.5455 2.20017 25.3697C3.00546 28.1939 5.00649 27.6291 5.29444 27.6195Z" fill="#696969"/>
                </g>
                <g clipPath="url(#clip1_2816_328)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M36.4869 14.9011C36.7162 14.4368 36.9651 13.9821 37.0676 13.6566C37.5118 12.2684 35.8573 8.41984 34.5102 7.7832C34.2564 7.66353 33.8269 7.58216 33.3975 7.49121C34.0466 8.8698 35.0471 11.1722 36.4869 14.9011Z" fill="#4285F4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M18.1312 28.0697C18.0092 27.0119 17.9799 25.9444 17.897 24.5132C18.9414 24.0536 19.3319 23.8718 20.4105 23.4792C18.8292 17.5341 16.6329 14.0732 15.3591 12.3691C11.9915 13.7908 9.33648 14.8535 5.95425 16.2464C2.83558 17.5293 1.87899 19.7312 3.12841 22.7469C3.49933 23.642 3.86049 24.5419 4.24606 25.4275C4.95374 27.0693 7.34033 28.2134 9.12173 28.041C9.83917 27.9692 10.8006 28.3282 11.3326 28.8213C12.4942 29.9031 13.3873 31.253 14.5147 32.3826C15.5153 33.3831 17.8726 33.1342 19.1317 31.9566C20.3372 30.8269 19.2586 30.0036 18.6437 29.1181C18.4192 28.8021 18.1752 28.4383 18.1312 28.0745V28.0697Z" fill="#26CAD3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M37.8973 22.4838C37.2433 20.5212 36.2721 18.664 35.5986 16.7062C35.5791 16.6439 35.5693 16.5721 35.5644 16.4956C33.4951 11.0482 32.2505 8.35326 31.6405 7.14221C31.6209 7.11827 31.6014 7.08955 31.5916 7.06562C31.5624 7.00339 31.5331 6.94116 31.5038 6.87415C31.2793 6.44812 31.1768 6.28059 31.1719 6.2758L31.2158 6.24708C30.4106 4.43289 29.7273 2.57084 28.8634 0.785374C28.6487 0.344991 27.7067 -0.104965 27.2626 0.0147043C26.8185 0.13916 26.4622 0.933764 26.2718 1.50339C26.0229 2.24534 26.1401 3.12132 25.8131 3.8154C24.3001 7.06083 21.9135 9.55474 18.5801 11.0147C18.0481 11.2493 18.0774 11.2349 17.5454 11.4647C18.8876 13.2932 21.1375 16.8115 22.6993 22.6753C26.3646 21.4547 29.4784 21.1674 33.6073 22.7423C34.2906 23.0008 34.8567 23.5513 35.5205 23.872C36.1696 24.1879 37.1018 24.9442 37.5557 24.6331C38.0145 24.3219 38.1023 23.0965 37.8924 22.4838H37.8973Z" fill="#4285F4"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.29444 26.6195C3.29444 26.6195 1.10795 22.4023 1.26413 20.9424C1.26413 20.9424 -0.600244 21.5455 0.200167 24.3697C1.00546 27.1939 3.00649 26.6291 3.29444 26.6195Z" fill="#26CAD3"/>
                </g>
                <defs>
                <clipPath id="clip0_2816_328">
                <rect width="38" height="33" fill="white" transform="translate(2 1)"/>
                </clipPath>
                <clipPath id="clip1_2816_328">
                <rect width="38" height="33" fill="white"/>
                </clipPath>
                </defs>
            </svg>} */}
            <svg className="non-active-svg" width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.4" clip-path="url(#clip0_8610_526)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M28.5003 11.7872C28.6742 11.443 28.8628 11.106 28.9405 10.8647C29.2772 9.83579 28.023 6.98323 27.0018 6.51135C26.8094 6.42265 26.4838 6.36233 26.1582 6.29492C26.6503 7.31674 27.4088 9.02331 28.5003 11.7872Z" fill="#484545"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M14.5853 21.5475C14.4928 20.7634 14.4706 19.9722 14.4077 18.9113C15.1995 18.5707 15.4955 18.4359 16.3131 18.145C15.1144 13.7384 13.4494 11.1732 12.4837 9.91016C9.93077 10.9639 7.91801 11.7515 5.35395 12.784C2.9897 13.7349 2.26451 15.3669 3.2117 17.6021C3.49289 18.2656 3.76669 18.9326 4.05898 19.589C4.59547 20.806 6.40473 21.6539 7.75521 21.5262C8.2991 21.473 9.02798 21.7391 9.43128 22.1045C10.3119 22.9063 10.9889 23.9069 11.8436 24.7442C12.6021 25.4857 14.3892 25.3012 15.3438 24.4284C16.2576 23.5911 15.44 22.9809 14.9738 22.3245C14.8036 22.0903 14.6186 21.8207 14.5853 21.551V21.5475Z" fill="#222222"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M29.5693 17.4069C29.0735 15.9523 28.3373 14.5757 27.8267 13.1245C27.8119 13.0784 27.8045 13.0252 27.8008 12.9684C26.232 8.93086 25.2885 6.93335 24.826 6.03572C24.8112 6.01798 24.7964 5.99669 24.789 5.97895C24.7668 5.93283 24.7446 5.8867 24.7224 5.83703C24.5522 5.52126 24.4745 5.39708 24.4708 5.39354L24.5041 5.37225C23.8936 4.02757 23.3756 2.64741 22.7208 1.32402C22.558 0.997609 21.8439 0.6641 21.5072 0.752799C21.1705 0.845046 20.9004 1.43401 20.7561 1.85622C20.5674 2.40615 20.6562 3.05543 20.4083 3.56988C19.2613 5.9754 17.4521 7.82389 14.925 8.90602C14.5217 9.07987 14.5439 9.06923 14.1406 9.23953C15.1581 10.5949 16.8638 13.2026 18.0477 17.5489C20.8264 16.6441 23.1869 16.4313 26.3171 17.5985C26.8351 17.7901 27.2643 18.1981 27.7675 18.4359C28.2596 18.67 28.9662 19.2306 29.3103 19C29.6581 18.7694 29.7247 17.8611 29.5656 17.4069H29.5693Z" fill="#222222"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.33697 20.4716C3.33697 20.4716 1.67941 17.3458 1.7978 16.2637C1.7978 16.2637 0.384431 16.7107 0.99122 18.804C1.60171 20.8973 3.11868 20.4787 3.33697 20.4716Z" fill="#484545"/>
                </g>
                <g clip-path="url(#clip1_8610_526)">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.6604 11.045C27.8343 10.7008 28.023 10.3638 28.1007 10.1225C28.4374 9.0936 27.1831 6.24104 26.1619 5.76916C25.9695 5.68046 25.644 5.62015 25.3184 5.55273C25.8104 6.57455 26.5689 8.28112 27.6604 11.045Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7454 20.8053C13.6529 20.0212 13.6307 19.23 13.5678 18.1692C14.3596 17.8285 14.6556 17.6937 15.4733 17.4028C14.2745 12.9962 12.6096 10.431 11.6439 9.16797C9.09092 10.2217 7.07816 11.0094 4.51411 12.0418C2.14986 12.9927 1.42467 14.6247 2.37185 16.86C2.65305 17.5234 2.92684 18.1904 3.21914 18.8468C3.75563 20.0638 5.56489 20.9117 6.91537 20.784C7.45925 20.7308 8.18814 20.9969 8.59143 21.3623C9.47202 22.1642 10.1491 23.1647 11.0038 24.002C11.7623 24.7435 13.5493 24.559 14.5039 23.6862C15.4178 22.8489 14.6001 22.2387 14.1339 21.5823C13.9637 21.3481 13.7787 21.0785 13.7454 20.8088V20.8053Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M28.7295 16.6648C28.2337 15.2101 27.4974 13.8335 26.9868 12.3824C26.972 12.3362 26.9646 12.283 26.9609 12.2263C25.3921 8.18867 24.4487 6.19117 23.9862 5.29353C23.9714 5.27579 23.9566 5.2545 23.9492 5.23676C23.927 5.19064 23.9048 5.14452 23.8826 5.09484C23.7124 4.77908 23.6347 4.6549 23.631 4.65135L23.6643 4.63006C23.0538 3.28538 22.5358 1.90522 21.8809 0.581834C21.7181 0.255421 21.004 -0.0780874 20.6673 0.0106117C20.3306 0.102859 20.0606 0.691821 19.9163 1.11403C19.7276 1.66396 19.8164 2.31324 19.5685 2.8277C18.4215 5.23321 16.6122 7.0817 14.0852 8.16383C13.6819 8.33768 13.7041 8.32704 13.3008 8.49734C14.3183 9.85266 16.0239 12.4604 17.2079 16.8067C19.9866 15.9019 22.3471 15.6891 25.4772 16.8563C25.9952 17.0479 26.4244 17.4559 26.9276 17.6937C27.4197 17.9278 28.1264 18.4884 28.4705 18.2578C28.8183 18.0272 28.8849 17.1189 28.7258 16.6648H28.7295Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.49713 19.7294C2.49713 19.7294 0.839562 16.6036 0.95796 15.5215C0.95796 15.5215 -0.455412 15.9685 0.151376 18.0618C0.761864 20.1551 2.27883 19.7365 2.49713 19.7294Z" fill="white"/>
                </g>
                <defs>
                <clipPath id="clip0_8610_526">
                <rect width="28.8076" height="24.4597" fill="white" transform="translate(0.839844 0.742188)"/>
                </clipPath>
                <clipPath id="clip1_8610_526">
                <rect width="28.8076" height="24.4597" fill="white"/>
                </clipPath>
                </defs>
            </svg>

            <svg className="svg-mob" xmlns="http://www.w3.org/2000/svg" width="29" height="24" viewBox="0 0 29 24" fill="none">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7943 20.3822C13.7068 19.6406 13.6858 18.8923 13.6263 17.889C14.3751 17.5669 14.6551 17.4394 15.4284 17.1642C14.2947 12.9966 12.72 10.5706 11.8067 9.37598C9.39217 10.3726 7.48857 11.1175 5.06357 12.094C2.82753 12.9933 2.14168 14.5368 3.03749 16.6508C3.30344 17.2783 3.56238 17.9091 3.83883 18.5299C4.34622 19.6809 6.05737 20.4829 7.3346 20.3621C7.84899 20.3117 8.53835 20.5634 8.91977 20.909C9.7526 21.6674 10.393 22.6136 11.2013 23.4055C11.9187 24.1068 13.6088 23.9324 14.5116 23.1069C15.3759 22.315 14.6026 21.7378 14.1617 21.117C14.0007 20.8956 13.8258 20.6406 13.7943 20.3855V20.3822Z" fill="#222222"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.9655 16.4668C27.4966 15.0911 26.8003 13.7891 26.3174 12.4167C26.3034 12.3731 26.2964 12.3227 26.2929 12.269C24.8092 8.45042 23.9168 6.56125 23.4794 5.71229C23.4654 5.69552 23.4514 5.67538 23.4444 5.65861C23.4235 5.61498 23.4025 5.57136 23.3815 5.52438C23.2205 5.22574 23.147 5.1083 23.1435 5.10494L23.175 5.08481C22.5976 3.81305 22.1077 2.50774 21.4884 1.25612C21.3344 0.947412 20.659 0.63199 20.3406 0.715879C20.0222 0.803123 19.7667 1.36014 19.6302 1.75946C19.4518 2.27957 19.5358 2.89363 19.3013 3.38019C18.2165 5.65525 16.5054 7.40349 14.1154 8.42694C13.734 8.59136 13.755 8.58129 13.3735 8.74236C14.3358 10.0242 15.949 12.4905 17.0688 16.6011C19.6967 15.7454 21.9293 15.5441 24.8896 16.648C25.3795 16.8292 25.7855 17.2151 26.2614 17.4399C26.7268 17.6614 27.3951 18.1916 27.7206 17.9735C28.0495 17.7554 28.1125 16.8963 27.962 16.4668H27.9655Z" fill="#222222"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M3.15614 19.3644C3.15614 19.3644 1.58846 16.4082 1.70044 15.3848C1.70044 15.3848 0.363719 15.8076 0.9376 17.7873C1.51498 19.7671 2.94968 19.3712 3.15614 19.3644Z" fill="#484545"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M12.9998 19.68C12.9123 18.9385 12.8914 18.1902 12.8319 17.1869C13.5807 16.8647 13.8606 16.7372 14.634 16.4621C13.5002 12.2945 11.9256 9.8684 11.0122 8.67383C8.59774 9.67043 6.69414 10.4154 4.26914 11.3918C2.0331 12.2911 1.34724 13.8347 2.24306 15.9487C2.509 16.5762 2.76795 17.207 3.04439 17.8278C3.55179 18.9787 5.26293 19.7807 6.54017 19.6599C7.05456 19.6096 7.74392 19.8612 8.12534 20.2069C8.95817 20.9652 9.59853 21.9115 10.4069 22.7034C11.1242 23.4047 12.8144 23.2302 13.7172 22.4047C14.5815 21.6128 13.8082 21.0357 13.3673 20.4149C13.2063 20.1934 13.0313 19.9384 12.9998 19.6834V19.68Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M27.1711 15.7647C26.7022 14.3889 26.0058 13.087 25.5229 11.7145C25.5089 11.6709 25.5019 11.6206 25.4984 11.5669C24.0147 7.74827 23.1224 5.8591 22.685 5.01015C22.671 4.99337 22.657 4.97324 22.65 4.95646C22.629 4.91284 22.608 4.86921 22.587 4.82224C22.4261 4.52359 22.3526 4.40615 22.3491 4.40279L22.3806 4.38266C21.8032 3.1109 21.3133 1.80559 20.6939 0.553974C20.5399 0.245264 19.8646 -0.0701581 19.5462 0.0137307C19.2277 0.100975 18.9723 0.657997 18.8358 1.05731C18.6573 1.57742 18.7413 2.19148 18.5069 2.67804C17.4221 4.9531 15.711 6.70134 13.3209 7.72479C12.9395 7.88921 12.9605 7.87914 12.5791 8.04021C13.5414 9.32203 15.1546 11.7884 16.2743 15.8989C18.9023 15.0432 21.1348 14.8419 24.0952 15.9459C24.5851 16.1271 24.991 16.513 25.4669 16.7378C25.9323 16.9593 26.6007 17.4894 26.9261 17.2713C27.2551 17.0532 27.318 16.1942 27.1676 15.7647H27.1711Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2.36171 18.6623C2.36171 18.6623 0.794031 15.7061 0.906008 14.6826C0.906008 14.6826 -0.430715 15.1054 0.143166 17.0852C0.720547 19.065 2.15525 18.669 2.36171 18.6623Z" fill="white"/>
            </svg>

        </>
    )
};

export default AnnouncementIcon;
