interface IProps {
    customClass?: string;
    children: any   
}

const ModalBody = ({children, customClass}: IProps) => {
    return (
        <div className={`modal__body ${customClass || ''}`}>
            {children}
        </div>
    )
};

export default ModalBody;
