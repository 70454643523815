import { Modal } from "components/common/modal";
import { TabPanel, Tabs } from "components/common/tabs";
import { useContext, useState } from "react"
import RefundOrders from "./refundOrders";
import RefundItems from "./refundItems";
import { ReportContext } from "contexts/report";

const LIMIT = 15;

const RefundOrderModal = ({closeModal}: any) => {
    const { fetchRefundsOrder, selectedEvent } = useContext(ReportContext);
    const [activeTab, setActiveTab] = useState(0);
    const [page, setPage] = useState(0);

    const fetchMoreData = async () => {
        if(!selectedEvent?.id){
            return;
        }
        setPage((prev: number) => prev+1);
        const res = await fetchRefundsOrder({event_id: selectedEvent?.id, limit: LIMIT, offset: (page+1)*LIMIT});
        return res;
    }

    return (
        <>
            <Modal customClass="refund-modal report-refund-modal"
                setShowModal={() => closeModal()}
            >
                <Tabs
                    tabs={['Orders', 'Items']}
                    onClickTab={setActiveTab}
                    activeTab={activeTab}
                >

                    <TabPanel
                        selected={activeTab === 0}
                    >
                        <RefundOrders setPage={setPage} page={page} fetchMoreData={fetchMoreData} />
                    </TabPanel>

                    <TabPanel
                        selected={activeTab === 1}
                    >
                        <RefundItems setPage={setPage} page={page} fetchMoreData={fetchMoreData} />
                    </TabPanel>

                </Tabs>
            </Modal>
        </>
    )
};

export default RefundOrderModal;
