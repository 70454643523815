import { FC } from "react";

interface Props  {
    isDisabled? : boolean,
}

const PaymentIcon: FC<Props> = () => {
    return (
        <>
            {/* {!isDisabled && <svg className="active-tab-svg" width="40" height="26" viewBox="0 0 40 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.4">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M2 7.23814H40V3.35271C40 2.61 39.3894 2 38.646 2H3.35401C2.61058 2 2.00255 2.61 2.00255 3.35271L2 7.23814Z" fill="#696969"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M39.9999 10.6123H2.00244V24.6482C2.00244 25.3934 2.61047 26.0009 3.3539 26.0009H38.645C39.3885 26.0009 39.999 25.3934 39.999 24.6482V10.6123H39.9999ZM12.8456 17.7596C13.1351 17.7596 13.3719 17.9961 13.3719 18.2853V21.924C13.3719 22.2133 13.1351 22.4524 12.8456 22.4524H7.39719C7.10766 22.4524 6.87092 22.2133 6.87092 21.924V18.2853C6.87092 17.9961 7.10766 17.7596 7.39719 17.7596H12.8456Z" fill="#696969"/>
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0 5.23814H38V1.35271C38 0.609996 37.3894 0 36.646 0H1.35401C0.610582 0 0.00255474 0.609996 0.00255474 1.35271L0 5.23814Z" fill="#26CAD3"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M37.9999 8.6123H0.00244141V22.6482C0.00244141 23.3934 0.610469 24.0009 1.3539 24.0009H36.645C37.3885 24.0009 37.999 23.3934 37.999 22.6482V8.6123H37.9999ZM10.8456 15.7596C11.1351 15.7596 11.3719 15.9961 11.3719 16.2853V19.924C11.3719 20.2133 11.1351 20.4524 10.8456 20.4524H5.39719C5.10766 20.4524 4.87092 20.2133 4.87092 19.924V16.2853C4.87092 15.9961 5.10766 15.7596 5.39719 15.7596H10.8456Z" fill="#0075C9"/>
            </svg>} */}
            {/* <svg className="non-active-svg" width="38" height="24" viewBox="0 0 38 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="Group 1479">
                <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M0 5.23814H38V1.35271C38 0.609996 37.3894 0 36.646 0H1.35401C0.610582 0 0.00255474 0.609996 0.00255474 1.35271L0 5.23814Z" fill="white"/>
                <path id="Vector_2" fillRule="evenodd" clipRule="evenodd" d="M37.9999 8.6123H0.00244141V22.6482C0.00244141 23.3934 0.610469 24.0009 1.3539 24.0009H36.645C37.3885 24.0009 37.999 23.3934 37.999 22.6482V8.6123H37.9999ZM10.8456 15.7596C11.1351 15.7596 11.3719 15.9961 11.3719 16.2853V19.924C11.3719 20.2133 11.1351 20.4524 10.8456 20.4524H5.39719C5.10766 20.4524 4.87092 20.2133 4.87092 19.924V16.2853C4.87092 15.9961 5.10766 15.7596 5.39719 15.7596H10.8456Z" fill="white"/>
                </g>
            </svg> */}
            <svg className="non-active-svg" width="31" height="19" viewBox="0 0 31 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.4">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.75 4.92871H30.2507V2.01456C30.2507 1.45751 29.7928 1 29.2352 1H2.76553C2.20795 1 1.75192 1.45751 1.75192 2.01456L1.75 4.92871Z" fill="#222222"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30.2503 7.46011H1.75146V17.9873C1.75146 18.5463 2.2075 19.0018 2.76508 19.0018H29.2341C29.7917 19.0018 30.2496 18.5463 30.2496 17.9873V7.46011H30.2503ZM9.88404 12.8207C10.1012 12.8207 10.2788 12.9981 10.2788 13.215V15.9441C10.2788 16.1611 10.1012 16.3404 9.88404 16.3404H5.79763C5.58047 16.3404 5.40292 16.1611 5.40292 15.9441V13.215C5.40292 12.9981 5.58047 12.8207 5.79763 12.8207H9.88404Z" fill="#222222"/>
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.75 3.92871H29.2507V1.01456C29.2507 0.457509 28.7928 0 28.2352 0H1.76553C1.20795 0 0.751916 0.457509 0.751916 1.01456L0.75 3.92871Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M29.2503 6.46011H0.751465V16.9873C0.751465 17.5463 1.2075 18.0018 1.76508 18.0018H28.2341C28.7917 18.0018 29.2496 17.5463 29.2496 16.9873V6.46011H29.2503ZM8.88404 11.8207C9.1012 11.8207 9.27876 11.9981 9.27876 12.215V14.9441C9.27876 15.1611 9.1012 15.3404 8.88404 15.3404H4.79763C4.58047 15.3404 4.40292 15.1611 4.40292 14.9441V12.215C4.40292 11.9981 4.58047 11.8207 4.79763 11.8207H8.88404Z" fill="white"/>
            </svg>

            <svg className="svg-mob" width="31" height="20" viewBox="0 0 31 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g opacity="0.4">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M1.75 5.56543H30.2507V2.65128C30.2507 2.09423 29.7928 1.63672 29.2352 1.63672H2.76553C2.20795 1.63672 1.75192 2.09423 1.75192 2.65128L1.75 5.56543Z" fill="#222222"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M30.2503 8.09683H1.75146V18.624C1.75146 19.183 2.2075 19.6386 2.76508 19.6386H29.2341C29.7917 19.6386 30.2496 19.183 30.2496 18.624V8.09683H30.2503ZM9.88404 13.4574C10.1012 13.4574 10.2788 13.6348 10.2788 13.8517V16.5809C10.2788 16.7978 10.1012 16.9771 9.88404 16.9771H5.79763C5.58047 16.9771 5.40292 16.7978 5.40292 16.5809V13.8517C5.40292 13.6348 5.58047 13.4574 5.79763 13.4574H9.88404Z" fill="#222222"/>
                </g>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M0.75 4.56543H29.2507V1.65128C29.2507 1.09423 28.7928 0.636719 28.2352 0.636719H1.76553C1.20795 0.636719 0.751916 1.09423 0.751916 1.65128L0.75 4.56543Z" fill="white"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M29.2503 7.09683H0.751465V17.624C0.751465 18.183 1.2075 18.6386 1.76508 18.6386H28.2341C28.7917 18.6386 29.2496 18.183 29.2496 17.624V7.09683H29.2503ZM8.88404 12.4574C9.1012 12.4574 9.27876 12.6348 9.27876 12.8517V15.5809C9.27876 15.7978 9.1012 15.9771 8.88404 15.9771H4.79763C4.58047 15.9771 4.40292 15.7978 4.40292 15.5809V12.8517C4.40292 12.6348 4.58047 12.4574 4.79763 12.4574H8.88404Z" fill="white"/>
            </svg>
        </>
    )
};

export default PaymentIcon;
