import { AuthContext } from "contexts/auth";
import { useOnboardingPending } from "hooks/useOnboardingPending";
import { useContext } from "react";
import { Navigate, Outlet, useLocation } from "react-router";

const OnboardingProtectedRoute = () => {
   
    const location = useLocation();
    const isOnboardingPending = useOnboardingPending();
    const { onboardingDetails } = useContext(AuthContext);
    

    if(onboardingDetails){
        const isSubmitReview = onboardingDetails?.menu_completed;
        if(isSubmitReview){
        return isOnboardingPending && location.pathname.includes("/promotional-resources")  ? <Outlet />  : <Navigate to="/onboarding" state={{from: location}} />
        }else{
            return isOnboardingPending  ? <Navigate to="/onboarding" state={{ from: location }} />
             : <Outlet />
        }
    

    }    

   return isOnboardingPending  ? <Navigate to="/onboarding" state={{ from: location }} />
   : <Outlet />

};

export default OnboardingProtectedRoute;
