import CrossButton from "components/common/crossButton";

const MenuImageBox = ({item_image, noImage}: any) => {
    return (
        <div style={{border: noImage ? '1px solid red' : 'none'}} className='emptyImgBox'>
            {item_image ? (
                <img alt="uploadedImage" style={{width: '100%'}} src={item_image} />
            ): noImage ? (
                <CrossButton
                    onClick={() => {}}
                    width={25}
                    height={25}
                />
            ):(
                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none">
                    <g clipPath="url(#clip0_3147_942)">
                        <path d="M15 30C23.2843 30 30 23.2843 30 15C30 6.71573 23.2843 0 15 0C6.71573 0 0 6.71573 0 15C0 23.2843 6.71573 30 15 30Z" fill="#26CAD3"/>
                        <path d="M15 29C22.732 29 29 22.732 29 15C29 7.26801 22.732 1 15 1C7.26801 1 1 7.26801 1 15C1 22.732 7.26801 29 15 29Z" stroke="white" strokeWidth="2"/>
                        <path d="M8.5 15H21.5" stroke="white" strokeWidth="3"/>
                        <path d="M15 9L15 21" stroke="white" strokeWidth="3"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_3147_942">
                            <rect width="30" height="30" fill="white"/>
                        </clipPath>
                    </defs>
                </svg>
            )}
            
        </div>
    )
};

export default MenuImageBox;
