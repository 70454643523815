import Cookies from "js-cookie";

const numberRegex = /^[0-9]+$/;

export const getAdminData = (callback: Function) => {
    
    const data = Cookies.get("adminData");

    if (data) {
        const adminData: any = JSON.parse(data);

        Object.keys(adminData || {}).forEach((item: any) => {
            Cookies.remove(item);
            Cookies.set(item, adminData[item]);
            adminData[item] = adminData[item] === 'true' ||
                adminData[item] === 'false' ||
                numberRegex.test(adminData[item]) ? JSON.parse(adminData[item]) : adminData[item];

        });

        Cookies.remove("adminData");
        Cookies.remove("impersonation_token");
        Cookies.remove("initials");

        callback(adminData);
    }
}