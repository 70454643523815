import Admins from "components/admins";
import { UserActions } from "contexts/reducers/user";
import { UserContext } from "contexts/user";
import { useIsSuperAdmin } from "hooks/useIsSuperAdmin";
import { useContext } from "react";

const VendorAdmins = ({vendorId}: any) => {
    const { fetchAdmins, addAdmin, updateAdmin, deleteAdmin, adminList, loading, setAdminsPage, adminsPage, adminQueryParam, setAdminQeuryParam} = useContext(UserContext);

    const getAdminLoading = loading[UserActions.SET_ADMINS];
    const isEditAdminLoading = loading[UserActions.EDIT_ADMIN];
    const isDeleteLoading = loading[UserActions.DELETE_ADMIN];
    const addAdminLoading = loading[UserActions.ADD_ADMIN];



    const isSuperAdmin = useIsSuperAdmin();


    return (
        <Admins
            vendorId={vendorId}
            adminList={adminList}
            fetchAdmins={fetchAdmins}
            addAdmin={addAdmin}
            updateAdmin={updateAdmin}
            deleteAdmin={deleteAdmin}
            loading={addAdminLoading}
            isEditAdminLoading={isEditAdminLoading}
            isDeleteLoading={isDeleteLoading}
            isAdminsLoading={getAdminLoading}
            isImperSonate={isSuperAdmin ? true: false}
            page= {adminsPage}
            setPage = {setAdminsPage}
            queryParams={ adminQueryParam}
            setQueryParams={setAdminQeuryParam}
            IsFetchingMore={loading[UserActions.SET_ADMINS_MORE]}
        />
    )
};

export default VendorAdmins;
