import ButtonLoader from "./loader";

type type = "button" | "submit" | "reset";
interface Props {
    parentClass?: string;
    onClick?: Function;
    customClass?: string;
    buttonName: string;
    disabled?: boolean;
    type?: type;
    loading?: boolean;
    loadingText?: string;
    isActiveDisabled?: boolean;
    customStyles?: any;
    parentStyles?: any;
    cancelButton?: boolean;
    width?: number | string;
    dropShadow?: boolean;
    height?: number | string;
}

const Button = ({
    isActiveDisabled, 
    loading, 
    loadingText, 
    buttonName, 
    parentClass, 
    onClick, 
    customClass, 
    disabled = false, 
    type = "button",
    customStyles,
    parentStyles,
    cancelButton,
    width,
    height,
    dropShadow = true
}: Props) => {
    return (
        <div style={{...(parentStyles && parentStyles), ...(width && {width}), ...(height && {height})}} className={parentClass || ''}>
            <button 
                className={`bttn ${dropShadow ? 'bttn-shadow' : ''} ${cancelButton ? 'bttn_cancel' : ''} ${isActiveDisabled ? 'active-disabled' : ''} ${customClass || ''}`} 
                disabled={loading || disabled}
                onClick={(e: any) => onClick && onClick(e)}
                type={type}
                style={{
                    ...(customStyles && customStyles)
                }}
            >
                {loading ? loadingText || buttonName : buttonName}
                {loading && (
                    <ButtonLoader />
                )}
            </button>
        </div>
    )
}

export default Button;