import { createContext, useReducer, useState } from "react"
import { VendorActions } from "./reducers/vendor"
import { getVenueList, postVenue, removeVenue } from "services/vendor";
import { VenueContextProvider } from "interfaces/venue"
import { VenueActions, VenueReducer } from "./reducers/venue"
import { VendorListRequest } from "interfaces/vendor";
import { getVenue } from "services/venue";

const initialState: VenueContextProvider = {
    loading: {
        [VenueActions.GET_VENUES]: true
    },
    setLoading: () => {},
    resetLoading: () => {},
    venueList: {
        venues: [],
        _count: 0
    },
    fetchVenues: () => Promise<any>,
    deleteVenue: () => Promise<any>,
    addVenue: () => Promise<any>,
    fetchVenueDetails: () => Promise<any>,
    setQueryParams: () => { },
    queryParams: {
        limit: 10,
        offset: 0,
        statusList: [],
        status: '',
        search: '',
        sort_order: '',
        sort_by: ''
    },
    page:0,
    setPage: ()=>{},

    venueQueryParams: {
        limit: window.innerHeight > 1100 ? 20 : 15,
        offset: 0,
        sort: ''
    },
    setVenueQueryParams : ()=>{}
}









export const VenueContext = createContext<VenueContextProvider>(null!)

const VenueProvider = ({ children }: any) => {
    // const {id} = useParams();
    // const venueId = id ? parseInt(id, 10) : null;
    const [{ loading, venueList, queryParams }, dispatch] = useReducer(VenueReducer, initialState);
    const [page, setPage] = useState(initialState.page);
    const [venueQueryParams, setVenueQueryParams] = useState(initialState.venueQueryParams);

    const addVenue = async (addVenueReq: any) => {
        setLoading(VenueActions.ADD_VENUE);
        const res = await postVenue(addVenueReq);
        resetLoading(VendorActions.ADD_VENUE)

        if(res?._status?.code === 201){
            dispatch({
                type: VenueActions.ADD_VENUE,
                payload: {...res.data, vendor: {...res.data.vendor, map_required: true}}
            })
        }

        return res;
    }

    const setQueryParams = (params: any) => {
        dispatch({
            type: VenueActions.SET_QUERY_PARAMS,
            payload: params
        })
    }

    const fetchVenueDetails = async (venue_id: string) => {
        setLoading(VenueActions.GET_VENUE_DETAILS);
        const res = await getVenue(venue_id);
        resetLoading(VenueActions.GET_VENUE_DETAILS);

        if(res?._status?.code === 200){
            dispatch({
                type: VendorActions.GET_VENUE_DETAILS,
                payload: res.data
            })
        }
        return res;
    }

    const setLoading = (loading: string) => {
        dispatch({
            type: VenueActions.SET_VENUE_LOADING,
            payload: loading
        })
    }

    const resetLoading = (loading: string) => {
        dispatch({
            type: VenueActions.RESET_VENUE_LOADING,
            payload: loading
        })
    }

    const fetchVenues = async (venueListReq: VendorListRequest) => {
        venueListReq.offset ? setLoading(VendorActions.GET_MORE_VENUES) : setLoading(VendorActions.GET_VENUES);
        const res = await getVenueList(venueListReq);
        venueListReq.offset ? resetLoading(VendorActions.GET_MORE_VENUES) : resetLoading(VendorActions.GET_VENUES);

        if(res?._status?.code === 200){
            dispatch({
                type: VendorActions.GET_VENUES,
                payload: res.data
            });
        }

        return res;
    }

    const deleteVenue = async (id: number) => {
        setLoading(VendorActions.DELETE_VENUE);
        const res = await removeVenue(id);
        resetLoading(VendorActions.DELETE_VENUE);

        if(res?.status === 204){
            dispatch({
                type: VendorActions.DELETE_VENUE,
                payload: id
            });
        }

        return res;
    }

    return <VenueContext.Provider value={{
        loading,
        setLoading,
        resetLoading,
        fetchVenues,
        venueList,
        deleteVenue,
        addVenue,
        fetchVenueDetails,
        queryParams,
        setQueryParams,
        page,
        setPage,
        venueQueryParams, 
        setVenueQueryParams

    }}>
        {children}
    </VenueContext.Provider>
}
export default VenueProvider;