import { insignificantZerosRegex } from "components/orders/details";
import Price from "./price";

const EventSalesPrint = ({events_sales, showDeliveryFees}: {events_sales: any; showDeliveryFees?: boolean}) => {
    return (
        <div className="report-print-container">
            <div className="event-sales">
                <div className="sales-section d-flex align-items-center">
                    <p className="reportsLabel">Net Sales:</p>
                    <p className="reportsText">
                        <Price amount={events_sales?.gross_sales?.replace(insignificantZerosRegex, '.')} />
                    </p>
                </div>
                <div className="sales-section d-flex align-items-center">
                    <p className="reportsLabel">Total Orders:</p>
                    <p className="reportsText">
                        <Price amount={events_sales?.total_orders} />
                    </p>
                </div>
                <div className="sales-section d-flex align-items-center">
                    <p className="reportsLabel">Avg. Order Total:</p>
                    <p className="reportsText">
                        <Price amount={events_sales?.average_order_total} />
                    </p>
                </div>
                
                
                
                
            </div>

            <div className="event-sales">
                <div className="sales-section d-flex align-items-center">
                    <p className="reportsLabel">Tax Collected:</p>
                    <p className="reportsText">
                        <Price amount={events_sales?.total_tax?.replace(insignificantZerosRegex, '.')} />
                    </p>
                </div>
                
                
                

            </div>

            <div className="event-sales">
                <div style={{gap: 40}} className="sales-section d-flex align-items-center">
                    <div className="d-flex align-items-center">
                        <p className="reportsLabel">
                            Refunds:
                        </p>
                        
                        <p className="reportsText">
                            <Price amount={events_sales?.refunds?.replace(insignificantZerosRegex, '.')} />
                        </p>
                    </div>
                </div>
                <div className="sales-section d-flex align-items-center">
                    <p className="reportsLabel">Refunded Orders:</p>
                    <p className="reportsText">
                        <Price amount={events_sales?.refunds_count} />
                    </p>
                </div>
                <div className="sales-section d-flex align-items-center">
                    <p className="reportsLabel">Refund Fees:</p>
                    <p className="reportsText">
                        <Price amount={events_sales?.refund_fees?.replace(insignificantZerosRegex, '.')} />
                    </p>
                </div>
            </div>
            <div className="event-sales">
            {showDeliveryFees && (
                    <div className="sales-section d-flex align-items-center">
                        <p className="reportsLabel">Delivery Fees:</p>
                        <p className="reportsText">
                            <Price amount={events_sales?.total_delivery_fee?.replace(insignificantZerosRegex, '.')} />
                        </p>
                    </div>
                )}
                
                
            </div>
            <div className="event-sales">
                <div className="sales-section d-flex align-items-center">
                    <p className="reportsLabel">Donations:</p>
                    <p className="reportsText">
                        <Price amount={events_sales?.total_tips?.replace(insignificantZerosRegex, '.')} />
                    </p>
                </div>
                <div className="sales-section d-flex align-items-center">
                    <p className="reportsText">
                        {events_sales?.donation_organization}
                    </p>
                </div>
            </div>
        </div>
    )
};

export default EventSalesPrint;
