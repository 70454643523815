const EditIcon = ({ onClick, customClass }: any) => {
  return (
    <a onClick={onClick} href="#/" title="Edit">
      <svg
        className={`cursor-pointer edit-icon-white ${customClass}`}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="23"
        viewBox="0 0 24 23"
        fill="none"
      >
        <path
          d="M14.5666 4.26899L19.0006 8.707L7.77159 19.94L3.33559 15.502L14.5666 4.26899ZM22.3716 3.19899L20.3926 1.21899C20.0244 0.851747 19.5256 0.645508 19.0056 0.645508C18.4856 0.645508 17.9868 0.851747 17.6186 1.21899L15.7186 3.11899L20.1606 7.55699L22.3716 5.34599C22.5133 5.20535 22.6258 5.03804 22.7026 4.85371C22.7794 4.66938 22.8189 4.47167 22.8189 4.27199C22.8189 4.07232 22.7794 3.87461 22.7026 3.69028C22.6258 3.50595 22.5133 3.33964 22.3716 3.19899ZM1.01359 21.74C0.993639 21.8238 0.995686 21.9114 1.01953 21.9941C1.04337 22.0769 1.0882 22.1522 1.14967 22.2125C1.21114 22.2729 1.28717 22.3163 1.37038 22.3387C1.45358 22.361 1.54115 22.3615 1.62459 22.34L6.56959 21.14L2.13459 16.704L1.01359 21.74Z"
          stroke="white"
          strokeWidth="1.2"
        />
      </svg>
    </a>
  );
};

export default EditIcon;
