import Skeleton from "./skeleton";

interface Props {
    count: number;
    height?: number;
}

const TableSkeleton = ({count, height}: Props) => {
    return (
        <>
            {[...new Array(count)].map((_, index: number) => {
                return (
                    <Skeleton height={height} key={index} customClass="table-skeleton" />
                )
            })}
        </>
    )
};

export default TableSkeleton;
