import React, { FC } from "react";
import { Skeleton } from "components/common/skeleton";
import StaticSkeleton from "components/common/skeleton/StaticSkeleton";

interface Props {

  isStatic: boolean
}


const UpComingEventSkeleton: FC<Props> = ({isStatic}) => {
  return (
    <div
      className="upComingEventSkeloton"
      style={{ display: "flex", flexDirection: "column" }}
    >
      <div className="skeletonMont">
        { isStatic ? <StaticSkeleton /> :
        <Skeleton />
        }
      </div>
      <div className="SkeletonEvents">
        <div className="dateTime">
        { isStatic ? <StaticSkeleton /> :
        <Skeleton />
        }
        </div>
        <div className="eventName">
        { isStatic ? <StaticSkeleton /> :
        <Skeleton />
        }
        </div>
      </div>
      <div className="SkeletonEvents">
        <div className="dateTime">
        { isStatic ? <StaticSkeleton /> :
        <Skeleton />
        }
        </div>
        <div className="eventName">
        { isStatic ? <StaticSkeleton /> :
        <Skeleton />
        }
        </div>
      </div>
      <div className="SkeletonEvents">
        <div className="dateTime">
        { isStatic ? <StaticSkeleton /> :
        <Skeleton />
        }
        </div>
        <div className="eventName">
        { isStatic ? <StaticSkeleton /> :
        <Skeleton />
        }
        </div>
      </div>
      <div className="SkeletonEvents">
        <div className="dateTime">
        { isStatic ? <StaticSkeleton /> :
        <Skeleton />
        }
        </div>
        <div className="eventName">
        { isStatic ? <StaticSkeleton /> :
        <Skeleton />
        }
        </div>
      </div>
      <div className="SkeletonEvents">
        <div className="dateTime">
        { isStatic ? <StaticSkeleton /> :
        <Skeleton />
        }
        </div>
        <div className="eventName">
        { isStatic ? <StaticSkeleton /> :
        <Skeleton />
        }
        </div>
      </div>
    </div>
  );
};

export default UpComingEventSkeleton;
