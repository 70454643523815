export const getClassName = (valueLength: number) => {
    let className=null;
    switch(valueLength){
        case 6:
            className = 'six-numbers'
            break;
        case 7:
            className = 'seven-numbers'
            break;
        case 8:
            className = 'eight-numbers'
            break;
        default:
            className = ''
    }

    return className;
}


export const useCalculateLength = (value: string) => {
    if(!value){
        return ''
    }

    const pureValue = value?.replace('.', '');
    const className = getClassName(pureValue.length);

    return className;
}