import React, { FC, useContext, useEffect } from "react";

import { useLocation } from "react-router-dom";
import EventsTableHeader from "./EventsTable/EventsTableHeader";
import EventsTableBody from "./EventsTable/EventsTableBody";
import SuperAdminReportsDropDown from "../DropDowns/SuperAdminReportsDropDown";
import { SuperAdminReportContext } from "contexts/superAdminReport";
import { SuperAdminReportActions } from "contexts/reducers/superAdminReports";
import { VendorContext } from "contexts/vendor";
import VendorDropdown from "../DropDowns/SuperAdminvendorDropDown";

const dateRangeOptions = [
  { label: "Last 24 Hours", value: "LAST_24_HOURS" },
  { label: "Last 7 Days", value: "LAST_7_DAYS" },
  { label: "Last Month", value: "LAST_MONTH" },
  { label: "Last Year", value: "LAST_YEAR" },
  { label: "Last 7 Days A Year Ago", value: "LAST_7_DAYS_LAST_YEAR" },
];

interface Props {
  isPrint?: boolean;
  activeTab?: any
}

const EventsBody: FC<Props> = ({ isPrint = false, activeTab }) => {
  const { fetchReprtEvents, eventQueryParams, setReportReducer, setSelectedVendor, selectedVendor } = useContext(SuperAdminReportContext);
  const {fetchVendors, vendorList} = useContext(VendorContext)

  const location = useLocation();
  const fromLocation = location.state;

  const onChangeDateRange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    delete fromLocation?.from;
    const newQueryParams = {
      ...eventQueryParams,
      filter_by: e.target.value,
      offset: 0,
    };

    setReportReducer(
      SuperAdminReportActions.SET_EVENT_QUERY_PARAMS,
      newQueryParams
    );
    fetchReprtEvents(newQueryParams);
  };


  
  const onChangeVendor = (vendor: any) => {
    delete fromLocation?.from;

    console.log(vendor);
   
   
    const newQueryParams = {
      ...eventQueryParams,
      vendor_id: vendor.id,
      offset: 0,
    };

    setReportReducer(
      SuperAdminReportActions.SET_EVENT_QUERY_PARAMS,
      newQueryParams
    );
    fetchReprtEvents(newQueryParams);
  };




useEffect(()=>{

/* eslint-disable react-hooks/exhaustive-deps */

fetchVendors({});
}, [])


// useEffect(()=>{
//     setSelectedVendor({name: "All Vendors", id: 0});
// },[])


  useEffect(() => {
    const newQueryParams = {
      ...eventQueryParams,
      sort_by:
        fromLocation?.from === "dashboardEvents"
          ? "sales"
          : eventQueryParams.sort_by,
      filter_by:
        fromLocation?.from === "dashboardEvents"
          ? "LAST_MONTH"
          : eventQueryParams.filter_by,
      offset: 0,
    };
    setReportReducer(
      SuperAdminReportActions.SET_EVENT_QUERY_PARAMS,
      newQueryParams
    );

    !isPrint && fetchReprtEvents({...newQueryParams, vendor_id: 0});

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  console.log(vendorList);

  //const vendorOptions = vendorList?.vendors?.map((vendor: any)=> {return {label:vendor.name, value: vendor.id }})


  return (
    <div className="superEventReportsBody">
      <div className="superEventReportsBody__dropdowns">
      <div className="dates-group">
        {/* <SuperAdminReportsDropDown
          isPrint={isPrint}
          options={vendorOptions}
          value={ selectedVendor }
          placeholder="Select Vendor"
          onChange={onChangeVendor}
          lable="Vendors"
        /> */}

     
      <VendorDropdown 
        onSelectVendor = {onChangeVendor}
        selectedVendor = {selectedVendor}
        setSelectedVendor = {setSelectedVendor}
        vendorList = {vendorList}

      
      
      />






      </div>
      <div className="dates-group">
        <SuperAdminReportsDropDown
          isPrint={isPrint}
          options={dateRangeOptions}
          value={
            fromLocation?.from === "dashboardEvents"
              ? "LAST_MONTH"
              : eventQueryParams.filter_by
          }
          onChange={onChangeDateRange}
          lable="Dates"
        />
      </div>
     
     
      
      </div>

      <div className="allEventsTable">
        <EventsTableHeader activeTab={activeTab}  isPrint={isPrint} />
        <EventsTableBody activeTab={activeTab}/>
      </div>
    </div>
  );
};

export default EventsBody;
