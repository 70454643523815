import { SearchableSelect } from "components/common/customReactSelect/customReactSelect";
import Label from "components/common/label";
import { ReportContext } from "contexts/report";
import { VendorContext } from "contexts/vendor";
import { useContext } from "react";
import { useDebouncedCallback } from "use-debounce";

const VendorDropdown = ({ onSelectVendor }: any) => {
  const {
    setEventDetails,
    setEvents,
    setSelectedEvent,
    vendorOpen,
    setVendorOpen,
    selectedVendor,
    setSelectedVendor, 
    setSelectedVenue, 
    setVenueList,
    resetOrders,    
  
  } = useContext(ReportContext);
  const { vendorList, fetchVendors } = useContext(VendorContext);
   const { vendors } = vendorList;
  //const { vendors } = vendorsVenuesEvents;

  const onClickVendor = (item: any) => {
    const selectedVend = vendors.find((vend: any) => vend.id === item.value);
    onSelectVendor(selectedVend);
    setEventDetails({
      event: null!,
      event_sales: null!,
      items: [],
      formattedItems: [],
      printFormattedItems: [],
    });

    setEvents({
      events: [],
      _count: 0,
    });
    setSelectedEvent(null!);
  };

//   const dropdownToggle = () => {
//     setVendorOpen((prev: boolean) => !prev);
//     eventOpen && setEventOpen(false);
//     venueOpen && setVenueOpen(false);
//   };

  // console.log('selectedVendor',selectedVendor);


  const onOpen = ()=>{
    setSelectedVendor(null!)
    setEventDetails({
      event: null!,
      event_sales: null!,
      items: [],
      formattedItems: [],
      printFormattedItems: [],
    });

    setEvents({
      events: [],
      _count: 0,
    });
    setSelectedEvent(null!);
    setSelectedVenue(null!)
    setVenueList({  
      venues: [],
      _count: 0})

      resetOrders({ orders: [],
        _count:0})       
  }


  const placeHolderText = vendorOpen ? "" : "Select Vendor";


  
  
  const debouncedSearch = useDebouncedCallback((value: string) => {
    fetchVendors({search: value})
    },100)


  const onTextChange = (val: any)=>{
      debouncedSearch(val);
    

  }






  return (
    <div className="venue-dropDown-container custom-dropdown">
      <Label text="Vendor" inputLabel />

      <div
        className={`${vendorOpen ? "report-vendor-dropdown" : ""}`}
        // onClick={dropdownToggle}
      >
        <SearchableSelect
          placeholder={placeHolderText}
          value={
            selectedVendor
              ? { label: selectedVendor.name, value: selectedVendor.id }
              : null
          }
          onChange={(value: any) => onClickVendor(value)}
          options={vendors.map((vendor: any) => ({
            label: vendor.name,
            value: vendor.id,
          }))}
          isOpen={vendorOpen}
          setIsOpen={setVendorOpen}
          onOpen={onOpen}
          onTextChange={onTextChange}
        />
      </div>

      {vendorOpen && (
        <div className="overlay" onClick={() => setVendorOpen(false)}></div>
      )}
    </div>
  );
};

export default VendorDropdown;
