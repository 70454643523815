import config from 'config/config';
import axios from 'services/core/axios';
import { getQueryString } from 'util/getQueryString';

export const getReportEvents = async (reportReq: any) => {
    const queryString = getQueryString(reportReq);

    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/reports/events${queryString}`);
        return res?.data

    } catch (error) {
        return error;
    }
}

export const getReportsDownload = async (reportReq: any) => {
    const queryString = getQueryString(reportReq);

    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/reports/events/download${queryString}`, {responseType: "arraybuffer"});
        return res?.data

    } catch (error) {
        return error;
    }
}

export const getAllEvents = async (allEventReq: any) => {
    const { venue_id, search } = allEventReq;

    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/reports/venues/${venue_id}/events${search ? `?search=${search}` : ''}`);
        return res?.data

    } catch (error) {
        return error;
    }
}

export const getEventItems = async (eventItemsData:{sort_order: string, sort_by: string, event_id: number}) => {
    const { event_id, ...body } = eventItemsData;
    const queryString = getQueryString(body);

    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/reports/venues/events/${event_id}/items${queryString}`);
        return res?.data

    } catch (error) {
        return error;
    }
}

export const getEventSales = async (eventSaleReq: any) => {
    const {event_id, timezone} = eventSaleReq;
    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/reports/venues/events/${event_id}/sales?timezone=${timezone}`);
        return res?.data

    } catch (error) {
        return error;
    }
}

export const getEventDetails = async (eventSaleReq: any) => {
    const {venue_id, ...body} = eventSaleReq;
    const queryString = getQueryString(body);
    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/reports/venues/${venue_id}/sales${queryString}`);
        return res?.data

    } catch (error) {
        return error;
    }
}

export const getRefundsOrder = async (refundDataReq: any) => {
    const {event_id, ...body} = refundDataReq;
    const queryString = getQueryString(body);
    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/reports/venues/events/${event_id}/orders/refunds${queryString}`);
        return res?.data

    } catch (error) {
        return error;
    }
}

