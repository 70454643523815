import { Skeleton } from "components/common/skeleton";
import StaticSkeleton from "components/common/skeleton/StaticSkeleton";
import React, { FC } from "react";

interface Props {
  isStatic : boolean
}

const MostActivevendorSkeleton: FC<Props> = ({isStatic}) => {
  return (
    <>
      <div className="SkeletonSchoolEvents">
        <div className="schoolEvents">
          {isStatic ? <StaticSkeleton /> : 
          <Skeleton />
  }
        </div>
        <div className="totalEvents">
        {isStatic ? <StaticSkeleton /> : 
          <Skeleton />
  }
        </div>
      </div>
      <div className="SkeletonSchoolEvents">
        <div className="schoolEvents">
        {isStatic ? <StaticSkeleton /> : 
          <Skeleton />
  }
        </div>
        <div className="totalEvents">
        {isStatic ? <StaticSkeleton /> : 
          <Skeleton />
  }
        </div>
      </div>
      <div className="SkeletonSchoolEvents">
        <div className="schoolEvents">
        {isStatic ? <StaticSkeleton /> : 
          <Skeleton />
  }
        </div>
        <div className="totalEvents">
        {isStatic ? <StaticSkeleton /> : 
          <Skeleton />
  }
        </div>
      </div>
      <div className="SkeletonSchoolEvents">
        <div className="schoolEvents">
        {isStatic ? <StaticSkeleton /> : 
          <Skeleton />
  }
        </div>
        <div className="totalEvents">
        {isStatic ? <StaticSkeleton /> : 
          <Skeleton />
  }
        </div>
      </div>
      <div className="SkeletonSchoolEvents">
        <div className="schoolEvents">
        {isStatic ? <StaticSkeleton /> : 
          <Skeleton />
  }
        </div>
        <div className="totalEvents">
        {isStatic ? <StaticSkeleton /> : 
          <Skeleton />
  }
        </div>
      </div>
    </>
  );
};

export default MostActivevendorSkeleton;
