const Price = ({amount, width}: {amount: string | number, width?: number}) => {
    // const isSignificantZero = insignificantZerosRegex.test(amount?.toString());
    const [leftNumber, rightNumber] = amount ? amount.toString()?.split(".") : [];
    return (
        <>
            {/* <span style={{width: width || 80, float: 'left', textAlign: 'right', opacity: isSignificantZero ? 0 : 1}}>
                {leftNumber || ''}
                
            </span>
            <span style={{width: 7, float: 'left'}}>{rightNumber ? '.' : ''}</span>
            <span style={{width: 23, float: 'left'}}>{rightNumber || ''}</span> */}
            <span className="number">{leftNumber ? parseFloat(leftNumber).toLocaleString('en-US'):""}
                {rightNumber && <span className="decimal">.{rightNumber}</span>}
            </span>
            
        </>
    )
};

export default Price;
