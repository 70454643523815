import { createContext, useReducer, useState } from "react"
import { AuthActions, AuthReducer } from "./reducers/auth"
import { AuthContextProvider, LoginRequest, RefreshTokenRequest, ResetPasswordRequest, UpdatePasswordRequest, UpdateUserRequest } from "interfaces/auth"
import { getImpersonationToken, login, putUser, refreshToken, resetPassword, updatePassword } from "services/auth"
import { setAuthCookies } from "util/setAuthCookies"
import { useNavigate } from "react-router-dom"
import Cookies from "js-cookie"
import { clearAllCookies } from "util/clearAllCookies"
import { getOnboardingDetails } from "services/onboarding"

const initialState: AuthContextProvider = {
    user: null!,
    loginUser: () => { },
    resetUserPassword: () => { },
    loading: [],
    setLoading: () => { },
    resetLoading: () => { },
    refreshJwtToken: () => { },
    setOpenSideBar: null!,
    setOpenSearchbar: null!,
    openSidebar: false,
    openSearchbar: false,
    fetchImpersonationToken: () => Promise<any>,
    fetchOnboardingDetails: () => Promise<any>,
    setUser: () => { },
    updateUser: () => Promise<any>,
    changePassword: () => Promise<any>,
    logoutUser: () => { },
    onboardingDetails: null!,
    setOnboardingDetails: () => { },
    iconClicked: '',
    setIconClicked: ()=>{},
    setVendorDetailsEditing: ()=>{},
    vendorDetailsEditing: false,

}

export const AuthContext = createContext<AuthContextProvider>(null!)

const AuthProvider = ({ children }: any) => {

    const navigate = useNavigate();

    const [{ user, onboardingDetails, loading }, dispatch] = useReducer(AuthReducer, initialState);

    const [openSidebar, setOpenSideBar] = useState(false);
    const [openSearchbar, setOpenSearchbar] = useState(false);
    const [iconClicked, setIconClicked] = useState('');
    const [vendorDetailsEditing, setVendorDetailsEditing] = useState(initialState.vendorDetailsEditing);

    const loginUser = async (loginReq: LoginRequest) => {
        setLoading(AuthActions.SET_USER)
        const loginData = await login(loginReq);
        resetLoading(AuthActions.SET_USER)

        if (loginData?._status?.code === 201) {
            setAuthCookies(loginData?.data);
            setUser(loginData?.data)

            if (loginData?.data?.role_id === 1) {
                navigate('/dashboard')
            } else if (loginData?.data?.onboarding_pending) {
                navigate("/onboarding", { state: { onboardingStep: loginData?.data?.onboarding_step } });
            } else {
                navigate("/dashboard")
            }
        }

        return loginData;
    }

    const changePassword = async (updatePasswordReq: UpdatePasswordRequest) => {
        setLoading(AuthActions.UPDATE_PASSWORD)
        const res = await updatePassword(updatePasswordReq);
        resetLoading(AuthActions.UPDATE_PASSWORD)

        return res;
    }

    const logoutUser = () => {
        clearAllCookies();
        setOnboardingDetails(null!);
        setUser(null!);

        navigate("/login");
    }

    const setUser = (payload: any) => {
        dispatch({
            type: AuthActions.SET_USER,
            payload
        });
    }

    const resetUserPassword = async (resetPasswordReq: ResetPasswordRequest) => {
        setLoading(AuthActions.RESET_PASSWORD)
        const result = await resetPassword(resetPasswordReq);
        resetLoading(AuthActions.RESET_PASSWORD)
        return result;
    }

    const setLoading = (loading: string) => {
        dispatch({
            type: AuthActions.SET_AUTH_LOADING,
            payload: loading
        })
    }

    const resetLoading = (loading: string) => {
        dispatch({
            type: AuthActions.RESET_AUTH_LOADING,
            payload: loading
        })
    }

    const refreshJwtToken = async (refreshTokenReq: RefreshTokenRequest) => {
        await refreshToken(refreshTokenReq);
    }

    const fetchImpersonationToken = async (admin_id: number) => {
        setLoading(AuthActions.GET_IMPERSONATION_TOKEN);
        const res = await getImpersonationToken(admin_id);
        resetLoading(AuthActions.GET_IMPERSONATION_TOKEN);

        if (res?._status?.code === 200) {
            const cookies: any = document.cookie.split(";");
            let adminData: any = {};

            cookies.forEach((item: any) => {
                const trimedValue = item?.trim();
                const [key, value] = trimedValue.split("=");
                adminData = {
                    ...adminData,
                    [key]: value

                }
            });

            const firstName = adminData?.first_name?.length > 0 ? adminData.first_name : '';
            const lastName = adminData?.last_name?.length > 0 ? adminData.last_name : ''
            const name = `${firstName}${lastName ? ` ${lastName}` : ''}`

            Cookies.set("fullName", name);
            Cookies.set("adminData", JSON.stringify(adminData));
            Cookies.remove("role_id");
            Cookies.remove("zipcode");

            dispatch({
                type: AuthActions.SET_USER,
                payload: res.data
            });

            Object.keys(res.data).forEach((item: string) => {
                Cookies.set(item, res.data[item]);
            });
            Cookies.set('role_id', '2');

            res.data.onboarding_pending ? navigate('/onboarding') : navigate("/dashboard", {state: {active: ''}});
        }
    }

    const updateUser = async (updateUserReq: UpdateUserRequest) => {
        setLoading(AuthActions.SET_USER)
        const res = await putUser(updateUserReq);
        resetLoading(AuthActions.SET_USER)

        if (res?._status?.code === 200) {
            setAuthCookies(res?.data?.user);
            setUser(res?.data?.user)

        }

        return res;
    }

    const setOnboardingDetails = (data: any) => {

        dispatch({
            type: AuthActions.SET_ONBOARDING_DETAILS,
            payload: data
        });
    }

    const fetchOnboardingDetails = async () => {
        setLoading(AuthActions.SET_ONBOARDING_DETAILS);
        const onboardingDetails = await getOnboardingDetails();
        resetLoading(AuthActions.SET_ONBOARDING_DETAILS);

        if (onboardingDetails) { 
            setOnboardingDetails(onboardingDetails.data);
        }

        return onboardingDetails;
    };

    return <AuthContext.Provider value={{
        user,
        loginUser,
        resetUserPassword,
        loading,
        setLoading,
        resetLoading,
        refreshJwtToken,
        setOpenSideBar,
        setOpenSearchbar,
        openSidebar,
        openSearchbar,
        fetchImpersonationToken,
        setUser,
        updateUser,
        changePassword,
        logoutUser,
        fetchOnboardingDetails,
        onboardingDetails,
        setOnboardingDetails,
        iconClicked,
        setIconClicked,
        vendorDetailsEditing, 
        setVendorDetailsEditing
    }}>
        {children}
    </AuthContext.Provider>
}
export default AuthProvider;