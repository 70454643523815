import { Table, TableBody, TableHead, TableHeading } from "components/common/table";
import React, { useContext } from "react";
import { PromotionalResourcesContext } from "contexts/promotionalResources";
import TemplateRow from "./templateRow";

const Announcements = ({announcementText}:{announcementText: string}) => {
    const {signageQrUrl} = useContext(PromotionalResourcesContext);
    const {promotional_templates} = signageQrUrl || {};

    return (
        <div className="table-container">
            <Table>
                <TableHead>
                    <TableHeading customClass="purpose">
                        Purpose
                    </TableHeading>
                    <TableHeading customClass="vendor-venue">
                        Vendor/Venue
                    </TableHeading>
                    <TableHeading customClass="options">
                        Options
                    </TableHeading>
                    <TableHeading customClass="arrow">
                    
                    </TableHeading>
                </TableHead>

                <TableBody>
                    <TemplateRow 
                        text={promotional_templates?.intercom_announcement_event}
                    />
                </TableBody>
            </Table>
        </div>
    )
};

export default Announcements;
