import { TableHead, TableHeading } from "components/common/table";
import React from "react"

const OrderHeader = () => {
    return (
        <TableHead>
            <TableHeading customClass="order-no">
                Order No.
            </TableHeading>
            <TableHeading customClass="order-date">
                Date
            </TableHeading>
            <TableHeading customClass="order-items">
                Items
            </TableHeading>
            <TableHeading customClass="total-order">
                Total
            </TableHeading>
            <TableHeading customClass="order-status">
                Status
            </TableHeading>
        </TableHead>
    )
};

export default OrderHeader;
