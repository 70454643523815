import { useEffect } from "react";
import {Modal} from "../modal";


interface Props{
    message?: string;
    setIsShow?: Function;
    closeButtonName?: string;
    title?: string;
    description?: string;
    closeModal?: Function;
}

const ApiModal = ({title, message, setIsShow, closeModal, closeButtonName, description}: Props) => {
    
    useEffect(() => {
        const handler = (e: any) => {
            e.stopPropagation();
            if(e.keyCode === 13 || e.keyCode === 27){
                onClose(e);
            }
        }
        window.addEventListener('keydown', handler, true);

        return () => {
            window.removeEventListener('keydown', handler, true)
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[])

    const onClose = (e: any) => {
        if(e && typeof e.preventDefault === 'function') {
            e.preventDefault();
            e.stopPropagation();
        }
        setIsShow && setIsShow();
        closeModal && closeModal()
    }

    return (
        <div className={'mainContent-backDrop'}>
            <Modal setShowModal={onClose}>
                <div className="error-modal-header">
                    <h6 className="app-modal-title">
                        {title || 'Access Denied'}
                    </h6>
                </div>
                <div className="modal__body">
                    <p className="BodyParagraph">
                        {description || message || 'Something went wrong. Please try again later.'}
                    </p>
                </div>
                <div className="app-modal-footer">
                    <button
                        className={"bttn bttn_cancel close-btn cursor-pointer"}
                        onClick={onClose}
                    >
                        {closeButtonName || 'Close'}
                    </button> 
                </div>
            </Modal>
        </div>
    )
}
export default ApiModal;