import Button from "components/common/button";
import SelectContainer from "./selectContainer";

const SelectOptions = ({children, onClickButton, heading, buttonName, isButtonDisabled, isButtonLoading}: {onClickButton: Function, isButtonLoading?: boolean, buttonName?: string, isButtonDisabled: boolean, heading: string, children: React.ReactNode}) => {
    return (
        <SelectContainer>
            <div>
                <p className="heading">{heading}</p>
                {children}
            </div>
            <div>
                <Button
                    onClick={onClickButton}
                    disabled={isButtonDisabled || isButtonLoading}
                    buttonName={buttonName || "Download PDF"}
                    loading={isButtonLoading}
                />
            </div>
        </SelectContainer>
    )
};

export default SelectOptions;
