import Label from "components/common/label";
import React from "react"
import Select from "components/common/select";

interface IProps {
    options: {label: string, value: any}[], 
    placeholder?: string;
    onChange: React.ChangeEventHandler<HTMLSelectElement>;
    value: any;
    disabled?: boolean;
    lable?: string;
    isPrint?: boolean
}

const datesMapper = {
    LAST_24_HOURS: 'Last 24 Hours',
    LAST_7_DAYS: 'Last 7 Days',
    LAST_MONTH: 'Last Month',
    LAST_YEAR: 'Last Year',
    LAST_7_DAYS_LAST_YEAR: 'Last 7 Days A Year Ago'
  }
  
const SuperAdminReportsDropDown = ({options, placeholder, value, disabled, lable, onChange, isPrint=false}: IProps) => {
    return (
        <div className="selectBox">
           { !isPrint && lable && <Label text="Period" inputLabel />}
            {isPrint ? (
                <p>{datesMapper[value as keyof typeof datesMapper]}</p>
            ):(
                <Select
                    customStyles={{opacity: disabled ? 0.4 : 1}}
                    value={value}
                    onChange={onChange}
                    disabled={disabled}
                    options={options}
                />
            )}
        </div>
    )
};

export default SuperAdminReportsDropDown;
