import { VendorContext } from "contexts/vendor";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import SectionHeader from "./sectionHeader";
import { VendorDetailSections } from ".";
import { VendorActions } from "contexts/reducers/vendor";
import { LabeledInputSkeleton } from "components/common/skeleton";
import Label from "components/common/label";
import Select from "components/common/select";
import UploadPDFModal from "components/common/uploadPDF";
import Button from "components/common/button";
import LargeTextDisplayModal from "components/common/largeTextDisplayModal";
import AgreementHistoryModal from "./AgreementHistoryModal";
import { AuthContext } from "contexts/auth";
import { postAgreementType } from "services/vendor";
import ApiModal from "components/common/apiErrorModal";
import InfoModal from "components/common/infoModal";

const AgreementSection = ({
  notPaid,
  data,
  setActiveEdit,
  activeEdit,
}: any) => {
  const {
    vendorDetails,
    loading,
    uploadVendorCustomAgreement,
    selectingVendorTypeInfo,
    emailAgreement,
  } = useContext(VendorContext);

  const [showModal, setShowModal] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  const [agreementTypes, setAgreementTypes] = useState([
    {
      label: "District Standard",
      checkVal: "District",
      value: 1,
      disabled: false,
      agreement_url: "",
    },
    {
      label: "School Standard",
      checkVal: "School",
      value: 2,
      disabled: false,
      agreement_url: "",
    },
    {
      label: "College Standard",
      checkVal: "College",
      value: 3,
      disabled: false,
      agreement_url: "",
    },
    {
      label: "Other Standard",
      checkVal: "Other",
      value: 4,
      disabled: false,
      agreement_url: "",
    },
    {
      label: "Upload Signed",
      checkVal: "Signed",
      value: 5,
      disabled: false,
      agreement_url: "",
    },
    {
      label: "Upload Custom",
      checkVal: "Custom",
      value: 6,
      disabled: false,
      agreement_url: "",
    },
  ]);

  const { vendor } = vendorDetails;

  const vendorLoading = loading[VendorActions.GET_VENDOR_DETAILS];

  const [showAgreementModal, setShowAgreementModal] = useState(false);

  const [viewAgreementModal, setViewAgreementModal] = useState(false);

  const [agreementHistoryModal, setAgreementHistoryModal] = useState(false);
  const [selectedAgreementType, setSelectedAgreementType] = useState<any>(null);
  const { setVendorDetailsEditing, vendorDetailsEditing } =
    useContext(AuthContext);
  const [error, setError] = useState("");

  const [vendorType, setVendorType] = useState(
    vendor?.prospect_details?.vendor_type || "District"
  );

  const getAgreementType = (type: string): number => {
    if (
      vendor?.selected_agreement?.agreement_url &&
      vendor?.selected_agreement?.is_signed
    ) {
      return 5;
    } else if (
      vendor?.selected_agreement?.agreement_url &&
      !vendor?.selected_agreement?.is_signed
    ) {
      console.log(13);
      return 6;
    } else if (type === "District") {
      return 1;
    } else if (type === "School") {
      return 2;
    } else if (type === "College") {
      return 3;
    } else if (type === "Other") {
      return 4;
    } else {
      return 1;
    }
  };

  const [agreementSelect, setAgreementSelect] = useState(
    getAgreementType(vendorType)
  );

  const [isEdit, setIsEdit] = useState(false);

  const onEdit = () => {
    setIsEdit(true);
    setActiveEdit(VendorDetailSections.AGREEMENT);
  };

  const onClickCross = () => {
    setIsEdit(false);

    setActiveEdit("");
    setVendorDetailsEditing(false);
  };

  const getAgreementText = (type: string): string => {
    const filteredAgreement = vendor?.vendor_agreements?.find(
      (agreement: { agreement_type: string }) => {
        return agreement.agreement_type === type?.toUpperCase();
      }
    );
    return filteredAgreement?.agreement || "";
  };

  const changeCustomAgreement = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setAgreementSelect(+e.target.value);

    const filteredSelect = agreementTypes.find((agreementType) => {
      return agreementType.value === +e.target.value;
    });
    setSelectedAgreementType(filteredSelect);
    selectingVendorTypeInfo(filteredSelect);
    if (+e.target.value === 5 || +e.target.value === 6) {
      setShowAgreementModal(true);
    }
  };

  const uploadingFile = async (file: any) => {
    const res: any = await uploadVendorCustomAgreement({
        vendor_id: vendor?.id,
        file: file,
        image_for: selectedAgreementType.label,
        is_signed:
          selectedAgreementType.label === "Upload Signed" ? true : false,
        vendor_type: vendor?.vendor_type,
    });

    const updatedAgreementTypes = agreementTypes.map((type) => {
      if (type?.value === agreementSelect) {
        type.agreement_url = res?.data?.url || "";
        selectingVendorTypeInfo(type);
      }
      return type;
    });

    setAgreementTypes(updatedAgreementTypes);
    setIsEdit(false);
    setVendorDetailsEditing(false);
    setActiveEdit("");

    // if (res?.data?.url) {
    //   console.log(selectedAgreementType);
    //   const response = await postAgreementType({
    //     vendor_id: vendor?.id,
    //     file: res.data.url,
    //     image_for: selectedAgreementType.label,
    //     is_signed:
    //       selectedAgreementType.label === "Upload Signed" ? true : false,
    //     vendor_type: vendor?.vendor_type,
    //   });
    //   if (response?.message) {
    //     setError(response.message);
    //   } else setIsEdit(false);
    //   setVendorDetailsEditing(false);
    //   setActiveEdit("");
    // }
  };

  const emailAgreementToAdmin = async () => {
    const res = await emailAgreement(vendor.id);
    if (res?._status?.code === 200 && res?.data?.sent) {
      setUserEmail(res?.data?.email);
      setShowModal(true);
    }
  };

  const onClickSubmit = async () => {
    const res = await postAgreementType({
      vendor_id: vendor?.id,
      vendor_type: vendor?.vendor_type,
    });

    if (res?.message) {
      setError(res.message);
    } else setIsEdit(false);
    setVendorDetailsEditing(false);
    setActiveEdit("");
  };

  useEffect(() => {
    setVendorType(vendor?.vendor_type);
    setAgreementSelect(getAgreementType(vendor?.vendor_type));
    const updatedAgreementTypes = agreementTypes.map((type) => {
      if (vendor?.vendor_type === type.checkVal) {
        type.disabled = false;
      } else if (["Signed", "Custom"].includes(type.checkVal)) {
        type.disabled = false;
      } else {
        type.disabled = true;
      }
      return type;
    });

    setAgreementTypes(updatedAgreementTypes);
    const filteredSelect = updatedAgreementTypes.find((agreementType) => {
      return agreementType.value === agreementSelect;
    });
    selectingVendorTypeInfo(filteredSelect);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor?.vendor_type]);

  console.log(selectedAgreementType);

  return (
    <>
      <SectionHeader
        title={VendorDetailSections.AGREEMENT}
        onClick={() => onEdit()}
        isEditMode={isEdit}
        onClickCross={onClickCross}
        onClickSubmit={onClickSubmit}
        editDisabled={
          vendor?.agreement_signed_on
            ? true
            : false ||
              (activeEdit && activeEdit !== VendorDetailSections.AGREEMENT)
        }
        submitDisabled={false}
        isLoading={false}
        isHistory={true}
        isHistoryDisabled={
          vendor?.agreement_history?.length === 0 || vendorDetailsEditing
            ? true
            : false
        }
        onClickHistory={() => setAgreementHistoryModal(true)}
      />
      <div className="admin-info">
        <div className="detail-fields-group agreement-block">
          <div
            className={`detail-group ${isEdit ? "agreement-block-type" : ""}`}
          >
            {vendorLoading ? (
              <LabeledInputSkeleton />
            ) : (
              <>
                {isEdit && (
                  <Label inputLabel={true} text="New Agreement Type" />
                )}
                {isEdit && (
                  <Select
                    name="new_agreement_type"
                    options={agreementTypes}
                    customStyles={{ color: "black" }}
                    value={agreementSelect}
                    onChange={changeCustomAgreement}
                    onBlur={() => {}}
                    isError={false}
                    placeholder={"Select Agreement Type"}
                  />
                )}
                <div className="agreement-block-group">
                  {!isEdit && !vendor?.selected_agreement && (
                    <div>
                      <Label text="New Agreement" />

                      <p className="field-text">
                        {agreementTypes[agreementSelect - 1].label}
                      </p>
                    </div>
                  )}

                  {!isEdit && !vendorLoading && !vendor?.selected_agreement && (
                    <Button
                      buttonName="View"
                      parentClass="agreement-block-view-button"
                      onClick={() => setViewAgreementModal(true)}
                      isActiveDisabled={false}
                      dropShadow={false}
                    />
                  )}

                  {!isEdit && vendor?.selected_agreement && (
                    <>
                      <div>
                        {vendor?.agreement_signed_on && (
                          <Label text="Agreement" />
                        )}

                        {!vendor?.agreement_signed_on && (
                          <Label text="New Agreement" />
                        )}

                        <p className="field-text">
                          {vendor?.selected_agreement
                            ?.vendor_agreement_templates
                            ? agreementTypes[agreementSelect - 1].label
                            : agreementTypes[agreementSelect - 1].label}
                        </p>

                        {vendor?.agreement_signed_on && (
                          <p className="signed-text">
                            Digitally Signed {vendor?.agreement_signed_on}
                          </p>
                        )}
                      </div>
                      <Button
                        buttonName="View"
                        parentClass="agreement-block-view-button"
                        onClick={() =>
                          !vendorDetailsEditing && setViewAgreementModal(true)
                        }
                        isActiveDisabled={false}
                        dropShadow={false}
                        parentStyles={
                          vendorDetailsEditing ? { opacity: 0.4 } : {}
                        }
                      />
                    </>
                  )}
                </div>
              </>
            )}
          </div>
          {showAgreementModal && (
            <UploadPDFModal
              title="Upload Agreement PDF"
              closeModal={() => {
                setShowAgreementModal(false);
              }}
              uploadPDF={uploadingFile}
            ></UploadPDFModal>
          )}
          {viewAgreementModal && (
            <LargeTextDisplayModal
              title={`${agreementTypes[agreementSelect - 1].label} Agreement`}
              buttonText="Email Agreement"
              closeModal={() => {
                setViewAgreementModal(false);
              }}
              description={`${
                !agreementTypes[agreementSelect - 1].agreement_url &&
                (vendor?.selected_agreement?.vendor_agreement_templates ||
                  !vendor?.selected_agreement)
                  ? getAgreementText(vendorType)
                  : vendor?.selected_agreement?.agreement_url ||
                    agreementTypes[agreementSelect - 1].agreement_url
              }`}
              custom={
                !agreementTypes[agreementSelect - 1].agreement_url &&
                (vendor?.selected_agreement?.vendor_agreement_templates ||
                  !vendor?.selected_agreement)
                  ? false
                  : true
              }
              onButtonClick={emailAgreementToAdmin}
            ></LargeTextDisplayModal>
          )}
          {agreementHistoryModal && (
            <AgreementHistoryModal
              closeModal={() => setAgreementHistoryModal(false)}
            ></AgreementHistoryModal>
          )}

          {error ? (
            <ApiModal
              title="Update Agreement Failed"
              description={error}
              closeModal={() => setError("")}
            />
          ) : (
            <></>
          )}

          {showModal && (
            <InfoModal
              title="Email Sent"
              description={`The agreement has been emailed to ${
                userEmail || ""
              }.`}
              closeModal={() => setShowModal(false)}
              onSubmit={() => setShowModal(false)}
              isCancel={false}
              submitButtonName="Great"
            />
          )}
        </div>
      </div>
    </>
  );
};

export default AgreementSection;
