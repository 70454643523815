import { PromotionalResourcesContext } from "contexts/promotionalResources";
import { useContext } from "react"

const ColorBox = ({color, setWidgetColor}:{color: string, setWidgetColor: Function}) => {
    const {widgetColor} = useContext(PromotionalResourcesContext);
    const isSelected = widgetColor === color;
    return (
        <div onClick={() => setWidgetColor(color)} className={isSelected ? 'selected' : ''} style={{backgroundColor: color}}></div>
    )
};

export default ColorBox;
