import { UploadResourceReq } from "interfaces/common";
import axios from "./core/axios";
import config from "config/config";

export const uploadResource = async (body: UploadResourceReq) => {
    const formData = new FormData();
    Object.keys(body).forEach((key: any) => {
        formData.append(key, body[key as keyof typeof body]);
    })
    try {
        const { data: { data } } = await axios.post(`${config.defaults.api_url}/v1/resources/image`, formData);
        return data

    } catch (error) {
        return error;
    }
}