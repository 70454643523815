// import { useState } from "react";

import Button from "components/common/button";
import InfoModal from "components/common/infoModal";
import PDFViewer from "components/common/pdfViewer";
import { OnboardingActions } from "contexts/reducers/onbaording";
import { CHECKLIST } from "interfaces/vendor";
import { useState } from "react";

interface Props {
  onAgree: Function;
  loading: string[];
  selectedStep: string;
  agreement: string;
  emailAgreement: Function;
}

const Agreement = ({
  emailAgreement,
  agreement,
  selectedStep,
  onAgree,
  loading,
}: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [isAgree, setIsAgree] = useState(false);
  const [userEmail, setUserEmail] = useState("");
  const onClickAgree = () => {
    onAgree();
  };

  const onChange = (e: any) => {
    setIsAgree(e.target.checked);
  };

  const onClickEmailAgreement = async () => {
    const res = await emailAgreement();

    if (res?._status?.code === 200 && res?.data?.sent) {
      setUserEmail(res?.data?.email);
      setShowModal(true);
    }
  };

  const isAgreementLoading = loading.includes(
    OnboardingActions.SET_ACCEPT_AGREEMENT
  );
  const emailAgreementLoading = loading.includes(
    OnboardingActions.SEND_EMAIL_AGREEMENT
  );

  const showAgreementInput = selectedStep === CHECKLIST.Agreement;

  return (
    <>
      <div className="agreement">
        <h1 className="agreement_title">Crowd Chow Agreement</h1>
        <div className="agreement_sub-title">
          <p>
            Welcome to Crowd Chow! Scroll through and read the entire agreement.
            If you agree, click the checkbox below and click Agree and Proceed.
          </p>
        </div>
        <Button
          customClass="email-agreement-btn"
          buttonName="Email Agreement"
          customStyles={{
            borderRadius: 20,
            width: "224px",
          }}
          parentStyles={{
            textAlign: "right",
          }}
          dropShadow={false}
          onClick={onClickEmailAgreement}
          loading={emailAgreementLoading}
        />
        <div className="agreement_body">
          {agreement?.indexOf("https://d2x068kgl0kh5q.cloudfront.net") > -1 && (
            <PDFViewer url={agreement}></PDFViewer>
          )}
          {agreement?.indexOf("https://d2x068kgl0kh5q.cloudfront.net") ===
            -1 && (
            <div
              style={{ padding: "10px" }}
              dangerouslySetInnerHTML={{ __html: agreement || "" }}
            />
          )}
        </div>
        {showAgreementInput && (
          <>
            <div className="agreement_form">
              <label className="checkBox">
                I agree to the terms and conditions above
                <input checked={isAgree} onChange={onChange} type="checkbox" />
                <span className="checkmark"></span>
              </label>
            </div>

            <div className="agreement_AgreeButton">
              <button
                type="button"
                className="bttn"
                disabled={!isAgree || isAgreementLoading}
                onClick={onClickAgree}
              >
                Agree and Proceed
              </button>
            </div>
          </>
        )}
      </div>

      {showModal && (
        <InfoModal
          title="Email Sent"
          description={`The agreement has been emailed to ${userEmail || ""}.`}
          closeModal={() => setShowModal(false)}
          onSubmit={() => setShowModal(false)}
          isCancel={false}
          submitButtonName="Great"
        />
      )}
    </>
  );
};

export default Agreement;
