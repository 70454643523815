const DeleteIcon = ({onClick}: any) => {
    return (
        <a href="#/" onClick={onClick} title="Delete">
            <svg className="delete-icon-white" xmlns="http://www.w3.org/2000/svg" width="20" height="26" viewBox="0 0 20 26" fill="none">
                <path d="M17.982 3.81784H13.773V3.05684C13.773 2.44684 13.5307 1.86182 13.0993 1.43049C12.668 0.999157 12.083 0.756836 11.473 0.756836L8.417 0.756836C7.807 0.756836 7.22199 0.999157 6.79065 1.43049C6.35932 1.86182 6.117 2.44684 6.117 3.05684V3.82184H1.913C1.40649 3.82236 0.920827 4.02353 0.562299 4.38131C0.203771 4.73909 0.00158669 5.22433 0 5.73084L0 8.40884C0 8.61173 0.080598 8.80631 0.224063 8.94977C0.367529 9.09324 0.562109 9.17384 0.765 9.17384H1.183L1.844 23.0568C1.87153 23.6462 2.12522 24.2023 2.55229 24.6095C2.97936 25.0166 3.54695 25.2435 4.137 25.2428H15.757C16.347 25.2435 16.9146 25.0166 17.3417 24.6095C17.7688 24.2023 18.0225 23.6462 18.05 23.0568L18.711 9.17484H19.129C19.3319 9.17484 19.5265 9.09424 19.6699 8.95077C19.8134 8.80731 19.894 8.61273 19.894 8.40984V5.73084C19.8935 5.22381 19.6919 4.73769 19.3335 4.37908C18.975 4.02046 18.489 3.81863 17.982 3.81784ZM7.652 3.05684C7.65226 2.85403 7.73295 2.6596 7.87636 2.51619C8.01976 2.37278 8.21419 2.2921 8.417 2.29184H11.478C11.6808 2.2921 11.8752 2.37278 12.0186 2.51619C12.1621 2.6596 12.2427 2.85403 12.243 3.05684V3.82184H7.652V3.05684ZM1.53 5.73084C1.53 5.62926 1.57035 5.53184 1.64218 5.46001C1.714 5.38819 1.81142 5.34784 1.913 5.34784H17.982C18.0836 5.34784 18.181 5.38819 18.2528 5.46001C18.3246 5.53184 18.365 5.62926 18.365 5.73084V7.64384H1.53V5.73084ZM16.522 22.9838C16.513 23.1803 16.4285 23.3658 16.2862 23.5016C16.1439 23.6374 15.9547 23.713 15.758 23.7128H4.137C3.9403 23.713 3.75109 23.6374 3.60878 23.5016C3.46646 23.3658 3.38201 23.1803 3.373 22.9838L2.715 9.17384H17.179L16.522 22.9838Z" fill="white"/>
                <path d="M9.94371 22.1821C10.0442 22.1821 10.1437 22.1623 10.2365 22.1239C10.3293 22.0854 10.4136 22.0291 10.4846 21.958C10.5557 21.887 10.612 21.8027 10.6505 21.7099C10.6889 21.617 10.7087 21.5176 10.7087 21.4171V11.4691C10.7087 11.3686 10.6889 11.2692 10.6505 11.1763C10.612 11.0835 10.5557 10.9992 10.4846 10.9282C10.4136 10.8571 10.3293 10.8008 10.2365 10.7623C10.1437 10.7239 10.0442 10.7041 9.94371 10.7041C9.84325 10.7041 9.74377 10.7239 9.65096 10.7623C9.55814 10.8008 9.47381 10.8571 9.40277 10.9282C9.33174 10.9992 9.27539 11.0835 9.23694 11.1763C9.1985 11.2692 9.17871 11.3686 9.17871 11.4691V21.4161C9.17858 21.5166 9.19827 21.6162 9.23666 21.7092C9.27504 21.8021 9.33137 21.8865 9.40242 21.9577C9.47347 22.0288 9.55785 22.0853 9.65073 22.1238C9.74361 22.1623 9.84317 22.1821 9.94371 22.1821Z" fill="white"/>
                <path d="M13.7728 22.1821C13.8733 22.1821 13.9728 22.1623 14.0656 22.1239C14.1584 22.0854 14.2427 22.0291 14.3137 21.958C14.3848 21.887 14.4411 21.8027 14.4796 21.7099C14.518 21.617 14.5378 21.5176 14.5378 21.4171V11.4691C14.5378 11.2662 14.4572 11.0716 14.3137 10.9282C14.1703 10.7847 13.9757 10.7041 13.7728 10.7041C13.5699 10.7041 13.3753 10.7847 13.2319 10.9282C13.0884 11.0716 13.0078 11.2662 13.0078 11.4691V21.4161C13.0077 21.5166 13.0274 21.6162 13.0658 21.7092C13.1041 21.8021 13.1605 21.8865 13.2315 21.9577C13.3026 22.0288 13.3869 22.0853 13.4798 22.1238C13.5727 22.1623 13.6723 22.1821 13.7728 22.1821Z" fill="white"/>
                <path d="M6.12535 22.1821C6.22581 22.1821 6.32529 22.1623 6.41811 22.1239C6.51092 22.0854 6.59525 22.0291 6.66629 21.958C6.73733 21.887 6.79368 21.8027 6.83212 21.7099C6.87057 21.617 6.89035 21.5176 6.89035 21.4171V11.4691C6.89035 11.3686 6.87057 11.2692 6.83212 11.1763C6.79368 11.0835 6.73733 10.9992 6.66629 10.9282C6.59525 10.8571 6.51092 10.8008 6.41811 10.7623C6.32529 10.7239 6.22581 10.7041 6.12535 10.7041C6.02489 10.7041 5.92541 10.7239 5.8326 10.7623C5.73979 10.8008 5.65545 10.8571 5.58442 10.9282C5.51338 10.9992 5.45703 11.0835 5.41858 11.1763C5.38014 11.2692 5.36035 11.3686 5.36035 11.4691V21.4161C5.36022 21.5166 5.37991 21.6162 5.4183 21.7092C5.45668 21.8021 5.51301 21.8865 5.58406 21.9577C5.65511 22.0288 5.73949 22.0853 5.83237 22.1238C5.92525 22.1623 6.02481 22.1821 6.12535 22.1821Z" fill="white"/>
            </svg>
        </a>
    )
};

export default DeleteIcon;

export const DeleteIconBlack = ({onClick, disabled}: any) => {
    return (
        <a onClick={() => !disabled && onClick()} href="#/" title="Delete">
            <svg className={`${disabled ? 'disabled' : 'cursor-pointer'}`} xmlns="http://www.w3.org/2000/svg" width="20" height="25" viewBox="0 0 20 25" fill="none">
                <path d="M17.982 3.061H13.773V2.3C13.773 1.69 13.5307 1.10499 13.0993 0.673654C12.668 0.242321 12.083 0 11.473 0L8.417 0C7.807 0 7.22199 0.242321 6.79065 0.673654C6.35932 1.10499 6.117 1.69 6.117 2.3V3.065H1.913C1.40649 3.06553 0.920827 3.26669 0.562299 3.62447C0.203771 3.98225 0.00158669 4.4675 0 4.974L0 7.652C0 7.85489 0.080598 8.04947 0.224063 8.19294C0.367529 8.3364 0.562109 8.417 0.765 8.417H1.183L1.844 22.3C1.87153 22.8894 2.12522 23.4455 2.55229 23.8527C2.97936 24.2598 3.54695 24.4866 4.137 24.486H15.757C16.347 24.4866 16.9146 24.2598 17.3417 23.8527C17.7688 23.4455 18.0225 22.8894 18.05 22.3L18.711 8.418H19.129C19.3319 8.418 19.5265 8.3374 19.6699 8.19394C19.8134 8.05047 19.894 7.85589 19.894 7.653V4.974C19.8935 4.46698 19.6919 3.98086 19.3335 3.62224C18.975 3.26363 18.489 3.06179 17.982 3.061ZM7.652 2.3C7.65226 2.09719 7.73295 1.90276 7.87636 1.75936C8.01976 1.61595 8.21419 1.53526 8.417 1.535H11.478C11.6808 1.53526 11.8752 1.61595 12.0186 1.75936C12.1621 1.90276 12.2427 2.09719 12.243 2.3V3.065H7.652V2.3ZM1.53 4.974C1.53 4.87242 1.57035 4.775 1.64218 4.70318C1.714 4.63135 1.81142 4.591 1.913 4.591H17.982C18.0836 4.591 18.181 4.63135 18.2528 4.70318C18.3246 4.775 18.365 4.87242 18.365 4.974V6.887H1.53V4.974ZM16.522 22.227C16.513 22.4235 16.4285 22.6089 16.2862 22.7447C16.1439 22.8805 15.9547 22.9562 15.758 22.956H4.137C3.9403 22.9562 3.75109 22.8805 3.60878 22.7447C3.46646 22.6089 3.38201 22.4235 3.373 22.227L2.715 8.417H17.179L16.522 22.227Z" fill="#3C3C3C"/>
                <path d="M9.94371 21.4253C10.0442 21.4253 10.1437 21.4055 10.2365 21.367C10.3293 21.3286 10.4136 21.2722 10.4846 21.2012C10.5557 21.1302 10.612 21.0458 10.6505 20.953C10.6889 20.8602 10.7087 20.7607 10.7087 20.6603V10.7123C10.7087 10.6118 10.6889 10.5123 10.6505 10.4195C10.612 10.3267 10.5557 10.2424 10.4846 10.1713C10.4136 10.1003 10.3293 10.0439 10.2365 10.0055C10.1437 9.96705 10.0442 9.94727 9.94371 9.94727C9.84325 9.94727 9.74377 9.96705 9.65096 10.0055C9.55814 10.0439 9.47381 10.1003 9.40277 10.1713C9.33174 10.2424 9.27539 10.3267 9.23694 10.4195C9.1985 10.5123 9.17871 10.6118 9.17871 10.7123V20.6593C9.17858 20.7598 9.19827 20.8594 9.23666 20.9523C9.27504 21.0453 9.33137 21.1297 9.40242 21.2008C9.47347 21.272 9.55785 21.3284 9.65073 21.3669C9.74361 21.4054 9.84317 21.4253 9.94371 21.4253Z" fill="#3C3C3C"/>
                <path d="M13.7728 21.4253C13.8733 21.4253 13.9728 21.4055 14.0656 21.367C14.1584 21.3286 14.2427 21.2722 14.3137 21.2012C14.3848 21.1302 14.4411 21.0458 14.4796 20.953C14.518 20.8602 14.5378 20.7607 14.5378 20.6603V10.7123C14.5378 10.5094 14.4572 10.3148 14.3137 10.1713C14.1703 10.0279 13.9757 9.94727 13.7728 9.94727C13.5699 9.94727 13.3753 10.0279 13.2319 10.1713C13.0884 10.3148 13.0078 10.5094 13.0078 10.7123V20.6593C13.0077 20.7598 13.0274 20.8594 13.0658 20.9523C13.1041 21.0453 13.1605 21.1297 13.2315 21.2008C13.3026 21.272 13.3869 21.3284 13.4798 21.3669C13.5727 21.4054 13.6723 21.4253 13.7728 21.4253Z" fill="#3C3C3C"/>
                <path d="M6.12486 21.4253C6.22533 21.4253 6.3248 21.4055 6.41762 21.367C6.51043 21.3286 6.59476 21.2722 6.6658 21.2012C6.73684 21.1302 6.79319 21.0458 6.83163 20.953C6.87008 20.8602 6.88986 20.7607 6.88986 20.6603V10.7123C6.88986 10.6118 6.87008 10.5123 6.83163 10.4195C6.79319 10.3267 6.73684 10.2424 6.6658 10.1713C6.59476 10.1003 6.51043 10.0439 6.41762 10.0055C6.3248 9.96705 6.22533 9.94727 6.12486 9.94727C6.0244 9.94727 5.92493 9.96705 5.83211 10.0055C5.7393 10.0439 5.65496 10.1003 5.58393 10.1713C5.51289 10.2424 5.45654 10.3267 5.4181 10.4195C5.37965 10.5123 5.35986 10.6118 5.35986 10.7123V20.6593C5.35973 20.7598 5.37942 20.8594 5.41781 20.9523C5.45619 21.0453 5.51252 21.1297 5.58357 21.2008C5.65462 21.272 5.739 21.3284 5.83188 21.3669C5.92476 21.4054 6.02432 21.4253 6.12486 21.4253Z" fill="#3C3C3C"/>
            </svg>
        </a>
    )
}
