import { ReportContext } from "contexts/report";
import React, { useContext } from "react";
import moment from "moment";
import EventSalesPrint from "../eventSalesPrint";
import EventItemsPrint from "../eventItemsPrint";

const Print = React.forwardRef((props: any, ref: any) => {
    const { eventDetails, selectedVenue } = useContext(ReportContext);
    const { event, event_sales } = eventDetails;
    
    return (
        <div ref={ref} className="venueReports">
            <div className="d-flex reportHeaderAlignment">
                <p className="reportsTopHeader">Crowd Chow Events</p>
            </div>
            <div className="d-flex align-items-center">
                <p className="reportsLabelHeader">Venue:</p>
                <p className="reportsText">{selectedVenue?.name || ''}</p>
            </div>
            <div className="d-flex align-items-center">
                <p className="reportsLabelHeader">Event:</p>
                <p className="reportsText">{event?.name}</p>
            </div>
            <div className="d-flex align-items-center">
                <p className="reportsLabelHeader">Date:</p>
                <p className="reportsText">
                    {event?.date ? `${moment(event?.date).format("M/D/YYYY")} ${moment(`${event?.date} ${event?.start_time}`).format('h:mm A')} - ${moment(`${event?.date} ${event?.end_time}`).format("h:mm A")}` : ''}
                </p>
            </div>
            <div className="divider"></div>
            <EventSalesPrint 
                events_sales={event_sales}
            />
            <div className="divider"></div>
            <EventItemsPrint 
                printFormattedItems={eventDetails?.items}
            />
        </div>

    )
});

export default Print;
