import { VendorContextProvider } from "interfaces/vendor"
import { capitalizeFirstLetter } from "util/capitalizeFirstLetter"

export enum VendorActions {
    SET_VENDOR = 'SET_VENDOR',
    SET_VENDOR_LOADING = 'SET_VENDOR_LOADING',
    RESET_VENDOR_LOADING = 'RESET_VENDOR_LOADING',
    SET_ACCEPT_AGREEMENT = 'SET_ACCEPT_AGREEMENT',
    SET_SELECTED_STEP = 'SET_SELECTED_STEP',
    SET_VENUES = 'SET_VENUES',
    SET_RESOURCE_LIBRARY = 'SET_RESOURCE_LIBRARY',
    SET_FOOD_IMAGE_LIBRARY = 'SET_FOOD_IMAGE_LIBRARY',
    SET_MENU = 'SET_MENU',
    SET_SAVE_AND_SUBMIT = 'SET_SAVE_AND_SUBMIT',
    SEND_WELCOME_EMAIL = 'SEND_WELCOME_EMAIL',
    UPDATE_VENDOR_DETAILS = 'UPDATE_VENDOR_DETAILS',
    ADD_VENDOR = 'ADD_VENDOR',
    GET_VENDORS = 'GET_VENDORS',
    DELETE_VENDOR = 'DELETE_VENDOR',
    RESET_VENDOR = 'RESET_VENDOR',
    GET_VENUES = 'GET_VENUES',
    DELETE_VENUE = 'DELETE_VENUE',
    GET_ADMINS = 'GET_ADMINS',
    ADD_ADMIN = 'ADD_ADMIN',
    DELETE_ADMIN = 'DELETE_ADMIN',
    EDIT_ADMIN = 'EDIT_ADMIN',
    GET_VENDOR_DETAILS = 'GET_VENDOR_DETAILS',
    ADD_VENUE = 'ADD_VENUE',
    GET_LEADS = 'GET_LEADS',
    ADD_LEAD = 'ADD_LEAD',
    DELETE_LEAD = 'DELETE_LEAD',
    UPDATE_LEAD = 'UPDATE_LEAD',
    SEND_SALES_LEAD = 'SEND_SALES_LEAD',
    SET_LEAD = 'SET_LEAD',
    APPROVE_VEDNOR = 'APPROVE_VEDNOR',
    SET_QUERY_PARAMS = 'SET_QUERY_PARAMS',
    ADD_NOTE_LEAD = 'ADD_NOTE_LEAD',
    GET_VENUE_DETAILS = 'GET_VENUE_DETAILS',
    DELETE_NOTE = 'DELETE_NOTE',
    SET_BILLING_HISTORY = 'SET_BILLING_HISTORY',
    SEND_INVOICE = 'SEND_INVOICE',
    UPDATE_SUBSCRIPTION_STATUS = 'UPDATE_SUBSCRIPTION_STATUS',
    ADD_VENDOR_NOTE = 'ADD_VENDOR_NOTE',
    DELETE_VENDOR_NOTE = 'DELETE_VENDOR_NOTE',
    SET_INVOICE_HISTORY = 'SET_INVOICE_HISTORY',
    GET_MORE_VENDORS = 'GET_MORE_VENDORS',
    GET_LEADS_MORE = 'GET_LEADS_MORE',
    GET_MORE_VENUES = 'GET_MORE_VENUES',
    GET_MORE_ADMINS = 'GET_MORE_ADMINS',
    UPLOAD_AGREEMENT_CUSTOM = 'UPLOAD_AGREEMENT_CUSTOM',
    EMPTY_VENDOR_LIST = "EMPTY_VENDOR_LIST",
    GET_VENDOR_ONBOARDING_STATUS = 'GET_VENDOR_ONBOARDING_STATUS',
    SEND_EMAIL_AGREEMENT = "SEND_AGREEMENT_VIA_EMAIL",
}


export const VendorReducer = (state: VendorContextProvider, action: any) => {
    const { type, payload } = action
    switch (type) {
        case VendorActions.SET_QUERY_PARAMS:
            return {
                ...state,
                queryParams: payload
            }
        case VendorActions.ADD_VENUE:
            return {
                ...state,
                venueList: {
                    ...state.venueList,
                    venues: [payload.vendor, ...state.venueList.venues],
                    _count: state.venueList._count + 1
                }
            }
        // case VendorActions.UPDATE_VENDOR_DETAILS:
        // case VendorActions.UPDATE_SUBSCRIPTION_STATUS:
        // case VendorActions.ADD_VENDOR_NOTE:
        //     return {
        //         ...state,
        //         vendorDetails: payload
        //     }
        case VendorActions.SET_VENDOR_LOADING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [payload]: true
                }
            }
        case VendorActions.RESET_VENDOR_LOADING:
            const newLoadingState = {
                ...state.loading
            }
            delete newLoadingState[payload];
            return {
                ...state,
                loading: newLoadingState
            }
        case VendorActions.GET_VENDORS:
            const { offset, ...restPayload } = payload;
            return {
                ...state,
                vendorList: {
                    ...restPayload,
                    vendors: offset ? [...state.vendorList.vendors, ...restPayload.vendors] : restPayload.vendors,

                }
            }
        case VendorActions.ADD_VENDOR:
            return {
                ...state,
                vendorList: {
                    ...state.vendorList,
                    vendors: [payload, ...state.vendorList.vendors],
                    _count: state.vendorList._count + 1
                }
            }
        case VendorActions.DELETE_VENDOR:
            return {
                ...state,
                vendorList: {
                    ...state.vendorList,
                    vendors: state.vendorList.vendors.filter((item: any) => item.id !== payload),
                    _count: state.vendorList._count - 1
                }
            }
        case VendorActions.RESET_VENDOR:
            return {
                ...state,
                vendorList: {
                    ...state.vendorList,
                }
            }
        case VendorActions.GET_VENUES: {
            const { offset, ...restPayload } = payload;
            return {
                ...state,
                venueList: {
                    ...restPayload,
                    venues: offset ? [...state.venueList.venues, ...restPayload.venues] : restPayload.venues
                }
            }
        }

        case VendorActions.EMPTY_VENDOR_LIST: {
            return {
                ...state,
                vendorList: payload
            }
        }
        case VendorActions.DELETE_VENUE:
            return {
                ...state,
                venueList: {
                    ...state.venueList,
                    venues: state.venueList.venues.filter((item: any) => item.id !== payload),
                    _count: state.venueList._count - 1
                }
            }
        case VendorActions.ADD_ADMIN:
            return {
                ...state,
                adminList: payload
            }
        case VendorActions.GET_ADMINS: {

            const { offset, ...restPayload } = payload;
            return {
                ...state,
                adminList: {
                    ...restPayload,
                    admins: offset ? [...state.adminList.admins, ...restPayload.admins] : restPayload.admins
                }
            }
        }
        case VendorActions.DELETE_ADMIN:
            return {
                ...state,
                adminList: {
                    ...state.adminList,
                    admins: state.adminList.admins.filter((item: any) => item.id !== payload),
                    _count: state.adminList._count - 1
                }
            }
        case VendorActions.EDIT_ADMIN:
            return {
                ...state,
                adminList: {
                    ...state.adminList,
                    admins: state.adminList.admins.map((item: any) => item.id === payload.admin_id ? ({ ...item, ...payload }) : item)
                }
            }
        case VendorActions.UPDATE_VENDOR_DETAILS:
        case VendorActions.UPDATE_SUBSCRIPTION_STATUS:
        case VendorActions.ADD_VENDOR_NOTE:
        case VendorActions.SEND_WELCOME_EMAIL:
        case VendorActions.GET_VENDOR_DETAILS:
            return {
                ...state,
                vendorDetails: {
                    ...payload,
                    vendor: {
                        ...payload.vendor,
                        vendor_types_formatted: payload.vendor.vendor_types.map((item: string, index: number) => {
                            return {
                                label: item,
                                value: item
                            }
                        }),
                        subscription: {
                            ...payload?.vendor?.subscription,
                            billing_statuses_formatted: payload?.vendor?.subscription?.billing_statuses?.map((item: any) => {
                                const formattedStatus = capitalizeFirstLetter(item);
                                return {
                                    label: formattedStatus,
                                    value: item
                                }
                            })
                        }
                    }
                }
            }
        case VendorActions.GET_LEADS:
            return {
                ...state,
                leadList: {
                    ...payload,
                    leads: payload.offset ? [...state.leadList.leads, ...payload.leads] : payload.leads,

                }
            }
        case VendorActions.ADD_LEAD:
            return {
                ...state,
                leadList: payload
            }
        case VendorActions.DELETE_LEAD:
            return {
                ...state,
                leadList: {
                    ...state.leadList,
                    leads: state.leadList.leads.filter((item: any) => item.id !== payload),
                    _count: state.leadList._count - 1
                }
            }
        case VendorActions.UPDATE_LEAD:
            return {
                ...state,
                leadList: payload
            }
        case VendorActions.SEND_SALES_LEAD:
            return {
                ...state,
                leadList: payload
            }
        case VendorActions.SET_LEAD:
            return {
                ...state,
                leadList: {
                    ...state.leadList,
                    leads: state.leadList.leads.map((item: any) => item.id === payload.id ? payload : item)
                }
            }
        case VendorActions.ADD_NOTE_LEAD:
            return {
                ...state,
                leadList: payload
            }
        case VendorActions.GET_VENUE_DETAILS:
            return {
                ...state,
                venueDetails: payload
            }
        case VendorActions.SET_BILLING_HISTORY:
            return {
                ...state,
                billingHistory: payload
            }
        case VendorActions.SET_INVOICE_HISTORY:
            return {
                ...state,
                invoiceHistory: payload
            }
        case VendorActions.DELETE_VENDOR_NOTE:
            return {
                ...state,
                vendorDetails: {
                    ...state.vendorDetails,
                    vendor: {
                        ...state.vendorDetails.vendor,
                        notes: state.vendorDetails.vendor.notes.filter((note: any) => note.id !== payload)
                    }
                }
            }
        case VendorActions.DELETE_NOTE:
            return {
                ...state,
                leadList: {
                    ...state.leadList,
                    leads: state.leadList.leads.map((item: any) => {
                        if (item.id === payload.lead_id) {
                            return {
                                ...item,
                                notes: item.notes.filter((note: any) => note.id !== payload.note_id)
                            }
                        } else {
                            return item
                        }
                    })
                }
            }
        default:
            return state
    }
}
