import { TableData, TableRow } from "components/common/table";
import { useContext, useState } from "react";
import Collapse from "@kunukn/react-collapse";
import moment from "moment";
import { VendorContext } from "contexts/vendor";
import InfoModal from "components/common/infoModal";
import { VendorActions } from "contexts/reducers/vendor";
import AccordianBody from "./accordianBody";
import AddLeadModal from "./addModal";
import EditIcon from "components/common/editIcon";
import DeleteIcon from "components/common/deleteIcon";

const colorMapper = {
  NEW: "#E94929",
  SALES_KIT_SENT: "#B712C9",
  VENDOR_CREATED: "#106CD0",
  ONBOARDING: "#FDC13D",
  CONVERTED: "#27C1C9",
};

const statusMapper = {
  NEW: "New",
  SALES_KIT_SENT: "Sales Kit Sent",
  VENDOR_CREATED: "Vendor Created",
  ONBOARDING: "Onboarding",
  CONVERTED: "Converted",
};

const MODAL_INIT_STATE = {
  type: "",
  data: null!,
};

const Accordian = ({ data, flash, open, setSuccess }: any) => {
  const {
    deleteLead,
    loading,
    setSalesKitSuccessModal,
    setLeadsClickedList,
    leadsClickedList,
  } = useContext(VendorContext);

  const [modal, setModal] = useState<any>(MODAL_INIT_STATE);
  // const [open, setOpen] = useState(false);
  const {
    id,
    vendor_name = "",
    venues = 0,
    created = "",
    lead_type = "",
    status = "",
  } = data || {};

  const onClickDelete = (data: any) => {
    setModal({
      type: "delete",
      data,
    });
  };

  const onDelete = async () => {
    const res = await deleteLead(id);

    if (res?.status === 204) {
      setModal(MODAL_INIT_STATE);
    }
  };

  const onSendSalesKit = () => {
    setSalesKitSuccessModal(true);
  };

  const rowClickHandler = () => {
    const added = leadsClickedList.indexOf(data.id);
    let updatedLeadsList;

    if (added === -1) {
      updatedLeadsList = [...leadsClickedList, data.id];
    } else {
      updatedLeadsList = leadsClickedList.filter((id) => id !== data.id);
    }

    setLeadsClickedList(updatedLeadsList);
  };

  const deleteLoading = loading[VendorActions.DELETE_LEAD];
  const statusColor = colorMapper[status as keyof typeof colorMapper];
  const formattedStatus =
    statusMapper[status as keyof typeof statusMapper] || "";
  return (
    <>
      <div className="accordian-table-row">
        <TableRow
          flash={flash}
          onClickRow={rowClickHandler}
          cursorPointer={true}
        >
          <TableData onClickData={() => {}} customClass="name">
            {statusColor && (
              <svg
                style={{ marginRight: "12px" }}
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="21"
                viewBox="0 0 21 21"
                fill="none"
              >
                <circle cx="10.5" cy="10.5" r="10.5" fill={statusColor} />
              </svg>
            )}
            <span>{vendor_name}</span>
          </TableData>
          <TableData onClickData={() => {}} customClass="venue">
            {venues}
          </TableData>

          <TableData onClickData={() => {}} customClass="scheduled-events">
            {created ? moment(created).format("M/D/YY") : "N/A"}
          </TableData>
          <TableData onClickData={() => {}} customClass="last-event">
            {lead_type.toLowerCase()}
          </TableData>
          <TableData onClickData={() => {}} customClass="status">
            {formattedStatus}
          </TableData>
          <TableData
            isTooltip={false}
            onClickData={() => {}}
            customClass="option"
          >
            <EditIcon
              onClick={(e: any) => {
                e.stopPropagation();
                setModal({
                  data,
                  type: "edit",
                });
              }}
            />
            <DeleteIcon
              onClick={(e: any) => {
                e.stopPropagation();
                onClickDelete(data);
              }}
            />
          </TableData>

          <TableData isTooltip={false} customClass="accordian-dropdown">
            <div>
              <svg
                className={open ? "rotate-dropdown-icon" : ""}
                xmlns="http://www.w3.org/2000/svg"
                width="41"
                height="41"
                viewBox="0 0 41 41"
                fill="none"
              >
                <mask
                  id="mask0_5082_176"
                  style={{ maskType: "luminance" }}
                  maskUnits="userSpaceOnUse"
                  x="0"
                  y="-1"
                  width="41"
                  height="42"
                >
                  <path
                    d="M4.82373e-07 -0.00031662L0 40.4507L40.451 40.4507L40.451 -0.000316137L4.82373e-07 -0.00031662Z"
                    fill="white"
                  />
                </mask>
                <g mask="url(#mask0_5082_176)">
                  <g filter="url(#filter0_d_5082_176)">
                    <path
                      d="M12.5028 14.6396L9.63379 17.5086L20.2268 28.1016L30.8198 17.5086L27.9508 14.6396L20.2268 22.3636L12.5028 14.6396Z"
                      fill="white"
                    />
                  </g>
                </g>
                <defs>
                  <filter
                    id="filter0_d_5082_176"
                    x="6.63379"
                    y="14.6396"
                    width="27.1855"
                    height="19.4619"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="3" />
                    <feGaussianBlur stdDeviation="1.5" />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.557 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="BackgroundImageFix"
                      result="effect1_dropShadow_5082_176"
                    />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="effect1_dropShadow_5082_176"
                      result="shape"
                    />
                  </filter>
                </defs>
              </svg>
            </div>
          </TableData>
        </TableRow>
        <Collapse className={!open ? "accordian-close" : ""} isOpen={open}>
          <AccordianBody data={data} onSendSalesKit={onSendSalesKit} />
        </Collapse>
      </div>
      {modal.type === "delete" && (
        <InfoModal
          title="Delete Lead"
          description={`Are you sure you you want to delete lead${
            modal.data?.district_school
              ? " " + modal.data?.district_school + "?"
              : "?"
          }`}
          onSubmit={onDelete}
          closeModal={() => setModal(MODAL_INIT_STATE)}
          submitButtonName="Yes"
          submitLoading={deleteLoading}
          width={410}
        />
      )}

      {modal.type === "edit" && (
        <AddLeadModal
          closeModal={() => setModal(MODAL_INIT_STATE)}
          title="Edit Lead"
          data={modal.data}
          isEdit
          setSuccess = {setSuccess}
        />
      )}
    </>
  );
};

export default Accordian;
