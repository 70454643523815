import QRCodeStyling from "qr-code-styling";
import flaticon from 'assets/images/qr-center-rotated.png';

export const generateQrCode = (data: string, width?: number, height?: number) => {
    const qrCode = new QRCodeStyling({
        width: width,
        height: height,
        data: data,
        image: flaticon,
        margin: 3,
        dotsOptions: {
            color: "#3c3c3c",
            type: "rounded"
        },
        cornersSquareOptions :{
            type: "rounded",
            color: '#3c3c3c',
    
        },
        imageOptions: {
            crossOrigin: "anonymous",
            margin: 4,
            size: 0.2,
            
        },
        qrOptions:{
            typeNumber:"0",
            mode:"Byte",
            errorCorrectionLevel:"Q"
        }
    });

    return qrCode;
}

export const getPdfData = (type: string) => {
    let pdfData: any = {
        
    };
    switch(type){
        case '4x6':
            pdfData = {
                ...pdfData,
                url: '/6x4_Banner.pdf',
                x: 1224,
                y: 3372,
                height: 1238,
                width: 1238,
                scale: 0.8
            }
            break;
        case '6x4':
            pdfData = {
                ...pdfData,
                url: '/4x6_Banner.pdf',
                x: 502,
                y: 1715,
                height: 1238,
                width: 1238,
                scale: 0.8
            }
            break;
        case '2_3_GENERAL':
                pdfData = {
                    ...pdfData,
                    url: '/2x3_General_Sign_new.pdf',
                    x: 2600,  
                    y: 7000,
                    // height: 1983,
                    // width: 1983,
                    height: 3966,
                    width: 3966,
                    scale: 0.5
                }
                break;
        case '11_7_CHOW_LANE':
            pdfData = {
                ...pdfData,
                url: '/11x7_Chow_Lane.pdf',
                x: 320,  
                y: 636,
                height: 3132,
                width: 3132,
                scale: 0.25
            }
            break;
        case '8_5_11_COUNTER':
            pdfData = {
                ...pdfData,
                url: '/8.5x11_Counter.pdf',
                x: 730,
                y: 610,
                height: 3273,
                width: 3273,
                scale: 0.33
            }
            break;
        case '7_11_CHOW_LANE':
            pdfData = {
                ...pdfData,
                url: '/7x11_Chow_Lane.pdf',
                x: 580,
                y: 1300,
                height: 2853,
                width: 2853,
                scale: 0.33
            }
            break;
        default:
            pdfData = null
    }

    return pdfData;
}