import React from 'react';
import DatePicker from 'react-datepicker';


function convertUTCToLocalDate(date: any) {
  if (!date) {
    return date
  }
  date = new Date(date)
  date = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate())
  return date
}

// function convertLocalToUTCDate(date: any) {
//   if (!date) {
//     return date
//   }
//   date = new Date(date)
//   date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()))
//   return date
// }

export default function UTCDatePicker({
  startDate ,
  endDate,
  selected,
  onChange,
  maxDate,
  ...props
}: any) {
  return (
    <DatePicker
      startDate={convertUTCToLocalDate(startDate)}
     //endDate={convertUTCToLocalDate(endDate)}
      selected={convertUTCToLocalDate(selected)}
      onChange={date => onChange(date)}
      maxDate={convertUTCToLocalDate(maxDate)}
      dateFormat={'M/d/YY'}
      onFocus={(e) => e.target.readOnly = true}
      {...props}
    />
  )
}