import CheckMarkButton from "components/common/checkMarkButton";

const Actions = ({disableSubmit, onClickCross, onClickSubmit, submitDisabled, isLoading, setSuccess}: any) => {
    
    return (
        <div className="form-action-btns">
            <span className="action-item edit">
                <svg className="cursor-pointer disabled" width="23" height="22" viewBox="0 0 23 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M13.8512 3.94644L18.2582 8.35344L7.10324 19.5084L2.70324 15.1014L13.8512 3.94644ZM21.6022 2.88344L19.6372 0.917441C19.2717 0.552575 18.7762 0.347656 18.2597 0.347656C17.7432 0.347656 17.2478 0.552575 16.8822 0.917441L14.9992 2.80044L19.4062 7.20744L21.6062 5.00744C21.7457 4.86784 21.8562 4.70211 21.9315 4.51973C22.0067 4.33735 22.0453 4.14191 22.0449 3.94461C22.0445 3.74731 22.0053 3.55202 21.9293 3.36993C21.8533 3.18783 21.7422 3.02251 21.6022 2.88344ZM0.392239 21.2964C0.372583 21.3798 0.374691 21.4669 0.398361 21.5493C0.422031 21.6316 0.466468 21.7065 0.527409 21.7667C0.58835 21.827 0.66375 21.8705 0.746373 21.8933C0.828996 21.916 0.91607 21.9171 0.999239 21.8964L5.91024 20.7054L1.51024 16.2984L0.392239 21.2964Z" fill="white"/>
                </svg>
            </span>
            <span className="action-item cancel">
                <svg className={`cursor-pointer ${isLoading ? 'disabled' : ''}`} onClick={onClickCross} width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g filter="url(#filter0_d_3552_3896)">
                    <path d="M15.4971 30.8525C24.0575 30.8525 30.9971 24.1368 30.9971 15.8525C30.9971 7.56827 24.0575 0.852539 15.4971 0.852539C6.93666 0.852539 -0.00292969 7.56827 -0.00292969 15.8525C-0.00292969 24.1368 6.93666 30.8525 15.4971 30.8525Z" fill="#E94929"/>
                    </g>
                    <path d="M10.5205 20.8539L21.2015 10.1729" stroke="white" strokeWidth="3.5"/>
                    <path d="M10.5202 10.1729L21.2012 20.8539" stroke="white" strokeWidth="3.5"/>
                    <defs>
                    <filter id="filter0_d_3552_3896" x="-0.00292969" y="0.852539" width="33" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dx="2" dy="2"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3552_3896"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3552_3896" result="shape"/>
                    </filter>
                    </defs>
                </svg>
            </span>
            <span className={`action-item`}>
                <CheckMarkButton 
                    onClick={onClickSubmit}
                    disabled={disableSubmit || isLoading}
                    activeDisabled={submitDisabled}
                    setSuccess = {setSuccess}
                />
            </span>

            <svg className="action-border" xmlns="http://www.w3.org/2000/svg" width="138" height="57" viewBox="0 0 138 57" fill="none">
                <g filter="url(#filter0_d_3552_3887)">
                    <path d="M110.003 4.85059H28.0029C16.405 4.85059 7.00293 14.2526 7.00293 25.8506C7.00293 37.4486 16.405 46.8506 28.0029 46.8506H110.003C121.601 46.8506 131.003 37.4486 131.003 25.8506C131.003 14.2526 121.601 4.85059 110.003 4.85059Z" stroke="white" strokeWidth="2"/>
                </g>
                <defs>
                    <filter id="filter0_d_3552_3887" x="0.00292969" y="0.850586" width="138" height="56" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dy="3"/>
                    <feGaussianBlur stdDeviation="3"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.557 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3552_3887"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3552_3887" result="shape"/>
                    </filter>
                    <filter id="filter1_d_3552_3887" x="92.0029" y="10.8525" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dx="2" dy="2"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3552_3887"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3552_3887" result="shape"/>
                    </filter>
                    <filter id="filter2_d_3552_3887" x="45.9971" y="10.8525" width="33" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dx="2" dy="2"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3552_3887"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3552_3887" result="shape"/>
                    </filter>
                </defs>
            </svg>
        </div>
    )
};

export default Actions;
