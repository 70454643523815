import ApiModal from "components/common/apiErrorModal";
import CheckMarkButton from "components/common/checkMarkButton";
import CrossButton from "components/common/crossButton";
import Input from "components/common/input";
import Label from "components/common/label";
import { Modal } from "components/common/modal";
import { useFormik } from "formik";
import { useIsDisabled } from "hooks/useIsDisabled";
import { useState } from "react";
import { deleteObjectProp } from "util/deleteObjectProp";
import { addNoteSchema } from "util/validationSchema/addNoteSchema";

const initialValues = {
    subject: '',
    note: ''
}

interface IProps {
    closeModal: Function;
    addNote: Function;
    isLoading: boolean;
    title?: string;
}

const AddNotesModal = ({closeModal, addNote, isLoading, title}: IProps) => {
    const [error, setError] = useState('')

    const formik = useFormik({
        initialValues: initialValues,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: addNoteSchema,
        onSubmit: async (values: any) => {
            if(!values.note){
                return;
            }

            const res = await addNote(values);

            if(res?._status?.code === 201){
                closeModal();
            }

            if(res?.response?.data?.message || res?.response?.data?.error){
                setError(res?.response?.data?.message || res?.response?.data?.error);
            }
        }
    });
    const { values, errors, handleChange, handleSubmit, setErrors } = formik;

    const isDisabled = useIsDisabled({subject: values.subject}, errors);
    const addLoading = isLoading;
    return (
        <>
            <Modal
                setShowModal={closeModal}
                customClass="add-modal add-new-note"
            >

                <div className="header">
                    <div className="modal-actions">
                        <CrossButton
                            onClick={closeModal}
                            disabled={addLoading}
                        />
                        <CheckMarkButton
                            onClick={handleSubmit}
                            width={47}
                            height={47}
                            activeDisabled={isDisabled || !values.note}
                            disabled={addLoading}
                            isForm
                        />
                    </div>
                    <p className="app-modal-title">{title || 'New Note'}</p>
                </div>

                <div className="modal__body ">
                    <div className="add-lead-form">
                        <Input
                            width={326}
                            label="Subject"
                            name="subject"
                            placeholder={errors.subject as string || "Enter subject"}
                            value={values.subject}
                            onChange={handleChange}
                            isError={!!(errors.subject)}
                            errors={errors}
                            setErrors={setErrors}
                            customClass="subject-field"
                        />
                        <div className="input-container">
                            <Label inputLabel text="Message" />
                            <div  className="message-box">
                                <textarea
                                    className="note-message"
                                    name="note"
                                    placeholder="Message"
                                    value={values.note} 
                                    onChange={(e: any) => {
                                        const newErrors = deleteObjectProp(errors, 'note');
                                        newErrors && setErrors(newErrors);
                                        handleChange(e);
                                    }}
                                    style={{border: errors.note ? '1px solid red' : 'unset'}}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>

            {error && (
                <ApiModal 
                    title="Add Notes Failed"
                    description={error}
                    closeModal={() => setError('')}
                />
            )}
        </>
    )
};

export default AddNotesModal;
