import { useContext } from "react"
import Description from "../common/decription";
import SocialMediaTable from "./socialMedia";
import { PromotionalResourcesContext } from "contexts/promotionalResources";
import EmailBlast from "./emailBlast";
import Announcements from "./announcement";

const Templates = () => {
    const {signageQrUrl} = useContext(PromotionalResourcesContext);
    const {promotional_templates} = signageQrUrl || {};

    let socialMedia: any = [];
    let emailBlasts: any = [];
    let intercom: any = [];

    promotional_templates?.forEach((item: any) => {
        if(item.type === 'EMAIL_BLASTS'){
            emailBlasts.push(item);
        }

        if(item.type === 'INTERCOM_ANNOUNCEMENTS'){
            intercom.push(item);
        }

        if(item.type === 'SOCIAL_MEDIA'){
            socialMedia.push(item);
        }
    })    
    return (
        <div className="templates">
            {socialMedia?.map((item: any) => {
                const {announcement_text} = item;
                return (
                    <div className="templates-table-sec">
                        <Description 
                            title="Social Media"
                            description="You have completed the onboarding checklist. Your venue maps and menu will be reviewed by the Crowd Chow 
                            team. Once we confirm everything looks good, you will receive an email with."
                        />
                        <SocialMediaTable announcementText={announcement_text} />
                    </div>
                )
            })}

            {emailBlasts?.map((item: any) => {
                const {announcement_text} = item;
                return (
                    <div className="templates-table-sec">
                        <Description 
                            title="Email Blasts"
                            description="You have completed the onboarding checklist. Your venue maps and menu will be reviewed by the Crowd Chow 
                            team. Once we confirm everything looks good, you will receive an email with."
                        />
                        <EmailBlast announcementText={announcement_text} />
                    </div>
                )
            })}

            {intercom?.map((item: any) => {
                const {announcement_text} = item;
                return (
                    <div className="templates-table-sec">
                        <Description 
                            title="Intercom Announcements"
                            description="You have completed the onboarding checklist. Your venue maps and menu will be reviewed by the Crowd Chow 
                            team. Once we confirm everything looks good, you will receive an email with."
                        />
                        <Announcements announcementText={announcement_text} />
                    </div>
                )
            })}
        </div>
    )
};

export default Templates;