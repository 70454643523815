import React, { FC } from "react";
import { Skeleton } from "components/common/skeleton";
import StaticSkeleton from "components/common/skeleton/StaticSkeleton";

interface Props {
isStatic : boolean
showTenRows?: boolean

}

const UpComingProductsSkeleton: FC<Props> = ({isStatic, showTenRows}) => {
const skeletonArray = [...new Array(showTenRows ? 7 : 5)];
  return (
    <>
      {skeletonArray.map(()=>{
          return(

            <div className="productSkeleton">
            <div className="productImg">
              {isStatic ? 
              <StaticSkeleton /> :
              <Skeleton />}
            </div>
            <div className="productSkeletonDetailContainer">
                <div className="productSkeletonDetail">
                    {
                    isStatic ? <>
                    <StaticSkeleton width={130}/>
                    <StaticSkeleton />
                    </>:
                    <> 
                    <Skeleton width={130}/>
                    <Skeleton />
                    </>}
                </div>
                    <Skeleton width={85} height={22} animation = {false} />
            </div>
          </div>


          )


      })}


    </>
  );
};

export default UpComingProductsSkeleton;
