import { IVendorAdminDashboard } from "interfaces/VendorAdminDashboard"




export enum VendorAdminDashboardActions {
 
    SET_DASHBOARD_LOADING = 'SET_DASHBOARD_LOADING',
    RESET_DASHBOARD_LOADING = 'RESET_DASHBOARD_LOADING',
    GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA',
    SET_DASHBOARD_QUERY_PARAMS = 'SET_DASHBOARD_QUERY_PARAMS',
    GET_MORE_EVENTS_DATA = 'GET_MORE_EVENTS_DATA',
    GET_TOP_SELLING_PRODUCTS = 'GET_TOP_SELLING_PRODUCTS',
    GET_MORE_SALES_DATA = 'GET_MORE_SALES_DATA',
    GET_EVENTS_LOADING = 'GET_EVENTS_LOADING'


}



const VendorAdminDashboardReducer = (state: IVendorAdminDashboard , action: any)=>{

    const {payload, type} = action;

    switch(type) {
        case VendorAdminDashboardActions.SET_DASHBOARD_LOADING : {
    
            return {
                ...state, 
                loading: {
                    ...state.loading,
                    [payload]: true
                }
            }
    
        } 
    
        case VendorAdminDashboardActions.RESET_DASHBOARD_LOADING :   
        const newLoadingState = {
            ...state.loading
        }
        delete newLoadingState[payload];
        return {
            ...state,
            loading: newLoadingState
        }
    
        case VendorAdminDashboardActions.SET_DASHBOARD_QUERY_PARAMS:
            return {
                ...state,
                dashboardQueryParams: payload
            }
        case VendorAdminDashboardActions.GET_TOP_SELLING_PRODUCTS:
        case VendorAdminDashboardActions.GET_MORE_SALES_DATA:{

            const {offset, ...restPayload} = payload;
            return {
                ...state,
                dashboardData: {
                    ...state.dashboardData, 
                    data: offset ? [...state.dashboardData.data, ...restPayload.data] : restPayload.data
                }
            }
        }
        case VendorAdminDashboardActions.GET_EVENTS_LOADING: {
            const {offset, data, ...restData} = payload;
            return {
                ...state,
                dashboardData: {
                    ...state.dashboardData,
                    ...restData
                }
            }
        }
        case VendorAdminDashboardActions.GET_MORE_EVENTS_DATA:{

            const {event_offset, ...restData} = payload;
            return {
                ...state,
                dashboardData: {
                    ...state.dashboardData,
                    events: event_offset ? [...state.dashboardData.events, ...restData.events] : restData.events
                }
            }
        }
        case VendorAdminDashboardActions.GET_DASHBOARD_DATA: {
            const {offset, event_offset, ...restDashboardData} = payload;
            return {
                ...state,
                dashboardData: restDashboardData 
            }
        }
        default:
            return state
    
    }



}

export default VendorAdminDashboardReducer