
interface Props {
    isError: boolean;
    isData: boolean;
}

const TOTAL_ROWS = 9;

const MenuSkeleton = ({isData, isError}: Props) => {
    return (
        <>
        {!isError && !isData && (
            <div className="results-table header">
                <div className="food-image">
                    Image
                </div>
                <div className='food-name'>
                    <p>Name</p>
                    <p>Price</p>
                </div>
                <div className='food-categories'>
                    Categories
                </div>
                <div className='food-option'>
                    Options
                </div>
            </div>
        )}
        <div className={`resultsTableSkeletonFadeIn ${isError || isData ? 'resultsTableSkeletonFadeOut' : ''}`}>
            {[...new Array(TOTAL_ROWS)].map((_:any, index: number) => {
                return (
                    <div className="results-table skeleton-menue">
                        <div className="food-image">
                        </div>
                        <div className="food-name">
                            <div>
                                <p className="name"></p>
                                <p className="food-price"></p>
                            </div>
                            <p className="desc"></p>
                        </div>
                        <div className="food-categories">
                            {(index !== 2 && index !== 4) && (
                                <>
                                    <label className=""></label>
                                    <label className=""></label>
                                </>
                            )}
                            
                            {(index !== 1 && index !== 6 && index !== 8) && (
                                <label className=""></label>
                            )}
                        </div>
                        {(index === 0 || index === 3) && (
                            <div className='food-option'>
                                <div>
                                    <p></p>
                                    <p></p>
                                    <p></p>
                                </div>
                                <div>
                                    <p></p>
                                    <p></p>
                                </div>
                            </div>
                        )}
                    </div>
                )
            })}
    
            <div className="overlay">
                <p>Upload CSV Above </p>
            </div>
        </div>
        </>
    )
};

export default MenuSkeleton;
