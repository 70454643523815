import React, { useContext, useEffect } from "react";
import VendorStatsHeader from "./VendorStatsHeader";
import VendorDashboardBody from "./VendorDashboardBody";
import { VendorAdminDashboardContext } from "contexts/vendorAdminDashboard";
import HeaderSkeleton from "../Skeleton/HeaderSkeleton";
import { Skeleton } from "components/common/skeleton";
import { VendorAdminDashboardActions } from "contexts/reducers/vendorAdminDashboard";

const VendorDashboard = () => {
    const {
        fetchDashboardData,
        dashboardQueryParams,
        dashboardData,
        loading
    } = useContext(VendorAdminDashboardContext);

    const { current_live_events } = dashboardData;
     const isLoading = loading[VendorAdminDashboardActions.GET_DASHBOARD_DATA];

    const fetchData = async () => {
        await fetchDashboardData(dashboardQueryParams, VendorAdminDashboardActions.GET_DASHBOARD_DATA);
    };

    const currenttLiveText = () => {
        if (current_live_events.length === 1) {
            return (
                <div className="superDashboardBtn">
                    <span>{current_live_events[0].name} is </span>
                    <span className="livenowLink">live now</span>.
                </div>
            );
        } else if (current_live_events.length === 2) {
            return (
                <div className="superDashboardBtn">
                    <span>
                        {current_live_events[0].name} and {current_live_events.length - 1}{" "}
                        other are{" "}
                    </span>
                    <span className="livenowLink">live now</span>.
                </div>
            );
        } else if (current_live_events.length > 2) {
            return (
                <div className="superDashboardBtn">
                    <span>
                        {current_live_events[0].name} and {current_live_events.length - 1}{" "}
                        others are{" "}
                    </span>
                    <span className="livenowLink">live now</span>.
                </div>
            );
        }
    };

    useEffect(() => {
        fetchData();
        /* eslint-disable react-hooks/exhaustive-deps */
    }, []);
    return (
        <div className="dashboard">
            <h2 className="dashboard_pageHeading">Dashboard</h2>
            {isLoading ? (
                <div className="live-now-skeleton">
                <Skeleton />
                </div>
            ) : !isLoading && current_live_events.length > 0 ? (
                <>{currenttLiveText()}</>
            ) : (
                <></>
            )}
            {isLoading ? <HeaderSkeleton /> : <VendorStatsHeader />}
            <VendorDashboardBody />
        </div>
    );
};
export default VendorDashboard;
