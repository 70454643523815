import { ReportContext } from "contexts/report";
import React, { useContext } from "react"
import EventSalesPrint from "../eventSalesPrint";
import EventItemsPrint from "../eventItemsPrint";

const DatesPrint =  React.forwardRef((props: any, ref: any) => {
    const { reportDates, selectedVenue } = useContext(ReportContext);
    const {events, events_sales} = reportDates;
    console.log('events_sales', reportDates);

    return (
        <div ref={ref} className="venueReports">
            <div className="d-flex reportHeaderAlignment">
                <p className="reportsTopHeader">Crowd Chow Sales Summary</p>
            </div>
            <div className="d-flex align-items-center">
                <p style={{width: 135}} className="reportsLabelHeader">Venue:</p>
                <p className="reportsText">{selectedVenue?.name || ''}</p>
            </div>
            <div className="d-flex align-items-center">
                <p style={{width: 135}} className="reportsLabelHeader">Date Range:</p>
                <p className="reportsText">{events?.date_range}</p>
            </div>
            <div className="d-flex align-items-center">
                <p style={{width: 135}} className="reportsLabelHeader">Event Count:</p>
                <p className="reportsText">{events?.count}</p>
            </div>
            <div className="divider"></div>
            <EventSalesPrint 
                showDeliveryFees
                events_sales={events_sales}
            />
            <div className="divider"></div>
            <EventItemsPrint 
                printFormattedItems={events_sales?.items}
            />
        </div>

    )
});

export default DatesPrint;
