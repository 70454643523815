import { useIsDisabled } from "hooks/useIsDisabled";
import { VendorDetailSections } from ".";
import SectionHeader from "./sectionHeader";
import { useFormik } from "formik";
import { ChangeEvent, useContext, useEffect, useState } from "react";
import * as Yup from "yup";
import Label from "components/common/label";
import Input from "components/common/input";
import Switch from "components/common/switch";
import QuestionMarkCircle from "components/common/questionMarkCircle";
import { VendorContext } from "contexts/vendor";
import { VendorActions } from "contexts/reducers/vendor";
import ApiModal from "components/common/apiErrorModal";
import {LabeledInputSkeleton} from "components/common/skeleton";
import SaveIcon from "components/venue/SaveIcon";
import { AuthContext } from "contexts/auth";

const VendorTaxFees = ({
  data,
  setActiveEdit,
  activeEdit,
}: any) => {
  const { updateVendorDetails, loading } = useContext(VendorContext);
  const {setVendorDetailsEditing} = useContext(AuthContext)

  const [isEdit, setIsEdit] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState({
    status: null,
    message: ''
  });

  const initialValues = {
    tax_orders: data?.tax_orders !== undefined ? data?.tax_orders : false,
    charge_delivery_fee:
      data?.charge_delivery_fee !== undefined
        ? data?.charge_delivery_fee
        : false,
    customer_pays_credit_card_fee:
      data?.customer_pays_credit_card_fee !== undefined
        ? data?.customer_pays_credit_card_fee
        : false,
    tax_rate: data?.tax_rate || "",
    delivery_fee: data?.delivery_fee || "",
    minimum_amount_for_customer: data?.minimum_amount_for_customer || "",
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      tax_rate: Yup.number().test('Positive value', 'Enter positive value only', (value: any) => value >= 0).required("Required"),
      delivery_fee: Yup.number().test('Positive value', 'Enter positive value only', (value: any) => value >= 0).required("Required"),
      minimum_amount_for_customer: Yup.number().test('Positive value', 'Enter positive value only', (value: any) => value >= 0).required("Required"),
    }),
    onSubmit: async (values: any) => {
      const res = await updateVendorDetails({ ...values });

      if(res?.response?.data?.message){
        setError({
          message: res?.response?.data?.message,
          status: res?.response?.status
        });
      }else if(res?.response?.status === 500){
        setError({
          message: '',
          status: res?.response?.status
        });
      }

      if (res?._status?.code === 200) {
        setSuccess(true)
        setIsEdit(false);
        setActiveEdit("");
        setVendorDetailsEditing(false);
      }
    },
  });

  const { values, errors, handleReset, setErrors, handleChange, handleSubmit } =
    formik;

  const onEdit = () => {
    setIsEdit(true);
    setActiveEdit(VendorDetailSections.SUBSCRIPTION);
    setVendorDetailsEditing(true);

  };

  const onClickCross = () => {
    setIsEdit(false);
    handleReset(initialValues);
    setActiveEdit("");
    setVendorDetailsEditing(false);

  };

  const changeHandler = (e: ChangeEvent<HTMLInputElement>) => {
    const lastChar = e.target.value[e.target.value.length-1];
    const numAndSpecialCharsRegex = /[a-zA-Z!@#$%^&(*)_+{}:"|\\<>?,]$/
    if(lastChar && numAndSpecialCharsRegex.test(lastChar)){
      return;
    }
    
    handleChange(e);
  }

  const isLoading = loading[VendorActions.UPDATE_VENDOR_DETAILS];
  const vendorLoading = loading[VendorActions.GET_VENDOR_DETAILS];

  const {
    tax_orders,
    charge_delivery_fee,
    customer_pays_credit_card_fee,
    ...restValues
  } = values;

  const isDisabled = useIsDisabled(restValues, errors);



  useEffect(()=>{
    const sucessTimer = setTimeout(()=>{
         if(success)
         setSuccess(false);
     },3000);


     return ()=>{
         clearTimeout(sucessTimer)
     }
     
 }, [success])



  return (
    <>

{
            <div className={`save-order ${success ? "save-order-animation" : ""}`}>
                 <span>   <SaveIcon /> Saved</span>
                </div>
            }
      <SectionHeader
        title="Vendor Fees and Tax Settings"
        editDisabled={
          activeEdit && activeEdit !== VendorDetailSections.SETTINGS
        }
        onClick={() => onEdit()}
        onClickCross={onClickCross}
        onClickSubmit={() => {
          handleSubmit();
        }}
        isEditMode={isEdit}
        submitDisabled={isDisabled}
        isLoading={isLoading || vendorLoading}
      />
      <div className="admin-info">
        <div className="detail-fields-group track-fields">
          <div className="detail-group tax-order">
            {vendorLoading ? (
              <LabeledInputSkeleton 
                switchSkeleton
              />
            ) : (
              <>
                <Label text="Tax Orders" />
                {isEdit ? (
                  <Switch
                    name="tax_orders"
                    parentClass="toggle-small"
                    checked={values.tax_orders}
                    onChange={handleChange}
                  />
                ) : (
                  <p className="field-text">
                    {values.tax_orders ? "Yes" : "No"}
                  </p>
                )}
              </>
            )}
          </div>
          <div className="detail-group tax-rate">
            {vendorLoading ? (
              <LabeledInputSkeleton />
            ) : (
              <>
                <Label
                  inputLabel={isEdit && tax_orders}
                  text="Tax Rate"
                  customClass={tax_orders ? "" : "disable-label"}
                />
                {isEdit && tax_orders ? (
                  <Input
                    type="number"
                    name="tax_rate"
                    value={values.tax_rate}
                    onChange={changeHandler}
                    isError={!!errors.tax_rate}
                    placeholder={
                      errors.tax_rate ? (errors.tax_rate as string) : ""
                    }
                    errors={errors}
                    setErrors={setErrors}
                  />
                ) : (
                  <p
                    className={`field-text ${tax_orders ? "" : "disable-text"}`}
                  >
                    {`${values.tax_rate}%` || "0.00%"}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        <div className="detail-fields-group delivery-fields">
          <div className="detail-group charge-delivery-fee">
            {vendorLoading ? (
              <LabeledInputSkeleton 
                switchSkeleton
              />
            ) : (
              <>
                <Label text="Charge Delivery Fee" />
                {isEdit ? (
                  <Switch
                    name="charge_delivery_fee"
                    parentClass="toggle-small"
                    checked={values.charge_delivery_fee}
                    onChange={handleChange}
                  />
                ) : (
                  <p className="field-text">
                    {values.charge_delivery_fee ? "Yes" : "No"}
                  </p>
                )}
              </>
            )}
          </div>
          <div className="detail-group delivery-fee">
            {vendorLoading ? (
              <LabeledInputSkeleton />
            ) : (
              <>
                <Label
                  inputLabel={isEdit && charge_delivery_fee}
                  text="Delivery Fee"
                  customClass={charge_delivery_fee ? "" : "disable-label"}
                />
                {isEdit && charge_delivery_fee ? (
                  <Input
                    type="number"
                    name="delivery_fee"
                    value={values.delivery_fee}
                    onChange={changeHandler}
                    isError={!!errors.delivery_fee}
                    placeholder={
                      errors.delivery_fee ? (errors.delivery_fee as string) : ""
                    }
                    errors={errors}
                    setErrors={setErrors}
                  />
                ) : (
                  <p
                    className={`field-text ${
                      charge_delivery_fee ? "" : "disable-text"
                    }`}
                  >
                    {values.delivery_fee || "0.00"}
                  </p>
                )}
              </>
            )}
          </div>
        </div>
        <div className="detail-fields-group paymentMethod-fields">
          <div className="detail-group customer-credit-card-fee">
            {vendorLoading ? (
              <LabeledInputSkeleton 
                switchSkeleton
              />
            ) : (
              <>
                <Label text="Customer Pays Credit Card Fee" />
                {isEdit ? (
                    <div className="toogle-small">
                      <Switch
                        name="customer_pays_credit_card_fee"
                        parentClass="toggle-small"
                        checked={values.customer_pays_credit_card_fee}
                        onChange={handleChange}
                      />
                    </div>
                ) : (
                  <div className="field-info-tooltip">
                    <p className="field-text">
                      {values.customer_pays_credit_card_fee ? "Yes" : "No"}
                    </p>
                    <QuestionMarkCircle />
                  </div>
                )}
              </>
            )}
          </div>
          <div className="detail-group minimum-order-total">
            {vendorLoading ? (
              <LabeledInputSkeleton />
            ) : (
              <>
                <Label
                  inputLabel={isEdit && customer_pays_credit_card_fee}
                  text="Minimum Order Total"
                  customClass={customer_pays_credit_card_fee ? "" : "disable-label"}
                />
                {isEdit && customer_pays_credit_card_fee ? (
                  <>
                    <Input
                      name="minimum_amount_for_customer"
                      value={values.minimum_amount_for_customer}
                      onChange={changeHandler}
                      isError={!!errors.minimum_amount_for_customer}
                      placeholder={
                        errors.minimum_amount_for_customer
                          ? (errors.minimum_amount_for_customer as string)
                          : ""
                      }
                      errors={errors}
                      setErrors={setErrors}
                    />
                  </>
                ) : (
                  <div className="field-info-tooltip">
                    <p
                      className={`field-text ${
                        customer_pays_credit_card_fee ? "" : "disable-text"
                      }`}
                    >
                      {values.minimum_amount_for_customer || "0.00"}
                    </p>
                    <QuestionMarkCircle
                        isDisabled={!customer_pays_credit_card_fee}
                      />
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {error.status && (
        <ApiModal 
          title="Update Vendor Failed"
          message={error.message}
          setIsShow={() => setError({status: null, message: ''})}
        />
      )}
    </>
  );
};

export default VendorTaxFees;
