import { insignificantZerosRegex } from "components/orders/details";
import Price from "./price";
import { getMaxQuantityLengths } from "./eventItems";

const EventItemsPrint = ({
  printFormattedItems,
}: {
  printFormattedItems: any[];
}) => {
  const { leftMaxQuantityLength, rightMaxQuantityLength } =
    getMaxQuantityLengths(printFormattedItems);
  return (
    <>
      <div className="salesItems">
        <p className="reportsLabel">Item Sales:</p>
        <div className="items-slider">
          <div className="salesItemsDetails">
            {printFormattedItems?.map((item: any, index: number) => {
              const { id, quantity } = item;
              const formattedQuantity =
                quantity?.toString()?.length > 4 ? "9999+" : quantity;
              return (
                <div key={id + "_print"} className="sale-item-detail-print">
                  <div className="sale-qty">
                    {formattedQuantity ? (
                      <p
                        className={`qty ${
                          quantity?.toString()?.length > 1
                            ? "qty-indicator"
                            : ""
                        }`}
                      >
                        {formattedQuantity}
                        <span>x</span>
                      </p>
                    ) : (
                      <p>0</p>
                    )}
                    <p
                      className={`food-item food-item-${
                        index % 2 === 0
                          ? leftMaxQuantityLength
                          : rightMaxQuantityLength
                      }`}
                    >
                      {item.name}
                    </p>
                  </div>
                  <p className="price-item">
                    {/* {item.total_price?.toString()?.replace(insignificantZerosRegex, '.')} */}
                    <Price
                      amount={item.total_price
                        ?.toString()
                        ?.replace(insignificantZerosRegex, ".")}
                    />
                  </p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default EventItemsPrint;
