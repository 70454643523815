import { useEffect, useRef, useState } from "react"

const AccordianBody = ({text, open}:{open: boolean, text: string}) => {
    const accrodianContainerRef = useRef<any>(null);

    const [showIndicator, setShowIndicator] = useState<any>(null!);
    const [isScrolled, setIsScrolled] = useState(false);
    const [showSeeMore, setShowSeeMore] = useState(false);

    const onScrollMessage = (e: any) => {
        if(e.target.scrollTop > 5){
            setShowIndicator(false);
            setIsScrolled(true)
        }else{
            setIsScrolled(false)
        }
    }
    
    useEffect(() => {
        if(text && accrodianContainerRef.current && open){
            const computedHeight = window.getComputedStyle(accrodianContainerRef.current).height?.replace("px", '');
            if(computedHeight >= '412'){
                setShowSeeMore(true);
            }
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[text, accrodianContainerRef.current, open])
    return (
        <div ref={accrodianContainerRef} onScroll={onScrollMessage} id="accordian-body" className="accordian-body">
            <p>
                {text || ''}
            </p>
            {showSeeMore && (
                <div className={`see-more ${!isScrolled ? '' : 'see-more-deactivate'}`}>
                    {showIndicator && (
                        <div className="scroll-indicator"></div>
                    )}
                </div>
            )}
        </div>
    )
};

export default AccordianBody;
