import axios from 'services/core/axios';
import config from 'config/config';
import { QrUrlUpdateRequest } from 'interfaces/promotionalResources';


export const getQrUrl = async (vendor_id: string) => {
    try {
        const res = await axios.get(`${config.defaults.api_url}/v1/vendors/${vendor_id}/promotional/resources`);
        return res?.data;
    }

    catch (error: any) {
        return error;
    }
}



export const putQrUrl = async (resource_id: string, updateQrUrlRequest: QrUrlUpdateRequest) =>{

try {
    const res = await axios.put(`${config.defaults.api_url}/v1/vendors/promotional/resources/${resource_id}`, updateQrUrlRequest)
    return res?.data;

}

catch (error: any)
{
    return error;
}


}