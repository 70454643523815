import VendorContact from "./vendorContact";
import { Fragment, useContext, useState } from "react";
import VenueForm from "./venueForm";
import { AuthContext } from "contexts/auth";
import Cookies from "js-cookie";
import { VendorActions } from "contexts/reducers/vendor";
import { checkList } from "../checklist";
import Button from "components/common/button";
import { useFormik } from "formik";
import { useAllDisabled } from "hooks/useIsDisabled";
import { OnboardingContext } from "contexts/onboarding";
import { useValidationSchema } from "hooks/useValidationSchema";
import * as Yup from "yup";
import { useVenuesInitValues } from "hooks/useVenuesInitValues";
import ApiModal from "components/common/apiErrorModal";
import * as _ from "lodash";

interface Props {
  addVenues: Function;
  loading: string[];
  setSelectedStep: Function;
  selectedStep: string;
}

const NOT_EMPTY_FIELDS = [
  "id",
  "concession_pickup",
  "chow_stations",
  "seat_delivery",
  "scheduled_enabled",
  "longitude",
  "latitude",
  "customer_facing_phone",
  "customer_facing_email",
];

const VENUE_OBJECT = {
  name: "",
  address: "",
  city: "",
  state: "",
  zipcode: "",
  longitude: 0,
  latitude: 0,
  phone: "",
  concession_pickup: true,
  chow_stations: true,
  seat_delivery: false,
  venue_type: "",
  scheduled_enabled: false,
  map_url: "",
  venue_logo: "",
};

const VenueSetup = ({
  addVenues,
  loading,
  setSelectedStep,
  selectedStep,
}: Props) => {
  const { user } = useContext(AuthContext);
  const { vendorOnboardingDetails } = useContext(OnboardingContext);

  const [error, setError] = useState("");
  const initValues = useVenuesInitValues(vendorOnboardingDetails, VENUE_OBJECT);
  const [initialValues, setInitialValues] = useState(initValues);

  const yupSchema = useValidationSchema(initialValues);

  const formik = useFormik({
    initialValues: initialValues,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object(yupSchema),
    onSubmit: (values) => {
      onClickSave(values);
    },
  });

  const {
    values,
    handleSubmit,
    handleBlur,
    errors,
    setFieldValue,
    handleChange,
    setFieldError,
    setErrors,
    touched,
    resetForm,
  } = formik;

  const venuesCount = Object.keys(values).filter((key: string) =>
    key.includes("venue")
  ).length;

  const onClickSave = async (values: any) => {
    const { contactForm, ...venues } = values;

    const vendor_id = user?.vendor_id || Cookies.get("vendor_id");
    if (vendor_id) {
      const res = await addVenues({
        vendor_id: vendor_id || 0,
        ...contactForm,
        venues: Object.values(venues),
      });

      if (res?._status?.code === 201 && res?.data?.venues_added) {
        const index = checkList.findIndex((item) => item === selectedStep);
        index !== -1 &&
          checkList[index + 1] &&
          setSelectedStep(checkList[index + 1]);
      }

      if (res?.response?.data?.message) {
        setError(res.response.data.message);
      }
    }
  };

  const onAddCustomerPhone = (phone: string, prevPhone: string) => {
    const { contactForm, ...vals } = values;
    Object.keys(vals).forEach((key: any) => {
      const val = vals[key as keyof typeof vals];

      const isEqual = _.isEqual(val.phone, prevPhone);
      setFieldValue(key, {
        ...val,
        ...("phone" in val && (val.phone.length === 0 || isEqual) && { phone }),
      });
    });
  };

  const onClickAdd = () => {
    const { contactForm, ...restValues } = values;
    const keys = Object.keys(values);

    let maxId = 0;
    keys.forEach((key: string) => {
      if (key.includes("venue")) {
        const id = restValues[key as keyof typeof restValues]?.id;
        if (id > maxId) {
          maxId = id;
        }
      }
    });

    const nextId = maxId + 1;
    resetForm({
      errors,
    });

    setInitialValues({
      ...values,
      [`venue${nextId}`]: {
        id: nextId,
        ...VENUE_OBJECT,
      },
    });
  };

  const onClickDelete = (name: string) => {
    if (venuesCount > 1) {
      const { [name]: prop, ...rest } = values as any;

      resetForm({
        errors,
      });

      setInitialValues(rest);
    }
  };
  const isDisabled =
    useAllDisabled(values, errors, NOT_EMPTY_FIELDS) ||
    (values.contactForm.customer_facing_email.length === 0 &&
      values.contactForm.customer_facing_phone.length === 0);

  const venueLoading = loading.includes(VendorActions.SET_VENUES);

  const { contactForm, ...restValues } = values;

  //  const currentVenuesCount = Object.keys(restValues || {}).length;
  //   const isLimitedPlan = vendorOnboardingDetails?.billing?.plan !== "UNLIMITED";
  //   const isVenueLimitReached = vendorOnboardingDetails?.venues_allowed <= currentVenuesCount;
  return (
    <>
      <div className="venue">
        <p className="venue_title">Venue Setup</p>
        <p className="venue_desc">
          Provide the name, address, and available order types (concession
          pickup, Chow Stations, Seat Delivery) for each venue below and upload
          an image of the venue.
        </p>

        <form onSubmit={handleSubmit}>
          <VendorContact
            setFormsData={setFieldValue}
            formData={values.contactForm}
            onAddCustomerPhone={onAddCustomerPhone}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
            handleBlur={handleBlur}
            setFieldError={setFieldError}
          />

          {Object.keys(restValues).map((key: any) => {
            const form: any = values[key as keyof typeof values];
            const { id } = form;
            return (
              <Fragment key={id}>
                <VenueForm
                  venueName={`Venue ${id}`}
                  id={id}
                  formData={form}
                  setFormsData={setFieldValue}
                  name={`venue${id}`}
                  handleChange={handleChange}
                  errors={errors}
                  setErrors={setErrors}
                  onClickDelete={onClickDelete}
                  venuesCount={venuesCount}
                />
              </Fragment>
            );
          })}
          <div className="addVenueBtn">
            {/* {!isLimitedPlan && ( */}
            <>
              <svg
                className="plus-icon"
                xmlns="http://www.w3.org/2000/svg"
                width="28"
                height="28"
                viewBox="0 0 28 28"
                fill="none"
              >
                <circle cx="14" cy="14" r="14" fill="#3C3C3C" />
                <rect
                  x="13.4814"
                  y="7.77734"
                  width="1.14074"
                  height="12.4444"
                  rx="0.57037"
                  fill="white"
                />
                <rect
                  x="20.2222"
                  y="13.4814"
                  width="1.14074"
                  height="12.4444"
                  rx="0.57037"
                  transform="rotate(90 20.2222 13.4814)"
                  fill="white"
                />
              </svg>
              <Button
                // disabled={isLimitedPlan && isVenueLimitReached} // Since we now have Unlimited plan only, this Add Venue button will always be enabled for now.
                onClick={onClickAdd}
                buttonName="Add Venue"
              />
            </>
            {/* )} */}
          </div>

          <div className="venue_saveBtn">
            <Button
              type="submit"
              loading={venueLoading}
              isActiveDisabled={isDisabled}
              buttonName="Save and Proceed"
              loadingText="Proceeding..."
            />
          </div>
        </form>
      </div>
      {error && (
        <ApiModal
          message={error}
          title="Add Venues Failed"
          setIsShow={() => setError("")}
        />
      )}
    </>
  );
};

export default VenueSetup;
