import ReportProvider from "contexts/report";
import { useIsSuperAdmin } from "hooks/useIsSuperAdmin";
import SuperAdminReportTabs from 'components/reports/superAdmin';
import SuperAdminReportProvider from "contexts/superAdminReport";
import VendorReportCore from "components/reports/normalAdmin/VendorReportCore";
import VendorProvider from "contexts/vendor";


const Reports = () => {
    const isSuperAdmin = useIsSuperAdmin();

    return (
        <>
            {isSuperAdmin ? (
                <VendorProvider>
                <SuperAdminReportProvider>
                    <SuperAdminReportTabs  />
                </SuperAdminReportProvider>
                </VendorProvider>
            ):(
               
                <ReportProvider>
                    <VendorReportCore />
                </ReportProvider>
                
            )}
        </>
    )
}

export default Reports;