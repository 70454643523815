import React, { FC } from 'react'
import VendorReportItemsTable from '../VendorReportItemsTable'


interface Props {
activeTab: number;
fetchOrders: () => Promise<any>

}

const AllOrders: FC<Props> = ({activeTab, fetchOrders}) => {




  
  return (
  <VendorReportItemsTable activeTab = {activeTab} fetchOrders={fetchOrders} />
  )
}

export default AllOrders
