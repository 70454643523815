import React, { useContext } from "react"
import Crowds from "./crowds";
import { UserContext } from "contexts/user";

const CrowdSection = ({vendor_id}: any) => {
    const { loading, fetchCrowds, crowdList, crowedPage, setCrowedPage, crowedQueryParams, setCrowedQueryParams } = useContext(UserContext);
    return (
        <Crowds 
            loading={loading}
            fetchCrowds={fetchCrowds}
            crowdList={crowdList}
            vendor_id={vendor_id}
            page = {crowedPage}
            setPage = {setCrowedPage}
            queryParams= {crowedQueryParams} 
            setQueryParams = {setCrowedQueryParams}

        />
    )
};

export default CrowdSection;
