import { BasicGetRequest } from './common';

export interface VendorContextProvider {
    loading: any;
    setLoading: Function;
    resetLoading: Function;
    sendWelcomeEmail: Function;
    updateVendorDetails: Function;
    updateVendorContext: Function;
    vendorList: VendorList;
    venueList: VenueList;
    addVendor: Function;
    fetchVendors: Function;
    deleteVendor: Function;
    resetVendor: Function;
    fetchVenues: Function;
    deleteVenue: Function;
    getAdmins: Function;
    adminList: AdminList;
    fetchAdmins: Function;
    addAdmin: Function;
    deleteAdmin: Function;
    updateAdmin: Function;
    fetchVendorDetail: Function;
    vendorDetails: any;
    addVenue: Function;
    leadList: LeadList
    fetchLeads: Function;
    search: boolean;
    addLead: Function;
    deleteLead: Function;
    updateLead: Function;
    sendSalesLead: Function;
    setLead: Function;
    approveVendor: Function;
    queryParams: any;
    setQueryParams: Function;
    venueQueryParams: any,
    setVenueQueryParams: Function
    addLeadNote: Function;
    fetchVenueDetails: Function;
    removeNote: Function;
    salesKitSuccessModal: any;
    setSalesKitSuccessModal: Function;
    fetchVendorOnbaordingStatus: Function;
    setActiveTab: Function;
    activeTab: number;
    fetchVendorBillingHistory: Function;
    billingHistory: any;
    sendInvoice: Function;
    updateSubscriptionStatus: Function;
    addNoteToVendor: Function;
    deleteVendorNote: Function;
    fetchVendorInvoiceHistory: Function;
    invoiceHistory: any;
    page: any;
    setPage: Function;
    vendorLeadTab: any,
    setVendorLeadTab: Function,
    leadsClickedList: Array<number>,
    setLeadsClickedList: Function
    venuePage: any,
    setVenuePage: Function
    adminsPage: number,
    setAdminsPage: Function,
    adminQueryParam: any,
    setAdminQeuryParam: Function;
    setVenues: Function;
    setAdmins: Function;
    leadsPage: any,
    setLeadsPage: Function,
    uploadVendorCustomAgreement: Function,
    selectedVendorType: any,
    selectingVendorTypeInfo: Function,
    reSetvendorList: Function,
    emailAgreement: Function,
}

export interface LeadList {
    leads: any;
    _count: number,
    enable_filters: any,
}
export interface AdminList {
    admins: Admin[];
    _count: number;
    _staffCount: number;
    _customersCount: number;
}

export interface VenueResponse {
    id: number;
    map_required: boolean;
    map_url: string;
    map_url_onboarding: string;
    name: string;
    scheduled_events: number;
}
export interface VenueList {
    venues: any;
    _count: number
}
export interface VendorList {
    vendors: any;
    enable_filters?: any;
    _count: number
}
export interface Vendor {
    onboarding_pending: boolean;
    onboarding_step: string;
    agreement: string;
    agreement_accepted: boolean;
    connected_account_stripe: VendorConnectedStripeAccount
    venue_details: any;
    app_setup: any;
    vendor: any;
    training_videos: any[];
    flyers: any[]
}

export interface VendorConnectedStripeAccount {
    connected: boolean;
    email: string
}

export interface Venue {
    name: string;
    address: string;
    state: string;
    zipcode: string;
    phone: string;
    city: string;
    latitude: number;
    longitude: number;
    venue_type: string;
    concession_pickup: boolean;
    chow_stations: boolean;
    seat_delivery: boolean;
    map_url: string;
}

export interface PostVenueRequest {
    venues: Venue[];
    name: string;
    address: string;
    zipcode: string;
    phone: string;
    customer_facing_email: string;
    customer_facing_phone: string;
    vendor_id: number;
}

export enum CHECKLIST {
    Agreement = 'Agreement',
    Billing = 'Billing',
    Connect_Stripe_Account = 'Connect Stripe Account',
    Venues = 'Venue Setup',
    Menu = 'Menu',
    Submit_For_Review = 'Submit for Review'
}

export enum ImageFor {
    Item = 'ITEM',
    Option = 'OPTION',
    Venue = 'VENUE',
    Vendor = 'VENDOR',
    Variant = 'VARIANT',
    Map = 'MAP'
}

export interface LibraryResourceRequest {
    offset?: number;
    limit?: number;
}

export interface ImageLibraryRequest extends LibraryResourceRequest {
    web_only: boolean;
}

export interface ResourceRequest extends LibraryResourceRequest {
    web_only?: boolean;
}

export interface MenuRequest {
    file: File;
    data?: any;
    confirm?: 'false' | 'true';
}
export interface VendorListRequest extends BasicGetRequest {
    venue_id?: number;
}

export interface SendWelcomeEmail {
    vendor_id?: number;
    subscription_type: string,
    yearly_fee: number,
    renewal_fee: number,
    venues: number,
    first_name: string,
    last_name: string,
    prospect_email: string
}

export interface VendorDetailsRequest {
    vendor_id: number;
    name?: string,
    address?: string,
    city?: string,
    state?: string,
    zipcode?: string,
    phone?: string,
    tax_orders?: boolean;
    tax_rate?: number;
    charge_delivery_fee?: boolean,
    delivery_fee?: number,
    auto_print_receipts?: boolean,
    remove_stripe?: boolean,
    customer_pays_credit_card_fee?: boolean,
    minimum_amount_for_customer?: number,
    is_enabled?: boolean
    billing_contact?: string;
}

export interface AddVendorRequest {
    name: string;
    address: string;
    city: string;
    state: string;
    zipcode: string;
    phone: string;
    lead_id?: string
}

export interface AddAdminRequest {
    first_name: string,
    last_name: string,
    email: string;
    phone: string;
    vendor_id: number;
}

export interface EditAdminRequest {
    admin_id: number;
    first_name?: string,
    last_name?: string,
    phone?: string
}

export interface Admin {
    id: number;
    first_name: string;
    last_name: string;
    email: string;
    phone: string;
    last_login: string;
}

// DON'T USE THIS ONE. USE FROM utils/enums/VendorBillingStatuses.ts 
export enum BillingStatus {
    UNPAID = 'UNPAID',
    PAID = 'PAID',
    PENDING = 'PENDING',
    LATE = 'LATE',
    COMPED = 'COMPED',
    NOT_YET = 'NOT_YET',
    INACTIVE = 'INACTIVE',
    PROCESSING = 'PROCESSING'
}

export enum VendorStatus {
    Created = 'CREATED',
    Welcome = 'WELCOME',
    Vp_Onboarding = 'VP_ONBOARDING',
    Needs_Review = 'NEEDS_REVIEW',
    Onboarding = 'ONBOARDING',
    SCHEDULED = 'SCHEDULED',
    Active = 'ACTIVE',
    AT_RISK = 'AT_RISK',
    Lapsed = 'LAPSED'
}

export interface LeadsRequest {
    status?: string;
    limit?: string;
    offset?: string;
    search: string;
}

export enum LeadsStatus {
    NEW = 'NEW',
    SALES_KIT_SENT = 'SALES_KIT_SENT',
    VENDOR_CREATED = 'VENDOR_CREATED',
    ONBOARDING = 'ONBOARDING',
    CONVERTED = 'CONVERTED'
}

export interface AddLeadRequest {
    name: string,
    email: string,
    district_school: string,
    district_school_email: string,
    role: string,
    number_of_venues: number,
    city: string,
    state: string,
    where_heard: string
}

export interface UpdateLeadRequest extends AddLeadRequest {
    lead_id: number;
}