import React from "react";
import { Skeleton } from "components/common/skeleton";

const HeaderSkeleton = () => {
  const divStyle = {
    borderRadius: "40px",
  };

const skeletonArray = [...new Array(4)];

  return (
    <div className="dashboard-header-skeleton">

      {skeletonArray.map(()=> <Skeleton customStyles={divStyle} />
)}
    </div>
  );
};

export default HeaderSkeleton;
