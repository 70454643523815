import React, { useEffect, useRef, useState } from "react"
import { useDebouncedCallback } from 'use-debounce';
import PlacesDropdown from "../placeDropdown";
import Input from "components/common/input";
import { GetLatlong, getAddressComponents, getGoogleAutocompleteService, getGooglePlacesService } from "util/googleApiHelpers";

const AddressField = (props?: any) => {
    const dropdownRef = useRef<any>();
    const {setFormValue, address, onClickPlace, error, onBlur, onClickCross, width } = props;

    const [dropdownWidth, setDropdownWidth] = useState<any>(null);
    const [showDropdown, setShowDropdown] = useState(false);
    const [placesPredictions, setPlacesPredictions] = useState<any>([]);

    const setAddressValues = (addressObject: any) => {
        const {formatted_address} = addressObject;
        
        const data = getAddressComponents(addressObject);
        console.log(data);
        if(formatted_address){
            GetLatlong(formatted_address, (latLong: any) => {
                if(latLong){
                    data.longitude = latLong.longitude;
                    data.latitude = latLong.latitude
                }
                data.address = formatted_address;
                onClickPlace(data)
            });
            
        }
    }

    const onClickAddress = async (place: any) => {
        // const autCompleteService = new google.maps.places.PlacesService(document.createElement('div'));
        const autCompleteService = getGooglePlacesService()
        const detailsOptions: google.maps.places.PlaceDetailsRequest = {
            placeId: place.place_id,
            fields: ['address_components','formatted_address']
        }
        if(autCompleteService){
            autCompleteService.getDetails(detailsOptions, (res, status) => {
                if(status === google.maps.places.PlacesServiceStatus.OK){
                    setAddressValues(res);
                }
                 console.log('details', res, status);
            })
        }
    }

    const handlePlaceSelect = useDebouncedCallback((value: string) => {
        const options: google.maps.places.AutocompletionRequest = { 
            input: value, 
            types: ['address'], 
            componentRestrictions: {country: "us"}
        };

        const autCompleteService = getGoogleAutocompleteService();
        if(autCompleteService){
            autCompleteService.getPlacePredictions(options, (res, status) => {
                if(status === google.maps.places.PlacesServiceStatus.OK){
                    setPlacesPredictions(res)
                }
            })
        }
    },800)

    const onChangeAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormValue(e.target.name, e.target.value);
        if(e.target.value === ""){
            setPlacesPredictions([]);
        }else{
            e.target.value.length > 3 && handlePlaceSelect(e.target.value);
        }
    }

    useEffect(() => {
        const clickHandler = (e: any) => {
            if(dropdownRef.current?.contains(e.target)){
                return;
            }
            
            setShowDropdown(false);
        }
        
        document.addEventListener("click", clickHandler, true);

        return () => {
            document.removeEventListener("click", clickHandler, true);
        }
    },[]);

    useEffect(() => {
        const dropdownWidth = window.getComputedStyle(dropdownRef.current)?.width;
        dropdownWidth && setDropdownWidth(dropdownWidth);
    },[])

    return (
        <div style={{...(width && {width})}} className="venue_venueForm_body_formGroup address">
            <div ref={dropdownRef}>
                <Input
                    label="Address"
                    placeholder={error || 'Enter Address'}
                    customClass={error ? 'input-error' : ''}
                    name="address"
                    value={address || ''}
                    onChange={onChangeAddress}
                    onFocus={(e: any) => {
                        e.stopPropagation();
                        setShowDropdown(true);
                    }}
                    onBlur={onBlur}
                    onClickCross={() => onClickCross('address')}
                />
                {showDropdown && (
                    <PlacesDropdown
                        width={dropdownWidth}
                        onClickPlace={(place: any) => {
                            onClickAddress(place);
                            setShowDropdown(false);
                        }}
                        places={placesPredictions}
                    />
                )}
            </div>
        </div>
    )
};

export default AddressField;
