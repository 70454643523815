import VenueProvider from "contexts/venues";
import { Outlet } from "react-router-dom";

const VenueProviderLayout = () => {
    return (
        <VenueProvider>
            <Outlet />
        </VenueProvider>
    )
};

export default VenueProviderLayout;
