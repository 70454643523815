import VenuesCore from "components/venues";
import { AuthContext } from "contexts/auth";
import { VenueContext } from "contexts/venues";
import Cookies from "js-cookie";
import { useContext } from "react";

const Venues = () => {
    const { user } = useContext(AuthContext);
    const id = user?.vendor_id || Cookies.get("vendor_id") ? Number(Cookies.get("vendor_id")) : 0
    const { fetchVenues, venueList, deleteVenue,  loading, addVenue,venueQueryParams, setVenueQueryParams, page, setPage  } = useContext(VenueContext);

    return (
        <>
           
            <div className="super-admin">
                <VenuesCore
                    id={id} 
                    fetchVenues = {fetchVenues} 
                    venueList = {venueList} 
                    deleteVenue = {deleteVenue}  
                    loading = {loading}
                    addVenue = {addVenue}
                    queryParams = {venueQueryParams}
                    setQueryParams = {setVenueQueryParams}
                    page = {page}
                    setPage = {setPage}
                />
            </div>
           
        </>
    )
};

export default Venues;
