import Label from "components/common/label";
import SectionHeader from "./sectionHeader";
import { useContext, useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import * as Yup from 'yup';
import { VendorDetailSections } from ".";
import { useIsDisabled } from "hooks/useIsDisabled";
import { VendorContext } from "contexts/vendor";
import { VendorActions } from "contexts/reducers/vendor";
import ApiModal from "components/common/apiErrorModal";
import {LabeledInputSkeleton} from "components/common/skeleton";
import PhoneField from "components/common/phoneField";
import ZipField from "components/common/zipField";
import { phoneValidation, zipValidation } from "util/validationSchema/common";
import EditableInput from "components/common/editableInput";
import { CITY_CHAR_LIMIT, VENDOR_NAME_CHAR_LIMIT } from "appConstants";
import Select from "components/common/select";
import SaveIcon from "components/venue/SaveIcon";
import AddressField from "components/signup/venueSetup/addressField";
import { deleteObjectProp } from "util/deleteObjectProp";
import { AuthContext } from "contexts/auth";

const USA_STATES = ['AL','AK','AZ','AR','CA','CO','CT','DE','FL','GA','HI','ID','IL','IN','IA','KS','KY','LA','ME','MD','MA','MI','MN','MS','MO','MT','NE','NV','NH','NJ','NM','NY','NC','ND','OH','OK','OR','PA','RI','SC','SD','TN','TX','UT','VT','VA','WA','WV','WI','WY']



const VendorInfo = ({data, setActiveEdit, activeEdit}: any) => {
    const {updateVendorDetails, loading} = useContext(VendorContext);
   // const [zipcodeDisabled, setZipcodeDisabled] = useState(false);
   const {setVendorDetailsEditing} = useContext(AuthContext)

    const nameRef = useRef<any>(null);
    const addressRef = useRef<any>(null);
    const [success, setSuccess] = useState(false);
    const [tooltips, setTooltips] = useState({
        name: false,
        address: false
    });

    const [error, setError] = useState({
        status: null,
        message: ''
    });
    const [isEdit, setIsEdit] = useState(false);

    const {name, address, city, state, zipcode, phone, county} = data || {};
    
    const initialValues = {
        name: name || '',
        address: address || '',
        city: city || '',
        state: state || '',
        zipcode: zipcode || '',
        phone: phone || '',
        county: county || ''
    }

    const formik = useFormik({
        initialValues,
        enableReinitialize: true,
        validateOnBlur: false,
        validateOnChange: false,
        validationSchema: Yup.object({
            name: Yup.string().required('Required'),
            address: Yup.string().required('Required'),
            city: Yup.string().required('Required'),
            county: Yup.string().required('Required'),
            state: Yup.string().required('Req'),
            zipcode: zipValidation,
            phone: phoneValidation,
        }),
        onSubmit: async (values: any) => {
            const res = await updateVendorDetails(values);

            if(res?.response?.data?.message){
                setError({
                    message: res?.response?.data?.message,
                    status: res?.response?.status
                });
            }else if(res?.response?.status === 500){
                setError({
                    message: '',
                    status: res?.response?.status
                });
            }

            if(res?._status?.code === 200){
                setIsEdit(false);
                setActiveEdit('');
                setSuccess(true);
                setVendorDetailsEditing(false)

            }
        }
    });

    const {values, errors, handleBlur, handleReset, setErrors, handleChange, setFieldValue, handleSubmit, setValues} = formik;

    const onEdit = () => {
        setActiveEdit("Vendor Info")
        setIsEdit(true);
        setActiveEdit(VendorDetailSections.VENDOR_INFO);
        setVendorDetailsEditing(true)

    }

    const onClickCross = () => {
        setIsEdit(false);
        handleReset(initialValues);
        setActiveEdit('');
        setVendorDetailsEditing(false)

    }

    useEffect(() => {
        if(data?.name && nameRef.current){
            setTooltips(prev => ({
                ...prev,
                name: nameRef.current ? (nameRef.current.scrollWidth - nameRef.current.clientWidth > 0) : false
            }))
        }

        
        if(data?.address && addressRef.current){
            setTooltips(prev => ({
                ...prev,
                address: addressRef.current ? (addressRef.current.scrollWidth - addressRef.current.clientWidth > 0) : false
            }))
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[data, addressRef.current, nameRef.current]);

    const isDisabled = useIsDisabled(values, errors);
    const isLoading = loading[VendorActions.UPDATE_VENDOR_DETAILS];
    const vendorLoading = loading[VendorActions.GET_VENDOR_DETAILS];





    const onClickPlace = (addressData: any) => { 

        console.log(addressData.address);



        // setZipcodeDisabled(!!addressData.zipcode)
        const state = addressData?.state;
        const stateIndex = USA_STATES.findIndex((item: string) =>  item === state);
        if(stateIndex === -1){
            const option = document.createElement("option");
            option.text = addressData?.state;
            option.value = addressData?.state;
            const selectEl = document.getElementById('state-select');
            selectEl?.appendChild(option);
        }

        const {formatted_address, ...restAddressData} = addressData;
        setValues({
            ...values,
            ...restAddressData,
            ...(formatted_address && {address: formatted_address})
        });

        let errorFields: string[] = [];
        if(errors.city && restAddressData?.city){
            errorFields.push('city')
        }
        if(errors.state && restAddressData?.state){
            errorFields.push('state');
        }
        if(errors.zipcode && restAddressData.zipcode){
            errorFields.push('zipcode');
        }
        const updatedErrors = deleteAllProps(errors, errorFields);
        setErrors(updatedErrors);


    }

    const deleteAllProps = (obj: any, fields: string[]) => {
        let newObj = obj;
        fields.forEach((field: string) => {
            newObj = deleteObjectProp(newObj, field);
        });

        return newObj;
    }



    useEffect(()=>{
        const sucessTimer = setTimeout(()=>{
             if(success)
             setSuccess(false);
         },3000);
 
 
         return ()=>{
             clearTimeout(sucessTimer)
         }
         
     }, [success])

    return (
        <>
            <SectionHeader
                title="Vendor Name and Address"
                onClick={() => onEdit()}
                isEditMode={isEdit}
                onClickCross={onClickCross}
                onClickSubmit={handleSubmit}
                editDisabled={activeEdit && activeEdit !== VendorDetailSections.VENDOR_INFO}
                submitDisabled={isDisabled}
                isLoading={isLoading || vendorLoading}
                setSuccess = {setSuccess}
            />
            <div className="admin-info">
                <div className="detail-group vendor-name">
                    
                    <EditableInput
                        label="Name"
                        name="name"
                        value={values.name}
                        onChange={handleChange}
                        maxLength={VENDOR_NAME_CHAR_LIMIT}
                        placeholder={errors.name ? errors.name as string : ''}
                        isError={!!(errors.name)}
                        errors={errors}
                        setErrors={setErrors}
                        isLoading={vendorLoading}
                        isEdit={isEdit}
                    />
                </div>
                <div className="detail-group vendor-email">
                    {vendorLoading ? (
                        <LabeledInputSkeleton />
                    ):(
                        <>
                           {!isEdit ? <Label inputLabel={isEdit} text="Address" />:<></>}
                            {isEdit ? (
                                // <Input
                                //     name="address"
                                //     value={values.address}
                                //     onChange={handleChange}
                                //     onBlur={handleBlur}
                                //     placeholder={errors.address ? errors.address as string : ''}
                                //     isError={!!(errors.address)}
                                //     errors={errors}
                                //     setErrors={setErrors}
                                // />

                                <AddressField 
                                setFormValue={setFieldValue}
                                onClickPlace={onClickPlace}
                                address={values.address || ''}
                                error={errors.address}
                                onBlur={handleBlur}
                                onClickCross={()=>{}}
                              
                              
                              />
                            ):(
                                <div className="show-truncate-tooltip">
                                    <p ref={addressRef} className="field-text">{values.address || 'Not Yet'}</p>
                                    {tooltips.address && (
                                        <span className="truncate-tooltip">{values.address}</span>
                                    )}
                                </div>
                            )}
                        </>
                    )}
                </div>
                <div className="detail-fields-group address-fields">
                    <div className="detail-group vendor-city">
                        <EditableInput
                            label="City"
                            name="city"
                            value={values.city}
                            onChange={handleChange}
                            maxLength={CITY_CHAR_LIMIT}
                            placeholder={errors.city ? errors.city as string : ''}
                            isError={!!(errors.city)}
                            errors={errors}
                            setErrors={setErrors}
                            isLoading={vendorLoading}
                            isEdit={isEdit}
                        />
                        
                    </div>
                    <div className="detail-group vendor-state">
                        {vendorLoading ? (
                            <LabeledInputSkeleton />
                        ):(
                            <>
                                <Label inputLabel={isEdit} text="State" />
                                {isEdit ? (
                                     <Select 
                                     name="state"
                                     placeholder={errors.state as string || 'Enter state'}
                                     value={values.state}
                                     onChange={handleChange}
                                     isError={!!(errors.state)}
                                     options={USA_STATES.map((item: any) => ({label: item, value: item}))}
                                    
                                 />
                              
                                ):(
                                    <p className="field-text">{values.state || 'Not Yet'}</p>
                                )}
                            </>
                        )}
                        
                    </div>
                </div>
                <div className="detail-fields-group contact-fields">
                    <div className="detail-group vendor-zip">
                        {vendorLoading ? (
                            <LabeledInputSkeleton />
                        ):(
                            <>
                                <Label inputLabel={isEdit} text="Zip" />
                                {isEdit ? (
                                    <ZipField
                                        name="zipcode"
                                        value={values.zipcode}
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder={errors.zipcode ? errors.zipcode as string : ''}
                                        isError={!!(errors.zipcode)}
                                        errors={errors}
                                        setErrors={setErrors}
                                    />
                                ):(
                                    <p className="field-text">{values.zipcode || 'Not Yet'}</p>
                                )}
                            </>
                        )}
                        
                    </div>

                    <div className="detail-group vendor-county">
                        <EditableInput
                            label="County"
                            name="county"
                            value={values.county}
                            onChange={handleChange}
                            maxLength={CITY_CHAR_LIMIT}
                            placeholder={errors.county ? errors.county as string : ''}
                            isError={!!(errors.county)}
                            errors={errors}
                            setErrors={setErrors}
                            isLoading={vendorLoading}
                            isEdit={isEdit}
                        />
                        
                    </div>
                 
                    <div className="detail-group vendor-phone-number">
                        {vendorLoading ? (
                            <LabeledInputSkeleton />
                        ):(
                            <>
                                <Label inputLabel={isEdit} text="Phone" />
                                {isEdit ? (
                                    <PhoneField
                                        name="phone"
                                        value={values.phone}
                                        placeholder={errors?.phone as string || '123-456-7890'}
                                        isError={!!(errors?.phone)}
                                        errors={errors}
                                        setErrors={setErrors}
                                        setValue={setFieldValue}
                                        errorMessage={errors.phone}
                                    />
                                ):(
                                    <p className="field-text">{values.phone || 'Not Yet'}</p>
                                )}
                            </>
                        )}
                        
                    </div>
                </div>
              
            </div>
            {error.status && (
                <ApiModal 
                    title="Update Vendor Failed"
                    message={error.message}
                    setIsShow={() => setError({status: null, message: ''})}
                />
            )}


{
            <div className={`save-order ${success ? "save-order-animation" : ""}`}>
                 <span>   <SaveIcon /> Saved</span>
                </div>
            }
        </>
    )
};

export default VendorInfo;
