import DeleteIcon from "components/common/deleteIcon";
import EditIcon from "components/common/editIcon";
import Button from "components/common/button";
import SearchInput from "components/common/searchInput";
import {
  Table,
  TableBody,
  TableData,
  TableHead,
  TableHeading,
  TableRow,
} from "components/common/table";
import { useContext, useEffect, useState } from "react";
import moment from "moment";
import { useDebouncedCallback } from "use-debounce";
import AddAdminModal from "./addAdminModal";
import DeleteModal from "components/vendors/vendorsCore/deleteModal";
import BottomBar from "components/common/bottomBar";
import { Admin } from "interfaces/vendor";
import EditAdminModal from "./editAdminModal";
import TableSkeleton from "components/common/skeleton/tableSkeleton";
import LoginIcon from "components/common/loginIcon";
import { AuthContext } from "contexts/auth";
import LoginAsModal from "./loginModal";
import { AuthActions } from "contexts/reducers/auth";
import NoData from "components/noData";
import { useIsSuperAdmin } from "hooks/useIsSuperAdmin";
import ApiModal from "components/common/apiErrorModal";
import SortIcon from "components/common/sortIcon";
import { useLimit } from "hooks/useLimit";
import SaveIcon from "components/venue/SaveIcon";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import MoreDataSkeleton from "components/common/moreDataSkeleton";

const enum ModalType {
  Login = "login",
  Edit = "edit",
  Delete = "Delete",
  Add = "add",
}

const MODAL_INIT_STATE = {
  type: "",
  data: null!,
};

interface IProps {
  fetchAdmins: Function;
  adminList: any;
  deleteAdmin: Function;
  addAdmin: Function;
  loading: boolean;
  updateAdmin: Function;
  isDeleteLoading: boolean;
  isAdminsLoading: boolean;
  isEditAdminLoading: boolean;
  vendorId: number | null;
  isImperSonate?: boolean;
  page: number;
  setPage: Function;
  queryParams: any;
  setQueryParams: Function;
  IsFetchingMore: boolean;
}

const Admins = ({
  isEditAdminLoading,
  isAdminsLoading,
  isDeleteLoading,
  fetchAdmins,
  adminList,
  deleteAdmin,
  addAdmin,
  loading,
  updateAdmin,
  vendorId,
  isImperSonate = true,
  page,
  setPage,
  queryParams,
  setQueryParams,
  IsFetchingMore
}: IProps) => {
  const { fetchImpersonationToken, loading: authLoading } =
    useContext(AuthContext);
  const isSuperAdmin = useIsSuperAdmin();

  const {skeletonsCount} = useLimit();

  const [error, setError] = useState("");
  const [flash, setFlash] = useState(-1);
  const [modal, setModal] = useState<any>(MODAL_INIT_STATE);
  const [search, setSearch] = useState("");
  const [success, setSuccess] = useState(false);

  const fetchMoreAdmins = async () => {
    const nextPage = Math.ceil(queryParams.offset/queryParams.limit)+1;

    setPage(nextPage)
    const res = await getAdmins(({...queryParams, offset: nextPage*queryParams.limit}));

    return res;
}

  const { handleScroll, scrollTop } = useInfiniteScroll(fetchMoreAdmins, 560);

  const debouncedSearch = useDebouncedCallback((value: string) => {
    setPage(0);
    getAdmins({
      ...queryParams,
      offset: 0,
      search: value,
    });
  }, 600);

  const onSearch = (e: any) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  const onDeleteAdmin = async () => {
    const res = await deleteAdmin(modal.data?.id);

    if (res?.status === 204) {
      setModal(MODAL_INIT_STATE);
    }

    if (res?.response?.data?.message || res?.response?.data?.error) {
      setError(res?.response?.data?.message || res?.response?.data?.error);
    }
  };

  const onClickEdit = (admin: Admin) => {
    setModal({
      type: ModalType.Edit,
      data: admin,
    });
  };

  const onLoginAs = () => {
    fetchImpersonationToken(modal?.data?.id);
  };

  const onClickLoginAs = async (admin: Admin) => {
    setModal({
      type: "login",
      data: admin,
    });
  };

  const onAddAdmin = async (addAdminData: any) => {
    const res = await addAdmin(addAdminData);
    return res;
  };

  const onSetFlash = async (recordIndex: number) => {
    const newPage = Math.floor(recordIndex / 10);
    setPage(newPage);
    if (newPage > 0 && page !== newPage) {
      await getAdmins({
        ...queryParams,
        offset: newPage * queryParams.limit,
      });
    }

    const offsetIndex = recordIndex % queryParams.limit;

    setFlash(offsetIndex);
    setTimeout(() => {
      setFlash(-1);
    }, 1000);
  };

  const getAdmins = async (params: any) => {
    setQueryParams(params);
    const res = await fetchAdmins({ ...params, vendor_id: vendorId });
    return res;
  };

  const onSort = (order: string) => {
    getAdmins({
      ...queryParams,
      sort: order,
    });
  };

  useEffect(() => {
    !admins.length && getAdmins({ ...queryParams, search: "" });

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const { admins, _count } = adminList;

  const impersonationTokenLoading = authLoading.includes(
    AuthActions.GET_IMPERSONATION_TOKEN
  );

  useEffect(() => {
    const sucessTimer = setTimeout(() => {
      if (success) setSuccess(false);
    }, 3000);

    return () => {
      clearTimeout(sucessTimer);
    };
  }, [success]);

  return (
    <>
      <div className="add-venue-container">
        {
          <div
            className={`save-order ${success ? "save-order-animation" : ""}`}
          >
            <span>
              {" "}
              <SaveIcon /> Saved
            </span>
          </div>
        }
        <div className="super-admin_addVendorContainer_action">
          <div className="addVendor-btn">
            <Button
              buttonName="Add Admin"
              onClick={() => setModal({ ...modal, type: ModalType.Add })}
            />
          </div>

          <SearchInput
            isClear
            onChange={onSearch}
            value={search}
            placeholder="Search"
          />
        </div>
        <div className="admin-tab-table">
          <Table>
            <TableHead>
              <TableHeading
                customClass="cursor-pointer name"
                onClick={() =>
                  onSort(queryParams.sort.includes("-name") ? "name" : "-name")
                }
              >
                Name
                <SortIcon
                  parentCustomStyle={{ marginLeft: 10 }}
                  ascending={
                    queryParams.sort.includes("name")
                      ? !queryParams.sort.includes("-name")
                      : true
                  }
                />
              </TableHeading>
              <TableHeading customClass="email email-max">Email</TableHeading>
              <TableHeading customClass="last-login">Last login</TableHeading>
              <TableHeading customClass="option">Options</TableHeading>
            </TableHead>
            <TableBody
                onScroll={(e: any) => handleScroll(e, admins.length < _count)}
                isShowBlur={_count > skeletonsCount && scrollTop < 10}
            >
              {isAdminsLoading ? (
                <TableSkeleton count={skeletonsCount} />
              ) : admins?.length > 0 ? (
                admins.map((admin: Admin, index: number) => {
                  const { last_name, first_name, email, last_login } = admin;
                  return (
                    <TableRow nonClickableRow flash={flash === index}>
                      <TableData customClass="name">
                        {`${last_name}${first_name ? `, ${first_name}` : ""}`}
                      </TableData>
                      <TableData customClass="email email-max">
                        {email}
                      </TableData>
                      <TableData customClass="last-login">
                        {last_login
                          ? moment(last_login).format("M/D/YY")
                          : "Not Yet"}
                      </TableData>
                      <TableData customClass="option">
                        <EditIcon
                          customClass="edit-icon"
                          onClick={() => onClickEdit(admin)}
                        />
                        {isImperSonate && (
                          <LoginIcon onClick={() => onClickLoginAs(admin)} />
                        )}
                        <DeleteIcon
                          onClick={() =>
                            setModal({ type: ModalType.Delete, data: admin })
                          }
                        />
                      </TableData>
                    </TableRow>
                  );
                })
              ) : (
                <NoData description="No Admins" />
              )}
              <MoreDataSkeleton 
                isShow={queryParams.offset && IsFetchingMore}
              />
            </TableBody>
          </Table>
        </div>
      </div>

      {modal.type === ModalType.Delete && (
        <DeleteModal
          onSuccess={onDeleteAdmin}
          setIsShow={() => setModal(MODAL_INIT_STATE)}
          title="Delete Admin"
          disabledModal={!!error}
          loading={isDeleteLoading}
          name={`${modal.data?.first_name || ""} ${
            modal.data?.last_name || ""
          }`}
        />
      )}

      {modal.type === ModalType.Add && (
        <AddAdminModal
          loading={loading}
          addAdmin={(data: any) => onAddAdmin(data)}
          closeModal={() => setModal(MODAL_INIT_STATE)}
          setFlash={onSetFlash}
          vendor_id={vendorId}
        />
      )}

      {modal.type === ModalType.Edit && (
        <EditAdminModal
          closeModal={() => setModal(MODAL_INIT_STATE)}
          data={modal.data}
          updateAdmin={updateAdmin}
          setSuccess={setSuccess}
          loading={isEditAdminLoading}
          title={isSuperAdmin ? "Edit Super Admin" : "Edit Admin"}
        />
      )}

      {modal.type === ModalType.Login && (
        <LoginAsModal
          closeModal={() => setModal(MODAL_INIT_STATE)}
          firstName={modal?.data?.first_name}
          lastName={modal?.data?.last_name}
          handleSubmit={() => onLoginAs()}
          isDisabled={impersonationTokenLoading}
        />
      )}

      {error && (
        <ApiModal
          title="Delete Admin Failed"
          description={error}
          setIsShow={() => setError("")}
        />
      )}

      <BottomBar
        showAdd
        showSearch
        onClickAdd={() => setModal({ ...modal, type: ModalType.Add })}
      />
    </>
  );
};

export default Admins;
