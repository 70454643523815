import { VenueContextProvider } from "interfaces/venue"

export enum VenueActions {
    SET_VENUES = 'SET_VENUES',
    GET_VENUES = 'GET_VENUES',
    DELETE_VENUE = 'DELETE_VENUE',
    ADD_VENUE = 'ADD_VENUE',
    RESET_VENUE_LOADING = 'RESET_VENUE_LOADING',
    SET_VENUE_LOADING = 'SET_VENUE_LOADING',
    GET_VENUE_DETAILS = 'GET_VENUE_DETAILS',
    SET_QUERY_PARAMS = 'SET_QUERY_PARAMS',
}


export const VenueReducer = (state: VenueContextProvider, action: any) => {
    const { type, payload } = action
    switch (type) {
        case VenueActions.ADD_VENUE:
            return {
                ...state,
                venueList: {
                    ...state.venueList,
                    venues: [payload.vendor, ...state.venueList.venues],
                    _count: state.venueList._count+1
                }
            }
            case VenueActions.SET_QUERY_PARAMS:
            return {
                ...state,
                queryParams: payload
            }
        case VenueActions.SET_VENUE_LOADING:
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [payload]: true
                }
            }
        case VenueActions.RESET_VENUE_LOADING:
            const newLoadingState = {
                ...state.loading
            }
            delete newLoadingState[payload];
            return {
                ...state,
                loading: newLoadingState
            }
        case VenueActions.GET_VENUES:
            return {
                ...state,
                venueList: payload
            }
        case VenueActions.DELETE_VENUE:
            return {
                ...state,
                venueList: {
                    ...state.venueList,
                    venues: state.venueList.venues.filter((item: any) => item.id !== payload),
                    _count: state.venueList._count - 1
                }
            }
        default:
            return state
    }
}
