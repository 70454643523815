import {  useContext, useState } from "react";
import { ReportContext } from "contexts/report";
import { ReportActions } from "contexts/reducers/report";
import { Skeleton } from "components/common/skeleton";

import Price from "../price";


//const ITEMS_PER_PAGE = 11;


const Items= ({items, activeTab, isDataFetched}: any) => {
    const {  loading } = useContext(ReportContext);

    const [scrollTop, setScrollTop] = useState(0);
   // const { asd } = eventItems;
    
   

   const loadingState = activeTab === 0 ? ReportActions.GET_EVENT_SALES : ReportActions.GET_EVENT_DETAILS;
   
   // const [sort, setSort] = useState('DESC');
    // const [page, setPage] = useState(0);


    // const onClickSort = () => {
    //     if(sort === 'DESC'){
    //         setSort('ASC');
    //         selectedEvent?.id &&
    //         fetchEventItems({event_id: selectedEvent?.id, sort_order: 'ASC', sort_by: 'quantity'})
    //     }else{
    //         setSort('DESC');
    //         selectedEvent?.id &&
    //         fetchEventItems({event_id: selectedEvent?.id, sort_order: 'DESC', sort_by: 'quantity'})
    //     }
    // }

   

   







    //const slicedItems = items?.slice(page*ITEMS_PER_PAGE, (page+1)*ITEMS_PER_PAGE);

    //const emptyRowsCount = ITEMS_PER_PAGE - items?.length;
    
    const emptyRowsCount = items?.length<10 ? 10-items.length : 0;
    
    const emptyRows = emptyRowsCount > 0 ? [...new Array(emptyRowsCount)] : [];
   // const noOfPages = Math.ceil(items?.length > ITEMS_PER_PAGE ? items?.length / ITEMS_PER_PAGE : 0);
    const isLoading = loading[loadingState];




    const getLengthClass = (quantity: string) => {

        const classesToReturn = {
            divClass: '',
            nameClass: ''
        }


        if(quantity.toString().length+1 === 2) {
            classesToReturn.divClass='qty-2';
            classesToReturn.nameClass='qty-food-item-2'

        }
        else if(quantity.toString().length+1 === 3) {
            classesToReturn.divClass='qty-3';
            classesToReturn.nameClass='qty-food-item-3'
        }
        else if(quantity.toString().length+1 === 4) {
            classesToReturn.divClass='qty-4';
            classesToReturn.nameClass='qty-food-item-4'
    }
    else if(quantity.toString().length+1 === 5) {
            classesToReturn.divClass='qty-5';
            classesToReturn.nameClass='qty-food-item-5'
}
else if(quantity.toString().length+1 === 6) {
            classesToReturn.divClass='qty-6';
            classesToReturn.nameClass='qty-food-item-6'
}

return classesToReturn;
}


    const quantityArray = items?.map((item: any)=> {return item?.quantity});

    let largestQuantity = 0;
    if(quantityArray){
        largestQuantity = Math.max(...quantityArray);
    }


    return (
       
        <>
            
                <div className="items-table-container">
                    <div className="items-table">
                        <div className="items-table-thead">
                            <div className="items-table-header">
                                {isDataFetched ? 
                                <div className="item-col th-col"> Name  </div>: <Skeleton width={290} animation = {false}/>}
                                
                                {isDataFetched ? 
                                <div className="varient-col th-col">Variant </div> : <Skeleton width={110} animation = {false}/>}
                               
                                {isDataFetched ? <div className="option-col th-col"> 
                                    <span className="">Options</span>
                                    <a href="#/" className="info-tooltip">
                                        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M12 1.5C6.21028 1.5 1.5 6.21028 1.5 12C1.5 17.7897 6.21028 22.5 12 22.5C17.7897 22.5 22.5 17.7897 22.5 12C22.5 6.21028 17.7897 1.5 12 1.5ZM12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0Z" fill="black"/>
                                            <path d="M11.8321 14.736C11.6508 14.736 11.5015 14.672 11.3841 14.544C11.2775 14.416 11.2241 14.24 11.2241 14.016C11.2241 13.4293 11.3308 12.9333 11.5441 12.528C11.7575 12.1227 12.0668 11.68 12.4721 11.2C12.7921 10.816 13.0268 10.496 13.1761 10.24C13.3255 9.984 13.4001 9.696 13.4001 9.376C13.4001 9.024 13.2668 8.74667 13.0001 8.544C12.7441 8.33067 12.3921 8.224 11.9441 8.224C11.5601 8.224 11.1921 8.30933 10.8401 8.48C10.4881 8.64 10.0775 8.87467 9.60813 9.184C9.34146 9.33333 9.12279 9.408 8.95213 9.408C8.77079 9.408 8.61079 9.328 8.47213 9.168C8.34413 8.99733 8.28012 8.8 8.28012 8.576C8.28012 8.26667 8.40813 8.016 8.66413 7.824C9.11213 7.44 9.64013 7.14133 10.2481 6.928C10.8561 6.704 11.4695 6.592 12.0881 6.592C12.7388 6.592 13.3148 6.704 13.8161 6.928C14.3281 7.152 14.7228 7.46133 15.0001 7.856C15.2881 8.25067 15.4321 8.704 15.4321 9.216C15.4321 9.77067 15.2988 10.256 15.0321 10.672C14.7655 11.0773 14.3761 11.536 13.8641 12.048C13.4268 12.496 13.0961 12.8747 12.8721 13.184C12.6588 13.4827 12.5255 13.824 12.4721 14.208C12.4401 14.3787 12.3655 14.512 12.2481 14.608C12.1415 14.6933 12.0028 14.736 11.8321 14.736ZM11.8641 18.096C11.5228 18.096 11.2401 17.984 11.0161 17.76C10.7921 17.5253 10.6801 17.2373 10.6801 16.896C10.6801 16.5547 10.7921 16.272 11.0161 16.048C11.2401 15.8133 11.5228 15.696 11.8641 15.696C12.2055 15.696 12.4881 15.8133 12.7121 16.048C12.9361 16.272 13.0481 16.5547 13.0481 16.896C13.0481 17.2373 12.9308 17.5253 12.6961 17.76C12.4721 17.984 12.1948 18.096 11.8641 18.096Z" fill="black"/>
                                        </svg>
                                        <span className="infoTtooltip">Options</span>
                                    </a>
                                </div>: <div className="option-col-skeleton"><Skeleton width={65} animation = {false}/> <Skeleton width={24} height={24} animation = {false}/></div> }

                                {isDataFetched ? 
                                <div className="price-col th-col"> Item<br /> Price</div>: 
                                <div className="price-col th-col"> <Skeleton width={120} animation = {false}/>  </div>
                                }
                                
                                {isDataFetched ? 
                                <div className="total-col th-col">Total<br /> Sales</div> : 
                                <div className="price-col th-col"> <Skeleton width={120} animation = {false}/>  </div>
                                }

                            </div>
                        </div>
                        <div onScroll={(e:any) => setScrollTop(e.target.scrollTop)} className="item-table-body">
                            {isLoading  ? (
                                <>
                                    <Skeleton height={46} />
                                    <Skeleton height={46} />
                                    <Skeleton height={46} />
                                    <Skeleton height={46} />
                                    <Skeleton height={46} />
                                    <Skeleton height={46} />
                                    <Skeleton height={46} />
                                    <Skeleton height={46} />
                                    <Skeleton height={46} />
                                    <Skeleton height={46} />
                                    <Skeleton height={46} />

                                </>
                            ): !isDataFetched ? (<>
                            <Skeleton animation = {false} height={46} />
                            <Skeleton animation = {false} height={46} />
                            <Skeleton animation = {false} height={46} />
                            <Skeleton animation = {false} height={46} />
                            <Skeleton animation = {false} height={46} />
                            <Skeleton animation = {false} height={46} />
                            <Skeleton animation = {false} height={46} />
                            <Skeleton animation = {false} height={46} />
                            <Skeleton animation = {false} height={46} />
                            <Skeleton animation = {false} height={46} />
                            <Skeleton animation = {false} height={46} />
                            
                            
                            </>) : (
                                <>
                                    {items?.map((item: any) => {
                                        // const formattedPrice = item?.price ? '$' + item?.price?.toString()?.replace(insignificantZerosRegex, '') : '.00';
                                        // const totalPrice = item?.total ? '$' + item?.total?.toString()?.replace(insignificantZerosRegex, '') : '.00';
                                        
                                        const formattedQuantity = item.quantity?.toString()?.length > 4 ? '9999+' : item.quantity;




                                          const classes = getLengthClass(largestQuantity.toString());
                                          const divClass = classes?.divClass;
                                          const nameClass = classes?.nameClass;
                                        
                                        return (
                                            <div key={item.id} className="item-table-row">
                                                <div className="item-col td-col">
                                                <div className="sale-qty">
                                                        <div className={`qty ${divClass}`}>
                                                            {formattedQuantity ? (
                                                                <p className={`${item.quantity?.toString()?.length > 1 ? 'qty-indicator' : ''}`}>
                                                                    {formattedQuantity}
                                                                    <span>x</span>
                                                                </p>
                                                            ):(
                                                                <p>0</p>
                                                            )}
                                                        </div>
                                                       <p className={`food-item ${nameClass}`}> {item.name}</p>
                                                    </div>                                                    
                                                </div>
                                                <div className="varient-col td-col">
                                                    {item.variant ? 
                                                    <div className="varient-label">{item.variant}</div>: 
                                                    <></> }
                                                </div>


                                                <div className="option-col td-col">

                                                    {item.options.map((option: {name: string, price: string})=> <div className="option-label">{option.name}</div>
                                                        
                                                    )}
                                                </div>



                                                <div className="price-col td-col">
                                                    <Price width={20} amount={item?.item_price} />
                                                </div>
                                                <div className="total-col td-col">
                                                    <Price width={22} amount={item?.total_price} />
                                                </div>
                                            </div>
                                        )
                                    })}
                                    {emptyRows.map((_, index: number) => {
                                        return (
                                            <div key={index} className="item-table-row" />
                                        )
                                    })}
                                </>
                                
                            )}
                            
                        </div>
                    </div>
                    {items?.length > 10 && scrollTop < 10 && (
                        <div className='more-data'></div>
                    )}
                </div>
                {/* <div className="items-footer">
                    <ReportsPagination count={noOfPages} activePage={page} onClickArrow={(page: number) => setPage(page)} />
                </div> */}
                
            
        </>
    )
};

export default Items;
