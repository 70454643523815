import { createContext, useReducer } from "react";
import {
  CHECKLIST,
  ImageLibraryRequest,
  LibraryResourceRequest,
  MenuRequest,
  PostVenueRequest,
  OnboardingContextProvider,
  checkListMapper,
} from "interfaces/onboarding";
import { OnboardingActions, OnboardReducer } from "./reducers/onbaording";
import {
  addMenu,
  getLibraryResource,
  getOnboardingDetails,
  postVenues,
  putAgreement,
  sendEmailAgreement,
} from "services/onboarding";
import { useLocation, useNavigate } from "react-router-dom";

const initialState: OnboardingContextProvider = {
  loading: [],
  vendorOnboardingDetails: null!,
  acceptAgreement: () => {},
  markBillingPaid: () => {},
  markStripeConnected: () => {},
  setLoading: () => {},
  resetLoading: () => {},
  fetchonboardingDetails: () => {},
  selectedStep: CHECKLIST.Agreement,
  setSelectedStep: (step: string) => {},
  addVenues: () => {},
  getImageLibrary: () => new Promise<any>(() => {}),
  fetchLibraryResource: () => new Promise<any>(() => {}),
  foodImageLibrary: null,
  menu: null,
  addCSVMenu: () => new Promise<any>(() => {}),
  emailAgreement: (vendor_id: number) => new Promise<any>(() => {}),
  setOnboardingDetails: () => {},
};

export const OnboardingContext = createContext<OnboardingContextProvider>(
  null!
);

const OnboardingProvider = ({ children }: any) => {
  const location = useLocation();
  const navigate = useNavigate();

  const initState: OnboardingContextProvider = {
    ...initialState,
    selectedStep: location?.state?.onboardingStep || CHECKLIST.Agreement,
  };

  const [
    { selectedStep, loading, vendorOnboardingDetails, foodImageLibrary, menu },
    dispatch,
  ] = useReducer(OnboardReducer, initState);

  const acceptAgreement = async () => {
    setLoading(OnboardingActions.SET_ACCEPT_AGREEMENT);
    dispatch({
      type: OnboardingActions.SET_ONBOARDING_DETAILS,
      payload: {
        ...vendorOnboardingDetails,
        agreement_accepted: true,
      },
    });
    const result = await putAgreement();
    resetLoading(OnboardingActions.SET_ACCEPT_AGREEMENT);

    if (!result) {
      dispatch({
        type: OnboardingActions.SET_ONBOARDING_DETAILS,
        payload: {
          ...vendorOnboardingDetails,
          agreement_accepted: false,
        },
      });
    }

    return result;
  };
  const markBillingPaid = async () => {
    dispatch({
      type: OnboardingActions.SET_ONBOARDING_DETAILS,
      payload: {
        ...vendorOnboardingDetails,
        billing: { paid: true },
      },
    });
  };


  const markStripeConnected = async () => {
    dispatch({
      type: OnboardingActions.SET_ONBOARDING_DETAILS,
      payload: {
        ...vendorOnboardingDetails,
        connected_account_stripe: { connected: true },
      },
    });
  };
  const addVenues = async (venueData: PostVenueRequest) => {
    setLoading(OnboardingActions.SET_VENUES);
    const res = await postVenues(venueData);
    resetLoading(OnboardingActions.SET_VENUES);

    if (res?._status?.code === 201) {
      dispatch({
        type: OnboardingActions.SET_ONBOARDING_DETAILS,
        payload: {
          ...vendorOnboardingDetails,
          onboarding_step: "MENU",
        },
      });
    }

    return res;
  };

  const getImageLibrary = async (imageLibraryreq: ImageLibraryRequest) => {
    setLoading(OnboardingActions.SET_FOOD_IMAGE_LIBRARY);
    const result = await getLibraryResource(imageLibraryreq);
    resetLoading(OnboardingActions.SET_FOOD_IMAGE_LIBRARY);

    if (result?._status?.code === 200) {
      dispatch({
        type: OnboardingActions.SET_FOOD_IMAGE_LIBRARY,
        payload: result.data,
      });
    }

    return result;
  };

  const fetchLibraryResource = async (libraryReq: LibraryResourceRequest) => {
    setLoading(OnboardingActions.SET_RESOURCE_LIBRARY);
    const result = await getLibraryResource(libraryReq);
    resetLoading(OnboardingActions.SET_RESOURCE_LIBRARY);

    return result;
  };

  const setOnboardingDetails = (payload: any) => {
    dispatch({
      type: OnboardingActions.SET_ONBOARDING_DETAILS,
      payload,
    });
    payload?.onboarding_pending
      ? setSelectedStep(
          checkListMapper[
            payload?.onboarding_step as keyof typeof checkListMapper
          ]
        )
      : navigate("/dashboard");
  };

  const fetchonboardingDetails = async () => {
    setLoading(OnboardingActions.SET_ONBOARDING_DETAILS);
    const res = await getOnboardingDetails();
    resetLoading(OnboardingActions.SET_ONBOARDING_DETAILS);

    if (res?._status?.code === 200) {
      setOnboardingDetails(res.data);
    }

    if (res?.response?.data?.code === 501) {
      navigate("/dashboard");
    }

    return res;
  };

  const emailAgreement = async () => {
    setLoading(OnboardingActions.SEND_EMAIL_AGREEMENT);
    const res = await sendEmailAgreement(vendorOnboardingDetails?.vendor?.id);
    resetLoading(OnboardingActions.SEND_EMAIL_AGREEMENT);

    return res;
  };

  const addCSVMenu = async (menuReq: MenuRequest) => {
    const isConfirmed = menuReq?.confirm === "true";
    isConfirmed
      ? setLoading(OnboardingActions.SET_SAVE_AND_SUBMIT)
      : setLoading(OnboardingActions.SET_MENU);
    const res = await addMenu(menuReq);
    isConfirmed
      ? resetLoading(OnboardingActions.SET_SAVE_AND_SUBMIT)
      : resetLoading(OnboardingActions.SET_MENU);

    const isError = res?.data?.data?.some(
      (item: any) => item?.errors?.length > 0
    );

    if (isConfirmed && res?._status?.code === 201 && !isError) {
      dispatch({
        type: OnboardingActions.SET_ONBOARDING_DETAILS,
        payload: {
          ...vendorOnboardingDetails,
          menu_completed: true,
        },
      });
    }

    return res;
  };

  const setSelectedStep = (step: string) => {
    dispatch({
      type: OnboardingActions.SET_SELECTED_STEP,
      payload: step,
    });
  };

  const setLoading = (loading: string) => {
    dispatch({
      type: OnboardingActions.SET_VENDOR_LOADING,
      payload: loading,
    });
  };

  const resetLoading = (loading: string) => {
    dispatch({
      type: OnboardingActions.RESET_VENDOR_LOADING,
      payload: loading,
    });
  };

  return (
    <OnboardingContext.Provider
      value={{
        loading,
        vendorOnboardingDetails,
        acceptAgreement,
        markBillingPaid,
        markStripeConnected,
        setLoading,
        resetLoading,
        fetchonboardingDetails,
        selectedStep,
        setSelectedStep,
        addVenues,
        getImageLibrary,
        fetchLibraryResource,
        foodImageLibrary,
        menu,
        addCSVMenu,
        emailAgreement,
        setOnboardingDetails,
      }}
    >
      {children}
    </OnboardingContext.Provider>
  );
};
export default OnboardingProvider;
