const QuestionMarkCircle = ({isDisabled, text}: any) => {
    return (
        <a href="#/" className={`info-tooltip ${isDisabled ? 'disabled' : ''}`}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 1.5C6.21028 1.5 1.5 6.21028 1.5 12C1.5 17.7897 6.21028 22.5 12 22.5C17.7897 22.5 22.5 17.7897 22.5 12C22.5 6.21028 17.7897 1.5 12 1.5ZM12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0Z" fill="white"/>
                <path d="M11.8321 14.736C11.6508 14.736 11.5015 14.672 11.3841 14.544C11.2775 14.416 11.2241 14.24 11.2241 14.016C11.2241 13.4293 11.3308 12.9333 11.5441 12.528C11.7575 12.1227 12.0668 11.68 12.4721 11.2C12.7921 10.816 13.0268 10.496 13.1761 10.24C13.3255 9.984 13.4001 9.696 13.4001 9.376C13.4001 9.024 13.2668 8.74667 13.0001 8.544C12.7441 8.33067 12.3921 8.224 11.9441 8.224C11.5601 8.224 11.1921 8.30933 10.8401 8.48C10.4881 8.64 10.0775 8.87467 9.60813 9.184C9.34146 9.33333 9.12279 9.408 8.95213 9.408C8.77079 9.408 8.61079 9.328 8.47213 9.168C8.34413 8.99733 8.28012 8.8 8.28012 8.576C8.28012 8.26667 8.40813 8.016 8.66413 7.824C9.11213 7.44 9.64013 7.14133 10.2481 6.928C10.8561 6.704 11.4695 6.592 12.0881 6.592C12.7388 6.592 13.3148 6.704 13.8161 6.928C14.3281 7.152 14.7228 7.46133 15.0001 7.856C15.2881 8.25067 15.4321 8.704 15.4321 9.216C15.4321 9.77067 15.2988 10.256 15.0321 10.672C14.7655 11.0773 14.3761 11.536 13.8641 12.048C13.4268 12.496 13.0961 12.8747 12.8721 13.184C12.6588 13.4827 12.5255 13.824 12.4721 14.208C12.4401 14.3787 12.3655 14.512 12.2481 14.608C12.1415 14.6933 12.0028 14.736 11.8321 14.736ZM11.8641 18.096C11.5228 18.096 11.2401 17.984 11.0161 17.76C10.7921 17.5253 10.6801 17.2373 10.6801 16.896C10.6801 16.5547 10.7921 16.272 11.0161 16.048C11.2401 15.8133 11.5228 15.696 11.8641 15.696C12.2055 15.696 12.4881 15.8133 12.7121 16.048C12.9361 16.272 13.0481 16.5547 13.0481 16.896C13.0481 17.2373 12.9308 17.5253 12.6961 17.76C12.4721 17.984 12.1948 18.096 11.8641 18.096Z" fill="white"/>
            </svg>
            <span className="infoTtooltip">{text || 'TEXT'}</span>
        </a>
    )
};

export default QuestionMarkCircle;


export const QuestionMarkCircleBlack = ({isDisabled, text}: any) => {
    return (
        <a href="#/" className={`info-tooltip ${isDisabled ? 'disabled' : ''}`}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M12 1.5C6.21028 1.5 1.5 6.21028 1.5 12C1.5 17.7897 6.21028 22.5 12 22.5C17.7897 22.5 22.5 17.7897 22.5 12C22.5 6.21028 17.7897 1.5 12 1.5ZM12 0C18.6274 0 24 5.37258 24 12C24 18.6274 18.6274 24 12 24C5.37258 24 0 18.6274 0 12C0 5.37258 5.37258 0 12 0Z" fill="black"/>
                <path d="M11.8321 14.736C11.6508 14.736 11.5015 14.672 11.3841 14.544C11.2775 14.416 11.2241 14.24 11.2241 14.016C11.2241 13.4293 11.3308 12.9333 11.5441 12.528C11.7575 12.1227 12.0668 11.68 12.4721 11.2C12.7921 10.816 13.0268 10.496 13.1761 10.24C13.3255 9.984 13.4001 9.696 13.4001 9.376C13.4001 9.024 13.2668 8.74667 13.0001 8.544C12.7441 8.33067 12.3921 8.224 11.9441 8.224C11.5601 8.224 11.1921 8.30933 10.8401 8.48C10.4881 8.64 10.0775 8.87467 9.60813 9.184C9.34146 9.33333 9.12279 9.408 8.95213 9.408C8.77079 9.408 8.61079 9.328 8.47213 9.168C8.34413 8.99733 8.28012 8.8 8.28012 8.576C8.28012 8.26667 8.40813 8.016 8.66413 7.824C9.11213 7.44 9.64013 7.14133 10.2481 6.928C10.8561 6.704 11.4695 6.592 12.0881 6.592C12.7388 6.592 13.3148 6.704 13.8161 6.928C14.3281 7.152 14.7228 7.46133 15.0001 7.856C15.2881 8.25067 15.4321 8.704 15.4321 9.216C15.4321 9.77067 15.2988 10.256 15.0321 10.672C14.7655 11.0773 14.3761 11.536 13.8641 12.048C13.4268 12.496 13.0961 12.8747 12.8721 13.184C12.6588 13.4827 12.5255 13.824 12.4721 14.208C12.4401 14.3787 12.3655 14.512 12.2481 14.608C12.1415 14.6933 12.0028 14.736 11.8321 14.736ZM11.8641 18.096C11.5228 18.096 11.2401 17.984 11.0161 17.76C10.7921 17.5253 10.6801 17.2373 10.6801 16.896C10.6801 16.5547 10.7921 16.272 11.0161 16.048C11.2401 15.8133 11.5228 15.696 11.8641 15.696C12.2055 15.696 12.4881 15.8133 12.7121 16.048C12.9361 16.272 13.0481 16.5547 13.0481 16.896C13.0481 17.2373 12.9308 17.5253 12.6961 17.76C12.4721 17.984 12.1948 18.096 11.8641 18.096Z" fill="black"/>
            </svg>
            <span className="infoTtooltip">{text || 'TEXT'}</span>
        </a>
    )
};

