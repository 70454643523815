import SearchInput from "components/common/searchInput";
import { Table, TableBody } from "components/common/table";
import { VendorContext } from "contexts/vendor";
import { ChangeEvent, FC, Fragment, useContext, useEffect, useMemo, useState } from "react";
import LeadHeader from "./header";
import Accordian from "./accordian";
import LeadStatuses from "./statuses";
import NoData from "components/noData";
import { useDebouncedCallback } from "use-debounce";
import VendorsCore from 'components/vendors/vendorsCore'
import AddLeadModal from "./addModal";
import TableSkeleton from "components/common/skeleton/tableSkeleton";
import { VendorActions } from "contexts/reducers/vendor";
import Button from "components/common/button";
import BottomBar from "components/common/bottomBar";
import InfoModal from "components/common/infoModal";
import { LeadsStatus } from "interfaces/vendor";
import { useLimit } from "hooks/useLimit";
import { useInfiniteScroll } from "hooks/useInfiniteScroll";
import MoreDataSkeleton from "components/common/moreDataSkeleton";
import SaveIcon from "components/venue/SaveIcon";

interface Props {
    fromLocation?: any
}

const Leads: FC<Props> = ({fromLocation}) => {
    const { addLead, setLoading, fetchLeads, leadList, loading, setQueryParams, queryParams, salesKitSuccessModal, setSalesKitSuccessModal, leadsClickedList, setLeadsClickedList,  setLeadsPage } = useContext(VendorContext);
    const {leads, _count, enable_filters} = leadList;
    

    const [flash, setFlash] = useState(false);
    const [showModal, setShowModal] = useState(false);
    const [search, setSearch] = useState('');
    const [from, setFrom] = useState(fromLocation?.from);
    const [success, setSuccess] = useState(false);

    const {skeletonsCount} = useLimit();

    const fetchMoreLeads = async () => {
        const nextPage = Math.ceil(queryParams.offset/queryParams.limit)+1
        setLeadsPage(nextPage)
        const res = await fetchLeadList(({...queryParams, offset: nextPage*queryParams.limit}));
        return res;
    }

    const { handleScroll, scrollTop } = useInfiniteScroll(fetchMoreLeads, 560);

    const filteredLeads = useMemo(() => {
        if(queryParams.statusList.length === 0){
            return leads;
        }
        return leads.filter((item: any) => queryParams.statusList.includes(item.status));

        /* eslint-disable react-hooks/exhaustive-deps */
    },[leads]);

    const isStatusAlreadyExists = (status: string) => {
        const isStatusAlreadyExists = queryParams.statusList.includes(status);
        return isStatusAlreadyExists;
    }

    const getUpdatedStatusList = (status: string, isStatusExists: boolean) => {
        const statuses = isStatusExists
        ? queryParams.statusList.filter((st: string) => st.toLowerCase() !== status.toLowerCase())
        : [...queryParams.statusList, status];

        const statusString = statuses.join(',');

        return {
            statusList: statuses,
            status: statusString
        }
    }

    const onClickStatus = (status: string) => {

        console.log(status);


        setFrom("");
        setLeadsPage(0);
        setLeadsClickedList([]);
        const isStatusExists = isStatusAlreadyExists(status);

        const updatedStatus = getUpdatedStatusList(status, isStatusExists);
        const updatedQuery = {...queryParams, offset:0}
        
        
        if(updatedStatus){
            const newState = {
                ...updatedQuery,
                ...updatedStatus
            }
            fetchLeadList(newState);
        }
    }

    const onAddLead = async (data: any) => {
        setLoading(VendorActions.ADD_LEAD);
        const isStatusExists = isStatusAlreadyExists(LeadsStatus.NEW);

        if(isStatusExists){
            const res = await addLead(data);
            if (res?.response?.data?.message || res?.response?.status === 500) {
                return res;
            }
            fetchLeadList({...queryParams, offset:0});
            return res;
        }else{
            const updatedStatus = getUpdatedStatusList(LeadsStatus.NEW, false);
            const updatedQuery = {...queryParams, offset:0}

            const newState = {
                ...updatedQuery,
                ...updatedStatus
            }
            const res = await addLead(data);
            if (res?.response?.data?.message || res?.response?.status === 500) {
                return res
            }
            await fetchLeadList(newState);
            return res;
        }
    }

    const debouncedSearch = useDebouncedCallback((value: string) => {
        setLeadsPage(0);
        fetchLeadList({
            ...queryParams,
            offset: 0,
            search: value,
            sort_order: '',
            status: ""
        })
    },600)

    const onSearch = (value: string) => {
        setLeadsClickedList([]);
        setSearch(value);
        debouncedSearch(value);
    }

    const onClickNewLead = () => {
        setShowModal(true);
    }

    const fetchLeadList = async (queryParams: any) => {        
        setQueryParams(queryParams);

        const { statusList, ...restParams } = queryParams;
        const res = await fetchLeads({...restParams} );

        return res;
    }


    const closeAddLeadModalHandler = ()=>{
    
        setShowModal(false);


    }

    useEffect(() => {
        if(leads.length) return;

        if(from === "superDashboard") {
            onClickStatus("NEW")
        }else{
            fetchLeadList(queryParams)
        }

        /* eslint-disable react-hooks/exhaustive-deps */
    },[]);

    useEffect(()=>{
        const sucessTimer = setTimeout(()=>{
             if(success)
             setSuccess(false);
         },3000);
    
    
         return ()=>{
             clearTimeout(sucessTimer)
         }
         
     }, [success])


    const getLeadsLoading = loading[VendorActions.GET_LEADS]

    const isSearch = queryParams.search;

    const checkClickedStatus = (id: number): number=>  leadsClickedList.indexOf(id);


    return (
        <>
            <div className="super-admin_addVendorContainer_action">
                <div className="addVendor-btn">
                    <Button
                        buttonName="New Lead"
                        onClick={onClickNewLead}
                        disabled={isSearch}
                    />
                </div>
                <SearchInput 
                    isClear
                    value={search} 
                    onChange={(e: ChangeEvent<HTMLInputElement>) => onSearch(e.target.value)} 
                    placeholder="Search"
                />        
            </div>
            <div className="lead-container">
                
                <LeadStatuses 
                    status={isSearch ? '' : queryParams.statusList}
                    onClickStatus={onClickStatus}
                    disableAll={isSearch}
                    availableStatuses = {enable_filters}
                />
                {isSearch && (
                    <div>
                        <p className="accordian-table-title">Vendors</p>
                        <VendorsCore
                            isHeader={false}        
                            vendorSearch={queryParams.search} 
                            fromLeads = {true}
                            
                        />
                    </div>
                )}
                {isSearch && <p className="accordian-table-title">Leads</p>}
                <div className="admin-data-table">
                    {filteredLeads.length > 0 || getLeadsLoading ? (
                        <>

            {
            <div className={`save-order ${success ? "save-order-animation" : ""}`}>
                 <span>   <SaveIcon /> Saved</span>
                </div>
            }

                            <Table>
                                <LeadHeader />
                                <TableBody 
                                    onScroll={(e: any) => handleScroll(e, leads.length < _count)}
                                    isShowBlur={_count > skeletonsCount && scrollTop < 10}
                                >
                                    {getLeadsLoading ? (
                                        <TableSkeleton count={skeletonsCount} />
                                    ):(
                                        filteredLeads.map((lead:any, index: number) => {
                                            const leadStatusIndex = checkClickedStatus(lead.id);
                                            return (
                                                <Fragment key={lead.id} >
                                                    <Accordian
                                                        data={lead}
                                                        flash={index === 0 && flash}
                                                        open={leadStatusIndex !== -1 ? true : false}
                                                        setSuccess = {setSuccess}

                                                    />
                                                </Fragment>
                                            )
                                        })
                                    )}
                                    <MoreDataSkeleton
                                        isShow={queryParams.offset > 0 && loading[VendorActions.GET_LEADS_MORE]} 
                                    />
                                </TableBody>
                            </Table>
                        </>
                    ):(
                        <NoData description="No Leads" />
                    )}
                </div>
                <BottomBar 
                    showSearch
                    showAdd
                    onClickAdd={() => setShowModal(true)}
                    addActive={showModal}
                />
            </div>
            {showModal && (
                <AddLeadModal
                    addLead={onAddLead}
                    closeModal={closeAddLeadModalHandler}
                    setFlash={setFlash}
                />
            )}

            {salesKitSuccessModal && (
                <InfoModal
                    title="Send Sales Kit"
                    description={`Sales Kit is sent to ${salesKitSuccessModal?.name || ''} successfully!`}
                    onSubmit={() => setSalesKitSuccessModal(false)}
                />
            )}
        </>
    )
};

export default Leads;
