const SimpleLoader = () => {
    return (

        <div className="simple-loader">
            <div className="dot-elastic"></div>
        </div>

    )
};

export default SimpleLoader;