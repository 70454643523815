const SignagePreview = ({children}:{children: React.ReactNode}) => {

    return (
        <div className="sign-priview">
            {children}
        </div>
    )
};

export default SignagePreview;
