/* eslint-disable no-useless-escape */
import Label from "components/common/label";
import SectionHeader from "./sectionHeader";
import { useFormik } from "formik";
import * as Yup from "yup";
import { deleteObjectProp } from "util/deleteObjectProp";
import { useIsDisabled } from "hooks/useIsDisabled";
import Button from "components/common/button";
import { useContext, useEffect, useState } from "react";
import { VendorContext } from "contexts/vendor";
import ApiModal from "components/common/apiErrorModal";
import { VendorActions } from "contexts/reducers/vendor";
import { useParams } from "react-router-dom";
import { VendorDetailSections } from ".";
import moment from "moment";
import LabelSkeleton from "components/common/skeleton/label";
import _ from "lodash";
import InfoModal from "components/common/infoModal";
import EditableInput from "components/common/editableInput";
import { EMAIL_CHAR_LIMIT, NAME_CHAR_LIMIT } from "appConstants";
import { AuthContext } from "contexts/auth";

const SendWelcomeEmailToProspect = ({
  data,
  setActiveEdit,
  activeEdit,
}: any) => {
  const {
    sendWelcomeEmail,
    loading,
    fetchVendorOnbaordingStatus,
    updateVendorDetails,
  } = useContext(VendorContext);

 

  //const shouldEdit = data?.email_sent?.length>0 ? false : true;

  const { id } = useParams();
  const vendorId = id ? parseInt(id, 10) : 0;
  const { setVendorDetailsEditing,  } = useContext(AuthContext);
  const [isEdit, setIsEdit] = useState<boolean>(true);
  const [isSameEmail, setIsSameEmail] = useState(false);
  const [error, setError] = useState({
    status: null,
    message: "",
  });

  const initialValues = {
    first_name: data?.first_name || "",
    last_name: data?.last_name || "",
    prospect_email: data?.email || "",
  };

  const formik = useFormik({
    initialValues,
    enableReinitialize: true,
    validateOnBlur: false,
    validateOnChange: false,
    validationSchema: Yup.object({
      first_name: Yup.string().required("Required"),
      last_name: Yup.string().required("Required"),
      prospect_email: Yup.string()
        .matches(
          /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
          "Enter valid email"
        )
        .required("Prospect email is required"),
    }),
    onSubmit: async (values: any) => { 
      checkOnboardingStatus();
    },
  });

  const checkOnboardingStatus = async () => {
    const res = await fetchVendorOnbaordingStatus();

    if (res?._status?.code === 200) {
      res?.data?.show_warning ? setIsSameEmail(true) : sendEmail();
    }
  };

  const sendEmail = async () => {
    const res = await sendWelcomeEmail({
      //   agreement_value: selectedVendorType.value || 1,
      //   agreement_url: selectedVendorType?.agreement_url || "",
      vendor_id: vendorId,
      ...values,
    });

    if (res?._status?.code === 201) {
      resetForm({ values: initialValues });
      setIsEdit(false);
      setActiveEdit("");
      setIsSameEmail(false);
      setVendorDetailsEditing(false);
    }

    if (res?.response?.data?.message) {
      setError({
        message: res?.response?.data?.message,
        status: res?.response?.status,
      });
    } else if (res?.response?.status === 500) {
      setError({
        message: "",
        status: res?.response?.status,
      });
    }
  };

  const {
    values,
    errors,
    handleReset,
    handleChange,
    setErrors,
    resetForm,
    handleSubmit,
  } = formik;

  const onClickCancel = (fieldName: string) => {
    const newErrors = deleteObjectProp(errors, fieldName);

    newErrors && setErrors && setErrors(newErrors);
  };

  const onEdit = () => {
    setIsEdit(true);
    setActiveEdit(VendorDetailSections.WELCOME_EMAIL);
    setVendorDetailsEditing(true);
  };

  const onClickCross = () => {
    setIsEdit(false);
    handleReset(initialValues);
    setActiveEdit("");
    setVendorDetailsEditing(false);
  };

  const isEqual = _.isEqual(initialValues, values);

  const isDisabled = useIsDisabled(values, errors);
  const sendDisabled = data?.email_sent ? isEqual : false;
  const isLoading =
    loading[VendorActions.SEND_WELCOME_EMAIL] ||
    loading[VendorActions.GET_VENDOR_ONBOARDING_STATUS];
  const vendorLoading = loading[VendorActions.GET_VENDOR_DETAILS];

  const onClickSubmit = async () => {

    if(values.first_name && values.last_name && values.prospect_email){
    const res: any = await updateVendorDetails(values);

    if (res?.response?.data?.message) {
      setError({
        message: res?.response?.data?.message,
        status: res?.response?.status,
      });
    } else if (res?.response?.status === 500) {
      setError({
        message: "",
        status: res?.response?.status,
      });
    }

    if (res?._status?.code === 200) {
      setIsEdit(false);
      setActiveEdit("");
      setVendorDetailsEditing(false);
    }
}
  };

  useEffect(() => {
    setIsEdit(data?.email_sent.length > 0 ? false : true);
  }, [data?.email_sent.length]);

  useEffect(()=>{

        if(data?.email_sent.length === 0)
         {
          setVendorDetailsEditing(true);
          setActiveEdit(VendorDetailSections.WELCOME_EMAIL);
         }

            /* eslint-disable react-hooks/exhaustive-deps */


  }, [])


  return (
    <>
      <SectionHeader
        title="Send Welcome Email to Prospect"
        isEditMode={isEdit && !vendorLoading}
        onClick={onEdit}
        onClickCross={onClickCross}
        editDisabled={
          data?.email_sent ||
          (activeEdit && activeEdit !== VendorDetailSections.WELCOME_EMAIL)
        }
        submitDisabled={isDisabled}
        isLoading={isLoading || vendorLoading}
        onClickSubmit={onClickSubmit}
      />
      <div className="subscription-plan-form">
        <form onSubmit={handleSubmit}>
          <div className="mutiple-gormGroups">
            <div className="detail-group formGroup firstName">
              <EditableInput
                label="First Name"
                name="first_name"
                value={values.first_name}
                onChange={handleChange}
                isError={!!errors.first_name}
                placeholder={
                  errors.first_name ? (errors.first_name as string) : ""
                }
                onClickCross={() => onClickCancel("first_name")}
                isLoading={vendorLoading}
                isEdit={isEdit}
                maxLength={NAME_CHAR_LIMIT}
              />
            </div>
            <div className="detail-group formGroup lastName">
              <EditableInput
                label="Last Name"
                name="last_name"
                value={values.last_name}
                onChange={handleChange}
                isError={!!errors.last_name}
                placeholder={
                  errors.last_name ? (errors.last_name as string) : ""
                }
                onClickCross={() => onClickCancel("last_name")}
                isLoading={vendorLoading}
                isEdit={isEdit}
                maxLength={NAME_CHAR_LIMIT}
              />
            </div>
          </div>

          <div className="mutiple-gormGroups">
            <div className="detail-group formGroup email">
              <div style={{ display: "flex", alignItems: "center" }}>
                {vendorLoading ? (
                  <LabelSkeleton />
                ) : (
                  <Label inputLabel={isEdit} text="Prospect Email" />
                )}
                {vendorLoading ? (
                  <LabelSkeleton />
                ) : (
                  data?.email_sent && (
                    <Label
                      text={`Sent ${moment(data.email_sent).format("M/D/YY")}`}
                      customStyles={{
                        color: "#222",
                        backgroundColor: "#FDC13D",
                      }}
                    />
                  )
                )}
              </div>
              <EditableInput
                name="prospect_email"
                value={values.prospect_email}
                onChange={(e: any) => {
                  handleChange(e);
                }}
                isError={!!errors.prospect_email}
                placeholder={
                  errors.prospect_email ? (errors.prospect_email as string) : ""
                }
                onClickCross={() => onClickCancel("prospect_email")}
                isLoading={vendorLoading}
                isEdit={isEdit}
                maxLength={EMAIL_CHAR_LIMIT}
              />
            </div>
            {isEdit && !vendorLoading && (
              <Button
                buttonName="Send"
                parentClass="sendBtn"
                type="submit"
                isActiveDisabled={isDisabled}
                loading={!isSameEmail && isLoading}
                dropShadow={false}
                disabled={sendDisabled}
              />
            )}
          </div>
        </form>
      </div>
      {error.status && (
        <ApiModal
          title="Update Vendor Failed"
          message={error.message}
          setIsShow={() => setError({ status: null, message: "" })}
        />
      )}
      {isSameEmail && (
        <InfoModal
          title="Edit Prospect Terms"
          description="The prospect will be taken back to the Agreement step in the Onboarding Checklist."
          closeModal={() => setIsSameEmail(false)}
          onSubmit={sendEmail}
          submitLoading={isLoading}
        />
      )}
    </>
  );
};

export default SendWelcomeEmailToProspect;

// function updateVendorDetails(values: any) {
//     throw new Error("Function not implemented.");
// }
