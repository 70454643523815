import React, { useContext } from 'react'
import ReportTabs from "components/reports/normalAdmin";
import { ReportContext } from 'contexts/report';



const VendorReportCore = () => {
  const { 
    eventList, 
    fetchEventItems, 
    setEventItems, 
    fetchEventSales, 
    fetchVenues, 
    setSeeAll, 
    selectedVenue, 
    selectedEvent, 
    startDate, 
    endDate, 
    fetchReprtEvents,
    eventDetails,
    seeAll, 
    setAllOrders,
    setOrderQueryParams,
    getVenuesUpdated, 
    venueQueryParams, 
    setVenueQueryParams
    
} = useContext(ReportContext);
  
  
    return (
    
          <ReportTabs  
            eventList = {eventList}
            fetchEventItems = {fetchEventItems} 
            setEventItems = {setEventItems} 
            fetchEventSales = {fetchEventSales} 
            fetchVenues = {fetchVenues} 
            setSeeAll = {setSeeAll} 
            selectedVenue = {selectedVenue} 
            selectedEvent = {selectedEvent} 
            startDate = {startDate} 
            endDate = {endDate} 
            fetchReprtEvents = {fetchReprtEvents}
            eventDetails = {eventDetails}
            seeAll = {seeAll}
            setAllOrders = {setAllOrders}
            setOrderQueryParams = {setOrderQueryParams}
            getVenuesUpdated = {getVenuesUpdated}
            venueQueryParams = {venueQueryParams}
            setVenueQueryParams = {setVenueQueryParams}
            />


  )
}

export default VendorReportCore
