import BottomBar from "components/common/bottomBar";
import SuperAdminDashboard from "components/dashboard/super";
import VendorDashboard from "components/dashboard/vendor";
import SuperAdminDashboarContextdProvider from "contexts/superAdminDashboard";
import VendorAdminDashboardContextProvider from "contexts/vendorAdminDashboard";
import { useIsSuperAdmin } from "hooks/useIsSuperAdmin";

const Dashboard = () => {
  const isSuperAdmin = useIsSuperAdmin();

  return (
    <>
      {isSuperAdmin ? (
        <div className="dashboard-main">
          <SuperAdminDashboarContextdProvider>
            <SuperAdminDashboard />
          </SuperAdminDashboarContextdProvider>
        </div>
      ) : (
        <div className="dashboard-main">
          <VendorAdminDashboardContextProvider>
            <VendorDashboard />
          </VendorAdminDashboardContextProvider>
        </div>
      )}

      <BottomBar />
    </>
  );
};

export default Dashboard;
