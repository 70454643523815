const LabeledInputSkeleton = ({switchSkeleton, customLabelStyle, customInputStyles, animation=true}: any) => {
    return (
        <div className="detail-group-skeleton">
            <div 
                className={`label-skeleton ${animation ? 'skeleton-animation' : ''}`}
                style={{
                    ...(customLabelStyle && customLabelStyle)
                }}
            ></div>
            <div 
                style={{
                    ...(customInputStyles && customInputStyles)
                }} 
                className={`data-skeleton ${animation ? 'skeleton-animation' : ''} ${switchSkeleton ? 'switch-skeleton' : ''}`}
            >
            </div>
        </div>
    )
};

export default LabeledInputSkeleton;
