
import OrderSection from "components/orders";


const Orders = () => {


    return <OrderSection />
 
};

export default Orders;
