import { AuthContext } from "contexts/auth";
import { useContext } from "react";

const LogoButton = () => {
    const { openSidebar, openSearchbar, setOpenSideBar, setOpenSearchbar } = useContext(AuthContext);
    return (
        <div className="sideBar-logo-btn">
            <button className={`${openSidebar ? 'active-btn' : ''}`} 
            onClick={() => {
                setOpenSideBar(!openSidebar);
                setOpenSearchbar(openSearchbar ? false : openSearchbar);
            }}>
                <svg width="47" height="40" viewBox="0 0 47 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <g clipPath="url(#clip0_1658_92)">
                    <path d="M44.1098 8.33966L15.9498 14.6397C17.9798 16.1097 19.9098 17.7397 22.0898 18.9997C23.1498 19.6097 22.2498 21.2897 21.1798 20.6797C18.4298 19.0897 16.0898 16.9097 13.4098 15.2097L1.96983 17.7697C0.289827 18.1497 -0.0901728 20.3797 1.37983 21.2797L30.5098 39.2197C31.4898 39.8197 32.7698 39.4297 33.2498 38.3797L45.8898 10.5497C46.4298 9.35966 45.3798 8.05966 44.0998 8.34966L44.1098 8.33966Z" fill="#26CAD3" stroke="#231F20" strokeWidth="0.94" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M34.5899 18.0099L32.2699 18.1199L30.8499 6.57992L26.1299 5.45992L26.6199 3.16992L33.1199 4.66992L34.5899 18.0099Z" fill="#FD2B13" stroke="#231F20" strokeWidth="0.94" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M28.3999 9.16016L27.1699 15.1602L35.8299 11.5802L28.3999 9.16016Z" fill="#26CAD3" stroke="#231F20" strokeWidth="0.94" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M35.8301 11.5801L27.1701 15.1601L25.3301 24.2101L27.9401 25.0601L40.2901 13.0301L35.8301 11.5801Z" fill="#FD2B13" stroke="#231F20" strokeWidth="0.94" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M27.9399 25.0603L34.7599 27.2803L41.1199 13.3003L40.2899 13.0303L27.9399 25.0603Z" fill="#0075C9" stroke="#231F20" strokeWidth="0.94" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12.2254 1.91356L9.88443 3.33745C7.69031 4.67202 6.99351 7.53259 8.32808 9.72672L20.9872 30.5391C22.3218 32.7333 25.1823 33.4301 27.3765 32.0955L29.7174 30.6716C31.9116 29.337 32.6084 26.4764 31.2738 24.2823L18.6147 3.46991C17.2801 1.27579 14.4195 0.578986 12.2254 1.91356Z" fill="#FFC844" stroke="#231F20" strokeWidth="0.84" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M10.3266 0.716072L9.69433 1.10063C8.75534 1.67177 8.45714 2.89597 9.02828 3.83496L26.5515 32.6443C27.1226 33.5832 28.3468 33.8814 29.2858 33.3103L29.9181 32.9258C30.857 32.3546 31.1552 31.1304 30.5841 30.1914L13.0609 1.38212C12.4898 0.443134 11.2656 0.144932 10.3266 0.716072Z" fill="#FD2B13" stroke="#231F20" strokeWidth="0.84" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M12.3299 7.13C12.0999 6.43 12.2299 5.83 12.3399 5.28C12.4799 4.65 12.5799 4.15 12.1699 3.48L12.9499 3C13.5499 3.98 13.3799 4.77 13.2299 5.47C13.0899 6.1 12.9899 6.6 13.3999 7.27C13.8099 7.94 14.2999 8.08 14.9199 8.24C15.6099 8.43 16.3799 8.64 16.9799 9.62C17.5799 10.6 17.4099 11.39 17.2599 12.09C17.1199 12.72 17.0199 13.22 17.4299 13.89C17.8399 14.56 18.3299 14.7 18.9499 14.86C19.6399 15.05 20.4099 15.26 21.0099 16.24C21.6099 17.22 21.4399 18.01 21.2899 18.71C21.1499 19.34 21.0499 19.84 21.4599 20.51C21.8699 21.18 22.3599 21.32 22.9799 21.48C23.6699 21.67 24.4499 21.88 25.0399 22.86C25.6399 23.84 25.4699 24.63 25.3199 25.33C25.1799 25.96 25.0799 26.46 25.4899 27.13C25.8999 27.8 26.3899 27.94 27.0099 28.11C27.6999 28.3 28.4799 28.51 29.0699 29.49L28.2899 29.97C27.8799 29.3 27.3899 29.16 26.7699 28.99C26.0799 28.8 25.2999 28.59 24.7099 27.61C24.1099 26.62 24.2799 25.84 24.4299 25.14C24.5699 24.51 24.6699 24.01 24.2599 23.34C23.8499 22.67 23.3599 22.53 22.7399 22.37C22.0499 22.18 21.2799 21.97 20.6799 20.99C20.0799 20.01 20.2499 19.22 20.3999 18.52C20.5399 17.89 20.6399 17.39 20.2299 16.72C19.8199 16.05 19.3299 15.91 18.7099 15.75C18.0199 15.56 17.2499 15.35 16.6499 14.37C16.0499 13.39 16.2199 12.6 16.3699 11.9C16.5099 11.27 16.6099 10.77 16.1999 10.1C15.7899 9.43 15.2999 9.29 14.6799 9.13C13.9899 8.94 13.2199 8.73 12.6199 7.75C12.4899 7.53 12.3899 7.33 12.3299 7.13Z" fill="#231F20"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_1658_92">
                    <rect width="46.51" height="39.96" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
            </button>
        </div>
    )
}

export default LogoButton;