import { ChangeEvent, useCallback, useContext, useRef, useState } from "react";
import { useDropzone } from "react-dropzone";
import MenuData from "./data";
import FoodImageLibraryModal from "../foodImageLibraryModal";
import { VendorActions } from "contexts/reducers/vendor";
import Button from "components/common/button";
import StepCircle from "./stepCircle";
import { OnboardingContext } from "contexts/onboarding";

const CSV_DATA_RESPONSE = {
  data: [],
  errors: [],
  url: "",
  vendor: null,
};

const Menu = ({ onClick }: any) => {
  const { getImageLibrary, addCSVMenu, loading } =
    useContext(OnboardingContext);

  const [noImageIndices, setNoImageIndices] = useState([]);
  const [file, setFile] = useState<any>(null);
  const [selectedName, setSelectedName] = useState("");
  const [error, setError] = useState("");
  const [csvData, setCsvData] = useState<any>(CSV_DATA_RESPONSE);
  const [active, setActive] = useState<any>(false);
  const [showModal, setShowModal] = useState(false);
  const inputRef = useRef<any>(null);

  const onUploadCSV = async (files: FileList | null) => {
    if (files && files.length > 0) {
      const file = files[0];

      if (file?.type !== "text/csv") {
        setError("Your file type is not CSV! Please, upload only CSV file.");
        return;
      }

      setFile(file);

      const res = await addCSVMenu({
        file,
        confirm: "false",
      });

      if (res?._status?.code === 201) {
        setCsvData(res.data);
        setError("");
      } else {
        setError(res?.response?.data?.message);
      }
    }
  };

  const onDrop = useCallback((acceptedFiles: any) => {
    onUploadCSV(acceptedFiles);

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  const onClickCancel = () => {
    setCsvData(CSV_DATA_RESPONSE);
    setFile(null);
  };

  const onClickDownload = () => {
    const aLink = document.createElement("a");
    aLink.href = "/crowd-chow-menu-template.csv";
    aLink.download = "sample_file_for_testing.csv";
    aLink.click();
    setActive(true);
  };

  const onOpenFoodImageModal = (name: string) => {
    setShowModal(true);
    getImageLibrary({ web_only: true });
    setSelectedName(name);
  };

  const onSelectImage = (item: any) => {
    const { image_url } = item;

    const updatedData = csvData.data.map((item: any) => {
      if (item.name === selectedName) {
        return {
          ...item,
          item_image: image_url,
        };
      } else {
        return item;
      }
    });

    setCsvData((prev: any) => ({
      ...prev,
      data: updatedData,
    }));
  };

  const onClickSaveAndSubmit = async () => {
    const indices = csvData.data.reduce(
      (acc: any, item: any, index: number) =>
        !item.item_image ? [...acc, index] : acc,
      []
    );

    if (indices.length > 0) {
      setNoImageIndices(indices);
      return;
    }

    indices.length > 0 && setNoImageIndices(indices);

    const res = await addCSVMenu({
      data: csvData.data,
      confirm: "true",
    });

    if (res?._status?.code === 201) {
      if (res?.data?.errors?.length > 0) {
        setCsvData(res.data);
      } else {
        onClick();
      }
    }
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const isError = csvData.errors.some((item: any) => item?.error);
  const isData = !isError && csvData.data.length > 0;

  const disabled = csvData.data.some((item: any) => !item.item_image);

  const isLoading = loading.includes(VendorActions.SET_MENU);
  const submitting = loading.includes(VendorActions.SET_SAVE_AND_SUBMIT);

  return (
    <>
      <div className="menuSetup">
        <h1 className="menuSetup_title">Menu</h1>
        <p className="menuSetup_desc">
          Use the template below to create a CSV (comma separated values) file
          containing categorized items with description, price, options and
          variants. Upload the CSV to create your Global Menu.
        </p>
        <p className="menuSetup_desc">
          Your items will be matched to our image library. For those items
          without a match, you need to select and image from the library or
          upload an image.
        </p>
        <div className="downloadUpload">
          <StepCircle
            stepNo={1}
            state={`step-number ${active ? "active" : ""}`}
          />
          <div
            onClick={onClickDownload}
            style={{ cursor: "pointer" }}
            className="menuSetup_uploadFile"
          >
            <p className="menuSetup_uploadFile_uploadTitle">
              Download Template
            </p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="61"
              height="68"
              viewBox="0 0 61 68"
              fill="none"
            >
              <path
                d="M60.9999 0H5.68677V68H43.2239L60.9999 52.0421V0Z"
                fill="#F6F6F6"
              />
              <path
                d="M43.2241 67.9999L61.0001 52.042H43.2241V67.9999Z"
                fill="#B4B4B4"
              />
              <path
                d="M37.4429 12.4785H0V29.2079H37.4429V12.4785Z"
                fill="#41BB86"
              />
              <path
                d="M12.3026 19.3884C12.1964 19.0061 12.1114 18.8045 11.9519 18.5814C11.6331 18.1142 11.123 17.8805 10.464 17.8805C9.8582 17.8805 9.33753 18.0824 8.96556 18.4753C8.47675 18.9849 8.211 19.8025 8.211 20.822C8.211 22.6804 9.07178 23.8058 10.4959 23.8058C11.1655 23.8058 11.718 23.5509 12.0475 23.0837C12.2175 22.8288 12.3026 22.6059 12.377 22.1387L14.2262 22.4361C14.0136 23.2855 13.8117 23.7315 13.4292 24.1881C12.8021 24.942 11.7713 25.3456 10.5067 25.3456C9.24212 25.3456 8.26431 24.9633 7.57368 24.2518C6.79788 23.4554 6.3728 22.2342 6.3728 20.8326C6.3728 19.4311 6.82975 18.1781 7.69052 17.3496C8.38134 16.6912 9.3271 16.3516 10.4749 16.3516C11.8032 16.3516 12.8341 16.8081 13.5035 17.6895C13.8117 18.093 13.9711 18.4328 14.12 19.0061L12.3026 19.3884Z"
                fill="white"
              />
              <path
                d="M16.9147 22.4148C16.9572 22.8501 17.021 23.052 17.2017 23.2749C17.4887 23.6465 17.9882 23.8482 18.6152 23.8482C19.5398 23.8482 20.1244 23.4235 20.1244 22.7438C20.1244 22.4146 19.9863 22.1491 19.7099 21.9792C19.3804 21.767 19.0509 21.6606 17.8926 21.3953C16.9893 21.1828 16.6386 21.0448 16.2347 20.7581C15.6395 20.3121 15.3208 19.6643 15.3208 18.8785C15.3208 17.3175 16.5642 16.3301 18.5303 16.3301C19.7949 16.3301 20.7513 16.7124 21.304 17.4345C21.5697 17.7955 21.7078 18.1035 21.8459 18.7299L19.9755 18.9318C19.9223 18.6026 19.848 18.4433 19.6991 18.2521C19.4546 17.9654 19.0295 17.8061 18.5195 17.8061C17.6905 17.8061 17.138 18.2097 17.138 18.815C17.138 19.1018 17.2655 19.3353 17.4993 19.5053C17.7332 19.6539 17.9457 19.7284 18.4982 19.8557C19.6566 20.1 20.1666 20.238 20.5917 20.4291C21.0274 20.6201 21.4206 20.9493 21.6651 21.3528C21.8776 21.7033 21.984 22.128 21.984 22.6377C21.984 24.3685 20.7725 25.3561 18.6471 25.3561C17.2763 25.3561 16.2454 24.9738 15.6822 24.2623C15.3315 23.827 15.1828 23.4235 15.0659 22.5952L16.9151 22.4146L16.9147 22.4148Z"
                fill="white"
              />
              <path
                d="M26.6068 22.8823C26.7024 23.1903 26.7875 23.477 26.8194 23.615C26.8831 23.3919 26.9575 23.1478 27.0425 22.8823L28.6897 17.6579C28.8704 17.0951 28.8917 16.9888 28.9554 16.5322H31.0702C30.9321 16.8084 30.815 17.0949 30.5601 17.8171L28.2964 24.0608C28.1052 24.5704 27.9988 24.9102 27.9563 25.1652H25.6821C25.6289 24.8996 25.5227 24.5598 25.342 24.0608L23.0783 17.8171C22.855 17.1907 22.7063 16.8402 22.5576 16.5322H24.6724C24.7361 16.9464 24.8105 17.2437 24.9381 17.6579L26.6066 22.8823H26.6068Z"
                fill="white"
              />
            </svg>
            <StepCircle
              stepNo={1}
              state={`step-number-mob ${active ? "active" : ""}`}
            />
          </div>
        </div>
        <div className="downloadUpload">
          <StepCircle
            stepNo={2}
            state={`step-number ${
              isError || error ? "error" : isData ? "active" : ""
            }`}
          />
          <div {...getRootProps()} className="menuSetup_uploadFile">
            <input
              ref={inputRef}
              {...getInputProps()}
              type="file"
              accept=".csv"
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                onUploadCSV(e.target.files)
              }
            />
            <p className="menuSetup_uploadFile_uploadTitle">Upload Menu CSV</p>
            <p className="menuSetup_uploadFile_uploadOptionText">
              Drag a file here or select Choose File below to upload CSV.
            </p>
            <Button
              onClick={(e: any) => {
                if (isData) {
                  e.stopPropagation();
                  onClickCancel();
                }
              }}
              type="button"
              customClass={`${isData ? "cancel" : ""}`}
              loading={isLoading}
              loadingText="Uploading..."
              buttonName={isData ? "Cancel" : "Choose File"}
            />
            {file && (
              <p
                style={{
                  marginTop: 2,
                  color: "white",
                  fontSize: 14,
                  marginBottom: "0",
                  height: "21px",
                  overflow: "hidden",
                }}
              >
                {file?.name || ""}
              </p>
            )}
            <StepCircle
              stepNo={2}
              state={`step-number-mob ${
                isError || error ? "error" : isData ? "active" : ""
              }`}
            />
          </div>
        </div>

        <MenuData
          csvData={csvData}
          error={error}
          onClickOpen={(name: any, index: number) => {
            onOpenFoodImageModal(name);
            setNoImageIndices((prev) =>
              prev.filter((idx: number) => idx !== index)
            );
          }}
          noImageIndices={noImageIndices}
        />
        <div className="submitBtn">
          {isData && !isError && (
            <Button
              onClick={() => onClickSaveAndSubmit()}
              disabled={!isData}
              isActiveDisabled={disabled}
              loading={submitting}
              buttonName="Save and Submit"
              loadingText="Submitting..."
            />
          )}
        </div>
      </div>
      {showModal && (
        <FoodImageLibraryModal
          closeModal={() => setShowModal(false)}
          onSelectImage={onSelectImage}
        />
      )}
    </>
  );
};

export default Menu;
