import { useEffect } from "react";

interface Props {
    width?: number;
    height?: number;
    onClick?: Function;
    disabled?: boolean;
    activeDisabled?: boolean;
    isForm?: boolean;
    setSuccess?: Function
}

const CheckMarkButton = ({onClick, width=33, height=33, disabled, activeDisabled, isForm}: Props) => {

    useEffect(() => {
        const keyHandler = (e: any) => {
            if(e.keyCode === 13){
                !disabled && onClick && onClick();
            }
        }

        if(isForm){
            window.addEventListener('keydown', keyHandler, false);
        }
        

        return () => {
            window.removeEventListener('keydown', keyHandler, false);
        }
        /* eslint-disable react-hooks/exhaustive-deps */
    },[]);

    return (
        <svg className={`modal-action-button ${disabled ? '' : 'cursor-pointer'} ${(disabled || activeDisabled) ? 'disabled' : ''}`} onClick={() => !disabled && onClick && onClick()} width={width} height={height} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 45 45" fill="none">
            <path d="M22.5 45C34.9264 45 45 34.9264 45 22.5C45 10.0736 34.9264 0 22.5 0C10.0736 0 0 10.0736 0 22.5C0 34.9264 10.0736 45 22.5 45Z" fill="#27C1C9"/>
            <path d="M33.4422 17.717L30.7252 15L20.4472 25.278L15.0422 19.867L12.3252 22.584L20.4462 30.705L33.4422 17.717Z" fill="white"/>
        </svg>
        /*
        <svg className={`modal-action-button ${disabled ? '' : 'cursor-pointer'} ${(disabled || activeDisabled) ? 'disabled' : ''}`} onClick={() => !disabled && onClick && onClick()} width={width} height={height} viewBox="0 0 32 33" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_3552_3892)">
                <path d="M15.0029 30.8525C23.2872 30.8525 30.0029 24.1368 30.0029 15.8525C30.0029 7.56827 23.2872 0.852539 15.0029 0.852539C6.71866 0.852539 0.00292969 7.56827 0.00292969 15.8525C0.00292969 24.1368 6.71866 30.8525 15.0029 30.8525Z" fill="#27C1C9"/>
            </g>
            <path d="M22.856 12.261L21.009 10.4141L14.021 17.4021L10.345 13.7261L8.49805 15.5731L14.0201 21.0951L22.856 12.261Z" fill="white"/>
            <defs>
                <filter id="filter0_d_3552_3892" x="0.00292969" y="0.852539" width="32" height="32" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                    <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                    <feOffset dx="2" dy="2"/>
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.161 0"/>
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3552_3892"/>
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3552_3892" result="shape"/>
                </filter>
            </defs>
        </svg>*/
    )
};

export default CheckMarkButton;
