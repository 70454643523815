import React, { FC } from "react";
import { useNavigate } from "react-router-dom";

interface Props {
  name: string,
  attribute: string,
  index: number,
  tag: string

}




        
       
     
const ActiveVendorsListItem: FC<Props> = ({name, attribute, index, tag}) => {


const navigate = useNavigate();


const eventClickHandler = ()=>{
if(tag==="Event" || tag === "Events")
navigate("/reports", {state: {from: "dashboardEvents"}})


}





  return (
<>   

    <div className="activeVendor">
      <div className="activeVendorName">
        <span>{index +1}.</span>
        <p>{name}</p>
      </div>
      
      <div onClick={eventClickHandler} className="vendorEventsLabel"> 
        {`${tag === "$" ? "$" : ""}${attribute ? attribute : ""  } 
          ${tag ==="Events" ? ((parseInt(attribute)=== 0 || parseInt(attribute)>1)  ? "Events": "Event") : ""} 
          ${tag==="Customers"? ((parseInt(attribute)=== 0 || parseInt(attribute)>1)  ? "Customers": "Customer") : ""}
        `} 
      </div>

    </div>
    </>

  );
};

export default ActiveVendorsListItem;
