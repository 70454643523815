/* eslint-disable no-useless-escape */
import * as Yup from 'yup';

export const addLeadSchema = Yup.object({
    name: Yup.string().trim().required('Name is required'),
    vendor_name: Yup.string().trim().required('District or school is required'),
    role: Yup.string().required('Role is required'),
    email: Yup.string().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Enter valid email').required('Email is required'),
    number_of_venues: Yup.number().required('No of venues is required'),
    city: Yup.string().trim('Please, enter valid city').required('Required'),
    state: Yup.string().required('Req'),
    vendor_type: Yup.string().required('Required'),
    high_schools: Yup.number().nullable()
})

export const editLeadSchema = Yup.object({
    first_name: Yup.string().required('First name is required'),
    last_name: Yup.string().required('Last name is Required'),
    email: Yup.string().matches(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/, 'Enter valid email').required('Email is required'),
})