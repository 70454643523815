import { useEffect, useState } from "react";

export const isWithinBound = (firstElement: HTMLElement | null, secondElement: HTMLElement | null) => {
    if(!firstElement || !secondElement){
        return false;
    }
    const rect1 = firstElement.getBoundingClientRect();
    const rect2 = secondElement.getBoundingClientRect();
    
    return rect1.right <= rect2.right &&
    rect1.left >= rect2.left &&
    rect1.bottom <= rect2.bottom &&
    rect1.top >= rect2.top
}

export const useIsInBound = (positions: any) => {
    const [isInBound, setIsInBound] = useState<boolean | undefined>(false);
    const [isConcessionBound, setIsConcessionBound] = useState<boolean | undefined>(false);
    
    useEffect(() => {
    
        if(positions){
            const mapContainer = document.getElementById("map-container");
            const stations = ['station_1', 'station_2', 'station_3', 'station_4'];

            const inBound = stations.some((id: string) => {
                const el = document.getElementById(id);
                if(el && mapContainer){
                    return isWithinBound(el, mapContainer);
                }
                return false;
            });

            
            const consContainer = document.getElementById("concession_1");
            let concessionInbound = false;
            if(consContainer && mapContainer){
                concessionInbound = isWithinBound(consContainer, mapContainer);
            }
            
            setIsInBound(inBound);
            setIsConcessionBound(concessionInbound);
        }
        
    },[positions])

    return {
        isStationsBound: isInBound,
        isConcessionBound: isConcessionBound
    };
}