import { PDFDocument } from 'pdf-lib';
import { generateQrCode, getPdfData } from 'util/qrCodeHelpers';

export const useQrCodeGeneration = () => {
   
    const onClickDownload = async (type: string, slug: string | undefined, landingType: "venue" | "vendor", setLoading?: Function | null ) => {
        
  
        setLoading && setLoading(true)
        
        const pdfData = getPdfData(type);
        if(!pdfData){
            return;
        }

        const qrCodeUrl = slug;
        
        if(!qrCodeUrl){
            return;
        }

        const qrCode = generateQrCode(qrCodeUrl, pdfData.width, pdfData.height);

        await qrCode.getRawData("png")
        const canvas = qrCode._canvas;
        
        const base64Image = canvas.toDataURL(`image/png`);
        
        const {url, x, y, scale} = pdfData;
        
        const existingPdfBytes = await fetch(url).then(res => res.arrayBuffer());
       
        
        const pdfDoc = await PDFDocument.load(existingPdfBytes);
        

        const pages = pdfDoc.getPages()
        const firstPage = pages[0];
    
        const pngImageBytes = await fetch(base64Image).then((res) => res.arrayBuffer());

        
        if(type !== '2x3')
        {    
            const pngImage = await pdfDoc.embedPng(pngImageBytes);
            const pngDims = pngImage.scale(scale);
            firstPage.drawImage(pngImage, {
                x: x,
                y: y,
                width: pngDims.width,
                height: pngDims.height,
            });
        }

        

        const pdfBytes = await pdfDoc.save();
    

        
        const file = new Blob([pdfBytes], { type: "application/pdf" });

        const fileURL = URL.createObjectURL(file);
        return fileURL;
        
        // const alink = document.createElement('a');
        // alink.href = fileURL;
        // alink.target = '_blank';
        // alink.download = type !== '2x3' ? `banner_${type}.pdf`: 'General_Sign_2x3.pdf';
        // alink.click();
                
        // setLoading && setLoading(false)
    }

    return {
        onClickDownload
    }
}